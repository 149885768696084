import React, { useState, useEffect } from 'react';
import { Form, Card, Space, Input } from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomTable from 'common/Content/CustomTable';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers } from '../../redux/slice';
import {
  getAllDepartments,
  getAllDesignations
} from '@/features/admin/redux/slice';
import moment from 'moment';

const AdminPage = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { allUsers, pagination, allDisignations, allDepartments } = useSelector(
    (state) => state.admin
  );

  const { Search } = Input;

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    handleGetAllUsers();
    handleGetAllDepartments();
    handleGetAllDesignations();
  }, [page, search]);

  const handleGetAllDepartments = () => {
    const payload = {};
    dispatch(getAllDepartments(payload));
  };

  const handleGetAllDesignations = () => {
    const payload = {};
    dispatch(getAllDesignations(payload));
  };

  const handleGetAllUsers = () => {
    const payload = { page, search };
    dispatch(getAllUsers(payload));
  };

  const columns = [
    {
      title: 'Employee ID',
      dataIndex: 'employee_id',
      key: 'employee_id',
      ellipsis: true,
      width: '80px'
    },
    {
      title: 'Name',
      dataIndex: 'first_name',
      key: 'first_name',
      ellipsis: true,
      width: '200px',
      render: (_, record) => {
        // return record?.role?.replace(/[^a-zA-Z0-9 ]/g, ' ').toUpperCase();
        let name = '';
        if (record.first_name) {
          name = record.first_name;
        }
        if (record.middle_name) {
          name = name.concat(' ' + record.middle_name);
        }
        if (record.last_name) {
          name = name.concat(' ' + record.last_name);
        }
        return name;
      }
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
      ellipsis: true,
      // width: '100px',
      render: (_, record) => {
        // return record?.role?.replace(/[^a-zA-Z0-9 ]/g, ' ').toUpperCase();
        const dep = allDepartments.filter(
          (department) => department.id == record?.department_id
        );
        return dep[0]?.department || 'NA';
      }
    },
    {
      title: 'Designation',
      dataIndex: 'designation_id',
      key: 'designation_id',
      // ellipsis: true,
      // width: '100px',
      render: (_, record) => {
        // return record?.role?.replace(/[^a-zA-Z0-9 ]/g, ' ').toUpperCase();
        const des = allDisignations.filter(
          (designation) => designation.id == record?.designation_id
        );
        return des[0]?.designation || 'NA';
      }
    },
    {
      title: 'Permission level',
      dataIndex: 'level',
      key: 'level',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return `Level-${record.level}`;
      }
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email'
      // ellipsis: true,
      // width: '100px'
    },
    {
      title: 'User created date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      // ellipsis: true,
      width: '130px',
      render: (_, record) => moment(record.createdAt).format('DD MMM YYYY')
    }
  ];

  return (
    <>
      <Form form={form} layout="vertical">
        <Card
          title={<span>User Details</span>}
          className={`${styles.cardStyles_approver}`}
          extra={
            <Space>
              <Search
                onChange={(e) => handleChange(e)}
                className={`w-100 ${styles.searchbar}`}
                justify="start"
                align="start"
                placeholder="Search by Emp ID or Name"
              />
            </Space>
          }
        >
          <div className={`${styles.table_container}`}>
            <CustomTable
              columns={columns}
              dataSource={allUsers}
              pagination={{
                size: 'small',
                total: pagination?.total_records || 0,
                showSizeChanger: false,
                onChange: (page) => {
                  setPage(page);
                }
              }}
            />
          </div>
        </Card>
        <br />
        <FormItem className="text-center">
          <CustomButton
            className={styles.inwardButton}
            onClick={() => history.back()}
            type="primary"
          >
            Back to Home
          </CustomButton>
        </FormItem>
      </Form>
    </>
  );
};
export default AdminPage;
