import React, { useState, useEffect } from 'react';
import {
  Form,
  Col,
  Row,
  Space,
  Select,
  Radio,
  Input,
  Card,
  message,
  Checkbox
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import { useDispatch } from 'react-redux';
import {
  getMaterials,
  getAllProcessList,
  getAccessories,
  updateMaterials
} from '../../redux/slice';
import {
  PlusOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  HolderOutlined
} from '@ant-design/icons';
import ReactQuill from 'react-quill';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const AddMaterial = ({
  matModal,
  setMatModal,
  product_id,
  processGroupId,
  configId,
  groupId,
  eqp,
  eqptype,
  matId,
  configMaster,
  instructions,
  bmrs,
  newprocessList
}) => {
  const [form] = Form.useForm();
  //   console.log(product_id, processGroupId, configId);
  const [materials, setMaterials] = useState([]);
  const [processList, setProcessList] = useState([]);
  const [data, setData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [equipments, setEquipments] = useState([
    { Equipment_id: '', eqpmodel: '', model: '' }
  ]);
  const [equConfigs, setEquConfigs] = useState([
    { eqp_config_id: '', eqpmodel: '', model: '' }
  ]);
  const [filterData, setFilterData] = useState([]);
  const [accessories, setAccessories] = useState([]);
  const [otherParams, setOtherParams] = useState(false);
  const [parameters, setParameters] = useState([
    {
      id: '',
      parameters: [
        {
          parameter_id: '',
          sub_param_id: '',
          type_of_value: '',
          low: '',
          high: '',
          absolute_value: '',
          uom: ''
        }
      ],
      accessories: [
        {
          parameter_id: '',
          sub_param_id: '',
          type_of_value: '',
          low: '',
          high: '',
          absolute_value: '',
          uom: ''
        }
      ]
    }
  ]);
  const [newInstruction, setNewInstruction] = useState([]);
  const [groupSequences, setGroupSequences] = useState(newprocessList);

  const dispatch = useDispatch();
  console.log(bmrs, 'filterData');
  useEffect(() => {
    handleGetAllMaterials();
    handleGetAllProcessList();
    console.log(eqptype);
    if (eqptype == 'parameter' || eqptype == 'accessories') {
      handleGetParams();
    }
    // if (eqptype == 'Instructions') {

    console.log(instructions, 'instructions');
    if (instructions?.length) {
      const list = [...newInstruction];
      instructions?.map((instr) => {
        let res = instr?.execute_bmr_details
          .filter(({ is_active }) => is_active)
          .map(({ execution_detail_id }) => execution_detail_id);
        list.push({
          instr_id: instr.id,
          eqp_id: instr.process_eqp_config_id,
          instruction: instr.instruction_step,
          execute_bmr: res,
          is_param: instr?.is_param
        });
      });
      setNewInstruction(list);
    } else {
      setNewInstruction([{ instructions: '' }]);
    }

    // }
  }, [flag]);

  console.log(eqptype, 'eqptype');
  const handleGetAllMaterials = () => {
    const payload = { id: product_id };
    dispatch(getMaterials(payload)).then((res) => {
      if (res?.payload?.success) {
        setMaterials(res?.payload?.data?.bmr_product_materials);
      }
    });
  };

  const handleGetParams = () => {
    const payload = { id: eqp };
    const params = [...parameters];
    params[0].id = matId;
    setParameters(params);
    dispatch(getAccessories(payload)).then((res) => {
      if (res?.payload?.success) {
        setAccessories(res?.payload?.data);
      }
    });
  };
  const handleGetAllProcessList = () => {
    dispatch(getAllProcessList()).then((res) => {
      if (res?.payload?.success) {
        // res?.payload?.data?.map((item) => {
        console.log(res?.payload?.data, groupId, 'datat');
        let Filterdata = res?.payload?.data.filter(
          (data) => data.id == groupId
        );
        setData(Filterdata);
        if (eqptype == 'Equipment') {
          const Filtered_data = Filterdata[0]['equipment_masters'].filter(
            (item) => item?.id === matId
          );
          console.log(Filtered_data);
          setFilterData(Filtered_data);
        }
      }
    });
  };

  const handleChangeMaterials = (e) => {
    const newdata = [...processList];
    newdata.material_items = e;
    setProcessList(newdata);
  };

  const handleClose = () => {
    setMatModal(false);
  };

  const handleUpdate = () => {
    console.log(groupId);
    let group_seq = [];
    groupSequences.map((item) => {
      group_seq.push({ id: item?.id });
    });

    const payload =
      eqptype == 'Instructions'
        ? {
            product_id: product_id,
            process_group_id: processGroupId,
            process_config_id: configId,
            instructions: newInstruction,
            eqp_type: 'Instructions'
          }
        : eqptype == 'sequence'
        ? {
            product_id: product_id,
            process_seq: group_seq,
            eqp_type: 'update_sequence'
          }
        : {
            product_id: product_id,
            process_group_id: processGroupId,
            process_config_id: configId,
            material_items: processList.material_items,
            equipments: equipments,
            equipment_masters: [
              { id: configMaster, process_eqp_ids: parameters }
            ],
            eqp_config: equConfigs,
            eqp_type: ''
          };
    console.log(payload);
    dispatch(updateMaterials(payload)).then((res) => {
      if (res?.payload?.success) {
        message.success(res?.payload?.message);
        setFlag(!flag);
        setMatModal(false);
        // setParamsList(res?.payload?.data);
      }
    });
  };

  const handleChangeModel = (e, l, type) => {
    console.log(e);
    if (type == 'Equipment_id') {
      const Filtered_data = data[0]['equipment_masters'].filter(
        (item) => item?.id === e
      );
      setFilterData(Filtered_data);
    }

    let list = [...equipments];
    list[l][type] = e;
    setEquipments(list);
  };

  const handleChangeEditModel = (e, l, type) => {
    console.log(e);

    let list = [...equConfigs];
    list[l]['eqp_config_id'] = eqp;
    list[l][type] = e;
    setEquConfigs(list);
  };

  const AddEquipments = () => {
    let list = [...equipments];
    list.push({
      Equipment_id: '',
      eqpmodel: '',
      model: '',
      parameters: [],
      accessories: []
    });
    setEquipments(list);
    // setEquipments([...equipments, { Equipment_id: '', model: '' }]);
  };

  const deleteEquipments = (i) => {
    let list = [...equipments];
    list.splice(i, 1);
    setEquipments(list);
  };

  const handleSetType = (e, index, process) => {
    console.log(e);
    const params = [...parameters];
    console.log(process);
    if (eqptype == 'accessories') {
      params[0].accessories[index][process] = e;
    } else {
      params[0].parameters[index][process] = e;
    }
    setParameters(params);
    // setTypeofValue(e.target.value);
  };

  console.log(parameters, 'parameters123');
  const handleotherparam = (e) => {
    console.log(e.target.checked);
    setOtherParams(e.target.checked);
  };
  const AddmoreParams = (n, b, e) => {
    console.log(e, b, newInstruction);
    // setOtherParams(e.target.checked);
    let list = [...newInstruction];
    list[n].execute_bmr ? '' : (list[n].execute_bmr = []);

    // let list = [...newInstruction];
    const duplicate = list[n].execute_bmr.filter((item) => item != e);
    otherParams ? list[n].execute_bmr.push(e) : duplicate;
    console.log(list);
    setNewInstruction(list);
  };
  console.log(newInstruction);
  const handleInstructions = (n, e) => {
    console.log(e);
    // setNewInstruction([...instruction, { instructions: '' }]);
    // console.log(mm, n, e);
    let list = [...newInstruction];
    list.push({
      eqp_id: '',
      instruction: '',
      execute_bmr: []
    });
    setNewInstruction(list);
    // setProcessList(list);
  };

  const handleAddMultiParams = () => {
    console.log();
    let list = [...parameters];
    if (eqptype == 'accessories') {
      list[0].accessories.push({
        parameter_id: '',
        sub_param_id: '',
        type_of_value: '',
        low: '',
        high: '',
        absolute_value: '',
        uom: ''
      });
    } else {
      list[0]?.parameters.push({
        parameter_id: '',
        sub_param_id: '',
        type_of_value: '',
        low: '',
        high: '',
        absolute_value: '',
        uom: ''
      });
    }

    setParameters(list);
  };

  const ChangeInstruction = (n, e, type) => {
    let list = [...newInstruction];
    list[n][type] = e;
    setNewInstruction(list);
  };

  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    console.log(droppedItem.source.index, 'hhh');
    // if (droppedItem.type === 'dropableItem') {
    let updatedList = [...groupSequences];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    console.log(reorderedItem);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    setGroupSequences(updatedList);
    console.log(groupSequences, 'updatedList');
    // }
    // if (droppedItem.type === 'dropableItem1')
    // else {
    //   console.log(droppedItem.destination);
    //   let updatedList = [...processList];
    //   console.log(updatedList);
    //   const [reorderedItem] = updatedList[
    //     droppedItem.type
    //   ].bom_process_masters.splice(droppedItem.source.index, 1);
    //   console.log(reorderedItem);
    //   updatedList[droppedItem.type].bom_process_masters.splice(
    //     droppedItem.destination.index,
    //     0,
    //     reorderedItem
    //   );
    //   setProcessList(updatedList);
    // }
  };

  const paramsData =
    eqptype == 'parameter'
      ? parameters?.[0]?.parameters
      : parameters?.[0]?.accessories;
  console.log(paramsData, 'newInstruction');
  return (
    <>
      {matModal ? (
        <CustomModal
          title={
            eqptype == 'materials'
              ? 'Edit Material'
              : eqptype == 'equipments'
              ? 'Edit Equipment'
              : eqptype == 'Equipment'
              ? 'Add Equipment IDs'
              : eqptype == 'parameter'
              ? 'Add Parameters'
              : eqptype == 'accessories'
              ? 'Add Accessories'
              : eqptype == 'Instructions'
              ? 'Edit & Add Instructions'
              : ''
          }
          visible={matModal}
          width={'80%'}
          footer={null}
          className={styles.customModal}
          closable={false}
        >
          <Form form={form} layout="vertical">
            {eqptype == 'materials' ? (
              <>
                <Row>
                  <Col span={24}>
                    <p
                      style={{
                        fontSize: '13px',
                        color: '#263238'
                        // marginTop: '7px'
                      }}
                    >
                      Add materials used during this process
                    </p>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={24}>
                    <FormItem
                      //   label="Materials"
                      rules={[
                        {
                          required: false,
                          message: 'Select Material'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Select
                        mode="multiple"
                        allowClear
                        placeholder="Select Materials"
                        onChange={(e) => handleChangeMaterials(e)}
                      >
                        {materials?.map((mats, j) => {
                          return (
                            <option key={j} value={mats?.material?.id}>
                              {mats?.material?.name}
                            </option>
                          );
                        })}
                        <option value={1281}>
                          Output of previous Stage (In process)
                        </option>
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
              </>
            ) : (
              ''
            )}
            {eqptype == 'Equipment' ? (
              <>
                <Row>
                  <Col span={4}>
                    <p
                      style={{
                        fontSize: '13px',
                        color: '#263238'
                      }}
                    >
                      Select the Equipments
                    </p>
                  </Col>
                </Row>
                {equipments?.map((equipment, l) => {
                  return (
                    <>
                      <Row gutter={24} key={l}>
                        <Col span={10}>
                          <FormItem
                            // label="Select the Equipments"
                            rules={[
                              {
                                required: false,
                                message: 'Select Equipment ID'
                              }
                            ]}
                            className={styles.customLable}
                          >
                            <Select
                              mode="multiple"
                              // value={equipment?.eqpmodel || null}
                              allowClear
                              placeholder="Select Equipment ID"
                              onChange={(e) =>
                                handleChangeEditModel(e, l, 'eqpmodel')
                              }
                            >
                              {filterData[0]?.equipment_models?.map(
                                (mats, j) => {
                                  return (
                                    <option key={j} value={mats?.id}>
                                      {mats?.equipment_id}
                                    </option>
                                  );
                                }
                              )}
                            </Select>
                          </FormItem>
                        </Col>
                      </Row>
                    </>
                  );
                })}
              </>
            ) : (
              ''
            )}
            {eqptype == 'equipments' ? (
              <>
                <Row>
                  <Col span={4}>
                    <p
                      style={{
                        fontSize: '13px',
                        color: '#263238'
                      }}
                    >
                      Select the Equipments
                    </p>
                  </Col>
                </Row>
                {equipments?.map((equipment, l) => {
                  return (
                    <>
                      <Row gutter={24} key={l}>
                        <Col span={6}>
                          <FormItem
                            // label="Select the Equipments"
                            rules={[
                              {
                                required: false,
                                message: 'Select Equipments'
                              }
                            ]}
                            className={styles.customLable}
                          >
                            <Select
                              // mode="multiple"

                              allowClear
                              placeholder="Select Equipments"
                              value={equipment?.Equipment_id || null}
                              onChange={(e) =>
                                handleChangeModel(e, l, 'Equipment_id')
                              }
                            >
                              {data[0]?.equipment_masters?.map((mats, j) => {
                                return (
                                  <option key={j} value={mats?.id}>
                                    {mats?.equipment_name}
                                  </option>
                                );
                              })}
                            </Select>
                          </FormItem>
                        </Col>
                        <Col span={10}>
                          <FormItem
                            // label="Select the Equipments"
                            rules={[
                              {
                                required: false,
                                message: 'Select Equipment ID'
                              }
                            ]}
                            className={styles.customLable}
                          >
                            <Select
                              mode="multiple"
                              // value={
                              //   equipment?.eqpmodel ||
                              //   null
                              // }
                              allowClear
                              placeholder="Select Equipment ID"
                              onChange={(e) =>
                                handleChangeModel(e, l, 'eqpmodel')
                              }
                            >
                              {filterData[0]?.equipment_models?.map(
                                (mats, j) => {
                                  return (
                                    <option key={j} value={mats?.id}>
                                      {mats?.equipment_id}
                                    </option>
                                  );
                                }
                              )}
                            </Select>
                          </FormItem>
                        </Col>
                        <Col span={6}>
                          {l == 0 ? (
                            <CustomButton onClick={(e) => AddEquipments(e)}>
                              <PlusOutlined />
                            </CustomButton>
                          ) : (
                            <CustomButton
                              // className={styles.inwardButton}
                              // type="primary"
                              // ghost
                              onClick={() => deleteEquipments(l)}
                            >
                              <DeleteOutlined />
                            </CustomButton>
                          )}
                        </Col>
                      </Row>
                    </>
                  );
                })}
              </>
            ) : (
              ''
            )}
            {eqptype == 'parameter' || eqptype == 'accessories'
              ? paramsData?.map((param, index) => {
                  const parameters =
                    eqptype == 'accessories'
                      ? accessories[0]?.accessory_masters
                      : accessories[0]?.paramater_type_masters;
                  console.log(parameters);
                  return (
                    <>
                      <Card key={index}>
                        <Row gutter={24} key={index}>
                          <Col span={12}>
                            <FormItem
                              label="Type of Equipment Parameters"
                              rules={[
                                {
                                  required: false,
                                  message: 'Select Material'
                                }
                              ]}
                              className={styles.customLable}
                            >
                              <Select
                                // value={parmtrs?.parameter_id}
                                allowClear
                                placeholder="Select Materials"
                                onChange={(e) =>
                                  handleSetType(e, index, 'parameter_id')
                                }
                              >
                                {parameters?.map((param, j) => {
                                  return (
                                    <option key={j} value={param.id}>
                                      {eqptype == 'parameter'
                                        ? param?.parameter_type
                                        : param?.accessory_name}
                                    </option>
                                  );
                                })}
                              </Select>
                            </FormItem>
                          </Col>
                          <Col span={12}>
                            <FormItem
                              label="Type of Value"
                              rules={[
                                {
                                  required: false,
                                  message: 'Select Accessories'
                                }
                              ]}
                              className={styles.customLable}
                            >
                              <Radio.Group
                                onChange={(e) =>
                                  handleSetType(
                                    e.target.value,
                                    index,
                                    'type_of_value'
                                  )
                                }
                              >
                                <Radio value={1}>Range</Radio>
                                <Radio value={2}>Absolute</Radio>
                              </Radio.Group>
                            </FormItem>
                          </Col>
                          {param.type_of_value === 1 ? (
                            <>
                              <Col span={12}>
                                <FormItem
                                  label="Low value"
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Select Accessories'
                                    }
                                  ]}
                                  className={styles.customLable}
                                >
                                  <Input
                                    placeholder="Enter Low"
                                    onChange={(e) =>
                                      handleSetType(
                                        e.target.value,
                                        index,
                                        'low'
                                      )
                                    }
                                  />
                                </FormItem>
                              </Col>
                              <Col span={12}>
                                <FormItem
                                  label="High Value"
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Select Accessories'
                                    }
                                  ]}
                                  className={styles.customLable}
                                >
                                  <Input
                                    placeholder="Enter High"
                                    onChange={(e) =>
                                      handleSetType(
                                        e.target.value,
                                        index,
                                        'high'
                                      )
                                    }
                                  />
                                </FormItem>
                              </Col>
                            </>
                          ) : param.type_of_value === 2 ? (
                            <Col span={24}>
                              <FormItem
                                label="Value"
                                rules={[
                                  {
                                    required: false,
                                    message: 'Select Accessories'
                                  }
                                ]}
                                className={styles.customLable}
                              >
                                <Input
                                  placeholder="Enter value"
                                  onChange={(e) =>
                                    handleSetType(
                                      e.target.value,
                                      index,
                                      'absolute_value'
                                    )
                                  }
                                />
                              </FormItem>
                            </Col>
                          ) : (
                            ''
                          )}
                          <Col span={24}>
                            <FormItem
                              label="UOM"
                              rules={[
                                {
                                  required: false,
                                  message: 'Select Accessories'
                                }
                              ]}
                              className={styles.customLable}
                            >
                              <Input
                                placeholder="Enter UOM"
                                onChange={(e) =>
                                  handleSetType(e.target.value, index, 'uom')
                                }
                              />
                            </FormItem>
                          </Col>
                        </Row>
                      </Card>
                      <br />
                      {paramsData.length - 1 == index ? (
                        <>
                          <br />
                          <FormItem className="text-center">
                            <CustomButton
                              onClick={() => handleAddMultiParams(index)}
                            >
                              <PlusOutlined /> Add another Parameter{' '}
                            </CustomButton>
                          </FormItem>
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  );
                })
              : ''}
            {eqptype == 'Instructions'
              ? newInstruction?.map((item, n) => {
                  return (
                    <Row gutter={24} key={n}>
                      <Col span={24}>
                        <FormItem
                          label={`Step ${n + 1}`}
                          rules={[
                            {
                              required: false,
                              message: 'Enter instructions'
                            }
                          ]}
                        >
                          <ReactQuill
                            className={styles.customLable9}
                            theme="snow"
                            rows={4}
                            value={item?.instruction || null}
                            placeholder="Enter Instructions"
                            onChange={(e) =>
                              ChangeInstruction(n, e, 'instruction')
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem
                          label="Would you like to show Equipment & Parameters for this step"
                          rules={[
                            {
                              required: false,
                              message: 'Select Material'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Radio.Group
                            onChange={(e) =>
                              ChangeInstruction(
                                // index,
                                // i,
                                n,
                                e.target.value,
                                'is_param'
                              )
                            }
                          >
                            <Radio value="true">Yes</Radio>
                            <Radio value="false">No</Radio>
                          </Radio.Group>
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem
                          label={
                            <span>
                              Do you want to add Fluid Bed Parameters
                              <sup
                                style={{
                                  color: 'red',
                                  fontSize: '12px'
                                }}
                              >
                                *
                              </sup>
                            </span>
                          }
                          rules={[
                            {
                              required: true,
                              message: 'Select Material'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Radio.Group
                            onChange={(e) =>
                              ChangeInstruction(
                                // index,
                                // i,
                                n,
                                e.target.value,
                                'is_fbb_exist'
                              )
                            }
                          >
                            <Radio value="true">Yes</Radio>
                            <Radio value="false">No</Radio>
                          </Radio.Group>
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem
                          label={
                            <span>
                              Would you like to add Tare Wt., Net Wt., Gross Wt.
                              Fields?
                              <sup
                                style={{
                                  color: 'red',
                                  fontSize: '12px'
                                }}
                              >
                                *
                              </sup>
                            </span>
                          }
                          rules={[
                            {
                              required: true,
                              message: 'Select Material'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Radio.Group
                            onChange={(e) =>
                              ChangeInstruction(
                                // index,
                                // i,
                                n,
                                e.target.value,
                                'is_container_exist'
                              )
                            }
                          >
                            <Radio value="true">Yes</Radio>
                            <Radio value="false">No</Radio>
                          </Radio.Group>
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem
                          label="Other Parameters"
                          rules={[
                            {
                              required: false,
                              message: 'Select Material'
                            }
                          ]}
                          className={styles.customLable}
                          onChange={handleotherparam}
                        >
                          <Checkbox.Group
                            style={{
                              width: '100%'
                            }}
                            // value={item?.execute_bmr}
                          >
                            <Row gutter={24}>
                              {bmrs[0]?.bom_process_group_master?.execution_details?.map(
                                // {param?.bom_process_master?.execution_details?.map(
                                (other, b) => {
                                  console.log(item, 'item123');
                                  console.log(other.id, 'bmr1234');
                                  let count = item?.execute_bmr?.filter(
                                    (id) => id == other.id
                                  );
                                  console.log(count, 'count123');
                                  return (
                                    <Col span={12} key={b}>
                                      {/* <Space key={b}> */}
                                      <Checkbox
                                        key={b}
                                        value={other?.id}
                                        // onChange={(e) => {
                                        //   handleotherparam(e);
                                        // }}
                                        onChange={(e) => {
                                          AddmoreParams(
                                            n,
                                            b,
                                            e.target.value,
                                            'other_param'
                                          );
                                        }}
                                      >
                                        {other?.exe_name}{' '}
                                      </Checkbox>
                                      <PlusCircleOutlined
                                        onClick={(e) => {
                                          e.stopPropagation(),
                                            AddmoreParams(
                                              n,
                                              b,
                                              other?.id,
                                              'other_param'
                                            );
                                        }}
                                      />{' '}
                                      {count?.length > 0
                                        ? `(${count?.length})`
                                        : 0}
                                      {/* </Space> */}
                                    </Col>
                                  );
                                }
                              )}
                            </Row>
                          </Checkbox.Group>
                          {/* </Checkbox.Group> */}
                          <span
                            style={{
                              color: '#c91761',
                              float: 'right',
                              cursor: 'pointer'
                            }}
                            onClick={(e) =>
                              handleInstructions(n, e.target.value)
                            }
                          >
                            {/* {instruction?.length - 1 ==
                                                  index */}
                            Add Next Step
                            {/* : ''} */}
                          </span>
                        </FormItem>
                      </Col>
                      {/* <Col span={24}>
                    <FormItem
                      label="Would you like to show Equipment & Parameters for this step"
                      rules={[
                        {
                          required: false,
                          message: 'Select Material'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Radio.Group
                        onChange={(e) =>
                          ChangeInstruction(
                            index,
                            i,
                            n,
                            e.target.value,
                            'is_param'
                          )
                        }
                      >
                        <Radio value="true">Yes</Radio>
                        <Radio value="false">No</Radio>
                      </Radio.Group>
                    </FormItem>
                  </Col> */}
                      {/*  */}
                    </Row>
                  );
                })
              : ''}

            {eqptype == 'sequence' ? (
              <DragDropContext onDragEnd={handleDrop}>
                <Droppable
                  droppableId="list-container"
                  // type="dropableItem"
                  className={`${styles.approver_line}`}
                >
                  {(provided) => (
                    <div
                      // className="list-container"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {groupSequences?.map((item, index) => {
                        return (
                          <>
                            <Draggable
                              key={item.id}
                              draggableId={`${parseInt(item?.id)}`}
                              index={index}
                            >
                              {(provided) => (
                                <>
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.dragHandleProps}
                                    {...provided.draggableProps}
                                  >
                                    <Space>
                                      <HolderOutlined />
                                      <Input
                                        placeholder="Enter Process Group Name of your Choice"
                                        className={styles.updateProcess}
                                        value={
                                          item?.bom_process_group_master
                                            ?.process_group_name
                                        }
                                        disabled
                                      />
                                    </Space>
                                  </div>
                                  {provided.placeholder}
                                  <br />
                                </>
                              )}
                            </Draggable>
                          </>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              ''
            )}
            <FormItem className="text-center">
              <Space>
                <CustomButton type="primary" onClick={() => handleUpdate()}>
                  Update
                </CustomButton>
                <CustomButton
                  type="primary"
                  onClick={() => handleClose(false)}
                  ghost
                >
                  Close
                </CustomButton>
              </Space>
            </FormItem>
          </Form>
        </CustomModal>
      ) : (
        ''
      )}
    </>
  );
};
export default AddMaterial;
