import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Card,
  Space,
  Select,
  Input,
  message,
  Image,
  DatePicker
} from 'antd';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import 'react-quill/dist/quill.snow.css';
import CustomButton from '@/common/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBatches, updateQCapprove } from '../../redux/slice';
// import { PlusOutlined } from '@ant-design/icons';
import { history } from 'app/history';
import { useLocation } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import truck from '../images/truck-delivery-done.gif';
import moment from 'moment';
import VerificationModal from '@/features/material/components/VerificationModal';

const QCApprovalPage = ({ loading }) => {
  const { batchdetails } = useSelector((state) => state.qaapproval);
  console.log(batchdetails);
  const searchParam = useLocation().search;
  const searchParams = new URLSearchParams(searchParam);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [batch, setBatch] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [data, setData] = useState([
    {
      bacth_id: searchParams.get('id'),
      status: '',
      inhouse_retest_date: '',
      assay: '',
      load_water_content: '',
      is_assay: '',
      solvent_percent: ''
    }
  ]);
  const [samplings, setSamplings] = useState();
  const [truckShow, setTruckShow] = useState(false);
  useEffect(() => {
    handleGetAllBatchList();
  }, []);

  const handleGetAllBatchList = () => {
    let payload = { id: searchParams.get('id') };
    dispatch(getAllBatches(payload)).then((response) => {
      if (response?.payload?.success) {
        console.log(response?.payload?.data);
        // const batch_number = response?.payload?.data.filter(
        //   (items) => items?.id == searchParams.get('id')
        // );

        setBatch(response?.payload?.data?.inhouse_batch_number);
        const list = [...data];
        setSamplings(response?.payload?.data);
        form.setFieldsValue({ status: 'Approved' });
        setData([
          {
            bacth_id: searchParams.get('id'),
            status: response?.payload?.data?.status,
            inhouse_retest_date: response?.payload?.data?.inhouse_retest_date,
            assay: response?.payload?.data?.assay,
            load_water_content: response?.payload?.data?.lod_water_content,
            is_code_transfer: response?.payload?.data?.is_code_transfer,
            is_assay:
              response?.payload?.data?.material?.item == 'API'
                ? (list[0]['is_assay'] = true)
                : (list[0]['is_assay'] = false),
            solvent_percent: response?.payload?.data?.solvent_percent
          }
        ]);
      }
    });
  };
  console.log(batch);
  // const onSearch = (value) => {
  //   setSearch(value);
  // };
  console.log(data);

  const onChange = (index, param, e) => {
    console.log(index, e);
    const list = [...data];
    if (param == 'bacth_id') {
      const inhouse_batch = batchdetails.filter((batch) => batch.id == e);
      console.log(inhouse_batch[0]?.inhouse_batch_number?.substring(1, 5));
      inhouse_batch[0]?.inhouse_batch_number?.substring(0, 5) == 'BNAPI'
        ? (list[index]['is_assay'] = true)
        : (list[index]['is_assay'] = false);
    }

    list[index][param] = e;
    setData(list);
  };
  console.log(data);
  // const handleClick = () => {
  //   setData([
  //     ...data,
  //     { bacth_id: '', status: '', assay: '', load_water_content: '' }
  //   ]);
  // };
  console.log(samplings);
  const onFinish = () => {
    setTruckShow(true);
    const payload = {
      batch_list: data
    };
    dispatch(updateQCapprove(payload)).then(function (response) {
      if (response?.payload?.success) {
        setData([
          { bacth_id: '', status: '', assay: '', load_water_content: '' }
        ]);
        form.resetFields();
        setTruckShow(false);
        message.success(response?.payload?.message);
        history.push('/batches-list?type=batches');
      } else {
        setTruckShow(false);
      }
    });
  };

  const handleQA = () => {
    setIsModal(true);
  };
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      // onFinishFailed={onFinishFailed}
    >
      <Card
        title="QC APPROVAL"
        className={styles.CustomPanel}
        extra={
          <CustomButton onClick={() => setIsEdit(true)}>
            <EditOutlined />
          </CustomButton>
        }
      >
        {data?.length
          ? data?.map((item, index) => {
              return (
                <>
                  <Card className={styles.CustomPanel} key={index}>
                    <Row gutter={24} key={index}>
                      <Col xs={24} xl={8} span={12} md={24} sm={24}>
                        <FormItem
                          label="Inhouse Batch Number"
                          rules={[
                            {
                              required: false,
                              message: 'Select In house batch number'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Input value={batch} disabled />
                          {/* <Select
                            showSearch
                            placeholder="Select In house batch number"
                            optionFilterProp="children"
                            onChange={(e) => onChange(index, 'bacth_id', e)}
                            onSearch={onSearch}
                          >
                            {batchdetails?.map((item, index) => {
                              return (
                                <option value={item.id} key={index}>
                                  {item.inhouse_batch_number}
                                </option>
                              );
                            })}
                          </Select> */}
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={8} span={12} md={24} sm={24}>
                        <FormItem
                          label="Status"
                          rules={[
                            {
                              required: false,
                              message: 'Enter name of the material'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          {samplings?.status && !isEdit ? (
                            <Input
                              placeholder="Enter assay"
                              disabled
                              value={samplings?.status || ''}
                            />
                          ) : (
                            <Select
                              showSearch
                              placeholder="Select status"
                              defaultValue={samplings?.status}
                              // optionFilterProp="children"
                              onChange={(e) => onChange(index, 'status', e)}
                            >
                              <option value="Quarantine">Quarantine</option>
                              <option value="Approved">Approved</option>
                              <option value="Rejected">Rejected</option>
                              <option value="Partially ID Approved">
                                Partially ID Approved
                              </option>
                            </Select>
                          )}
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={4} span={12} md={24} sm={24}>
                        <FormItem
                          label="Retest Date"
                          // name="inhouse_retest_date"
                          rules={[
                            {
                              required: false,
                              message: 'Select Retest Date'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          {samplings?.inhouse_retest_date && !isEdit ? (
                            <Input
                              placeholder="Enter retest date"
                              disabled
                              value={
                                samplings?.inhouse_retest_date
                                  ? moment(
                                      samplings?.inhouse_retest_date
                                    ).format('DD-MM-YYYY')
                                  : ''
                              }
                            />
                          ) : (
                            <DatePicker
                              style={{ width: '100%' }}
                              onChange={(e) =>
                                onChange(index, 'inhouse_retest_date', e)
                              }
                              // value={samplings?.inhouse_retest_date}
                              // disabled={searchParams.get('id') ? isEdit : false}
                            />
                          )}
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={4} span={12} md={24} sm={24}>
                        <FormItem
                          label="Expiry Date"
                          // name="inhouse_retest_date"
                          rules={[
                            {
                              required: false,
                              message: 'Select Retest Date'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          {samplings?.qc_expiry_date && !isEdit ? (
                            <Input
                              placeholder="Enter Expiry date"
                              disabled
                              value={
                                samplings?.qc_expiry_date
                                  ? moment(samplings?.qc_expiry_date).format(
                                      'DD-MM-YYYY'
                                    )
                                  : ''
                              }
                            />
                          ) : (
                            <DatePicker
                              style={{ width: '100%' }}
                              onChange={(e) =>
                                onChange(index, 'qc_expiry_date', e)
                              }
                              // value={samplings?.inhouse_retest_date}
                              // disabled={searchParams.get('id') ? isEdit : false}
                            />
                          )}
                        </FormItem>
                      </Col>

                      {/* {index == 0 ? (
                        <Col xs={6} xl={2} span={12} md={24} sm={24}>
                          <CustomButton 
                            loading={loading}
                            onClick={handleClick}
                            style={{ marginTop: '23px' }}
                          >
                            <PlusOutlined />
                          </CustomButton >
                        </Col>
                      ) : (
                        ''
                      )} */}
                      {item?.is_assay ? (
                        <>
                          {samplings?.assay && !isEdit ? (
                            <Col xs={20} xl={8} span={12} md={24} sm={24}>
                              <FormItem
                                label="Assay(%)"
                                rules={[
                                  {
                                    required: false,
                                    message: 'Enter name of the material'
                                  }
                                ]}
                                className={styles.customLable}
                                onChange={(e) =>
                                  onChange(index, 'assay', e.target.value)
                                }
                              >
                                <Input
                                  placeholder="Enter assay"
                                  disabled
                                  value={data?.[0]?.assay || ''}
                                />
                              </FormItem>
                            </Col>
                          ) : (
                            <Col xs={20} xl={8} span={12} md={24} sm={24}>
                              <FormItem
                                label="Assay (%)"
                                rules={[
                                  {
                                    required: false,
                                    message: 'Enter name of the material'
                                  }
                                ]}
                                className={styles.customLable}
                                onChange={(e) =>
                                  onChange(index, 'assay', e.target.value)
                                }
                              >
                                <Input
                                  placeholder="Enter assay"
                                  value={data?.[0]?.assay || ''}
                                />
                              </FormItem>
                            </Col>
                          )}

                          {/* <Col
                            xs={4}
                            xl={2}
                            span={12}
                            md={24}
                            sm={24}
                            className={styles.qr}
                          >
                            <Input
                              disabled
                              value={'%'}
                              style={{ textAlign: 'center' }}
                            />
                          </Col> */}
                          {samplings?.lod_water_content && !isEdit ? (
                            <Col xs={20} xl={8} span={12} md={24} sm={24}>
                              <FormItem
                                label="LOD or Water Content (%)"
                                rules={[
                                  {
                                    required: false,
                                    message: 'Enter LOD or Water Content'
                                  }
                                ]}
                                className={styles.customLable}
                                onChange={(e) =>
                                  onChange(
                                    index,
                                    'load_water_content',
                                    e.target.value
                                  )
                                }
                              >
                                <Input
                                  placeholder="Enter LOD or Water content"
                                  value={data?.[0]?.load_water_content || ''}
                                  disabled
                                />
                              </FormItem>
                            </Col>
                          ) : (
                            <Col xs={20} xl={8} span={12} md={24} sm={24}>
                              <FormItem
                                label="LOD or Water Content (%)"
                                rules={[
                                  {
                                    required: false,
                                    message: 'Enter LOD or Water Content'
                                  }
                                ]}
                                className={styles.customLable}
                                onChange={(e) =>
                                  onChange(
                                    index,
                                    'load_water_content',
                                    e.target.value
                                  )
                                }
                              >
                                <Input
                                  placeholder="Enter LOD or Water content"
                                  value={data?.[0]?.load_water_content || ''}
                                />
                              </FormItem>
                            </Col>
                          )}
                          {/* <Col
                            xs={4}
                            xl={2}
                            span={12}
                            md={24}
                            sm={24}
                            className={styles.qr}
                          >
                            <Input
                              disabled
                              value={'%'}
                              style={{ textAlign: 'center' }}
                            />
                          </Col> */}
                          {samplings?.lod_water_content && !isEdit ? (
                            <Col xs={20} xl={8} span={12} md={24} sm={24}>
                              <FormItem
                                label="Solvent (%)"
                                rules={[
                                  {
                                    required: false,
                                    message: 'Enter Solvent'
                                  }
                                ]}
                                className={styles.customLable}
                                onChange={(e) =>
                                  onChange(
                                    index,
                                    'solvent_percent',
                                    e.target.value
                                  )
                                }
                              >
                                <Input
                                  placeholder="Enter Solvent"
                                  value={data?.[0]?.solvent_percent || ''}
                                  disabled
                                />
                              </FormItem>
                            </Col>
                          ) : (
                            <Col xs={20} xl={8} span={12} md={24} sm={24}>
                              <FormItem
                                label="Solvent (%)"
                                rules={[
                                  {
                                    required: false,
                                    message: 'Enter Solvent'
                                  }
                                ]}
                                className={styles.customLable}
                                onChange={(e) =>
                                  onChange(
                                    index,
                                    'solvent_percent',
                                    e.target.value
                                  )
                                }
                              >
                                <Input
                                  placeholder="Enter Solvent"
                                  value={data?.[0]?.solvent_percent || ''}
                                />
                              </FormItem>
                            </Col>
                          )}
                          {/* <Col
                            xs={4}
                            xl={2}
                            span={12}
                            md={24}
                            sm={24}
                            className={styles.qr}
                          >
                            <Input
                              disabled
                              value={'%'}
                              style={{ textAlign: 'center' }}
                            />
                          </Col> */}
                        </>
                      ) : (
                        ''
                      )}
                    </Row>
                  </Card>
                  <br />
                </>
              );
            })
          : ''}
      </Card>
      <br />
      <FormItem className="text-center">
        <Space>
          <CustomButton
            type="primary"
            onClick={() => history.back()}
            loading={loading}
            ghost
          >
            Cancel
          </CustomButton>
          {truckShow ? (
            <Image
              src={truck}
              preview={false}
              style={{ width: '100px !important', height: '100px' }}
            />
          ) : samplings?.inhouse_retest_date || samplings?.expiry_date ? (
            <CustomButton
              type="primary"
              onClick={() => handleQA()}
              loading={loading}
            >
              Initiate Verification
            </CustomButton>
          ) : (
            <CustomButton type="primary" htmlType="submit" loading={loading}>
              Update
            </CustomButton>
          )}
        </Space>
      </FormItem>
      {isModal ? (
        <VerificationModal
          isModal={isModal}
          materials={data}
          setIsModal={setIsModal}
          setIsEdit={setIsEdit}
          page="BatchApproval"
        />
      ) : (
        ''
      )}
    </Form>
  );
};
export default QCApprovalPage;
