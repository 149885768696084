import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  users,
  createuser,
  updateuser,
  deleteuser,
  designations,
  createdesignations,
  createdepartments,
  departments
} from './api';

const initialState = {
  allUsers: [],
  pagination: {},
  user: {},
  getAllMaterilLoading: false,
  allDisignations: [],
  allDepartments: []
};

const actions = {
  GET_ALL_USERS: 'admin/GET_ALL_USERS',
  GET_USER_BY_ID: 'admin/GET_USER_BY_ID',
  USER_CREATE: 'admin/USER_CREATE',
  UPDATE_USER: 'admin/UPDATE_USER',
  DELETE_USER: 'admin/DELETE_USER',
  GET_ALL_DESIGNATIONS: 'admin/GET_ALL_DESIGNATIONS',
  CREATE_DESIGNATIONS: 'admin/CREATE_DESIGNATIONS',
  CREATE_DEPARTMENTS: 'admin/CREATE_DEPARTMENTS',
  GET_ALL_DEPARTMENTS: 'admin/GET_ALL_DEPARTMENTS'
};

export const getAllUsers = createAsyncThunk(
  actions.GET_ALL_USERS,
  async (payload) => {
    const response = await users('user-list', payload);

    return response;
  }
);

export const getUserById = createAsyncThunk(
  actions.GET_USER_BY_ID,
  async (payload) => {
    const response = await users('user-details', payload);

    return response;
  }
);

export const UserCreate = createAsyncThunk(
  actions.USER_CREATE,
  async (payload) => {
    const response = await createuser('user-create', payload);

    return response;
  }
);

export const updateUser = createAsyncThunk(
  actions.UPDATE_USER,
  async (payload) => {
    const response = await updateuser(
      `user-update?user_id=${payload?.user_id}`,
      payload
    );

    return response;
  }
);

export const deleteUser = createAsyncThunk(
  actions.DELETE_USER,
  async (payload) => {
    const response = await deleteuser(
      `user-delete?user_id=${payload?.id}`,
      payload
    );

    return response;
  }
);

export const getAllDesignations = createAsyncThunk(
  actions.GET_ALL_DESIGNATIONS,
  async (payload) => {
    const response = await designations(`designation_list`, payload);

    return response;
  }
);

export const createDesignation = createAsyncThunk(
  actions.CREATE_DESIGNATIONS,
  async (payload) => {
    const response = await createdesignations(`create_designation`, payload);

    return response;
  }
);

export const createDepartment = createAsyncThunk(
  actions.CREATE_DEPARTMENTS,
  async (payload) => {
    const response = await createdepartments(`create_departments`, payload);

    return response;
  }
);

export const getAllDepartments = createAsyncThunk(
  actions.GET_ALL_DEPARTMENTS,
  async (payload) => {
    const response = await departments(`department_list`, payload);

    return response;
  }
);

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, pagination } = action.payload;
        if (success) {
          state.allUsers = data;
          state.pagination = pagination || null;
        } else {
          state.allUsers = [];
        }
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getUserById.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getUserById.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data } = action.payload;
        if (success) {
          state.user = data;
        } else {
          state.user = {};
        }
      })
      .addCase(getUserById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(UserCreate.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(UserCreate.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(UserCreate.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(updateUser.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(updateUser.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(deleteUser.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(deleteUser.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllDesignations.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllDesignations.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, pagination } = action.payload;
        if (success) {
          state.allDisignations = data;
          state.pagination = pagination || null;
        } else {
          state.allDisignations = [];
        }
      })
      .addCase(getAllDesignations.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createDesignation.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createDesignation.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createDesignation.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createDepartment.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createDepartment.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createDepartment.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllDepartments.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllDepartments.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, pagination } = action.payload;
        if (success) {
          state.allDepartments = data;
          state.pagination = pagination || null;
        } else {
          state.allDepartments = [];
        }
      })
      .addCase(getAllDepartments.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
  }
});
export default adminSlice.reducer;
