import React, { useState, useEffect } from 'react';
import { Form, Card, Space, Input, Tag } from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomTable from 'common/Content/CustomTable';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import { useDispatch } from 'react-redux';
import { getAlllclist } from '../../redux/slice';
import Layout from '@/common/Layout';
import { useParams } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import { getLoggedInUser, getRoles } from 'utilities/helpers';

const LineClearenceList = ({ pagination }) => {
  const [form] = Form.useForm();
  const params = useParams();

  const dispatch = useDispatch();

  const { Search } = Input;

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [LCData, setLCData] = useState([]);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    handleGetAllLCList();
  }, [page, search]);

  const user = getLoggedInUser();
  const { SuperadminProddata } = getRoles('Production');

  const handleGetAllLCList = () => {
    const payload = { dispensing_request_id: params.id, page, search };
    dispatch(getAlllclist(payload)).then((res) => {
      setLCData(res?.payload?.data);
    });
  };

  const column1 = [
    {
      title: 'Equipment Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return record?.eqp_lc_master?.line_clr_eqp_name;
      }
    },
    {
      title: `Equipemnt ID`,
      dataIndex: 'permission',
      key: 'permission',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return record?.eqp_id_lc_master?.lc_eqp_id;
      }
    },
    {
      title: `Current Product`,
      dataIndex: 'current_product',
      key: 'current_product',
      ellipsis: true,
      width: '100px'
    },
    {
      title: `Current Batch`,
      dataIndex: 'current_batch',
      key: 'current_batch',
      ellipsis: true,
      width: '100px'
    },
    {
      title: `Status`,
      dataIndex: 'lc_status',
      key: 'lc_status',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return (
          <Tag
            color={
              record?.lc_status === 'QA LC Pending'
                ? '#ff9933'
                : record?.lc_status === 'Approved'
                ? '#28a745'
                : '#007bff'
            }
          >
            {record?.lc_status}
          </Tag>
        );
      }
    },
    {
      title: `Action`,
      dataIndex: 'current_batch',
      key: 'current_batch',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return (
          <a href={`/qa-lineclearence/${params.id}?id=${record.id}`}>
            <EyeOutlined
            // onClick={() => handleView(record?.designation, record?.id)}
            />
          </a>
        );
      }
    }
  ];

  return (
    <>
      <Layout sider={false}>
        <Form form={form} layout="vertical">
          <Card
            title={<span>Line Clearence Checklist</span>}
            className={`${styles.cardStyles_approver}`}
            extra={
              <Space>
                <Search
                  onChange={(e) => handleChange(e)}
                  className={`w-100 ${styles.searchbar}`}
                  justify="start"
                  align="start"
                  placeholder="Search by Equip ID or Name"
                />
                {user?.user?.role == 'prod_user' ||
                SuperadminProddata?.[0]?.level == 2 ? (
                  <CustomButton
                    className={styles.inwardButton}
                    onClick={() =>
                      history.push(`/qa-lineclearence/${params.id}`)
                    }
                    type="primary"
                  >
                    Create
                  </CustomButton>
                ) : (
                  ''
                )}
              </Space>
            }
          >
            <div className={`${styles.table_container}`}>
              <CustomTable
                columns={column1}
                dataSource={LCData}
                pagination={{
                  size: 'small',
                  total: pagination?.total_records || 0,
                  showSizeChanger: false,
                  onChange: (page) => {
                    setPage(page);
                  }
                }}
              />
            </div>
          </Card>

          <FormItem className="text-center">
            <CustomButton
              className={styles.inwardButton}
              onClick={() => history.back()}
              type="primary"
            >
              Back to Home
            </CustomButton>
          </FormItem>
        </Form>
      </Layout>
    </>
  );
};
export default LineClearenceList;
