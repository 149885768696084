import React, { useState } from 'react';
import {
  Row,
  Col,
  Form,
  Card,
  // Input,
  Space,
  Select,
  // message,
  Image,
  Input,
  Radio,
  DatePicker
  // Radio
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import truck from '@/features/qa/components/images/truck-delivery-done.gif';
import Layout from '@/common/Layout';
import { useCreateSolventTransferPumpMutation } from '../../redux/apiSlice';
import moment from 'moment';
// import LiveTimeInput from '@/common/Clock';
import CustomModal from '@/common/CustomModal';

import LocalStorage from 'utilities/localStorage';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
const user = LocalStorage.getItem('Gravity_user');
// console.log('user', user?.user?.employee);

const commercialFinishedProduct = () => {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  // #region Initial states - Start
  const [truckShow] = useState(false);

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [products, setProducts] = useState([
    {
      date_of_receipt: '',
      fgtn: '',
      no_of_containers: '',
      pack_size_tablets: '',
      total_no_of_shippers: '',
      total_received_batch_qty: '',
      user_id_date: '',
      remarks: ''
    }
  ]);

  const [isModal, setIsModal] = useState(false);

  let [createSolventTransferPump] = useCreateSolventTransferPumpMutation();

  const handleAdd = () => {
    let list = [...products];
    list.push({
      date_of_receipt: '',
      fgtn: '',
      no_of_containers: '',
      pack_size_tablets: '',
      total_no_of_shippers: '',
      total_received_batch_qty: '',
      user_id_date: '',
      remarks: ''
    });
    setProducts(list);
  };

  const handleRemove = (index) => {
    let list = [...products];
    list.splice(index, 1);
    setProducts(list);
  };

  const onFinishModal = async () => {};
  const handleClose = () => {
    setIsModal(false);
  };
  const onFinish = async (values) => {
    setSubmitButtonDisabled(true);

    const values2 = { ...values };
    values2.cleaned_by_user_date = moment(new Date()).format();
    values2.date = moment(new Date()).format();
    // values2.dpb_or_ihpb = dpbOrIhpbDefaultValue;

    const response = await createSolventTransferPump({
      ...values2
    });
    if (response?.data?.success === true) {
      // handleShowMessage('success', response?.data?.message);
      setTimeout(
        () => history.push('/log-books-solvent-transfer-pump-list'),
        1500
      );
    } else {
      setSubmitButtonDisabled(false);
      // handleShowMessage('error', response?.error?.data?.message);
    }
  };
  // #endregion

  // #region All onChange events handler - Start

  return (
    <Layout sider={false}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          cleaned_by_user_id: user?.user?.employee,
          display_cleaned_by: `${user?.user?.employee} (${moment(
            new Date()
          ).format('DD-MM-YY')})`
        }}
      >
        <Card
          title={'COMMERCIAL FINISHED PRODUCTS RECEIPT REGISTER'}
          className={styles.CustomPanel}
        >
          <Space direction="vertical">
            <Card className={styles.CustomPanel} title="Receipt Details">
              <Card className={styles.customLable11}>
                <Row gutter={24}>
                  <Col xs={24} xl={8} span={12} md={24} sm={24}>
                    <FormItem
                      label="Name of the Product"
                      name="product_name"
                      rules={[
                        {
                          required: true,
                          message: 'Select Equipment Id'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Select
                        placeholder="Select Equipment Id"
                        showSearch
                        optionFilterProp="children"
                        // onSearch={(e) => onSearch(e, 'equipment')}
                      ></Select>
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={8} span={12} md={24} sm={24}>
                    <FormItem
                      label="Product Code"
                      name="product_code"
                      rules={[
                        {
                          required: true,
                          message: 'Select Equipment Id'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Select
                        placeholder="Select Equipment Id"
                        showSearch
                        optionFilterProp="children"
                        // onSearch={(e) => onSearch(e, 'equipment')}
                      ></Select>
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={8} span={12} md={24} sm={24}>
                    <FormItem
                      label="Product Batch"
                      name="product_batch"
                      rules={[
                        {
                          required: true,
                          message: 'Select Equipment Id'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input placeholder="Product Batch" />
                    </FormItem>
                  </Col>
                </Row>
              </Card>
              <Card className={styles.customLable11}>
                <Row gutter={24}>
                  <Col xs={24} xl={8} span={12} md={24} sm={24}>
                    <FormItem
                      label="Market"
                      name="market"
                      rules={[
                        {
                          required: false,
                          message: 'Select Dispatch Date'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input placeholder="Enter Market" />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={8} span={12} md={24} sm={24}>
                    <FormItem
                      label="UOM"
                      name="uom"
                      rules={[
                        {
                          required: true,
                          message: 'Select Equipment Id'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input placeholder="Enter UOM" />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={8} span={12} md={24} sm={24}>
                    <FormItem
                      label="Pack style"
                      name="pack_style"
                      rules={[
                        {
                          required: true,
                          message: 'Select Equipment Id'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Radio.Group>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="MFG Date"
                      name="mfg_date"
                      rules={[
                        {
                          required: true,
                          message: 'Select Dispatch Date'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <DatePicker
                        showTime={{
                          format: 'HH:mm'
                        }}
                        placeholder="Select date & time"
                        style={{ width: '100%' }}
                        name="removal_from_cold_store"
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="Expiry Date"
                      name="expiry_date"
                      rules={[
                        {
                          required: true,
                          message: 'Select Dispatch Date'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <DatePicker
                        showTime={{
                          format: 'HH:mm'
                        }}
                        placeholder="Select date & time"
                        style={{ width: '100%' }}
                        name="removal_from_cold_store"
                      />
                    </FormItem>
                  </Col>
                </Row>
              </Card>
            </Card>

            <br />
            {products?.map((product, index) => (
              <Card
                className={styles.customLable11}
                extra={
                  index === 0 ? (
                    <PlusCircleOutlined onClick={() => handleAdd()} />
                  ) : (
                    <DeleteOutlined onClick={() => handleRemove(index)} />
                  )
                }
                key={index}
              >
                <Row gutter={24}>
                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="Date of Receipt"
                      // name="dispatch_date"
                      rules={[
                        {
                          required: false,
                          message: 'Select Dispatch Date'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <DatePicker
                        showTime={{
                          format: 'HH:mm'
                        }}
                        placeholder="Select date & time"
                        style={{ width: '100%' }}
                        // name="removal_from_cold_store"
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="FGTN #"
                      rules={[
                        {
                          required: true,
                          message: 'Select Equipment Id'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input placeholder="Enter FGTN #" />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="No. of Containers/Cartons"
                      // name="container_number"
                      rules={[
                        {
                          required: true,
                          message: 'Select Equipment Id'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input placeholder="No.of Containers" />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="Pack size Tablets/Capsules"
                      rules={[
                        {
                          required: true,
                          message: 'Select Equipment Id'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input placeholder="Enter pack Size" />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="Total number of Shippers Received"
                      rules={[
                        {
                          required: true,
                          message: 'Select Equipment Id'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input placeholder="Enter Total no.of Shippers" />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="Total Received Batch Quantity"
                      rules={[
                        {
                          required: true,
                          message: 'Select Equipment Id'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input placeholder="Enter Total no.of received batch" />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="Received by User ID & Date"
                      rules={[
                        {
                          required: true,
                          message: 'Select Equipment Id'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input placeholder="Enter User ID & Date" />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="Remarks"
                      rules={[
                        {
                          required: true,
                          message: 'Select Equipment Id'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input placeholder="Enter remarks" />
                    </FormItem>
                  </Col>
                </Row>
              </Card>
            ))}
          </Space>
        </Card>
        <br />
        <FormItem className="text-center">
          <Space>
            {truckShow ? (
              <Image
                src={truck}
                preview={false}
                style={{ width: '100px !important', height: '100px' }}
              />
            ) : (
              <CustomButton
                className={styles.inwardButton}
                htmlType="submit"
                type="primary"
                onSubmit={(e) => onFinish(e)}
                disabled={submitButtonDisabled}
              >
                Create
              </CustomButton>
            )}
            <CustomButton
              className={styles.inwardButton}
              type="primary"
              ghost
              onClick={() => history.back()}
            >
              Back
            </CustomButton>
          </Space>
        </FormItem>
        {isModal ? (
          <CustomModal
            title=""
            visible={isModal}
            width="50%"
            footer={null}
            className={styles.customModal}
            closable={false}
          >
            <div className={`${styles.table_row}`}>
              <Form form={form2} layout="vertical" onFinish={onFinishModal}>
                <Card
                  title={'Initiate Verification'}
                  className={styles.CustomPanel}
                >
                  <Row gutter={24}>
                    <Col xs={24} xl={12} span={24} md={24} sm={24}>
                      <FormItem
                        label="Verified By (User id)"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: 'Enter Verifier User Id'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input placeholder="Enter Verifier User Id" />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={12} span={24} md={24} sm={24}>
                      <FormItem
                        label="Password"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: 'Enter Password'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input.Password placeholder="Enter Password" />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={12} span={24} md={24} sm={24}>
                      <FormItem
                        label="Remarks"
                        name="remarks2"
                        className={styles.customLable}
                      >
                        <Input.TextArea placeholder="Enter remarks" />
                      </FormItem>
                    </Col>
                  </Row>
                </Card>

                <br />
                <FormItem className="text-center">
                  <Space>
                    <CustomButton
                      className={styles.inwardButton}
                      htmlType="submit"
                      type="primary"
                      size="medium"
                      // disabled={isModalSubmitDisabled}
                    >
                      Submit
                    </CustomButton>
                    <CustomButton
                      className={styles.inwardButton}
                      onClick={handleClose}
                      type="primary"
                      ghost
                      size="medium"
                    >
                      Close
                    </CustomButton>
                  </Space>
                </FormItem>
              </Form>
            </div>
          </CustomModal>
        ) : (
          ''
        )}
      </Form>
    </Layout>
  );
};
export default commercialFinishedProduct;
