import apiClient from 'utilities/apiClient';

export const batches = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.batch}/${url}`, payload, true);
};

export const qcApprove = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.qc}/${url}`, payload, true);
};

export const dispensingList = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.dispense}/${url}`, payload, true);
};

export const updateDispensing = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.dispense}/${url}`, payload, true);
};

export const crete_testForm = (url, payload) => {
  return payload?.type == 'regenerate'
    ? apiClient.post(`${apiClient.Urls.trf}/${url}`, payload, true)
    : payload?.trf_id
    ? apiClient.patch(`${apiClient.Urls.trf}/${url}`, payload, true)
    : apiClient.post(`${apiClient.Urls.trf}/${url}`, payload, true);
};
