import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  materials,
  qc_doc_damage,
  qc_storage_damage,
  qc_damage_report,
  qc_discrepency_report,
  lcMaterialDetails,
  suppliers,
  create_vendor,
  update_vendor,
  dispense,
  batchLog,
  getbatchLog,
  printLogs,
  cloneprod,
  create_material_type
} from './api';

const initialState = {
  lcMaterialDetails: {},
  allqcMaterials: [],
  pagination: [],
  getAllMaterilLoading: null,
  allMaterials: [],
  batchlogs: []
};

const actions = {
  GET_ALL_QC_MATERIALS: 'qc/GET_ALL_MATERIALS',
  DOC_QC: 'qc/DOC_QC',
  STORAGE_QC: 'qc/STORAGE_QC',
  DAMAGE_QC: 'qc/DAMAGE_QC',
  DISCREPENCY_QC: 'qc/DISCREPENCY_QC',
  GET_LC_MATERIAL_DETAILS: 'lc/GET_LC_MATERIAL_DETAILS',
  GET_ALL_SUPPLIERS: 'lc/GET_ALL_SUPPLIERS',
  GET_ALL_MATERIALS: 'lc/GET_ALL_MATERIALS',
  CREATE_VENDORS: 'lc/CREATE_VENDORS',
  GET_ALL_APPROVED_MATERIALS: 'lc/GET_ALL_APPROVED_MATERIALS',
  CREATE_MATERIAL: 'lc/CREATE_MATERIAL',
  UPDATE_VENDOR: 'lc/UPDATE_VENDOR',
  GET_ALL_PRODUCTS: 'lc/GET_ALL_PRODUCTS',
  CREATE_LOG: 'lc/CREATE_LOG',
  GET_LOGS: 'lc/GET_LOGS',
  PRINT_LOGS: 'lc/PRINT_LOGS',
  CLONE_PROD: 'lc/CLONE_PROD',
  GET_ALL_UOMS: 'lc/GET_ALL_UOMS',
  CREATE_MATERIAL_TYPE: 'lc/CREATE_MATERIAL_TYPE'
};

export const getAllQCMaterials = createAsyncThunk(
  actions.GET_ALL_QC_MATERIALS,
  async (payload) => {
    const response = await materials('all', payload);

    return response;
  }
);

export const getAllMaterials = createAsyncThunk(
  actions.GET_ALL_MATERIALS,
  async (payload) => {
    const response = await suppliers('all', payload);

    return response;
  }
);

export const getAllMaterialsTypes = createAsyncThunk(
  actions.GET_ALL_MATERIALS_TYPES,
  async (payload) => {
    const response = await suppliers('material_type_list', payload);

    return response;
  }
);
export const getAllApprovedMaterials = createAsyncThunk(
  actions.GET_ALL_APPROVED_MATERIALS,
  async (payload) => {
    const response = await suppliers('all-materials', payload);

    return response;
  }
);

export const document_qc = createAsyncThunk(actions.DOC_QC, async (payload) => {
  const response = await qc_doc_damage('report-document', payload);

  return response;
});
export const storage_qc = createAsyncThunk(
  actions.STORAGE_QC,
  async (payload) => {
    const response = await qc_storage_damage('report-storage', payload);

    return response;
  }
);
export const damage_report_qc = createAsyncThunk(
  actions.DAMAGE_QC,
  async (payload) => {
    const response = await qc_damage_report('report-damage', payload);

    return response;
  }
);
export const discrepency_report_qc = createAsyncThunk(
  actions.DISCREPENCY_QC,
  async (payload) => {
    const response = await qc_discrepency_report('report-discrepancy', payload);

    return response;
  }
);

export const getQcMaterialDetails = createAsyncThunk(
  actions.GET_LC_MATERIAL_DETAILS,
  async (payload) => {
    const response = await lcMaterialDetails('get-all', payload);
    // console.log('getDispenseMaterialDetails', response);
    return response;
  }
);

export const getAllSuppliers = createAsyncThunk(
  actions.GET_ALL_SUPPLIERS,
  async (payload) => {
    const response = await suppliers('all-suppliers', payload);
    // console.log('getDispenseMaterialDetails', response);
    return response;
  }
);

export const createVendor = createAsyncThunk(
  actions.CREATE_VENDORS,
  async (payload) => {
    const response = await create_vendor('create-vendor', payload);
    // console.log('getDispenseMaterialDetails', response);
    return response;
  }
);

export const createMaterial = createAsyncThunk(
  actions.CREATE_MATERIAL,
  async (payload) => {
    const response = await create_vendor('create', payload);
    // console.log('getDispenseMaterialDetails', response);
    return response;
  }
);

export const updateVendor = createAsyncThunk(
  actions.UPDATE_VENDOR,
  async (payload) => {
    const response = await update_vendor('accept-vendor', payload);
    // console.log('getDispenseMaterialDetails', response);
    return response;
  }
);

export const getAllProducts = createAsyncThunk(
  actions.GET_ALL_PRODUCTS,
  async (payload) => {
    const response = await dispense('product_list', payload);
    // console.log('getDispenseMaterialDetails', response);
    return response;
  }
);

export const createlog = createAsyncThunk(
  actions.CREATE_LOG,
  async (payload) => {
    const response = await batchLog('create_batchlog', payload);
    // console.log('getDispenseMaterialDetails', response);
    return response;
  }
);

export const getAllLogBooksdetails = createAsyncThunk(
  actions.GET_LOGS,
  async (payload) => {
    const response = await getbatchLog('get_batchlog', payload);
    // console.log('getDispenseMaterialDetails', response);
    return response;
  }
);

export const printLogDetails = createAsyncThunk(
  actions.PRINT_LOGS,
  async (payload) => {
    const response = await printLogs('print_batchlog', payload);
    // console.log('getDispenseMaterialDetails', response);
    return response;
  }
);

export const cloneProduct = createAsyncThunk(
  actions.CLONE_PROD,
  async (payload) => {
    const response = await cloneprod(
      `clone_product?product_id=${payload?.product_id}`,
      payload
    );
    // console.log('getDispenseMaterialDetails', response);
    return response;
  }
);

export const getAllUoms = createAsyncThunk(
  actions.GET_ALL_UOMS,
  async (payload) => {
    const response = await suppliers('all-materialuom', payload);

    return response;
  }
);

export const createMaterialType = createAsyncThunk(
  actions.CREATE_MATERIAL_TYPE,
  async (payload) => {
    const response = await create_material_type(
      payload?.type === 'type' ? 'type_create' : 'uom_create',
      payload
    );

    return response;
  }
);
export const qcSlice = createSlice({
  name: 'qc',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllQCMaterials.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllQCMaterials.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, pagination } = action.payload;
        if (success) {
          state.allqcMaterials = data;
          state.pagination = pagination || null;
        } else {
          state.allqcMaterials = [];
        }
      })
      .addCase(getAllQCMaterials.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(document_qc.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(document_qc.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }

        state.getAllMaterilLoading = false;
      })
      .addCase(document_qc.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(storage_qc.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(storage_qc.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
        state.getAllMaterilLoading = false;
      })
      .addCase(storage_qc.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(damage_report_qc.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(damage_report_qc.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
        state.getAllMaterilLoading = false;
      })
      .addCase(damage_report_qc.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(discrepency_report_qc.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(discrepency_report_qc.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
        state.getAllMaterilLoading = false;
      })
      .addCase(discrepency_report_qc.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getQcMaterialDetails.pending, (state) => {
        state.getMaterialDetailslLoading = true;
      })
      .addCase(getQcMaterialDetails.fulfilled, (state, action) => {
        console.log('check', action.payload);
        state.getMaterialDetailslLoading = false;

        const { success, data } = action.payload;

        if (success) {
          state.lcMaterialDetails = data;
        } else {
          state.lcMaterialDetails = '';
        }
      })
      .addCase(getQcMaterialDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getMaterialDetailslLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllSuppliers.pending, (state) => {
        state.getMaterialDetailslLoading = true;
      })
      .addCase(getAllSuppliers.fulfilled, (state, action) => {
        state.getMaterialDetailslLoading = false;

        const { success, data } = action.payload;

        if (success) {
          state.lcMaterialDetails = data;
        } else {
          state.lcMaterialDetails = '';
        }
      })
      .addCase(getAllSuppliers.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getMaterialDetailslLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllMaterials.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllMaterials.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, pagination } = action.payload;
        if (success) {
          state.allMaterials = data;
          state.pagination = pagination || null;
        } else {
          state.allMaterials = [];
        }
      })
      .addCase(getAllMaterials.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(createVendor.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createVendor.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { message: msg, success } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createVendor.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllApprovedMaterials.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllApprovedMaterials.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { message: msg, success } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllApprovedMaterials.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(createMaterial.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createMaterial.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { message: msg, success } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createMaterial.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(updateVendor.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(updateVendor.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { message: msg, success } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(updateVendor.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllProducts.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { message: msg, success } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllProducts.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createlog.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createlog.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { message: msg, success } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createlog.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllLogBooksdetails.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllLogBooksdetails.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { message: msg, success } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllLogBooksdetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(printLogDetails.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(printLogDetails.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { message: msg, success } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(printLogDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(cloneProduct.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(cloneProduct.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { message: msg, success } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(cloneProduct.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllUoms.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllUoms.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { message: msg, success } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllUoms.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createMaterialType.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createMaterialType.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { message: msg, success } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createMaterialType.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
  }
});
export default qcSlice.reducer;
