// import './App.css';

// import { useEffect } from 'react';

const LoginAuth = () => {
  //   let faceio;
  //   useEffect(() => {
  //     faceio = new faceIO('fioa0ce4');
  //   }, []);
  //   const handleRegister = async () => {
  //     try {
  //       let response = await faceio.enroll({
  //         locale: 'auto',
  //         payload: {
  //           email: 'example@gmail.com'
  //         }
  //       });
  //       console.log(` Unique Facial ID: ${response.facialId}
  //       Enrollment Date: ${response.timestamp}
  //       Gender: ${response.details.gender}
  //       Age Approximation: ${response.details.age}`);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   const authenticateUser = () => {
  //     // Authenticate a previously enrolled user
  //     faceio
  //       .authenticate({
  //         locale: 'auto' // Default user locale
  //       })
  //       .then((userData) => {
  //         console.log('Success, user identified');
  //         // Grab the facial ID linked to this particular user which will be same
  //         // for each of his successful future authentication. FACEIO recommend
  //         // that your rely on this Facial ID if you plan to uniquely identify
  //         // all enrolled users on your backend for example.
  //         console.log('Linked facial Id: ' + userData.facialId);
  //         // Grab the arbitrary data you have already linked (if any) to this particular user
  //         // during his enrollment via the payload parameter of the enroll() method.
  //         console.log('Payload: ' + JSON.stringify(userData.payload));
  //         // {"whoami": 123456, "email": "john.doe@example.com"} set via enroll()
  //       })
  //       .catch((errCode) => {
  //         console.log(errCode);
  //         // handle authentication failure. Visit:
  //         // https://faceio.net/integration-guide#error-codes
  //         // for the list of all possible error codes
  //       });
  //   };
  //   return (
  //     <section className="w-full h-screen flex flex-col items-center justify-center">
  //       <h1 className="font-sans font-bold text-xl mb-4">
  //         Face Authentication by FaceIO
  //       </h1>
  //       <div className="flex flex-col justify-center items-center">
  //         <button
  //           className="block px-4 py-2 outline-none bg-blue-500 rounded text-white mb-2"
  //           onClick={handleRegister}
  //         >
  //           register
  //         </button>
  //         <button onClick={authenticateUser}>Authenticate User</button>
  //       </div>
  //     </section>
  //   );
  // };
};
export default LoginAuth;
