import apiClient from 'utilities/apiClient';

export const document = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.document}/${url}`, payload, true);
};

export const create_batch = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.batch}/${url}`, payload, true);
};

export const create_damage = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.damaged}/${url}`, payload, true);
};

export const edit_damage = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.damaged}/${url}`, payload, true);
};
