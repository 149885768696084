import React, { useState, useEffect } from 'react';
import {
  Form,
  Card,
  Select,
  Row,
  Col,
  Input,
  InputNumber,
  Space,
  message,
  Image
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllMaterialslist,
  createReconsiliation,
  getAllReconsiliationbyid,
  updateReconsiliation,
  stockUpdation
} from '../../redux/slice';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import { getLoggedInUser, getRoles } from 'utilities/helpers';
import { useLocation } from 'react-router-dom';
import truck from '@/features/generateMrn/components/images/truck-delivery-done.gif';
import { EditOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const ReconsiliationRequestPage = ({ loading }) => {
  const [form] = Form.useForm();

  // const { allMaterials } = useSelector((state) => state.stocks);
  const { allMaterials, reconsData } = useSelector(
    (state) => state.reconciliation
  );

  const { user } = getLoggedInUser();
  const { Superadmindata } = getRoles('Warehouse');
  const searchParam = useLocation().search;
  const searchParams = new URLSearchParams(searchParam);

  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [code, setCode] = useState('');
  const [batches, setBatches] = useState([]);

  const [receivedQty, setReceivedQty] = useState(0);
  const [consumedQty, setConsumedQty] = useState(0);
  const [differenceQty, setDifferenceQty] = useState(0);
  const [variance, setVariance] = useState(0);
  const [batchid, setBatchid] = useState('');
  const [truckShow, setTruckShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [reconData, setReconData] = useState({});
  const [comments, setComments] = useState('');

  useEffect(() => {
    handleGetAllMaterialsList();
    setReconData(reconsData);
  }, [search, code]);

  const handleGetAllMaterialsList = () => {
    let payload = { search };
    (user?.role == 'qc_initiator' ||
      (user?.role == 'user' && user?.level == 3) ||
      user?.role == 'qa_super_user') &&
    searchParams.get('id') !== ''
      ? dispatch(getAllReconsiliationbyid({ id: searchParams.get('id') })).then(
          (res) => {
            if (res?.payload?.success) {
              console.log('mmm');
              setReconData(res?.payload?.data);
            }
          }
        )
      : dispatch(getAllMaterialslist(payload));
  };

  useEffect(() => {
    form.setFieldsValue({
      received_qty_as_per_mrn: reconData?.received_qty_as_per_mrn,
      consumed_qty_as_per_stock_card: reconData?.consumed_qty_as_per_stock_card,
      comments: reconData?.comments
    });
  }, [reconData]);
  const onChange = (value) => {
    const material = allMaterials.filter(
      (MaterialsList) => MaterialsList.id === value
    );

    let payload = { search: material[0].code };
    dispatch(getAllMaterialslist(payload)).then((response) => {
      if (response?.payload?.success) {
        setBatches(response?.payload?.data[0]?.batch_details);
        dispatch(getAllMaterialslist());
      }
    });
    form.setFieldsValue({ supplier_id: null });
    setCode(material[0]);
  };
  console.log(allMaterials);
  const onSearch = (value) => {
    setSearch(value);
  };

  const onChangeBatch = (value) => {
    const batches_details =
      value == 'all'
        ? allMaterials[0]?.batch_details
        : batches.filter((batches) => batches.id === value);
    setBatches(batches_details);
    setBatchid(value);
  };
  const onSearchBatch = (value) => {
    const batches_details = batches.inhouse_batch_number
      .toLowerCase()
      .includes(value.toLowerCase());
    setBatches(batches_details);
  };

  useEffect(() => {
    setDifferenceQty(
      (consumedQty
        ? parseFloat(consumedQty)
        : parseFloat(reconData?.consumed_qty_as_per_stock_card)) -
        (receivedQty
          ? parseFloat(receivedQty)
          : parseFloat(reconData?.received_qty_as_per_mrn))
    );
    setVariance(
      (((consumedQty
        ? parseFloat(consumedQty)
        : parseFloat(reconData?.consumed_qty_as_per_stock_card)) -
        (receivedQty
          ? parseFloat(receivedQty)
          : parseFloat(reconData?.received_qty_as_per_mrn))) /
        (receivedQty
          ? parseFloat(receivedQty)
          : parseFloat(reconData?.received_qty_as_per_mrn))) *
        100
    );
    // const list = { ...reconData };

    // list.difference_qty = differenceQty;
    // list.variance = variance;
    // setReconData(list);
  }, [receivedQty, consumedQty]);
  console.log(reconData);
  const handleReceivedQty = (e) => {
    setReceivedQty(e);
  };
  const handleConsumedQty = (e) => {
    setConsumedQty(e);
  };

  const onFinish = (values) => {
    setTruckShow(true);
    const payload = {
      received_qty_as_per_mrn: receivedQty,
      consumed_qty_as_per_stock_card: consumedQty,
      difference_qty: differenceQty,
      variance: variance,
      comments: values?.comments || '',
      material_id: code?.id,
      batch_details_id: batchid
    };
    dispatch(createReconsiliation(payload)).then((response) => {
      if (response?.payload?.success) {
        setTruckShow(false);
        message.success(response?.payload?.message);
        history.push('/stock-reconsilation');
      } else {
        setTruckShow(false);
      }
    });
  };

  const handleUpadateform = () => {
    const payload = {
      id: searchParams?.get('id'),
      received_qty_as_per_mrn: receivedQty
        ? receivedQty
        : reconData?.received_qty_as_per_mrn,
      consumed_qty_as_per_stock_card: consumedQty
        ? consumedQty
        : reconData?.consumed_qty_as_per_stock_card,
      difference_qty: differenceQty ? differenceQty : reconData?.difference_qty,
      variance: variance ? variance : reconData?.variance,
      comments: comments
    };
    dispatch(stockUpdation(payload)).then((response) => {
      if (response?.payload?.success) {
        setTruckShow(false);
        message.success(response?.payload?.message);
        history.push('/stock-reconsilation');
      }
    });
  };
  const handleUpdateStatus = (value) => {
    setTruckShow(true);
    const payload = { id: searchParams.get('id'), status: value };
    dispatch(updateReconsiliation(payload)).then((response) => {
      if (response?.payload?.success) {
        setTruckShow(false);
        message.success(response?.payload?.message);
        history.push('/stock-reconsilation');
      }
    });
  };
  console.log(reconData, 'lll');
  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Card
        title={
          <span>
            {user?.role == 'qc_initiator' || user?.role == 'qa_super_user'
              ? 'STOCK RECONCILIATION - QA APPROVAL'
              : 'STOCK RECONCILIATION'}
          </span>
        }
        className={`${styles.cardStyles_approver}`}
        extra={
          (user?.role == 'user' && user?.level == 3) ||
          Superadmindata?.[0]?.level == 3 ? (
            <EditOutlined onClick={() => setIsEdit(true)} />
          ) : (
            ''
          )
        }
      >
        <Row gutter={24}>
          <Col xs={24} xl={6} span={12} md={24} sm={24}>
            {user?.role == 'qc_initiator' ||
            (user?.role == 'user' && user?.level == 3) ||
            user?.role == 'qa_super_user' ? (
              <FormItem
                label="Material Code"
                rules={[
                  {
                    required: true,
                    message: 'Select Material Code'
                  }
                ]}
                className={styles.customLable}
              >
                <Input value={reconsData?.material?.code} disabled />
              </FormItem>
            ) : (
              <FormItem
                label="Material Code"
                name="material_id"
                rules={[
                  {
                    required: true,
                    message: 'Select Material Code'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  showSearch
                  placeholder="Select a material code"
                  optionFilterProp="children"
                  onChange={onChange}
                  onSearch={onSearch}
                >
                  {allMaterials?.map((item, index) => {
                    return (
                      <option value={item.id} key={index}>
                        {item.code}
                      </option>
                    );
                  })}
                </Select>
              </FormItem>
            )}
          </Col>
          <Col xs={24} xl={8} span={12} md={24} sm={24}>
            <FormItem
              label="Material Name"
              rules={[
                {
                  required: false,
                  message: 'Select Material Code'
                }
              ]}
              className={styles.customLable}
            >
              {user?.role == 'qc_initiator' ||
              (user?.role == 'user' && user?.level == 3) ||
              user?.role == 'qa_super_user' ? (
                <Input
                  value={`${reconsData?.material?.name || 'NA'}`}
                  disabled
                />
              ) : (
                <Input value={`${code?.name || 'NA'}`} disabled />
              )}
            </FormItem>
          </Col>
          <Col xs={24} xl={6} span={12} md={24} sm={24}>
            <FormItem
              label="Batch Number"
              rules={[
                {
                  required: true,
                  message: 'Select In House Batch Number'
                }
              ]}
              className={styles.customLable}
            >
              {user?.role == 'qc_initiator' ||
              (user?.role == 'user' && user?.level == 3) ||
              user?.role == 'qa_super_user' ? (
                <Input
                  value={`${
                    reconsData?.batch_detail?.inhouse_batch_number || 'NA'
                  }`}
                  disabled
                />
              ) : (
                <Select
                  showSearch
                  placeholder="Select In House Batch Number"
                  optionFilterProp="children"
                  onChange={onChangeBatch}
                  onSearch={onSearchBatch}
                >
                  {batches?.map((item, index) => {
                    return (
                      <option value={item.id} key={index}>
                        {item.inhouse_batch_number}
                      </option>
                    );
                  })}
                </Select>
              )}
            </FormItem>
          </Col>
          <Col xs={24} xl={4} span={12} md={24} sm={24}>
            <FormItem
              label="UOM"
              rules={[
                {
                  required: false,
                  message: 'Select Material Code'
                }
              ]}
              className={styles.customLable}
            >
              {user?.role == 'qc_initiator' ||
              (user?.role == 'user' && user?.level == 3) ||
              user?.role == 'qa_super_user' ? (
                <Input
                  value={`${reconsData?.material?.uom || 'NA'}`}
                  disabled
                />
              ) : (
                <Input value={`${code?.uom || 'NA'}`} disabled />
              )}
            </FormItem>
          </Col>
          <Col xs={24} xl={12} span={12} md={24} sm={24}>
            <FormItem
              label="Received Qty as per MRN(A)"
              name="received_qty_as_per_mrn"
              rules={[
                {
                  required: false,
                  message: 'Enter Received Qty'
                }
              ]}
              className={styles.customLable}
            >
              {user?.role == 'qc_initiator' ||
              (user?.role == 'user' && user?.level == 3) ||
              user?.role == 'qa_super_user' ? (
                <InputNumber
                  min={0}
                  placeholder="Enter Received Qty"
                  style={{ width: '100%' }}
                  // value={`${reconsData?.received_qty_as_per_mrn || 'NA'}`}
                  onChange={(e) => handleReceivedQty(e)}
                  disabled={!isEdit}
                />
              ) : (
                <InputNumber
                  min={0}
                  placeholder="Enter Received Qty"
                  style={{ width: '100%' }}
                  onChange={(e) => handleReceivedQty(e)}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} xl={12} span={12} md={24} sm={24}>
            <FormItem
              label="Consumed Qty as per Stock Card(B)"
              name="consumed_qty_as_per_stock_card"
              rules={[
                {
                  required: false,
                  message: 'Enter Consumed Qty'
                }
              ]}
              className={styles.customLable}
            >
              {user?.role == 'qc_initiator' ||
              (user?.role == 'user' && user?.level == 3) ||
              user?.role == 'qa_super_user' ? (
                <InputNumber
                  // value={`${
                  //   reconsData?.consumed_qty_as_per_stock_card || 'NA'
                  // }`}
                  min={0}
                  placeholder="Enter Consumed Qty"
                  style={{ width: '100%' }}
                  onChange={(e) => handleConsumedQty(e)}
                  disabled={!isEdit}
                />
              ) : (
                <InputNumber
                  min={0}
                  placeholder="Enter Consumed Qty"
                  style={{ width: '100%' }}
                  onChange={(e) => handleConsumedQty(e)}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} xl={12} span={12} md={24} sm={24}>
            <FormItem
              label={`Difference Qty(B-A)`}
              rules={[
                {
                  required: false,
                  message: 'Enter Difference Qty'
                }
              ]}
              className={styles.customLable}
            >
              {user?.role == 'qc_initiator' ||
              (user?.role == 'user' && user?.level == 3) ||
              user?.role == 'qa_super_user' ? (
                <Input
                  value={`${
                    differenceQty
                      ? parseFloat(differenceQty).toFixed(3)
                      : reconData?.difference_qty
                      ? parseFloat(reconsData?.difference_qty).toFixed(3)
                      : 0 || 'NA'
                  }`}
                  disabled
                />
              ) : (
                <InputNumber
                  min={0}
                  placeholder="Enter Difference Qty"
                  value={parseFloat(differenceQty).toFixed(3)}
                  disabled
                  style={{ width: '100%' }}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} xl={12} span={12} md={24} sm={24}>
            <FormItem
              label="% Variance((B-A/A)x100)"
              rules={[
                {
                  required: false,
                  message: 'Enter Variance'
                }
              ]}
              className={styles.customLable}
            >
              {user?.role == 'qc_initiator' ||
              (user?.role == 'user' && user?.level == 3) ||
              user?.role == 'qa_super_user' ? (
                <Input
                  value={`${
                    variance
                      ? parseFloat(variance).toFixed(3)
                      : reconData?.variance
                      ? parseFloat(reconsData?.variance).toFixed(3)
                      : 0 || 'NA'
                  }`}
                  disabled
                />
              ) : (
                <InputNumber
                  min={0}
                  placeholder="Enter Variance"
                  value={parseFloat(variance).toFixed(3)}
                  disabled
                  style={{ width: '100%' }}
                />
              )}
            </FormItem>
          </Col>
          {user?.role == 'qc_initiator' ||
          (user?.role == 'user' && user?.level == 3) ||
          user?.role == 'qa_super_user' ? (
            <Col xs={24} xl={24} span={12} md={24} sm={24}>
              <FormItem
                label="Comments"
                name="comments"
                rules={[
                  {
                    required: false,
                    message: 'Enter Comments'
                  }
                ]}
                className={styles.customLable}
              >
                <TextArea
                  placeholder="Enter Comments"
                  onChange={(e) => setComments(e.target.value)}
                  disabled={!isEdit}
                />
              </FormItem>
            </Col>
          ) : (
            ''
          )}
        </Row>
        <FormItem className="text-center">
          {user?.role == 'qc_initiator' || user?.role == 'qa_super_user' ? (
            <Space>
              {truckShow ? (
                <Image
                  src={truck}
                  preview={false}
                  style={{ width: '100px !important', height: '100px' }}
                />
              ) : (
                <>
                  <CustomButton
                    onClick={() => handleUpdateStatus('rejected')}
                    type="primary"
                    ghost
                  >
                    Reject
                  </CustomButton>
                  <CustomButton
                    type="primary"
                    onClick={() => handleUpdateStatus('approved')}
                    loading={loading}
                  >
                    Approve
                  </CustomButton>
                </>
              )}
            </Space>
          ) : (user?.role == 'user' && user?.level == 3) ||
            Superadmindata?.[0]?.level == 3 ? (
            <Space>
              <CustomButton htmlType="button" onClick={() => history.back()}>
                Back
              </CustomButton>
              {truckShow ? (
                <Image
                  src={truck}
                  preview={false}
                  style={{ width: '100px !important', height: '100px' }}
                />
              ) : (
                <CustomButton
                  type="primary"
                  onClick={() => handleUpadateform()}
                  loading={loading}
                >
                  Update
                </CustomButton>
              )}
            </Space>
          ) : (
            <Space>
              <CustomButton htmlType="button" onClick={() => history.back()}>
                Back
              </CustomButton>
              {truckShow ? (
                <Image
                  src={truck}
                  preview={false}
                  style={{ width: '100px !important', height: '100px' }}
                />
              ) : (
                <CustomButton
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Submit
                </CustomButton>
              )}
            </Space>
          )}
        </FormItem>
      </Card>
    </Form>
  );
};
export default ReconsiliationRequestPage;
