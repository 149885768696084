import React from 'react';
import Layout from '@/common/Layout';
import CreateLineClearanceChecklist from './CreateLineClearanceChecklist';

const QCChecklist = () => {
  return (
    <>
      <Layout sider={false}>
        <CreateLineClearanceChecklist />
      </Layout>
    </>
  );
};

export default QCChecklist;
