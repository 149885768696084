import React, { Fragment, useEffect } from 'react';
import { useState } from 'react';
import {
  Row,
  Col,
  Select,
  Card,
  Form,
  Input,
  Space,
  message,
  Tag,
  Image
} from 'antd';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import { useDispatch } from 'react-redux';
import {
  getDocsbyId,
  createDamageReport,
  editDamageReport
} from '../../redux/slice';
import CustomButton from '@/common/CustomButton';
import { QrReader } from 'react-qr-reader';
import { QrcodeOutlined, DeleteOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { history } from 'app/history';
import { CheckCircleOutlined } from '@ant-design/icons';
import truck from '@/features/generateMrn/components/images/truck-delivery-done.gif';
import { setAutoFreeze } from 'immer';

const { TextArea } = Input;

const DamageReportPage = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const searchParam = useLocation().search;
  const searchParams = new URLSearchParams(searchParam);
  // const { material } = useSelector((state) => state.damage);

  const [material, setMaterial] = useState([]);
  const [openCam, setOpenCam] = useState(false);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [buttonValue, setButtonValue] = useState('');
  const [truckShow, setTruckShow] = useState(false);
  const [containerData, setContainerData] = useState([]);
  const [editContainers, setEditContainers] = useState([]);
  // const [damagesCont, setDamagesCont] = useState([]);
  setAutoFreeze(false);
  useEffect(() => {
    handleGetDocsbyId();
  }, [search]);

  const handleGetDocsbyId = () => {
    const payload = {
      id: searchParams.get('id')
    };
    dispatch(getDocsbyId(payload)).then(function (response) {
      if (response?.payload?.success) {
        setMaterial(response?.payload?.data);
        localStorage.setItem(
          'material_code',
          response?.payload?.data?.material?.code
        );
        localStorage.setItem(
          'material_name',
          response?.payload?.data?.material?.name
        );
        localStorage.setItem(
          'invoice_num',
          response?.payload?.data?.invoice_number
        );

        // setDamagesCont(damaged_cont);
      }
    });
  };

  console.log(editContainers);

  useEffect(() => {
    form.setFieldsValue({
      invoice_no:
        material?.invoice_number || localStorage.getItem('invoice_num'),
      material_code:
        material?.material?.code || localStorage.getItem('material_code'),
      material_name:
        material?.material?.name || localStorage.getItem('material_name')
    });
    const conatainers = material?.damages?.damaged_containers.filter(
      (item) => item?.status === 'QA Pending'
    );

    setEditContainers(conatainers);
  }, [material]);

  const onFinish = (values) => {
    setTruckShow(true);
    const payload =
      buttonValue == 1
        ? {
            damaged_containers: containerData,
            remarks: values.Comments,
            document_id: searchParams.get('id')
          }
        : {
            damaged_containers: [],
            remarks: values.Comments,
            document_id: searchParams.get('id')
          };
    buttonValue == 1
      ? dispatch(createDamageReport(payload)).then(function (response) {
          if (response?.payload?.success) {
            setTruckShow(false);
            message.success(response?.payload?.message);
            history.push(`/batch-details/${searchParams.get('id')}`);
          }
        })
      : history.back();
  };

  const handleQrScan = async (scanData) => {
    if (scanData && scanData !== '') {
      let batches = batchContainers[0]?.containers.filter(
        (person) => person.is_weighing == false
      );
      let result = batches?.length
        ? batches.filter(
            (batches) =>
              batches.number ==
              scanData.text.slice(scanData.text.lastIndexOf('/') + 1)
          )
        : [];
      let duplicate = data?.length
        ? data.filter(
            (person) =>
              person == scanData.text.slice(scanData.text.lastIndexOf('/') + 1)
          )
        : [];
      let damages = material?.damages?.damaged_containers?.length
        ? material?.damages?.damaged_containers?.filter(
            (person) =>
              person == scanData.text.slice(scanData.text.lastIndexOf('/') + 1)
          )
        : [];
      {
        duplicate.length || damages.length
          ? message.error('This container has already been added.')
          : result.length
          ? setData([
              ...data,
              scanData.text.slice(scanData.text.lastIndexOf('/') + 1)
            ])
          : message.error('This container does not belong to this batch');
      }
      setOpenCam(false);
    }
  };
  const handleError = (err) => {
    console.error(err);
  };

  const onChange = (value, name, index) => {
    if (name == 'container_num') {
      setData([...data, value]);
      setContainerData([...containerData, { [name]: value }]);
    } else {
      const list = [...containerData];
      list[index].damaged_quantity = value;
      setContainerData(list);
    }

    form.resetFields();
  };

  const onSearch = (value) => {
    setSearch(value);
  };
  const conatainers = material?.batch_details;
  const batchContainers = conatainers?.length
    ? conatainers?.filter(
        (conatainers) => conatainers?.id == searchParams.get('batch_id')
      )
    : [];

  const removeConatiner = (id) => {
    const list = [...data];
    list.splice(id, 1);
    setData(list);
  };
  let batches = batchContainers[0]?.containers.filter(
    (person) => person.is_weighing == false
  );
  let damages = batches?.length
    ? batches.filter((person) =>
        data.every((person2) => !person2.includes(person.number))
      )
    : [];
  const damaged_containers_list = material?.damages?.damaged_containers
    ? material?.damages?.damaged_containers
    : [];

  let result = damages?.length
    ? damages.filter((person) =>
        damaged_containers_list?.every(
          (person2) => !person2?.container_number.includes(person.number)
        )
      )
    : [];
  const damaged_cont = batchContainers[0]?.containers.filter(
    (person) => person.is_damaged == true
  );

  // useEffect(() => {
  //   const conatainers = material?.batch_details;
  //   const batchContainers = conatainers?.length
  //     ? conatainers?.filter(
  //         (conatainers) => conatainers?.id == searchParams.get('batch_id')
  //       )
  //     : [];
  //   console.log(conatainers);
  //   const damaged_cont = batchContainers[0]?.containers.filter(
  //     (person) => person.is_damaged == true
  //   );
  //   damaged_cont?.map((item) => {
  //     let qty = material?.damages?.damaged_containers.filter(
  //       (data) => data?.container_number == item?.number
  //     );
  //     editContainers.push({
  //       number: item.number,
  //       damaged_quantity: qty?.[0]?.damaged_quantity
  //     });
  //   });

  //   setEditContainers(editContainers);
  // }, [material]);

  const handleEditWeight = (e, index, container, type) => {
    const list = [...editContainers];
    list[index]['container_number'] = container;
    list[index][type] = e.target.value;
    setEditContainers(list);
  };

  const handleUpdate = (values) => {
    console.log(editContainers);
    const payload = {
      damaged_containers: editContainers,
      remarks: values.Comments,
      document_id: searchParams.get('id')
    };
    dispatch(editDamageReport(payload)).then(function (response) {
      if (response?.payload?.success) {
        setTruckShow(false);
        message.success(response?.payload?.message);
        history.push(`/batch-details/${searchParams.get('id')}`);
      }
    });
  };
  console.log(editContainers);
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      // onFinishFailed={onFinishFailed}
    >
      <Fragment>
        <Card title="DAMAGE REPORT" className={styles.CustomPanel}>
          <Row gutter={24}>
            <Col xs={12} xl={16} span={16}>
              <h6 className={styles.lable}>Material Code</h6>
            </Col>
            <Col xs={12} xl={8} span={8}>
              <h6 className={styles.lableValue}>{material?.material?.code}</h6>
            </Col>
            <Col xs={12} xl={16} span={16}>
              <h6 className={styles.lable}>Material Name</h6>
            </Col>
            <Col xs={12} xl={8} span={8}>
              <h6 className={styles.lableValue}>{material?.material?.name}</h6>
            </Col>
            <Col xs={12} xl={16} span={16}>
              <h6 className={styles.lable}>Invoice Number</h6>
            </Col>
            <Col xs={12} xl={8} span={8}>
              <h6 className={styles.lableValue}>{material?.invoice_number}</h6>
            </Col>
            {!searchParams.get('type') ? (
              <>
                <Col xs={12} xl={16} span={16}>
                  <h6 className={styles.lable}>Scan container Label</h6>
                </Col>
                {containerData?.length
                  ? containerData?.map((item, index) => {
                      return index === 0 ? (
                        <>
                          <Col xs={10} xl={3} span={6} key={index}>
                            <h6 className={styles.lableValue}>
                              {item?.container_num}
                            </h6>
                          </Col>
                          <Col xs={10} xl={3} span={6} key={index}>
                            {/* {item.qty ? (
                          <h6 className={styles.lableValue}>{item?.qty}</h6>
                        ) : ( */}
                            <Input
                              placeholder="Quantity"
                              className={styles.lableValue2}
                              onChange={(e) =>
                                onChange(
                                  e.target.value,
                                  'damaged_quantity',
                                  index
                                )
                              }
                            />
                            {/* )} */}
                          </Col>

                          <Col xs={2} xl={2} span={2}>
                            <DeleteOutlined
                              onClick={() => removeConatiner(index)}
                              className={styles.removal}
                            />
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col xs={12} xl={16} span={16}></Col>
                          <Col xs={10} xl={3} span={6} key={index}>
                            <h6 className={styles.lableValue}>
                              {item?.container_num}
                            </h6>
                          </Col>
                          <Col xs={10} xl={3} span={6} key={index}>
                            {/* {item.qty ? (
                          <h6 className={styles.lableValue}>{item?.qty}</h6>
                        ) : ( */}
                            <Input
                              className={styles.lableValue2}
                              placeholder="Quantity"
                              onChange={(e) =>
                                onChange(
                                  e.target.value,
                                  'damaged_quantity',
                                  index
                                )
                              }
                            />
                            {/* )} */}
                          </Col>
                          <Col xs={2} xl={2} span={2}>
                            <DeleteOutlined
                              onClick={() => removeConatiner(index)}
                              className={styles.removal}
                            />
                          </Col>
                        </>
                      );
                    })
                  : ''}

                {data?.length ? (
                  <Col xs={12} xl={16} span={16} md={12} sm={12}></Col>
                ) : (
                  ''
                )}
                {result?.length ? (
                  <>
                    <Col xs={10} xl={3} span={6} md={10} sm={10}>
                      <FormItem
                        name="damaged_containers"
                        rules={[
                          {
                            required: false,
                            message: 'Enter damaged containers'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Select
                          showSearch
                          placeholder="Scan or select container"
                          optionFilterProp="children"
                          onChange={(e) => onChange(e, 'container_num')}
                          onSearch={onSearch}
                        >
                          {result?.map((item, index) => {
                            return (
                              <option value={item.number} key={index}>
                                {item.number}
                              </option>
                            );
                          })}
                        </Select>
                      </FormItem>
                    </Col>
                    <Col xs={10} xl={3} span={6} md={10} sm={10}>
                      <FormItem
                        name="quantity"
                        rules={[
                          {
                            required: false,
                            message: 'Enter damaged containers'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Quantity"
                          onChange={(e) =>
                            onChange(e.target.value, 'damaged_quantity')
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col xs={2} xl={2} span={2} md={2} sm={2}>
                      <CustomButton
                        onClick={() => setOpenCam(true)}
                        className={styles.qr}
                      >
                        <QrcodeOutlined onClick={() => setOpenCam(true)} />
                      </CustomButton>
                    </Col>
                  </>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}
            {searchParams.get('type') !== 'view' ? (
              <Col xs={24} xl={24} span={24}>
                <FormItem
                  label="Comments"
                  name="Comments"
                  rules={[
                    {
                      required: false,
                      message: 'Enter damaged containers'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <TextArea placeholder="Please enter comments" />
                </FormItem>
              </Col>
            ) : (
              ''
            )}
          </Row>
          {searchParams.get('type') && editContainers?.length
            ? editContainers?.map((item, index) => {
                // let qty = material?.damages?.damaged_containers.filter(
                //   (data) => data?.container_number == item?.number
                // );
                return (
                  <>
                    <Row
                      gutter={24}
                      key={index}
                      className={styles.selectContainers}
                    >
                      <Col xs={10} xl={12} span={16}>
                        <FormItem
                          label="Container"
                          rules={[
                            {
                              required: false,
                              message: 'Enter damaged containers'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Input value={item?.container_number} disabled />
                        </FormItem>
                      </Col>
                      <Col xs={10} xl={12} span={16}>
                        <FormItem
                          label="Quantity"
                          rules={[
                            {
                              required: false,
                              message: 'Enter damaged containers'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Input
                            value={item?.damaged_quantity}
                            // disabled={!isEdit}
                            onChange={(e) =>
                              handleEditWeight(
                                e,
                                index,
                                item?.container_number,
                                'damaged_quantity'
                              )
                            }
                          />
                        </FormItem>
                      </Col>
                    </Row>
                    <br />
                  </>
                );
              })
            : ''}

          {!searchParams.get('type') ? (
            <Row gutter={24}>
              <Col span={24}>
                {damaged_cont?.length
                  ? damaged_cont?.map((item, index) => {
                      return (
                        <Tag
                          icon={<CheckCircleOutlined />}
                          key={index}
                          className={styles.customTag}
                        >
                          {item?.number}
                        </Tag>
                      );
                    })
                  : ''}
              </Col>
            </Row>
          ) : (
            ''
          )}
          <br />
          <br />
          <FormItem className="text-center">
            <Space size={5}>
              {truckShow ? (
                <Image
                  src={truck}
                  preview={false}
                  style={{ width: '100px !important', height: '100px' }}
                />
              ) : searchParams.get('type') == 'view' ? (
                ''
              ) : searchParams.get('type') == 'Edit' ? (
                <CustomButton
                  onClick={handleUpdate}
                  className={styles.inwardButton}
                  // htmlType="submit"
                  type="primary"
                >
                  Update
                </CustomButton>
              ) : (
                <CustomButton
                  onClick={() => setButtonValue('1')}
                  className={styles.inwardButton}
                  htmlType="submit"
                  type="primary"
                  disabled={data?.length ? false : true}
                >
                  Submit
                </CustomButton>
              )}

              <CustomButton
                onClick={() => setButtonValue('2')}
                className={styles.inwardButton}
                htmlType="submit"
                disabled={data?.length ? true : false}
              >
                Back to Batch Details
              </CustomButton>
            </Space>
          </FormItem>
        </Card>
        {openCam ? (
          <QrReader
            constraints={{ facingMode: 'environment' }}
            delay={1000}
            onError={handleError}
            onResult={(result) => handleQrScan(result)}
            // chooseDeviceId={()=>selected}
            style={{ width: '3px', height: '3px' }}
          />
        ) : (
          ''
        )}
      </Fragment>
    </Form>
  );
};
export default DamageReportPage;
