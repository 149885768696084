import React from 'react';
import Layout from '@/common/Layout';
import StockCardIssuancePage from './components/stockCardIssuancePage';

const StockCardIssuance = () => {
  return (
    <>
      <Layout sider={false}>
        <StockCardIssuancePage />
      </Layout>
    </>
  );
};

export default StockCardIssuance;
