import React, { useState } from 'react';
import {
  Row,
  Col,
  Form,
  Card,
  Input,
  Space,
  InputNumber,
  message,
  Image,
  DatePicker
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import truck from '@/features/qa/components/images/truck-delivery-done.gif';
import Layout from '@/common/Layout';
import { useCreateWaterCollectionMutation } from '../../redux/apiSlice';
import moment from 'moment';

import LocalStorage from 'utilities/localStorage';
const user = LocalStorage.getItem('Gravity_user');
console.log('user', user?.user?.employee);

const createProductNewPage = () => {
  const [form] = Form.useForm();

  // #region Initial states - Start
  const [truckShow] = useState(false);

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  // #endregion

  // #region Showing success or error message - Start
  const handleShowMessage = (messageType, messageText) => {
    messageApi.open({
      type: messageType,
      content: messageText
    });
  };
  // #endregion

  // #region Creating Vacuum Cleaning Record - Start
  let [createWaterCollection] = useCreateWaterCollectionMutation();

  const onFinish = async (values) => {
    setSubmitButtonDisabled(true);

    const values2 = { ...values };
    values2.collected_by_user_date = moment(new Date()).format();

    const response = await createWaterCollection({
      ...values2
    });
    // console.log(response);
    if (response?.data?.success === true) {
      handleShowMessage('success', response?.data?.message);
      setTimeout(
        () => history.push('/log-books-purified-water-collection-list'),
        1500
      );
    } else {
      setSubmitButtonDisabled(false);
      handleShowMessage('error', response?.error?.data?.message);
    }
  };
  // #endregion

  return (
    <>
      {contextHolder}
      <Layout sider={false}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            collected_by_user_id: user?.user?.employee,
            display_collected_by: `${user?.user?.employee} (${moment(
              new Date()
            ).format('DD-MM-YY')})`
          }}
        >
          <Card
            title={'PURIFIED WATER COLLECTION LOG'}
            className={styles.CustomPanel}
          >
            <Row gutter={24}>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Collection Date & Time"
                  name="collection_date"
                  rules={[
                    {
                      required: true,
                      message: 'Select Collection date & time'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <DatePicker
                    showTime={true}
                    placeholder="Select Collection date & time"
                    style={{ width: '100%' }}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Water Quantity (in Liters)"
                  name="water_quantity"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Water Quantity'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <InputNumber
                    placeholder="Enter Water Quantity"
                    style={{ width: '100%' }}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Collected by (User Id & Date)"
                  name="display_collected_by"
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Remarks"
                  name="remarks"
                  className={styles.customLable}
                >
                  <Input.TextArea placeholder="" style={{ width: '100%' }} />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24} hidden={true}>
                <FormItem
                  label="Cleaned by"
                  name="collected_by_user_id"
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24} hidden={true}>
                <FormItem
                  label="Cleaned by"
                  name="collected_by_user_date"
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
            </Row>
          </Card>
          <br />
          <FormItem className="text-center">
            <Space>
              {truckShow ? (
                <Image
                  src={truck}
                  preview={false}
                  style={{ width: '100px !important', height: '100px' }}
                />
              ) : (
                <CustomButton
                  className={styles.inwardButton}
                  htmlType="submit"
                  type="primary"
                  onSubmit={(e) => onFinish(e)}
                  disabled={submitButtonDisabled}
                >
                  Create
                </CustomButton>
              )}
              <CustomButton
                className={styles.inwardButton}
                type="primary"
                ghost
                onClick={() => history.back()}
              >
                Back
              </CustomButton>
            </Space>
          </FormItem>
        </Form>
      </Layout>
    </>
  );
};
export default createProductNewPage;
