import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { create_checklist, print_lc } from './api';
import { message } from 'antd';

const actions = {
  CREATE_CHECKLIST: 'lc/CREATE_CHECKLIST',
  GET_LC_MATERIAL_DETAILS: 'lc/GET_LC_MATERIAL_DETAILS',
  GET_PRINT_LC: 'lc/PRINT_LC'
};

export const createChecklist = createAsyncThunk(
  actions.CREATE_CHECKLIST,
  async (payload) => {
    const response = await create_checklist('qa-create', payload);
    return response;
  }
);

export const printLC = createAsyncThunk(
  actions.GET_PRINT_LC,
  async (payload) => {
    const response = await print_lc(`print_lc_pdf?id=${payload.id}`);

    return response;
  }
);

const initialState = {
  printLC: []
};
export const lcSlice = createSlice({
  name: 'lc',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(printLC.pending, (state) => {
        state.lcPrinting = true;
      })
      .addCase(printLC.fulfilled, (state, action) => {
        state.lcPrinting = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(printLC.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.lcPrinting = false;

        message.error(msg);
      });
  }
});
export default lcSlice.reducer;
