import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { batch, createbatch, updatebatch } from './api';

const initialState = {
  allBatches: []
};

const actions = {
  GET_ALL_BATCHES: 'issuance/GET_ALL_BATCHES',
  CREATE_ISSUANCE: 'issuance/CREATE_ISSUANCE',
  BATCH_UPDATE: 'issuance/BATCH_UPDATE'
};

export const getAllBatcheslist = createAsyncThunk(
  actions.GET_ALL_BATCHES,
  async (payload) => {
    const response = await batch('all-stocks', payload);

    return response;
  }
);

export const createStockIssuance = createAsyncThunk(
  actions.CREATE_ISSUANCE,
  async (payload) => {
    const response = await createbatch('create-stock-issuance', payload);

    return response;
  }
);

export const batchUpdate = createAsyncThunk(
  actions.BATCH_UPDATE,
  async (payload) => {
    const response = await updatebatch('batch_update', payload);

    return response;
  }
);

export const issuanceSlice = createSlice({
  name: 'issuance',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllBatcheslist.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllBatcheslist.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, pagination } = action.payload;
        if (success) {
          console.log(data);
          state.allBatches = data;
          state.pagination = pagination || null;
        } else {
          state.allMaterials = [];
        }
      })
      .addCase(getAllBatcheslist.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createStockIssuance.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createStockIssuance.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createStockIssuance.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(batchUpdate.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(batchUpdate.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(batchUpdate.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
  }
});
export default issuanceSlice.reducer;
