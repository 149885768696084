import React, { useState } from 'react';
import { Form, Card, Space, DatePicker } from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import localStyles from './index.module.less';
import CustomTable from 'common/Content/CustomTable';
import CustomButton from '@/common/CustomButton';
import Layout from '@/common/Layout';
import { history } from 'app/history';
import { useGetTrhmrListQuery } from '../../redux/apiSlice';
import moment from 'moment';
import Spinner from '../../../../common/Spinner';
const { RangePicker } = DatePicker;

const AdminPage = () => {
  const [form] = Form.useForm();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  let totalRecords;

  const { data, error, isLoading, refetch } = useGetTrhmrListQuery({
    page,
    per_page: perPage,
    from_date: fromDate,
    to_date: toDate
  });

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      ellipsis: true,
      // width: '200px',
      render: (_, record) => {
        return record?.date ? (
          <a href={`log-books-trhmr-list/${record?.id}`}>
            {moment(record.date).format('DD-MMM-YYYY')}
          </a>
        ) : (
          ''
        );
      }
    },
    {
      title: 'Area Name',
      dataIndex: 'areaName',
      key: 'areaName',
      ellipsis: true,
      // width: '200px',
      render: (_, record) => {
        return record?.area_name_master?.areaName
          ? record.area_name_master.areaName
          : 'N/A';
      }
    },
    {
      title: 'Thermo Hygrometer ID',
      dataIndex: 'hygrometer_id',
      key: 'hygrometer_id',
      ellipsis: true,
      // width: '200px',
      render: (_, record) => {
        return record?.thermo_hygrometer_id_master?.thermo_hygrometer_id
          ? record?.thermo_hygrometer_id_master?.thermo_hygrometer_id
          : '';
      }
    },
    {
      title: 'Calibration Due Date',
      dataIndex: 'calibration',
      key: 'calibration',
      ellipsis: true,
      // width: '200px',
      render: (_, record) => {
        return record?.thermo_hygrometer_id_master
          ?.hygrometer_calibration_due_date
          ? moment(
              record?.thermo_hygrometer_id_master
                ?.hygrometer_calibration_due_date
            ).format('DD-MMM-YYYY')
          : '';
      }
    },
    {
      title: 'Period',
      dataIndex: 'period',
      key: 'period',
      ellipsis: true,
      // width: '200px',
      render: (_, record) => {
        return (
          <>
            {record?.trhmr_log_book_details.length > 0
              ? record?.trhmr_log_book_details.map((obj, index) => {
                  if (index === 0) {
                    return <span>{obj.period}</span>;
                  } else {
                    return (
                      <span>
                        {' '}
                        <br /> {obj.period}
                      </span>
                    );
                  }
                })
              : ''}
          </>
        );
      }
    },
    {
      title: 'Observed Temp',
      dataIndex: 'temperature',
      key: 'temperature',
      ellipsis: true,
      // width: '200px',
      render: (_, record) => {
        return (
          <>
            {record?.trhmr_log_book_details.length > 0
              ? record?.trhmr_log_book_details.map((obj, index) => {
                  if (index === 0) {
                    return <span>{obj.temperature}</span>;
                  } else {
                    return (
                      <span>
                        <br />
                        {obj.temperature}
                      </span>
                    );
                  }
                })
              : ''}
          </>
        );
      }
    },
    {
      title: 'Observed RH',
      dataIndex: 'relative_humidity',
      key: 'relative_humidity',
      ellipsis: true,
      // width: '200px',
      render: (_, record) => {
        return (
          <>
            {record?.trhmr_log_book_details.length > 0
              ? record?.trhmr_log_book_details.map((obj, index) => {
                  if (index === 0) {
                    return <span>{obj.relative_humidity}</span>;
                  } else {
                    return (
                      <span>
                        {' '}
                        <br /> {obj.relative_humidity}
                      </span>
                    );
                  }
                })
              : ''}
          </>
        );
      }
    },
    {
      title: 'Verified by',
      dataIndex: 'verified_by_user_id',
      key: 'verified_by_user_id',
      ellipsis: true,
      // width: '200px',
      render: (_, record) => {
        return (
          <>
            {record?.trhmr_log_book_details.length > 0
              ? record?.trhmr_log_book_details.map((obj, index) => {
                  if (index === 0) {
                    return <span>{obj.verified_by_user_id}</span>;
                  } else {
                    return (
                      <span>
                        {' '}
                        <br /> {obj.verified_by_user_id}
                      </span>
                    );
                  }
                })
              : ''}
          </>
        );
      }
    }
  ];

  let content;
  if (isLoading) {
    console.log('Loading.....');
    content = <Spinner />;
  } else if (data) {
    console.log(data?.pagination?.total_records);
    totalRecords = data?.pagination?.total_records;
    content = (
      <Layout sider={false}>
        <div className={localStyles.heading1}>
          <h1>Overview</h1>
          <h4>Your current requests summary and other activities</h4>
        </div>
        <Form form={form} layout="vertical">
          <Card
            title={
              <span>Temperature & Relative Humidity Monitoring Record</span>
            }
            className={`${styles.cardStyles_approver}`}
            extra={
              <Space>
                <RangePicker
                  onChange={(e) => {
                    console.log(e[0]._d);
                    setFromDate(moment(e[0]._d).startOf('day').format());
                    setToDate(moment(e[1]._d).endOf('day').format());
                  }}
                />
                <CustomButton
                  className={styles.inwardButton}
                  onClick={() => history.push('/log-books-trhmr-create')}
                  type="primary"
                >
                  Create
                </CustomButton>
              </Space>
            }
          >
            <div className={`${styles.table_container}`}>
              <CustomTable
                columns={columns}
                dataSource={data.data}
                pagination={{
                  size: 'small',
                  pageSize: perPage,
                  defaultCurrentPage: page,
                  total: totalRecords,
                  onChange: (page, pageSize) => {
                    setPage(page);
                    setPerPage(pageSize);
                    refetch();
                  },
                  showSizeChanger: true
                }}
              />
            </div>
          </Card>
          <br />
          <FormItem className="text-center">
            <CustomButton
              className={styles.inwardButton}
              onClick={() => history.back()}
              type="primary"
            >
              Back to Home
            </CustomButton>
          </FormItem>
        </Form>
      </Layout>
    );
  } else if (error) {
    console.log(error);
    content = 'Something went wrong!';
  }

  return <>{content}</>;
};
export default AdminPage;
