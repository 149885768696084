import React from 'react';
import Layout from '@/common/Layout';
import WastageReconsilationPage from './components/wastageReconsilationPage';

const WastageReconsilation = () => {
  return (
    <>
      <Layout sider={false}>
        <WastageReconsilationPage />
      </Layout>
    </>
  );
};

export default WastageReconsilation;
