import React, { useState, useEffect } from 'react';
import Layout from '@/common/Layout';
import styles from '@/common/Layout/index.module.less';
import {
  Card,
  Form,
  Row,
  Col,
  Select,
  Input,
  Space,
  Image,
  message,
  Alert
} from 'antd';
import FormItem from '@/common/FormItem';
import CustomButton from '@/common/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  EditOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';
import {
  getAllMaterialslist,
  getAllCodes,
  createReturnMaterials,
  getAllProductsList,
  updateStatus
} from '../../redux/slice';
import moment from 'moment';
import truck from '../images/truck-delivery-done.gif';
import { history } from 'app/history';
import { getLoggedInUser, getRoles } from 'utilities/helpers';
import { useLocation } from 'react-router-dom';

const { TextArea } = Input;

const CreateReturnQty = ({ loading }) => {
  const [form] = Form.useForm();

  const { allMaterials } = useSelector((state) => state.returns);

  const [materials, setMaterials] = useState([
    {
      material_id: '',
      material_name: '',
      batch_detail_id: '',
      returned_qty: '',
      uom: ''
    }
  ]);
  const [editReturn, setEditReturn] = useState([]);

  const { user } = getLoggedInUser();
  const { SuperadminProddata } = getRoles('Production');
  const searchParam = useLocation().search;
  const searchParams = new URLSearchParams(searchParam);

  const [search, setSearch] = useState('');
  const [codes, setCodes] = useState([]);
  const [batches, setBatches] = useState([]);
  const [truckShow, setTruckShow] = useState(false);
  const [comments, setComments] = useState('');
  const [qAComments, setQAComments] = useState('');
  const [whComments, setWHComments] = useState('');
  const [status, setStatus] = useState('');
  const [productId, setProductId] = useState('');
  // const [returnedMaterials, setReturnedMaterials] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    handleGetAllMaterialsList();
    searchParams.get('id') ? handleGetMaterialById() : '';
  }, [search]);

  const handleGetAllMaterialsList = () => {
    let payload = { search };
    dispatch(getAllProductsList(payload));
  };

  // useEffect(() => {
  //   form.setFieldsValue({
  //     dispensing_request_id: returnedMaterials?.dispensing_request_id,
  //     product_name: returnedMaterials?.product_name,
  //     bmp_bpr_number: returnedMaterials?.bmr_number,
  //     returned_date: returnedMaterials?.returned_date
  //   });
  // }, []);
  const handleGetMaterialById = () => {
    let payload = { id: searchParams.get('id') };
    dispatch(getAllMaterialslist(payload)).then((response) => {
      if (response?.payload?.success) {
        form.setFieldsValue({
          dispensing_request_id: response?.payload?.data?.dispensing_request_id,
          product_name: response?.payload?.data?.product_name,
          bmp_bpr_number: response?.payload?.data?.bmr_number,
          returned_date: response?.payload?.data?.returned_date
        });
        setStatus(response?.payload?.data?.status);
        setQAComments(response?.payload?.data?.QA_comments);
        setWHComments(response?.payload?.data?.WH_comments);
        setMaterials(response?.payload?.data?.returned_material_codes);
        setProductId(response?.payload?.data?.dispensing_request_id);
        console.log(response?.payload?.data);
      }
    });
  };

  const addMaterials = () => {
    setMaterials([
      ...materials,
      {
        material_id: '',
        material_name: '',
        batch_detail_id: '',
        returned_qty: '',
        uom: ''
      }
    ]);
  };

  const removeMaterials = (index) => {
    const list = [...materials];
    list.splice(index, 1);
    setMaterials(list);
  };

  console.log(materials);
  const onSearch = (value) => {
    setSearch(value);
  };

  const onChange = (e) => {
    const material = allMaterials.filter(
      (MaterialsList) => MaterialsList.id === e
    );
    console.log(material);
    form.setFieldsValue({ product_name: material[0]?.product_detail?.name });
    form.setFieldsValue({
      bmp_bpr_number: material[0]?.bmr_number
    });
    form.setFieldsValue({ returned_date: moment().format('YYYY-MM-DD') });
    const payload = { dispensing_request_id: e };
    dispatch(getAllCodes(payload)).then((response) => {
      if (response?.payload?.success) {
        console.log(response?.payload?.data);
        setCodes(response?.payload?.data);
      }
    });
  };

  const handleChange = (e, index, type) => {
    console.log(e);
    const mat_codes =
      type == 'material_id'
        ? codes.filter((code) => code.materials?.[0]?.id === e)
        : type == 'batch_detail_id'
        ? batches.filter((code) => code.id == e)
        : '';
    console.log(mat_codes);
    const list = [...materials];
    list[index][type] = e;
    type == 'material_id'
      ? (list[index]['material_code'] = mat_codes?.[0]?.materials?.[0]?.code)
      : '';
    type == 'material_id'
      ? (list[index]['material_name'] = mat_codes?.[0]?.materials?.[0]?.name)
      : '';
    type == 'material_id'
      ? (list[index]['uom'] = mat_codes?.[0]?.materials?.[0]?.uom)
      : '';
    setMaterials(list);
    setBatches(mat_codes?.[0]?.materials?.[0]?.batch_details);
    console.log(materials);
    type == 'batch_detail_id'
      ? (list[index]['inhouse_batch_no'] = mat_codes?.[0]?.inhouse_batch_number)
      : '';
    // type == 'batch_detail_id'
    //   ? (list[index]['inhouse_batch_no'] = mat_codes?.[0]?.inhouse_batch_number)
    //   : '';
    setMaterials(list);
  };

  const onFinish = (values) => {
    setTruckShow(true);
    values['returned_material_items'] = materials;
    dispatch(createReturnMaterials(values)).then((response) => {
      if (response?.payload?.success) {
        message.success(response?.payload?.message);
        history.push('/material-return-request');
        console.log(response?.payload?.data);
      }
    });
    console.log(values);
  };

  const handleApprove = (status) => {
    setTruckShow(true);
    const payload = {
      id: searchParams.get('id'),
      status,
      QA_comments:
        status == 'QA Approved' || status == 'QA Rejected'
          ? comments
          : qAComments,
      WH_comments: status == 'WH Accepted' ? comments : ''
    };
    dispatch(updateStatus(payload)).then((response) => {
      if (response?.payload?.success) {
        setTruckShow(false);
        message.success(response?.payload?.message);
        history.push('/material-return-request');
      }
    });
  };

  const handlecomments = (e) => {
    console.log(e.target.value);
    setComments(e.target.value);
  };
  console.log(setEditReturn, editReturn);
  const handleEditReturn = (index, id) => {
    console.log(id);

    const payload = { dispensing_request_id: productId };
    dispatch(getAllCodes(payload)).then((response) => {
      if (response?.payload?.success) {
        console.log(response?.payload?.data);
        setCodes(response?.payload?.data);
      }
    });
    const list = [...materials];

    list[index].edit = true;
    setMaterials(list);
  };

  // const handlereturnQty = (value, index, id) => {
  //   console.log(index);
  //   const list = [...materials];
  //   list[index]['returned_qty'] = value;
  //   setMaterials(list);

  //   const return_qty = [...returnedMaterials];
  //   return_qty[index]['returned_qty'] = value;
  //   return_qty[index]['id'] = id;
  //   setReturnedMaterials(return_qty);
  //   // setReturnedMaterials([
  //   //   ...returnedMaterials,
  //   //   { id: id, returned_qty: value }
  //   // ]);
  // };

  // console.log(materials);
  return (
    <Layout sider={false}>
      {qAComments ? (
        <Card>
          <Alert message={qAComments} type="info" showIcon />
        </Card>
      ) : (
        ''
      )}
      {whComments ? (
        <Card>
          <Alert message={whComments} type="info" showIcon />
        </Card>
      ) : (
        ''
      )}
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Card
          title={<span>MATERIAL RETURN QUANTITY</span>}
          className={`${styles.cardStyles_approver}`}
        >
          <Row gutter={24}>
            <Col xs={24} xl={6} span={12} md={24} sm={24}>
              <FormItem
                label="Product Batch"
                name="dispensing_request_id"
                rules={[
                  {
                    required: true,
                    message: 'Select Product Batch'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  showSearch
                  placeholder="Select a product batch"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  onChange={(e) => onChange(e)}
                  disabled={status ? true : false}
                >
                  {allMaterials?.map((item, index) => {
                    return (
                      <option value={item.id} key={index}>
                        {item.product_batch_number}
                      </option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={12} md={24} sm={24}>
              <FormItem
                label="Product Name"
                name="product_name"
                rules={[
                  {
                    required: true,
                    message: 'Select Product Name'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Product Name" disabled />
              </FormItem>
            </Col>
            <Col xs={24} xl={6} span={12} md={24} sm={24}>
              <FormItem
                label="BMR/BPR #"
                name="bmp_bpr_number"
                rules={[
                  {
                    required: true,
                    message: 'Select Product Name'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter BMR/BPR Number" disabled />
              </FormItem>
            </Col>
            <Col xs={24} xl={4} span={12} md={24} sm={24}>
              <FormItem
                label="Date"
                name="returned_date"
                rules={[
                  {
                    required: true,
                    message: 'Select Product Name'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Date" disabled />
              </FormItem>
            </Col>
          </Row>
        </Card>
        <br />
        <Card className={`${styles.subpanel}`}>
          {materials?.map((item, index) => {
            return (
              <Row gutter={24} key={index}>
                <Col xs={24} xl={6} span={12} md={24} sm={24}>
                  <FormItem
                    label="Material Code"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Material Code'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Select
                      onChange={(e) => handleChange(e, index, 'material_id')}
                      value={item?.material_code}
                      placeholder="Select Material Code"
                      // disabled={status ? true : false}
                      disabled={item?.edit || status == '' ? false : true}
                    >
                      {codes?.map((item, index) => {
                        return (
                          <option key={index} value={item?.materials?.[0]?.id}>
                            {item?.materials?.[0]?.code}
                          </option>
                        );
                      })}
                    </Select>
                  </FormItem>
                </Col>
                <Col xs={24} xl={6} span={12} md={24} sm={24}>
                  <FormItem
                    label="Material Name"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Material Name'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Material name"
                      value={item?.material_name}
                      // disabled={status ? true : false}
                      disabled={item?.edit || status == '' ? false : true}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={4} span={12} md={24} sm={24}>
                  <FormItem
                    label="Inhouse Batch #"
                    rules={[
                      {
                        required: true,
                        message: 'Select Inhouse Batch Number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Select
                      placeholder="Select Inhouse Batch Number"
                      onChange={(e) =>
                        handleChange(e, index, 'batch_detail_id')
                      }
                      value={item?.inhouse_batch_no}
                      // disabled={status ? true : false}
                      disabled={item?.edit || status == '' ? false : true}
                    >
                      {batches?.map((item, index) => {
                        return (
                          <option value={item?.id} key={index}>
                            {item?.inhouse_batch_number}
                          </option>
                        );
                      })}
                    </Select>
                  </FormItem>
                </Col>
                <Col xs={24} xl={4} span={12} md={24} sm={24}>
                  <FormItem
                    label="Returned Qty"
                    rules={[
                      {
                        required: true,
                        message: 'Select Inhouse Batch Number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Return Qty"
                      onChange={(e) =>
                        handleChange(e.target.value, index, 'returned_qty')
                      }
                      value={item?.returned_qty}
                      disabled={item?.edit || status == '' ? false : true}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={2} span={12} md={24} sm={24}>
                  <FormItem label="UOM" className={styles.customLable}>
                    <Input
                      placeholder="UOM"
                      value={item?.uom}
                      disabled={true}
                    />
                  </FormItem>
                </Col>
                {(user?.role === 'prod_user' &&
                  status !== '' &&
                  user?.level == 3) ||
                SuperadminProddata?.[0]?.level == 3 ? (
                  <Col
                    xs={24}
                    xl={2}
                    span={12}
                    md={24}
                    sm={24}
                    style={{ marginTop: '32px' }}
                  >
                    <EditOutlined
                      onClick={() => handleEditReturn(index, item?.id)}
                    />
                  </Col>
                ) : (
                  ''
                )}

                {(user?.role === 'prod_user' && status == '') ||
                SuperadminProddata?.[0]?.level == 3 ? (
                  index == 0 ? (
                    <Col xs={24} xl={2} span={12} md={24} sm={24}>
                      <CustomButton
                        className={styles.border_button}
                        type="primary"
                        ghost
                        style={{ marginTop: '24px' }}
                      >
                        <PlusCircleOutlined onClick={() => addMaterials()} />
                      </CustomButton>
                    </Col>
                  ) : (
                    <Col xs={24} xl={2} span={12} md={24} sm={24}>
                      <CustomButton
                        className={styles.border_button}
                        type="primary"
                        ghost
                        style={{ marginTop: '24px' }}
                      >
                        <MinusCircleOutlined
                          onClick={() => removeMaterials(index)}
                        />
                      </CustomButton>
                    </Col>
                  )
                ) : (
                  ''
                )}
              </Row>
            );
          })}
          {user?.role !== 'prod_user' ? (
            <Col xs={24} xl={22} span={12} md={24} sm={24}>
              <FormItem
                label="Comments"
                rules={[
                  {
                    required: true,
                    message: 'Enter Comments'
                  }
                ]}
                className={styles.customLable}
              >
                <TextArea
                  rows={4}
                  placeholder="Comments"
                  onChange={(e) => handlecomments(e)}
                />
              </FormItem>
            </Col>
          ) : (
            ''
          )}
          <FormItem className="text-center">
            <Space>
              <CustomButton
                type="primary"
                onClick={() => history.back()}
                loading={loading}
                ghost
              >
                Back
              </CustomButton>
              {truckShow ? (
                <Image
                  src={truck}
                  preview={false}
                  style={{ width: '100px !important', height: '100px' }}
                />
              ) : (user?.role === 'qc_initiator' ||
                  user?.role === 'qa_super_user') &&
                status == 'QA Pending' ? (
                <Space>
                  <CustomButton
                    type="primary"
                    loading={loading}
                    onClick={() => handleApprove('QA Approved')}
                    style={{
                      backgroundColor: '#28a745',
                      borderColor: '#28a745'
                    }}
                  >
                    Approve
                  </CustomButton>
                  <CustomButton
                    type="primary"
                    loading={loading}
                    onClick={() => handleApprove('QA Rejected')}
                    // danger
                    style={{
                      backgroundColor: '#dc3545',
                      borderColor: '#dc3545'
                    }}
                  >
                    Reject
                  </CustomButton>
                </Space>
              ) : (user?.role === 'user' || user?.role === 'qa_super_user') &&
                status == 'QA Approved' ? (
                <Space>
                  <CustomButton
                    type="primary"
                    loading={loading}
                    onClick={() => handleApprove('WH Accepted')}
                    style={{
                      backgroundColor: '#28a745',
                      borderColor: '#28a745'
                    }}
                  >
                    Accept
                  </CustomButton>
                </Space>
              ) : (user?.role === 'prod_user' ||
                  user?.role === 'qa_super_user') &&
                status == '' ? (
                <CustomButton
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Create
                </CustomButton>
              ) : (
                ''
              )}
              {(user?.role === 'prod_user' &&
                user?.level == '3' &&
                status !== 'QA Approved') ||
              SuperadminProddata?.[0]?.level == 3 ? (
                truckShow ? (
                  <Image
                    src={truck}
                    preview={false}
                    style={{ width: '100px !important', height: '100px' }}
                  />
                ) : (
                  <CustomButton
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                  >
                    Update
                  </CustomButton>
                )
              ) : (
                ''
              )}
            </Space>
          </FormItem>
        </Card>
      </Form>
    </Layout>
  );
};
export default CreateReturnQty;
