import React, { useEffect } from 'react';
import styles from '@/common/Layout/index.module.less';
import { Card, Empty, Input, Tag } from 'antd';
import CustomTable from 'common/Content/CustomTable';
import moment from 'moment';
import Layout from '@/common/Layout';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMRNMaterials } from '../../redux/slice';

const { Search } = Input;
const ListofMaterials = () => {
  const { allMaterials, pagination, getAllMaterilLoading } = useSelector(
    (state) => state.material
  );
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    handleGetAllMaterials();
  }, [page, search]);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const handleGetAllMaterials = () => {
    let payload = {
      search: search,
      page,
      status: 'approved'
    };
    dispatch(getAllMRNMaterials(payload));
  };

  const columns = [
    {
      title: 'Material code',
      dataIndex: 'material',
      key: 'material_code',
      ellipsis: true,
      width: '150px',
      sorter: (a, b) => a?.material_code?.localeCompare(b?.material_code),
      render: (_, record) => {
        {
          return <a href={`/view/${record.id}`}>{record?.material?.code}</a>;
        }
      },
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Invoice no',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      width: '150px',
      ellipsis: true,
      sorter: (a, b) => a?.invoice_number?.localeCompare(b?.invoice_number),
      render: (text) => {
        return text;
      },
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Name of the material',
      dataIndex: 'material',
      key: 'material',
      ellipsis: true,
      width: '200px',
      render: (record) => {
        {
          return record?.name;
        }
      },
      sorter: (a, b) => a?.material?.name?.localeCompare(b?.material?.name),
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },

    {
      title: 'PO number',
      dataIndex: 'po_number',
      key: 'po_number',
      align: 'center',
      width: '150px',
      // ellipsis: true,
      sorter: (a, b) => a?.po_number?.localeCompare(b?.po_number),
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Receipt Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      width: '150px',
      sorter: (a, b) =>
        moment(a[`createdAt`]).unix() - moment(b[`createdAt`]).unix(),
      render: (createdAt) => <>{moment(createdAt).format('DD MMM YYYY')}</>,
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Transaction Type',
      dataIndex: 'mrn_type',
      key: 'mrn_type',
      align: 'center',
      width: '150px',
      sorter: (a, b) => a?.mrn_type?.localeCompare(b?.mrn_type),
      render: (mrn_type) => (
        <>
          <Tag color={mrn_type == 'Update MRN' ? '#2db7f5' : 'green'}>
            {mrn_type || 'General MRN'}
          </Tag>
        </>
      ),
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    }
  ];
  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        <div className={styles.empty_state_text}>
          There are no material requests to display
        </div>
      </span>
    )
  };
  return (
    <>
      <Layout sider={false}>
        <h2 className={`${styles.page_title}`}>Material Receipts</h2>
        <h4 className={`${styles.page_sub_title}`}>
          View all material receipt notes along with weighing and damage details
        </h4>
        <br />
        <Card
          className={`${styles.cardStyles_approver}`}
          title="Material Receipts"
          // style={{ fontWeight: 'bold' }}
          extra={
            <Search
              onChange={(e) => handleChange(e)}
              className={`w-100 ${styles.searchbar}`}
              justify="start"
              align="start"
              placeholder="Search by material code"
            />
          }
        >
          {/* <Space size={9} direction="vertical" className={styles.searchwidth}>
            <Row
              gutter={16}
              style={{
                marginTop: '-7px',
                marginRight: '0px',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Col span={16}>
                <div className={`${styles.Gate_pass_req_title}`}>
                  Material Receipts{' '}
                </div>
                <div className={`${styles.Gate_pass_req_sub_title}`}>
                  Keep track of your recent material requests
                </div>
              </Col>
              <Col span={8} className={styles.CustomButton s}>
                <Search
                  onChange={(e) => handleChange(e)}
                  className={`w-100 ${styles.searchbar}`}
                  justify="start"
                  align="start"
                  placeholder="Search by material code"
                />
              </Col>
            </Row>
          </Space> */}
          <div className={`${styles.table_container}`}>
            <CustomTable
              locale={locale}
              loading={getAllMaterilLoading}
              columns={columns}
              dataSource={allMaterials}
              pagination={{
                size: 'small',
                total: pagination?.total_records || 0,
                showSizeChanger: false,
                onChange: (page) => {
                  setPage(page);
                }
              }}
              // className={styles.scrolling}
              // scroll={{ x: 'auto' }}
            />
          </div>
        </Card>
      </Layout>
    </>
  );
};

export default ListofMaterials;
