import React, { useState, useEffect } from 'react';
import {
  Row,
  Form,
  Card,
  Space,
  Col,
  Tag,
  Badge,
  Input,
  DatePicker,
  Collapse,
  Popconfirm,
  message
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';

import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import { useDispatch } from 'react-redux';
// import CustomModal from '@/common/CustomModal';
// import { DeleteOutlined, PlusCircleFilled } from '@ant-design/icons';
// import truck from '@/features/qa/components/images/truck-delivery-done.gif';
import Layout from '@/common/Layout';
import {
  getAllParamsList,
  getboms,
  deleteParams
  // createbomMateials,
  // getAccessories
} from '../../redux/slice';
import { useParams } from 'react-router-dom';
import {
  updateProduct,
  getallprodMaterials
} from '@/features/dispensing/redux/slice';
import ProductDetails from '@/features/dispensing/components/ProductDetailsPage';
import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  DownOutlined
} from '@ant-design/icons';
import AddMaterial from '../AddMaterial';
import { getLoggedInUser, getRoles } from 'utilities/helpers';

const { Panel } = Collapse;

const ConfigProcessView = () => {
  const [form] = Form.useForm();
  const params = useParams();

  const [processList, setProcessList] = useState([]);
  const [matModal, setMatModal] = useState(false);
  const [groupId, setGroupId] = useState('');
  const [configId, setConfigId] = useState('');
  const [mainid, setMainid] = useState('');
  const [eqp, setEqp] = useState('');
  const [type, setType] = useState('');
  const [matId, setMatId] = useState('');
  const [configMaster, setConfigMaster] = useState('');
  const [instructions, setInstructions] = useState([]);
  const [bmrs, setBmrs] = useState([]);
  const [data, setData] = useState({});
  const [extraGranulardata, setExtraGranulardata] = useState([]);
  const [firstIndexData, setFirstIndexData] = useState([]);
  // const [paramsList, setParamsList] = useState([]);
  // const [process, setProcess] = useState([]);
  // const [isModal, setIsModal] = useState(false);
  // const [parameters, setParameters] = useState([]);
  // const [accessories, setAccessories] = useState([]);
  // const [paramgroup, setParamgroup] = useState();
  // const [showModal, setShowModal] = useState(false);
  // const [isInstructions, setIsInstructions] = useState(false);
  // const [instructiongroup, setInstructiongroup] = useState();

  const dispatch = useDispatch();
  const { user } = getLoggedInUser();
  const { Superadmindata } = getRoles('Quality Assurance');

  // console.log(isInstructions);
  useEffect(() => {
    // handleGetAllProcessList();
    handleGetAllBoms();
    handleGetAllParameters();
    handleGetAllProductMaterials();
  }, [matModal]);

  const handleGetAllParameters = () => {
    dispatch(getAllParamsList()).then((res) => {
      if (res?.payload?.success) {
        // setParamsList(res?.payload?.data);
      }
    });
  };

  const handleGetAllProductMaterials = () => {
    let payload = {
      type:
        (user?.role == 'qc_initiator' && user?.level == 3) ||
        Superadmindata?.[0]?.level == 3
          ? 'product_view'
          : user?.role == 'prod_user'
          ? 'product_view'
          : '',
      id: params?.id
    };
    dispatch(getallprodMaterials(payload)).then((response) => {
      if (response?.payload?.success) {
        console.log(response?.payload?.data);
        setData(response?.payload?.data);
        const extra_granular_data =
          response?.payload?.data?.bmr_product_materials.filter(
            (item) => item?.is_extra == true
          );
        setExtraGranulardata(extra_granular_data);
      }
    });
  };

  const handleGetAllBoms = () => {
    const payload = {
      id: params.id
    };
    dispatch(getboms(payload)).then((res) => {
      if (res?.payload?.success) {
        setProcessList(res?.payload?.data);
        setFirstIndexData(res?.payload?.FirstIndexData);
      }
    });
  };

  const handleGetAllBomsByID = (dataid) => {
    const payload = {
      id: params.id,
      process_group_config_id: dataid ? dataid : ''
    };
    dispatch(getboms(payload)).then((res) => {
      if (res?.payload?.success) {
        // setProcessList(res?.payload?.data);
        setFirstIndexData(res?.payload?.data);
      }
    });
  };

  const handleMaterial = (
    value,
    config_id,
    groupId,
    eqp_id,
    id,
    type,
    configId,
    process_id
  ) => {
    console.log(
      value,
      config_id,
      groupId,
      eqp_id,
      id,
      type,
      configId,
      'kkkkkk'
    );

    setMatModal(true);
    setGroupId(value);
    setConfigId(config_id);
    setMainid(groupId);
    setEqp(eqp_id);
    setType(type);
    setMatId(id);
    setConfigMaster(configId);
    if (type == 'Instructions') {
      let instr = processList.filter((data) => data.id == value);
      console.log(instr, 'llllll');
      setInstructions(
        instr?.[0]?.process_configs?.[process_id]?.instruction_configs
      );
      setBmrs(firstIndexData);
      // setBmrs(instr.length ? instr : firstIndexData);
    }
  };
  console.log(bmrs, 'SSSS');
  //   const onChange = (e) => {
  //     console.log(`checked = ${e.target.checked}`);
  //   };
  // console.log(parameters);
  const onFinish = () => {
    let config_materials = [];
    processList?.map((item) => {
      item?.process_configs?.map((materials) => {
        config_materials.push({
          process_master: [
            {
              process_config_id: materials?.id,
              association_param: materials.params,
              instruction_param: materials.instructions?.[0]?.[0]
            }
          ]
        });
      });
    });
    const payload = { product_id: params.id, process_group: config_materials };
    console.log(payload);
    // dispatch(createbomMateials(payload)).then((res) => {
    //   if (res?.payload?.success) {
    //     history.push(`bmr-params/${params.id}`);
    //   }
    // });
  };

  const cancel = (e) => {
    console.log(e);
  };

  const handleApprove = (status) => {
    const payload = {
      status: status,
      id: params.id
    };
    dispatch(updateProduct(payload)).then((response) => {
      if (response?.payload?.success) {
        message.success(response?.payload?.message);
        history.push('/product-list');
      }
    });
  };
  // const handleAddInstructions = (e, index, i) => {
  //   console.log(e, index, i);
  //   e.preventDefault();
  //   setIsInstructions(true);
  // };

  const handleDelete = (e, type) => {
    console.log(e);
    const payload = {
      product_id: params.id,
      bom_id: type == 'material' ? e : '',
      process_eqp_config_id: type == 'equipment' ? e : '',
      process_eqp_ids: type == 'equipment_config' ? e : '',
      inst_config_id: type == 'instruction' ? e : '',
      process_group_id: type == 'process_group' ? e : '',
      process_config_id: type == 'process' ? e : ''
    };
    dispatch(deleteParams(payload)).then((res) => {
      if (res?.payload?.success) {
        handleGetAllBoms();
        message.success(res?.payload?.message);
      }
    });
  };

  var valueArr = processList.map(function (item) {
    return item.process_group_sequence;
  });
  var isDuplicate = valueArr.some(function (item, idx) {
    return valueArr.indexOf(item) != idx;
  });
  console.log(isDuplicate, 'duplicates123');
  console.log('processList123:', processList);

  return (
    <Layout sider={false}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <ProductDetails
          data={data}
          setData={setData}
          setMatModal={setMatModal}
          matModal={matModal}
          extraGranulardata={extraGranulardata}
          setExtraGranulardata={setExtraGranulardata}
        />
        {processList?.map((item, index) => {
          const listdata = firstIndexData?.length
            ? item?.id == firstIndexData[0].id
              ? firstIndexData[0]
              : item
            : item;

          console.log('listdata', listdata);
          return (
            <>
              <Card
                title={
                  item?.group_mod_name ? (
                    <Space>
                      <DownOutlined
                        onClick={() => handleGetAllBomsByID(item?.id)}
                      />
                      <Tag
                        color="rgb(201 23 97)"
                        style={{ fontWeight: '100', fontSize: '18px' }}
                      >
                        {item?.group_mod_name}
                      </Tag>
                    </Space>
                  ) : (
                    <Space>
                      <DownOutlined
                        onClick={() => handleGetAllBomsByID(item?.id)}
                      />
                      {item?.bom_process_group_master?.process_group_name}
                    </Space>
                  )
                }
                className={styles.CustomPanel}
                key={index}
                extra={
                  <Space>
                    {data?.status !== 'Approved' ? (
                      <Popconfirm
                        title="Are you sure you want to delete this Process Group?"
                        description="Are you sure to delete this Process Group?"
                        onConfirm={() =>
                          handleDelete(item?.id, 'process_group')
                        }
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        <DeleteOutlined
                          style={{
                            color: '#c91761'
                          }}
                        />
                      </Popconfirm>
                    ) : (
                      ''
                    )}
                    {item?.group_mod_name ? (
                      // <Tag color="rgb(201 23 97)" style={{ fontWeight: '100' }}>
                      <span style={{ fontSize: '18px' }}>
                        {item?.bom_process_group_master?.process_group_name}
                      </span>
                    ) : (
                      // </Tag>
                      ''
                    )}
                    {
                      <>
                        {data?.status !== 'Approved' ? (
                          <CustomButton
                            type="primary"
                            size="small"
                            ghost
                            onClick={() =>
                              history.push(`/bmr-config/${params.id}`, {
                                data: processList
                              })
                            }
                          >
                            Add New Process Group
                          </CustomButton>
                        ) : (
                          ''
                        )}
                      </>
                      /* { */
                      // item?.is_cloned ?
                      // <EditOutlined />
                      //  : ''
                      /* } */
                    }
                    {isDuplicate ? (
                      <CustomButton
                        type="primary"
                        size="small"
                        ghost
                        onClick={() =>
                          handleMaterial(
                            item?.id,
                            '',
                            item?.process_group_id,
                            '',
                            '',
                            'sequence'
                          )
                        }
                      >
                        Sequence
                      </CustomButton>
                    ) : (
                      ''
                    )}
                  </Space>
                }
              >
                {listdata?.process_configs?.map((param, i) => {
                  return (
                    <>
                      <Badge.Ribbon
                        text={
                          listdata?.bom_process_group_master?.material_level
                            ? 'Materials'
                            : 'Lot'
                        }
                        // style={{ top: '17px !important' }}
                        className={styles.tagStyle2}
                      >
                        <Card
                          key={i}
                          title={
                            param?.process_mod_name ? (
                              <Tag
                                color="rgb(201 23 97)"
                                style={{ fontWeight: '100', fontSize: '18px' }}
                              >
                                {param?.process_mod_name}
                              </Tag>
                            ) : (
                              param?.bom_process_master?.process_name
                            )
                          }
                          className={styles.SubPanel}
                          extra={
                            <Space>
                              {data?.status !== 'Approved' ? (
                                <Popconfirm
                                  title="Are you sure you want to delete this Process?"
                                  description="Are you sure to delete this Process?"
                                  onConfirm={() =>
                                    handleDelete(param?.id, 'process')
                                  }
                                  onCancel={cancel}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <DeleteOutlined
                                    style={{
                                      color: '#c91761'
                                    }}
                                  />
                                </Popconfirm>
                              ) : (
                                ''
                              )}
                              {data?.status !== 'Approved' ? (
                                <CustomButton
                                  type="primary"
                                  size="small"
                                  ghost
                                  // style={{ marginLeft: '-10%' }}
                                  // onClick={(e) => {
                                  //   handleAddInstructions(e, index);
                                  // }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleMaterial(
                                      item?.id,
                                      param?.id,
                                      '',
                                      item?.process_group_id,
                                      '',
                                      'Instructions',
                                      '',
                                      i
                                    );
                                  }}
                                >
                                  Add Instructions
                                </CustomButton>
                              ) : (
                                ''
                              )}
                              {param?.process_mod_name ? (
                                // <Tag
                                //   color="rgb(201 23 97)"
                                //   style={{ fontWeight: '100' }}
                                // >
                                <span
                                  style={{
                                    fontSize: '18px',
                                    marginRight: '18px'
                                  }}
                                >
                                  {param?.bom_process_master?.process_name}
                                </span>
                              ) : (
                                ''
                              )}
                            </Space>
                          }
                        >
                          <>
                            <Row>
                              <Col span={24}>
                                <p
                                  style={{
                                    fontSize: '13px',
                                    color: '#263238'
                                    // marginTop: '7px'
                                  }}
                                >
                                  Materials used during this process{' '}
                                  {
                                    // item?.is_cloned ||
                                    (user?.role == 'prod_user' &&
                                      user?.level == 2 &&
                                      data?.status == 'Rejected') ||
                                    (user?.role == 'prod_user' &&
                                      user?.level == 3 &&
                                      data?.status == 'Rejected') ? (
                                      <Space>
                                        <EditOutlined
                                          style={{
                                            // marginRight: '80px',
                                            color: '#c91761'
                                          }}
                                          onClick={() =>
                                            handleMaterial(
                                              item?.id,
                                              param?.id,
                                              item?.process_group_id,
                                              '',
                                              '',
                                              'materials'
                                            )
                                          }
                                        />
                                      </Space>
                                    ) : (
                                      ''
                                    )
                                  }
                                </p>
                              </Col>
                            </Row>
                            <Row gutter={24}>
                              <Col span={24}>
                                {param?.bom_materials?.map((item, j) => {
                                  return (
                                    <Space size={1} key={j}>
                                      {data?.status !== 'Approved' ? (
                                        <p className={styles.paragraph} key={j}>
                                          <Tag
                                            key={j}
                                            // style={{
                                            //   padding: '3px',
                                            //   color: 'rgb(201 23 97) !important'
                                            // }}
                                            className={styles.tagStyle}
                                          >
                                            {item?.material?.name}{' '}
                                            <Popconfirm
                                              title="Are you sure you want to delete this Material?"
                                              description="Are you sure to delete this task?"
                                              onConfirm={() =>
                                                handleDelete(
                                                  item?.id,
                                                  'material'
                                                )
                                              }
                                              onCancel={cancel}
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                              <CloseCircleOutlined
                                                style={{
                                                  color: '#c91761'
                                                }}
                                              />
                                            </Popconfirm>
                                          </Tag>
                                        </p>
                                      ) : (
                                        ''
                                      )}
                                    </Space>
                                  );
                                })}
                              </Col>
                            </Row>
                            <Row gutter={24}>
                              <Col span={24}>
                                {/* {param?.equipment_master?.map((item, j) => { */}
                                {/* return ( */}
                                <Space size={1} direction="vertical">
                                  <span
                                    style={{
                                      fontSize: '14px',
                                      color: '#263238'
                                      // marginTop: '7px'
                                    }}
                                  >
                                    Equipments:{' '}
                                    {
                                      // item?.is_cloned ||
                                      (user?.role == 'prod_user' &&
                                        user?.level == 2 &&
                                        data?.status == 'Rejected') ||
                                      (user?.role == 'prod_user' &&
                                        user?.level == 3 &&
                                        data?.status == 'Rejected') ? (
                                        <Space>
                                          <EditOutlined
                                            style={{
                                              // marginRight: '80px',
                                              color: '#c91761'
                                            }}
                                            onClick={() =>
                                              handleMaterial(
                                                item?.id,
                                                param?.id,
                                                item?.process_group_id,
                                                '',
                                                '',
                                                'equipments'
                                              )
                                            }
                                          />
                                        </Space>
                                      ) : (
                                        ''
                                      )
                                    }
                                  </span>

                                  {param?.process_equipment_config?.map(
                                    (equips, k) => {
                                      return (
                                        <>
                                          <Row gutter={24}>
                                            <Col span={20}>
                                              <p
                                                className={styles.paragraph}
                                                key={k}
                                              >
                                                <Tag
                                                  className={styles.tagStyle}
                                                >
                                                  {
                                                    equips?.equipment_master
                                                      ?.equipment_name
                                                  }
                                                </Tag>

                                                {item?.is_cloned ? (
                                                  <>
                                                    <Tag
                                                      className={
                                                        styles.buttonStyle
                                                      }
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleMaterial(
                                                          item?.id,
                                                          param?.id,

                                                          item?.process_group_id,
                                                          equips?.id,
                                                          equips
                                                            ?.equipment_master
                                                            ?.id,
                                                          'Equipment',
                                                          ''
                                                        );
                                                      }}
                                                    >
                                                      Add IDs
                                                    </Tag>
                                                    <Popconfirm
                                                      title="Are you sure you want to delete this Equipment?"
                                                      description="Are you sure to delete this task?"
                                                      onConfirm={() =>
                                                        handleDelete(
                                                          equips?.id,
                                                          'equipment'
                                                        )
                                                      }
                                                      onCancel={cancel}
                                                      okText="Yes"
                                                      cancelText="No"
                                                    >
                                                      <DeleteOutlined
                                                        style={{
                                                          color: '#c91761'
                                                        }}
                                                      />
                                                    </Popconfirm>
                                                  </>
                                                ) : (
                                                  ''
                                                )}
                                              </p>
                                            </Col>
                                          </Row>
                                          {equips?.process_eqp_ids?.map(
                                            (eqp_id, l) => {
                                              return (
                                                <Row gutter={24} key={l}>
                                                  <Col span={24}>
                                                    <p
                                                      className={
                                                        styles.paragraph
                                                      }
                                                      key={l}
                                                    >
                                                      <Tag
                                                        className={
                                                          styles.tagStyle
                                                        }
                                                      >
                                                        {
                                                          eqp_id
                                                            ?.equipment_model
                                                            ?.equipment_id
                                                        }
                                                      </Tag>
                                                      <Tag
                                                        className={
                                                          styles.tagStyle
                                                        }
                                                      >
                                                        {
                                                          eqp_id
                                                            ?.equipment_model
                                                            ?.equipment_capacity
                                                        }
                                                      </Tag>
                                                      {/* <Tag
                                                        className={
                                                          styles.buttonStyle
                                                        }
                                                        onClick={(e) => {
                                                          e.stopPropagation();
                                                          handleMaterial(
                                                            item?.id,
                                                            param?.id,

                                                            item?.process_group_id,
                                                            eqp_id
                                                              ?.equipment_model
                                                              ?.id,
                                                            eqp_id.id,
                                                            'accessories',
                                                            eqp_id?.process_eqp_config_id
                                                          );
                                                        }}
                                                      >
                                                        Add Accessories
                                                      </Tag> */}
                                                      {/* {item?.is_cloned ? ( */}
                                                      <>
                                                        {data?.status !==
                                                        'Approved' ? (
                                                          <>
                                                            <Tag
                                                              className={
                                                                styles.buttonStyle
                                                              }
                                                              onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleMaterial(
                                                                  item?.id,
                                                                  param?.id,

                                                                  item?.process_group_id,
                                                                  eqp_id
                                                                    ?.equipment_model
                                                                    ?.id,
                                                                  eqp_id.id,
                                                                  'parameter',
                                                                  eqp_id?.process_eqp_config_id
                                                                );
                                                              }}
                                                            >
                                                              Add Parameters
                                                            </Tag>
                                                            <Tag
                                                              className={
                                                                styles.buttonStyle
                                                              }
                                                              onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleMaterial(
                                                                  item?.id,
                                                                  param?.id,

                                                                  item?.process_group_id,
                                                                  eqp_id
                                                                    ?.equipment_model
                                                                    ?.id,
                                                                  eqp_id.id,
                                                                  'accessories',
                                                                  eqp_id?.process_eqp_config_id
                                                                );
                                                              }}
                                                            >
                                                              Add Accessories
                                                            </Tag>
                                                          </>
                                                        ) : (
                                                          ''
                                                        )}
                                                        {item?.is_cloned ? (
                                                          <Popconfirm
                                                            title="Are you sure you want to delete this Equipment?"
                                                            description="Are you sure to delete this task?"
                                                            onConfirm={() =>
                                                              handleDelete(
                                                                eqp_id?.id,
                                                                'equipment_config'
                                                              )
                                                            }
                                                            onCancel={cancel}
                                                            okText="Yes"
                                                            cancelText="No"
                                                          >
                                                            <DeleteOutlined
                                                              style={{
                                                                color: '#c91761'
                                                              }}
                                                            />
                                                          </Popconfirm>
                                                        ) : (
                                                          ''
                                                        )}
                                                      </>
                                                      {/* ) : (
                                                        ''
                                                      )} */}
                                                    </p>
                                                  </Col>
                                                  <Space direction="vertical">
                                                    {eqp_id?.parameter_configs
                                                      .length ? (
                                                      <>
                                                        <Space direction="vertical">
                                                          <Row
                                                            gutter={24}
                                                            className={
                                                              styles.gap
                                                            }
                                                          >
                                                            <Col span={24}>
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    '14px'
                                                                }}
                                                              >
                                                                Set Parameters:
                                                              </span>
                                                            </Col>
                                                          </Row>
                                                          {eqp_id?.parameter_configs?.map(
                                                            (item, index) => {
                                                              return (
                                                                <>
                                                                  <Space>
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          '14px',
                                                                        color:
                                                                          'black'
                                                                      }}
                                                                      key={
                                                                        index
                                                                      }
                                                                    >
                                                                      Type of
                                                                      parameter:{' '}
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            '14px',
                                                                          color:
                                                                            'gray'
                                                                        }}
                                                                      >
                                                                        {
                                                                          item?.name_param
                                                                        }
                                                                      </span>
                                                                    </span>
                                                                    {/* </Col>

                                                                  <Col span={6}> */}
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          '14px',
                                                                        color:
                                                                          'black'
                                                                      }}
                                                                      key={
                                                                        index
                                                                      }
                                                                    >
                                                                      Value:{' '}
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            '14px',
                                                                          color:
                                                                            'gray',
                                                                          marginTop:
                                                                            '-6px !important'
                                                                        }}
                                                                      >
                                                                        {item?.absolute_value
                                                                          ? item?.absolute_value
                                                                          : item?.low_param &&
                                                                            item?.high_param
                                                                          ? `${item?.low_param} to ${item?.high_param}`
                                                                          : 'NA'}
                                                                      </span>
                                                                    </span>
                                                                    {/* </Col>
                                                                  <Col span={8}> */}
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          '14px',
                                                                        color:
                                                                          'black'
                                                                      }}
                                                                      key={
                                                                        index
                                                                      }
                                                                    >
                                                                      UOM:{' '}
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            '14px',
                                                                          color:
                                                                            'gray'
                                                                        }}
                                                                      >
                                                                        {
                                                                          item?.uom
                                                                        }
                                                                      </span>
                                                                    </span>
                                                                  </Space>
                                                                  {/* </Col>
                                                                </Row> */}
                                                                </>
                                                              );
                                                            }
                                                          )}
                                                        </Space>
                                                      </>
                                                    ) : (
                                                      ''
                                                    )}
                                                    {eqp_id?.accessory_configs
                                                      .length ? (
                                                      <>
                                                        <Space direction="vertical">
                                                          <Row
                                                            gutter={24}
                                                            className={
                                                              styles.gap
                                                            }
                                                          >
                                                            <Col span={24}>
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    '14px'
                                                                }}
                                                              >
                                                                Set Accessories:
                                                              </span>
                                                            </Col>
                                                          </Row>
                                                          {eqp_id?.accessory_configs?.map(
                                                            (item, index) => {
                                                              return (
                                                                <>
                                                                  <Space>
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          '14px',
                                                                        color:
                                                                          'black'
                                                                      }}
                                                                      key={
                                                                        index
                                                                      }
                                                                    >
                                                                      Type of
                                                                      accessory:{' '}
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            '14px',
                                                                          color:
                                                                            'gray'
                                                                        }}
                                                                      >
                                                                        {
                                                                          item?.name_accessory
                                                                        }
                                                                      </span>
                                                                    </span>
                                                                    {/* </Col>

                                                                  <Col span={6}> */}
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          '14px',
                                                                        color:
                                                                          'black'
                                                                      }}
                                                                      key={
                                                                        index
                                                                      }
                                                                    >
                                                                      Value:{' '}
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            '14px',
                                                                          color:
                                                                            'gray',
                                                                          marginTop:
                                                                            '-6px !important'
                                                                        }}
                                                                      >
                                                                        {item?.absolute_value
                                                                          ? item?.absolute_value
                                                                          : item?.low_param &&
                                                                            item?.high_param
                                                                          ? `${item?.low_param} to ${item?.high_param}`
                                                                          : 'NA'}
                                                                      </span>
                                                                    </span>
                                                                    {/* </Col>
                                                                  <Col span={8}> */}
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          '14px',
                                                                        color:
                                                                          'black'
                                                                      }}
                                                                      key={
                                                                        index
                                                                      }
                                                                    >
                                                                      UOM:{' '}
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            '14px',
                                                                          color:
                                                                            'gray'
                                                                        }}
                                                                      >
                                                                        {
                                                                          item?.uom
                                                                        }
                                                                      </span>
                                                                    </span>
                                                                  </Space>
                                                                  {/* </Col>
                                                                </Row> */}
                                                                </>
                                                              );
                                                            }
                                                          )}
                                                        </Space>
                                                      </>
                                                    ) : (
                                                      ''
                                                    )}
                                                  </Space>
                                                </Row>
                                              );
                                            }
                                          )}
                                        </>
                                      );
                                    }
                                  )}
                                </Space>
                                {/* );
                                  })} */}
                              </Col>
                            </Row>
                            {param?.instruction_configs?.length ? (
                              <Card
                                title="Instructions"
                                className={styles.SubPanel22}
                                extra={
                                  // item?.is_cloned ||
                                  (user?.role == 'prod_user' &&
                                    user?.level == 2 &&
                                    data?.status == 'Rejected') ||
                                  (user?.role == 'prod_user' &&
                                    user?.level == 3 &&
                                    data?.status == 'Rejected') ? (
                                    <EditOutlined
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleMaterial(
                                          item?.id,
                                          param?.id,

                                          item?.process_group_id,
                                          '',
                                          '',
                                          'Instructions'
                                        );
                                      }}
                                    />
                                  ) : (
                                    ''
                                  )
                                }
                              >
                                <>
                                  <Row gutter={24}>
                                    {param?.instruction_configs?.map((K, s) => {
                                      return (
                                        <>
                                          <Col span={24}>
                                            <Card
                                              title={`Step-${s + 1}`}
                                              className={styles.SubPanel22}
                                              extra={
                                                item?.is_cloned ? (
                                                  <Popconfirm
                                                    title="Are you sure you want to delete this Material?"
                                                    description="Are you sure to delete this task?"
                                                    onConfirm={() =>
                                                      handleDelete(
                                                        K?.id,
                                                        'instruction'
                                                      )
                                                    }
                                                    onCancel={cancel}
                                                    okText="Yes"
                                                    cancelText="No"
                                                  >
                                                    <DeleteOutlined
                                                      style={{
                                                        color: '#c91761'
                                                      }}
                                                    />
                                                  </Popconfirm>
                                                ) : (
                                                  ''
                                                )
                                              }
                                            >
                                              <Col span={24}>
                                                <span
                                                  style={{
                                                    fontSize: '14px',
                                                    fontWeight: '100'
                                                    // color: 'black'
                                                  }}
                                                  key={s}
                                                >
                                                  <span
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        K?.instruction_step
                                                    }}
                                                    // className={styles.tdbody}
                                                  />
                                                </span>
                                              </Col>
                                              <Col span={24}>
                                                <Collapse
                                                  collapsible="icon"
                                                  defaultActiveKey={['0']}
                                                  className={styles.SubPanel21}
                                                  // key={index}
                                                  // className={styles.cardStyles_approver2}
                                                >
                                                  <Panel
                                                    header={'Execution Details'}
                                                  >
                                                    <Card>
                                                      {/* <Row> */}

                                                      <Row gutter={24}>
                                                        {K.execute_bmr_details?.map(
                                                          (exe, d) => {
                                                            return (
                                                              <Col
                                                                span={12}
                                                                key={d}
                                                              >
                                                                <FormItem
                                                                  label={`${exe?.exe_name}`}
                                                                  rules={[
                                                                    {
                                                                      required: false,
                                                                      message:
                                                                        'Enter Lots'
                                                                    }
                                                                  ]}
                                                                  className={
                                                                    styles.customLable11
                                                                  }
                                                                >
                                                                  {exe?.exe_type ==
                                                                  'Input' ? (
                                                                    <Input
                                                                      placeholder={
                                                                        exe?.exe_name
                                                                      }
                                                                      disabled
                                                                    />
                                                                  ) : exe?.exe_type ==
                                                                    'Date' ? (
                                                                    <DatePicker
                                                                      disabled
                                                                      style={{
                                                                        width:
                                                                          '100%'
                                                                      }}
                                                                    />
                                                                  ) : exe?.exe_type ==
                                                                    'Date&Time' ? (
                                                                    <DatePicker
                                                                      disabled
                                                                      showTime={{
                                                                        format:
                                                                          'HH:mm'
                                                                      }}
                                                                      style={{
                                                                        width:
                                                                          '100%'
                                                                      }}
                                                                    />
                                                                  ) : (
                                                                    ''
                                                                  )}
                                                                </FormItem>
                                                              </Col>
                                                            );
                                                          }
                                                        )}
                                                      </Row>
                                                    </Card>
                                                  </Panel>
                                                </Collapse>
                                              </Col>
                                            </Card>
                                            <br />
                                          </Col>
                                        </>
                                      );
                                    })}
                                  </Row>
                                </>
                              </Card>
                            ) : (
                              ''
                            )}
                          </>
                        </Card>
                      </Badge.Ribbon>
                      <br />
                    </>
                  );
                })}
              </Card>
              <br />
            </>
          );
        })}

        <br />
        <FormItem className="text-center">
          <Space>
            {/* {truckShow ? (
              <Image
                src={truck}
                preview={false}
                style={{ width: '100px !important', height: '100px' }}
              />
            ) : ( */}

            {/* )} */}
            <CustomButton
              className={styles.inwardButton}
              type="primary"
              ghost
              onClick={() => history.push('/product-list')}
            >
              Back
            </CustomButton>
            {(user?.level == 3 &&
              user?.role == 'qc_initiator' &&
              data?.status == 'Pending') ||
            (Superadmindata?.[0]?.level == 3 && data?.status == 'Pending') ? (
              <>
                <CustomButton
                  className={styles.inwardButton}
                  onClick={() => handleApprove('Approved')}
                  type="primary"
                  // htmlType="submit"
                >
                  Approve
                </CustomButton>
                <CustomButton
                  className={styles.inwardButton}
                  onClick={() => handleApprove('Rejected')}
                  type="primary"
                  // htmlType="submit"
                  ghost
                >
                  Reject
                </CustomButton>
              </>
            ) : user?.role !== 'qc_initiator' || data?.status !== 'Approved' ? (
              <CustomButton
                className={styles.inwardButton}
                type="primary"
                ghost
                onClick={() => history.push(`/bmr-config/${params.id}`)}
                disabled={isDuplicate}
              >
                Proceed to Configure
              </CustomButton>
            ) : (
              ''
            )}
          </Space>
        </FormItem>

        {matModal ? (
          <AddMaterial
            matModal={matModal}
            setMatModal={setMatModal}
            product_id={params.id}
            processGroupId={groupId}
            configId={configId}
            groupId={mainid}
            eqp={eqp}
            eqptype={type}
            matId={matId}
            configMaster={configMaster}
            instructions={instructions}
            bmrs={bmrs}
            newprocessList={processList}
          />
        ) : (
          ''
        )}
      </Form>
    </Layout>
  );
};
export default ConfigProcessView;
