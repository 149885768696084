import React, { useState, useEffect } from 'react';
import Layout from '@/common/Layout';
import {
  Row,
  Col,
  Form,
  Card,
  Input,
  message,
  InputNumber,
  Space,
  Select,
  Image
} from 'antd';
import styles from '@/common/Layout/index.module.less';
// import { getDispenseMaterialDetails } from '@/features/dispensing/redux/slice';
import FormItem from '@/common/FormItem';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import truck from '@/features/generateMrn/components/images/truck-delivery-done.gif';
// import { useState } from 'react';
import CustomButton from '@/common/CustomButton';
import { QrcodeOutlined } from '@ant-design/icons';
import {
  createGranuler,
  getAllMatrials,
  createProdGross
} from '../../redux/slice';
import { getAllDispenseBooths } from '@/features/dispensing/redux/slice';
import { history } from 'app/history';
import { QrReader } from 'react-qr-reader';

const Weighing = () => {
  const { WeighingMaterials, prodDetails } = useSelector(
    (state) => state.product
  );
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const params = useParams();

  const [openCam, setOpenCam] = useState(false);
  const [materialId, setMaterialId] = useState('');
  const [scannedMats, setScannedMats] = useState([]);
  const [matindex, setMatindex] = useState('');
  const [dispenseBooths, setDispenseBooths] = useState([]);
  const [ipAddress, setIpAddress] = useState();
  const [truckShow, setTruckShow] = useState(false);

  console.log(materialId, ipAddress);
  useEffect(() => {
    handleGetAllMaterials();
    handleGetDispenseBooths();
  }, []);

  const handleGetAllMaterials = () => {
    let payload = {
      dispensing_request_id: params.id
    };
    dispatch(getAllMatrials(payload)).then((response) => {
      setScannedMats(
        response?.payload?.data?.map(() => {
          return {
            material_id: '',
            material_name: '',
            code: '',
            batch_number: '',
            prod_gross_weight: ''
          };
        })
      );
    });
  };

  const handleQrCode = (id, index) => {
    console.log(id);
    setMaterialId(id);
    setMatindex(index);
    setOpenCam(true);
  };

  const handleGetDispenseBooths = () => {
    dispatch(getAllDispenseBooths()).then((result) => {
      if (result?.payload?.success) {
        setDispenseBooths(result?.payload?.data);
      }
    });
  };

  const handleBalance = (e) => {
    setIpAddress(e);
  };

  const handleQrScan = async (scanData) => {
    // console.log(`loaded data data`, scanData);
    if (scanData && scanData !== '') {
      console.log(`loaded >>>`, scanData);
      const material_id = scanData.text.slice(
        scanData.text.lastIndexOf('?') + 1
      );

      const scaned_id = material_id
        .slice(3)
        .substr(0, material_id.slice(3).indexOf('&'));

      const filtered_data = WeighingMaterials.filter(
        (data) => data?.id == materialId
      );

      console.log(scaned_id, materialId);

      if (parseInt(scaned_id) === parseInt(materialId)) {
        const list = [...scannedMats];
        list[matindex].material_id = filtered_data[0]?.material?.id;
        list[matindex].new_dispensary_id = materialId;
        list[matindex].material_name = filtered_data[0]?.material?.name;
        list[matindex].code = filtered_data[0]?.material?.code;
        list[matindex].batch_number =
          filtered_data[0]?.batch_detail?.inhouse_batch_number;
        setScannedMats(list);
        message.success('This material belong to this LOT');
      } else {
        message.error('This material does not belong to this LOT');
      }
      setOpenCam(false);
    }
  };
  const handleError = (err) => {
    console.error(err);
  };

  const handleGrossWeight = (e, index) => {
    if (parseFloat(WeighingMaterials[index].gross_weight) === e) {
      const list = [...scannedMats];
      list[index].prod_gross_weight = e;
      setScannedMats(list);
    }
  };

  const handleSubmit = (status) => {
    console.log(status);
    setTruckShow(true);
    const payload = {
      dispensing_request_id: params.id,
      material_gross_weights: scannedMats,
      status: status
    };
    dispatch(createProdGross(payload)).then((response) => {
      if (response?.payload?.success) {
        setTruckShow(false);
        // message.success(response?.payload.message);
        // history.push(`/sifting-and-milling/${params.id}`);
        history.push(`/execute-bmr/${params.id}`);
      }
    });
  };
  const onFinish = () => {
    var product_granulation = [];
    // console.log(lots);
    // lots.map((item) => {
    //   item?.weights.map((data) => {
    //     product_granulation.push(data);
    //   });
    // });
    console.log(product_granulation);
    const payload = {
      dispensing_request_id: params.id
      // product_granulation: lots
    };
    console.log(payload);
    dispatch(createGranuler(payload)).then((response) => {
      if (response?.payload?.success) {
        message.success(response?.payload.message);
        history.push(`/execute-bmr/${params.id}`);
      }
    });
  };

  console.log(
    scannedMats.every((item) => {
      return item.gross_weight !== '';
    })
  );
  console.log(scannedMats);
  return (
    <Layout sider={false}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
      >
        <Card
          title={<span style={{ fontWeight: 'bold' }}>WIP STORE</span>}
          className={styles.CustomPanel}
        >
          <Card className={styles.subpanel}>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem label="Product Name" className={styles.customLable}>
                  <Input
                    disabled
                    value={prodDetails?.product_detail?.name || 'NA'}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="Product Batch Number"
                  className={styles.customLable}
                >
                  <Input
                    disabled
                    value={prodDetails?.product_batch_number || 'NA'}
                  />
                </FormItem>
              </Col>

              <Col span={12}>
                <FormItem label="Effective Date" className={styles.customLable}>
                  <Input
                    disabled
                    value={
                      prodDetails?.effective_date
                        ? moment(prodDetails?.effective_date).format(
                            'DD MMM YYYY'
                          )
                        : 'NA'
                    }
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="Batch Size" className={styles.customLable}>
                  <Input
                    disabled
                    value={prodDetails?.product_detail?.batch_size || 'NA'}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={12}>
                <FormItem label="Balance Name" className={styles.customLable}>
                  <Select
                    placeholder="Select Balance Name"
                    onChange={(e) => handleBalance(e)}
                  >
                    {dispenseBooths?.map((item, index) => {
                      return (
                        <option value={item?.ip_address} key={index}>
                          {item?.balance_name}
                        </option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="Equipment Name" className={styles.customLable}>
                  <Input
                    disabled
                    value={prodDetails?.product_detail?.equipment_name || 'NA'}
                  />
                </FormItem>
              </Col>
            </Row>
          </Card>
          <br />
          {WeighingMaterials?.map((item, index) => {
            const lot_no = item?.lot_no ? ` - ${item?.lot_no}` : '';
            const part_no = item?.part_no ? ` - ${item?.part_no}` : '';
            return (
              <>
                <Card
                  className={styles.CustomPanel}
                  key={index}
                  title={`Material ${lot_no} ${part_no}`}
                  extra={
                    <CustomButton
                      onClick={() => handleQrCode(item?.id, index)}
                      className={styles.qr}
                    >
                      <QrcodeOutlined
                        onClick={() => handleQrCode(item?.id, index)}
                      />
                    </CustomButton>
                  }
                >
                  <Row gutter={24} key={index}>
                    <Col xs={24} xl={8} span={24} md={24} sm={24}>
                      <FormItem
                        label="Name"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Material Name'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter Material Name"
                          value={item?.material?.name || ''}
                          disabled
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={4} span={24} md={24} sm={24}>
                      <FormItem
                        label="Code"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Material Code'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter Material Code"
                          value={item?.material?.code || ''}
                          disabled
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={6} span={24} md={24} sm={24}>
                      <FormItem
                        label="Inhouse Batch #"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Inhouse Batch Number'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter Inhouse Batch Number"
                          value={item?.batch_detail?.inhouse_batch_number || ''}
                          disabled
                        />
                      </FormItem>
                    </Col>
                    {/* <Col xs={24} xl={4} span={24} md={24} sm={24}>
                    <FormItem
                      label="Net Weight#"
                      rules={[
                        {
                          required: false,
                          message: 'Enter Inhouse Batch Number'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Enter Net Weight"
                        value={item?.net_weight || ''}
                        disabled
                      />
                    </FormItem>
                  </Col> */}
                    <Col xs={24} xl={4} span={24} md={24} sm={24}>
                      <FormItem
                        label="Gross Weight"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Inhouse Batch Number'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter Gross Weight"
                          value={item?.gross_weight || ''}
                          disabled
                        />
                      </FormItem>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col xs={24} xl={8} span={24} md={24} sm={24}>
                      <FormItem
                        label="Name"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Material Name'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter Material Name"
                          value={
                            item?.weighing_gross_weights.length
                              ? item?.material?.name
                              : scannedMats[index]?.material_name || ''
                          }
                          disabled
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={4} span={24} md={24} sm={24}>
                      <FormItem
                        label="Code"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Material Code'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter Material Code"
                          value={
                            item?.weighing_gross_weights.length
                              ? item?.material?.code
                              : scannedMats[index]?.code || ''
                          }
                          disabled
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={6} span={24} md={24} sm={24}>
                      <FormItem
                        label="Inhouse Batch #"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Inhouse Batch Number'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter Inhouse Batch Number"
                          value={
                            item?.weighing_gross_weights.length
                              ? item?.batch_detail?.inhouse_batch_number
                              : scannedMats[index]?.batch_number || ''
                          }
                          disabled
                        />
                      </FormItem>
                    </Col>
                    {/* <Col xs={24} xl={4} span={24} md={24} sm={24}>
                    <FormItem
                      label="Net Weight#"
                      rules={[
                        {
                          required: false,
                          message: 'Enter Inhouse Batch Number'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Enter Net Weight"
                        value={item?.net_weight || ''}
                        disabled
                      />
                    </FormItem>
                  </Col> */}
                    <Col xs={24} xl={4} span={24} md={24} sm={24}>
                      <FormItem
                        label="Gross Weight"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Inhouse Batch Number'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <InputNumber
                          style={{ width: '100%' }}
                          // max={parseFloat(item?.gross_weight)}
                          placeholder="Enter Gross Weight"
                          onChange={(e) => handleGrossWeight(e, index)}
                          disabled={
                            scannedMats[index]?.material_name ? false : true
                          }
                          value={
                            item?.weighing_gross_weights.length
                              ? item?.weighing_gross_weights[0]
                                  ?.prod_gross_weight
                              : ''
                          }
                        />
                      </FormItem>
                    </Col>
                  </Row>
                </Card>
                <br />
              </>
            );
          })}
          {openCam ? (
            <QrReader
              constraints={{ facingMode: 'environment' }}
              delay={1000}
              onError={handleError}
              onResult={(result) => handleQrScan(result)}
              // chooseDeviceId={()=>selected}
              style={{ width: '3px', height: '3px' }}
            />
          ) : (
            ''
          )}
        </Card>
        <br />
        <FormItem className="text-center">
          <Space size={6}>
            {truckShow ? (
              <Image
                src={truck}
                preview={false}
                style={{ width: '100px !important', height: '100px' }}
              />
            ) : (
              <>
                <CustomButton
                  className={styles.inwardButton}
                  // htmlType="submit"
                  onClick={() => handleSubmit('Approved')}
                  type="primary"
                  // disabled={
                  //   !scannedMats.every((item) => {
                  //     return item.prod_gross_weight !== '';
                  //   })
                  // }
                >
                  Approve & Proceed
                </CustomButton>
                <CustomButton
                  className={styles.inwardButton}
                  // htmlType="submit"
                  onClick={() => handleSubmit('Rejected')}
                  // type="danger"
                  style={{
                    color: '#fff',
                    backgroundColor: '#c82333',
                    borderColor: '#bd2130'
                  }}
                >
                  Reject
                </CustomButton>
                <CustomButton
                  className={styles.inwardButton}
                  // htmlType="submit"
                  onClick={() => handleSubmit('OnHold')}
                  type="primary"
                  style={{
                    color: '#fff',
                    backgroundColor: '#138496',
                    borderColor: '#117a8b'
                  }}
                >
                  Hold
                </CustomButton>
              </>
            )}
          </Space>
        </FormItem>
      </Form>
    </Layout>
  );
};
export default Weighing;
