import React from 'react';
import Layout from '@/common/Layout';
import CreateProductsPage from './components/createProductPage';

const ProdUser = () => {
  return (
    <>
      <Layout sider={false}>
        <CreateProductsPage />
      </Layout>
    </>
  );
};

export default ProdUser;
