import React, { useState, useEffect } from 'react';
// import Layout from '@/common/Layout';
import { Form, Card, Space, Input, Tag, message } from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import { getAllProducts, cloneProduct } from '../../redux/slice';
import CustomTable from 'common/Content/CustomTable';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import Layout from '@/common/Layout';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { getLoggedInUser, getRoles } from 'utilities/helpers';

const { Search } = Input;

const ProductsList = ({ loading }) => {
  const [form] = Form.useForm();
  const { user } = getLoggedInUser();
  const { SuperadminProddata } = getRoles('Production');
  //   const { allSamples } = useSelector((state) => state.stocks);
  const [allsuppliers, setAllsuppliers] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [search, setSearch] = useState('');
  //   console.log(allsamples);
  const dispatch = useDispatch();
  useEffect(() => {
    handleGetAllProducts();
  }, [page, search]);

  const handleGetAllProducts = () => {
    const payload = { page: page, search: search };
    dispatch(getAllProducts(payload)).then((response) => {
      if (response?.payload?.success) {
        setAllsuppliers(response?.payload?.data);
        setPagination(response?.payload?.pagination);
      }
    });
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const handleClone = (id) => {
    console.log(id);
    const payload = { product_id: id };
    dispatch(cloneProduct(payload)).then((response) => {
      if (response?.payload?.success) {
        message.success(response?.payload?.message);
      }
    });
  };
  //   console.log(allSamples);
  const columns = [
    {
      title: 'Product Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return user?.level == 3 || SuperadminProddata?.[0]?.level == 3 ? (
          // &&
          //   record?.status === 'Pending'
          // <a href={`/product-details/${record.id}`}>{text}</a>
          <a href={`/bmr-params-view/${record.id}`}>{text}</a>
        ) : user?.role == 'prod_user' || user?.role == 'qa_super_user' ? (
          <a href={`/bmr-params-view/${record.id}`}>{text}</a>
        ) : (
          text
        );
      }
    },
    {
      title: 'BMR/BPR #',
      dataIndex: 'bmr_bpr_code',
      key: 'bmr_bpr_code',
      ellipsis: true,
      width: '100px'
    },
    // {
    //   title: 'Product Batch Number',
    //   dataIndex: 'code',
    //   key: 'code',
    //   ellipsis: true,
    //   width: '100px',
    //   render: (_, record) => {
    //     return record?.material?.code;
    //   }
    // },
    // {
    //   title: 'Effective Date',
    //   dataIndex: 'name',
    //   key: 'name',
    //   ellipsis: true,
    //   width: '100px',
    //   render: (_, record) => {
    //     return record?.effective_date
    //       ? moment(record?.effective_date).format('DD MMM YYYY')
    //       : 'NA';
    //   }
    // },
    {
      title: 'Theoretical Yield',
      dataIndex: 'theoretical_yield',
      key: 'theoretical_yield',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'Batch Size',
      dataIndex: 'theoretical_batch_size',
      key: 'theoretical_batch_size',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'Type',
      dataIndex: 'product_sub_type',
      key: 'product_sub_type',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.createdAt
          ? moment(record?.createdAt).format('DD MMM YYYY')
          : 'NA';
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return (
          <Tag
            color={
              record?.status === 'Pending'
                ? '#007bff'
                : record?.status === 'Rejected'
                ? '#dc3545'
                : record?.status === 'Approved'
                ? '#28a745'
                : '#007bff'
            }
          >
            {record?.status?.toUpperCase()}
          </Tag>
        );
      }
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return (
          <CustomButton
            size="small"
            type="primary"
            ghost
            onClick={() => handleClone(text)}
            disabled={record?.is_cloned}
          >
            {record?.is_cloned ? 'Cloned' : 'Clone'}
          </CustomButton>
        );
      }
    }
  ];

  return (
    <>
      <Layout sider={false}>
        <Form form={form} layout="vertical">
          <Card
            title={<span>Product Details</span>}
            className={`${styles.cardStyles_approver}`}
            extra={
              <Space>
                {user?.role == 'prod_user' || user?.role == 'qa_super_user' ? (
                  <CustomButton
                    className={styles.inwardButton}
                    // onClick={() => history.push('/create-product')}
                    onClick={() => history.push('/product-create')}
                    type="primary"
                  >
                    Create
                  </CustomButton>
                ) : (
                  ''
                )}
                <Search
                  onChange={(e) => handleChange(e)}
                  className={`w-100 ${styles.searchbar}`}
                  justify="start"
                  align="start"
                  placeholder="Search by Product Name"
                />
              </Space>
            }
          >
            <div className={`${styles.table_container}`}>
              <CustomTable
                columns={columns}
                pagination={{
                  size: 'small',
                  total: pagination?.total_records || 0,
                  showSizeChanger: false,
                  onChange: (page) => {
                    setPage(page);
                  }
                }}
                dataSource={allsuppliers}
                loading={loading}
              />
            </div>
          </Card>
          <br />
          <FormItem className="text-center">
            <CustomButton
              className={styles.inwardButton}
              onClick={() => history.back()}
              type="primary"
            >
              Back to Home
            </CustomButton>
          </FormItem>
        </Form>
      </Layout>
    </>
  );
};
export default ProductsList;
