import React, { useEffect, useState } from 'react';
import {
  Card,
  Row,
  Col,
  Input,
  Form,
  Select,
  // Checkbox,
  message,
  Space,
  Radio,
  Divider,
  DatePicker,
  TimePicker
} from 'antd';
import Layout from '@/common/Layout';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import { useDispatch } from 'react-redux';
import {
  getAllEquips,
  getCurrentProduct,
  createProdClearence,
  getAlllclist,
  createQAClearence
} from '../../redux/slice';
import { useParams } from 'react-router-dom';
import CustomButton from '@/common/CustomButton';
import { getLoggedInUser, getRoles } from 'utilities/helpers';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';
import VerificationModal from '@/features/material/components/VerificationModal';
import { history } from 'app/history';

const LineClearence = () => {
  const [form] = Form.useForm();
  const params = useParams();
  const { user } = getLoggedInUser();
  const { SuperadminProddata } = getRoles('Production');
  const { SuperadminQAdata } = getRoles('Quality Assurance');

  const searchParam = useLocation().search;
  const searchParams = new URLSearchParams(searchParam);

  const [allEquips, setAllEquips] = useState([]);
  const [currentProduct, setCurrentProduct] = useState([]);
  // const [lcMasters, setLcMasters] = useState([]);
  const [inspectionsByProd, setInspectionsByProd] = useState([]);
  const [inspectionsByQA, setInspectionsByQA] = useState([]);
  const [infProd, setInfProd] = useState('');
  const [infQA, setInfQA] = useState('');
  const [lCViewData, setLCViewData] = useState({});
  const [lCQuestions, setLCQuestions] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [data, setData] = useState({});
  const [type, setType] = useState('');
  const [search, setSearch] = useState('');
  const [eqpType, setEqpType] = useState('');
  const [tempLCQtns, setTempLCQtns] = useState([]);
  const [allAccessories, setAllAccessories] = useState([
    {
      accessory_name: '',
      accessory_id: '',
      cleaning: '',
      inspect_by_prod: '',
      inspect_by_qa: ''
    }
  ]);

  const dispatch = useDispatch();

  useEffect(() => {
    handleGetAllEquipements();
    handleGetCurrentProduct();
    searchParams.get('id') ? handleGetAllLCList() : '';
  }, [search]);

  const handleGetAllLCList = () => {
    let payload = { id: searchParams.get('id') };
    dispatch(getAlllclist(payload)).then((res) => {
      if (res?.payload?.success) {
        setLCViewData(res?.payload?.data);
        setInfProd(res?.payload?.data?.infer_prod_dept);
        setInfQA(res?.payload?.data?.infer_qa_dept);
        setAllAccessories(res?.payload?.data?.access_clean_verifies);
        // setLCQuestions(res?.payload?.data?.lc_bmr_eqp_questions);
        let questions_temp = res?.payload?.data?.lc_bmr_eqp_questions.filter(
          (qtn) => qtn?.lc_eqp_question_master?.lc_question_type === 'value'
        );
        let questions = res?.payload?.data?.lc_bmr_eqp_questions.filter(
          (qtn) => qtn?.lc_eqp_question_master?.lc_question_type !== 'value'
        );
        setLCQuestions(questions);
        setTempLCQtns(questions_temp);
        form.setFieldsValue({
          equipment_name: res?.payload?.data?.eqp_lc_master?.id,
          equipment_id: res?.payload?.data?.lc_eqp_id,
          previous_product: res?.payload?.data?.previous_product,
          previous_batch: res?.payload?.data?.previous_batch
        });
        let list = [...inspectionsByProd];
        res?.payload?.data?.lc_bmr_eqp_questions?.map((item) => {
          list.push({
            question: item?.id,
            inspect_by_prod: item?.inspect_by_prod
          });
        });

        setInspectionsByProd(list);
        let qalist = [...inspectionsByQA];
        res?.payload?.data?.lc_bmr_eqp_questions?.map((item) => {
          qalist.push({
            question: item?.id,
            inspect_by_qa: item?.inspect_by_qa
          });
        });

        setInspectionsByQA(qalist);
      }
    });
  };

  const handleGetAllEquipements = () => {
    const payload = { search };
    dispatch(getAllEquips(payload)).then((res) => {
      if (res?.payload?.success) {
        setAllEquips(res?.payload?.data);
      }
    });
  };

  const onSearch = (value) => {
    setSearch(value);
  };

  const handleGetCurrentProduct = () => {
    const payload = { dispensing_request_id: params?.id };
    dispatch(getCurrentProduct(payload)).then((res) => {
      if (res?.payload?.success) {
        setCurrentProduct(res?.payload?.data);
      }
    });
  };

  const handleOnChange = (e) => {
    const eqp_id = allEquips.filter((data) => data?.id == e);
    console.log(eqp_id);
    setEqpType(eqp_id?.[0]?.lc_type);
    let questions = eqp_id?.[0]?.lc_eqp_question_masters.filter(
      (qtn) => qtn?.lc_question_type !== 'value'
    );
    let questions_temp = eqp_id?.[0]?.lc_eqp_question_masters.filter(
      (qtn) => qtn?.lc_question_type === 'value'
    );
    console.log(questions);
    // setLcMasters(eqp_id);
    setLCQuestions(questions);
    setTempLCQtns(questions_temp);
  };

  useEffect(() => {
    form.setFieldsValue({
      equipment_name: lCViewData?.eqp_lc_master?.id,
      equipment_id: lCViewData?.eqp_id_lc_master?.lc_eqp_id,
      previous_product: lCViewData?.previous_product,
      previous_batch: lCViewData?.previous_batch
    });
  }, []);

  const handleOnChangeProd = (id, e, type) => {
    var empData = inspectionsByProd.find((value) => value.question === id);
    console.log(empData);
    if (empData) {
      if (type == 'prod') {
        let list = [...inspectionsByProd];
        list.find((value) => value.question === id).inspect_by_prod = e;
        setInspectionsByProd(list);
      } else {
        let list = [...inspectionsByQA];
        list.find((value) => value.question === id).inspect_by_qa = e;
        setInspectionsByQA(list);
      }
    } else {
      let list = [...inspectionsByProd];
      list.push({ question: id, inspect_by_prod: e });
      setInspectionsByProd(list);
    }
  };
  console.log(lCQuestions);
  const handleOnChangeInfProd = (e, type) => {
    type == 'QA' ? setInfQA(e) : setInfProd(e);
  };

  const onFinish = (values) => {
    if (searchParams.get('id') && user?.role == 'prod_user') {
      values.id = searchParams.get('id');
      values.infer_prod_dept = infProd;
      values.questions = inspectionsByProd;
      values.accessory_verify = allAccessories.length ? allAccessories : [];
      values.dispensing_request_id = params.id;
      setData(values);
      setIsModal(!isModal);
      setType('prod_user');
    } else if (
      searchParams.get('id') &&
      user?.role == 'qc_initiator' &&
      lCViewData?.qa_sign_date
    ) {
      console.log(allAccessories.length, 'access');
      values.id = searchParams.get('id');
      values.infer_qa_dept = infQA;
      values.questions = inspectionsByQA;
      values.accessory_verify = allAccessories.length ? allAccessories : [];
      values.dispensing_request_id = params.id;
      values.isType = 'Edit';
      setType('QA');
      setData(values);
      setIsModal(!isModal);
    } else if (searchParams.get('id') && user?.role == 'qc_initiator') {
      values.id = searchParams.get('id');
      values.infer_qa_dept = infQA;
      values.questions = inspectionsByQA;
      values.accessory_verify = allAccessories.length ? allAccessories : [];
      values.dispensing_request_id = params.id;
      dispatch(createQAClearence(values)).then((res) => {
        if (res?.payload?.success) {
          message.success(res?.payload?.message);
          history.push(`/qa-lc-list/${params.id}`);
        }
      });
    } else {
      values.current_product = currentProduct?.product_detail?.name;
      values.current_batch = currentProduct?.product_batch_number;
      values.infer_prod_dept = infProd;
      // values.infer_qa_dept = '';
      values.questions = inspectionsByProd;
      values.accessory_verify = allAccessories.length ? allAccessories : [];
      values.dispensing_request_id = params.id;
      dispatch(createProdClearence(values)).then((res) => {
        if (res?.payload?.success) {
          message.success(res?.payload?.message);
          history.push(`/qa-lc-list/${params.id}`);
        }
      });
    }
  };

  const handleChangeDoneDate = (e, id, index, type, prop) => {
    console.log(e, id, index, type, prop);

    var empData = inspectionsByProd.find((value) => value.question === id);
    console.log(empData);
    if (empData) {
      if (type == 'prod') {
        let list = [...inspectionsByProd];
        list.find((value) => value.question === id)[prop] =
          prop === 'date_time'
            ? `${moment(e).format('hh:mm')}`
            : prop === 'observed_value'
            ? e.target.value
            : `${moment(e).format('YYYY-MM-DD')}`;
        setInspectionsByProd(list);
      } else {
        let list = [...inspectionsByQA];
        list.find((value) => value.question === id)[prop] =
          prop === 'date_time'
            ? `${moment(e).format('hh:mm')}`
            : prop === 'observed_value'
            ? e.target.value
            : `${moment(e).format('YYYY-MM-DD')}`;
        setInspectionsByQA(list);
      }
    } else {
      let list = [...inspectionsByProd];
      list.push({
        question: id,
        inspect_by_prod: '',
        [prop]:
          prop === 'date_time'
            ? `${moment(e).format('hh:mm')}`
            : prop === 'observed_value'
            ? e.target.value
            : `${moment(e).format('YYYY-MM-DD')}`
      });
      setInspectionsByProd(list);
    }
  };

  const handleAddAccessories = () => {
    setAllAccessories([
      ...allAccessories,
      {
        accessory_name: '',
        accessory_id: '',
        cleaning: '',
        inspect_by_prod: '',
        inspect_by_qa: ''
      }
    ]);
  };
  const handleOnchangeAccessory = (e, index, type) => {
    // if (main_type == 'prod') {
    let list = [...allAccessories];
    list[index][type] = e;
    setAllAccessories(list);
    // } else {
    //   let list = [...allAccessories];
    //   list[index][type] = e;
    //   setAllAccessories(list);
    // }
  };
  console.log(allAccessories);
  const handleDeleteAccessories = (index) => {
    let list = [...allAccessories];
    list.splice(index, 1);
    setAllAccessories(list);
  };
  console.log(inspectionsByProd);
  return (
    <Layout sider={false}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Space direction="vertical">
          <Card
            className={`${styles.cardStyles_approver}`}
            title={
              <span style={{ fontWeight: 'bold' }}>
                Line Clearence Check List
              </span>
            }
            extra={
              searchParams.get('id') ? (
                (user?.role === 'prod_user' &&
                  user?.level == 3 &&
                  lCViewData?.lc_status !== 'Approved') ||
                (user?.role === 'qc_initiator' && user?.level == 3) ||
                SuperadminQAdata?.[0]?.level == 2 ||
                SuperadminProddata?.[0]?.level == 3 ? (
                  <EditOutlined onClick={() => setIsEdit(true)} />
                ) : (
                  ''
                )
              ) : (
                ''
              )
            }
          >
            <Row gutter={24}>
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label="Lineclearance form"
                  name="equipment_name"
                  rules={[
                    {
                      required: true,
                      message: 'Material Code'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    onSearch={onSearch}
                    placeholder="Select Lineclearance form"
                    onChange={(e) => handleOnChange(e)}
                    disabled={searchParams.get('id') ? true : false}
                  >
                    {allEquips?.map((item, index) => {
                      return (
                        <option value={item?.id} key={index}>
                          {item?.line_clr_eqp_name || 'jjjj'}
                        </option>
                      );
                    })}
                  </Select>
                  {/* <Input placeholder="Enter Equipment Name" /> */}
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label="Equipment / Area"
                  name="equipment_id"
                  rules={[
                    {
                      required: true,
                      message: 'Material Code'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter Equipment / Area"
                    disabled={searchParams.get('id') ? true : false}
                    // value={currentProduct?.product_detail?.name}
                    // disabled
                  />
                  {/* <Select
                    placeholder="Select a Equipment / Area"
                    disabled={searchParams.get('id') ? true : false}
                  >
                    {lcMasters?.[0]?.eqp_id_lc_masters?.map((item, index) => {
                      return (
                        <option value={item?.id} key={index}>
                          {item?.lc_eqp_id}
                        </option>
                      );
                    })}
                  </Select> */}
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label="Current Product Name"
                  rules={[
                    {
                      required: true,
                      message: 'Material Code'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter Current product Name"
                    value={currentProduct?.product_detail?.name}
                    disabled
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label="Clearence for Batch #"
                  rules={[
                    {
                      required: true,
                      message: 'Material Code'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter Clearence for Batch #"
                    value={currentProduct?.product_batch_number}
                    disabled
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label="Previous Product Name"
                  name="previous_product"
                  rules={[
                    {
                      required: true,
                      message: 'Material Code'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter Previous Product Name"
                    disabled={
                      user?.role == 'prod_user'
                        ? searchParams.get('id') && !isEdit
                        : true
                    }
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label="Previous Batch #"
                  name="previous_batch"
                  rules={[
                    {
                      required: true,
                      message: 'Material Code'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter Previous Batch #"
                    disabled={
                      user?.role == 'prod_user'
                        ? searchParams.get('id') && !isEdit
                        : true
                    }
                  />
                </FormItem>
              </Col>
            </Row>
          </Card>
          {lCQuestions?.length ? (
            <>
              <Card className={`${styles.cardStyles_approver12}`}>
                <Row gutter={24}>
                  <Col xs={24} xl={12} span={12} md={24} sm={24}></Col>
                  <Col xs={24} xl={6} span={6} md={24} sm={24}>
                    <b>Inspected By Production</b>
                  </Col>
                  <Col xs={24} xl={6} span={6} md={24} sm={24}>
                    <b>Inspected By QA</b>
                  </Col>
                </Row>
                <br />
                {lCQuestions?.map((item, index) => {
                  return (
                    <>
                      <Row gutter={24} key={index}>
                        <Col xs={24} xl={12} span={12} md={24} sm={24}>
                          <p style={{ color: 'black' }}>
                            {searchParams.get('id')
                              ? item?.lc_eqp_question_master?.lc_eqp_question
                              : item?.lc_eqp_question}
                            {item?.lc_eqp_question_master?.lc_question_type ===
                              'date' || item?.lc_question_type === 'date' ? (
                              <Row gutter={24}>
                                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                                  <FormItem
                                    label="Done Date"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Select From and To date'
                                      }
                                    ]}
                                    className={styles.customLable}
                                  >
                                    <DatePicker
                                      // format={{
                                      //   format: 'YYYY-MM-DD'
                                      // }}
                                      style={{ width: '100%' }}
                                      value={
                                        item?.done_date
                                          ? moment(
                                              item?.done_date,
                                              'YYYY-MM-DD'
                                            )
                                          : item?.done_date
                                      }
                                      onChange={(e) =>
                                        handleChangeDoneDate(
                                          e,
                                          item?.id,
                                          index,
                                          'prod',
                                          'done_date'
                                        )
                                      }
                                      disabled={
                                        user?.role == 'prod_user'
                                          ? searchParams.get('id') && !isEdit
                                          : true
                                      }
                                    />
                                  </FormItem>
                                </Col>

                                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                                  <FormItem
                                    label="Due Date"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Select From and To date'
                                      }
                                    ]}
                                    className={styles.customLable}
                                  >
                                    <DatePicker
                                      style={{ width: '100%' }}
                                      onChange={(e) =>
                                        handleChangeDoneDate(
                                          e,
                                          item?.id,
                                          index,
                                          'prod',
                                          'due_date'
                                        )
                                      }
                                      value={
                                        item?.due_date
                                          ? moment(item?.due_date, 'YYYY-MM-DD')
                                          : item?.due_date
                                      }
                                      disabled={
                                        user?.role == 'prod_user'
                                          ? searchParams.get('id') && !isEdit
                                          : true
                                      }
                                    />
                                  </FormItem>
                                </Col>
                              </Row>
                            ) : item?.lc_eqp_question_master
                                ?.lc_question_type === 'date&time' ||
                              item?.lc_question_type === 'date&time' ? (
                              <Row gutter={24}>
                                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                                  <FormItem
                                    label="Done Time"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Select From and To date'
                                      }
                                    ]}
                                    className={styles.customLable}
                                  >
                                    <TimePicker
                                      style={{ width: '100%' }}
                                      onChange={(e) =>
                                        handleChangeDoneDate(
                                          e,
                                          item?.id,
                                          index,
                                          'prod',
                                          'date_time'
                                        )
                                      }
                                      value={
                                        item?.date_time
                                          ? moment(item?.date_time, 'hh:mm')
                                          : item?.date_time
                                      }
                                      disabled={
                                        user?.role == 'prod_user'
                                          ? searchParams.get('id') && !isEdit
                                          : true
                                      }
                                    />
                                  </FormItem>
                                </Col>

                                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                                  <FormItem
                                    label="Due Date"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Select From and To date'
                                      }
                                    ]}
                                    className={styles.customLable}
                                  >
                                    <DatePicker
                                      style={{ width: '100%' }}
                                      onChange={(e) =>
                                        handleChangeDoneDate(
                                          e,
                                          item?.id,
                                          index,
                                          'prod',
                                          'due_date'
                                        )
                                      }
                                      value={
                                        item?.due_date
                                          ? moment(item?.due_date, 'YYYY-MM-DD')
                                          : item?.due_date
                                      }
                                      disabled={
                                        user?.role == 'prod_user'
                                          ? searchParams.get('id') && !isEdit
                                          : true
                                      }
                                    />
                                  </FormItem>
                                </Col>
                              </Row>
                            ) : (
                              ''
                            )}
                          </p>
                        </Col>
                        <Col xs={24} xl={6} span={12} md={24} sm={24}>
                          <Radio.Group
                            onChange={(e) =>
                              handleOnChangeProd(
                                item?.id,
                                e.target.value,
                                'prod'
                              )
                            }
                            className={styles.customRadio}
                            value={
                              searchParams.get('id') && !isEdit
                                ? item?.inspect_by_prod
                                : inspectionsByProd?.filter(
                                    (data) => data?.question == item?.id
                                  )?.[0]?.inspect_by_prod
                            }
                            disabled={
                              user?.role == 'prod_user'
                                ? searchParams.get('id') && !isEdit
                                : true
                            }
                          >
                            <Radio value="Yes">Yes</Radio>
                            <Radio value="No">No</Radio>
                            <Radio value="NA">NA</Radio>
                          </Radio.Group>
                        </Col>
                        <Col xs={24} xl={6} span={12} md={24} sm={24}>
                          <Radio.Group
                            onChange={(e) =>
                              handleOnChangeProd(item?.id, e.target.value, 'QA')
                            }
                            className={styles.customRadio}
                            name="type"
                            value={
                              item?.inspect_by_qa &&
                              !isEdit &&
                              lCViewData?.qa_sign_date
                                ? item?.inspect_by_qa
                                : inspectionsByQA?.filter(
                                    (data) => data?.question == item?.id
                                  )?.[0]?.inspect_by_qa
                            }
                            disabled={
                              user?.role == 'qc_initiator'
                                ? lCViewData?.qa_sign_date && !isEdit
                                : true
                            }
                          >
                            <Radio value="Yes">Yes</Radio>
                            <Radio value="No">No</Radio>
                            <Radio value="NA">NA</Radio>
                          </Radio.Group>
                        </Col>
                      </Row>
                      <Divider className={styles.divider} />
                    </>
                  );
                })}
              </Card>
              <Card className={`${styles.cardStyles_approver12}`}>
                <h4>
                  If found satisfactory select Yes; If found Not satisfactory
                  select No; Select NA In case of Not Applicable <br />
                  Note: Refer pictorial display for hard to clean areas during
                  verification
                </h4>
              </Card>
              {lCViewData?.eqp_lc_master?.lc_type === 'ALC' ||
              eqpType === 'ALC' ? (
                <Card className={`${styles.cardStyles_approver12}`}>
                  <Row gutter={24}>
                    <Col xs={24} xl={12} span={12} md={24} sm={24}>
                      <b>Check Point</b>
                    </Col>
                    <Col xs={24} xl={6} span={6} md={24} sm={24}>
                      <b>Inspected By Production</b>
                    </Col>
                    <Col xs={24} xl={6} span={6} md={24} sm={24}>
                      <b>Inspected By QA</b>
                    </Col>
                  </Row>
                  <Divider className={styles.divider} />
                  {tempLCQtns?.map((item, index) => {
                    return (
                      <>
                        <Row gutter={24} key={index}>
                          <Col xs={24} xl={12} span={12} md={24} sm={24}>
                            <p style={{ color: 'black' }}>
                              {searchParams.get('id')
                                ? item?.lc_eqp_question_master?.lc_eqp_question
                                : item?.lc_eqp_question}
                              <Row gutter={24}>
                                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                                  <FormItem
                                    label="Observed Value"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Select From and To date'
                                      }
                                    ]}
                                    className={styles.customLable}
                                  >
                                    <Input
                                      placeholder="Enter Observed Value"
                                      onChange={(e) =>
                                        handleChangeDoneDate(
                                          e,
                                          item?.id,
                                          index,
                                          'prod',
                                          'observed_value'
                                        )
                                      }
                                      value={item?.observed_value}
                                      disabled={
                                        user?.role == 'prod_user'
                                          ? searchParams.get('id') && !isEdit
                                          : true
                                      }
                                    />
                                  </FormItem>
                                </Col>
                              </Row>
                            </p>
                          </Col>
                          <Col xs={24} xl={6} span={12} md={24} sm={24}>
                            <Radio.Group
                              onChange={(e) =>
                                handleOnChangeProd(
                                  item?.id,
                                  e.target.value,
                                  'prod'
                                )
                              }
                              className={styles.customRadio}
                              value={
                                searchParams.get('id') && !isEdit
                                  ? item?.inspect_by_prod
                                  : inspectionsByProd?.filter(
                                      (data) => data?.question == item?.id
                                    )?.[0]?.inspect_by_prod
                              }
                              disabled={
                                user?.role == 'prod_user'
                                  ? searchParams.get('id') && !isEdit
                                  : true
                              }
                            >
                              <Radio value="Yes">Yes</Radio>
                              <Radio value="No">No</Radio>
                              <Radio value="NA">NA</Radio>
                            </Radio.Group>
                          </Col>
                          <Col xs={24} xl={6} span={12} md={24} sm={24}>
                            <Radio.Group
                              onChange={(e) =>
                                handleOnChangeProd(
                                  item?.id,
                                  e.target.value,
                                  'QA'
                                )
                              }
                              className={styles.customRadio}
                              name="type"
                              value={
                                item?.inspect_by_qa &&
                                !isEdit &&
                                lCViewData?.qa_sign_date
                                  ? item?.inspect_by_qa
                                  : inspectionsByQA?.filter(
                                      (data) => data?.question == item?.id
                                    )?.[0]?.inspect_by_qa
                              }
                              disabled={
                                user?.role == 'qc_initiator'
                                  ? lCViewData?.qa_sign_date && !isEdit
                                  : true
                              }
                            >
                              <Radio value="Yes">Yes</Radio>
                              <Radio value="No">No</Radio>
                              <Radio value="NA">NA</Radio>
                            </Radio.Group>
                          </Col>
                        </Row>
                        <Divider className={styles.divider} />
                      </>
                    );
                  })}
                </Card>
              ) : (
                ''
              )}
              <Card className={`${styles.cardStyles_approver12}`}>
                <Row gutter={24}>
                  <Col xs={24} xl={8} span={12} md={24} sm={24}>
                    <p style={{ color: 'black' }}>
                      Inference by Production Department
                    </p>
                  </Col>
                  <Col xs={24} xl={16} span={24} md={24} sm={24}>
                    <Space size={100}>
                      <Radio.Group
                        onChange={(e) => handleOnChangeInfProd(e.target.value)}
                        className={styles.customRadio}
                        value={infProd}
                        disabled={
                          user?.role == 'prod_user'
                            ? searchParams.get('id') && !isEdit
                            : true
                        }
                      >
                        <Space size={120}>
                          <Radio value="Yes">Equipment Cleared</Radio>
                          <Radio value="No">Not Cleared</Radio>
                          <Radio value="NA">N/A</Radio>
                        </Space>
                      </Radio.Group>
                    </Space>
                  </Col>
                </Row>
                {user?.role !== 'prod_user' ||
                lCViewData?.lc_status === 'Approved' ? (
                  <Row gutter={24}>
                    <Col xs={24} xl={8} span={12} md={24} sm={24}>
                      <p style={{ color: 'black' }}>
                        Inference by Quality Assurance Department
                      </p>
                    </Col>

                    <Col xs={24} xl={16} span={24} md={24} sm={24}>
                      <Space size={100}>
                        <Radio.Group
                          onChange={(e) =>
                            handleOnChangeInfProd(e.target.value, 'QA')
                          }
                          className={styles.customRadio}
                          value={infQA}
                          disabled={
                            user?.role == 'qc_initiator'
                              ? lCViewData?.qa_sign_date && !isEdit
                              : true
                          }
                        >
                          <Space size={120}>
                            <Radio value="Yes">Equipment Cleared</Radio>
                            <Radio value="No">Not Cleared</Radio>
                            <Radio value="NA">N/A</Radio>
                          </Space>
                        </Radio.Group>
                      </Space>
                    </Col>
                  </Row>
                ) : (
                  ''
                )}
                <Row gutter={24}>
                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="PR User Id & Date"
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Enter Previous Product Name"
                        value={
                          lCViewData?.sign_date
                            ? lCViewData?.sign_date
                            : `${user?.employee} ( ${moment().format(
                                'DD-MM-YYYY'
                              )})`
                        }
                        disabled
                      />
                    </FormItem>
                  </Col>
                  {user?.role !== 'prod_user' ||
                  lCViewData?.lc_status === 'Approved' ? (
                    <Col xs={24} xl={12} span={12} md={24} sm={24}>
                      <FormItem
                        label="QA User Id & Date"
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter Previous Product Name"
                          value={
                            lCViewData?.qa_sign_date
                              ? lCViewData?.qa_sign_date
                              : `${user?.employee} ( ${moment().format(
                                  'DD-MM-YYYY'
                                )})`
                          }
                          disabled
                        />
                      </FormItem>
                    </Col>
                  ) : (
                    ''
                  )}
                </Row>
              </Card>
              {console.log(lCViewData?.eqp_lc_master?.lc_type)}
              {lCViewData?.eqp_lc_master?.lc_type === 'ALC' ||
              eqpType === 'ALC' ? (
                <Card
                  className={`${styles.cardStyles_approver}`}
                  title="Accessories cleaning Verification"
                >
                  <Row gutter={24}>
                    <Col xs={24} xl={4} span={12} md={24} sm={24}>
                      <b>Accessories Name</b>
                    </Col>
                    <Col xs={24} xl={4} span={12} md={24} sm={24}>
                      <b>Accessories ID</b>
                    </Col>
                    <Col xs={24} xl={4} span={12} md={24} sm={24}>
                      <b>Cleaning</b>
                    </Col>
                    <Col xs={24} xl={5} span={6} md={24} sm={24}>
                      <b>Inspected By Production</b>
                    </Col>
                    <Col xs={24} xl={5} span={6} md={24} sm={24}>
                      <b>Inspected By QA</b>
                    </Col>
                    <Col xs={24} xl={2} span={6} md={24} sm={24}></Col>
                  </Row>
                  {allAccessories?.map((item, index) => {
                    return (
                      <Row gutter={24} key={index}>
                        <Col xs={6} xl={4} span={12} md={24} sm={24}>
                          <FormItem
                            rules={[
                              {
                                required: true,
                                message: 'Material Code'
                              }
                            ]}
                            className={styles.customLable}
                          >
                            <Input
                              placeholder="Enter Accessories Name"
                              onChange={(e) =>
                                handleOnchangeAccessory(
                                  e.target.value,
                                  index,
                                  'accessory_name',
                                  'prod'
                                )
                              }
                              value={item?.accessory_name}
                              disabled={
                                user?.role == 'prod_user'
                                  ? searchParams.get('id') && !isEdit
                                  : true
                              }
                            />
                          </FormItem>
                        </Col>
                        <Col xs={6} xl={4} span={12} md={24} sm={24}>
                          <FormItem
                            rules={[
                              {
                                required: true,
                                message: 'Material Code'
                              }
                            ]}
                            className={styles.customLable}
                          >
                            <Input
                              placeholder="Enter Accessories ID"
                              onChange={(e) =>
                                handleOnchangeAccessory(
                                  e.target.value,
                                  index,
                                  'accessory_id',
                                  'prod'
                                )
                              }
                              value={item?.accessory_id}
                              disabled={
                                user?.role == 'prod_user'
                                  ? searchParams.get('id') && !isEdit
                                  : true
                              }
                            />
                          </FormItem>
                        </Col>
                        <Col xs={6} xl={4} span={12} md={24} sm={24}>
                          <FormItem
                            rules={[
                              {
                                required: true,
                                message: 'Material Code'
                              }
                            ]}
                            className={styles.customLable}
                          >
                            <Select
                              placeholder="Select Lineclearance form"
                              onChange={(e) =>
                                handleOnchangeAccessory(
                                  e,
                                  index,
                                  'cleaning',
                                  'prod'
                                )
                              }
                              value={item?.cleaning}
                              disabled={
                                user?.role == 'prod_user'
                                  ? searchParams.get('id') && !isEdit
                                  : true
                              }
                            >
                              <option value="satisfactory">Satisfactory</option>
                              <option value="not_satisfactory">
                                Not Satisfactory
                              </option>
                            </Select>
                          </FormItem>
                        </Col>
                        <Col xs={6} xl={5} span={12} md={24} sm={24}>
                          <FormItem
                            rules={[
                              {
                                required: true,
                                message: 'Material Code'
                              }
                            ]}
                            className={styles.customLable}
                          >
                            <Radio.Group
                              onChange={(e) =>
                                handleOnchangeAccessory(
                                  e.target.value,
                                  index,
                                  'inspect_by_prod',
                                  'prod'
                                )
                              }
                              className={styles.customRadio}
                              value={
                                item?.inspect_by_prod
                                // searchParams.get('id') && !isEdit
                                //   ? item?.inspect_by_prod
                                //   : inspectionsByProd?.filter(
                                //       (data) => data?.question == item?.id
                                //     )?.[0]?.inspect_by_prod
                              }
                              disabled={
                                user?.role == 'prod_user'
                                  ? searchParams.get('id') && !isEdit
                                  : true
                              }
                            >
                              <Radio value="Yes">Yes</Radio>
                              <Radio value="No">No</Radio>
                              <Radio value="NA">NA</Radio>
                            </Radio.Group>
                          </FormItem>
                        </Col>
                        <Col xs={6} xl={5} span={12} md={24} sm={24}>
                          <FormItem
                            rules={[
                              {
                                required: true,
                                message: 'Material Code'
                              }
                            ]}
                            className={styles.customLable}
                          >
                            <Radio.Group
                              onChange={(e) =>
                                handleOnchangeAccessory(
                                  e.target.value,
                                  index,
                                  'inspect_by_qa',
                                  'qa'
                                )
                              }
                              className={styles.customRadio}
                              value={
                                item?.inspect_by_qa
                                // &&
                                // !isEdit &&
                                // lCViewData?.qa_sign_date
                                //   ? item?.inspect_by_qa
                                //   : inspectionsByQA?.filter(
                                //       (data) => data?.question == item?.id
                                //     )?.[0]?.inspect_by_qa
                              }
                              disabled={
                                user?.role == 'qc_initiator'
                                  ? lCViewData?.qa_sign_date && !isEdit
                                  : true
                              }
                            >
                              <Radio value="Yes">Yes</Radio>
                              <Radio value="No">No</Radio>
                              <Radio value="NA">NA</Radio>
                            </Radio.Group>
                          </FormItem>
                        </Col>
                        <Col xs={6} xl={2} span={12} md={24} sm={24}>
                          {user?.role == 'prod_user' ? (
                            <FormItem
                              rules={[
                                {
                                  required: true,
                                  message: 'Material Code'
                                }
                              ]}
                              style={{ color: '#c91761', fontSize: '17px' }}
                            >
                              {index === 0 ? (
                                <PlusCircleOutlined
                                  onClick={() => handleAddAccessories()}
                                />
                              ) : (
                                <DeleteOutlined
                                  onClick={(index) =>
                                    handleDeleteAccessories(index)
                                  }
                                />
                              )}
                            </FormItem>
                          ) : (
                            ''
                          )}
                        </Col>
                      </Row>
                    );
                  })}
                </Card>
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}
        </Space>
        <br />
        <br />
        <FormItem className="text-center">
          <Space>
            <CustomButton
              className={styles.inwardButton}
              type="primary"
              ghost
              onClick={() => history.back()}
            >
              Back
            </CustomButton>
            {
              // searchParams.get('id') && user?.role === 'prod_user') ||
              user?.role === 'qc_initiator' && isEdit ? (
                <CustomButton
                  className={styles.inwardButton}
                  htmlType="submit"
                  // onClick={() => handleSubmit('Approved')}
                  type="primary"
                >
                  Update
                </CustomButton>
              ) : lCViewData?.sign_date && lCViewData?.qa_sign_date ? (
                ''
              ) : (
                <CustomButton
                  className={styles.inwardButton}
                  htmlType="submit"
                  // onClick={() => handleSubmit('Approved')}
                  type="primary"
                >
                  Submit
                </CustomButton>
              )
            }
          </Space>
        </FormItem>
      </Form>
      {isModal ? (
        <VerificationModal
          isModal={isModal}
          // materials={materials}
          setIsModal={setIsModal}
          material={data}
          // setIsEdit={setIsEdit}
          page={type}
        />
      ) : (
        ''
      )}
    </Layout>
  );
};
export default LineClearence;
