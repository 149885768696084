import React from 'react';
import { Card, Image, Space, Row, Col, Form } from 'antd';
import truck from '../../images/1.svg';
import Gatepasstruck from '../../images/2.svg';
import QCtruck from '../../images/3.svg';
import FileImage from '../../images/file-icon.svg';
// import temp from '../../images/Untitled.png';
// import CustomButton  from '@/common/CustomButton';
import styles from './index.module.less';
import { ArrowRightOutlined } from '@ant-design/icons';
import { getLoggedInUser } from 'utilities/helpers';

const DashBoardPage = () => {
  const [form] = Form.useForm();
  const { user } = getLoggedInUser();
  const cards = [
    {
      title: 'Warehouse ',
      icon: truck,
      url: '/dashboard?type=warehouse'
    },
    {
      title: 'Quality Assurance',
      icon: Gatepasstruck,
      url: '/dashboard?type=quality-assurance'
    },
    {
      title: 'Quality Control',
      icon: QCtruck,
      url: '/dashboard?type=quality-control'
    },
    {
      title: `Production`,
      icon: truck,
      url: '/dashboard?type=production'
    },
    {
      title: `Admin`,
      icon: truck,
      url: '/dashboard?type=admin'
    },
    {
      title: `PPIC User`,
      icon: truck,
      url: '/dashboard?type=ppic_user'
    },
    {
      title: `Finance User`,
      icon: truck,
      url: '/dashboard?type=finance_user'
    },
    {
      title: `BD User`,
      icon: truck,
      url: '/dashboard?type=bd_user'
    }
  ];

  const cardView = cards;
  return (
    <>
      <Form form={form} layout="vertical">
        <Row gutter={24}>
          {cardView?.map((items, index) => {
            return (
              <>
                <Col
                  xs={12}
                  xl={4}
                  md={6}
                  sm={6}
                  span={6}
                  className={`${styles.cardbody1} mb-2`}
                  key={index}
                >
                  <Card
                    hoverable
                    className={styles.cardbody}
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = items.url;
                    }}
                  >
                    <Space direction="vertical">
                      <Image
                        src={items.icon}
                        preview={false}
                        style={{ width: '80% !important' }}
                        className={
                          user?.role === 'qc_initiator'
                            ? styles.qc_stickers
                            : styles.stickers
                        }
                      />
                      <span>{items.title}</span>
                    </Space>
                  </Card>
                  <br />
                </Col>
              </>
            );
          })}
        </Row>
        {user?.role !== 'prod_user' ? (
          <Card hoverable className={styles.cardbody1}>
            <Row>
              <Col xs={24} xl={8} span={8}>
                <Space direction="vertical" size={19}>
                  <Image src={FileImage} preview={false} />

                  <span>
                    <h2 style={{ fontWeight: 'bold' }}>Our Policies</h2>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </span>
                </Space>
              </Col>

              <Col className="mt-3" xs={24} xl={16} span={16}>
                <Space direction="vertical" size={19}>
                  <span style={{ fontSize: '14px' }}>
                    <h4 style={{ fontWeight: 'bold' }}>
                      Material Reciept Guidelines
                    </h4>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using Content here, content here, making it look like
                    readable English. Many desktop publishing packages and web
                    page editors now use Lorem Ipsum as their default model
                    text, and a search for lorem ipsum will uncover many web
                    sites still in their infancy. <br />
                    <p style={{ float: 'right', color: 'red' }}>
                      Learn More <ArrowRightOutlined />
                    </p>
                  </span>

                  <span style={{ fontSize: '13px' }}>
                    <h4 style={{ fontWeight: 'bold' }}>Quality policy</h4>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using Content here, content here, making it look like
                    readable English. Many desktop publishing packages and web
                    page editors now use Lorem Ipsum as their default model
                    text, and a search for lorem ipsum will uncover many web
                    sites still in their infancy. <br />
                    <p style={{ float: 'right', color: 'red' }}>
                      Learn More <ArrowRightOutlined />
                    </p>
                  </span>
                </Space>
              </Col>
            </Row>
          </Card>
        ) : (
          ''
        )}
      </Form>
    </>
  );
};

export default DashBoardPage;
