import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  // DatePicker,
  // Select,
  Radio,
  Space,
  Image,
  message,
  Card,
  Tag
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
// import truck from '../images/truck-delivery-done.gif';
import truck from '@/features/generateMrn/components/images/truck-delivery-done.gif';
import { useDispatch, useSelector } from 'react-redux';
import { history } from 'app/history';
import Layout from '@/common/Layout';
import { useParams } from 'react-router-dom';
import { getAllQCMaterials, discrepency_report_qc } from '../../redux/slice';
import { CheckCircleOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { CheckableTag } = Tag;
const DiscepancyReportPage = ({ loading }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { allqcMaterials } = useSelector((state) => state.qc);
  const [form] = Form.useForm();

  const [selectedTags, setSelectedTags] = useState([]);
  const [comments, setComments] = useState();
  const [container, setContainer] = useState();
  const [discrepancyContainers, setDiscrepancyContainers] = useState([]);
  const [truckShow, setTruckShow] = useState(false);

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      message.error(
        'Please select a response to all the questions in the checklist'
      );
    }
  };
  console.log(localStorage.getItem('inv_num'));
  const handleClickSubmit = () => {
    // values['document_id'] = params.id;
    // values['damage_containers'] = damagedContainers;
    setTruckShow(true);
    const payload = {
      document_id: params.id,
      discrepancy_containers: discrepancyContainers
    };
    dispatch(discrepency_report_qc(payload)).then(function (response) {
      console.log(response);
      if (response?.payload?.success) {
        setTruckShow(false);
        message.success(response?.payload?.message);
        history.back();
      }
    });
  };

  // const handleStandardCancel = () => {
  //   history.push('/dashboard');
  // };
  const handleComments = (e) => {
    setComments(e.target.value);
  };
  const handleCapacity = (e) => {
    setContainer(e.target.value);
  };
  useEffect(() => {
    form.setFieldsValue({
      invoice_no: localStorage.getItem('invoice_num'),
      material_name: localStorage.getItem('material_name'),
      material_code: localStorage.getItem('material_code')
    });
  }, [discrepancyContainers]);
  const weighments = allqcMaterials?.Weighments?.length
    ? allqcMaterials?.Weighments.filter(
        (weighment) => weighment.discrepancy == true
      )
    : [];

  const containers = weighments?.length
    ? weighments.filter((weighments) =>
        discrepancyContainers.every(
          (person2) =>
            !person2.container_number.includes(weighments.container_number)
        )
      )
    : [];
  console.log(containers);
  // const containers = weighments || [];

  const handleChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? tag
      : selectedTags.filter((t) => t !== tag);
    setSelectedTags(nextSelectedTags);
  };

  useEffect(() => {
    handleGetAllMaterials();
  }, []);
  const handleGetAllMaterials = () => {
    let payload = {
      id: params.id
    };
    dispatch(getAllQCMaterials(payload));
  };
  console.log(allqcMaterials);

  const handleClick = () => {
    setDiscrepancyContainers([
      ...discrepancyContainers,
      {
        container_number: selectedTags,
        comments: comments,
        is_accepted: container
      }
    ]);
    form.resetFields();
  };
  return (
    <Layout sider={false}>
      <Form
        form={form}
        layout="vertical"
        // onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Card title="DISCREPANCY INTIMATION" className={styles.CustomPanel}>
          <Row gutter={24}>
            <Col xs={24} xl={8} span={8}>
              <FormItem
                label="Material Code"
                name="material_code"
                rules={[
                  {
                    required: false,
                    message: 'Enter Material code'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Material code" disabled />
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <FormItem
                label="Material Name"
                name="material_name"
                rules={[
                  {
                    required: false,
                    message: 'Enter Material name'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Material name" disabled />
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <FormItem
                label="Invoice Number"
                name="invoice_no"
                rules={[
                  {
                    required: false,
                    message: 'Enter Invoice number'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Invoice number" disabled />
              </FormItem>
            </Col>
          </Row>
        </Card>
        <Card title="CONTAINERS" className={styles.CustomPanel}>
          {containers?.length ? (
            <>
              <Row gutter={24}>
                {containers?.map((tag) => (
                  <CheckableTag
                    key={tag.container_number}
                    checked={selectedTags.includes(tag.container_number)}
                    onChange={(checked) =>
                      handleChange(tag.container_number, checked)
                    }
                    className={styles.checkbleTags}
                    disabled
                  >
                    {tag.container_number}
                  </CheckableTag>
                ))}
              </Row>
              {selectedTags?.length ? (
                <Row gutter={24}>
                  <Col span={24}>
                    <FormItem
                      label="Comments"
                      name="comments"
                      rules={[
                        {
                          required: false,
                          message: 'Enter comments'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <TextArea
                        placeholder="Please enter comments"
                        name="comments"
                        onChange={(e) => handleComments(e)}
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={8} span={8}>
                    <FormItem
                      name="proceed"
                      rules={[
                        {
                          required: true,
                          message: 'Choose Yes/No'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Radio.Group
                        onChange={handleCapacity}
                        className={styles.customRadio}
                        name="proceed"
                      >
                        <Radio value="Yes">Approved</Radio>
                        <Radio value="No">Reject</Radio>
                      </Radio.Group>
                    </FormItem>
                  </Col>
                </Row>
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}
          <Row>
            {discrepancyContainers?.length
              ? discrepancyContainers?.map((item, index) => {
                  return (
                    <Tag
                      key={index}
                      icon={<CheckCircleOutlined />}
                      className={styles.customTag}
                    >
                      {item.container_number}
                    </Tag>
                  );
                })
              : ''}
          </Row>
        </Card>
        <br />
        <FormItem className="text-center">
          <Space>
            {containers?.length === 0 ? (
              truckShow ? (
                <Image
                  src={truck}
                  preview={false}
                  style={{ width: '100px !important', height: '100px' }}
                />
              ) : (
                <CustomButton
                  type="primary"
                  onClick={handleClickSubmit}
                  loading={loading}
                >
                  Confirm
                </CustomButton>
              )
            ) : (
              <CustomButton
                type="primary"
                onClick={handleClick}
                loading={loading}
              >
                Submit
              </CustomButton>
            )}
          </Space>
        </FormItem>
        {/* </Space> */}
      </Form>
    </Layout>
  );
};
export default DiscepancyReportPage;
