import React from 'react';
import Layout from '@/common/Layout';
import ReconsiliationRequestPage from './components/reconsiliationRequestPage';

const ReconsiliationRequest = () => {
  return (
    <>
      <Layout sider={false}>
        <ReconsiliationRequestPage />
      </Layout>
    </>
  );
};

export default ReconsiliationRequest;
