import apiClient from 'utilities/apiClient';

export const materials = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.document}/${url}`, payload, true);
};

export const qc_doc_damage = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.qc}/${url}`, payload, true);
};

export const qc_storage_damage = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.qc}/${url}`, payload, true);
};

export const qc_damage_report = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.qc}/${url}`, payload, true);
};

export const qc_discrepency_report = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.qc}/${url}`, payload, true);
};
export const lcMaterialDetails = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.lc}/${url}`, payload, true);
};

export const suppliers = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.materialList}/${url}`, payload, true);
};

export const create_vendor = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.materialList}/${url}`, payload, true);
};

export const update_vendor = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.qc}/${url}`, payload, true);
};

export const dispense = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.dispense}/${url}`, payload, true);
};

export const batchLog = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.batchLog}/${url}`, payload, true);
};

export const getbatchLog = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.batchLog}/${url}`, payload, true);
};

export const printLogs = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.batchLog}/${url}`, payload, true);
};

export const cloneprod = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.product}/${url}`, payload, true);
};

export const create_material_type = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.materialList}/${url}`, payload, true);
};
