import React, { useState, useEffect } from 'react';
import {
  Row,
  Form,
  Card,
  Space,
  Col,
  Tag,
  Input,
  Collapse,
  message,
  DatePicker,
  Select
  // Popconfirm
  // TimePicker
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import { useDispatch } from 'react-redux';
import Layout from '@/common/Layout';
import CustomModal from '@/common/CustomModal';
import CustomTable from 'common/Content/CustomTable';
import {
  getAllParamsList,
  getconfigboms,
  createactualMaterials,
  createFbb,
  AddEndDate,
  emergencyStop
  // getFbb
} from '../../redux/slice';
import {
  useCreateEmsMutation,
  useGetEmsQuery
  // useGetEmsRoomNumberMastersQuery
} from '../../redux/apiSlice';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { getLoggedInUser } from 'utilities/helpers';
import {
  AlertOutlined,
  CheckCircleFilled,
  DeleteOutlined,
  // CheckCircleOutlined,
  PlusCircleOutlined,
  RocketOutlined
} from '@ant-design/icons';
import Spinner from '../../../../common/Spinner';
import LiveTimeInput from '../../../../common/Clock';

const { Panel } = Collapse;

const ExecuteBMR = () => {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const params = useParams();
  const user = getLoggedInUser();

  const [processList, setProcessList] = useState([]);
  const [filteredEqps, setFilteredEqps] = useState([]);
  const [showFBB, setShowFBB] = useState(false);
  const [isModalShow, setIsModalShow] = useState(false);
  const [isPassword, setIsPassword] = useState('');
  const [actualData, setActualData] = useState({});
  const [date, setDate] = useState(moment(new Date(), 'DD-MM-YYYY HH:mm'));
  const [emergencydata, setEmergencydata] = useState({});
  const [ismodel, setIsmodel] = useState(false);
  const [isRemarks, setIsRemarks] = useState('');
  const [bmrtype, setBmrType] = useState('');
  const [dataIndexes, setDataIndexes] = useState([
    {
      fbb_date: '',
      start_date: '',
      end_date: '',
      fbb_duration: '',
      initial_qty: '',
      final_qty: '',
      sprayed_qty: '',
      inlet_set: '',
      inlet_observed: '',
      product_temp: '',
      exhaust_temp: '',
      air_flow: '',
      pump_rpm: '',
      spray_rate: '',
      automisation: '',
      filter_shaking: '',
      inlet_RH: '',
      filter_bag: '',
      done_by: ''
    }
  ]);
  const [mainGroupId, setMainGroupId] = useState('');
  const [lotId, setLotId] = useState('');
  const [exeEndDate, setExeEndDate] = useState('');
  const [parameters, setParameters] = useState();
  const [type, setType] = useState('');
  // const [containers, setContainers] = useState([
  //   { container: '', tare_weight: '', net_weight: '', gross_weight: '' }
  // ]);
  const [temparatures, setTemparatures] = useState({
    fbb_date: '',
    start_date: '',
    end_date: '',
    fbb_duration: '',
    initial_qty: '',
    final_qty: '',
    sprayed_qty: '',
    inlet_set: '',
    inlet_observed: '',
    product_temp: '',
    exhaust_temp: '',
    air_flow: '',
    pump_rpm: '',
    spray_rate: '',
    automisation: '',
    filter_shaking: '',
    inlet_RH: '',
    filter_bag: '',
    done_by: `${user?.user?.employee} (${moment().format('DD-MM-YYYY')})`
  });
  const [isEmsModal, setIsEmsModal] = useState(false);
  // const [roomNumberSearch, setRoomNumberSearch] = useState('');

  const dispatch = useDispatch();
  useEffect(() => {
    handleGetAllBoms();
    handleGetAllParameters();
  }, []);

  const handleGetAllParameters = () => {
    dispatch(getAllParamsList());
  };
  const handleGetAllBoms = () => {
    const payload = { id: params.id };
    dispatch(getconfigboms(payload)).then((res) => {
      if (res?.payload?.success) {
        res?.payload?.data?.map((item) => {
          item?.process_configs?.map((data) => {
            data?.instruction_configs?.map((instruction, n) => {
              data?.bmr_lot_values[n]?.instruction_config.exe_bmr_actuals.length
                ? (instruction.bmr_show = false)
                : (instruction.bmr_show = true);
              instruction.equipments = [
                {
                  name: '',
                  code: '',
                  model: ''
                }
              ];
              instruction?.is_container_exist
                ? (instruction.containers = [
                    {
                      container: '',
                      tare_weight: '',
                      net_weight: '',
                      gross_weight: '',
                      type_seq: 0
                    }
                  ])
                : '';
            });
            data.lots = [];
            data?.material_level
              ? data.lots.push(data?.instruction_configs)
              : [...Array(data?.lot_no)].map(() =>
                  data.lots.push(data?.instruction_configs)
                );
            data.instructions = [];

            data.instructions.push({
              eqp_id: '',
              instruction: '',
              execute_bmr: []
            });

            data?.process_equipment_config?.map((params) => {
              params.process_eqp_ids.map((eqp_ids) => {
                eqp_ids.parameters = [];
                eqp_ids.accessories = [];
                // (params.lot = i + 1),
                eqp_ids.parameters.push({
                  parameter_id: '',
                  sub_param_id: '',
                  type_of_value: '',
                  low: '',
                  high: '',
                  absolute_value: '',
                  uom: '',
                  actual_value: '',
                  temparature: {
                    fbb_date: '',
                    start_date: '',
                    end_date: '',
                    fbb_duration: '',
                    initial_qty: '',
                    final_qty: '',
                    sprayed_qty: '',
                    inlet_set: '',
                    inlet_observed: '',
                    product_temp: '',
                    exhaust_temp: '',
                    air_flow: '',
                    pump_rpm: '',
                    spray_rate: '',
                    automisation: '',
                    filter_shaking: '',
                    inlet_RH: '',
                    filter_bag: '',
                    done_by: ''
                  },

                  instructions: [{ instrction: '' }]
                });

                eqp_ids.accessories.push({
                  parameter_id: '',
                  sub_param_id: '',
                  type_of_value: '',
                  low: '',
                  high: '',
                  absolute_value: '',
                  uom: '',
                  actual_value: '',
                  instructions: [{ instrction: '' }]
                });
              });
            });
          });
        });
        setProcessList(res?.payload?.data);
        setEmergencydata(res?.payload?.product_dispense);
      }
    });
  };

  const handleSubmit = (index, i, g, q) => {
    const lot_id = processList[index].process_configs[i]?.bmr_lot_values.filter(
      (data) =>
        data?.lot_no == `lot${g + 1}` && data?.step_no == `step_${q + 1}`
    );
    console.log(
      processList[index].process_configs[i].lots[g][q].equipments,
      'equip3005'
    );
    if (
      processList[index].process_configs[i].lots[g][q].equipments[0].name !== ''
    ) {
      const payload = {
        product_id: processList[index].product_id,
        process_group_id: processList[index].id,
        process_id: processList[index].process_configs[i].id,
        lot_stage: `lot${g + 1}`,
        step: `step${q + 1}`,
        lot: processList[index].process_configs[i].lots[g][q],
        dispensing_request_id: params?.id,
        lot_id: lot_id[0]?.id || ''
        // containers: containers
      };

      setIsModalShow(true);
      setActualData(payload);
      setParameters(index, i, g, q);
      setType('actual');
    } else {
      message.error('Please Select Equipment');
    }

    // dispatch(createactualMaterials(payload)).then((res) => {
    //   if (res?.payload?.success) {
    //     message.success(res?.payload?.message);
    //     let list = [...processList];
    //     handleGetAllBoms();
    //     list[index].process_configs[i].instruction_configs[q].bmr_show = false;
    //     setProcessList(list);
    //     setContainers([
    //       { container: '', tare_weight: '', net_weight: '', gross_weight: '' }
    //     ]);
    //   }
    // });
  };

  const handleBmr = (bmr) => {
    setBmrType(bmr);
    setIsmodel(true);
  };
  const handlePassword = (e) => {
    setIsPassword(e.target.value);
  };
  const handleRemarks = (e) => {
    setIsRemarks(e.target.value);
  };
  const handleVerify = () => {
    const payload = actualData;
    payload.password = isPassword;

    console.log(payload);
    type == 'actual'
      ? dispatch(createactualMaterials(payload)).then((res) => {
          if (res?.payload?.success) {
            message.success(res?.payload?.message);
            setIsModalShow(false);
            let list = [...processList];
            handleGetAllBoms();
            list[parameters[0]].process_configs[
              parameters[1]
            ].instruction_configs[parameters[2]].bmr_show = false;
            setProcessList(list);
            // setContainers([
            //   {
            //     container: '',
            //     tare_weight: '',
            //     net_weight: '',
            //     gross_weight: ''
            //   }
            // ]);
          }
        })
      : type == 'Fbb'
      ? dispatch(createFbb(payload)).then((res) => {
          if (res?.payload?.success) {
            message.success(res?.payload?.message);
            setIsModalShow(false);
            handleGetAllBoms();
          }
        })
      : type == 'EndDate'
      ? dispatch(AddEndDate(payload)).then((res) => {
          if (res?.payload?.success) {
            message.success(res?.payload?.message);
            setIsModalShow(false);
            handleGetAllBoms();
          }
        })
      : '';
  };
  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setDate(moment(new Date(), 'DD-MM-YYYY HH:mm:ss'));
  //   }, 1000); //1000

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);
  console.log(setDate);
  const handleaddFBB = (index, i, g, q) => {
    const lot_id = processList[index].process_configs[i]?.bmr_lot_values.filter(
      (data) =>
        data?.lot_no == `lot${g + 1}` && data?.step_no == `step_${q + 1}`
    );

    console.log(lot_id);
    const payload = {
      product_id: processList[index].product_id,
      process_group_id: processList[index].id,
      process_id: processList[index].process_configs[i].id,
      lot_stage: `lot${g + 1}`,
      step: `step${q + 1}`,
      instruction_id: processList[index].process_configs[i].lots[g][q].id,
      dispensing_request_id: params?.id,
      lot_id: lot_id[0]?.id || '',
      temparatures: temparatures
    };

    setIsModalShow(true);
    setActualData(payload);
    setParameters(index, i, g, q);
    setType('Fbb');

    // dispatch(createFbb(payload)).then((res) => {
    //   if (res?.payload?.success) {
    //     message.success(res?.payload?.message);
    //     handleGetAllBoms();
    //   }
    // });
  };

  const onFinish = () => {
    history.push(`/view-execute-bmr/${params.id}`);
  };

  const handleChangeOther = (e, index, i, g, q, l) => {
    let list = [...processList];
    console.log(
      list[index].process_configs[i].lots[g][q].execute_bmr_details[l]
    );
    list[index].process_configs[i].lots[g][q].execute_bmr_details[
      l
    ].actual_value = e;
    setProcessList(list);
  };

  const handleAddMoreBMR = (e, index, i, g, q, id) => {
    e.persist();
    setMainGroupId(id);
    setLotId(g);
    console.log(e, index, i, g, q, id, 'mmmmm');
    console.log(processList);
    let list = [...processList];
    list[index].process_configs[i].instruction_configs[q].bmr_show = true;
    list[index].process_configs[i].lots[0][0].instruction_step =
      list[index].process_configs[i].instruction_configs[q]?.instruction_step;
    let bmr_count =
      list[index]?.process_configs[i]?.bmr_lot_values[q]?.instruction_config
        ?.exe_bmr_actuals;
    list[index].process_configs[i].lots[g][q].execute_bmr_details.map(
      (data) => {
        data.type_seq =
          parseInt(bmr_count[parseInt(bmr_count?.length) - 1].type_sequence) +
          1;
      }
    );

    setProcessList(list);
  };

  const handleChangeparamact = (e, index, i, g, q, l, jj) => {
    let list = [...processList];

    list[index].process_configs[i].lots[g][q].equipments[
      jj
    ].parameter_configs[0][l].actual_value = e.target.value;
    setProcessList(list);
  };

  const handleChangeacces = (e, index, i, g, q, l, jj) => {
    let list = [...processList];
    list[index].process_configs[i].lots[g][q].equipments[
      jj
    ].accessory_configs[0][l].actual_value = e.target.value;
    setProcessList(list);
  };

  const handleChangeModel = (e, index, i, g, q, jj) => {
    let list = [...processList];
    let filter_data = list[index].process_configs[
      i
    ].process_equipment_config.filter((data) => data.equipment_master_id == e);
    list[index].process_configs[i].lots[g][q].equipments[jj].name = e;
    list[index].process_configs[i].lots[g][q].equipment_id = e;
    setProcessList(list);
    setFilteredEqps(filter_data);
  };

  const handleChangeCodes = (e, index, i, g, q, jj) => {
    let filter_data = filteredEqps[0]?.process_eqp_ids.filter(
      (code) => code?.equipment_model_id == e
    );
    console.log(filter_data, 'filter_data');
    let list = [...processList];
    list[index].process_configs[i].lots[g][q].equipment_code = e;
    list[index].process_configs[i].lots[g][q].equipments[jj].code = e;
    list[index].process_configs[i].lots[g][q].equipments[jj].model =
      filter_data[0]?.equipment_model?.equipment_capacity;
    list[index].process_configs[i].lots[g][q].equipments[jj].parameter_configs =
      [];
    list[index].process_configs[i].lots[g][q].equipments[jj].accessory_configs =
      [];
    list[index].process_configs[i].lots[g][q].equipments[
      jj
    ].parameter_configs.push(filter_data[0]?.parameter_configs);
    list[index].process_configs[i].lots[g][q].equipments[
      jj
    ].accessory_configs.push(filter_data[0]?.accessory_configs);
    setProcessList(list);
  };

  // const handleModel = (e, index, i, g, q, id, name) => {
  //   const dispensing_request_id = params?.id;
  //   let payload = { parameter_config_id: id, dispensing_request_id };
  //   dispatch(getFbb(payload)).then((res) => {
  //     if (res?.payload?.success) {
  //       message.success(res?.payload?.message);
  //       setDataIndexes(
  //         res?.payload?.data.concat([{ date: '', index, i, g, q, id, name }])
  //       );
  //       setShowFBB(true);
  //     }
  //   });
  // };
  console.log(setDataIndexes, mainGroupId, lotId);
  const handleAddContainer = (e, index, i, g, q) => {
    const list = [...processList];
    console.log(e, index, i, g, q);
    console.log(processList);
    list[index].process_configs[i].lots[g][q].containers.push({
      container: '',
      tare_weight: '',
      net_weight: '',
      gross_weight: '',
      type_seq: 0
    });
    setProcessList(list);
  };

  const handleChangeContainer = (e, index, i, g, q, n, type) => {
    const list = [...processList];

    list[index].process_configs[i].lots[g][q].containers[n][type] =
      e.target.value;
    list[index].process_configs[i].lots[g][q].containers[n].type_seq = n + 1;
    setProcessList(list);
  };
  console.log(processList, 'processList');
  const handledeleteContainer = (index, i, g, q, n) => {
    console.log(index, i, g, q, n);
    console.log(processList);
    const list = [...processList];
    list[index].process_configs[i].lots[g][q].containers.splice(n, 1);
    setProcessList(list);
    console.log(n);
  };
  // console.log(containers, 'containers');
  const onChange = (value, dataIndexes, type) => {
    let list = { ...temparatures };
    list.dispensing_request_id = params.id;
    list.parameter_config_id = dataIndexes?.id;
    list[type] = value;
    setTemparatures(list);
  };

  const handleEndDate = (index, i, g, q, id) => {
    console.log(exeEndDate, id);
    const payload = {
      id: id,
      actual_value: date
    };

    setIsModalShow(true);
    setActualData(payload);
    setParameters(index, i, g, q);
    setType('EndDate');

    // dispatch(AddEndDate(payload)).then((res) => {
    //   if (res?.payload?.success) {
    //     message.success(res?.payload?.message);
    //     handleGetAllBoms();
    //   }
    // });
  };
  const handleChangeEndDate = (e) => {
    setExeEndDate(e);
  };

  const column = [
    {
      title: 'Date & Time',
      dataIndex: 'fbb_date',
      key: 'fbb_date',
      ellipsis: false,
      width: '100px',
      visible: true,
      render: (_, record) =>
        record?.id ? (
          moment(record.fbb_date).format('DD MMM YYYY HH:mm') || 'NA'
        ) : (
          <DatePicker
            showTime={{
              format: 'HH:mm'
            }}
            format="DD-MM-YYYY HH:mm"
            style={{
              width: '100%'
            }}
            onChange={(e) => onChange(e, record, 'fbb_date')}
          />
        )
    },
    // {
    //   title: `Start Time`,
    //   dataIndex: 'permission',
    //   key: 'permission',
    //   ellipsis: false,
    //   width: '100px',
    //   visible: true,
    //   render: (_, record) =>
    //     record?.id ? (
    //       moment(record.start_date).format('HH:mm') || 'NA'
    //     ) : (
    //       <TimePicker
    //         showTime={{
    //           format: 'HH:mm'
    //         }}
    //         style={{
    //           width: '100%'
    //         }}
    //         onChange={(e) => onChange(e, record, 'start_date')}
    //       />
    //     )
    // },
    // {
    //   title: `End Time`,
    //   dataIndex: 'permission',
    //   key: 'permission',
    //   ellipsis: false,
    //   width: '100px',
    //   visible: true,
    //   render: (_, record) =>
    //     record?.id ? (
    //       moment(record.end_date).format('HH:mm') || 'NA'
    //     ) : (
    //       <TimePicker
    //         showTime={{
    //           format: 'HH:mm'
    //         }}
    //         style={{
    //           width: '100%'
    //         }}
    //         onChange={(e) => onChange(e, record, 'end_date')}
    //       />
    //     )
    // },
    // {
    //   title: `Duration (Min)`,
    //   dataIndex: 'permission',
    //   key: 'permission',
    //   ellipsis: false,
    //   width: '100px',
    //   visible: true,
    //   render: (_, record) =>
    //     record?.id ? (
    //       record.fbb_duration || 'NA'
    //     ) : (
    //       <Input
    //         style={{
    //           width: '100%'
    //         }}
    //         placeholder="Duration"
    //         onChange={(e) => onChange(e.target.value, record, 'fbb_duration')}
    //       />
    //     )
    // },
    {
      title: `Granulating Solution`,
      children: [
        {
          title: `Initial Qty.in KG(W1)`,
          dataIndex: 'Granulating Solution',
          key: 'permission',
          ellipsis: false,
          width: '100px',
          visible: true,
          render: (_, record) =>
            record?.id ? (
              record.initial_qty || 'NA'
            ) : (
              <Input
                style={{
                  width: '100%'
                }}
                placeholder="Initial Qty"
                onChange={(e) =>
                  onChange(e.target.value, record, 'initial_qty')
                }
              />
            )
        },
        {
          title: `Final Qty.in KG(W2)`,
          dataIndex: 'Granulating Solution',
          key: 'permission',
          ellipsis: false,
          width: '100px',
          visible: true,
          render: (_, record) =>
            record?.id ? (
              record.final_qty || 'NA'
            ) : (
              <Input
                style={{
                  width: '100%'
                }}
                placeholder="Final Qty"
                onChange={(e) => onChange(e.target.value, record, 'final_qty')}
              />
            )
        },
        {
          title: `Qty. Sprayed in KG(Y= W1-W2)`,
          dataIndex: 'Granulating Solution',
          key: 'permission',
          ellipsis: false,
          width: '100px',
          visible: true,
          render: (_, record) =>
            record?.id ? (
              record.sprayed_qty || 'NA'
            ) : (
              <Input
                style={{
                  width: '100%'
                }}
                placeholder="Sprayed Qty"
                onChange={(e) =>
                  onChange(e.target.value, record, 'sprayed_qty')
                }
              />
            )
        }
      ]
    },
    {
      title: `Inlet Temp.`,
      children: [
        {
          title: `Set`,
          dataIndex: 'Inlet temperature',
          key: 'permission',
          ellipsis: false,
          width: '100px',
          visible: true,
          render: (_, record) =>
            record?.id ? (
              record.inlet_set || 'NA'
            ) : (
              <Input
                style={{
                  width: '100%'
                }}
                placeholder="Set value"
                onChange={(e) => onChange(e.target.value, record, 'inlet_set')}
              />
            )
        },
        {
          title: `Actual`,
          dataIndex: 'Inlet temperature',
          key: 'permission',
          ellipsis: false,
          width: '100px',
          visible: true,
          render: (_, record) =>
            record?.id ? (
              record.inlet_observed || 'NA'
            ) : (
              <Input
                style={{
                  width: '100%'
                }}
                placeholder="Observed value"
                onChange={(e) =>
                  onChange(e.target.value, record, 'inlet_observed')
                }
              />
            )
        }
      ]
    },
    {
      title: `Product Temp.`,
      dataIndex: 'Product temperature',
      key: 'permission',
      ellipsis: false,
      width: '100px',
      visible: true,
      render: (_, record) =>
        record?.id ? (
          record.product_temp || 'NA'
        ) : (
          <Input
            style={{
              width: '100%'
            }}
            placeholder="Product temp"
            onChange={(e) => onChange(e.target.value, record, 'product_temp')}
          />
        )
    },
    {
      title: `Exhaust Temp.`,
      dataIndex: 'Exhaust temperature',
      key: 'permission',
      ellipsis: false,
      width: '100px',
      visible: true,
      render: (_, record) =>
        record?.id ? (
          record.exhaust_temp || 'NA'
        ) : (
          <Input
            style={{
              width: '100%'
            }}
            placeholder="Exhaust temp"
            onChange={(e) => onChange(e.target.value, record, 'exhaust_temp')}
          />
        )
    },
    {
      title: `Airflow (CFM)`,
      dataIndex: 'Air flow (CFM)',
      key: 'permission',
      ellipsis: false,
      visible: true,
      width: '100px',
      render: (_, record) =>
        record?.id ? (
          record.air_flow || 'NA'
        ) : (
          <Input
            style={{
              width: '100%'
            }}
            placeholder="Airflow (CFM)"
            onChange={(e) => onChange(e.target.value, record, 'air_flow')}
          />
        )
    },
    {
      title: `Pump (RPM)`,
      dataIndex: 'Pump',
      key: 'permission',
      ellipsis: false,
      width: '100px',
      visible: true,
      render: (_, record) =>
        record?.id ? (
          record.pump_rpm || 'NA'
        ) : (
          <Input
            style={{
              width: '100%'
            }}
            placeholder="Exhaust temp"
            onChange={(e) => onChange(e.target.value, record, 'pump_rpm')}
          />
        )
    },
    {
      title: `Spray rate (gm/min/gun)`,
      dataIndex: 'Spray rate (g/min)',
      key: 'permission',
      ellipsis: false,
      width: '100px',
      visible: true,
      render: (_, record) =>
        record?.id ? (
          record.spray_rate || 'NA'
        ) : (
          <Input
            // value={''}
            style={{
              width: '100%'
            }}
            placeholder="Spray rate"
            onChange={(e) => onChange(e.target.value, record, 'spray_rate')}
          />
        )
    },
    {
      title: `Atomaization(Bar)`,
      children: [
        {
          title: `Set`,
          dataIndex: 'Inlet temperature',
          key: 'permission',
          ellipsis: false,
          width: '100px',
          visible: true,
          render: (_, record) =>
            record?.id ? (
              record.automisation_set || 'NA'
            ) : (
              <Input
                style={{
                  width: '100%'
                }}
                placeholder="Set value"
                onChange={(e) =>
                  onChange(e.target.value, record, 'automisation_set')
                }
              />
            )
        },
        {
          title: `Actual`,
          dataIndex: 'Inlet temperature',
          key: 'permission',
          ellipsis: false,
          width: '100px',
          visible: true,
          render: (_, record) =>
            record?.id ? (
              record.automisation || 'NA'
            ) : (
              <Input
                // value={''}
                style={{
                  width: '100%'
                }}
                placeholder="Observed value"
                onChange={(e) =>
                  onChange(e.target.value, record, 'automisation')
                }
              />
            )
        }
      ]
    },
    {
      title: `Filter Shaking Pause Time (sec)`,
      dataIndex: 'Filter shaking pause time (Sec)',
      key: 'permission',
      ellipsis: false,
      width: '100px',
      visible: true,
      render: (_, record) =>
        record?.id ? (
          record.filter_shaking || 'NA'
        ) : (
          <Input
            // value={''}
            style={{
              width: '100%'
            }}
            placeholder="Filter Shaking"
            onChange={(e) => onChange(e.target.value, record, 'filter_shaking')}
          />
        )
    },
    {
      title: `Inlet RH (%)`,
      dataIndex: 'Inlet RH',
      key: 'permission',
      ellipsis: false,
      width: '100px',
      visible: true,
      render: (_, record) =>
        record?.id ? (
          record.inlet_RH | 'NA'
        ) : (
          <Input
            // value={''}
            style={{
              width: '100%'
            }}
            placeholder="Inlet RH"
            onChange={(e) => onChange(e.target.value, record, 'inlet_RH')}
          />
        )
    },
    {
      title: `Filter Bag Differential Pressure`,
      dataIndex: 'permission',
      key: 'permission',
      ellipsis: false,
      width: '100px',
      visible: true,
      render: (_, record) =>
        record?.id ? (
          record.filter_bag || 'NA'
        ) : (
          <Input
            // value={''}
            style={{
              width: '100%'
            }}
            placeholder="Inlet RH"
            onChange={(e) => onChange(e.target.value, record, 'filter_bag')}
          />
        )
    },
    {
      title: `Filter shaking mode`,
      dataIndex: 'permission',
      key: 'permission',
      ellipsis: false,
      width: '100px',
      visible: true,
      render: (_, record) =>
        record?.id ? (
          record.filter_shake_mode || 'NA'
        ) : (
          <Input
            // value={''}
            style={{
              width: '100%'
            }}
            placeholder="Inlet RH"
            onChange={(e) =>
              onChange(e.target.value, record, 'filter_shake_mode')
            }
          />
        )
    },
    {
      title: `Remarks`,
      dataIndex: 'Remarks',
      key: 'Remarks',
      ellipsis: false,
      width: '100px',
      visible: true,
      render: (_, record) =>
        record?.id ? (
          record.Remarks || 'NA'
        ) : (
          <Input
            style={{
              width: '100%'
            }}
            placeholder="Remarks"
            onChange={(e) => onChange(e.target.value, record, 'remarks')}
          />
        )
    },
    {
      title: `Done By Sign & Date`,
      dataIndex: 'permission',
      key: 'permission',
      ellipsis: false,
      width: '100px',
      visible: true,
      render: (_, record) =>
        record?.id ? (
          record.done_by || 'NA'
        ) : (
          <Input
            value={`${user?.user?.employee} (${moment().format('DD-MM-YYYY')})`}
            style={{
              width: '100%'
            }}
            placeholder="Done By"
          />
        )
    }
  ].filter(
    (item) => item?.dataIndex == dataIndexes?.[0]?.name || item?.visible
  );

  const handleClose = () => {
    setShowFBB(false);
    setIsModalShow(false);
    setIsmodel(false);
  };
  const handleModalSubmit = () => {
    const payload = temparatures;
    dispatch(createFbb(payload)).then((res) => {
      if (res?.payload?.success) {
        message.success(res?.payload?.message);
        setShowFBB(false);
      }
    });
  };

  const handleEmergencyStop = () => {
    const payload = {
      dispensing_request_id: params.id,
      emergency_stop: bmrtype == 'stop' ? true : false,
      remarks: isRemarks
    };
    console.log(payload);
    dispatch(emergencyStop(payload)).then((res) => {
      if (res?.payload?.success) {
        message.success(res?.payload?.message);
        handleGetAllBoms();
        setIsmodel(false);
        setBmrType('');
        setIsRemarks('');
        form.resetFields();
      }
    });
  };

  // #region Showing success or error message - Start
  const [messageApi, contextHolder] = message.useMessage();
  const handleShowMessage = (messageType, messageText) => {
    messageApi.open({
      type: messageType,
      content: messageText
    });
  };
  // #endregion

  // #region EMS modal - Start
  const [createEms] = useCreateEmsMutation();

  let { data: emsData, error, isLoading } = useGetEmsQuery(params.id);
  // let { data: roomNumbersData, refetch: roomNumbersRefetch } =
  //   useGetEmsRoomNumberMastersQuery({ search: roomNumberSearch });
  // console.log(roomNumbersData);

  const columns = [
    {
      title: 'Room Number',
      dataIndex: 'roomNumber',
      key: 'roomNumber',
      ellipsis: true,
      render: (_, record) => {
        return record?.ems_room_number_master?.ems_room_no || '';
      }
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      ellipsis: true,
      // width: '200px',
      render: (_, record) => {
        return record?.date ? moment(record.date).format('DD-MM-YYYY') : '';
      }
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      ellipsis: true
    },
    {
      title: 'Temperature NMT 25&deg;C',
      dataIndex: 'temperatureInDegreeCelcius',
      key: 'temperatureInDegreeCelcius',
      ellipsis: true
    },
    {
      title: 'Relative Humidity NMT 60% (RH)',
      dataIndex: 'relativeHumidity',
      key: 'relativeHumidity',
      ellipsis: true
    },
    {
      title: 'Pressure Differential (NLT 1.0mm of WC)',
      dataIndex: 'pressureDifferential',
      key: 'pressureDifferential',
      ellipsis: true
    },
    {
      title: 'Checked by (Sign & Date)',
      dataIndex: 'checkedBy',
      key: 'checkedBy',
      ellipsis: true,
      render: (_, record) => {
        return `${record?.checkedBy} (${moment(record.checkedByDate).format(
          'DD-MMM-YYYY'
        )})`;
      }
    }
  ];

  let content;
  if (isLoading) {
    content = <Spinner />;
  } else if (emsData) {
    content = (
      <div className={`${styles.table_container}`}>
        <CustomTable columns={columns} dataSource={emsData.data} />
      </div>
    );
  } else if (error) {
    content = 'Something went wrong';
  }

  // const onRoomNumberSearch = (e) => {
  //   if (e.length === 0 || e.length >= 2) {
  //     setRoomNumberSearch(e);
  //     roomNumbersRefetch();
  //   }
  // };

  const onFinishEmsModal = async () => {
    try {
      console.log('From onFinishEmsModal function');
      const values = await form2.validateFields();
      const payload = { ...values, dispensing_request_id: params.id };
      const date = moment(new Date()).format();
      payload.date = date;
      const time = moment(new Date()).format('HH:mm:ss');
      payload.time = time;

      const response = await createEms(payload);
      if (response?.data?.success === true) {
        form2.resetFields();
        setIsEmsModal(false);
        handleShowMessage('success', response?.data?.message);
      } else {
        console.log(response);
        handleShowMessage('error', response?.error?.data?.message);
      }
    } catch (info) {
      console.log('Validate Failed:', info);
    }
  };

  // #endregion
  return (
    <Layout sider={false}>
      {contextHolder}
      <Form form={form} layout="vertical" onFinish={onFinish}>
        {
          <div
            className="text-right"
            style={{ marginBottom: '5px', marginRight: '8px' }}
          >
            <Space>
              <CustomButton
                type="primary"
                className={styles.inwardButton}
                // ghost
                style={{
                  color: '#fff',
                  borderColor: 'green',
                  backgroundColor: 'green',
                  fontSize: '12px'
                }}
                size="small"
                onClick={() => setIsEmsModal(true)}
              >
                EMS
              </CustomButton>
              <CustomButton
                type="primary"
                className={styles.inwardButton}
                // ghost
                size="small"
                onClick={() => history.push(`/qa-lc-list/${params.id}`)}
              >
                LC Checklist
              </CustomButton>
              {emergencydata?.emergency_stop ? (
                <CustomButton
                  // type="primary"
                  className={styles.inwardButton}
                  // ghost
                  style={{
                    color: '#fff',
                    borderColor: 'green',
                    backgroundColor: 'green',
                    fontSize: '12px'
                  }}
                  size="small"
                  onClick={() => handleBmr('start')}
                >
                  <RocketOutlined />
                  Release
                </CustomButton>
              ) : (
                <CustomButton
                  // type="primary"
                  className={styles.inwardButton}
                  // ghost
                  style={{
                    color: '#fff',
                    borderColor: 'red',
                    backgroundColor: 'red',
                    fontSize: '12px'
                  }}
                  size="small"
                  onClick={() => handleBmr('stop')}
                >
                  <AlertOutlined className={styles.blink} />
                  Emergency Stop
                </CustomButton>
              )}
            </Space>
          </div>
        }
        {processList?.map((item, index) => {
          return (
            <>
              <Collapse
                collapsible="header"
                defaultActiveKey={['0']}
                className={styles.CustomPanel1}
              >
                <Panel
                  header={
                    item?.group_mod_name ? (
                      <Tag
                        color="rgb(201 23 97)"
                        style={{ fontWeight: '100', fontSize: '18px' }}
                      >
                        {item?.group_mod_name}
                      </Tag>
                    ) : (
                      item?.bom_process_group_master?.process_group_name
                    )
                  }
                  className={styles.CustomPanel2}
                  key={index}
                  extra={
                    <Space>
                      {/* <CustomButton
                        onClick={() => history.push(`/qa-lineclearence`)}
                      >
                        LineClearence
                      </CustomButton> */}
                      {/* <DeleteOutlined
                        onClick={() => handledeleteContainer()}
                        style={{ color: 'rgb(201, 23, 97)' }}
                      /> */}
                      {item?.group_mod_name ? (
                        <span style={{ fontSize: '18px' }}>
                          {item?.bom_process_group_master?.process_group_name}
                        </span>
                      ) : (
                        ''
                      )}
                    </Space>
                  }
                >
                  {item?.process_configs?.map((param, i) => {
                    return (
                      <>
                        <Card
                          key={i}
                          title={
                            param?.process_mod_name ? (
                              <Tag
                                color="rgb(201 23 97)"
                                style={{
                                  fontWeight: '100',
                                  fontSize: '18px'
                                }}
                              >
                                {param?.process_mod_name}
                              </Tag>
                            ) : (
                              param?.bom_process_master?.process_name
                            )
                          }
                          className={styles.SubPanel}
                          extra={
                            <Space>
                              {/* <DeleteOutlined
                                onClick={() => handledeleteContainer()}
                                style={{ color: 'rgb(201, 23, 97)' }}
                              /> */}
                              {param?.process_mod_name ? (
                                <span style={{ fontSize: '18px' }}>
                                  {param?.bom_process_master?.process_name}
                                </span>
                              ) : (
                                ''
                              )}
                            </Space>
                          }
                        >
                          <>
                            <Row gutter={24}>
                              <Col span={24}>
                                <p
                                  style={{
                                    fontSize: '14px',
                                    color: '#263238'
                                  }}
                                >
                                  Materials used during this process
                                </p>
                              </Col>
                            </Row>

                            <Row gutter={24}>
                              <Col span={24}>
                                {param?.bom_materials?.map((item, j) => {
                                  return (
                                    <Space size={1} key={j}>
                                      <p className={styles.paragraph}>
                                        <Tag
                                          key={j}
                                          className={styles.tagStyle}
                                        >
                                          {item?.material?.name}
                                        </Tag>
                                      </p>
                                    </Space>
                                  );
                                })}
                              </Col>
                            </Row>

                            {param?.lots
                              ? param?.lots.map((x, g) => (
                                  <>
                                    {console.log(x, 'l')}
                                    <Collapse
                                      collapsible="header"
                                      defaultActiveKey={['0']}
                                      className={styles.CustomPanel1}
                                    >
                                      <Panel
                                        header={
                                          param?.material_level
                                            ? `Batch`
                                            : `LOT ${g + 1}`
                                        }
                                        className={styles.CustomPanel2}
                                        key={g}
                                      >
                                        {x?.map((instr, q) => {
                                          const act_values =
                                            param?.bmr_lot_values.filter(
                                              (data) =>
                                                data?.step_no ==
                                                  instr?.step_no &&
                                                data?.lot_no == `lot${g + 1}`
                                            );
                                          console.log(
                                            instr?.execute_bmr_details,
                                            'instr'
                                          );
                                          const parameters =
                                            param?.process_equipment_config?.filter(
                                              (params) =>
                                                params?.id ==
                                                instr?.process_eqp_config_id
                                            );
                                          let list = [...processList];
                                          list[index].process_configs[i].lots[
                                            g
                                          ][q].params = [];
                                          list[index].process_configs[i].lots[
                                            g
                                          ][q].params.push(parameters[0]);

                                          let parametrs = act_values[0]
                                            ?.equipment_model?.id
                                            ? instr?.params[0]?.process_eqp_ids.filter(
                                                (id) =>
                                                  id.equipment_model_id ==
                                                  act_values[0]?.equipment_model
                                                    ?.id
                                              )
                                            : [];
                                          const fluid =
                                            param?.bmr_lot_values.filter(
                                              (data) =>
                                                data.lot_no == `lot${g + 1}`
                                            );

                                          const added_bmrs = act_values?.[0]
                                            ?.instruction_config
                                            ?.exe_bmr_actuals
                                            ? instr?.bmr_show === true
                                              ? true
                                              : false
                                            : true;

                                          console.log(act_values, 'issuee');
                                          return (
                                            <>
                                              <Card
                                                className={styles.SubPanel3}
                                              >
                                                <Card
                                                  className={styles.SubPanel2}
                                                  key={q}
                                                >
                                                  <h3
                                                    style={{
                                                      fontWeight: 'bold',
                                                      color: '#c91761'
                                                    }}
                                                  >
                                                    Step - {q + 1}
                                                  </h3>
                                                  <Row gutter={24}>
                                                    <Col span={24}>
                                                      <span
                                                        style={{
                                                          fontSize: '14px',
                                                          fontWeight: '100'
                                                        }}
                                                        key={index}
                                                      >
                                                        <span
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              instr?.instruction_step
                                                          }}
                                                        />
                                                      </span>
                                                    </Col>
                                                  </Row>
                                                </Card>
                                                <br />
                                                <Card
                                                  title="Execution Details"
                                                  className={styles.SubPanel}
                                                  extra={
                                                    !emergencydata?.emergency_stop ? (
                                                      <PlusCircleOutlined
                                                        style={{
                                                          fontSize: '24px',
                                                          color: '#c91761'
                                                        }}
                                                        onClick={(e) =>
                                                          handleAddMoreBMR(
                                                            e,
                                                            index,
                                                            i,
                                                            g,
                                                            q,
                                                            param?.id
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      ''
                                                    )
                                                  }
                                                >
                                                  {act_values?.[0]
                                                    ?.bmr_eqp_actuals.length &&
                                                  instr.is_fbb_exist == true ? (
                                                    <Card
                                                      title="Fluid Bed Parameters"
                                                      className={
                                                        styles.SubPanel
                                                      }
                                                    >
                                                      <div
                                                        className={`${styles.table_row}`}
                                                      >
                                                        <CustomTable
                                                          columns={column}
                                                          dataSource={
                                                            fluid
                                                              ? fluid?.[
                                                                  q
                                                                ]?.fbb_processes?.concat(
                                                                  dataIndexes
                                                                )
                                                              : dataIndexes
                                                          }
                                                          bordered
                                                          pagination={false}
                                                          size="middle"
                                                          scroll={{
                                                            x: 'calc(700px + 50%)',
                                                            y: 240
                                                          }}
                                                        />
                                                      </div>
                                                      <FormItem className="text-center">
                                                        <Space>
                                                          <CustomButton
                                                            className={
                                                              styles.inwardButton
                                                            }
                                                            onClick={
                                                              handleClose
                                                            }
                                                            type="primary"
                                                            size="small"
                                                            ghost
                                                          >
                                                            Close
                                                          </CustomButton>
                                                          <CustomButton
                                                            className={
                                                              styles.inwardButton
                                                            }
                                                            onClick={() =>
                                                              handleaddFBB(
                                                                index,
                                                                i,
                                                                g,
                                                                q,
                                                                param?.type_seq
                                                              )
                                                            }
                                                            type="primary"
                                                            size="small"
                                                            disabled={
                                                              emergencydata?.emergency_stop
                                                            }
                                                          >
                                                            Save
                                                          </CustomButton>
                                                        </Space>
                                                      </FormItem>
                                                    </Card>
                                                  ) : (
                                                    ''
                                                  )}
                                                  <br />
                                                  {console.log(
                                                    'act_values143',
                                                    act_values
                                                  )}
                                                  {act_values?.map(
                                                    (actvalue) => {
                                                      return actvalue
                                                        ?.bmr_eqp_actuals.length
                                                        ? actvalue?.bmr_eqp_actuals.map(
                                                            (data, kk) => {
                                                              const exe_bmr_detailss =
                                                                actvalue?.instruction_config?.exe_bmr_actuals.filter(
                                                                  (data) =>
                                                                    data?.type_sequence ==
                                                                    parseInt(
                                                                      kk + 1
                                                                    )
                                                                );
                                                              console.log(
                                                                exe_bmr_detailss,
                                                                'exe_bmr_detailss'
                                                              );
                                                              return (
                                                                <>
                                                                  <Card
                                                                    className={
                                                                      styles.SubPanel3
                                                                    }
                                                                  >
                                                                    <Row
                                                                      gutter={
                                                                        24
                                                                      }
                                                                      key={kk}
                                                                    >
                                                                      <Col
                                                                        span={8}
                                                                      >
                                                                        <FormItem
                                                                          label="Name"
                                                                          rules={[
                                                                            {
                                                                              required: false,
                                                                              message:
                                                                                'Select Material'
                                                                            }
                                                                          ]}
                                                                          className={
                                                                            styles.customLable
                                                                          }
                                                                        >
                                                                          <Input
                                                                            value={
                                                                              data
                                                                                ?.equipment_master
                                                                                ?.equipment_name
                                                                            }
                                                                            disabled
                                                                          />
                                                                        </FormItem>
                                                                      </Col>
                                                                      <Col
                                                                        span={8}
                                                                      >
                                                                        <FormItem
                                                                          label="Code"
                                                                          rules={[
                                                                            {
                                                                              required: false,
                                                                              message:
                                                                                'Select Material'
                                                                            }
                                                                          ]}
                                                                          className={
                                                                            styles.customLable
                                                                          }
                                                                        >
                                                                          <Input
                                                                            value={
                                                                              data
                                                                                ?.equipment_model
                                                                                ?.equipment_id
                                                                            }
                                                                            disabled
                                                                          />
                                                                        </FormItem>
                                                                      </Col>
                                                                      <Col
                                                                        span={8}
                                                                      >
                                                                        <FormItem
                                                                          label="Model"
                                                                          rules={[
                                                                            {
                                                                              required: false,
                                                                              message:
                                                                                'Select Material'
                                                                            }
                                                                          ]}
                                                                          className={
                                                                            styles.customLable
                                                                          }
                                                                        >
                                                                          <Input
                                                                            value={
                                                                              data
                                                                                ?.equipment_model
                                                                                ?.equipment_capacity
                                                                            }
                                                                            disabled
                                                                          />
                                                                        </FormItem>
                                                                      </Col>
                                                                    </Row>
                                                                    {data
                                                                      ?.parameter_actual_values
                                                                      .length
                                                                      ? data?.parameter_actual_values?.map(
                                                                          (
                                                                            parameters,
                                                                            ll
                                                                          ) => {
                                                                            return (
                                                                              <Row
                                                                                gutter={
                                                                                  24
                                                                                }
                                                                                key={
                                                                                  ll
                                                                                }
                                                                              >
                                                                                <Col
                                                                                  span={
                                                                                    8
                                                                                  }
                                                                                  key={
                                                                                    ll
                                                                                  }
                                                                                >
                                                                                  <FormItem
                                                                                    label="Parameter"
                                                                                    rules={[
                                                                                      {
                                                                                        required: false,
                                                                                        message:
                                                                                          'Select Material'
                                                                                      }
                                                                                    ]}
                                                                                    className={
                                                                                      styles.customLable
                                                                                    }
                                                                                  >
                                                                                    <Input
                                                                                      value={
                                                                                        parameters
                                                                                          ?.parameter_config
                                                                                          ?.name_param
                                                                                      }
                                                                                      disabled
                                                                                    />
                                                                                  </FormItem>
                                                                                </Col>
                                                                                <Col
                                                                                  span={
                                                                                    8
                                                                                  }
                                                                                >
                                                                                  <FormItem
                                                                                    label="Range/Absolute"
                                                                                    rules={[
                                                                                      {
                                                                                        required: false,
                                                                                        message:
                                                                                          'Select Material'
                                                                                      }
                                                                                    ]}
                                                                                    className={
                                                                                      styles.customLable
                                                                                    }
                                                                                  >
                                                                                    <Input
                                                                                      value={
                                                                                        parameters
                                                                                          ?.parameter_config
                                                                                          ?.low_param
                                                                                          ? `${parameters?.parameter_config?.low_param}-${parameters?.parameter_config?.high_param}`
                                                                                          : parameters
                                                                                              ?.parameter_config
                                                                                              ?.absolute_value ||
                                                                                            'NA'
                                                                                      }
                                                                                      disabled
                                                                                    />
                                                                                  </FormItem>
                                                                                </Col>
                                                                                <Col
                                                                                  span={
                                                                                    8
                                                                                  }
                                                                                >
                                                                                  {!parameters
                                                                                    ?.parameter_config
                                                                                    ?.paramater_type_master
                                                                                    ?.is_fbb ? (
                                                                                    <FormItem
                                                                                      label="Actual Value"
                                                                                      rules={[
                                                                                        {
                                                                                          required: false,
                                                                                          message:
                                                                                            'Select Material'
                                                                                        }
                                                                                      ]}
                                                                                      className={
                                                                                        styles.customLable
                                                                                      }
                                                                                    >
                                                                                      <Input
                                                                                        value={
                                                                                          parameters?.actual_value
                                                                                        }
                                                                                        disabled
                                                                                      />
                                                                                    </FormItem>
                                                                                  ) : (
                                                                                    ''
                                                                                  )}
                                                                                </Col>
                                                                              </Row>
                                                                            );
                                                                          }
                                                                        )
                                                                      : ''}
                                                                    {data
                                                                      ?.acccessory_actual_values
                                                                      .length
                                                                      ? data?.acccessory_actual_values?.map(
                                                                          (
                                                                            parameters,
                                                                            ll
                                                                          ) => {
                                                                            return (
                                                                              <Row
                                                                                gutter={
                                                                                  24
                                                                                }
                                                                                key={
                                                                                  ll
                                                                                }
                                                                              >
                                                                                <Col
                                                                                  span={
                                                                                    8
                                                                                  }
                                                                                  key={
                                                                                    ll
                                                                                  }
                                                                                >
                                                                                  <FormItem
                                                                                    label="Accessory"
                                                                                    rules={[
                                                                                      {
                                                                                        required: false,
                                                                                        message:
                                                                                          'Select Material'
                                                                                      }
                                                                                    ]}
                                                                                    className={
                                                                                      styles.customLable
                                                                                    }
                                                                                  >
                                                                                    <Input
                                                                                      value={
                                                                                        parameters
                                                                                          ?.accessory_config
                                                                                          ?.name_accessory
                                                                                      }
                                                                                      disabled
                                                                                    />
                                                                                  </FormItem>
                                                                                </Col>
                                                                                <Col
                                                                                  span={
                                                                                    8
                                                                                  }
                                                                                >
                                                                                  <FormItem
                                                                                    label="Range/Absolute"
                                                                                    rules={[
                                                                                      {
                                                                                        required: false,
                                                                                        message:
                                                                                          'Select Material'
                                                                                      }
                                                                                    ]}
                                                                                    className={
                                                                                      styles.customLable
                                                                                    }
                                                                                  >
                                                                                    <Input
                                                                                      value={
                                                                                        parameters
                                                                                          ?.accessory_config
                                                                                          ?.low_param
                                                                                          ? `${parameters?.accessory_config?.low_param}-${parameters?.accessory_config?.high_param}`
                                                                                          : parameters
                                                                                              ?.accessory_config
                                                                                              ?.absolute_value ||
                                                                                            'NA'
                                                                                      }
                                                                                      disabled
                                                                                    />
                                                                                  </FormItem>
                                                                                </Col>
                                                                                <Col
                                                                                  span={
                                                                                    8
                                                                                  }
                                                                                >
                                                                                  {parameters
                                                                                    ?.parameter_config
                                                                                    ?.paramater_type_master
                                                                                    ?.is_fbb ? (
                                                                                    ''
                                                                                  ) : (
                                                                                    // <CustomButton
                                                                                    //   style={{
                                                                                    //     width:
                                                                                    //       '100%'
                                                                                    //   }}
                                                                                    //   onClick={(
                                                                                    //     e
                                                                                    //   ) =>
                                                                                    //     handleModel(
                                                                                    //       e,
                                                                                    //       index,
                                                                                    //       i,
                                                                                    //       g,
                                                                                    //       q,
                                                                                    //       parameters
                                                                                    //         ?.parameter_config
                                                                                    //         ?.id,
                                                                                    //       parameters
                                                                                    //         ?.parameter_config
                                                                                    //         ?.name_param
                                                                                    //     )
                                                                                    //   }
                                                                                    // >
                                                                                    //   Add
                                                                                    //   FBB
                                                                                    // </CustomButton>
                                                                                    <FormItem
                                                                                      label="Actual Value"
                                                                                      rules={[
                                                                                        {
                                                                                          required: false,
                                                                                          message:
                                                                                            'Select Material'
                                                                                        }
                                                                                      ]}
                                                                                      className={
                                                                                        styles.customLable
                                                                                      }
                                                                                    >
                                                                                      <Input
                                                                                        value={
                                                                                          parameters?.actual_value
                                                                                        }
                                                                                        disabled
                                                                                      />
                                                                                    </FormItem>
                                                                                  )}
                                                                                </Col>
                                                                              </Row>
                                                                            );
                                                                          }
                                                                        )
                                                                      : ''}
                                                                    <Row
                                                                      gutter={
                                                                        24
                                                                      }
                                                                    >
                                                                      {actvalue
                                                                        ?.instruction_config
                                                                        ?.exe_bmr_actuals
                                                                        .length
                                                                        ? exe_bmr_detailss?.map(
                                                                            (
                                                                              bmrs,
                                                                              f
                                                                            ) => {
                                                                              return (
                                                                                <>
                                                                                  {console.log(
                                                                                    bmrs?.exe_nama ==
                                                                                      'End (Date & Time)'
                                                                                      ? bmrs?.actual_value
                                                                                      : '',
                                                                                    'EndDate'
                                                                                  )}
                                                                                  {
                                                                                    // bmrs?.type_sequence ===
                                                                                    // kk +
                                                                                    //   1 ? (
                                                                                    <Col
                                                                                      span={
                                                                                        8
                                                                                      }
                                                                                      key={
                                                                                        f
                                                                                      }
                                                                                    >
                                                                                      <FormItem
                                                                                        label={
                                                                                          bmrs?.exe_nama ==
                                                                                          'End (Date & Time)' ? (
                                                                                            <Space
                                                                                              size={
                                                                                                6
                                                                                              }
                                                                                            >
                                                                                              <span>
                                                                                                {
                                                                                                  bmrs?.exe_nama
                                                                                                }
                                                                                              </span>{' '}
                                                                                              <span>
                                                                                                {bmrs?.actual_value ? (
                                                                                                  <CheckCircleFilled
                                                                                                    style={{
                                                                                                      color:
                                                                                                        'green'
                                                                                                    }}
                                                                                                  />
                                                                                                ) : (
                                                                                                  <CustomButton
                                                                                                    size="small"
                                                                                                    type="primary"
                                                                                                    onClick={() =>
                                                                                                      handleEndDate(
                                                                                                        index,
                                                                                                        i,
                                                                                                        g,
                                                                                                        q,
                                                                                                        bmrs?.id
                                                                                                      )
                                                                                                    }
                                                                                                    disabled={
                                                                                                      emergencydata?.emergency_stop
                                                                                                    }
                                                                                                  >
                                                                                                    Save
                                                                                                  </CustomButton>
                                                                                                )}
                                                                                              </span>
                                                                                            </Space>
                                                                                          ) : (
                                                                                            bmrs?.exe_nama
                                                                                          )
                                                                                        }
                                                                                        rules={[
                                                                                          {
                                                                                            required: false,
                                                                                            message:
                                                                                              'Select Material'
                                                                                          }
                                                                                        ]}
                                                                                        className={
                                                                                          styles.customLable
                                                                                        }
                                                                                      >
                                                                                        {bmrs?.exe_nama ==
                                                                                          'End (Date & Time)' &&
                                                                                        !bmrs?.actual_value ? (
                                                                                          <DatePicker
                                                                                            showTime={{
                                                                                              format:
                                                                                                'HH:mm'
                                                                                            }}
                                                                                            format="DD-MM-YYYY HH:mm:ss"
                                                                                            style={{
                                                                                              width:
                                                                                                '100%'
                                                                                            }}
                                                                                            value={
                                                                                              date
                                                                                            }
                                                                                            disabled
                                                                                            onChange={(
                                                                                              e
                                                                                            ) =>
                                                                                              handleChangeEndDate(
                                                                                                e
                                                                                              )
                                                                                            }
                                                                                          />
                                                                                        ) : (
                                                                                          <Input
                                                                                            value={
                                                                                              bmrs
                                                                                                ?.execute_bmr_detail
                                                                                                ?.exe_type ==
                                                                                                'Date' ||
                                                                                              bmrs
                                                                                                ?.execute_bmr_detail
                                                                                                ?.exe_type ==
                                                                                                'Date&Time'
                                                                                                ? bmrs?.actual_value
                                                                                                  ? bmrs?.actual_value
                                                                                                  : // moment(
                                                                                                    //     bmrs?.actual_value
                                                                                                    //   ).format(
                                                                                                    //     'DD MM YYYY HH:mm'
                                                                                                    //   )
                                                                                                    ''
                                                                                                : bmrs?.actual_value
                                                                                            }
                                                                                            disabled={
                                                                                              bmrs?.actual_value
                                                                                                ? true
                                                                                                : false
                                                                                            }
                                                                                            placeholder="Enter value"
                                                                                          />
                                                                                        )}
                                                                                      </FormItem>
                                                                                    </Col>
                                                                                    // ) : (
                                                                                    //   ''
                                                                                    // )
                                                                                  }
                                                                                </>
                                                                              );
                                                                            }
                                                                          )
                                                                        : ''}
                                                                    </Row>
                                                                    <Row
                                                                      gutter={
                                                                        24
                                                                      }
                                                                    >
                                                                      {actvalue?.container_processes?.map(
                                                                        (
                                                                          param,
                                                                          n
                                                                        ) => {
                                                                          return (
                                                                            <>
                                                                              <Col
                                                                                span={
                                                                                  6
                                                                                }
                                                                                key={
                                                                                  n
                                                                                }
                                                                              >
                                                                                <FormItem
                                                                                  label="Container"
                                                                                  rules={[
                                                                                    {
                                                                                      required: false,
                                                                                      message:
                                                                                        'Select Container'
                                                                                    }
                                                                                  ]}
                                                                                  className={
                                                                                    styles.customLable
                                                                                  }
                                                                                >
                                                                                  <Input
                                                                                    placeholder="Enter Container"
                                                                                    onChange={(
                                                                                      e
                                                                                    ) =>
                                                                                      handleChangeContainer(
                                                                                        e,
                                                                                        index,
                                                                                        i,
                                                                                        g,
                                                                                        q,
                                                                                        n,
                                                                                        'container'
                                                                                      )
                                                                                    }
                                                                                    value={
                                                                                      param?.container
                                                                                    }
                                                                                    disabled
                                                                                  />
                                                                                </FormItem>
                                                                              </Col>
                                                                              <Col
                                                                                span={
                                                                                  6
                                                                                }
                                                                              >
                                                                                <FormItem
                                                                                  label="Tare Weight"
                                                                                  rules={[
                                                                                    {
                                                                                      required: false,
                                                                                      message:
                                                                                        'Select Container'
                                                                                    }
                                                                                  ]}
                                                                                  className={
                                                                                    styles.customLable
                                                                                  }
                                                                                >
                                                                                  <Input
                                                                                    placeholder="Enter Tare Weight"
                                                                                    onChange={(
                                                                                      e
                                                                                    ) =>
                                                                                      handleChangeContainer(
                                                                                        e,
                                                                                        index,
                                                                                        i,
                                                                                        g,
                                                                                        q,
                                                                                        n,
                                                                                        'tare_weight'
                                                                                      )
                                                                                    }
                                                                                    value={
                                                                                      param?.tare_weight
                                                                                    }
                                                                                    disabled
                                                                                  />
                                                                                </FormItem>
                                                                              </Col>
                                                                              <Col
                                                                                span={
                                                                                  6
                                                                                }
                                                                              >
                                                                                <FormItem
                                                                                  label="Net Weight"
                                                                                  rules={[
                                                                                    {
                                                                                      required: false,
                                                                                      message:
                                                                                        'Select Container'
                                                                                    }
                                                                                  ]}
                                                                                  className={
                                                                                    styles.customLable
                                                                                  }
                                                                                >
                                                                                  <Input
                                                                                    placeholder="Enter Net Weight"
                                                                                    onChange={(
                                                                                      e
                                                                                    ) =>
                                                                                      handleChangeContainer(
                                                                                        e,
                                                                                        index,
                                                                                        i,
                                                                                        g,
                                                                                        q,
                                                                                        n,
                                                                                        'net_weight'
                                                                                      )
                                                                                    }
                                                                                    value={
                                                                                      param?.net_weight
                                                                                    }
                                                                                    disabled
                                                                                  />
                                                                                </FormItem>
                                                                              </Col>
                                                                              <Col
                                                                                span={
                                                                                  6
                                                                                }
                                                                              >
                                                                                <FormItem
                                                                                  label="Gross Weight"
                                                                                  rules={[
                                                                                    {
                                                                                      required: false,
                                                                                      message:
                                                                                        'Select Container'
                                                                                    }
                                                                                  ]}
                                                                                  className={
                                                                                    styles.customLable
                                                                                  }
                                                                                >
                                                                                  <Input
                                                                                    placeholder="Enter Gross Weight"
                                                                                    onChange={(
                                                                                      e
                                                                                    ) =>
                                                                                      handleChangeContainer(
                                                                                        e,
                                                                                        index,
                                                                                        i,
                                                                                        g,
                                                                                        q,
                                                                                        n,
                                                                                        'gross_weight'
                                                                                      )
                                                                                    }
                                                                                    value={
                                                                                      param?.gross_weight
                                                                                    }
                                                                                    disabled
                                                                                  />
                                                                                </FormItem>
                                                                              </Col>
                                                                            </>
                                                                          );
                                                                        }
                                                                      )}
                                                                    </Row>
                                                                  </Card>
                                                                  <br />
                                                                </>
                                                              );
                                                            }
                                                          )
                                                        : '';
                                                    }
                                                  )}

                                                  {
                                                    // mainGroupId == param?.id &&
                                                    // lotId == g ? (
                                                    // instr?.bmr_show
                                                    // !act_values?.[0]
                                                    //   ?.instruction_config
                                                    //   ?.exe_bmr_actuals ? (
                                                    added_bmrs ? (
                                                      <Card
                                                        className={
                                                          styles.SubPanel3
                                                        }
                                                      >
                                                        {added_bmrs
                                                          ? instr?.equipments.map(
                                                              (data, jj) => {
                                                                return (
                                                                  <>
                                                                    <Row
                                                                      gutter={
                                                                        24
                                                                      }
                                                                      key={jj}
                                                                    >
                                                                      <Col
                                                                        span={8}
                                                                      >
                                                                        <FormItem
                                                                          label="Name"
                                                                          rules={[
                                                                            {
                                                                              required: false,
                                                                              message:
                                                                                'Select Material'
                                                                            }
                                                                          ]}
                                                                          className={
                                                                            styles.customLable
                                                                          }
                                                                        >
                                                                          <Select
                                                                            onChange={(
                                                                              e
                                                                            ) =>
                                                                              handleChangeModel(
                                                                                e,
                                                                                index,
                                                                                i,
                                                                                g,
                                                                                q,
                                                                                jj,
                                                                                'Equipment_id'
                                                                              )
                                                                            }
                                                                            placeholder="Select Equipment"
                                                                          >
                                                                            {param?.process_equipment_config?.map(
                                                                              (
                                                                                eqps,
                                                                                d
                                                                              ) => {
                                                                                return (
                                                                                  <option
                                                                                    key={
                                                                                      d
                                                                                    }
                                                                                    value={
                                                                                      eqps
                                                                                        ?.equipment_master
                                                                                        ?.id ||
                                                                                      null
                                                                                    }
                                                                                  >
                                                                                    {
                                                                                      eqps
                                                                                        ?.equipment_master
                                                                                        ?.equipment_name
                                                                                    }
                                                                                  </option>
                                                                                );
                                                                              }
                                                                            )}
                                                                          </Select>
                                                                        </FormItem>
                                                                      </Col>
                                                                      <Col
                                                                        span={8}
                                                                      >
                                                                        <FormItem
                                                                          label="Code"
                                                                          rules={[
                                                                            {
                                                                              required: false,
                                                                              message:
                                                                                'Select Code'
                                                                            }
                                                                          ]}
                                                                          className={
                                                                            styles.customLable
                                                                          }
                                                                          placeholder="Select Equipment Code"
                                                                        >
                                                                          <Select
                                                                            onChange={(
                                                                              e
                                                                            ) =>
                                                                              handleChangeCodes(
                                                                                e,
                                                                                index,
                                                                                i,
                                                                                g,
                                                                                q,
                                                                                jj,
                                                                                'Equipment_code'
                                                                              )
                                                                            }
                                                                          >
                                                                            {filteredEqps[0]?.process_eqp_ids?.map(
                                                                              (
                                                                                eqps,
                                                                                d
                                                                              ) => {
                                                                                return (
                                                                                  <option
                                                                                    key={
                                                                                      d
                                                                                    }
                                                                                    value={
                                                                                      eqps
                                                                                        ?.equipment_model
                                                                                        ?.id ||
                                                                                      null
                                                                                    }
                                                                                  >
                                                                                    {
                                                                                      eqps
                                                                                        ?.equipment_model
                                                                                        ?.equipment_id
                                                                                    }
                                                                                  </option>
                                                                                );
                                                                              }
                                                                            )}
                                                                          </Select>
                                                                        </FormItem>
                                                                      </Col>
                                                                      <Col
                                                                        span={8}
                                                                      >
                                                                        <FormItem
                                                                          label="Model"
                                                                          className={
                                                                            styles.customLable
                                                                          }
                                                                        >
                                                                          <Input
                                                                            value={
                                                                              data?.model ||
                                                                              null
                                                                            }
                                                                            disabled
                                                                            placeholder="Model Name"
                                                                          />
                                                                        </FormItem>
                                                                      </Col>
                                                                    </Row>
                                                                    <Row
                                                                      gutter={
                                                                        24
                                                                      }
                                                                    >
                                                                      {(parametrs?.length
                                                                        ? parametrs?.[0]
                                                                            .parameter_configs
                                                                        : data
                                                                            ?.parameter_configs?.[0]
                                                                      )?.map(
                                                                        (
                                                                          param,
                                                                          l
                                                                        ) => {
                                                                          return (
                                                                            <>
                                                                              <Col
                                                                                span={
                                                                                  8
                                                                                }
                                                                                key={
                                                                                  l
                                                                                }
                                                                              >
                                                                                <FormItem
                                                                                  label="Parameter"
                                                                                  rules={[
                                                                                    {
                                                                                      required: false,
                                                                                      message:
                                                                                        'Select Material'
                                                                                    }
                                                                                  ]}
                                                                                  className={
                                                                                    styles.customLable
                                                                                  }
                                                                                >
                                                                                  <Input
                                                                                    value={
                                                                                      param?.name_param
                                                                                    }
                                                                                    disabled
                                                                                  />
                                                                                </FormItem>
                                                                              </Col>
                                                                              <Col
                                                                                span={
                                                                                  8
                                                                                }
                                                                                key={
                                                                                  l
                                                                                }
                                                                              >
                                                                                <FormItem
                                                                                  label="Range/Absolute"
                                                                                  rules={[
                                                                                    {
                                                                                      required: false,
                                                                                      message:
                                                                                        'Select Material'
                                                                                    }
                                                                                  ]}
                                                                                  className={
                                                                                    styles.customLable
                                                                                  }
                                                                                >
                                                                                  <Input
                                                                                    value={
                                                                                      param?.low_param
                                                                                        ? `${param?.low_param}-${param?.high_param}`
                                                                                        : param?.absolute_value ||
                                                                                          'NA'
                                                                                    }
                                                                                    disabled
                                                                                  />
                                                                                </FormItem>
                                                                              </Col>
                                                                              <Col
                                                                                span={
                                                                                  8
                                                                                }
                                                                                key={
                                                                                  l
                                                                                }
                                                                              >
                                                                                {!param
                                                                                  ?.paramater_type_master
                                                                                  ?.is_fbb ? (
                                                                                  <FormItem
                                                                                    label="Actual Value"
                                                                                    rules={[
                                                                                      {
                                                                                        required: false,
                                                                                        message:
                                                                                          'Select Material'
                                                                                      }
                                                                                    ]}
                                                                                    className={
                                                                                      styles.customLable
                                                                                    }
                                                                                  >
                                                                                    <Input
                                                                                      value={
                                                                                        act_values?.[0]
                                                                                          ?.instruction_config
                                                                                          ?.parameter_actual_value?.[
                                                                                          l
                                                                                        ]
                                                                                          ?.actual_value
                                                                                      }
                                                                                      placeholder="Enter Value"
                                                                                      disabled={
                                                                                        act_values?.[0]
                                                                                          ?.instruction_config
                                                                                          ?.parameter_actual_value?.[
                                                                                          l
                                                                                        ]
                                                                                          ?.actual_value
                                                                                          ? true
                                                                                          : false
                                                                                      }
                                                                                      onChange={(
                                                                                        e
                                                                                      ) =>
                                                                                        handleChangeparamact(
                                                                                          e,
                                                                                          index,
                                                                                          i,
                                                                                          g,
                                                                                          q,
                                                                                          l,
                                                                                          jj,
                                                                                          param.pro_eqp_id
                                                                                        )
                                                                                      }
                                                                                    />
                                                                                  </FormItem>
                                                                                ) : (
                                                                                  ''
                                                                                  // <CustomButton
                                                                                  //   style={{
                                                                                  //     width:
                                                                                  //       '100%'
                                                                                  //   }}
                                                                                  //   onClick={(
                                                                                  //     e
                                                                                  //   ) =>
                                                                                  //     handleModel(
                                                                                  //       e,
                                                                                  //       index,
                                                                                  //       i,
                                                                                  //       g,
                                                                                  //       q,
                                                                                  //       param.id,
                                                                                  //       param.name_param
                                                                                  //     )
                                                                                  //   }
                                                                                  // >
                                                                                  //   Add
                                                                                  //   FBB
                                                                                  // </CustomButton>
                                                                                )}
                                                                              </Col>
                                                                            </>
                                                                          );
                                                                        }
                                                                      )}
                                                                    </Row>
                                                                    <Row
                                                                      gutter={
                                                                        24
                                                                      }
                                                                    >
                                                                      {(parametrs?.length
                                                                        ? parametrs?.[0]
                                                                            .accessory_config
                                                                        : data
                                                                            ?.accessory_configs?.[0]
                                                                      )?.map(
                                                                        (
                                                                          param,
                                                                          l
                                                                        ) => {
                                                                          return (
                                                                            <>
                                                                              <Col
                                                                                span={
                                                                                  8
                                                                                }
                                                                                key={
                                                                                  l
                                                                                }
                                                                              >
                                                                                <FormItem
                                                                                  label="Accessory"
                                                                                  rules={[
                                                                                    {
                                                                                      required: false,
                                                                                      message:
                                                                                        'Select Material'
                                                                                    }
                                                                                  ]}
                                                                                  className={
                                                                                    styles.customLable
                                                                                  }
                                                                                >
                                                                                  <Input
                                                                                    value={
                                                                                      param?.name_accessory
                                                                                    }
                                                                                    disabled
                                                                                  />
                                                                                </FormItem>
                                                                              </Col>
                                                                              <Col
                                                                                span={
                                                                                  8
                                                                                }
                                                                                key={
                                                                                  l
                                                                                }
                                                                              >
                                                                                <FormItem
                                                                                  label="Range/Absolute"
                                                                                  rules={[
                                                                                    {
                                                                                      required: false,
                                                                                      message:
                                                                                        'Select Material'
                                                                                    }
                                                                                  ]}
                                                                                  className={
                                                                                    styles.customLable
                                                                                  }
                                                                                >
                                                                                  <Input
                                                                                    value={
                                                                                      param?.low_param
                                                                                        ? `${param?.low_param}-${param?.high_param}`
                                                                                        : param?.absolute_value ||
                                                                                          'NA'
                                                                                    }
                                                                                    disabled
                                                                                  />
                                                                                </FormItem>
                                                                              </Col>
                                                                              <Col
                                                                                span={
                                                                                  8
                                                                                }
                                                                                key={
                                                                                  l
                                                                                }
                                                                              >
                                                                                <FormItem
                                                                                  label="Actual Value"
                                                                                  rules={[
                                                                                    {
                                                                                      required: false,
                                                                                      message:
                                                                                        'Select Material'
                                                                                    }
                                                                                  ]}
                                                                                  className={
                                                                                    styles.customLable
                                                                                  }
                                                                                >
                                                                                  <Input
                                                                                    value={
                                                                                      act_values?.[0]
                                                                                        ?.instruction_config
                                                                                        ?.acccessory_actual_values?.[
                                                                                        l
                                                                                      ]
                                                                                        ?.actual_value
                                                                                    }
                                                                                    disabled={
                                                                                      act_values?.[0]
                                                                                        ?.instruction_config
                                                                                        ?.acccessory_actual_values?.[
                                                                                        l
                                                                                      ]
                                                                                        ?.actual_value
                                                                                        ? true
                                                                                        : false
                                                                                    }
                                                                                    placeholder="Enetr Value"
                                                                                    onChange={(
                                                                                      e
                                                                                    ) =>
                                                                                      handleChangeacces(
                                                                                        e,
                                                                                        index,
                                                                                        i,
                                                                                        g,
                                                                                        q,
                                                                                        l,
                                                                                        jj,
                                                                                        param.pro_eqp_id
                                                                                      )
                                                                                    }
                                                                                  />
                                                                                </FormItem>
                                                                              </Col>
                                                                            </>
                                                                          );
                                                                        }
                                                                      )}
                                                                    </Row>
                                                                    <br />
                                                                  </>
                                                                );
                                                              }
                                                            )
                                                          : ''}

                                                        <Row gutter={24}>
                                                          {added_bmrs
                                                            ? instr?.execute_bmr_details?.map(
                                                                (param, l) => {
                                                                  return (
                                                                    <>
                                                                      <Col
                                                                        span={8}
                                                                        key={l}
                                                                      >
                                                                        <FormItem
                                                                          label={
                                                                            param?.exe_name ==
                                                                            'End (Date & Time)' ? (
                                                                              <Space
                                                                                size={
                                                                                  6
                                                                                }
                                                                              >
                                                                                <span>
                                                                                  {
                                                                                    param?.exe_name
                                                                                  }
                                                                                </span>
                                                                                {/* <span>
                                                                                  <CheckCircleOutlined
                                                                                    onClick={() =>
                                                                                      handleEndDate(
                                                                                        param?.id
                                                                                      )
                                                                                    }
                                                                                  />
                                                                                </span> */}
                                                                              </Space>
                                                                            ) : (
                                                                              param?.exe_name
                                                                            )
                                                                          }
                                                                          rules={[
                                                                            {
                                                                              required: false,
                                                                              message:
                                                                                'Select Material'
                                                                            }
                                                                          ]}
                                                                          className={
                                                                            styles.customLable
                                                                          }
                                                                        >
                                                                          {param?.exe_type ==
                                                                          'Input' ? (
                                                                            param?.exe_value ==
                                                                            'user_id' ? (
                                                                              <Input
                                                                                value={
                                                                                  param?.exe_value ==
                                                                                  'user_id'
                                                                                    ? `${
                                                                                        user
                                                                                          ?.user
                                                                                          ?.employee
                                                                                      } (${moment().format(
                                                                                        'DD-MM-YYYY'
                                                                                      )})`
                                                                                    : null
                                                                                }
                                                                                placeholder="Enter value"
                                                                                disabled={
                                                                                  param?.exe_value ==
                                                                                  'user_id'
                                                                                    ? true
                                                                                    : false
                                                                                }
                                                                                onChange={(
                                                                                  e
                                                                                ) =>
                                                                                  handleChangeOther(
                                                                                    e
                                                                                      .target
                                                                                      .value,
                                                                                    index,
                                                                                    i,
                                                                                    g,
                                                                                    q,
                                                                                    l
                                                                                  )
                                                                                }
                                                                              />
                                                                            ) : (
                                                                              <Input
                                                                                placeholder="Enter value"
                                                                                onChange={(
                                                                                  e
                                                                                ) =>
                                                                                  handleChangeOther(
                                                                                    e
                                                                                      .target
                                                                                      .value,
                                                                                    index,
                                                                                    i,
                                                                                    g,
                                                                                    q,
                                                                                    l
                                                                                  )
                                                                                }
                                                                              />
                                                                            )
                                                                          ) : param?.exe_type ==
                                                                            'Date' ? (
                                                                            <DatePicker
                                                                              showTime={{
                                                                                format:
                                                                                  'HH:mm'
                                                                              }}
                                                                              format="DD-MM-YYYY HH:mm"
                                                                              style={{
                                                                                width:
                                                                                  '100%'
                                                                              }}
                                                                              placeholder="Enter value"
                                                                              onChange={(
                                                                                e
                                                                              ) =>
                                                                                handleChangeOther(
                                                                                  e,
                                                                                  index,
                                                                                  i,
                                                                                  g,
                                                                                  q,
                                                                                  l
                                                                                )
                                                                              }
                                                                            />
                                                                          ) : param?.exe_type ==
                                                                            'Date&Time' ? (
                                                                            <DatePicker
                                                                              showTime={{
                                                                                format:
                                                                                  'HH:mm'
                                                                              }}
                                                                              format="DD-MM-YYYY HH:mm:ss"
                                                                              value={
                                                                                param?.exe_name ==
                                                                                'End (Date & Time)'
                                                                                  ? ''
                                                                                  : date
                                                                              }
                                                                              style={{
                                                                                width:
                                                                                  '100%'
                                                                              }}
                                                                              disabled
                                                                              onChange={(
                                                                                e
                                                                              ) =>
                                                                                handleChangeOther(
                                                                                  e,
                                                                                  index,
                                                                                  i,
                                                                                  g,
                                                                                  q,
                                                                                  l
                                                                                )
                                                                              }
                                                                            />
                                                                          ) : (
                                                                            ''
                                                                          )}
                                                                        </FormItem>
                                                                      </Col>
                                                                    </>
                                                                  );
                                                                }
                                                              )
                                                            : 'JJJJJ'}
                                                        </Row>
                                                        {console.log(
                                                          instr,
                                                          'Reactjs'
                                                        )}
                                                        {/* {instr?.is_container_exist ? ( */}
                                                        <Row gutter={24}>
                                                          {instr?.containers?.map(
                                                            (param, n) => {
                                                              return (
                                                                <>
                                                                  <Col
                                                                    span={6}
                                                                    key={n}
                                                                  >
                                                                    <FormItem
                                                                      label="Container"
                                                                      rules={[
                                                                        {
                                                                          required: false,
                                                                          message:
                                                                            'Select Container'
                                                                        }
                                                                      ]}
                                                                      className={
                                                                        styles.customLable
                                                                      }
                                                                    >
                                                                      <Input
                                                                        placeholder="Enter Container"
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          handleChangeContainer(
                                                                            e,
                                                                            index,
                                                                            i,
                                                                            g,
                                                                            q,
                                                                            n,
                                                                            'container'
                                                                          )
                                                                        }
                                                                        value={
                                                                          param?.container
                                                                        }
                                                                      />
                                                                    </FormItem>
                                                                  </Col>
                                                                  <Col span={6}>
                                                                    <FormItem
                                                                      label="Tare Weight"
                                                                      rules={[
                                                                        {
                                                                          required: false,
                                                                          message:
                                                                            'Select Container'
                                                                        }
                                                                      ]}
                                                                      className={
                                                                        styles.customLable
                                                                      }
                                                                    >
                                                                      <Input
                                                                        placeholder="Enter Tare Weight"
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          handleChangeContainer(
                                                                            e,
                                                                            index,
                                                                            i,
                                                                            g,
                                                                            q,
                                                                            n,
                                                                            'tare_weight'
                                                                          )
                                                                        }
                                                                        value={
                                                                          param?.tare_weight
                                                                        }
                                                                      />
                                                                    </FormItem>
                                                                  </Col>
                                                                  <Col span={5}>
                                                                    <FormItem
                                                                      label="Net Weight"
                                                                      rules={[
                                                                        {
                                                                          required: false,
                                                                          message:
                                                                            'Select Container'
                                                                        }
                                                                      ]}
                                                                      className={
                                                                        styles.customLable
                                                                      }
                                                                    >
                                                                      <Input
                                                                        placeholder="Enter Net Weight"
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          handleChangeContainer(
                                                                            e,
                                                                            index,
                                                                            i,
                                                                            g,
                                                                            q,
                                                                            n,
                                                                            'net_weight'
                                                                          )
                                                                        }
                                                                        value={
                                                                          param?.net_weight
                                                                        }
                                                                      />
                                                                    </FormItem>
                                                                  </Col>
                                                                  <Col span={5}>
                                                                    <FormItem
                                                                      label="Gross Weight"
                                                                      rules={[
                                                                        {
                                                                          required: false,
                                                                          message:
                                                                            'Select Container'
                                                                        }
                                                                      ]}
                                                                      className={
                                                                        styles.customLable
                                                                      }
                                                                    >
                                                                      <Input
                                                                        placeholder="Enter Gross Weight"
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          handleChangeContainer(
                                                                            e,
                                                                            index,
                                                                            i,
                                                                            g,
                                                                            q,
                                                                            n,
                                                                            'gross_weight'
                                                                          )
                                                                        }
                                                                        value={
                                                                          param?.gross_weight
                                                                        }
                                                                      />
                                                                    </FormItem>
                                                                  </Col>
                                                                  <Col span={2}>
                                                                    {n == 0 ? (
                                                                      !emergencydata?.emergency_stop ? (
                                                                        <PlusCircleOutlined
                                                                          style={{
                                                                            marginTop:
                                                                              '36px',
                                                                            fontSize:
                                                                              '18px'
                                                                          }}
                                                                          onClick={(
                                                                            e
                                                                          ) =>
                                                                            handleAddContainer(
                                                                              e,
                                                                              index,
                                                                              i,
                                                                              g,
                                                                              q,
                                                                              n
                                                                            )
                                                                          }
                                                                        />
                                                                      ) : (
                                                                        ''
                                                                      )
                                                                    ) : (
                                                                      <DeleteOutlined
                                                                        style={{
                                                                          marginTop:
                                                                            '36px',
                                                                          fontSize:
                                                                            '18px'
                                                                        }}
                                                                        onClick={() =>
                                                                          handledeleteContainer(
                                                                            index,
                                                                            i,
                                                                            g,
                                                                            q,
                                                                            n,
                                                                            g
                                                                          )
                                                                        }
                                                                      />
                                                                    )}
                                                                  </Col>
                                                                </>
                                                              );
                                                            }
                                                          )}
                                                        </Row>
                                                        {/* ) : (
                                                          ''
                                                        )} */}
                                                      </Card>
                                                    ) : (
                                                      // ) : (
                                                      //   ''
                                                      // )
                                                      ''
                                                    )
                                                  }
                                                </Card>
                                                {console.log(
                                                  act_values[0]
                                                    ?.instruction_config
                                                    ?.exe_bmr_actuals,
                                                  'PPPP'
                                                )}
                                                {
                                                  // mainGroupId == param?.id &&
                                                  // lotId == g &&
                                                  // instr?.bmr_show ? (

                                                  added_bmrs ? (
                                                    <>
                                                      <br />
                                                      <FormItem className="text-center">
                                                        <Space>
                                                          <CustomButton
                                                            className={
                                                              styles.inwardButton
                                                            }
                                                            onClick={() =>
                                                              handleSubmit(
                                                                index,
                                                                i,
                                                                g,
                                                                q,
                                                                // param?.type_seq
                                                                act_values[0]
                                                                  ?.instruction_config
                                                                  ?.exe_bmr_actuals
                                                                  ? parseInt(
                                                                      act_values[0]
                                                                        ?.instruction_config
                                                                        ?.exe_bmr_actuals?.[
                                                                        act_values[0]
                                                                          ?.instruction_config
                                                                          ?.exe_bmr_actuals
                                                                          ?.length
                                                                      ]
                                                                        ?.type_sequence
                                                                    ) + 1
                                                                  : 1
                                                              )
                                                            }
                                                            type="primary"
                                                            // size="small"
                                                            disabled={
                                                              act_values?.length ===
                                                              instr
                                                                ?.execute_bmr_details
                                                                ?.length
                                                                ? true
                                                                : false ||
                                                                  emergencydata?.emergency_stop
                                                            }
                                                          >
                                                            Save
                                                          </CustomButton>
                                                        </Space>
                                                      </FormItem>
                                                    </>
                                                  ) : (
                                                    ''
                                                  )
                                                }
                                              </Card>
                                              <br />
                                            </>
                                          );
                                        })}
                                      </Panel>
                                    </Collapse>

                                    <br />
                                  </>
                                ))
                              : ''}

                            {param?.lot ? (
                              <Row gutter={24}>
                                <Col span={6}>
                                  <FormItem
                                    rules={[
                                      {
                                        required: false,
                                        message: 'Enter Lots'
                                      }
                                    ]}
                                    className={styles.customLable}
                                  >
                                    <Input placeholder="Enter number of Lots" />
                                  </FormItem>
                                </Col>
                              </Row>
                            ) : (
                              ''
                            )}
                          </>
                        </Card>
                        <br />
                      </>
                    );
                  })}
                </Panel>
              </Collapse>
              <br />
            </>
          );
        })}

        <br />
        <FormItem className="text-center">
          <Space>
            <CustomButton
              className={styles.inwardButton}
              htmlType="submit"
              type="primary"
              disabled={emergencydata?.emergency_stop}
            >
              Proceed
            </CustomButton>
          </Space>
        </FormItem>
        {showFBB ? (
          <CustomModal
            title="FBB"
            visible={showFBB}
            width="100%"
            footer={null}
            className={styles.customModal}
            closable={false}
          >
            <div className={`${styles.table_row}`}>
              {/* <CustomTable
                columns={column}
                dataSource={dataIndexes}
                pagination={false}
                scroll={{ x: 'auto' }}
              /> */}
            </div>
            <FormItem className="text-center">
              <Space>
                <CustomButton
                  className={styles.inwardButton}
                  onClick={handleClose}
                  type="primary"
                  size="small"
                  ghost
                >
                  Close
                </CustomButton>
                <CustomButton
                  className={styles.inwardButton}
                  onClick={handleModalSubmit}
                  type="primary"
                  size="small"
                  disabled={emergencydata?.emergency_stop}
                >
                  Save
                </CustomButton>
              </Space>
            </FormItem>
          </CustomModal>
        ) : (
          ''
        )}
      </Form>
      {isModalShow ? (
        <CustomModal
          // title="Verification"
          visible={isModalShow}
          width="30%"
          footer={null}
          className={styles.customModal}
          closable={false}
        >
          <Form form={form} layout="vertical">
            {/* <FormItem className="text-center"> */}
            <div
              style={{
                fontSize: '17px ',
                color: '#e80e94',
                textAlign: 'center'
              }}
            >
              <b>Verification</b>
            </div>
            {/* </FormItem> */}
            <Row gutter={24}>
              <Col span={24}>
                <FormItem
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Password'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input.Password
                    placeholder="Enter Password"
                    onChange={(e) => handlePassword(e)}
                  />
                </FormItem>
              </Col>
            </Row>
            <FormItem className="text-center">
              <Space>
                <CustomButton
                  className={styles.inwardButton}
                  onClick={handleClose}
                  type="primary"
                  size="small"
                  ghost
                >
                  Close
                </CustomButton>
                <CustomButton
                  className={styles.inwardButton}
                  // htmlType="submit"
                  type="primary"
                  size="small"
                  onClick={() => handleVerify()}
                >
                  Verify
                </CustomButton>
              </Space>
            </FormItem>
          </Form>
        </CustomModal>
      ) : (
        ''
      )}
      {ismodel ? (
        <CustomModal
          title={`Are you sure you want to ${
            bmrtype == 'start' ? 'Release' : 'Stop'
          } this Process`}
          visible={ismodel}
          width="50%"
          footer={null}
          className={styles.customModal}
          closable={false}
        >
          <Form form={form} layout="vertical">
            <Row gutter={24}>
              <Col span={24}>
                <FormItem
                  label="Remarks"
                  name="remarks"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Password'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter Remarks"
                    onChange={(e) => handleRemarks(e)}
                  />
                </FormItem>
              </Col>
            </Row>
            <FormItem className="text-center">
              <Space>
                <CustomButton
                  className={styles.inwardButton}
                  onClick={handleClose}
                  type="primary"
                  size="small"
                  ghost
                >
                  Close
                </CustomButton>
                <CustomButton
                  className={styles.inwardButton}
                  // htmlType="submit"
                  type="primary"
                  size="small"
                  onClick={() => handleEmergencyStop()}
                >
                  Submit
                </CustomButton>
              </Space>
            </FormItem>
          </Form>
        </CustomModal>
      ) : (
        ''
      )}
      {isEmsModal ? (
        <CustomModal
          title=""
          visible={isEmsModal}
          width="85%"
          footer={null}
          className={styles.customModal}
          closable={false}
        >
          <div className={`${styles.table_row}`}>
            <Form form={form2} layout="vertical" onFinish={onFinishEmsModal}>
              <Card title={'Create EMS details'} className={styles.CustomPanel}>
                <Row gutter={24}>
                  <Col xs={24} xl={12} span={24} md={24} sm={24}>
                    <FormItem
                      label="Room Number"
                      name="ems_room_number_id"
                      rules={[
                        {
                          required: true,
                          message: 'Select Room Number'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input placeholder="Enter Room Number" />
                      {/* <Select
                        placeholder="Select Room Number"
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        onSearch={(e) => onRoomNumberSearch(e)}
                      >
                        {roomNumbersData?.data?.map((item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {item.roomNumber}
                            </option>
                          );
                        })}
                      </Select> */}
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={24} md={24} sm={24}>
                    <FormItem
                      label="Date"
                      name="date"
                      className={styles.customLable}
                    >
                      <LiveTimeInput valueType={'date'} />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={24} md={24} sm={24}>
                    <FormItem
                      label="Time"
                      name="time"
                      className={styles.customLable}
                    >
                      <LiveTimeInput valueType={'time'} />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={24} md={24} sm={24}>
                    <FormItem
                      label={
                        <span>
                          Temperature NMT 25<sup>o</sup>C
                        </span>
                      }
                      name="temperatureInDegreeCelcius"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Temperature'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input placeholder="Enter Temperature" />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={24} md={24} sm={24}>
                    <FormItem
                      label="Relative Humidity NMT 60% (RH)"
                      name="relativeHumidity"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Relative Humidity'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input placeholder="Enter Relative Humidity" />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={24} md={24} sm={24}>
                    <FormItem
                      label="Pressure Differential (NLT 1.0mm of WC)"
                      name="pressureDifferential"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Pressure Differential'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input placeholder="Enter Pressure Differential" />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={24} md={24} sm={24}>
                    <FormItem
                      label="Checked by (Sign & Date)"
                      name="checkedBy"
                      className={styles.customLable}
                    >
                      <Input
                        defaultValue={`${user?.user?.employee} (${moment(
                          new Date()
                        ).format('DD-MMM-YYYY')})`}
                        disabled={true}
                      />
                    </FormItem>
                  </Col>
                </Row>
              </Card>

              <br />
              <FormItem className="text-center">
                <Space>
                  <CustomButton
                    className={styles.inwardButton}
                    htmlType="button"
                    type="primary"
                    size="medium"
                    onClick={onFinishEmsModal}
                  >
                    Submit
                  </CustomButton>
                  <CustomButton
                    className={styles.inwardButton}
                    onClick={() => setIsEmsModal(false)}
                    type="primary"
                    ghost
                    size="medium"
                  >
                    Close
                  </CustomButton>
                </Space>
              </FormItem>
            </Form>
            <br />
            {content}
          </div>
        </CustomModal>
      ) : (
        ''
      )}
    </Layout>
  );
};
export default ExecuteBMR;
