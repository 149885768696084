import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { materials } from './api';

const initialState = {
  allMaterials: [],
  pagination: [],
  getAllMaterilLoading: null
};

const actions = {
  GET_ALL_MATERIALS: 'materials/GET_ALL_MATERIALS'
};

export const getAllMaterials = createAsyncThunk(
  actions.GET_ALL_MATERIALS,
  async (payload) => {
    const response = await materials('all', payload);

    return response;
  }
);

export const materialSlice = createSlice({
  name: 'materials',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMaterials.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllMaterials.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, pagination } = action.payload;
        if (success) {
          state.allMaterials = data;
          state.pagination = pagination || null;
        } else {
          state.allMaterials = [];
        }
      })
      .addCase(getAllMaterials.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
  }
});
export default materialSlice.reducer;
