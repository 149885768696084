import React, { useState } from 'react';
import { Form, Card, Space, DatePicker } from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import localStyles from './index.module.less';
import CustomTable from 'common/Content/CustomTable';
import CustomButton from '@/common/CustomButton';
import Layout from '@/common/Layout';
import { history } from 'app/history';
import { useGetWaterCollectionListQuery } from '../../redux/apiSlice';
import moment from 'moment';
import Spinner from '../../../../common/Spinner';
const { RangePicker } = DatePicker;

const AdminPage = () => {
  const [form] = Form.useForm();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  let totalRecords;

  const { data, error, isLoading, refetch } = useGetWaterCollectionListQuery({
    page,
    per_page: perPage,
    from_date: fromDate,
    to_date: toDate
  });

  console.log('data ====', data);

  const columns = [
    {
      title: 'Collection Date & Time',
      dataIndex: 'collection_date',
      key: 'collection_date',
      ellipsis: true,
      render: (_, record) => {
        return record?.collection_date
          ? moment(record.collection_date).format('DD-MMM-YYYY (hh:mm:ss A)')
          : '';
      }
    },
    {
      title: 'Water Quantity (in Liters)',
      dataIndex: 'water_quantity',
      key: 'water_quantity',
      ellipsis: true
    },
    {
      title: 'Collected by (User id & Date)',
      dataIndex: 'collected_by',
      key: 'collected_by',
      ellipsis: true,
      // width: '200px',
      render: (_, record) => {
        let result = '';
        record?.collected_by_user_id
          ? (result = record.collected_by_user_id)
          : '';
        record?.collected_by_user_date
          ? (result += ` (${moment(record?.collected_by_user_date).format(
              'DD-MMM-YYYY'
            )})`)
          : '';
        return result;
      }
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
      ellipsis: true
      // width: '200px',
    }
  ];

  let content;
  if (isLoading) {
    console.log('Loading.....');
    content = <Spinner />;
  } else if (data) {
    console.log(data?.pagination?.total_records);
    totalRecords = data?.pagination?.total_records;
    content = (
      <Layout sider={false}>
        <div className={localStyles.heading1}>
          <h1>Overview</h1>
          <h4>Your current requests summary and other activities</h4>
        </div>
        <Form form={form} layout="vertical">
          <Card
            title={<span>Purified Water Collection Log</span>}
            className={`${styles.cardStyles_approver}`}
            extra={
              <Space>
                <RangePicker
                  onChange={(e) => {
                    console.log(e[0]._d);
                    setFromDate(moment(e[0]._d).startOf('day').format());
                    setToDate(moment(e[1]._d).endOf('day').format());
                  }}
                />
                <CustomButton
                  className={styles.inwardButton}
                  onClick={() =>
                    history.push('/log-books-purified-water-collection-create')
                  }
                  type="primary"
                >
                  Create
                </CustomButton>
              </Space>
            }
          >
            <div className={`${styles.table_container}`}>
              <CustomTable
                columns={columns}
                dataSource={data.data}
                pagination={{
                  size: 'small',
                  pageSize: perPage,
                  defaultCurrentPage: page,
                  total: totalRecords,
                  onChange: (page, pageSize) => {
                    setPage(page);
                    setPerPage(pageSize);
                    refetch();
                  },
                  showSizeChanger: true
                }}
              />
            </div>
          </Card>
          <br />
          <FormItem className="text-center">
            <CustomButton
              className={styles.inwardButton}
              onClick={() => history.back()}
              type="primary"
            >
              Back to Home
            </CustomButton>
          </FormItem>
        </Form>
      </Layout>
    );
  } else if (error) {
    console.log(error);
    content = 'Something went wrong!';
  }

  return <>{content}</>;
};
export default AdminPage;
