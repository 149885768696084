// import React, { useEffect, useState } from 'react';
// import Layout from '@/common/Layout';
// import { Card, Col, DatePicker, Form, Input, Row, Space, message, Select } from 'antd';
// import { PlusCircleOutlined } from '@ant-design/icons';
// import styles from '@/common/Layout/index.module.less';
// import FormItem from '@/common/FormItem';
// import CustomButton from '@/common/CustomButton';
// import { useDispatch, useSelector } from 'react-redux';
// import { createStretchWrapper, getStretchWrapper } from '../../redux/slice';
// import { history } from 'app/history';
// import { useLocation } from 'react-router-dom';
// import moment from 'moment';

// const CleaningRecordOfColdChamberCreate = () => {
//   const { returnableData } = useSelector((state) => state.return);
//   const search = useLocation().search;
//   const id = new URLSearchParams(search).get('id');

//   const [form] = Form.useForm();
//   const dispatch = useDispatch();
//   const [dropdownGroups, setDropdownGroups] = useState([{ id: 1 }]);

//   useEffect(() => {
//     if (id && returnableData) {
//       const recordData = returnableData?.[0] || {};

//       form.setFieldsValue({
//         loc: recordData.loc,
//         eq: recordData.eq,
//         mon: recordData.mon,
//       });

//       const initialFields = Array.isArray(recordData.cleaning_wrapping_secs)
//         ? recordData.cleaning_wrapping_secs.map((record, index) => ({
//             id: index + 1,
//             date: moment(record.date, 'YYYY-MM-DD'),
//             cle: record.cle,
//             remarks: record.remarks,
//           }))
//         : [{ id: 1, date: null, cle: null, remarks: null }];

//       setDropdownGroups(initialFields);
//     }
//   }, [id, returnableData]);

//   const onFinish = (values) => {
//     const cleaning_wrapping_secs = dropdownGroups.map((group, index) => ({
//       date: values[`date_${group.id}`]?.format('YYYY-MM-DD'),
//       cle: values[`cle_${group.id}`],
//       remarks: values[`remarks_${group.id}`],
//     }));

//     const payload = {
//       mon: values.mon,
//       eq: values.eq,
//       loc: values.loc,
//       cleaning_wrapping_secs,
//     };

//     console.log('Payload:', payload);
//     dispatch(createStretchWrapper(payload)).then((response) => {
//       if (response?.payload?.success) {
//         message.success(response?.payload?.message);
//         history.push('stretch-wrapping-machine-list');
//       } else {
//         message.error(response?.payload?.error || 'Submission failed');
//       }
//     });
//   };

//   const handleAddDropdown = () => {
//     setDropdownGroups([
//       ...dropdownGroups,
//       { id: dropdownGroups.length + 1, date: '', cle: '', remarks: '' }
//     ]);
//   };

//   return (
//     <Layout sider={false}>
//   <Card
//     title="Cleaning Record of Stretch Wrapping Machine"
//     className={`${styles.cardStyles_approver}`}
//   >
//     <Form
//       form={form}
//       layout="vertical"
//       onFinish={onFinish}
//       className={styles.panelborder}
//     >
//       {/* Static fields: Location, Equipment ID, and Month */}
//       <Row gutter={16} style={{ marginBottom: '16px' }}>
//         <Col span={7}>
//           <FormItem
//             label="Location"
//             name="loc"
//             className={styles.customLable}
//             style={{ marginBottom: 0 }}
//           >
//             <Input placeholder="Select Location" />
//           </FormItem>
//         </Col>
//         <Col span={7}>
//           <FormItem
//             label="Equipment ID"
//             name="eq"
//             className={styles.customLable}
//             style={{ marginBottom: 0 }}
//           >
//             <Input placeholder="Select Equipment ID" />
//           </FormItem>
//         </Col>
//         <Col span={7}>
//           <FormItem
//             label="Month"
//             name="mon"
//             className={styles.customLabel}
//             style={{ marginBottom: 0 }}
//           >
//             <Select
//               placeholder="Select Month"
//               style={{ width: '100%' }}
//             >
//               {[
//                 'January', 'February', 'March', 'April', 'May', 'June',
//                 'July', 'August', 'September', 'October', 'November', 'December'
//               ].map((month, idx) => (
//                 <Select.Option value={idx + 1} key={idx}>
//                   {month}
//                 </Select.Option>
//               ))}
//             </Select>
//           </FormItem>
//         </Col>
//         <Col span={3}  style={{
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'center',
//     }}>
//       <PlusCircleOutlined
//             onClick={handleAddDropdown}
//             style={{
//               fontSize: '24px',
//               color: '#e80e94',
//               cursor: 'pointer',
//             }}
//           />
//           </Col>
//       </Row>

//       {/* Dynamic fields: Date, Cleaned by, Verified by, and Remarks */}
//       {dropdownGroups.map((group, index) => (

//         <Row gutter={16} style={{ marginBottom: '16px' }} key={group.id}>
//           <Col span={8} style={{ marginTop: '24px' }}>
//             <FormItem
//               label="Date"
//               name={`date_${group.id}`}
//               className={styles.customLable}
//             >
//               <DatePicker
//                 style={{ width: '100%' }}
//               />
//             </FormItem>
//           </Col>
//           <Col span={8} style={{ marginTop: '24px' }}>
//             <FormItem
//               label="Cleaned by"
//               name={`cle_${group.id}`}
//               className={styles.customLable}
//             >
//               <Input />
//             </FormItem>
//           </Col>
//           <Col span={8} style={{ marginTop: '24px' }}>
//             <FormItem
//               label="Verified by (User Id & Date)"
//               name={`sec_${group.id}`}
//               className={styles.customLable}
//             >
//               <Input disabled={true} />
//             </FormItem>
//           </Col>
//           <Col span={24}>
//             <FormItem
//               label="Remarks"
//               name={`remarks_${group.id}`}
//               className={styles.customLable}
//             >
//               <Input placeholder="Enter Remarks" />
//             </FormItem>
//           </Col>
//         </Row>
//       ))}

//       {/* Submit and Add Button */}
//       <FormItem className="text-center">
//         <Space>
//           <CustomButton
//             className={styles.inwardButton}
//             htmlType="submit"
//             type="primary"
//           >
//             Submit
//           </CustomButton>
//         </Space>
//       </FormItem>
//     </Form>
//   </Card>
// </Layout>

//   );
// };

// export default CleaningRecordOfColdChamberCreate;

import React, { useEffect, useState } from 'react';
import Layout from '@/common/Layout';
import {
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Space,
  message,
  Select
} from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import CustomButton from '@/common/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { createStretchWrapper, getStretchWrapper } from '../../redux/slice';
import { history } from 'app/history';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

const CleaningRecordOfColdChamberCreate = () => {
  const { returnableData } = useSelector((state) => state.return);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [dropdownGroups, setDropdownGroups] = useState([{ id: 1 }]);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    if (id) {
      handleGetAllReturnGatepass();
    }
  }, [id]);

  useEffect(() => {
    if (id && returnableData) {
      const recordData = returnableData[0];

      if (recordData) {
        form.setFieldsValue({
          id: recordData.id,
          loc: recordData.loc,
          eq: recordData.eq,
          mon: recordData.mon
        });

        if (Array.isArray(recordData.cleaning_wrapping_secs)) {
          const cleaningWrappingSecs = recordData.cleaning_wrapping_secs.map(
            (record, index) => ({
              /*id: record.id*/ id: index + 1,
              date: moment(record.date, 'YYYY-MM-DD'),
              cle: record.cle,
              remarks: record.remarks
            })
          );

          setDropdownGroups(cleaningWrappingSecs);

          cleaningWrappingSecs.forEach((field, index) => {
            form.setFieldsValue({
              [`id_${index + 1}`]: field.id,
              [`date_${index + 1}`]: field.date,
              [`cle_${index + 1}`]: field.cle,
              [`remarks_${index + 1}`]: field.remarks
            });
          });
        }
        setIsEditMode(true);
      }
    }
  }, [id, returnableData]);

  const handleGetAllReturnGatepass = () => {
    let payload = {
      id: id || ''
    };
    dispatch(getStretchWrapper(payload));
  };

  const onFinish = (values) => {
    const cleaning_wrapping_secs = dropdownGroups.map((group) => ({
      id: values[`id_${group.id}`] || null,
      date: values[`date_${group.id}`]?.format('YYYY-MM-DD'),
      cle: values[`cle_${group.id}`],
      remarks: values[`remarks_${group.id}`]
    }));

    const payload = {
      id: id || null,
      loc: values.loc,
      eq: values.eq,
      mon: values.mon,
      cleaning_wrapping_secs
    };

    dispatch(createStretchWrapper(payload)).then((response) => {
      if (response?.payload?.success) {
        message.success(response.payload.message);
        history.push('stretch-wrapping-machine-list');
      } else {
        message.error(response.payload?.error || 'Submission failed');
      }
    });
  };

  const handleAddDropdown = () => {
    setDropdownGroups([
      ...dropdownGroups,
      { id: dropdownGroups.length + 1, date: '', cle: '', remarks: '' }
    ]);
  };

  return (
    <Layout sider={false}>
      <Card
        title="Cleaning Record of Stretch Wrapping Machine"
        className={`${styles.cardStyles_approver}`}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={styles.panelborder}
        >
          {/* Static fields: Location, Equipment ID, and Month */}
          <Row gutter={16} style={{ marginBottom: '16px' }}>
            <Col span={7}>
              <FormItem
                label="Location"
                name="loc"
                className={styles.customLable}
                style={{ marginBottom: 0 }}
              >
                <Input placeholder="Select Location" />
              </FormItem>
            </Col>
            <Col span={7}>
              <FormItem
                label="Equipment ID"
                name="eq"
                className={styles.customLable}
                style={{ marginBottom: 0 }}
              >
                <Input placeholder="Select Equipment ID" />
              </FormItem>
            </Col>
            <Col span={7}>
              <FormItem
                label="Month"
                name="mon"
                className={styles.customLabel}
                style={{ marginBottom: 0 }}
              >
                <Select placeholder="Select Month" style={{ width: '100%' }}>
                  {[
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                  ].map((month, idx) => (
                    <Select.Option value={idx + 1} key={idx}>
                      {month}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col
              span={3}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {!isEditMode && (
                <PlusCircleOutlined
                  onClick={handleAddDropdown}
                  style={{
                    fontSize: '24px',
                    color: '#e80e94',
                    cursor: 'pointer'
                  }}
                />
              )}
            </Col>
          </Row>

          {/* Dynamic fields: Date, Cleaned by, Verified by, and Remarks */}
          {dropdownGroups.map((group) => (
            <Row gutter={16} style={{ marginBottom: '16px' }} key={group.id}>
              <Col span={8} style={{ marginTop: '24px' }}>
                <FormItem
                  label="Date"
                  name={`date_${group.id}`}
                  className={styles.customLable}
                >
                  <DatePicker style={{ width: '100%' }} />
                </FormItem>
              </Col>
              <Col span={8} style={{ marginTop: '24px' }}>
                <FormItem
                  label="Cleaned by"
                  name={`cle_${group.id}`}
                  className={styles.customLable}
                >
                  <Input />
                </FormItem>
              </Col>
              <Col span={8} style={{ marginTop: '24px' }}>
                <FormItem
                  label="Verified by (User Id & Date)"
                  name={`sec_${group.id}`}
                  className={styles.customLable}
                >
                  <Input disabled />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem
                  label="Remarks"
                  name={`remarks_${group.id}`}
                  className={styles.customLable}
                >
                  <Input placeholder="Enter Remarks" />
                </FormItem>
              </Col>
            </Row>
          ))}

          {/* Submit and Add Button */}
          <FormItem className="text-center">
            <Space>
              <CustomButton
                className={styles.inwardButton}
                htmlType="submit"
                type="primary"
              >
                Submit
              </CustomButton>
            </Space>
          </FormItem>
        </Form>
      </Card>
    </Layout>
  );
};

export default CleaningRecordOfColdChamberCreate;
