import React from 'react';
import Layout from '@/common/Layout';
import {
  Row,
  Col,
  Form,
  Card,
  Input,
  Select,
  Space,
  message,
  Image
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  getUserById,
  UserCreate,
  updateUser,
  getAllDesignations,
  getAllDepartments
} from '../../redux/slice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { history } from 'app/history';
import User from '../../images/user.gif';

const createUser = () => {
  const { allDepartments, allDisignations } = useSelector(
    (state) => state.admin
  );

  const [form] = Form.useForm();
  const searchParam = useLocation().search;
  const searchParams = new URLSearchParams(searchParam);

  const [account, setAccount] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [rolePermissions, setRolePermissions] = useState([]);
  const [dept, setDept] = useState('');
  const [subDept, setSubDept] = useState('');
  console.log(setIsEdit);
  const dispatch = useDispatch();
  useEffect(() => {
    handleGetUserById();
  }, []);

  useEffect(() => {
    handleGetAllDepartments();
    handleGetAllDesignations();
  }, []);

  const handleGetAllDepartments = () => {
    const payload = {};
    dispatch(getAllDepartments(payload)).then((response) => {
      setSubDept(response?.payload?.data.filter((item) => item.id !== 12));
    });
  };

  const handleGetAllDesignations = () => {
    const payload = {};
    dispatch(getAllDesignations(payload));
  };

  const handleGetUserById = () => {
    const payload = { user_id: searchParams.get('id') };
    dispatch(getUserById(payload)).then((response) => {
      form.setFieldsValue({
        first_name: response?.payload?.data?.first_name,
        last_name: response?.payload?.data?.last_name,
        middle_name: response?.payload?.data?.middle_name,
        role: response?.payload?.data?.department
          ? `${response?.payload?.data?.department}`
          : '',
        email: response?.payload?.data?.email,
        permission: response?.payload?.data?.level
          ? `${response?.payload?.data?.level}`
          : '',
        department: response?.payload?.data?.department_id
          ? response?.payload?.data?.department_id
          : '',
        emp_id: response?.payload?.data?.employee_id,
        user_id: response?.payload?.data?.employee_id,
        designation_id: response?.payload?.data?.designation_id
          ? response?.payload?.data?.designation_id
          : ''
      });
    });
  };
  console.log(allDepartments);
  const onFinish = (values) => {
    setAccount(true);
    const payload = {
      first_name: values?.first_name,
      last_name: values?.last_name,
      middle_name: values?.middle_name,
      email: values?.email,
      role: values?.department,
      department: values?.department,
      level: values?.permission,
      employee_id: values?.emp_id,
      designation_id: values?.designation_id,
      user_id: searchParams.get('id'),
      qa_dept: rolePermissions ? rolePermissions : []
    };
    searchParams.get('id')
      ? dispatch(updateUser(payload)).then((response) => {
          if (response?.payload?.success) {
            message.success(response?.payload?.message);
            history.push('users-list');
          }
        })
      : dispatch(UserCreate(payload)).then((response) => {
          if (response?.payload?.success) {
            message.success(response?.payload?.message);
            setAccount(false);
            history.push('users-list');
          } else {
            message.error(response?.payload?.message);
            setAccount(false);
          }
        });

    console.log(payload);
  };
  const handleAddmore = () => {
    const list = [...rolePermissions];
    list.push({ role: '', permission: '' });
    setRolePermissions(list);
  };

  const handleChangeDept = (e) => {
    setDept(e);
    if (e == 12) {
      const list = [...rolePermissions];
      list.push({ role: '', permission: '' });
      setRolePermissions(list);
    }
  };

  const handleChangeperm = (e, index, type) => {
    const list = [...rolePermissions];

    type === 'department'
      ? (list[index].role = e)
      : (list[index].permission = e);
    setRolePermissions(list);

    let result = subDept.filter(
      (o1) => !rolePermissions.some((o2) => o1.id === o2.role)
    );
    setSubDept(result);
  };

  return (
    <Layout sider={false}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Card
          title={
            searchParams.get('id') && isEdit
              ? 'UPDATE USER'
              : searchParams.get('id') && !isEdit
              ? 'VIEW DETAILS'
              : 'NEW USER'
          }
          className={styles.CustomPanel}
          extra={
            searchParams.get('id') ? (
              <CustomButton onClick={() => setIsEdit(true)}>
                <EditOutlined />
              </CustomButton>
            ) : (
              ''
            )
          }
        >
          <Row gutter={24}>
            <Col xs={24} xl={8} span={8} md={24} sm={24}>
              <FormItem
                label="First Name"
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: 'Enter first name'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter first name"
                  disabled={searchParams.get('id') && !isEdit ? true : false}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={8} md={24} sm={24}>
              <FormItem
                label="Middle Name"
                name="middle_name"
                rules={[
                  {
                    required: false,
                    message: 'Enter middle name'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter middle name"
                  disabled={searchParams.get('id') && !isEdit ? true : false}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={8} md={24} sm={24}>
              <FormItem
                label="Last Name"
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: 'Enter last name'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter last name"
                  disabled={searchParams.get('id') && !isEdit ? true : false}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={8} md={24} sm={24}>
              <FormItem
                label="Employee ID"
                name="emp_id"
                rules={[
                  {
                    required: true,
                    message: 'Enter employee ID'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter employee ID"
                  onChange={(e) =>
                    form.setFieldsValue({ user_id: e.target.value })
                  }
                  disabled={searchParams.get('id') && !isEdit ? true : false}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={8} md={24} sm={24}>
              <FormItem
                label="User ID"
                name="user_id"
                rules={[
                  {
                    required: false,
                    message: 'Enter user ID'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter user ID" disabled={true} />
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={8} md={24} sm={24}>
              <FormItem
                label="E-mail"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Enter e-mail'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter e-mail"
                  disabled={searchParams.get('id') && !isEdit ? true : false}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={8} md={24} sm={24}>
              <FormItem
                label="Department"
                name="department"
                rules={[
                  {
                    required: true,
                    message: 'Enter department'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  placeholder="Select Department"
                  disabled={searchParams.get('id') && !isEdit ? true : false}
                  onChange={(e) => handleChangeDept(e)}
                >
                  {allDepartments?.map((item, index) => {
                    return (
                      <option value={item?.id} key={index}>
                        {item?.department}
                      </option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={8} md={24} sm={24}>
              <FormItem
                label="Designation"
                name="designation_id"
                rules={[
                  {
                    required: true,
                    message: 'Enter Designation'
                  }
                ]}
                className={styles.customLable}
                placeholder="Select Designation"
              >
                <Select
                  placeholder="Select Designation"
                  disabled={searchParams.get('id') && !isEdit ? true : false}
                >
                  {allDisignations?.map((item, index) => {
                    return (
                      <option value={item?.id} key={index}>
                        {item?.designation}
                      </option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={8} md={24} sm={24}>
              <FormItem
                label="Permission"
                name="permission"
                rules={[
                  {
                    required: true,
                    message: 'Select Permission'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  placeholder="Select Permissions"
                  disabled={searchParams.get('id') && !isEdit ? true : false}
                >
                  <option value="1">Level-1</option>
                  <option value="2">Level-2</option>
                  <option value="3">Level-3</option>
                </Select>
              </FormItem>
            </Col>
          </Row>
        </Card>
        {dept == 12 ? (
          <Card className={styles.CustomPanelFG1}>
            {rolePermissions?.map((item, index) => {
              return (
                <Row gutter={24} key={index}>
                  <Col xs={24} xl={8} span={8} md={24} sm={24}>
                    <FormItem
                      label="Department"
                      rules={[
                        {
                          required: true,
                          message: 'Enter department'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Select
                        placeholder="Select Department"
                        disabled={
                          searchParams.get('id') && !isEdit ? true : false
                        }
                        onChange={(e) =>
                          handleChangeperm(e, index, 'department')
                        }
                      >
                        {subDept?.map((item, index) => {
                          return (
                            <option value={item?.id} key={index}>
                              {item?.department}
                            </option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={8} span={8} md={24} sm={24}>
                    <FormItem
                      label="Permission"
                      rules={[
                        {
                          required: true,
                          message: 'Select Permission'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Select
                        placeholder="Select Permissions"
                        disabled={
                          searchParams.get('id') && !isEdit ? true : false
                        }
                        onChange={(e) =>
                          handleChangeperm(e, index, 'permission')
                        }
                      >
                        <option value="1">Level-1</option>
                        <option value="2">Level-2</option>
                        <option value="3">Level-3</option>
                      </Select>
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={8} span={8} md={24} sm={24}>
                    <FormItem
                      label="Add"
                      rules={[
                        {
                          required: true,
                          message: 'Select Permission'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <CustomButton onClick={() => handleAddmore(index)}>
                        <PlusOutlined />
                      </CustomButton>
                    </FormItem>
                  </Col>
                </Row>
              );
            })}
          </Card>
        ) : (
          ''
        )}
        <br />
        <FormItem className="text-center">
          {searchParams.get('id') && isEdit ? (
            <Space>
              <CustomButton
                className={styles.inwardButton}
                htmlType="submit"
                type="primary"
              >
                Update
              </CustomButton>
              <CustomButton
                className={styles.inwardButton}
                type="primary"
                ghost
                onClick={() => history.back()}
              >
                Back
              </CustomButton>
            </Space>
          ) : searchParams.get('id') && !isEdit ? (
            <CustomButton
              className={styles.inwardButton}
              type="primary"
              ghost
              onClick={() => history.back()}
            >
              Back
            </CustomButton>
          ) : (
            <Space>
              {account ? (
                <Image
                  src={User}
                  preview={false}
                  style={{ width: '100px !important', height: '100px' }}
                />
              ) : (
                <CustomButton
                  className={styles.inwardButton}
                  htmlType="submit"
                  type="primary"
                >
                  Create
                </CustomButton>
              )}
              <CustomButton
                className={styles.inwardButton}
                type="primary"
                ghost
                onClick={() => history.back()}
              >
                Back
              </CustomButton>
            </Space>
          )}
        </FormItem>
      </Form>
    </Layout>
  );
};
export default createUser;
