import React from 'react';
import Layout from '@/common/Layout';
import AuditTrailsPage from './components/allLogBooks';

const AuditTrails = () => {
  return (
    <>
      <Layout sider={false}>
        <AuditTrailsPage />
      </Layout>
    </>
  );
};

export default AuditTrails;
