import React, { useEffect } from 'react';
import Layout from '@/common/Layout';
import { Card, Col, DatePicker, Form, Input, Row, Space, message } from 'antd';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import CustomButton from '@/common/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { createReturnablGP, getAllReturnGP } from '../../redux/slice';
import { history } from 'app/history';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

const createReturnableGatepass = () => {
  const { returnableData } = useSelector((state) => state.return);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    handleGetAllReturnGatepass();
    id
      ? form.setFieldsValue({
          rgp: returnableData?.[0]?.rgp,
          date: moment(returnableData?.[0]?.date, 'YYYY-MM-DD'),
          vehicle_no: returnableData?.[0]?.vehicle_no,
          description: returnableData?.[0]?.des,
          qty: returnableData?.[0]?.qty,
          expect: moment(returnableData?.[0]?.expect, 'YYYY-MM-DD'),
          purpose: returnableData?.[0]?.purpose,
          prepared_by: returnableData?.[0]?.user,
          authorized_by: returnableData?.[0]?.auth,
          received: returnableData?.[0]?.received_by,
          material: moment(returnableData?.[0]?.material, 'YYYY-MM-DD'),
          received_by: returnableData?.[0]?.rec
        })
      : '';
  }, [id]);

  const handleGetAllReturnGatepass = () => {
    let payload = {
      id: id ? id : ''
    };
    dispatch(getAllReturnGP(payload));
  };

  // console.log(returnableData);
  const onFinish = (values) => {
    console.log('Success:', values);
    values['id'] = id;
    dispatch(createReturnablGP(values)).then((response) => {
      if (response?.payload?.success) {
        message.success(response?.payload?.message);
        history.push('returnable-gate-pass-list');
      }
    });
  };

  return (
    <Layout sider={false}>
      <Card
        title="Returnable Gate Pass"
        className={`${styles.cardStyles_approver}`}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={styles.panelborder}
        >
          <Row gutter={24}>
            <Col span={12}>
              <FormItem
                label="RGP #"
                name="rgp"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input placeholder="Enter RGP #" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Preparation Date"
                name="date"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <DatePicker
                  placeholder="Select Date"
                  style={{ width: '100%' }}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Vehicle Number"
                name="vehicle_no"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Vehicle Number" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Description"
                name="description"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Description" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Qty"
                name="qty"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Qty" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Expected date of Return"
                name="expect"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <DatePicker
                  placeholder="Select Date"
                  style={{ width: '100%' }}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Purpose"
                name="purpose"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Purpose" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Prepared By(User & Date)"
                name="user"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Purpose" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Authorized By(User & Date)"
                name="auth"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Purpose" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Received By(User & Date)"
                name="received"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Purpose" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Material return date"
                name="material"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <DatePicker
                  placeholder="Select Date"
                  style={{ width: '100%' }}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Material received by"
                name="received_by"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Purpose" />
              </FormItem>
            </Col>
          </Row>
          <FormItem className="text-center">
            <Space>
              <CustomButton
                className={styles.inwardButton}
                // onClick={handleClose}
                type="primary"
                size="small"
                ghost
              >
                Close
              </CustomButton>
              <CustomButton
                className={styles.inwardButton}
                htmlType="submit"
                type="primary"
                size="small"
              >
                Submit
              </CustomButton>
            </Space>
          </FormItem>
        </Form>
      </Card>
    </Layout>
  );
};
export default createReturnableGatepass;
