import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { users } from './api';

const initialState = {
  allUsers: [],
  pagination: {},
  user: {},
  getAllMaterilLoading: false
};

const actions = {
  GET_ALL_USERS: 'admin/GET_ALL_USERS'
};

export const getAllUsers = createAsyncThunk(
  actions.GET_ALL_USERS,
  async (payload) => {
    const response = await users('user-list', payload);

    return response;
  }
);

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, pagination } = action.payload;
        if (success) {
          state.allUsers = data;
          state.pagination = pagination || null;
        } else {
          state.allUsers = [];
        }
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
  }
});
export default adminSlice.reducer;
