import apiClient from 'utilities/apiClient';

export const container = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.container}/${url}`, payload, true);
};

export const wastage = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.wastage}/${url}`, payload, true);
};

export const Createwastage = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.wastage}/${url}`, payload, true);
};

export const updatewastage = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.wastage}/${url}`, payload, true);
};
export const wastageReasons = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.wastage}/${url}`, payload, true);
};
