import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  Card,
  Input,
  Space,
  Select,
  DatePicker,
  Radio,
  message,
  InputNumber,
  Image
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMaterialslist } from '@/features/stockCard/redux/slice';
import {
  CreateProduct,
  getAllProductNames,
  getAllProductDetails
} from '../../redux/slice';
import { DeleteOutlined, PlusCircleFilled } from '@ant-design/icons';
import truck from '@/features/qa/components/images/truck-delivery-done.gif';

const { Option } = Select;

const createProductPage = () => {
  const [form] = Form.useForm();
  const { allMaterials } = useSelector((state) => state.stocks);
  const { productNames, productDetails } = useSelector(
    (state) => state.product
  );

  const dispatch = useDispatch();

  const [search, setSearch] = useState('');
  const [code, setCode] = useState('');
  const [productType, setProductType] = useState('');
  const [parentBMR, setParentBMR] = useState(false);
  const [lots, setLots] = useState(0);
  const [parts, setParts] = useState(0);
  const [truckShow, setTruckShow] = useState(false);
  const [newmaterials, setNewmaterials] = useState([
    {
      material_id: '',
      supplier_id: '',
      quantity_for_batch: '',
      lots: [],
      stage: '',
      dispensing_required: false,
      is_extra: false,
      compensation_material: false,
      qty_dispensed: '',
      part_qty: '',
      lot_qty: ''
    }
  ]);

  const list = [];

  for (let i = 1; i <= 30; i++) {
    list.push(i);
  }
  console.log(productType);
  const [extragranuler, setExtragranuler] = useState([
    {
      material_id: '',
      supplier_id: '',
      quantity_for_batch: '',
      stage: '',
      dispensing_required: false,
      is_extra: true
    }
  ]);

  const onFinish = (values) => {
    console.log(values);
    setTruckShow(true);
    var arr3 = [...newmaterials, ...extragranuler];
    values['material_items'] = arr3;
    values['customer_details'] = 'USA';
    values['manufacturing_site'] = '1';
    values['is_parent_bmr'] = parentBMR;
    dispatch(CreateProduct(values)).then((response) => {
      if (response?.payload?.success) {
        setTruckShow(false);
        message.success(response?.payload?.message);
        history.push('/dashboard');
      } else {
        setTruckShow(false);
      }
    });
    console.log(values);
  };

  useEffect(() => {
    handleGetAllMaterialsList();
  }, [search, code]);

  useEffect(() => {
    handleGetProductNames();
  }, []);

  const handleGetProductNames = () => {
    dispatch(getAllProductNames());
  };
  const handleGetAllMaterialsList = () => {
    const payload = { search };
    dispatch(getAllMaterialslist(payload));
  };

  const onSearch = (value) => {
    setSearch(value);
  };

  const handleBMR = (e) => {
    console.log(e);
    e.target.value == 'Yes' ? setParentBMR(true) : setParentBMR(false);
  };
  const onChange = (value, index) => {
    const material = allMaterials.filter(
      (MaterialsList) => MaterialsList.id === value
    );
    const list = [...newmaterials];
    list[index]['material_id'] = material[0]?.id;
    list[index]['supplier_id'] = material[0]?.suppliers[0]?.id;
    list[index]['supplier_name'] = material[0]?.suppliers[0]?.manufacturer_name;
    list[index]['material_name'] = material[0]?.name;
    list[index]['uom'] = material[0]?.uom;
    list[index]['compensation_material'] =
      material[0]?.compensation_material == 'Yes' ? true : false;
    setNewmaterials(list);
    // form.setFieldsValue({ material_name: material[0]?.name });
    setCode(material[0]);
  };

  const onChangeExtra = (value, index) => {
    const material = allMaterials.filter(
      (MaterialsList) => MaterialsList.id === value
    );
    const list = [...extragranuler];
    list[index]['material_id'] = material[0]?.id;
    list[index]['supplier_id'] = material[0]?.suppliers[0]?.id;
    list[index]['supplier_name'] = material[0]?.suppliers[0]?.manufacturer_name;
    list[index]['material_name'] = material[0]?.name;
    list[index]['uom'] = material[0]?.uom;
    setExtragranuler(list);
    // form.setFieldsValue({ material_name: material[0]?.name });
    setCode(material[0]);
  };

  const handleQuantityChange = (e, index) => {
    const list = [...newmaterials];
    list[index]['quantity_for_batch'] = e.target.value;
  };

  const handleYieldChange = (e, index) => {
    const list = [...newmaterials];
    list[index]['standard_yield_qty'] = e.target.value;
  };

  const handleStageGroup = (e, index) => {
    const list = [...newmaterials];
    list[index]['stage_group'] = e;
  };

  const handleCompensation = (e, index) => {
    console.log(e.target.value);
    const list = [...newmaterials];
    list[index]['compensation_material'] =
      e.target.value == 'Yes' ? true : false;
  };

  const handleExtraQuantityChange = (e, index) => {
    const list = [...extragranuler];
    list[index]['quantity_for_batch'] = e.target.value;
  };

  console.log(newmaterials);
  const handleAdd = () => {
    setNewmaterials([
      ...newmaterials,
      {
        material_id: '',
        supplier_id: '',
        quantity_for_batch: '',
        lots: [],
        stage: '',
        dispensing_required: false,
        is_extra: false,
        compensation_material: false,
        qty_dispensed: '',
        part_qty: '',
        lot_qty: ''
      }
    ]);
  };

  const handleAddExtra = () => {
    setExtragranuler([
      ...extragranuler,
      {
        material_id: '',
        supplier_id: '',
        quantity_for_batch: '',
        stage: '',
        dispensing_required: false,
        is_extra: true
      }
    ]);
  };

  const handleRemove = (i) => {
    console.log(i);
    let formavalues = [...newmaterials];
    formavalues.splice(i, 1);
    setNewmaterials(formavalues);
  };

  const handleRemoveExtra = (i) => {
    let formavalues = [...extragranuler];
    formavalues.splice(i, 1);
    setExtragranuler(formavalues);
  };

  const handleLotChange = (e, index, i) => {
    const list = [...newmaterials];
    console.log(e.target.value);
    list[index].lots[i]['lot'] = e.target.value;

    setNewmaterials(list);
  };
  const handlePartChange = (e, index, i, j) => {
    console.log(e, index, i, j);
    const list = [...newmaterials];
    // console.log(list[index].lots[i]['parts'][j]['part']);
    parts == 1
      ? (list[index].lots[i]['parts'] = [
          {
            part:
              j == 0 ? e.target.value : list[index].lots[i]['parts'][0]['part']
          }
        ])
      : (list[index].lots[i]['parts'] = [
          {
            part:
              j == 0 ? e.target.value : list[index].lots[i]['parts'][0]['part']
          },
          {
            part:
              j == 1 ? e.target.value : list[index].lots[i]['parts'][1]['part']
          }
        ]);
    setNewmaterials(list);
    // console.log(list);
    // setNewmaterials({
    //   ...newmaterials[index],
    //   lots: [
    //     {
    //       ...newmaterials[index].lots,
    //       parts: [...newmaterials[index].lots[i].parts, { part: 30 }]
    //     }
    //   ]
    // });
    // setNewmaterials([
    //   ...list,
    //   {
    //     lots: [...list[0].lots, { ...list[0].lots[1].parts, part: 'played' }]
    //   }
    // ]);
    // const newState = list.map((obj) => {
    //   // 👇️ if id equals 2, update country property
    //   if (obj.lots[i].parts[j] === j) {
    //     return { ...obj.lots[i].parts[j], part: e.target.value };
    //   }

    //   // 👇️ otherwise return the object as is
    //   return obj;
    // });

    // setNewmaterials(list);
  };

  const handlelotsChange = (e, index, type) => {
    console.log(e.target.value, index, type);
    // let batch_qty = newmaterials[index].quantity_for_batch;
    // let excess_qty = newmaterials[index].excess_qty;
    // let cal = parseFloat(batch_qty * excess_qty).toFixed(3) / 100;
    // let total_qty = parseFloat(batch_qty) + parseFloat(cal);
    // console.log(total_qty);
    const list = [...newmaterials];
    setLots(e.target.value);
    let lots = [];
    for (var i = 0; i < e.target.value; i++) {
      lots.push({ lot: '', parts: [] });
    }
    list[index].lot_qty = e.target.value;
    list[index].lots = lots;
    console.log(newmaterials);
  };

  const handlepartsChange = (e, index, type) => {
    console.log(e, index, type);
    setParts(e.target.value);
    const list = [...newmaterials];
    let parts = [];
    for (var i = 0; i < e.target.value; i++) {
      parts.push({ part: '' });
    }
    list[index].lots.map((item) => {
      item.parts = parts;
    });
    list[index].part_qty = e.target.value;
    setNewmaterials(list);
    console.log(newmaterials);
  };

  const calculateQty = (index) => {
    console.log(newmaterials[index].excess_qty);
    let batch_qty = newmaterials[index].quantity_for_batch;
    let excess_qty = newmaterials[index].excess_qty || 0;
    let cal = (parseFloat(batch_qty) * parseFloat(excess_qty)).toFixed(3) / 100;
    let total_qty = parseFloat(batch_qty) + parseFloat(cal);
    let lot_qty = total_qty / lots;
    let part_qty = lot_qty / parts;
    const list = [...newmaterials];
    list[index].qty_dispensed = total_qty;
    list[index].lots.map((item) => {
      item['lot'] = parseFloat(lot_qty).toFixed(3);
      item.parts.map((part) => {
        part['part'] = parseFloat(part_qty).toFixed(3);
      });
    });

    setNewmaterials(list);
    // list[index].lots.map((item, index, i) => {
    //   // var name = `lot${index}${i}`;
    //   form.setFieldsValue( {`lot${index}${i}`: 30} );
    // });

    console.log(newmaterials);
  };

  const handleExcessChange = (e, index) => {
    console.log(e);
    const list = [...newmaterials];
    list[index]['excess_qty'] = e || 0;
  };

  const handleExtraExcessChange = (e, index) => {
    const list = [...extragranuler];
    list[index]['excess_qty'] = e.target.value;
  };

  const onChangeStage = (e, index) => {
    console.log(e);
    const list = [...newmaterials];
    list[index]['stage'] = e;
  };

  const onChangeDispensing = (e, index) => {
    const list = [...newmaterials];
    list[index]['dispensing_required'] = e == 'Yes' ? true : false;
  };

  const handleDispensingSeq = (e, index) => {
    const list = [...newmaterials];
    list[index]['dispensing_sequence'] = e;
  };

  const handleDispensingExtraSeq = (e, index) => {
    const list = [...extragranuler];
    list[index]['dispensing_sequence'] = e;
  };

  const handleGetProductDetails = (e) => {
    setProductType(e);
    let payload = { product_type_code: e };
    dispatch(getAllProductDetails(payload));
  };

  const handleProductDetails = (e) => {
    let shelf_life = productDetails.filter(
      (data) => data.product_details === e
    );
    console.log(shelf_life);
    form.setFieldsValue({ shelf_life: shelf_life[0]?.shelf_life });
  };
  console.log(allMaterials);
  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Card title={'ADD PRODUCT'} className={styles.CustomPanel}>
        <Row gutter={24}>
          <Col xs={24} xl={12} span={12} md={24} sm={24}>
            <FormItem
              label="Product Name"
              name="is_product"
              rules={[
                {
                  required: false,
                  message: 'Enter Theoretical Yield/Batch Size'
                }
              ]}
              className={styles.customLable}
            >
              <Select
                placeholder="Select Product Type"
                onChange={(e) => handleGetProductDetails(e)}
              >
                {productNames.map((item, index) => {
                  return (
                    <option value={item?.product_name_type} key={index}>
                      {item?.product_name}
                    </option>
                  );
                })}
              </Select>
            </FormItem>
          </Col>
          <Col xs={24} xl={12} span={24} md={24} sm={24}>
            <FormItem
              label="Product Details"
              name="product_name"
              rules={[
                {
                  required: true,
                  message: 'Enter detail name of the Product'
                }
              ]}
              className={styles.customLable}
            >
              <Select
                placeholder="Select Product Details"
                onChange={(e) => handleProductDetails(e)}
              >
                {productDetails?.map((item, index) => {
                  return (
                    <option value={item?.product_details} key={index}>
                      {item?.product_details}
                    </option>
                  );
                })}
              </Select>
              {/* <Input placeholder="Enter detail name of the Product" /> */}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label="Ref. MFC#"
              className={styles.customLable}
              name="ref_mfc"
              rules={[
                {
                  required: true,
                  message: 'Enter Ref MFC'
                }
              ]}
            >
              <Input placeholder="Enter Ref. MFC#" />
            </FormItem>
          </Col>
          <Col xs={24} xl={12} span={12}>
            <FormItem
              label="Shelf life"
              name="shelf_life"
              rules={[
                {
                  required: true,
                  message: 'Enter Shelf life'
                }
              ]}
              className={styles.customLable}
            >
              <Input placeholder="Enter Shelf life" />
            </FormItem>
          </Col>
          <Col xs={24} xl={12} span={12}>
            <FormItem
              label="Manufacturing Site"
              name="manufacturing_site"
              rules={[
                {
                  required: false,
                  message: 'Select Manufacturing Site'
                }
              ]}
              className={styles.customLable}
            >
              <Select
                showSearch
                placeholder="Select  Manufacturing Site"
                optionFilterProp="children"
                defaultValue="1"
              >
                <option value="1">Graviti Pharmaceuticals Pvt. Ltd.</option>
              </Select>
            </FormItem>
          </Col>
          <Col xs={24} xl={12} span={12}>
            <FormItem
              label="Market / Customer Details"
              name="customer_details"
              rules={[
                {
                  required: false,
                  message: 'Enter Market / Customer Details'
                }
              ]}
              className={styles.customLable}
            >
              <Select
                placeholder="Select Market/Customer Details"
                defaultActiveFirstOption={true}
                filterOption={true}
                defaultValue="USA"
              >
                <Option value="USA">USA</Option>
              </Select>
            </FormItem>
          </Col>
          <Col xs={24} xl={12} span={12} md={24} sm={24}>
            <FormItem
              label="BMR / BPR Number"
              name="bmr_bpr_number"
              rules={[
                {
                  required: false,
                  message: 'Enter BMR/BPR Number'
                }
              ]}
              className={styles.customLable}
            >
              <Input placeholder="Enter BMR/BPR Number" />
            </FormItem>
          </Col>
          {/* <Col xs={24} xl={12} span={12} md={24} sm={24}>
            <FormItem
              label="Product Batch Number"
              name="product_batch_number"
              rules={[
                {
                  required: false,
                  message: 'Enter Product Batch Number'
                }
              ]}
              className={styles.customLable}
            >
              <Input placeholder="Enter Product Batch Number" />
            </FormItem>
          </Col> */}
          <Col xs={24} xl={12} span={12} md={24} sm={24}>
            <FormItem
              label="Effective Date"
              name="effective_date"
              rules={[
                {
                  required: false,
                  message: 'Select Effective Date'
                }
              ]}
              className={styles.customLable}
            >
              <DatePicker
                placeholder="Select Effective Date"
                style={{ width: '100%' }}
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={12} span={12} md={24} sm={24}>
            <FormItem
              label="Theoretical Yield of Sifted & Milled Granules"
              name="theoretical_yield"
              rules={[
                {
                  required: false,
                  message: 'Enter Theoretical Yield of Sifted & Milled Granules'
                }
              ]}
              className={styles.customLable}
            >
              <Input placeholder="Enter Theoretical Yield of Sifted & Milled Granules" />
            </FormItem>
          </Col>
          <Col xs={24} xl={12} span={12} md={24} sm={24}>
            <FormItem
              label="Theoretical Yield/Batch Size"
              name="theoretical_batch_size"
              rules={[
                {
                  required: false,
                  message: 'Enter Theoretical Yield/Batch Size'
                }
              ]}
              className={styles.customLable}
            >
              <Input placeholder="Enter Theoretical Yield/Batch Size" />
            </FormItem>
          </Col>
          <Col xs={24} xl={12} span={12} md={24} sm={24}>
            <FormItem
              label="Is this BMR linked to a parent record?"
              rules={[
                {
                  required: false,
                  message: 'Select BMR'
                }
              ]}
              className={styles.customLable}
            >
              <Radio.Group onChange={(e) => handleBMR(e)}>
                <Radio value={'Yes'}>Yes</Radio>
                <Radio value={'No'}>No</Radio>
              </Radio.Group>
            </FormItem>
          </Col>
          {parentBMR ? (
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Parent BMR"
                name="parent_bmr"
                rules={[
                  {
                    required: true,
                    message: 'Enter Parent BMR'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Parent BMR" />
              </FormItem>
            </Col>
          ) : (
            ''
          )}
        </Row>
        {newmaterials.map((item, index) => {
          console.log(item);
          return (
            <>
              <Card
                key={index}
                title={`Material-${index + 1}`}
                extra={
                  <Space>
                    {/* <CustomButton  onClick={() => handleAdd()}>
                      <PlusCircleFilled />
                    </CustomButton > */}
                    {index > 0 ? (
                      <CustomButton
                        className={styles.border_button}
                        onClick={() => handleRemove(index)}
                      >
                        <DeleteOutlined />
                      </CustomButton>
                    ) : (
                      ''
                    )}
                  </Space>
                }
              >
                <Row gutter={24}>
                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="Stage"
                      rules={[
                        {
                          required: true,
                          message: 'Select Stage'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Select
                        showSearch
                        placeholder="Select Stage"
                        optionFilterProp="children"
                        onChange={(e) => onChangeStage(e, index)}
                      >
                        <option value="Granulation">Granulation</option>
                        <option value="Blending">Blending</option>
                        <option value="Compression">Compression</option>
                        <option value="Coating">Coating</option>
                        <option value="Packing">Packing</option>
                      </Select>
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="Dispensing Required"
                      rules={[
                        {
                          required: true,
                          message: 'Select Dispensing Required'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Select
                        showSearch
                        placeholder="Select Dispensing Required"
                        optionFilterProp="children"
                        onChange={(e) => onChangeDispensing(e, index)}
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </Select>
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="Material Code"
                      rules={[
                        {
                          required: true,
                          message: 'Select Material Code'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Select
                        showSearch
                        placeholder="Select a material code"
                        optionFilterProp="children"
                        onChange={(e) => onChange(e, index)}
                        onSearch={onSearch}
                      >
                        {allMaterials?.map((item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {item.code}
                            </option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="Material Name"
                      rules={[
                        {
                          required: true,
                          message: 'Select Material Name'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Enter Material Name"
                        disabled
                        value={newmaterials[index]?.material_name}
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="Source (Manufacturer Name)"
                      rules={[
                        {
                          required: false,
                          message: 'Select Material Code'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        value={newmaterials[index]?.supplier_name}
                        disabled
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="UOM"
                      rules={[
                        {
                          required: false,
                          message: 'Enter UOM'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input value={newmaterials[index]?.uom} disabled />
                    </FormItem>
                  </Col>

                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="Standard Quantity for Batch"
                      rules={[
                        {
                          required: false,
                          message: 'Enter Quantity for Batch'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Enter Quantity for Batch"
                        onChange={(e) => handleQuantityChange(e, index)}
                      />
                    </FormItem>
                  </Col>

                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="Is This Compensatory Material"
                      rules={[
                        {
                          required: false,
                          message: 'Enter Quantity for Batch'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Radio.Group
                        onChange={(e) => handleCompensation(e, index)}
                      >
                        <Radio value={'Yes'}>Yes</Radio>
                        <Radio value={'No'}>No</Radio>
                      </Radio.Group>
                    </FormItem>
                  </Col>
                  {/* {productType == 'ESM' ? ( */}
                  <>
                    <Col xs={24} xl={12} span={12} md={24} sm={24}>
                      <FormItem
                        label="Stage Group"
                        rules={[
                          {
                            required: false,
                            message: 'Select Stage Group'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Select
                          showSearch
                          placeholder="Select Stage Group"
                          optionFilterProp="children"
                          onChange={(e) => handleStageGroup(e, index)}
                        >
                          {list?.map((item, index) => {
                            return (
                              <option value={item} key={index}>
                                {item}
                              </option>
                            );
                          })}
                        </Select>
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={12} span={12} md={24} sm={24}>
                      <FormItem
                        label="Standard Yield of this Stage"
                        rules={[
                          {
                            required: true,
                            message: 'Enter Standard Yield of this Stage'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter Standard Yield of this Stage"
                          onChange={(e) => handleYieldChange(e, index)}
                        />
                      </FormItem>
                    </Col>
                  </>
                  {/* ) : (
                    ''
                  )} */}
                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="Dispensing Sequence"
                      rules={[
                        {
                          required: false,
                          message: 'Select Dispensing Sequence'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Select
                        showSearch
                        placeholder="Select Dispensing Sequence"
                        optionFilterProp="children"
                        onChange={(e) => handleDispensingSeq(e, index)}
                      >
                        {list?.map((item, index) => {
                          return (
                            <option value={item} key={index}>
                              {item}
                            </option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="Excess Quantity (%)"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Excess Quantity'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <InputNumber
                        min={0}
                        style={{
                          width: '100%'
                        }}
                        placeholder="Enter Excess Quantity"
                        onChange={(e) => handleExcessChange(e, index)}
                      />
                    </FormItem>
                  </Col>
                  <>
                    <Col xs={24} xl={12} span={12} md={24} sm={24}>
                      <FormItem
                        label="How many lots?"
                        rules={[
                          {
                            required: false,
                            message: 'Enter number of lots'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter number of lots"
                          onChange={(e) => handlelotsChange(e, index)}
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={12} span={12} md={24} sm={24}>
                      <FormItem
                        label="How many parts for each lot?"
                        rules={[
                          {
                            required: false,
                            message: 'Enter number of parts'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter number of parts"
                          onChange={(e) => handlepartsChange(e, index)}
                        />
                      </FormItem>
                    </Col>
                  </>
                  {item?.lots.map((sub_item, i) => {
                    return (
                      <Col xs={24} xl={12} span={12} md={24} sm={24} key={i}>
                        <FormItem
                          label={`Lot -${i + 1}`}
                          rules={[
                            {
                              required: false,
                              message: 'Enter Lot Quantity'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Input
                            placeholder="Enter Lot Quantity"
                            onChange={(e) => handleLotChange(e, index, i)}
                            value={sub_item.lot}
                          />
                        </FormItem>
                        <Row gutter={24}>
                          {sub_item?.parts?.map((part, j) => {
                            return (
                              <Col
                                xs={12}
                                xl={12}
                                span={12}
                                md={24}
                                sm={24}
                                key={j}
                              >
                                <FormItem
                                  label={`Part -${j + 1}`}
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Enter part Quantity'
                                    }
                                  ]}
                                  className={styles.customLable}
                                >
                                  <Input
                                    placeholder="Enter part Quantity"
                                    onChange={(e) =>
                                      handlePartChange(e, index, i, j)
                                    }
                                    value={part.part}
                                  />
                                </FormItem>
                              </Col>
                            );
                          })}
                        </Row>
                      </Col>
                    );
                  })}
                </Row>
                <FormItem className="text-center">
                  <Space size={5}>
                    <CustomButton
                      className={styles.inwardButton}
                      type="primary"
                      ghost
                      onClick={() => calculateQty(index)}
                    >
                      Calculate Lot/Part Quantities
                    </CustomButton>

                    <CustomButton
                      type="primary"
                      ghost
                      onClick={() => handleAdd()}
                    >
                      <PlusCircleFilled />
                    </CustomButton>
                    {/* {index > 0 ? (
                      <CustomButton  onClick={() => handleRemove(index)}>
                        <DeleteOutlined />
                      </CustomButton >
                    ) : (
                      ''
                    )} */}
                  </Space>
                </FormItem>
              </Card>
              <br />
            </>
          );
        })}
        {extragranuler.map((_, index) => {
          return (
            <>
              <Card
                key={index}
                title={`Extra Granular/Lubrication Ingredient-${index + 1}`}
                extra={
                  <Space>
                    <CustomButton onClick={() => handleAddExtra()}>
                      <PlusCircleFilled />
                    </CustomButton>
                    {index > 0 ? (
                      <CustomButton
                        className={styles.border_button}
                        onClick={() => handleRemoveExtra(index)}
                      >
                        <DeleteOutlined />
                      </CustomButton>
                    ) : (
                      ''
                    )}
                  </Space>
                }
              >
                <Row gutter={24}>
                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="Material Code"
                      rules={[
                        {
                          required: true,
                          message: 'Select Material Code'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Select
                        showSearch
                        placeholder="Select a material code"
                        optionFilterProp="children"
                        onChange={(e) => onChangeExtra(e, index)}
                        onSearch={onSearch}
                      >
                        {allMaterials?.map((item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {item.code}
                            </option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="Material Name"
                      rules={[
                        {
                          required: true,
                          message: 'Select Material Name'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Enter Material Name"
                        disabled
                        value={extragranuler[index]?.material_name}
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="Source (Manufacturer Name)"
                      rules={[
                        {
                          required: false,
                          message: 'Select Material Code'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        value={extragranuler[index]?.supplier_name}
                        disabled
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="UOM"
                      rules={[
                        {
                          required: false,
                          message: 'Enter UOM'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input value={extragranuler[index]?.uom} disabled />
                    </FormItem>
                  </Col>

                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="Standard Quantity for Batch"
                      rules={[
                        {
                          required: false,
                          message: 'Enter Quantity for Batch'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Enter Quantity for Batch"
                        onChange={(e) => handleExtraQuantityChange(e, index)}
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="Dispensing Sequence"
                      rules={[
                        {
                          required: false,
                          message: 'Select Dispensing Sequence'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Select
                        showSearch
                        placeholder="Select Dispensing Sequence"
                        optionFilterProp="children"
                        onChange={(e) => handleDispensingExtraSeq(e, index)}
                      >
                        {list?.map((item, index) => {
                          return (
                            <option value={item} key={index}>
                              {item}
                            </option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="Excess Quantity"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Excess Quantity'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Enter Excess Quantity"
                        onChange={(e) => handleExtraExcessChange(e, index)}
                      />
                    </FormItem>
                  </Col>
                </Row>
              </Card>
              <br />
            </>
          );
        })}
      </Card>
      <br />
      <FormItem className="text-center">
        <Space>
          {truckShow ? (
            <Image
              src={truck}
              preview={false}
              style={{ width: '100px !important', height: '100px' }}
            />
          ) : (
            <CustomButton
              className={styles.inwardButton}
              htmlType="submit"
              type="primary"
            >
              Create
            </CustomButton>
          )}
          <CustomButton
            className={styles.inwardButton}
            type="primary"
            ghost
            onClick={() => history.back()}
          >
            Back
          </CustomButton>
        </Space>
      </FormItem>
    </Form>
  );
};
export default createProductPage;
