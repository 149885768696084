import React, { useEffect } from 'react';
import Layout from '@/common/Layout';
import { Card, Col, DatePicker, Form, Input, Row, Space, message } from 'antd';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import CustomButton from '@/common/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  // createnonReturnablGP,
  createnonReturnablGPRegister,
  // getAllNonReturnGP,
  getAllnonReturnGPRegister
} from '../../redux/slice';
import { history } from 'app/history';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

const createNonReturnableGatepassRegister = () => {
  const { nonreturnableData } = useSelector((state) => state.return);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    handleGetAllReturnGatepass();
    id
      ? form.setFieldsValue({
          date: moment(nonreturnableData?.[0]?.date, 'YYYY-MM-DD'),
          nrgp: nonreturnableData?.[0]?.nrgp,
          des: nonreturnableData?.[0]?.des,
          qty: nonreturnableData?.[0]?.qty,
          per: nonreturnableData?.[0]?.per,
          uom: nonreturnableData?.[0]?.uom,
          war: nonreturnableData?.[0]?.war,
          sec: nonreturnableData?.[0]?.sec,
          remarks: nonreturnableData?.[0]?.remarks
        })
      : '';
  }, [id]);

  const handleGetAllReturnGatepass = () => {
    let payload = {
      id: id ? id : ''
    };
    dispatch(getAllnonReturnGPRegister(payload));
  };

  const onFinish = (values) => {
    console.log('Success:', values);
    dispatch(createnonReturnablGPRegister(values)).then((response) => {
      if (response?.payload?.success) {
        message.success(response?.payload?.message);
        history.push('non-returnable-gate-pass-register-list');
      }
    });
  };

  return (
    <Layout sider={false}>
      <Card
        title="Non Returnable Gate Pass Register"
        className={`${styles.cardStyles_approver}`}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={styles.panelborder}
        >
          <Row gutter={24}>
            <Col span={12}>
              <FormItem
                label="Date"
                name="date"
                //rules={[{ required: true }]}
                className={styles.customLable}
              >
                <DatePicker style={{ width: '100%' }} />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="NRGP#"
                name="nrgp"
                //rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Description of Goods"
                name="des"
                //rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="UOM"
                name="uom"
                //rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Qty"
                name="qty"
                //rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Person Collected the Material"
                name="per"
                //rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Warehouse (User Id & Date)"
                name="war"
                //rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Security Out Ward No"
                name="sec"
                //rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="remarks"
                name="remarks"
                //rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Remarks" />
              </FormItem>
            </Col>
            {/* <Col span={12}>
              <FormItem
                label="Received By(User & Date)"
                name="received_by"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input placeholder="received by" />
              </FormItem>
            </Col> */}
          </Row>
          <FormItem className="text-center">
            <Space>
              {/* <CustomButton
                className={styles.inwardButton}
                // onClick={handleClose}
                type="primary"
                size="small"
                ghost
              >
                Close
              </CustomButton> */}
              <CustomButton
                className={styles.inwardButton}
                htmlType="submit"
                type="primary"
                size="small"
              >
                Submit
              </CustomButton>
            </Space>
          </FormItem>
        </Form>
      </Card>
    </Layout>
  );
};
export default createNonReturnableGatepassRegister;
