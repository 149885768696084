import React, { useState } from 'react';
import { Row, Form, Input, Typography, Col } from 'antd';
import styles from './index.module.less';
import { Message, Icon } from 'semantic-ui-react';
import tataPro from '../../images/logo.svg';
import truck_img from '../../images/login-icon.svg';
import FormItem from 'common/FormItem';
import CustomButton from 'common/CustomButton';

const { Link } = Typography;

const LoginPage = ({ loading, onSubmit }) => {
  const [errorMsg] = useState('');
  const [form] = Form.useForm();

  const onFinish = (values) => {
    const { email, password } = values;

    let payload = {
      email,
      password
    };

    onSubmit(payload);
  };
  return (
    <>
      <Row className={styles.mainContainerStyle}>
        <Col xs={2} xl={12}>
          <img className={`${styles.left_logo} mt-5 ml-5 mb-5`} src={tataPro} />

          <img className={`${styles.main_img}mt-5 ml-5 mb-5`} src={truck_img} />
        </Col>
        <Col xs={22} xl={12}>
          <div>
            <h1
              style={{
                marginTop: '20%',
                marginLeft: '20%',
                fontWeight: 'Bold',
                fontFamily: 'Source Sans Pro'
              }}
            >
              Let&apos;s get started
            </h1>
            <h4
              style={{
                marginLeft: '20%',
                fontFamily: 'Source Sans Pro',
                textJustify: 'inter-word',
                marginRight: '20%'
              }}
            >
              Manage all receipts, stock movements, QC and dispensing processes
            </h4>
          </div>
          {/* <AuthWrapper> */}
          <div className={` ${styles.right_outer_container}`}>
            <Form
              form={form}
              layout="vertical"
              className={`ant-form ant-form-vertical mt-4 ${styles.form_width}`}
              onFinish={onFinish}
            >
              <FormItem
                label="User ID"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
              >
                <Input
                  placeholder="Enter Employee/User ID"
                  className={styles.customLable}
                />
              </FormItem>
              <FormItem
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
              >
                <Input.Password
                  placeholder="Enter password"
                  className={styles.customLable}
                />
              </FormItem>
              <Row>
                <Col span={12}>
                  <Link
                    href="/change-password"
                    className="d-block text-left mb-4 sfprotext-medium font-12"
                  >
                    Change password?
                  </Link>
                </Col>
                <Col span={12}>
                  <Link
                    href="/reset-password"
                    className="d-block text-right mb-4 sfprotext-medium font-12"
                  >
                    Forgot password?
                  </Link>
                </Col>
              </Row>
              <FormItem>
                <CustomButton
                  block
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Sign in
                </CustomButton>
              </FormItem>
            </Form>
          </div>
        </Col>
      </Row>
      {/*  <div className={styles.bakground_img}>
        <div className={styles.left_screen}>
          <Row className={`mt-5 ml-5 mb-5`}></Row>
          <Row className={`mt-5 ml-5 mb-5`}></Row>
        </div>
        <div className={styles.right_screen}></div>
      </div> */}
      <div>
        {errorMsg && (
          <Message negative>
            <Icon name="warning" />
            {errorMsg}
          </Message>
        )}
      </div>
    </>
  );
};

export default LoginPage;
