import React, { useEffect } from 'react';
import Layout from '@/common/Layout';
import { Row, Col, Form, Card, Input, InputNumber, message } from 'antd';
import styles from '@/common/Layout/index.module.less';
import { getDispenseMaterialDetails } from '@/features/dispensing/redux/slice';
import FormItem from '@/common/FormItem';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useState } from 'react';
import CustomButton from '@/common/CustomButton';
// import MaterialsCard from '@/common/materials';
import { createExtraGranulation } from '../../redux/slice';
import { history } from 'app/history';

const extraGranulation = () => {
  const { materialDetails } = useSelector((state) => state.dispensing);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const params = useParams();

  const [extraGranules, setExtraGranules] = useState([]);
  const [siftedMaterials, setSiftedMaterials] = useState([
    {
      sift_container: '',
      sift_tare_weight: '',
      sift_net_weight: '',
      sift_gross_weight: '',
      sift_uom: '',
      sift_left_over_container: '',
      sift_left_over_tare_weight: '',
      sift_left_over_net_weight: '',
      sift_left_over_gross_weight: '',
      sift_left_over_uom: '',
      required_blending: '',
      blending_container: '',
      blending_tare_weight: '',
      blending_net_weight: '',
      blending_gross_weight: '',
      blending_uom: '',
      blending_left_over_container: '',
      blending_left_over_tare_weight: '',
      blending_left_over_net_weight: '',
      blending_left_over_gross_weight: ''
    }
  ]);

  useEffect(() => {
    handleGetAllMaterials();
  }, []);

  const handleGetAllMaterials = () => {
    let payload = {
      id: params.id
    };
    dispatch(getDispenseMaterialDetails(payload)).then((response) => {
      if (response?.payload?.success) {
        var extra = response?.payload?.data?.dispensing_materials?.filter(
          (item) => item?.is_extra
        );
        setExtraGranules(extra);

        extra?.map(() => {
          setSiftedMaterials([
            ...siftedMaterials,
            {
              sift_container: '',
              sift_tare_weight: '',
              sift_net_weight: '',
              sift_gross_weight: '',
              sift_uom: '',
              sift_left_over_container: '',
              sift_left_over_tare_weight: '',
              sift_left_over_net_weight: '',
              sift_left_over_gross_weight: '',
              sift_left_over_uom: '',
              required_blending: '',
              blending_container: '',
              blending_tare_weight: '',
              blending_net_weight: '',
              blending_gross_weight: '',
              blending_uom: '',
              blending_left_over_container: '',
              blending_left_over_tare_weight: '',
              blending_left_over_net_weight: '',
              blending_left_over_gross_weight: ''
            }
          ]);
        });
      }
    });
  };
  const handleChange = (type, index, e) => {
    let temp_siftedMaterials = [...siftedMaterials];
    temp_siftedMaterials[index][type] = e;
    if (type === 'sift_tare_weight') {
      temp_siftedMaterials[index]['sift_gross_weight'] =
        e + (temp_siftedMaterials[index]['sift_net_weight'] || 0);
    } else if (type === 'sift_net_weight') {
      temp_siftedMaterials[index]['sift_gross_weight'] =
        e + (temp_siftedMaterials[index]['sift_tare_weight'] || 0);
    }
    if (type === 'sift_left_over_tare_weight') {
      temp_siftedMaterials[index]['sift_left_over_gross_weight'] =
        e + (temp_siftedMaterials[index]['sift_left_over_net_weight'] || 0);
    } else if (type === 'sift_left_over_net_weight') {
      temp_siftedMaterials[index]['sift_left_over_gross_weight'] =
        e + (temp_siftedMaterials[index]['sift_left_over_tare_weight'] || 0);
    }

    if (type === 'blending_tare_weight') {
      temp_siftedMaterials[index]['blending_gross_weight'] =
        e + (temp_siftedMaterials[index]['blending_net_weight'] || 0);
    } else if (type === 'blending_net_weight') {
      temp_siftedMaterials[index]['blending_gross_weight'] =
        e + (temp_siftedMaterials[index]['blending_tare_weight'] || 0);
    }

    if (type === 'blending_left_over_tare_weight') {
      temp_siftedMaterials[index]['blending_left_over_gross_weight'] =
        e + (temp_siftedMaterials[index]['blending_left_over_net_weight'] || 0);
    } else if (type === 'blending_left_over_net_weight') {
      temp_siftedMaterials[index]['blending_left_over_gross_weight'] =
        e +
        (temp_siftedMaterials[index]['blending_left_over_tare_weight'] || 0);
    }
    console.log(temp_siftedMaterials);
    setSiftedMaterials(temp_siftedMaterials);
  };

  const onFinish = (values) => {
    console.log(siftedMaterials);
    const payload = {
      dispensing_request_id: params.id,
      product_balance: values?.production_balance_id,
      extra_container: siftedMaterials
    };
    dispatch(createExtraGranulation(payload)).then((response) => {
      if (response?.payload?.success) {
        message.success(response?.payload.message);
        history.push(`/blending/${params.id}`);
      }
    });
  };

  return (
    <Layout sider={false}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
      >
        <Card
          title={
            <span style={{ fontWeight: 'bold' }}>
              Extra Granular Ingredients
            </span>
          }
          className={styles.CustomPanel}
        >
          <Card>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem label="Product Name" className={styles.customLable}>
                  <Input
                    disabled
                    value={materialDetails?.product_detail?.name || 'NA'}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="Product Batch Number"
                  className={styles.customLable}
                >
                  <Input
                    disabled
                    value={materialDetails?.product_batch_number || 'NA'}
                  />
                </FormItem>
              </Col>

              <Col span={12}>
                <FormItem label="Effective Date" className={styles.customLable}>
                  <Input
                    disabled
                    value={
                      materialDetails?.effective_date
                        ? moment(materialDetails?.effective_date).format(
                            'DD MMM YYYY'
                          )
                        : 'NA'
                    }
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="Batch Size" className={styles.customLable}>
                  <Input
                    disabled
                    value={materialDetails?.product_detail?.batch_size || 'NA'}
                  />
                </FormItem>
              </Col>
            </Row>
          </Card>
          <Card>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem
                  label="Production Balance ID"
                  name="production_balance_id"
                  className={styles.customLable}
                >
                  <Input placeholder="Enter Production Balance ID" />
                </FormItem>
              </Col>
            </Row>
          </Card>
          {siftedMaterials?.map((item, index) => {
            console.log(index);
            var blending =
              materialDetails?.yield_sifted_milleds?.[0]?.percent_actual_yield <
              70
                ? (parseFloat(
                    materialDetails?.yield_sifted_milleds?.[0]
                      ?.percent_actual_yield
                  ) *
                    parseFloat(extraGranules[index]?.required_weight)) /
                  100
                : extraGranules[index]?.required_weight;
            // let temp_siftedMaterials = [...siftedMaterials];
            // console.log(temp_siftedMaterials);
            // temp_siftedMaterials[index]['blending'] = blending;
            siftedMaterials[index]['required_blending'] = blending;
            siftedMaterials[index]['sift_uom'] =
              extraGranules[index]?.material?.uom;
            siftedMaterials[index]['dispensing_material_id'] =
              extraGranules[index]?.material?.id;

            return (
              <Card
                key={index}
                title={`Extra Granuler material Sifting - ${extraGranules[index]?.material?.name}`}
              >
                <Card
                  title={
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontSize: '12px',
                        color: '#c91761'
                      }}
                    >
                      {`Sifted Materials of ${extraGranules[index]?.material?.name}`}
                    </span>
                  }
                >
                  <Row gutter={24}>
                    <Col xs={24} xl={4} span={24} md={24} sm={24}>
                      <FormItem
                        label="Container #"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Container #'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter Container ID"
                          value={siftedMaterials?.[index]?.sift_container || ''}
                          onChange={(e) => {
                            handleChange(
                              `sift_container`,
                              index,
                              e.target.value
                            );
                          }}
                        />
                      </FormItem>
                    </Col>

                    <Col xs={24} xl={4} span={24} md={24} sm={24}>
                      <FormItem
                        label="Tare Weight"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Tare Weight'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <InputNumber
                          style={{ width: '100%' }}
                          min={0}
                          placeholder="Enter Tare Weight"
                          value={
                            siftedMaterials?.[index]?.sift_tare_weight || ''
                          }
                          onChange={(e) =>
                            handleChange(`sift_tare_weight`, index, e)
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={2} span={24} md={24} sm={24}>
                      <FormItem
                        label="UOM"
                        rules={[
                          {
                            required: false,
                            message: 'Enter UOM'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="UOM"
                          value={extraGranules[index]?.material?.uom || ''}
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={4} span={24} md={24} sm={24}>
                      <FormItem
                        label="Net Weight"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Net Weight'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <InputNumber
                          style={{ width: '100%' }}
                          min={0}
                          placeholder="Enter Net Weight"
                          value={
                            siftedMaterials?.[index]?.sift_net_weight || ''
                          }
                          onChange={(e) =>
                            handleChange(`sift_net_weight`, index, e)
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={2} span={24} md={24} sm={24}>
                      <FormItem
                        label="UOM"
                        rules={[
                          {
                            required: false,
                            message: 'Enter UOM'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="UOM"
                          value={extraGranules[index]?.material?.uom || ''}
                          disabled
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={4} span={24} md={24} sm={24}>
                      <FormItem
                        label="Gross Weight"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Gross Weight'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter Gross Weight"
                          value={
                            siftedMaterials?.[index]?.sift_gross_weight || ''
                          }
                          disabled
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={2} span={24} md={24} sm={24}>
                      <FormItem
                        label="UOM"
                        rules={[
                          {
                            required: false,
                            message: 'Enter UOM'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="UOM"
                          value={extraGranules[index]?.material?.uom}
                          disabled
                        />
                      </FormItem>
                    </Col>
                  </Row>
                </Card>
                <Card
                  title={
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontSize: '12px',
                        color: '#c91761'
                      }}
                    >
                      {`Left Over Materials & Rejects`}
                    </span>
                  }
                >
                  <Row gutter={24}>
                    <Col xs={24} xl={4} span={24} md={24} sm={24}>
                      <FormItem
                        label="Container #"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Container #'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter Container ID"
                          value={
                            siftedMaterials?.[index]
                              ?.sift_left_over_container || ''
                          }
                          onChange={(e) => {
                            handleChange(
                              `sift_left_over_container`,
                              index,
                              e.target.value
                            );
                          }}
                        />
                      </FormItem>
                    </Col>

                    <Col xs={24} xl={4} span={24} md={24} sm={24}>
                      <FormItem
                        label="Tare Weight"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Tare Weight'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <InputNumber
                          style={{ width: '100%' }}
                          min={0}
                          placeholder="Enter Tare Weight"
                          value={
                            siftedMaterials?.[index]
                              ?.sift_left_over_tare_weight || ''
                          }
                          onChange={(e) =>
                            handleChange(`sift_left_over_tare_weight`, index, e)
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={2} span={24} md={24} sm={24}>
                      <FormItem
                        label="UOM"
                        rules={[
                          {
                            required: false,
                            message: 'Enter UOM'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="UOM"
                          value={extraGranules[index]?.material?.uom || ''}
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={4} span={24} md={24} sm={24}>
                      <FormItem
                        label="Net Weight"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Net Weight'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <InputNumber
                          style={{ width: '100%' }}
                          min={0}
                          placeholder="Enter Net Weight"
                          value={
                            siftedMaterials?.[index]
                              ?.sift_left_over_net_weight || ''
                          }
                          onChange={(e) =>
                            handleChange(`sift_left_over_net_weight`, index, e)
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={2} span={24} md={24} sm={24}>
                      <FormItem
                        label="UOM"
                        rules={[
                          {
                            required: false,
                            message: 'Enter UOM'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="UOM"
                          value={extraGranules[index]?.material?.uom || ''}
                          disabled
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={4} span={24} md={24} sm={24}>
                      <FormItem
                        label="Gross Weight"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Gross Weight'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter Gross Weight"
                          value={
                            siftedMaterials?.[index]
                              ?.sift_left_over_gross_weight || ''
                          }
                          disabled
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={2} span={24} md={24} sm={24}>
                      <FormItem
                        label="UOM"
                        rules={[
                          {
                            required: false,
                            message: 'Enter UOM'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="UOM"
                          value={extraGranules[index]?.material?.uom}
                          disabled
                        />
                      </FormItem>
                    </Col>
                  </Row>
                </Card>

                <Card
                  title={
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontSize: '12px',
                        color: '#c91761'
                      }}
                    >
                      {`Required Quantity for Blending`}
                    </span>
                  }
                >
                  <Row gutter={24}>
                    <Col xs={24} xl={4} span={24} md={24} sm={24}>
                      <FormItem
                        rules={[
                          {
                            required: false,
                            message: 'Enter Blending'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter Required Quantity"
                          disabled
                          value={blending || ''}
                        />
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={24} xl={4} span={24} md={24} sm={24}>
                      <FormItem
                        label="Container #"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Container #'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter Container ID"
                          value={
                            siftedMaterials?.[index]?.blending_container || ''
                          }
                          onChange={(e) => {
                            handleChange(
                              `blending_container`,
                              index,
                              e.target.value
                            );
                          }}
                        />
                      </FormItem>
                    </Col>

                    <Col xs={24} xl={4} span={24} md={24} sm={24}>
                      <FormItem
                        label="Tare Weight"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Tare Weight'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <InputNumber
                          style={{ width: '100%' }}
                          min={0}
                          placeholder="Enter Tare Weight"
                          value={
                            siftedMaterials?.[index]?.blending_tare_weight || ''
                          }
                          onChange={(e) =>
                            handleChange(`blending_tare_weight`, index, e)
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={2} span={24} md={24} sm={24}>
                      <FormItem
                        label="UOM"
                        rules={[
                          {
                            required: false,
                            message: 'Enter UOM'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="UOM"
                          value={extraGranules[index]?.material?.uom || ''}
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={4} span={24} md={24} sm={24}>
                      <FormItem
                        label="Net Weight"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Net Weight'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <InputNumber
                          style={{ width: '100%' }}
                          min={0}
                          placeholder="Enter Net Weight"
                          value={
                            siftedMaterials?.[index]?.blending_net_weight || ''
                          }
                          onChange={(e) =>
                            handleChange(`blending_net_weight`, index, e)
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={2} span={24} md={24} sm={24}>
                      <FormItem
                        label="UOM"
                        rules={[
                          {
                            required: false,
                            message: 'Enter UOM'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="UOM"
                          value={extraGranules[index]?.material?.uom || ''}
                          disabled
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={4} span={24} md={24} sm={24}>
                      <FormItem
                        label="Gross Weight"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Gross Weight'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter Gross Weight"
                          value={
                            siftedMaterials?.[index]?.blending_gross_weight ||
                            ''
                          }
                          disabled
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={2} span={24} md={24} sm={24}>
                      <FormItem
                        label="UOM"
                        rules={[
                          {
                            required: false,
                            message: 'Enter UOM'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="UOM"
                          value={extraGranules[index]?.material?.uom}
                          disabled
                        />
                      </FormItem>
                    </Col>
                  </Row>
                </Card>

                <Card
                  title={
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontSize: '12px',
                        color: '#c91761'
                      }}
                    >
                      {`Left Over Materials & Rejects`}
                    </span>
                  }
                >
                  <Row gutter={24}>
                    <Col xs={24} xl={4} span={24} md={24} sm={24}>
                      <FormItem
                        label="Container #"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Container #'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter Container ID"
                          value={
                            siftedMaterials?.[index]
                              ?.blending_left_over_container || ''
                          }
                          onChange={(e) => {
                            handleChange(
                              `blending_left_over_container`,
                              index,
                              e.target.value
                            );
                          }}
                        />
                      </FormItem>
                    </Col>

                    <Col xs={24} xl={4} span={24} md={24} sm={24}>
                      <FormItem
                        label="Tare Weight"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Tare Weight'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <InputNumber
                          style={{ width: '100%' }}
                          min={0}
                          placeholder="Enter Tare Weight"
                          value={
                            siftedMaterials?.[index]
                              ?.blending_left_over_tare_weight || ''
                          }
                          onChange={(e) =>
                            handleChange(
                              `blending_left_over_tare_weight`,
                              index,
                              e
                            )
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={2} span={24} md={24} sm={24}>
                      <FormItem
                        label="UOM"
                        rules={[
                          {
                            required: false,
                            message: 'Enter UOM'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="UOM"
                          value={extraGranules[index]?.material?.uom || ''}
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={4} span={24} md={24} sm={24}>
                      <FormItem
                        label="Net Weight"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Net Weight'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <InputNumber
                          style={{ width: '100%' }}
                          min={0}
                          placeholder="Enter Net Weight"
                          value={
                            siftedMaterials?.[index]
                              ?.blending_left_over_net_weight || ''
                          }
                          onChange={(e) =>
                            handleChange(
                              `blending_left_over_net_weight`,
                              index,
                              e
                            )
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={2} span={24} md={24} sm={24}>
                      <FormItem
                        label="UOM"
                        rules={[
                          {
                            required: false,
                            message: 'Enter UOM'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="UOM"
                          value={extraGranules[index]?.material?.uom || ''}
                          disabled
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={4} span={24} md={24} sm={24}>
                      <FormItem
                        label="Gross Weight"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Gross Weight'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter Gross Weight"
                          value={
                            siftedMaterials?.[index]
                              ?.blending_left_over_gross_weight || ''
                          }
                          disabled
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={2} span={24} md={24} sm={24}>
                      <FormItem
                        label="UOM"
                        rules={[
                          {
                            required: false,
                            message: 'Enter UOM'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="UOM"
                          value={extraGranules[index]?.material?.uom}
                          disabled
                        />
                      </FormItem>
                    </Col>
                  </Row>
                </Card>
                {/* <MaterialsCard
                  item={siftedMaterials}
                  index={index}
                  name={`Sifted Materials of ${item?.material?.name}`}
                  type="sift"
                /> */}
                {/* <MaterialsCard
                  item={siftedMaterials[index]}
                  index={index}
                  name="Left Over Materials & Rejects"
                  type="sift_left_over"
                />
                <MaterialsCard
                  item={siftedMaterials[index]}
                  index={index}
                  name="Required Quantity for Blending"
                  type="blending"
                />
                <MaterialsCard
                  item={siftedMaterials[index]}
                  index={index}
                  name="Left Over Materials & Rejects"
                  type="blending_left_over"
                /> */}
              </Card>
            );
          })}
        </Card>
        <br />
        <FormItem className="text-center">
          <CustomButton
            className={styles.inwardButton}
            htmlType="submit"
            type="primary"
          >
            Save & Proceed
          </CustomButton>
        </FormItem>
      </Form>
    </Layout>
  );
};
export default extraGranulation;
