import React, { useState } from 'react';
import { Form, Card, Space, DatePicker } from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import localStyles from './index.module.less';
import CustomTable from 'common/Content/CustomTable';
import CustomButton from '@/common/CustomButton';
import Layout from '@/common/Layout';
import { history } from 'app/history';
import { useGetSTPCleaningListQuery } from '../../redux/apiSlice';
import moment from 'moment';
import Spinner from '../../../../common/Spinner';
const { RangePicker } = DatePicker;

const AdminPage = () => {
  const [form] = Form.useForm();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  let totalRecords;

  const { data, error, isLoading, refetch } = useGetSTPCleaningListQuery({
    page,
    per_page: perPage,
    from_date: fromDate,
    to_date: toDate
  });

  console.log('data ====', data);

  const columns = [
    {
      title: 'Equipment ID',
      dataIndex: 'equip_id',
      key: 'equip_id',
      ellipsis: true,
      // width: '200px',
      render: (_, record) => {
        return record?.solvent_transfer_pump_equipment_id_master?.equipment_id
          ? record.solvent_transfer_pump_equipment_id_master.equipment_id
          : '';
      }
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      ellipsis: true,
      // width: '200px',
      render: (_, record) => {
        return record?.date ? moment(record?.date).format('DD-MMM-YYYY') : '';
      }
    },
    {
      title: 'Dispensed / In-House',
      dataIndex: 'dpb_or_ihpb',
      key: 'dpb_or_ihpb',
      ellipsis: true,
      // width: '200px',
      render: (_, record) => {
        let result = '';
        if (record?.dpb_or_ihpb == 'dpb') {
          result = 'Dispensed';
        } else if (record?.dpb_or_ihpb == 'ihpb') {
          result = 'In-House';
        }
        return result;
      }
    },
    {
      title: 'Product Batch #',
      dataIndex: 'dpb_or_ihpb_number',
      key: 'dpb_or_ihpb_number',
      ellipsis: true
      // width: '200px',
    },
    {
      title: 'Product / Material Name',
      dataIndex: 'name_of_product',
      key: 'name_of_product',
      ellipsis: true
      // width: '200px',
    },
    {
      title: 'Next Cleaning Due',
      dataIndex: 'next_cleaning_due',
      key: 'next_cleaning_due',
      ellipsis: true,
      // width: '200px',
      render: (_, record) => {
        return record?.next_cleaning_due
          ? moment(record?.next_cleaning_due).format('DD-MMM-YYYY')
          : '';
      }
    },
    {
      title: 'Cleaned by (User id & Date)',
      dataIndex: 'cleaned_by',
      key: 'cleaned_by',
      ellipsis: true,
      // width: '200px',
      render: (_, record) => {
        let result = '';
        record?.cleaned_by_user_id ? (result = record.cleaned_by_user_id) : '';
        record?.cleaned_by_user_date
          ? (result += ` (${moment(record?.cleaned_by_user_date).format(
              'DD-MMM-YYYY'
            )})`)
          : '';
        return result;
      }
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
      ellipsis: true
      // width: '200px',
    }
  ];

  let content;
  if (isLoading) {
    console.log('Loading.....');
    content = <Spinner />;
  } else if (data) {
    console.log(data?.pagination?.total_records);
    totalRecords = data?.pagination?.total_records;
    content = (
      <Layout sider={false}>
        <div className={localStyles.heading1}>
          <h1>Overview</h1>
          <h4>Your current requests summary and other activities</h4>
        </div>
        <Form form={form} layout="vertical">
          <Card
            title={<span>Cleaning Record of Solvent Transfer Pump</span>}
            className={`${styles.cardStyles_approver}`}
            extra={
              <Space>
                <RangePicker
                  onChange={(e) => {
                    console.log(e[0]._d);
                    setFromDate(moment(e[0]._d).startOf('day').format());
                    setToDate(moment(e[1]._d).endOf('day').format());
                  }}
                />
                <CustomButton
                  className={styles.inwardButton}
                  onClick={() =>
                    history.push('/log-books-solvent-transfer-pump-create')
                  }
                  type="primary"
                >
                  Create
                </CustomButton>
              </Space>
            }
          >
            <div className={`${styles.table_container}`}>
              <CustomTable
                columns={columns}
                dataSource={data.data}
                pagination={{
                  size: 'small',
                  pageSize: perPage,
                  defaultCurrentPage: page,
                  total: totalRecords,
                  onChange: (page, pageSize) => {
                    setPage(page);
                    setPerPage(pageSize);
                    refetch();
                  },
                  showSizeChanger: true
                }}
              />
            </div>
          </Card>
          <br />
          <FormItem className="text-center">
            <CustomButton
              className={styles.inwardButton}
              onClick={() => history.back()}
              type="primary"
            >
              Back to Home
            </CustomButton>
          </FormItem>
        </Form>
      </Layout>
    );
  } else if (error) {
    console.log(error);
    content = 'Something went wrong!';
  }

  return <>{content}</>;
};
export default AdminPage;
