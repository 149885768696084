import React, { useState, useEffect } from 'react';
import Layout from '@/common/Layout';
import {
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Space,
  Tag,
  InputNumber,
  message
} from 'antd';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMaterialslist } from '@/features/stockCard/redux/slice';
import {
  getBatchDetails,
  createCodetoCodetransfer,
  getTransferDataById,
  approveTransferedData,
  getTypedMaterials
} from '../../redux/slice';
import { getLoggedInUser } from 'utilities/helpers';
import moment from 'moment';
import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import { useLocation } from 'react-router-dom';
import { history } from 'app/history';

const { TextArea } = Input;
const dateFormat = 'YYYY-MM-DD';

const CreateCodetoCode = ({ loading }) => {
  const [form] = Form.useForm();
  const searchParam = useLocation().search;
  const searchParams = new URLSearchParams(searchParam);

  const { allMaterials } = useSelector((state) => state.stocks);

  const dispatch = useDispatch();

  const [search, setSearch] = useState('');
  const [batches, setBatches] = useState([]);
  const [batcheDetails, setBatcheDetails] = useState([]);
  const [containers, setContainers] = useState([]);
  const [conatinerValues, setConatinerValues] = useState([]);
  const [materialDetails, setMaterialDetails] = useState([]);
  const [transferMaterial, setTransferMaterial] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [allMaterialslist, setAllMaterialslist] = useState([]);
  const [transferData, setTransferData] = useState({});
  const [isView, setIsView] = useState(false);
  const [comments, setComments] = useState('');
  const [materialCodes, setMaterialCodes] = useState([]);

  const { user } = getLoggedInUser();

  useEffect(() => {
    searchParams.get('id') ? handleGetTransferdataById() : '';
    handleGetAllMaterialsList();
  }, [search, isView]);

  console.log(transferData);
  const handleGetAllMaterialsList = () => {
    let payload = { search };
    dispatch(getAllMaterialslist(payload)).then((response) => {
      if (response?.payload?.success) {
        setAllMaterialslist(response?.payload?.data);
      }
    });
  };

  const handleGetTransferdataById = () => {
    setIsView(true);
    let payload = { id: searchParams.get('id') };
    dispatch(getTransferDataById(payload)).then((response) => {
      if (response?.payload?.success) {
        setTransferData(response?.payload?.data);
        let result = response?.payload?.data?.transfer_containers.map(
          ({ container }) => container?.number
        );
        console.log(result);
        setConatinerValues(result);
        form.setFieldsValue({
          request: response?.payload?.data?.request,
          req_date: moment(response?.payload?.data?.req_date, dateFormat),
          material_id: response?.payload?.data?.batch_details?.material?.code,
          batch_details_id:
            response?.payload?.data?.batch_details?.inhouse_batch_number,
          qty_transfer: response?.payload?.data?.qty_transfer,
          reason: response?.payload?.data?.reason,
          grade: response?.payload?.data?.grade,
          vendor_coa: response?.payload?.data?.vendor_coa,
          transfer_material_id: response?.payload?.data?.materials?.code,
          trf_serial_number:
            response?.payload?.data?.batch_details?.trf_serial_no,
          test_to_be_performed:
            response?.payload?.data?.batch_details?.test_to_be_performed
        });
      }
    });
  };
  const onSearch = (value) => {
    setSearch(value);
  };

  const onFinish = (values) => {
    values['wh_containers'] = conatinerValues;
    values['count'] = conatinerValues.length;
    values['prepared_by'] = `${user?.employee}(${moment().format(
      'DD MMM YYYY'
    )})`;
    dispatch(createCodetoCodetransfer(values)).then((response) => {
      if (response?.payload?.success) {
        setIsModal(false);
        setIsView(true);
        // setTransferData(response?.payload?.data);
        history.push(`/create-code-to-code?id=${response?.payload?.data?.id}`);
      }
    });
  };

  const onChange = (value, type) => {
    console.log(value, type);
    const material = allMaterials.filter(
      (MaterialsList) => MaterialsList.id === value
    );
    console.log(material);
    setMaterialDetails(material);
    let payload = { search: material[0]?.code };
    dispatch(getAllMaterialslist(payload)).then((response) => {
      if (response?.payload?.success) {
        setBatches(response?.payload?.data[0]?.batch_details);
      }
    });
    let data = { type: material[0]?.item };
    dispatch(getTypedMaterials(data)).then((response) => {
      if (response?.payload?.success) {
        setMaterialCodes(response?.payload?.data);
      }
    });
  };
  const onChangeBatch = (value) => {
    let payload = { batch_details_id: value };
    setBatcheDetails(batches.filter((batches) => batches.id === value));
    dispatch(getBatchDetails(payload)).then((response) => {
      if (response?.payload?.success) {
        setContainers(response?.payload?.data);
      }
    });
  };

  const onChangeContainer = (e) => {
    setConatinerValues(e);
  };

  const handleTransferMaterial = (value) => {
    const material = allMaterials.filter(
      (MaterialsList) => MaterialsList.id === value
    );
    setTransferMaterial(material);
  };

  const handleClose = () => {
    setIsModal(false);
  };

  const handleComments = (e) => {
    setComments(e.target.value);
  };

  const handleApprove = (status) => {
    const payload = {
      id: searchParams.get('id'),
      comments: comments,
      status: status
    };
    dispatch(approveTransferedData(payload)).then((response) => {
      if (response?.payload?.success) {
        message.success(response?.payload?.message);
        history.push('code-to-code-list');
      }
    });
  };
  return (
    <Layout sider={false}>
      <Card
        title="Code to Code Transfer Request"
        className={`${styles.cardStyles_approver}`}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={styles.panelborder}
        >
          <Row gutter={24}>
            <Col span={12}>
              <FormItem
                label="Request #"
                name="request"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Request #" disabled={isView} />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Date of Request"
                name="req_date"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <DatePicker
                  placeholder="Select Date"
                  style={{ width: '100%' }}
                  disabled={isView}
                />
              </FormItem>
            </Col>
          </Row>
          <Space direction="vertical">
            <Card
              title="Available Material Code Details"
              className={`${styles.cardStyles_approver}`}
            >
              <Row gutter={24}>
                <Col span={8}>
                  <FormItem
                    label="Material Code"
                    name="material_id"
                    rules={[{ required: true }]}
                    className={styles.customLable}
                  >
                    <Select
                      showSearch
                      placeholder="Select a material code"
                      optionFilterProp="children"
                      onChange={(e) => onChange(e, 'id')}
                      onSearch={onSearch}
                      disabled={isView}
                    >
                      {allMaterialslist ? (
                        allMaterialslist?.map((item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {item.code}
                            </option>
                          );
                        })
                      ) : (
                        <Spin />
                      )}
                    </Select>
                  </FormItem>
                </Col>
                <Col xs={24} xl={8} span={12} md={24} sm={24}>
                  <FormItem
                    label="In House Batch #"
                    name="batch_details_id"
                    rules={[
                      {
                        required: true,
                        message: 'Select In House Batch Number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    {isView ? (
                      <Input placeholder="Enter Inhouse BatchId" disabled />
                    ) : (
                      <Select
                        showSearch
                        placeholder="Select In House Batch Number"
                        optionFilterProp="children"
                        onChange={onChangeBatch}
                        // onSearch={onSearchBatch}
                      >
                        {batches?.map((item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {item.inhouse_batch_number}
                            </option>
                          );
                        })}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label="MRN #"
                    rules={[{ required: false }]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Enter MRN #"
                      value={
                        transferData?.batch_details?.mrn_number ||
                        batcheDetails?.[0]?.mrn_number ||
                        'NA'
                      }
                      disabled
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label="Material Name"
                    rules={[{ required: false }]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Enter MRN #"
                      value={
                        transferData?.batch_details?.material?.name ||
                        materialDetails?.[0]?.name ||
                        'NA'
                      }
                      disabled
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label="Manufacturer name"
                    rules={[{ required: false }]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Enter MRN #"
                      value={
                        transferData?.batch_details?.material?.suppliers?.[0]
                          ?.manufacturer_name ||
                        materialDetails?.[0]?.suppliers?.[0]
                          ?.manufacturer_name ||
                        'NA'
                      }
                      disabled
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label="Supplier name"
                    rules={[{ required: false }]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Enter Supplier Name"
                      value={
                        transferData?.batch_details?.material?.suppliers?.[0]
                          ?.name ||
                        materialDetails?.[0]?.suppliers?.[0]?.name ||
                        'NA'
                      }
                      disabled
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label="Vendor Batch/Lot"
                    rules={[{ required: false }]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Enter Batch/Lot"
                      value={
                        transferData?.batch_details?.mfg_batch_lot_no ||
                        batcheDetails?.[0]?.mfg_batch_lot_no ||
                        'NA'
                      }
                      disabled
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label="Manufacturer Date"
                    rules={[{ required: false }]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Enter Batch/Lot"
                      value={
                        transferData?.batch_details?.mfg_date ||
                        batcheDetails?.[0]?.mfg_date ||
                        'NA'
                      }
                      disabled
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label="Expiry Date"
                    rules={[{ required: false }]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Enter Expiry Date"
                      value={
                        transferData?.batch_details?.mfg_expiry_date ||
                        batcheDetails?.[0]?.mfg_expiry_date ||
                        'NA'
                      }
                      disabled
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label="Mfg Retest Date"
                    rules={[{ required: false }]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Enter Expiry Date"
                      value={
                        transferData?.batch_details?.mfg_retest_date ||
                        batcheDetails?.[0]?.mfg_retest_date ||
                        'NA'
                      }
                      disabled
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label="UOM"
                    rules={[{ required: false }]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Enter UOM"
                      value={
                        transferData?.batch_details?.material?.uom ||
                        batcheDetails?.[0]?.uom ||
                        'NA'
                      }
                      disabled
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label="Available Qty"
                    rules={[{ required: false }]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Enter Qty"
                      value={
                        transferData?.batch_details?.stock_card_issuances?.[0]
                          ?.closing_balance ||
                        batcheDetails?.[0]?.stock_card_issuances?.[0]
                          ?.closing_balance ||
                        'NA'
                      }
                      disabled
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label="Current Material Status"
                    rules={[{ required: false }]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Qty"
                      value={
                        transferData?.batch_details?.status ||
                        batcheDetails?.[0]?.status ||
                        'NA'
                      }
                      disabled
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label="Approved Material AR #"
                    rules={[{ required: false }]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Qty"
                      value={
                        transferData?.batch_details?.ar_number ||
                        batcheDetails?.[0]?.ar_number ||
                        'NA'
                      }
                      disabled
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label="In House Retest Date"
                    rules={[{ required: false }]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Qty"
                      value={
                        transferData?.batch_details?.inhouse_retest_date ||
                        batcheDetails?.[0]?.inhouse_retest_date ||
                        'NA'
                      }
                      disabled
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label="WH Containers to be transfered"
                    rules={[{ required: false }]}
                    className={styles.customLable}
                  >
                    <Select
                      showSearch
                      placeholder="Select Containers"
                      optionFilterProp="children"
                      onChange={(e) => onChangeContainer(e)}
                      mode="multiple"
                      disabled={isView}
                      // onSearch={onSearchBatch}
                    >
                      {containers?.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.number}
                          </option>
                        );
                      })}
                    </Select>
                    {isView ? (
                      <p style={{ marginTop: '8px' }}>
                        {conatinerValues?.map((item, index) => {
                          return (
                            <Tag color="#c91761" key={index}>
                              {item}
                            </Tag>
                          );
                        })}
                      </p>
                    ) : (
                      ''
                    )}
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label="Qty to be Transfered"
                    name="qty_transfer"
                    rules={[{ required: false }]}
                    className={styles.customLable}
                  >
                    <InputNumber
                      min={1}
                      max={
                        batcheDetails?.[0]?.stock_card_issuances?.[0]
                          ?.closing_balance
                      }
                      style={{ width: '100%' }}
                      placeholder="Qty"
                      disabled={isView}
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label="Reason for code transfer"
                    name="reason"
                    rules={[{ required: false }]}
                    className={styles.customLable}
                  >
                    <Input placeholder="Reason" disabled={isView} />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label="TRF Serial #"
                    name="trf_serial_number"
                    rules={[{ required: false }]}
                    className={styles.customLable}
                  >
                    <Input placeholder="TRF Serial Number" disabled={isView} />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label="Test to be Performed"
                    name="test_to_be_performed"
                    rules={[{ required: false }]}
                    className={styles.customLable}
                  >
                    <Select
                      placeholder="Select Test to be performed"
                      disabled={isView}
                    >
                      <option value="Identification Test">
                        Identification Test
                      </option>
                      <option value="Complete Analysis">
                        Complete Analysis
                      </option>
                      {/* <option value="R&D Identification Test">
                        R&D Identification Test
                      </option> */}
                    </Select>
                  </FormItem>
                </Col>
              </Row>
            </Card>
            <Card
              title="To Be Transferred Material Code Details"
              className={`${styles.cardStyles_approver}`}
            >
              <Row gutter={24}>
                <Col span={8}>
                  <FormItem
                    label="Material Code"
                    name="transfer_material_id"
                    rules={[{ required: true }]}
                    className={styles.customLable}
                  >
                    <Select
                      showSearch
                      placeholder="Select a material code"
                      optionFilterProp="children"
                      onChange={(e) => handleTransferMaterial(e, 'id')}
                      onSearch={onSearch}
                      disabled={isView}
                    >
                      {materialCodes ? (
                        materialCodes?.map((item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {item.code}
                            </option>
                          );
                        })
                      ) : (
                        <Spin />
                      )}
                    </Select>
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label="Material Name"
                    rules={[{ required: true }]}
                    className={styles.customLable}
                  >
                    <Input
                      disabled
                      value={
                        transferMaterial?.[0]?.name ||
                        transferData?.materials?.name
                      }
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label="Grade"
                    name="grade"
                    rules={[{ required: true }]}
                    className={styles.customLable}
                  >
                    <Input placeholder="Enter Grade" disabled={isView} />
                  </FormItem>
                </Col>
                {/* <Col span={8}>
                  <FormItem
                    label="Vendor COA as per required grade"
                    name="vendor_coa"
                    rules={[{ required: true }]}
                    className={styles.customLable}
                  >
                    <Select placeholder="Select a COA" disabled={isView}>
                      <option value={'available'}>Available</option>
                      <option value={'not_available'}>Not Available</option>
                    </Select>
                  </FormItem>
                </Col> */}
                <Col span={8}>
                  <FormItem
                    label="Prepared By"
                    rules={[{ required: true }]}
                    className={styles.customLable}
                  >
                    <Input
                      disabled
                      value={
                        transferData?.prepared_by ||
                        `${user?.employee}(${moment().format('DD MMM YYYY')})`
                      }
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label="Verified By"
                    rules={[{ required: true }]}
                    className={styles.customLable}
                    disabled={isView}
                  >
                    <Input disabled value={transferData?.verified_by} />
                  </FormItem>
                </Col>
              </Row>
              {user?.role !== 'qc_initiator' ? (
                <FormItem className="text-center">
                  <CustomButton
                    type="primary"
                    loading={loading}
                    className={styles.border_button}
                    ghost
                    onClick={() => setIsModal(true)}
                    disabled={isView}
                  >
                    Initiate Verification
                  </CustomButton>
                </FormItem>
              ) : (
                ''
              )}
            </Card>
            {transferData?.status === 'Approved' ? (
              <Row gutter={24}>
                <Col span={24}>
                  <FormItem
                    label="Comments"
                    // name="comments"
                    rules={[{ required: false }]}
                    className={styles.customLable}
                  >
                    <TextArea
                      placeholder="Enter Comments"
                      value={transferData?.qa_comments || ''}
                      disabled
                    />
                  </FormItem>
                </Col>
              </Row>
            ) : user?.role === 'qc_initiator' &&
              transferData?.status !== 'Approved' ? (
              <Row gutter={24}>
                <Col span={24}>
                  <FormItem
                    label="Comments"
                    // name="comments"
                    rules={[{ required: false }]}
                    className={styles.customLable}
                  >
                    <TextArea
                      placeholder="Enter Comments"
                      onChange={(e) => handleComments(e)}
                    />
                  </FormItem>
                </Col>
              </Row>
            ) : (
              ''
            )}
            {user?.role !== 'qc_initiator' &&
            transferData?.status === 'Approved' ? (
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem
                    label="Assigned new MRN # after Approval"
                    // name="comments"
                    rules={[{ required: false }]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="New MRN number"
                      disabled
                      value={transferData?.code_batch?.mrn_number}
                    />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="Assigned new In House Batch # after Approval"
                    rules={[{ required: false }]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Assigned new InHouseBatch #"
                      disabled
                      value={transferData?.code_batch?.inhouse_batch_number}
                    />
                  </FormItem>
                </Col>
              </Row>
            ) : (
              ''
            )}
            <FormItem className="text-center">
              <Space>
                {' '}
                <CustomButton
                  type="primary"
                  loading={loading}
                  className={styles.border_button}
                  ghost
                  onClick={() => history.push('code-to-code-list')}
                >
                  Back
                </CustomButton>{' '}
                {user?.role === 'qc_initiator' &&
                transferData?.status !== 'Approved' ? (
                  <CustomButton
                    type="success"
                    loading={loading}
                    className={styles.border_button}
                    style={{
                      backgroundColor: '#28a745',
                      borderColor: '#28a745',
                      color: '#fff'
                    }}
                    onClick={() => handleApprove('Approved')}
                  >
                    Approve
                  </CustomButton>
                ) : user?.role !== 'qc_initiator' &&
                  transferData?.status === 'Approved' ? (
                  <CustomButton
                    type="primary"
                    loading={loading}
                    className={styles.border_button}
                    onClick={() =>
                      window.open(
                        `${transferData?.code_batch?.pdf_doc}`,
                        '_blank'
                      )
                    }
                    disabled={!isView}
                  >
                    Print Labels
                  </CustomButton>
                ) : user?.role !== 'qc_initiator' ? (
                  <CustomButton
                    type="primary"
                    loading={loading}
                    className={styles.border_button}
                    onClick={() => history.push(`code-to-code-list`)}
                    disabled={!isView}
                  >
                    Proceed for QA Approval
                  </CustomButton>
                ) : (
                  ''
                )}
              </Space>
            </FormItem>
          </Space>
          {isModal ? (
            <CustomModal
              title="Verification"
              visible={isModal}
              width="50%"
              footer={null}
              className={styles.customModal}
              closable={false}
            >
              <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={24}>
                  <Col span={12}>
                    <FormItem
                      label="User ID"
                      name="employee_id"
                      rules={[
                        {
                          required: true,
                          message: 'Enter User ID'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input placeholder="Enter User ID" />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      label="Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Password'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input.Password placeholder="Enter Password" />
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <FormItem
                      label="Comments"
                      name="comments"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Comments'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <TextArea placeholder="Enter Comments" />
                    </FormItem>
                  </Col>
                </Row>
                <FormItem className="text-center">
                  <Space>
                    <CustomButton
                      className={styles.inwardButton}
                      onClick={handleClose}
                      type="primary"
                      size="small"
                      ghost
                    >
                      Close
                    </CustomButton>
                    <CustomButton
                      className={styles.inwardButton}
                      htmlType="submit"
                      type="primary"
                      size="small"
                    >
                      Verify
                    </CustomButton>
                  </Space>
                </FormItem>
              </Form>
            </CustomModal>
          ) : (
            ''
          )}
        </Form>
      </Card>
    </Layout>
  );
};
export default CreateCodetoCode;
