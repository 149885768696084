import React, { useState, useEffect } from 'react';
import Layout from '@/common/Layout';
import { Card, Col, DatePicker, Form, Input, Row, Space, message } from 'antd';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import CustomButton from '@/common/CustomButton';
import moment from 'moment';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';

const CreateIPAPreperationLog = () => {
  const [form] = Form.useForm();
  const [isSaved, setIsSaved] = useState(false);
  const [currentTime, setCurrentTime] = useState(
    moment().format('YYYY-MM-DD HH:mm')
  );
  const [products, setProducts] = useState([
    { inputA: '', inputB: '', inputC: '', sum: '', Add: '' }
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format('YYYY-MM-DD HH:mm'));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleAdd = () => {
    const lastProduct = products[products.length - 1];
    setProducts((prevProducts) => [
      ...prevProducts,
      {
        inputA: lastProduct.sum || '',
        inputB: '',
        inputC: '',
        sum: lastProduct.sum || '',
        Add: ''
      }
    ]);
  };

  const handleRemove = (index) => {
    setProducts((prevProducts) => prevProducts.filter((_, i) => i !== index));
  };

  const handleInputChange = (index, key, value) => {
    const updatedProducts = [...products];
    updatedProducts[index][key] = value;

    const inputA = parseFloat(updatedProducts[index].inputA) || 0;
    const inputB = parseFloat(updatedProducts[index].inputB) || 0;
    const inputC = parseFloat(updatedProducts[index].inputC) || 0;

    updatedProducts[index].sum = (inputA - inputB).toFixed(2);
    updatedProducts[index].Add = (inputB + inputC).toFixed(2);

    setProducts(updatedProducts);
  };

  const onFinish = (values) => {
    values['products'] = products; // Add products to form values if needed
    setIsSaved(true);
    message.success('Form saved successfully!');

    // Handle form submission here
  };

  return (
    <Layout sider={false}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        className={styles.panelborder}
      >
        <Card title="70% IPA Preparation Log" className={styles.CustomPanel}>
          <Row gutter={24}>
            <Col xs={24} xl={24} span={24} md={24} sm={24}>
              <Card>
                <Row gutter={16} style={{ marginBottom: '16px' }}>
                  <Col span={8}>
                    <Form.Item
                      label="Date of Dispensing"
                      name="dateOfDispensing"
                    >
                      <DatePicker
                        placeholder="Select date"
                        style={{ width: '100%' }}
                        disabled={isSaved}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Dispensed Quantity (kg)"
                      name="dispensedQuantity"
                    >
                      <Input
                        placeholder="Enter Dispensed Quantity"
                        disabled={isSaved}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Quantity (in Liters) (A)" name="inputA">
                      <Input
                        placeholder="Enter Quantity"
                        disabled={isSaved}
                        onChange={(e) =>
                          handleInputChange(0, 'inputA', e.target.value)
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Col
                  span={24}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '16px'
                  }}
                >
                  <CustomButton
                    className={styles.inwardButton}
                    htmlType="submit"
                    type="primary"
                    style={{ borderRadius: '5px' }}
                  >
                    Save
                  </CustomButton>
                </Col>
              </Card>
            </Col>
          </Row>
          <br />
          {products.map((product, index) => (
            <Card
              key={index}
              title={`100% IPA Consumption Details ${index + 1}`}
              extra={
                index === 0 ? (
                  <PlusCircleOutlined onClick={handleAdd} />
                ) : (
                  <DeleteOutlined onClick={() => handleRemove(index)} />
                )
              }
            >
              <Row gutter={24}>
                <Col xs={24} xl={8} span={12} md={24} sm={24}>
                  <Form.Item label="Date & Time">
                    <Input value={currentTime} disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={8} span={12} md={24} sm={24}>
                  <Form.Item label="Quantity Used (B)">
                    <Input
                      placeholder="Enter Quantity Used"
                      value={product.inputB}
                      onChange={(e) =>
                        handleInputChange(index, 'inputB', e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={8} span={12} md={24} sm={24}>
                  <Form.Item label="Balance Qty in Liters (A-B)">
                    <Input disabled value={product.sum} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <h1
                    style={{
                      textAlign: 'start',
                      marginBottom: '25px',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      color: '#c91761'
                    }}
                  >
                    70% IPA Preparation Details
                  </h1>
                </Col>
                <Col xs={24} xl={8} span={12} md={24} sm={24}>
                  <Form.Item label="Purified Waters in Liters (C)">
                    <Input
                      placeholder="Enter Purified Waters"
                      value={product.inputC}
                      onChange={(e) =>
                        handleInputChange(index, 'inputC', e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={8} span={12} md={24} sm={24}>
                  <Form.Item label="Total Prepared Qty (B+C)">
                    <Input disabled value={product.Add} />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={8} span={12} md={24} sm={24}>
                  <Form.Item label="Prepared By (User id & Date)">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={8} span={12} md={24} sm={24}>
                  <Form.Item label="Verified By (User id & Date)">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={16} span={12} md={24} sm={24}>
                  <Form.Item label="Remarks">
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          ))}

          <FormItem className="text-center">
            <Space style={{ marginTop: '15px' }}>
              <CustomButton
                className={styles.inwardButton}
                style={{ borderRadius: '5px' }}
              >
                Back
              </CustomButton>
              <CustomButton
                className={styles.inwardButton}
                htmlType="submit"
                type="primary"
                style={{ borderRadius: '5px' }}
              >
                Initiate Verification
              </CustomButton>
            </Space>
          </FormItem>
        </Card>
      </Form>
    </Layout>
  );
};

export default CreateIPAPreperationLog;
