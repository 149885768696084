import React from 'react';
import Layout from '@/common/Layout';
import FinanceUsersList from './components/FinanceUsersList';

const FinanceUser = () => {
  return (
    <>
      <Layout sider={false}>
        <FinanceUsersList />
      </Layout>
    </>
  );
};

export default FinanceUser;
