import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  get_AllStandardEnvelopes,
  stats,
  get_AllCustomEnvelopes,
  price,
  categories,
  subcategories,
  standardDoa,
  projects,
  businessunits
} from './api';
import LocalStorage from 'utilities/localStorage';

const initialState = {
  loading: false,
  allStats: [],
  getAllApproverLoading: false,
  allStandardEnvelopes: [],
  allCustomEnvelopes: [],
  subcategories: [],
  pricebrackets: [],
  categories: [],
  doa: {},
  projects: [],
  bu: []
};

const actions = {
  GET_ALL_STATS: 'getAllStats/GET_ALL_STATS',
  GET_ALL_STANDARD_ENVELOPES:
    'getAllStandardEnvelopes/GET_ALL_STANDARD_ENVELOPES',
  GET_ALL_CUSTOM_ENVELOPES: 'getAllCustomEnvelopes/GET_ALL_CUSTOM_ENVELOPES',
  GET_ALL_SUB_CATEGORIES: 'getAllSubCategories/GET_ALL_SUB_CATEGORIES',
  GET_ALL_PRICE_BRACKETS: 'getAllPricebrackets/GET_ALL_PRICE_BRACKETS',
  GET_ALL_CATEGORIES: 'getAllCategories/GET_ALL_CATEGORIES',
  GET_DOA: 'getdoa/GET_DOA',
  GET_ALL_PROJECTS: 'getAllProjects/GET_ALL_PROJECTS',
  GET_ALL_BUS: 'getAllBUs/GET_ALL_BUS'
};

export const getAllStats = createAsyncThunk(
  actions.GET_ALL_STATS,
  async (payload) => {
    const user = LocalStorage.getItem('NFA_user');
    const response = await stats(
      `count?user_id=${user.user_details.id}`,
      payload
    );
    return response;
  }
);

export const getAllStandardEnvelopes = createAsyncThunk(
  actions.GET_ALL_STANDARD_ENVELOPES,
  async (payload) => {
    const response = await get_AllStandardEnvelopes('all-standard', payload);
    return response;
  }
);

export const getAllCustomEnvelopes = createAsyncThunk(
  actions.GET_ALL_CUSTOM_ENVELOPES,
  async (payload) => {
    const response = await get_AllCustomEnvelopes('all-custom', payload);
    return response;
  }
);

export const getAllSubCategories = createAsyncThunk(
  actions.GET_ALL_SUB_CATEGORIES,
  async (payload) => {
    const response = await subcategories('all', payload);
    return response;
  }
);

export const getAllPricebrackets = createAsyncThunk(
  actions.GET_ALL_PRICE_BRACKETS,
  async (payload) => {
    const response = await price('all', payload);
    return response;
  }
);

export const getAllCategories = createAsyncThunk(
  actions.GET_ALL_CATEGORIES,
  async (payload) => {
    const response = await categories('all', payload);
    return response;
  }
);

export const getdoa = createAsyncThunk(actions.GET_DOA, async (payload) => {
  const response = await standardDoa('find-doa', payload);
  return response;
});

export const getAllProjects = createAsyncThunk(
  actions.GET_ALL_PROJECTS,
  async (payload) => {
    let search = { search: payload };
    const response = await projects('all-projects', search);
    return response;
  }
);

export const getAllBUs = createAsyncThunk(
  actions.GET_ALL_BUS,
  async (payload) => {
    let search = { search: payload };
    const response = await businessunits('all', search);
    return response;
  }
);

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllStats.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllStats.fulfilled, (state, action) => {
        const { success, data, message: msg, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.allStats = data;
        state.pagination = pagination || null;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllStats.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllStandardEnvelopes.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllStandardEnvelopes.fulfilled, (state, action) => {
        const { success, data } = action.payload;
        state.loading = false;
        state.allStandardEnvelopes = data;
        if (!success) {
          /* message.error(msg); */
        }
      })
      .addCase(getAllStandardEnvelopes.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllCustomEnvelopes.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCustomEnvelopes.fulfilled, (state, action) => {
        const { success, data } = action.payload;
        state.loading = false;
        state.allCustomEnvelopes = data;
        if (success) {
          /* message.success(msg); */
        } else {
          /* message.error(msg); */
        }
      })
      .addCase(getAllCustomEnvelopes.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllSubCategories.pending, (state) => {
        state.getAllEnvelopLoading = true;
      })
      .addCase(getAllSubCategories.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllEnvelopLoading = false;
        state.subcategories = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllSubCategories.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEnvelopLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllPricebrackets.pending, (state) => {
        state.getAllEnvelopLoading = true;
      })
      .addCase(getAllPricebrackets.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllEnvelopLoading = false;
        state.pricebrackets = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllPricebrackets.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEnvelopLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllCategories.pending, (state) => {
        state.getAllEnvelopLoading = true;
      })
      .addCase(getAllCategories.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllEnvelopLoading = false;
        state.categories = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllCategories.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEnvelopLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getdoa.pending, (state) => {
        state.getAllEnvelopLoading = true;
      })
      .addCase(getdoa.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.getAllEnvelopLoading = false;
        state.doa = data ? data : '';
      })
      .addCase(getdoa.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEnvelopLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllProjects.pending, (state) => {
        state.getAllEnvelopLoading = true;
      })
      .addCase(getAllProjects.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;
        state.getAllEnvelopLoading = false;
        state.projects = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          /*  message.error(msg); */
        }
      })
      .addCase(getAllProjects.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEnvelopLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllBUs.pending, (state) => {
        state.getAllEnvelopLoading = true;
      })
      .addCase(getAllBUs.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;
        state.getAllEnvelopLoading = false;
        state.bu = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          /*  message.error(msg); */
        }
      })
      .addCase(getAllBUs.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEnvelopLoading = false;

        message.error(msg);
      });
  }
});
export default dashboardSlice.reducer;
