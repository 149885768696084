import React, { useState, useEffect } from 'react';
import Layout from '@/common/Layout';
import {
  Row,
  Col,
  Form,
  Card,
  // Select,
  Input,
  DatePicker,
  Space,
  message,
  Image,
  Spin,
  Select
} from 'antd';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import {
  createinvoice,
  getAllfgDetails,
  getAllProdDescs,
  getAllConsignees
} from '../../redux/slice';
import { useDispatch } from 'react-redux';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import truck from '@/features/generateMrn/components/images/truck-delivery-done.gif';
// import { PlusCircleFilled, PlusCircleOutlined } from '@ant-design/icons';

// const { TextArea } = Input;

const CreateInvoice = ({ loading }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  // const { batchlogs } = useSelector((state) => state.logs);

  const [truckShow, setTruckShow] = useState(false);
  const [alllFgDetails, setAlllFgDetails] = useState([]);
  const [search, setSearch] = useState();
  const [alllPrdDsc, setAlllPrdDsc] = useState([]);
  const [allConsignees, setAllConsignees] = useState([]);

  useEffect(() => {
    handleGetAllFGDetails();
    handleGetAllProdDesc();
    handleGetAllConsignee();
  }, [search]);

  const handleGetAllFGDetails = () => {
    const payload = search ? { search } : '';
    dispatch(getAllfgDetails(payload)).then((response) => {
      if (response?.payload?.success) {
        setAlllFgDetails(response?.payload?.data);
      }
    });
  };

  const handleGetAllProdDesc = () => {
    const payload = search ? { search } : '';
    dispatch(getAllProdDescs(payload)).then((response) => {
      if (response?.payload?.success) {
        setAlllPrdDsc(response?.payload?.data);
      }
    });
  };

  const handleGetAllConsignee = () => {
    const payload = search ? { search } : '';
    dispatch(getAllConsignees(payload)).then((response) => {
      if (response?.payload?.success) {
        setAllConsignees(response?.payload?.data);
      }
    });
  };

  const onSearch = (value) => {
    setSearch(value);
  };

  const onFinish = (values) => {
    setTruckShow(true);

    dispatch(createinvoice(values)).then((response) => {
      if (response?.payload?.success) {
        setTruckShow(false);
        message.success(response?.payload?.message);
        history.push('/invoice-details-list');
      }
    });
  };

  return (
    <Layout sider={false}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
      >
        <Card title="INVOICE DETAILS" className={styles.CustomPanel}>
          <Row gutter={24}>
            <Col xs={24} xl={6} span={6} md={24} sm={24}>
              <FormItem
                label="Invoice #"
                name="inv_num"
                rules={[
                  {
                    required: true,
                    message: 'Select Invoice Number'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Invoice #" />
              </FormItem>
            </Col>
            <Col xs={24} xl={6} span={6} md={24} sm={24}>
              <FormItem
                label="Invoice Date"
                name="inv_date"
                rules={[
                  {
                    required: true,
                    message: 'Select Invoice Date'
                  }
                ]}
                className={styles.customLable}
              >
                <DatePicker
                  placeholder="Select Invoice Date"
                  style={{ width: '100%' }}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={6} span={6} md={24} sm={24}>
              <FormItem
                label="PO #"
                name="po_num"
                rules={[
                  {
                    required: true,
                    message: 'Select PO Number'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter PO #" />
              </FormItem>
            </Col>
            <Col xs={24} xl={6} span={6} md={24} sm={24}>
              <FormItem
                label="PO Date"
                name="po_date"
                rules={[
                  {
                    required: true,
                    message: 'Select PO Date'
                  }
                ]}
                className={styles.customLable}
              >
                <DatePicker
                  placeholder="Select PO Date"
                  style={{ width: '100%' }}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={6} span={6} md={24} sm={24}>
              <FormItem
                label="Product Description"
                name="product_desc"
                rules={[
                  {
                    required: true,
                    message: 'Enter Product Description'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  showSearch
                  placeholder="Select Product Description"
                  optionFilterProp="children"
                  // onChange={(e) => onChange(e, 'id')}
                  onSearch={onSearch}
                >
                  {alllPrdDsc ? (
                    alllPrdDsc?.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.product_desc_name}
                        </option>
                      );
                    })
                  ) : (
                    <Spin />
                  )}
                </Select>
                {/* <Input placeholder="Enter Product Description" /> */}
              </FormItem>
            </Col>
            <Col xs={24} xl={6} span={6} md={24} sm={24}>
              <FormItem
                label="Product Batch #"
                name="product_batch"
                rules={[
                  {
                    required: true,
                    message: 'Enter Product Batch'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  showSearch
                  placeholder="Select Product Batch"
                  optionFilterProp="children"
                  // onChange={(e) => onChange(e, 'id')}
                  onSearch={onSearch}
                >
                  {alllFgDetails ? (
                    alllFgDetails?.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.packing_fg_batch}
                        </option>
                      );
                    })
                  ) : (
                    <Spin />
                  )}
                </Select>
                {/* <Input placeholder="Enter Product Batch" /> */}
              </FormItem>
            </Col>
            <Col xs={24} xl={6} span={6} md={24} sm={24}>
              <FormItem
                label="Manufacturing Date"
                name="mfg_date"
                rules={[
                  {
                    required: true,
                    message: 'Select Manufacturing Date'
                  }
                ]}
                className={styles.customLable}
              >
                <DatePicker
                  placeholder="Select Manufactiring Date"
                  style={{ width: '100%' }}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={6} span={6} md={24} sm={24}>
              <FormItem
                label="Expiry Date"
                name="expy_date"
                rules={[
                  {
                    required: true,
                    message: 'Select Expiry Date'
                  }
                ]}
                className={styles.customLable}
              >
                <DatePicker
                  placeholder="Select Expiry Date"
                  style={{ width: '100%' }}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={6} span={6} md={24} sm={24}>
              <FormItem
                label="Consignee"
                name="consignee"
                rules={[
                  {
                    required: true,
                    message: 'Select Consignee'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  showSearch
                  placeholder="Select Consignee"
                  optionFilterProp="children"
                  // onChange={(e) => onChange(e, 'id')}
                  onSearch={onSearch}
                >
                  {allConsignees ? (
                    allConsignees?.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.consignee_name}
                        </option>
                      );
                    })
                  ) : (
                    <Spin />
                  )}
                </Select>
                {/* <Input placeholder="Enter Consignee" /> */}
              </FormItem>
            </Col>
            <Col xs={24} xl={6} span={6} md={24} sm={24}>
              <FormItem
                label="Quantity (Bottles)"
                name="qty_bottles"
                rules={[
                  {
                    required: true,
                    message: 'Select qty bottles'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Quantity" />
              </FormItem>
            </Col>
          </Row>
        </Card>
        <br />
        <FormItem className="text-center">
          <Space>
            <CustomButton htmlType="button" onClick={() => history.back()}>
              Cancel
            </CustomButton>
            {truckShow ? (
              <Image
                src={truck}
                preview={false}
                style={{ width: '100px !important', height: '100px' }}
              />
            ) : (
              <CustomButton type="primary" htmlType="submit" loading={loading}>
                Submit
              </CustomButton>
            )}
          </Space>
        </FormItem>
      </Form>
    </Layout>
  );
};
export default CreateInvoice;
