import React from 'react';
import { Button } from 'antd';
import styles from '@/common/Layout/index.module.less';

const CustomButton = ({
  children,
  className,
  font = 'bold',
  type = 'default',
  ...rest
}) => {
  return (
    <Button
      {...rest}
      type={type}
      className={`sfprotext-${font} ${
        type === 'secondary' ? styles.secondary_btn : ''
      } ${className} ${styles.border_button}`}
      // style={{ borderRadius: '18px !important' }}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
