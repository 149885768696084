import React, { useEffect } from 'react';
import {
  Form,
  Card,
  Col,
  Row,
  Space,
  Input,
  message,
  Select,
  InputNumber,
  Tag,
  Tooltip
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomTable from 'common/Content/CustomTable';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getallprodMaterials,
  updateProduct,
  ValidateMaterial
  // getAllMaterialsBatches
} from '../../redux/slice';
import Layout from '@/common/Layout';
import { useState } from 'react';
import { getLoggedInUser, getRoles } from 'utilities/helpers';
import CustomModal from '@/common/CustomModal';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { ClockCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Meta } = Card;
const { TextArea } = Input;

const AssayCalculation = () => {
  const [form] = Form.useForm();
  const params = useParams();
  const dispatch = useDispatch();
  const { user } = getLoggedInUser();
  const { Superadmindata } = getRoles('Quality Assurance');
  const { allMaterials } = useSelector((state) => state.dispensing);

  const [data, setData] = useState({});
  const [extraGranulardata, setExtraGranulardata] = useState([]);
  const [materialData, setMaterialData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [materials, setMaterials] = useState([]);
  const [batch, setBatch] = useState([{ number: '', avl_qty: '' }]);
  const [materialBatches, setMaterialBatches] = useState([]);

  console.log(allMaterials, window.location.pathname.split('/'));
  const url = window.location.pathname.split('/');
  useEffect(() => {
    handleGetAllProductMaterials();
    // populatedFields();
  }, []);

  // useEffect(() => {
  //   handleGetAllMaterialsList();
  // }, []);

  // const handleGetAllMaterialsList = () => {
  //   dispatch(getAllMaterialsBatches());
  // };

  const handleAddBatches = () => {
    let list = [...batch];
    list.push({ number: '', avl_qty: '' });
    setBatch(list);
  };
  const handleGetAllProductMaterials = () => {
    let payload = { id: params?.id, type: 'calculation' };
    dispatch(getallprodMaterials(payload)).then((response) => {
      if (response?.payload?.success) {
        console.log(response?.payload?.data);
        setData(response?.payload?.data);
        const material_data =
          response?.payload?.data?.product_detail?.bmr_product_materials.filter(
            (item) => item?.is_extra == false
          );
        const extra_granular_data =
          response?.payload?.data?.product_detail?.bmr_product_materials.filter(
            (item) => item?.is_extra == true
          );
        setMaterialData(material_data);
        setExtraGranulardata(extra_granular_data);
      }
    });
  };
  const assayCal = () => {
    history.push(`/print-assay/${params.id}`);
  };

  const handleClose = () => {
    form.resetFields();
    setBatch([{ number: '', avl_qty: '' }]);
    setShowModal(false);
  };
  const onFinish = (values) => {
    const payload = {
      status: status,
      id: params.id,
      comments: values.comments
    };
    dispatch(updateProduct(payload)).then((response) => {
      if (response?.payload?.success) {
        message.success(response?.payload?.message);
        history.push('/product-list');
      }
    });
  };

  const handleClick = (id) => {
    console.log(id);
    const material_codes = data?.product_detail?.bmr_product_materials?.filter(
      (material) => material.material_id == id
    );
    setMaterials(material_codes);
    setShowModal(true);
  };

  const handleAddChange = (index, name, value) => {
    let list = [...batch];
    list[index][name] = value;
    setBatch(list);
  };

  const onChange = (id) => {
    const material_batches =
      data?.product_detail?.bmr_product_materials?.filter(
        (material) => material.material_id == id
      );
    console.log(material_batches);
    setMaterialBatches(material_batches?.[0]?.material?.batch_details);
    setShowModal(true);
  };
  console.log(materialBatches);

  const handleRemoveBatches = (id) => {
    console.log(id);
    let list = [...batch];
    list.splice(id, 1);
    setBatch(list);
    console.log(batch);
  };
  const handleValidate = () => {
    console.log(materials);
    let lots = [
      {
        name: 'lot_1',
        lot_standard_quantity: parseFloat(materials?.[0]?.lot_1)
      },
      {
        name: 'lot_2',
        lot_standard_quantity: parseFloat(materials?.[0]?.lot_2)
      },
      {
        name: 'lot_3',
        lot_standard_quantity: parseFloat(materials?.[0]?.lot_3)
      },
      {
        name: 'lot_4',
        lot_standard_quantity: parseFloat(materials?.[0]?.lot_4)
      }
    ];
    const payload = {
      material_id: materials?.[0]?.material_id,
      compensation_material: materials?.[0]?.compensation_material,
      is_extra: materials?.[0]?.is_extra,
      dispensing_request_id: params?.id,
      batch_qty: materials?.[0]?.quantity_for_batch,
      lots: lots,
      batches: batch
    };
    console.log(payload);
    dispatch(ValidateMaterial(payload)).then((response) => {
      if (response?.payload?.success) {
        message.success(response?.payload?.message);
        handleGetAllProductMaterials();
        form.resetFields();
        setBatch([{ number: '', avl_qty: '' }]);
        setShowModal(false);
      }
    });
  };
  console.log(batch);
  // compensation_material;
  const columns = [
    {
      dataIndex: 'material',
      key: 'material',
      fixed: 'left',
      // width: '70px',
      // responsive: ['xl', 'xs', 'sm', 'xxl', 'md'],
      render: (_, record) => {
        return record?.compensation_material && record?.dispensing_required ? (
          <Space size={0}>
            <Tooltip title="Dispensing Required">
              <Tag color="success">D</Tag>
            </Tooltip>
            <Tooltip title="Compensatory Material">
              <Tag color="error">C</Tag>
            </Tooltip>
          </Space>
        ) : record?.compensation_material ? (
          <Tooltip title="Compensatory Material">
            <Tag color="success">C</Tag>
          </Tooltip>
        ) : record?.dispensing_required ? (
          <Tooltip title="Dispensing Required">
            <Tag color="success">D</Tag>
          </Tooltip>
        ) : (
          ''
        );
      }
    },
    {
      title: 'Name',
      dataIndex: 'material',
      key: 'material',
      fixed: 'left',
      // ellipsis: true,
      // width: '250px',
      // responsive: ['xl', 'xs', 'sm', 'xxl', 'md'],
      render: (_, record) => {
        return <span>{record?.material?.name}</span>;
      }
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      ellipsis: true,
      width: '150px',
      // responsive: ['xl', 'xs', 'sm', 'xxl', 'md'],
      render: (_, record) => {
        return record?.material?.suppliers?.manufacturer_name;
      }
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      // width: '100px',
      // responsive: ['xl', 'xs', 'sm', 'xxl', 'md'],
      render: (_, record) => {
        return record?.material?.code;
      }
    },
    {
      title: 'Batch Qty(KG)',
      dataIndex: 'quantity_for_batch',
      key: 'quantity_for_batch'
      // width: '120px',
      // responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    // {
    // title: 'Required Quantity',
    // children: [
    {
      title: 'LOT 1',
      dataIndex: 'lot_1',
      key: 'lot_1',
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'LOT 2',
      dataIndex: 'lot_2',
      key: 'lot_2',
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'LOT 3',
      dataIndex: 'lot_3',
      key: 'lot_3',
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'LOT 4',
      dataIndex: 'lot_4',
      key: 'lot_4',
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    // ]
    // },

    {
      title: 'Action',
      dataIndex: 'lot_4',
      key: 'lot_4',
      width: '100px',
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md'],
      render: (_, record) => {
        var d = data?.dispensing_histories?.filter(
          (material) => material.material_id == record?.material_id
        );
        console.log(d);
        return d?.length ? (
          <Tag icon={<CheckCircleOutlined />} color="success">
            Fulfilled
          </Tag>
        ) : (
          <Tag
            icon={<ClockCircleOutlined />}
            color="default"
            onClick={() => handleClick(record?.material_id)}
            style={{ cursor: 'pointer' }}
          >
            Fulfil
          </Tag>
        );
      }
    }
  ];
  const columns1 = [
    {
      title: 'Name',
      dataIndex: 'material',
      key: 'material',
      // ellipsis: true,
      // width: '100px',
      fixed: 'left',
      // responsive: ['xl', 'xs', 'sm', 'xxl', 'md'],
      render: (_, record) => {
        return (
          <span onClick={() => handleClick(record?.material_id)}>
            {record?.material?.name}
          </span>
        );
      }
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      // ellipsis: true,
      width: '80px',
      // responsive: ['xl', 'xs', 'sm', 'xxl', 'md'],
      render: (_, record) => {
        return record?.material?.suppliers?.manufacturer_name;
      }
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      // fixed: 'left',
      // responsive: ['xl', 'xs', 'sm', 'xxl', 'md'],
      render: (_, record) => {
        return record?.material?.code;
      }
    },
    {
      title: 'Batch Qty(KG)',
      dataIndex: 'quantity_for_batch',
      key: 'quantity_for_batch'
      // width: '150px',
      // responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Action',
      dataIndex: 'lot_4',
      key: 'lot_4',
      // responsive: ['xl', 'xs', 'sm', 'xxl', 'md'],
      render: (_, record) => {
        var d = data?.dispensing_histories?.filter(
          (material) => material.material_id == record?.material_id
        );
        return d?.length ? (
          <Tag icon={<CheckCircleOutlined />} color="success">
            Fulfilled
          </Tag>
        ) : (
          <Tag
            icon={<ClockCircleOutlined />}
            color="default"
            onClick={() => handleClick(record?.material_id)}
            style={{ cursor: 'pointer' }}
          >
            Fulfil
          </Tag>
        );
      }
    }
  ];
  return (
    <>
      <Layout sider={false}>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Card
            title={
              <span style={{ fontWeight: 'bold', color: '#c91761' }}>
                Dispensing Request - BMR Based
              </span>
            }
          >
            <Row gutter={24}>
              <Col xs={24} xl={24} span={12}>
                <p>
                  <span style={{ fontWeight: 'bold' }}>Product Name: </span>
                  {'  '}
                  <span>{data?.product_detail?.name}</span>
                </p>
              </Col>
              <Col xs={24} xl={12} span={12}>
                <p>
                  <span style={{ fontWeight: 'bold' }}>Product Batch: </span>
                  {'  '}
                  <span>{data?.product_batch_number}</span>
                </p>
              </Col>
              <Col xs={24} xl={12} span={12}>
                <p>
                  <span style={{ fontWeight: 'bold' }}>BMR/BPR Number: </span>
                  {'  '}
                  <span>{data?.product_detail?.bmr_bpr_code}</span>
                </p>
              </Col>
              <Col xs={24} xl={12} span={12}>
                <p>
                  <span style={{ fontWeight: 'bold' }}>Effective Date: </span>
                  {'  '}
                  <span>
                    {data?.effective_date
                      ? moment(data?.effective_date).format('DD/MM/YYYY')
                      : 'NA'}
                  </span>
                </p>
              </Col>
              <Col xs={24} xl={12} span={12}>
                <p>
                  <span style={{ fontWeight: 'bold' }}>Batch Size: </span>
                  {'  '}
                  <span>
                    {data?.product_detail?.theoretical_batch_size || 'NA'}
                  </span>
                </p>
              </Col>
            </Row>
          </Card>
          <br />
          <Card>
            <Meta
              title={
                <span style={{ color: '#c91761', fontWeight: 'bold' }}>
                  Dispensing Details
                </span>
              }
              description={
                <span style={{ fontWeight: 'bold' }}>
                  Note:- When two or more material code options are available
                  for a material, that entire material quantity shall be sourced
                  from any one of the material code.
                </span>
              }
              className={`${styles.cardStyles_approver}`}
            />
            <div className={`${styles.table_container}`}>
              <CustomTable
                columns={columns}
                dataSource={materialData}
                pagination={false}
                scroll={{ x: 'auto' }}
              />
            </div>
          </Card>
          <br />
          <Card>
            <Meta
              title={
                <span style={{ color: '#c91761', fontWeight: 'bold' }}>
                  Extra Granular Ingredients
                </span>
              }
              className={`${styles.cardStyles_approver}`}
            />
            <div className={`${styles.table_container}`}>
              <CustomTable
                columns={columns1}
                dataSource={extraGranulardata}
                pagination={false}
                scroll={{ x: 380 }}
              />
            </div>
            {(user?.level == 3 && user?.role == 'qc_initiator') ||
            Superadmindata?.[0]?.level == 3 ? (
              <div>
                <Row gutter={24}>
                  <Col xs={24} xl={24} span={12} md={24} sm={24}>
                    <FormItem
                      label="Comments"
                      name="comments"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Comments'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <TextArea rows={4} placeholder="Please Enter Comments" />
                    </FormItem>
                  </Col>
                </Row>
              </div>
            ) : (
              ''
            )}
          </Card>
          <br />

          <FormItem className="text-center">
            <Space>
              <>
                <CustomButton
                  className={styles.inwardButton}
                  onClick={() => history.back()}
                  type="primary"
                  ghost
                >
                  Back to Home
                </CustomButton>
                {url[1] == 'assay-calculation' ? (
                  <CustomButton
                    className={styles.inwardButton}
                    onClick={() => assayCal()}
                    type="primary"
                  >
                    Next
                  </CustomButton>
                ) : (
                  <CustomButton
                    className={styles.inwardButton}
                    onClick={() => history.push('/dispensing-list')}
                    type="primary"
                  >
                    Submit
                  </CustomButton>
                )}
              </>
            </Space>
          </FormItem>
        </Form>

        {showModal ? (
          <CustomModal
            title="Inventory"
            visible={showModal}
            width={'80%'}
            footer={null}
            className={styles.customModal}
            closable={false}
          >
            <Form form={form} layout="vertical">
              <Row gutter={24}>
                <Col xs={24} xl={24} span={12} md={24} sm={24}>
                  <FormItem
                    label="Material Code"
                    className={styles.customLable}
                  >
                    <Select
                      showSearch
                      placeholder="Select a material code"
                      // optionFilterProp="children"
                      onChange={(e) => onChange(e)}
                      // onSearch={onSearch}
                      // value={selectSecondValue[index]}
                    >
                      {materials?.map((item, index) => {
                        return (
                          <option value={item?.material_id} key={index}>
                            {item?.material?.code}
                          </option>
                        );
                      })}
                    </Select>
                  </FormItem>
                </Col>
                {batch?.map((item, index) => {
                  return (
                    <>
                      <Col xs={24} xl={10} span={12} md={24} sm={24}>
                        <FormItem
                          label="Batch Number"
                          className={styles.customLable}
                        >
                          <Select
                            onChange={(e) =>
                              handleAddChange(index, 'number', e)
                            }
                            value={item?.number}
                          >
                            {materialBatches?.map((item, index) => {
                              return (
                                <option
                                  value={item?.inh}
                                  key={index}
                                  disabled={batch.find(
                                    (bt) => bt.number == item?.inh
                                  )}
                                >
                                  {item?.inh}
                                </option>
                              );
                            })}
                          </Select>
                        </FormItem>
                      </Col>
                      <Col xs={20} xl={12} span={12} md={24} sm={24}>
                        <FormItem
                          label="Available Quantity"
                          className={styles.customLable}
                        >
                          <InputNumber
                            placeholder="Enter Available Quantity"
                            min={0}
                            style={{ width: '100%' }}
                            onChange={(e) =>
                              handleAddChange(index, 'avl_qty', e)
                            }
                            value={item?.avl_qty || ''}
                          />
                        </FormItem>
                      </Col>
                      {batch?.length == index + 1 ? (
                        <Col xs={2} xl={2} span={2} md={2} sm={2}>
                          <CustomButton
                            onClick={() => handleAddBatches()}
                            className={styles.qrcode}
                          >
                            <PlusOutlined />
                          </CustomButton>
                        </Col>
                      ) : (
                        <Col xs={2} xl={2} span={2} md={2} sm={2}>
                          <CustomButton
                            onClick={() => handleRemoveBatches(index)}
                            className={styles.qrcode}
                          >
                            <DeleteOutlined />
                          </CustomButton>
                        </Col>
                      )}
                    </>
                  );
                })}
              </Row>
              <FormItem className="text-center">
                <Space>
                  <CustomButton
                    onClick={() => handleValidate()}
                    type="primary"
                    // style={{
                    //   width: '100%',
                    //   borderColor: '#28a745',
                    //   color: '#28a745'
                    // }}
                  >
                    Calculate Quantities
                  </CustomButton>
                  <CustomButton
                    type="primary"
                    onClick={() => handleClose(false)}
                    ghost
                  >
                    Close
                  </CustomButton>
                </Space>
              </FormItem>
            </Form>
          </CustomModal>
        ) : (
          ''
        )}
      </Layout>
    </>
  );
};
export default AssayCalculation;
