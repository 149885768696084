import React, { useState, useEffect } from 'react';
import Layout from '@/common/Layout';
import {
  Row,
  Col,
  Form,
  Card,
  // Select,
  Input,
  DatePicker,
  Space,
  message,
  Image
} from 'antd';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import { createinvoice, getAllInvoicedetails } from '../../redux/slice';
import { useDispatch } from 'react-redux';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import truck from '@/features/generateMrn/components/images/truck-delivery-done.gif';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { EditOutlined } from '@ant-design/icons';

const dateFormat = 'YYYY-MM-DD';

const ViewEditInvoice = ({ loading }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [truckShow, setTruckShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const params = useParams();
  const onFinish = (values) => {
    setTruckShow(true);
    values.id = params.id;
    dispatch(createinvoice(values)).then((response) => {
      if (response?.payload?.success) {
        setTruckShow(false);
        message.success(response?.payload?.message);
        history.push('/invoice-details-list');
      }
    });
  };

  useEffect(() => {
    handleGetInvoiceById();
  }, []);

  const handleGetInvoiceById = () => {
    const payload = { id: params?.id };
    dispatch(getAllInvoicedetails(payload)).then((response) => {
      if (response?.payload?.success) {
        form.setFieldsValue({ inv_num: response?.payload?.data?.[0]?.inv_num });
        form.setFieldsValue({
          inv_date: moment(response?.payload?.data?.[0]?.inv_date, dateFormat)
        });
        form.setFieldsValue({ po_num: response?.payload?.data?.[0]?.po_num });
        form.setFieldsValue({
          po_date: moment(response?.payload?.data?.[0]?.po_date, dateFormat)
        });
        form.setFieldsValue({
          product_desc: response?.payload?.data?.[0]?.product_desc
        });
        form.setFieldsValue({
          product_batch: response?.payload?.data?.[0]?.product_batch
        });
        form.setFieldsValue({
          mfg_date: moment(response?.payload?.data?.[0]?.mfg_date, dateFormat)
        });
        form.setFieldsValue({
          expy_date: moment(response?.payload?.data?.[0]?.expy_date, dateFormat)
        });
        form.setFieldsValue({
          consignee: response?.payload?.data?.[0]?.consignee
        });
        form.setFieldsValue({
          qty_bottles: response?.payload?.data?.[0]?.qty_bottles
        });
      }
    });
  };

  return (
    <Layout sider={false}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
      >
        <Card
          title="INVOICE DETAILS"
          className={styles.CustomPanel}
          extra={<EditOutlined onClick={() => setIsEdit(true)} />}
        >
          <Row gutter={24}>
            <Col xs={24} xl={6} span={6} md={24} sm={24}>
              <FormItem
                label="Invoice #"
                name="inv_num"
                rules={[
                  {
                    required: true,
                    message: 'Select Invoice Number'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter Invoice #"
                  disabled={isEdit ? false : true}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={6} span={6} md={24} sm={24}>
              <FormItem
                label="Invoice Date"
                name="inv_date"
                rules={[
                  {
                    required: true,
                    message: 'Select Invoice Date'
                  }
                ]}
                className={styles.customLable}
              >
                <DatePicker
                  placeholder="Select Invoice Date"
                  style={{ width: '100%' }}
                  disabled={isEdit ? false : true}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={6} span={6} md={24} sm={24}>
              <FormItem
                label="PO #"
                name="po_num"
                rules={[
                  {
                    required: true,
                    message: 'Select PO Number'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter PO #"
                  disabled={isEdit ? false : true}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={6} span={6} md={24} sm={24}>
              <FormItem
                label="PO Date"
                name="po_date"
                rules={[
                  {
                    required: true,
                    message: 'Select PO Date'
                  }
                ]}
                className={styles.customLable}
              >
                <DatePicker
                  placeholder="Select PO Date"
                  style={{ width: '100%' }}
                  disabled={isEdit ? false : true}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={6} span={6} md={24} sm={24}>
              <FormItem
                label="Product Description"
                name="product_desc"
                rules={[
                  {
                    required: true,
                    message: 'Enter Product Description'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter Product Description"
                  disabled={isEdit ? false : true}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={6} span={6} md={24} sm={24}>
              <FormItem
                label="Product Batch #"
                name="product_batch"
                rules={[
                  {
                    required: true,
                    message: 'Enter Product Batch'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter Product Batch"
                  disabled={isEdit ? false : true}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={6} span={6} md={24} sm={24}>
              <FormItem
                label="Manufacturing Date"
                name="mfg_date"
                rules={[
                  {
                    required: true,
                    message: 'Select Manufacturing Date'
                  }
                ]}
                className={styles.customLable}
              >
                <DatePicker
                  placeholder="Select Manufactiring Date"
                  style={{ width: '100%' }}
                  disabled={isEdit ? false : true}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={6} span={6} md={24} sm={24}>
              <FormItem
                label="Expiry Date"
                name="expy_date"
                rules={[
                  {
                    required: true,
                    message: 'Select Expiry Date'
                  }
                ]}
                className={styles.customLable}
              >
                <DatePicker
                  placeholder="Select Expiry Date"
                  style={{ width: '100%' }}
                  disabled={isEdit ? false : true}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={6} span={6} md={24} sm={24}>
              <FormItem
                label="Consignee"
                name="consignee"
                rules={[
                  {
                    required: true,
                    message: 'Select Consignee'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter Consignee"
                  disabled={isEdit ? false : true}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={6} span={6} md={24} sm={24}>
              <FormItem
                label="Quantity (Bottles)"
                name="qty_bottles"
                rules={[
                  {
                    required: true,
                    message: 'Select qty bottles'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter Quantity"
                  disabled={isEdit ? false : true}
                />
              </FormItem>
            </Col>
          </Row>
        </Card>
        <br />

        <FormItem className="text-center">
          <Space>
            <CustomButton htmlType="button" onClick={() => history.back()}>
              Cancel
            </CustomButton>

            {truckShow ? (
              <Image
                src={truck}
                preview={false}
                style={{ width: '100px !important', height: '100px' }}
              />
            ) : isEdit ? (
              <CustomButton type="primary" htmlType="submit" loading={loading}>
                Update
              </CustomButton>
            ) : (
              ''
            )}
          </Space>
        </FormItem>
      </Form>
    </Layout>
  );
};
export default ViewEditInvoice;
