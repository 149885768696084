import React, { useState } from 'react';
import {
  Row,
  Col,
  Form,
  Card,
  // Input,
  Space,
  Select,
  // message,
  Image,
  Input,
  Radio,
  DatePicker
  // Radio
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import truck from '@/features/qa/components/images/truck-delivery-done.gif';
import Layout from '@/common/Layout';
import { useCreateSolventTransferPumpMutation } from '../../redux/apiSlice';
import moment from 'moment';
import LiveTimeInput from '@/common/Clock';
import CustomModal from '@/common/CustomModal';

import LocalStorage from 'utilities/localStorage';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
const user = LocalStorage.getItem('Gravity_user');
// console.log('user', user?.user?.employee);

const dispatchCheckListReport = () => {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  // #region Initial states - Start
  const [truckShow] = useState(false);

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [products, setProducts] = useState([
    {
      product_name: '',
      batch_id: '',
      exp_date: '',
      no_of_shippers: '',
      no_of_pallets: '',
      remarks: ''
    }
  ]);
  const [dispatches, setDispatches] = useState([
    { data_logger: '', batch_number: '', pallet_id: '' }
  ]);
  const [isModal, setIsModal] = useState(false);

  let [createSolventTransferPump] = useCreateSolventTransferPumpMutation();

  const handleAdd = () => {
    let list = [...products];
    list.push({
      product_name: '',
      batch_id: '',
      exp_date: '',
      no_of_shippers: '',
      no_of_pallets: '',
      remarks: ''
    });
    setProducts(list);
  };

  const handleRemove = (index) => {
    let list = [...products];
    list.splice(index, 1);
    setProducts(list);
  };

  const handleAddDispatchDetails = () => {
    let dispatch_list = [...dispatches];
    dispatch_list.push({
      data_logger: '',
      batch_number: '',
      pallet_id: ''
    });
    setDispatches(dispatch_list);
  };

  const handleRemoveDispatchDetails = (index) => {
    let dispatch_list = [...dispatches];
    dispatch_list.splice(index, 1);
    setDispatches(dispatch_list);
  };

  const handleModel = async () => {
    await form.validateFields();
    setIsModal(true);
  };

  const onFinishModal = async () => {};
  const handleClose = () => {
    setIsModal(false);
  };
  const onFinish = async (values) => {
    setSubmitButtonDisabled(true);

    const values2 = { ...values };
    values2.cleaned_by_user_date = moment(new Date()).format();
    values2.date = moment(new Date()).format();
    // values2.dpb_or_ihpb = dpbOrIhpbDefaultValue;

    const response = await createSolventTransferPump({
      ...values2
    });
    if (response?.data?.success === true) {
      // handleShowMessage('success', response?.data?.message);
      setTimeout(
        () => history.push('/log-books-solvent-transfer-pump-list'),
        1500
      );
    } else {
      setSubmitButtonDisabled(false);
      // handleShowMessage('error', response?.error?.data?.message);
    }
  };
  // #endregion

  // #region All onChange events handler - Start

  return (
    <Layout sider={false}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          cleaned_by_user_id: user?.user?.employee,
          display_cleaned_by: `${user?.user?.employee} (${moment(
            new Date()
          ).format('DD-MM-YY')})`
        }}
      >
        <Card
          title={'DISPATCH CHECK LIST FOR FINISHED GOODS'}
          className={styles.CustomPanel}
        >
          <Row gutter={24}>
            <Col xs={24} xl={12} span={24} md={24} sm={24}>
              <Card
                className={styles.CustomPanel}
                title="Verification Activity"
              >
                <Row gutter={24}>
                  <Col xs={24} xl={24} span={12} md={24} sm={24}>
                    <FormItem
                      label="Customer Name"
                      name="customer_name"
                      rules={[
                        {
                          required: true,
                          message: 'Select Equipment Id'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Select
                        placeholder="Select Equipment Id"
                        showSearch
                        optionFilterProp="children"
                        // onSearch={(e) => onSearch(e, 'equipment')}
                      ></Select>
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={24} span={12} md={24} sm={24}>
                    <FormItem
                      // label="Customer Name"
                      // name="solventTransferPumpEquipmentIdMasterId"
                      rules={[
                        {
                          required: true,
                          message: 'Select Equipment Id'
                        }
                      ]}
                      className={styles.customLable}
                    ></FormItem>
                  </Col>
                  <Col xs={24} xl={24} span={12} md={24} sm={24}>
                    <FormItem
                      label="Country / Market"
                      name="country"
                      rules={[
                        {
                          required: true,
                          message: 'Select Equipment Id'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Select
                        placeholder="Select Equipment Id"
                        showSearch
                        optionFilterProp="children"
                        // onSearch={(e) => onSearch(e, 'equipment')}
                      ></Select>
                    </FormItem>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={24} xl={12} span={24} md={24} sm={24}>
              <Card className={styles.CustomPanel} title="Loading Activity">
                <Row gutter={24}>
                  <Col xs={24} xl={8} span={12} md={24} sm={24}>
                    <FormItem
                      label="Dispatch Date"
                      name="dispatch_date"
                      rules={[
                        {
                          required: false,
                          message: 'Select Dispatch Date'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <LiveTimeInput valueType="date" />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={8} span={12} md={24} sm={24}>
                    <FormItem
                      label="Vehicle #"
                      name="vehicle_number"
                      rules={[
                        {
                          required: true,
                          message: 'Select Equipment Id'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input placeholder="Enter Vehicle Number" />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={8} span={12} md={24} sm={24}>
                    <FormItem
                      label="Container #"
                      name="container_number"
                      rules={[
                        {
                          required: true,
                          message: 'Select Equipment Id'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input placeholder="Enter Container Number" />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={24} span={12} md={24} sm={24}>
                    <FormItem
                      label="Cleanliness & Condition of the Vehicle Satisfactory"
                      name="cleanliness"
                      rules={[
                        {
                          required: true,
                          message: 'Select Equipment Id'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Radio.Group>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={24} span={12} md={24} sm={24}>
                    <FormItem
                      label="Pallet labels is available(Track & Trace)& Verification"
                      name="track_trace"
                      rules={[
                        {
                          required: true,
                          message: 'Select Equipment Id'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Radio.Group>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </FormItem>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <br />
          {products?.map((product, index) => (
            <Card
              className={styles.customLable11}
              extra={
                index === 0 ? (
                  <PlusCircleOutlined onClick={() => handleAdd()} />
                ) : (
                  <DeleteOutlined onClick={() => handleRemove(index)} />
                )
              }
              key={index}
            >
              <Row gutter={24}>
                <Col xs={24} xl={8} span={12} md={24} sm={24}>
                  <FormItem
                    label="Product Name"
                    // name="dispatch_date"
                    rules={[
                      {
                        required: false,
                        message: 'Select Dispatch Date'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Select
                      placeholder="Select Equipment Id"
                      showSearch
                      optionFilterProp="children"
                      // onSearch={(e) => onSearch(e, 'equipment')}
                    ></Select>
                  </FormItem>
                </Col>
                <Col xs={24} xl={8} span={12} md={24} sm={24}>
                  <FormItem
                    label="Batch #"
                    // name="vehicle_number"
                    rules={[
                      {
                        required: true,
                        message: 'Select Equipment Id'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input placeholder="Enter Vehicle Number" />
                  </FormItem>
                </Col>
                <Col xs={24} xl={8} span={12} md={24} sm={24}>
                  <FormItem
                    label="Expiry Date"
                    // name="container_number"
                    rules={[
                      {
                        required: true,
                        message: 'Select Equipment Id'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <DatePicker
                      placeholder="Select date"
                      style={{ width: '100%' }}
                      // name="removal_from_cold_store"
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={8} span={12} md={24} sm={24}>
                  <FormItem
                    label="Number of Shippers"
                    rules={[
                      {
                        required: true,
                        message: 'Select Equipment Id'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input placeholder="Enter Container Number" />
                  </FormItem>
                </Col>
                <Col xs={24} xl={8} span={12} md={24} sm={24}>
                  <FormItem
                    label="Number of Pallets/Shippers Loaded"
                    rules={[
                      {
                        required: true,
                        message: 'Select Equipment Id'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input placeholder="Enter Container Number" />
                  </FormItem>
                </Col>
                <Col xs={24} xl={8} span={12} md={24} sm={24}>
                  <FormItem
                    label="Remarks"
                    rules={[
                      {
                        required: true,
                        message: 'Select Equipment Id'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input placeholder="Enter Container Number" />
                  </FormItem>
                </Col>
              </Row>
            </Card>
          ))}
          <Card className={styles.customLable11}>
            <Row gutter={24}>
              <Col xs={24} xl={24} span={12} md={24} sm={24}>
                <FormItem
                  label="Mode of Dispatch"
                  // name="dispatch_date"
                  rules={[
                    {
                      required: true,
                      message: 'Select Dispatch Date'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Radio.Group>
                    <Radio value="Road">Road</Radio>
                    <Radio value="Air">Air</Radio>
                    <Radio value="Sea">Sea</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
              {dispatches?.map((dispatch, index) => (
                <>
                  <Col xs={24} xl={7} span={12} md={24} sm={24} key={index}>
                    <FormItem
                      label="Data Logger Serial No(If Applicable"
                      rules={[
                        {
                          required: true,
                          message: 'Select Equipment Id'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input placeholder="Enter Container Number" />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={7} span={12} md={24} sm={24}>
                    <FormItem
                      label="Batch #"
                      rules={[
                        {
                          required: true,
                          message: 'Select Equipment Id'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input placeholder="Enter Container Number" />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={8} span={12} md={24} sm={24}>
                    <FormItem
                      label="Pallet #(If Applicable)"
                      rules={[
                        {
                          required: true,
                          message: 'Select Equipment Id'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input placeholder="Enter Container Number" />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={2} span={12} md={24} sm={24}>
                    <FormItem className={styles.plusButton}>
                      {index === 0 ? (
                        <PlusCircleOutlined
                          onClick={() => handleAddDispatchDetails()}
                        />
                      ) : (
                        <DeleteOutlined
                          onClick={() => handleRemoveDispatchDetails(index)}
                        />
                      )}
                    </FormItem>
                  </Col>
                </>
              ))}
            </Row>
          </Card>
          <Card className={styles.customLable11}>
            <Row gutter={24}>
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label="Pallets covered with thermal blanket"
                  name="pallets_covered"
                  rules={[
                    {
                      required: true,
                      message: 'Select Dispatch Date'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Radio.Group>
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                    <Radio value="NA">NA</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label="Air bag filling and lashing is done"
                  name="filling_aur_bag"
                  rules={[
                    {
                      required: true,
                      message: 'Select Dispatch Date'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Radio.Group>
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                    <Radio value="NA">NA</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label="Physical Condition of the Packs / Pallets after loading is satisfactory"
                  name="physical_condition_of_the_packs"
                  rules={[
                    {
                      required: true,
                      message: 'Select Dispatch Date'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Radio.Group>
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label="Truck doors are closed/Locked"
                  name="truck_doors"
                  rules={[
                    {
                      required: true,
                      message: 'Select Dispatch Date'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Radio.Group>
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
            </Row>
          </Card>
          <Card
            className={styles.CustomPanel}
            title={
              <>
                <span>
                  For Finished goods stored in 2&deg;C-8&deg;C materials only
                </span>
              </>
            }
          >
            <Row gutter={24}>
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label="Date & Time of removal from cold chamber"
                  name="removal_from_cold_store"
                  rules={[
                    {
                      required: true,
                      message: 'Select Dispatch Date'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <DatePicker
                    showTime={{
                      format: 'HH:mm'
                    }}
                    placeholder="Select date & time"
                    style={{ width: '70%' }}
                    name="removal_from_cold_store"
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label="Date & Time of loading in tuck"
                  name="loading_truck_time"
                  rules={[
                    {
                      required: true,
                      message: 'Select Dispatch Date'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <DatePicker
                    showTime={{
                      format: 'HH:mm'
                    }}
                    placeholder="Select date & time"
                    style={{ width: '70%' }}
                    name="removal_from_cold_store"
                  />
                </FormItem>
              </Col>
            </Row>
          </Card>
          <br />
          <Card className={styles.customLable11}>
            <Row gutter={24}>
              <Col xs={24} xl={8} span={12} md={24} sm={24}>
                <FormItem
                  label="Done By"
                  name="done_by"
                  rules={[
                    {
                      required: false,
                      message: 'Select Dispatch Date'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input placeholder="Done by" />
                </FormItem>
              </Col>
              <Col xs={24} xl={8} span={12} md={24} sm={24}>
                <FormItem
                  label="Checked By"
                  name="checked_by"
                  rules={[
                    {
                      required: false,
                      message: 'Select Dispatch Date'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input placeholder="Checked by" />
                </FormItem>
                <span
                  className={styles.verificaton_button}
                  onClick={() => handleModel()}
                >
                  Initiate WH verification
                </span>
              </Col>
              <Col xs={24} xl={8} span={12} md={24} sm={24}>
                <FormItem
                  label="Verified By"
                  name="verified_by"
                  rules={[
                    {
                      required: false,
                      message: 'Select Dispatch Date'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input placeholder="Verified by" />
                </FormItem>
                <span
                  className={styles.verificaton_button}
                  onClick={() => handleModel()}
                >
                  Initiate QA verification
                </span>
              </Col>
            </Row>
          </Card>
        </Card>

        <br />
        <FormItem className="text-center">
          <Space>
            {truckShow ? (
              <Image
                src={truck}
                preview={false}
                style={{ width: '100px !important', height: '100px' }}
              />
            ) : (
              <CustomButton
                className={styles.inwardButton}
                htmlType="submit"
                type="primary"
                onSubmit={(e) => onFinish(e)}
                disabled={submitButtonDisabled}
              >
                Create
              </CustomButton>
            )}
            <CustomButton
              className={styles.inwardButton}
              type="primary"
              ghost
              onClick={() => history.back()}
            >
              Back
            </CustomButton>
          </Space>
        </FormItem>
        {isModal ? (
          <CustomModal
            title=""
            visible={isModal}
            width="50%"
            footer={null}
            className={styles.customModal}
            closable={false}
          >
            <div className={`${styles.table_row}`}>
              <Form form={form2} layout="vertical" onFinish={onFinishModal}>
                <Card
                  title={'Initiate Verification'}
                  className={styles.CustomPanel}
                >
                  <Row gutter={24}>
                    <Col xs={24} xl={12} span={24} md={24} sm={24}>
                      <FormItem
                        label="Verified By (User id)"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: 'Enter Verifier User Id'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input placeholder="Enter Verifier User Id" />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={12} span={24} md={24} sm={24}>
                      <FormItem
                        label="Password"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: 'Enter Password'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input.Password placeholder="Enter Password" />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={12} span={24} md={24} sm={24}>
                      <FormItem
                        label="Remarks"
                        name="remarks2"
                        className={styles.customLable}
                      >
                        <Input.TextArea placeholder="Enter remarks" />
                      </FormItem>
                    </Col>
                  </Row>
                </Card>

                <br />
                <FormItem className="text-center">
                  <Space>
                    <CustomButton
                      className={styles.inwardButton}
                      htmlType="submit"
                      type="primary"
                      size="medium"
                      // disabled={isModalSubmitDisabled}
                    >
                      Submit
                    </CustomButton>
                    <CustomButton
                      className={styles.inwardButton}
                      onClick={handleClose}
                      type="primary"
                      ghost
                      size="medium"
                    >
                      Close
                    </CustomButton>
                  </Space>
                </FormItem>
              </Form>
            </div>
          </CustomModal>
        ) : (
          ''
        )}
      </Form>
    </Layout>
  );
};
export default dispatchCheckListReport;
