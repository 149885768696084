import CustomButton from '@/common/CustomButton';
import { Card, Col, Divider, Row } from 'antd';
import React, { useEffect } from 'react';
import Styles from '@/common/Layout/index.module.less';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getDispenseLablebyId } from '../../redux/slice';

const DispenseLabelPage = () => {
  const { lables } = useSelector((state) => state.label);
  const dispatch = useDispatch();
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');
  const request_id = new URLSearchParams(search).get('request_id');
  console.log(lables);
  useEffect(() => {
    handleGetLable();
  }, []);

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);
  const handleClose = () => {
    window.open('https://google.com/', '_self');
    window.close();
  };

  const handleGetLable = () => {
    const payload = {
      id: id,
      request_id: request_id
    };
    dispatch(getDispenseLablebyId(payload));
  };

  return (
    <>
      {/* <Badge.Ribbon
        text={lables?.qc_status?.toUpperCase()}
        color={
          lables?.qc_status == 'pending'
            ? '#007FFF'
            : lables?.qc_status == 'Quarantine'
            ? '#FF9800'
            : lables?.qc_status == 'approved'
            ? '#4CAF50'
            : '#007FFF'
        }
      > */}
      <Card>
        <>
          <br />
          <Row>
            <Col span={12}>
              <h4 style={{ fontWeight: 'bold' }}>Name of the Material</h4>
            </Col>
            <Col span={3}>:</Col>
            <Col span={9}>{lables?.material?.name}</Col>
          </Row>
          <Divider className={Styles.divider} />
          <Row>
            <Col span={12}>
              <h4 style={{ fontWeight: 'bold' }}>Material Code</h4>
            </Col>
            <Col span={3}>:</Col>
            <Col span={9}>{lables?.material?.code}</Col>
          </Row>
          <Divider className={Styles.divider} />
          <Row>
            <Col span={12}>
              <h4 style={{ fontWeight: 'bold' }}>In-House Batch Number</h4>
            </Col>
            <Col span={3}>:</Col>
            <Col span={9}>{lables?.batch_detail?.inhouse_batch_number}</Col>
          </Row>
          <Divider className={Styles.divider} />
          <Row>
            <Col span={12}>
              <h4 style={{ fontWeight: 'bold' }}>Product Name</h4>
            </Col>
            <Col span={3}>:</Col>
            <Col span={9}>
              {lables?.dispensing_request?.product_detail?.name || 'NA'}
            </Col>
          </Row>
          <Divider className={Styles.divider} />
          <Row>
            <Col span={12}>
              <h4 style={{ fontWeight: 'bold' }}>Product Batch Number</h4>
            </Col>
            <Col span={3}>:</Col>
            <Col span={9}>
              {lables?.dispensing_request?.product_batch_number || 'NA'}
            </Col>
          </Row>
          <Divider className={Styles.divider} />
          <Row>
            <Col span={12}>
              <h4 style={{ fontWeight: 'bold' }}>LOT</h4>
            </Col>
            <Col span={3}>:</Col>
            <Col span={9}>{lables?.lot_no || 'NA'}</Col>
          </Row>
          <Divider className={Styles.divider} />
          <Row>
            <Col span={12}>
              <h4 style={{ fontWeight: 'bold' }}>Part</h4>
            </Col>
            <Col span={3}>:</Col>
            <Col span={9}>{'NA'}</Col>
          </Row>
          <Divider className={Styles.divider} />
          <Row>
            <Col span={12}>
              <h4 style={{ fontWeight: 'bold' }}>Dispense Container No.</h4>
            </Col>
            <Col span={3}>:</Col>
            <Col span={9}>{lables?.dc_number || 'NA'}</Col>
          </Row>
          <Divider className={Styles.divider} />
          <Row>
            <Col span={12}>
              <h4 style={{ fontWeight: 'bold' }}>Tare Weight</h4>
            </Col>
            <Col span={3}>:</Col>
            <Col span={9}>{lables?.tare_weight || 'NA'}</Col>
          </Row>
          <Divider className={Styles.divider} />
          <Row>
            <Col span={12}>
              <h4 style={{ fontWeight: 'bold' }}>Net Weight</h4>
            </Col>
            <Col span={3}>:</Col>
            <Col span={9}>{lables?.net_weight || 'NA'}</Col>
          </Row>
          <Divider className={Styles.divider} />
          <Row>
            <Col span={12}>
              <h4 style={{ fontWeight: 'bold' }}>Gross Weight</h4>
            </Col>
            <Col span={3}>:</Col>
            <Col span={9}>{lables?.gross_weight || 'NA'}</Col>
          </Row>
        </>
      </Card>
      {/* </Badge.Ribbon> */}
      <br />
      <div style={{ textAlign: 'center' }}>
        <CustomButton type="primary" onClick={handleClose}>
          Close
        </CustomButton>
      </div>
    </>
  );
};
export default DispenseLabelPage;
