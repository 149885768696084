import apiClient from 'utilities/apiClient';

export const materials = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.materialList}/${url}`, payload, true);
};

export const dispensing = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.dispense}/${url}`, payload, true);
};

export const dispensingList = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.dispense}/${url}`, payload, true);
};

export const updateDispensing = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.dispense}/${url}`, payload, true);
};

export const getbatches = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.materialList}/${url}`, payload, true);
};

export const allContainers = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.container}/${url}`, payload, true);
};

export const dispenseMaterialDetails = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.dispense}/${url}`, payload, true);
};

export const products = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.dispense}/${url}`, payload, true);
};

export const updateDate = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.product}/${url}`, payload, true);
};

export const dispenseBMRDetails = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.dispense}/${url}`, payload, true);
};

export const update_products = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.product}/${url}`, payload, true);
};

export const validate_material = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.qc}/${url}`, payload, true);
};

export const update_material = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.dispense}/${url}`, payload, true);
};

export const recal_yield = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.dispense}/${url}`, payload, true);
};

export const dispensing_booths = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.dispense}/${url}`, payload, true);
};

export const get_balance = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.balance}/${url}`, payload, true);
};

export const get_containers = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.dispense}/${url}`, payload, true);
};

export const approvedDispense = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.qc}/${url}`, payload, true);
};

export const getName = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.dispense}/${url}`, payload, true);
};

export const update_lots = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.product}/${url}`, payload, true);
};

export const update_return_qty = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.dispense}/${url}`, payload, true);
};
