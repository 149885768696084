import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { getReports, getFailedLogs, downloadReport } from './api';

const initialState = {
  loading: false
};

const actions = {
  GET_REPORTS: 'audit/GET_REPORTS',
  GET_FAILED_LOGINS: 'audit/GET_FAILED_LOGINS',
  DOWNLOAD_AUDIT: 'audit/DOWNLOAD_AUDIT'
};

export const getAllReports = createAsyncThunk(
  actions.GET_REPORTS,
  async (payload) => {
    const response = await getReports('audit_log_view', payload);
    return response;
  }
);

export const getFailedLoginReports = createAsyncThunk(
  actions.GET_FAILED_LOGINS,
  async (payload) => {
    const response = await getFailedLogs('failed-logins', payload);
    return response;
  }
);

export const DownloadAuditReport = createAsyncThunk(
  actions.DOWNLOAD_AUDIT,
  async (payload) => {
    const response = await downloadReport('audit_log_download', payload);
    return response;
  }
);

export const AuditTrailsSlice = createSlice({
  name: 'AuditTrail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllReports.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllReports.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllReports.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getFailedLoginReports.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getFailedLoginReports.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getFailedLoginReports.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(DownloadAuditReport.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(DownloadAuditReport.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(DownloadAuditReport.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
  }
});

export default AuditTrailsSlice.reducer;
