import React from 'react';
import Layout from '@/common/Layout';
import CreateGenerateMRNPage from './components/CreateGenerateMRNPage';

const GenerateMRN = () => {
  return (
    <>
      <Layout sider={false}>
        <CreateGenerateMRNPage />
      </Layout>
    </>
  );
};

export default GenerateMRN;
