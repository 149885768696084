import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Col,
  Row,
  Card,
  Radio,
  DatePicker,
  message,
  Empty,
  Select,
  Space,
  Divider,
  Tag
} from 'antd';
import FormItem from '@/common/FormItem';
import CustomButton from '@/common/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from 'common/Content/CustomTable';
import { columns } from './constants.js';
import { useParams } from 'react-router-dom';
import styles from '@/common/Layout/index.module.less';
import { history } from 'app/history';
import moment from 'moment';
import dayjs from 'dayjs';
import {
  createChecklist,
  getDispenseMaterialDetails,
  getPreviousProduct,
  getCampaignBatches
} from '../../redux/slice.js';
import { EditOutlined } from '@ant-design/icons';

const dateFormat = 'YYYY-MM-DD';
const datetimeFormat = 'YYYY-MM-DD HH:mm';

const CreateLineClearanceChecklistPage = () => {
  const [form] = Form.useForm();
  const [lineClearanceChecklist, setLineClearanceChecklist] = useState({});
  const [aHU, setAHU] = useState('off');
  const [lAF, setLAF] = useState('off');
  const dispatch = useDispatch();
  const params = useParams();
  const [wHLC, setWHLC] = useState({});
  const [qALC, setQALC] = useState({});
  const [lcData, setLcData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const { materialDetails } = useSelector((state) => state.materialDetails);
  const [campaignBatches, setCampaignBatches] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  console.log(materialDetails);
  // const material = 'material';

  const handleCapacity = (e) => {
    const { name, value } = e.target;
    setLineClearanceChecklist({ ...lineClearanceChecklist, [name]: value });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo.errorFields.length);
    if (errorInfo.errorFields.length > 0) {
      message.error(
        'Please select a response to all the questions in the checklist'
      );
    }
  };

  const material = materialDetails?.type;

  console.log(materialDetails);

  useEffect(() => {
    handleGetAllMaterials();
    handleGetAllCampaignBatches();
    setCampaignBatches(localStorage.getItem('batches'));
    console.log(localStorage.getItem('batches'));

    // populatedFields();
  }, []);

  useEffect(() => {
    lcData?.laf_switched_on == 'on' ? setLAF('on') : '';
    lcData?.area_ahu_switched_on == 'on' ? setAHU('on') : '';
    form.setFieldsValue({
      dispense_sampling_booth_id: lcData?.dispense_sampling_booth_id,
      previous_product_name: lcData?.previous_product_name,
      previous_product_batch_no: lcData?.previous_product_batch_no,
      area_ahu_switched_on: lcData?.area_ahu_switched_on,
      area_ahu_time_date: lcData?.area_ahu_time_date
        ? moment(lcData?.area_ahu_time_date, dateFormat)
        : '',
      laf_switched_on: lcData?.laf_switched_on,
      laf_time_date: lcData?.laf_time_date
        ? moment(lcData?.laf_time_date, dateFormat)
        : '',
      magnehelle_guage_reading_dispensing_samping_area:
        lcData?.magnehelle_guage_reading_dispensing_samping_area,
      temperature: lcData?.temperature,
      relative_humidity: lcData?.relative_humidity,
      pre_filter: lcData?.pre_filter,
      fine_filter: lcData?.fine_filter,
      hepa_filter: lcData?.hepa_filter
    });
  }, [lcData]);
  const handleGetAllMaterials = () => {
    let payload = {
      id: params.id
    };
    dispatch(getDispenseMaterialDetails(payload)).then(function (result) {
      if (result?.payload?.success) {
        console.log('jjjj');
        setLcData(result?.payload?.data?.line_clearance_input);
        setLineClearanceChecklist(
          result?.payload?.data?.lcChecklistData
            ? result?.payload?.data?.lcChecklistData[0]
            : ''
        );
        localStorage.setItem('bmr_number', result?.payload?.data?.bmr_number);
        localStorage.setItem(
          'product_batch_number',
          result?.payload?.data?.product_batch_number
        );
      }
    });
  };
  const handleGetAllCampaignBatches = () => {
    console.log(campaignBatches);
    let payload = {
      dispensing_request_id: String(localStorage.getItem('batches')).split(',')
    };
    dispatch(getCampaignBatches(payload)).then(function (result) {
      if (result?.payload?.success) {
        console.log(result?.payload?.data);
        setCampaigns(result?.payload?.data);
        // setCampaignBatches(result?.payload?.data?.batches);
      }
    });
    console.log(payload);
  };

  console.log(moment().format('YYYY-MM-DD'));
  const onFinish = (values) => {
    form.getFieldsValue();
    isEdit ? (values['id'] = materialDetails?.line_clearance_input?.id) : '';
    isEdit
      ? (values['line_clear_id'] = materialDetails?.lcChecklistData?.[0]?.id)
      : '';
    values['dispensing_request_id'] = params.id;
    values['date'] = values?.date
      ? values?.date
      : moment().format('YYYY-MM-DD');
    values['area_ahu_time_date'] = values?.area_ahu_time_date
      ? values?.area_ahu_time_date
      : moment().format('YYYY-MM-DD hh:mm');
    values['laf_time_date'] = values?.laf_time_date
      ? values?.laf_time_date
      : moment().format('YYYY-MM-DD hh:mm');
    console.log('form', values);
    values['proposed_product_name'] = materialDetails?.product_detail?.name;
    values['proposed_product_batch_no'] = materialDetails?.product_batch_number;
    values['pre_filter'] = lineClearanceChecklist.pre_filter;
    values['fine_filter'] = lineClearanceChecklist.fine_filter;
    values['hepa_filter'] = lineClearanceChecklist.hepa_filter;
    values['no_containers_of_previous_material'] =
      lineClearanceChecklist.no_containers_of_previous_material;
    values['checked_unavailablity_used_dispensing_sample_aids_area'] =
      lineClearanceChecklist.checked_unavailablity_used_dispensing_sample_aids_area;
    values['checked_unavailablity_vivible_powder_observed_around_the_area'] =
      lineClearanceChecklist.checked_unavailablity_vivible_powder_observed_around_the_area;
    values['dustbins_are_cleared'] =
      lineClearanceChecklist.dustbins_are_cleared;
    values['cleaning_of_floor'] = lineClearanceChecklist.cleaning_of_floor;
    values['cleaning_of_covings'] = lineClearanceChecklist.cleaning_of_covings;
    values['cleaning_of_electrical'] =
      lineClearanceChecklist.cleaning_of_electrical;
    values['previous_record_removed'] =
      lineClearanceChecklist.previous_record_removed;
    values['cleaniness_working_area'] =
      lineClearanceChecklist.cleaniness_working_area;
    values['balance_calbration_carried_out'] =
      lineClearanceChecklist.balance_calbration_carried_out;
    values['check_the_packing_condition'] =
      lineClearanceChecklist.check_the_packing_condition;
    values['checked_the_material'] =
      lineClearanceChecklist.checked_the_material;
    values['checked_the_retest_date'] =
      lineClearanceChecklist.checked_the_retest_date;
    values['swab_results_of_previous_product'] =
      lineClearanceChecklist.swab_results_of_previous_product;
    values['availability_cleanness_nitrogen'] =
      lineClearanceChecklist.availability_cleanness_nitrogen;
    values['sodium_vapour_lamps_required_proposed_product'] =
      lineClearanceChecklist.sodium_vapour_lamps_required_proposed_product;
    values['cleaniness_of_pass_box'] =
      lineClearanceChecklist.cleaniness_of_pass_box;
    values['compaign_batches'] = String(localStorage.getItem('batches')).split(
      ','
    );
    // values['lc_input_id'] = lcMaterialDetails?.id;
    // dispatch(createChecklist(values));
    dispatch(createChecklist(values)).then(function (response) {
      console.log(lineClearanceChecklist);
      if (response?.payload?.success) {
        console.log('success', response);
        message.success(response.payload.message);
        history.push('/dispense-material-list');
        form.resetFields();
      }
    });
  };

  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        <div className={styles.empty_state_text}>
          There are no material requests to display
        </div>
      </span>
    )
  };

  const disabledDate = (current) => {
    return current && current > dayjs().endOf('day');
  };

  const handleBoothId = (e) => {
    const payload = { dispense_sampling_booth_id: e };
    dispatch(getPreviousProduct(payload)).then(function (response) {
      if (response?.payload?.success) {
        console.log('success', response);
        form.setFieldsValue({
          previous_product_name:
            response?.payload?.data?.product_name ||
            localStorage.getItem('previous_product_name'),
          previous_product_batch_no:
            response?.payload?.data?.batch_number ||
            localStorage.getItem('previous_product_batch_no')
        });
      }
    });
  };

  useEffect(() => {
    console.log(materialDetails);
    const lc_filter = materialDetails?.lcChecklistData?.filter(
      (item) => item?.lc_type == 'lc_initiated'
    );
    const lc_qa_filter = materialDetails?.lcChecklistData?.filter(
      (item) => item?.lc_type == 'lc_submitted'
    );
    setWHLC(lc_filter);
    setQALC(lc_qa_filter);
    form.setFieldsValue({
      bmr_number:
        materialDetails?.bmr_number || localStorage.getItem('bmr_number'),
      product_code: materialDetails?.product_detail?.code,
      product_name: materialDetails?.product_detail?.name,
      proposed_product_name:
        materialDetails?.product_detail?.name ||
        localStorage.getItem('proposed_product_name'),
      proposed_product_batch_no:
        materialDetails?.product_batch_number ||
        localStorage.getItem('proposed_product_batch_no')
    });
  }, []);
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      {/* <h2 className={styles.CustomPanel}>
        Line Clearance Checklist -{' '}
        {material == 'bmr' ? 'BMR Based' : 'Material Code Based'}
      </h2> */}

      <Card
        className={styles.cardStyles_approver}
        title={`Line Clearance Checklist - ${
          material == 'bmr' ? 'BMR Based' : 'Material Code Based'
        }`}
        extra={
          materialDetails?.status === 'QA LC Pending' ? (
            <EditOutlined type="primary" onClick={() => setIsEdit(true)} />
          ) : (
            ''
          )
        }
      >
        <Row gutter={24}>
          {material == 'bmr' ? (
            <>
              <Col xs={24} xl={6} span={6}>
                <FormItem
                  label="BMR Number"
                  // name="bmr_number"
                  rules={[
                    {
                      required: false,
                      message: 'Enter BMR Number'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    // name="bmr_number"
                    // onChange={handleCapacity}
                    placeholder="Enter BMR Number"
                    value={materialDetails?.product_detail?.bmr_bpr_code}
                    disabled
                  />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="Product Name" className={styles.customLable}>
                  <Input
                    disabled
                    value={materialDetails?.product_detail?.name || 'NA'}
                  />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem
                  label="Product Batch Number"
                  className={styles.customLable}
                >
                  <Input
                    disabled
                    value={materialDetails?.product_batch_number || 'NA'}
                  />
                </FormItem>
              </Col>

              <Col span={6}>
                <FormItem label="Effective Date" className={styles.customLable}>
                  <Input
                    disabled
                    value={
                      materialDetails?.effective_date
                        ? moment(materialDetails?.effective_date).format(
                            'DD MMM YYYY'
                          )
                        : 'NA'
                    }
                  />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="Batch Size" className={styles.customLable}>
                  <Input
                    disabled
                    value={
                      materialDetails?.product_detail?.theoretical_batch_size ||
                      'NA'
                    }
                  />
                </FormItem>
              </Col>

              <Col span={6}>
                <FormItem label="MFC Number" className={styles.customLable}>
                  <Input disabled value={materialDetails?.ref_mfc || 'NA'} />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="Product Code" className={styles.customLable}>
                  <Input
                    disabled
                    value={materialDetails?.product_code || 'NA'}
                  />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="Lable Claim" className={styles.customLable}>
                  <Input
                    disabled
                    value={materialDetails?.label_claim || 'NA'}
                  />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem
                  label="Manufacturing Date"
                  className={styles.customLable}
                >
                  <Input
                    disabled
                    value={
                      materialDetails?.manufacturing_date
                        ? moment(materialDetails?.manufacturing_date).format(
                            'DD MMM YYYY'
                          )
                        : 'NA'
                    }
                  />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="Expiry Date" className={styles.customLable}>
                  <Input
                    disabled
                    value={materialDetails?.expiry_date || 'NA'}
                  />
                </FormItem>
              </Col>

              <Col span={6}>
                <FormItem
                  label="Manufacturing Site"
                  className={styles.customLable}
                >
                  <Input
                    disabled
                    value={materialDetails?.manufacturing_site || 'NA'}
                  />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem
                  label="Market / Customer Details"
                  className={styles.customLable}
                >
                  <Input
                    disabled
                    value={materialDetails?.customer_details || 'NA'}
                  />
                </FormItem>
              </Col>

              {/* <Col xs={24} xl={8} span={8}>
                <FormItem
                  label="Product Name"
                  rules={[
                    {
                      required: false,
                      message: 'Enter Product Name'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    disabled
                    value={materialDetails?.product_detail?.name}
                    placeholder="Enter Product Name"
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <FormItem
                  label="Effective Date"
                  rules={[
                    {
                      required: false,
                      message: 'Enter Product Name'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    disabled
                    value={
                      materialDetails?.effective_date
                        ? moment(materialDetails?.effective_date).format(
                            'DD-MM-YYYY'
                          )
                        : 'NA'
                    }
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <FormItem
                  label="Batch Size"
                  rules={[
                    {
                      required: false,
                      message: 'Enter Product Name'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    disabled
                    value={
                      materialDetails?.product_detail?.theoretical_batch_size ||
                      'NA'
                    }
                  />
                </FormItem>
              </Col> */}
              <Col xs={24} xl={8} span={8}>
                <FormItem
                  label="Product Batch #"
                  rules={[
                    {
                      required: false,
                      message: 'Enter Product Code'
                    }
                  ]}
                  className={styles.customLable12}
                >
                  {campaigns.map((batch) => (
                    <Tag key={batch?.id}>{batch?.product_batch_number}</Tag>
                  ))}
                  {/* <Input
                    name="product_batch_number"
                    value={materialDetails?.product_batch_number}
                    placeholder="Enter Product Batch Number"
                    disabled
                  /> */}
                </FormItem>
              </Col>
            </>
          ) : null}
        </Row>
        {material == 'material' ? (
          <>
            <CustomTable
              locale={locale}
              columns={columns}
              dataSource={materialDetails?.dispensing_materials}
              className={styles.scrolling}
              scroll={{ x: 'auto' }}
              pagination={false}
            />
          </>
        ) : null}
        {/* </Card> */}
        {/* <br /> */}
        <Divider />
        {/* <Card className={styles.cardStyles_approver} title=""> */}
        <Row gutter={24}>
          <Col xs={24} xl={12} span={12}>
            <FormItem
              label="Dispensing Sample Booth ID No "
              name="dispense_sampling_booth_id"
              rules={[
                {
                  required: false,
                  message: 'Enter Sample Booth ID Number'
                }
              ]}
              className={styles.customLable}
            >
              {/* <Input
                name="dispense_sampling_booth_id"
                onChange={handleCapacity}
                placeholder="Enter Sample Booth ID Number"
              /> */}
              <Select
                placeholder="Select Sample Booth ID Number"
                onChange={(e) => handleBoothId(e)}
                disabled={
                  materialDetails?.status == 'QA LC Pending' && !isEdit
                    ? true
                    : false
                }
              >
                <option value="WHE004">WHE004</option>
                <option value="WHE005">WHE005</option>
                <option value="WHE020">WHE020</option>
                <option value="PRE018">PRE018</option>
              </Select>
            </FormItem>
          </Col>
          <Col xs={24} xl={12} span={12}>
            <FormItem
              label="Date"
              name="date"
              onChange={handleCapacity}
              className={styles.customLable}
            >
              <DatePicker
                style={{ width: '100%' }}
                defaultValue={moment()}
                format={dateFormat}
                disabledDate={disabledDate}
                disabled={
                  materialDetails?.status == 'QA LC Pending' ? true : false
                }
              />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={12} span={12}>
            <FormItem
              label="Previous Product Name"
              name="previous_product_name"
              onChange={handleCapacity}
              rules={[
                {
                  required: false,
                  message: 'Enter Previous Product Name'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                name="previous_product_name"
                onChange={handleCapacity}
                placeholder="Enter Previous Product Name"
                disabled
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={12} span={12}>
            <FormItem
              label="Previous Batch No"
              name="previous_product_batch_no"
              onChange={handleCapacity}
              rules={[
                {
                  required: false,
                  message: 'Enter Batch Number'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                name="previous_product_batch_no"
                onChange={handleCapacity}
                placeholder="Enter Batch Number"
                disabled
              />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={12} span={12}>
            <FormItem
              label="Proposed Product Name"
              // name="proposed_product_name"
              onChange={handleCapacity}
              rules={[
                {
                  required: false,
                  message: 'Enter Proposed Product Name'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                // name="proposed_product_name"
                onChange={handleCapacity}
                placeholder="Enter Proposed Product Name"
                value={materialDetails?.product_detail?.name}
                disabled
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={12} span={12}>
            <FormItem
              label="Proposed Batch No"
              // name="proposed_product_batch_no"
              rules={[
                {
                  required: false,
                  message: 'Enter Batch Number'
                }
              ]}
              className={styles.customLable12}
            >
              {campaigns?.map((batch) => (
                <Tag key={batch?.id}>{batch?.product_batch_number}</Tag>
              ))}
              {/* <Input
                name="proposed_product_batch_no"
                onChange={handleCapacity}
                placeholder="Enter Batch Number"
                value={materialDetails?.product_batch_number}
                disabled
              /> */}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={12} span={12}>
            <FormItem
              label="Area AHU Switch Status"
              name="area_ahu_switched_on"
              rules={[
                {
                  required: false,
                  message: 'Enter Area AHU Switch status'
                }
              ]}
              className={styles.customLable}
            >
              <Select
                placeholder="Select AHU switch status"
                onChange={(e) => setAHU(e)}
                disabled={
                  materialDetails?.status == 'QA LC Pending' && !isEdit
                    ? true
                    : false
                }
              >
                <option value="on">ON</option>
                <option value="off">OFF</option>
              </Select>
            </FormItem>
          </Col>
          {aHU == 'on' ? (
            <Col xs={24} xl={12} span={12}>
              {/* <FormItem
              label="Time & Date"
              name="area_ahu_time_date"
              // onChange={handleCapacity}
              rules={[
                {
                  required: false,
                  message: 'Enter Time & Date'
                }
              ]}
            >
              <Space direction="vertical" size={12}>
                <DatePicker showTime />
              </Space>
            </FormItem> */}
              <FormItem
                label="Time & Date"
                name="area_ahu_time_date"
                onChange={handleCapacity}
                rules={[
                  {
                    required: false,
                    message: 'Select date'
                  }
                ]}
                className={styles.customLable}
              >
                <DatePicker
                  showTime={{
                    format: 'HH:mm'
                  }}
                  style={{ width: '100%' }}
                  defaultValue={moment()}
                  format={datetimeFormat}
                  disabledDate={disabledDate}
                  disabled={
                    materialDetails?.status == 'QA LC Pending' && !isEdit
                      ? true
                      : false
                  }
                />
              </FormItem>
            </Col>
          ) : (
            ''
          )}
          <Col xs={24} xl={12} span={12}>
            <FormItem
              label="LAF Switch Status"
              name="laf_switched_on"
              rules={[
                {
                  required: false,
                  message: 'Enter LAF Switch status'
                }
              ]}
            >
              <Select
                placeholder="Select LAF switch status"
                onChange={(e) => setLAF(e)}
                disabled={
                  materialDetails?.status == 'QA LC Pending' && !isEdit
                    ? true
                    : false
                }
              >
                <option value="on">ON</option>
                <option value="off">OFF</option>
              </Select>
            </FormItem>
          </Col>
          {lAF == 'on' ? (
            <Col xs={24} xl={12} span={12}>
              <FormItem
                label="Time & Date"
                name="laf_time_date"
                onChange={handleCapacity}
                rules={[
                  {
                    required: false,
                    message: 'Enter Time & Date'
                  }
                ]}
              >
                <DatePicker
                  showTime={{
                    format: 'HH:mm'
                  }}
                  style={{ width: '100%' }}
                  defaultValue={moment()}
                  format={datetimeFormat}
                  disabledDate={disabledDate}
                  disabled={
                    materialDetails?.status == 'QA LC Pending' && !isEdit
                      ? true
                      : false
                  }
                />
              </FormItem>
            </Col>
          ) : (
            ''
          )}
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Magnehelic gauge reading Dispensing Sample Area"
              name="magnehelle_guage_reading_dispensing_samping_area"
              rules={[
                {
                  required: false,
                  message: 'Enter Gauge Reading Dispensing Sample Area'
                }
              ]}
            >
              <Input
                name="magnehelle_guage_reading_dispensing_samping_area"
                onChange={handleCapacity}
                placeholder="Enter Gauge Reading Dispensing Sample Area"
                disabled={
                  materialDetails?.status == 'QA LC Pending' && !isEdit
                    ? true
                    : false
                }
              />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={12} span={12}>
            <FormItem
              label="Temperature(&#8451;)"
              name="temperature"
              rules={[
                {
                  required: false,
                  message: 'Enter Temperature'
                }
              ]}
            >
              <Input
                name="temperature"
                onChange={handleCapacity}
                placeholder="Enter Temperature"
                disabled={
                  materialDetails?.status == 'QA LC Pending' && !isEdit
                    ? true
                    : false
                }
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={12} span={12}>
            <FormItem
              label="% Relative Humidity"
              name="relative_humidity"
              rules={[
                {
                  required: false,
                  message: 'Enter Relative Humidity'
                }
              ]}
            >
              <Input
                name="relative_humidity"
                onChange={handleCapacity}
                placeholder="Enter Relative Humidity"
                disabled={
                  materialDetails?.status == 'QA LC Pending' && !isEdit
                    ? true
                    : false
                }
              />
            </FormItem>
          </Col>
        </Row>
      </Card>
      <br />
      {/* <h2 className={styles.CustomPanel}>LAF Magnehelle Gauge Reading</h2> */}
      <Card
        className={styles.cardStyles_approver}
        title="LAF Magnehelle Gauge Reading"
      >
        <Row gutter={24}>
          <Col xs={24} xl={8} span={8}>
            <FormItem
              label="Pre Filter"
              name="pre_filter"
              rules={[
                {
                  required: false,
                  message: 'Enter Pre Filter'
                }
              ]}
            >
              <Input
                name="pre_filter"
                onChange={handleCapacity}
                placeholder="Enter Pre Filter"
                disabled={
                  materialDetails?.status == 'QA LC Pending' && !isEdit
                    ? true
                    : false
                }
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={8} span={8}>
            <FormItem
              label="Fine Filter"
              name="fine_filter"
              rules={[
                {
                  required: false,
                  message: 'Enter Fine Filter'
                }
              ]}
            >
              <Input
                name="fine_filter"
                onChange={handleCapacity}
                placeholder="Enter Fine Filter"
                disabled={
                  materialDetails?.status == 'QA LC Pending' && !isEdit
                    ? true
                    : false
                }
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={8} span={8}>
            <FormItem
              label="HEPA Filter"
              name="hepa_filter"
              rules={[
                {
                  required: false,
                  message: 'Enter HEPA Filter'
                }
              ]}
            >
              <Input
                name="hepa_filter"
                onChange={handleCapacity}
                placeholder="Enter HEPA Filter"
                disabled={
                  materialDetails?.status == 'QA LC Pending' && !isEdit
                    ? true
                    : false
                }
              />
            </FormItem>
          </Col>
        </Row>
        <Divider />
        {/* </Card>
      <Card className={styles.cardStyles_approver}> */}
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="No containers of previous material observed in the area"
              name="no_containers_of_previous_material"
              rules={[
                {
                  required: false,
                  message: 'Select no containers of previous materials'
                }
              ]}
            >
              <Space size={16}>
                {wHLC && materialDetails?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.no_containers_of_previous_material == 1
                        ? 'Yes'
                        : wHLC?.[0]?.no_containers_of_previous_material == 2
                        ? 'No'
                        : wHLC?.[0]?.no_containers_of_previous_material == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC && materialDetails?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.no_containers_of_previous_material == 1
                        ? 'Yes'
                        : qALC?.[0]?.no_containers_of_previous_material == 2
                        ? 'No'
                        : qALC?.[0]?.no_containers_of_previous_material == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {materialDetails?.status !== 'Approved' ? (
                <Radio.Group
                  onChange={handleCapacity}
                  name="no_containers_of_previous_material"
                  disabled={
                    materialDetails?.status == 'QA LC Pending' && !isEdit
                      ? true
                      : false
                  }
                  value={parseInt(
                    lineClearanceChecklist?.no_containers_of_previous_material
                  )}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Checked for the unavailability of used dispensing sampling aids in the area"
              name="checked_unavailablity_used_dispensing_sample_aids_area"
              rules={[
                {
                  required: false,
                  message: 'Select checked unavailabilty'
                }
              ]}
            >
              <Space size={16}>
                {wHLC && materialDetails?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.sample_aids_area == 1
                        ? 'Yes'
                        : wHLC?.[0]?.sample_aids_area == 2
                        ? 'No'
                        : wHLC?.[0]?.sample_aids_area == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC && materialDetails?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.sample_aids_area == 1
                        ? 'Yes'
                        : qALC?.[0]?.sample_aids_area == 2
                        ? 'No'
                        : qALC?.[0]?.sample_aids_area == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {materialDetails?.status !== 'Approved' ? (
                <Radio.Group
                  name="checked_unavailablity_used_dispensing_sample_aids_area"
                  onChange={handleCapacity}
                  disabled={
                    materialDetails?.status == 'QA LC Pending' && !isEdit
                      ? true
                      : false
                  }
                  value={parseInt(
                    lineClearanceChecklist?.checked_unavailablity_used_dispensing_sample_aids_area
                  )}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Checked for the unavailability of visible powder observed around the balance"
              name="checked_unavailablity_vivible_powder_observed_around_the_area"
              rules={[
                {
                  required: false,
                  message: 'Select checked poweder observed around area'
                }
              ]}
            >
              <Space size={16}>
                {wHLC && materialDetails?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.vivible_powder == 1
                        ? 'Yes'
                        : wHLC?.[0]?.vivible_powder == 2
                        ? 'No'
                        : wHLC?.[0]?.vivible_powder == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC && materialDetails?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.vivible_powder == 1
                        ? 'Yes'
                        : qALC?.[0]?.vivible_powder == 2
                        ? 'No'
                        : qALC?.[0]?.vivible_powder == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {materialDetails?.status !== 'Approved' ? (
                <Radio.Group
                  name="checked_unavailablity_vivible_powder_observed_around_the_area"
                  onChange={handleCapacity}
                  disabled={
                    materialDetails?.status == 'QA LC Pending' && !isEdit
                      ? true
                      : false
                  }
                  value={parseInt(
                    lineClearanceChecklist?.checked_unavailablity_vivible_powder_observed_around_the_area
                  )}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Dustbins are cleared and scraps are removed"
              name="dustbins_are_cleared"
              rules={[
                {
                  required: false,
                  message: 'Select Dustbins are cleared and scraps are removed'
                }
              ]}
            >
              <Space size={16}>
                {wHLC && materialDetails?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.dustbins_are_cleared == 1
                        ? 'Yes'
                        : wHLC?.[0]?.dustbins_are_cleared == 2
                        ? 'No'
                        : wHLC?.[0]?.dustbins_are_cleared == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC && materialDetails?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.dustbins_are_cleared == 1
                        ? 'Yes'
                        : qALC?.[0]?.dustbins_are_cleared == 2
                        ? 'No'
                        : qALC?.[0]?.dustbins_are_cleared == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {materialDetails?.status !== 'Approved' ? (
                <Radio.Group
                  name="dustbins_are_cleared"
                  onChange={handleCapacity}
                  disabled={
                    materialDetails?.status == 'QA LC Pending' && !isEdit
                      ? true
                      : false
                  }
                  value={parseInt(lineClearanceChecklist?.dustbins_are_cleared)}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Cleaning of floor, stainless steel panels of dispensing sampling booth"
              name="cleaning_of_floor"
              rules={[
                {
                  required: false,
                  message: 'Select Cleaning of floor'
                }
              ]}
            >
              <Space size={16}>
                {wHLC && materialDetails?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.cleaning_of_floor == 1
                        ? 'Yes'
                        : wHLC?.[0]?.cleaning_of_floor == 2
                        ? 'No'
                        : wHLC?.[0]?.cleaning_of_floor == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC && materialDetails?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.cleaning_of_floor == 1
                        ? 'Yes'
                        : qALC?.[0]?.cleaning_of_floor == 2
                        ? 'No'
                        : qALC?.[0]?.cleaning_of_floor == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {materialDetails?.status !== 'Approved' ? (
                <Radio.Group
                  name="cleaning_of_floor"
                  onChange={handleCapacity}
                  disabled={
                    materialDetails?.status == 'QA LC Pending' && !isEdit
                      ? true
                      : false
                  }
                  value={parseInt(lineClearanceChecklist?.cleaning_of_floor)}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Cleaning of covings"
              name="cleaning_of_covings"
              rules={[
                {
                  required: false,
                  message: 'Select Cleaning of covings'
                }
              ]}
            >
              <Space size={16}>
                {wHLC && materialDetails?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.cleaning_of_covings == 1
                        ? 'Yes'
                        : wHLC?.[0]?.cleaning_of_covings == 2
                        ? 'No'
                        : wHLC?.[0]?.cleaning_of_covings == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC && materialDetails?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.cleaning_of_covings == 1
                        ? 'Yes'
                        : qALC?.[0]?.cleaning_of_covings == 2
                        ? 'No'
                        : qALC?.[0]?.cleaning_of_covings == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {materialDetails?.status !== 'Approved' ? (
                <Radio.Group
                  name="cleaning_of_covings"
                  onChange={handleCapacity}
                  disabled={
                    materialDetails?.status == 'QA LC Pending' && !isEdit
                      ? true
                      : false
                  }
                  value={parseInt(lineClearanceChecklist?.cleaning_of_covings)}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Cleaning of Electrical fixtures of dispensing sampling booth"
              name="cleaning_of_electrical"
              rules={[
                {
                  required: false,
                  message: 'Select Cleaning of Electrical'
                }
              ]}
            >
              <Space size={16}>
                {wHLC && materialDetails?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.cleaning_of_electrical == 1
                        ? 'Yes'
                        : wHLC?.[0]?.cleaning_of_electrical == 2
                        ? 'No'
                        : wHLC?.[0]?.cleaning_of_electrical == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC && materialDetails?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.cleaning_of_electrical == 1
                        ? 'Yes'
                        : qALC?.[0]?.cleaning_of_electrical == 2
                        ? 'No'
                        : qALC?.[0]?.cleaning_of_electrical == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {materialDetails?.status !== 'Approved' ? (
                <Radio.Group
                  name="cleaning_of_electrical"
                  onChange={handleCapacity}
                  disabled={
                    materialDetails?.status == 'QA LC Pending' && !isEdit
                      ? true
                      : false
                  }
                  value={parseInt(
                    lineClearanceChecklist?.cleaning_of_electrical
                  )}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Previous records removed from the area"
              name="previous_record_removed"
              rules={[
                {
                  required: false,
                  message: 'Select Previous records removed'
                }
              ]}
            >
              <Space size={16}>
                {wHLC && materialDetails?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.previous_record_removed == 1
                        ? 'Yes'
                        : wHLC?.[0]?.previous_record_removed == 2
                        ? 'No'
                        : wHLC?.[0]?.previous_record_removed == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC && materialDetails?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.previous_record_removed == 1
                        ? 'Yes'
                        : qALC?.[0]?.previous_record_removed == 2
                        ? 'No'
                        : qALC?.[0]?.previous_record_removed == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {materialDetails?.status !== 'Approved' ? (
                <Radio.Group
                  name="previous_record_removed"
                  onChange={handleCapacity}
                  disabled={
                    materialDetails?.status == 'QA LC Pending' && !isEdit
                      ? true
                      : false
                  }
                  value={parseInt(
                    lineClearanceChecklist?.previous_record_removed
                  )}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Cleanliness of working area"
              name="cleaniness_working_area"
              rules={[
                {
                  required: false,
                  message: 'Select Cleanliness of working area'
                }
              ]}
            >
              <Space size={16}>
                {wHLC && materialDetails?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.cleaniness_working_area == 1
                        ? 'Yes'
                        : wHLC?.[0]?.cleaniness_working_area == 2
                        ? 'No'
                        : wHLC?.[0]?.cleaniness_working_area == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC && materialDetails?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.cleaniness_working_area == 1
                        ? 'Yes'
                        : qALC?.[0]?.cleaniness_working_area == 2
                        ? 'No'
                        : qALC?.[0]?.cleaniness_working_area == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {materialDetails?.status !== 'Approved' ? (
                <Radio.Group
                  name="cleaniness_working_area"
                  onChange={handleCapacity}
                  disabled={
                    materialDetails?.status == 'QA LC Pending' && !isEdit
                      ? true
                      : false
                  }
                  value={parseInt(
                    lineClearanceChecklist?.cleaniness_working_area
                  )}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Balance calibration carried out"
              name="balance_calbration_carried_out"
              rules={[
                {
                  required: false,
                  message: 'Select Balance calibration carried out'
                }
              ]}
            >
              <Space size={16}>
                {wHLC && materialDetails?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.balance_calbration_carried_out == 1
                        ? 'Yes'
                        : wHLC?.[0]?.balance_calbration_carried_out == 2
                        ? 'No'
                        : wHLC?.[0]?.balance_calbration_carried_out == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC && materialDetails?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.balance_calbration_carried_out == 1
                        ? 'Yes'
                        : qALC?.[0]?.balance_calbration_carried_out == 2
                        ? 'No'
                        : qALC?.[0]?.balance_calbration_carried_out == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {materialDetails?.status !== 'Approved' ? (
                <Radio.Group
                  name="balance_calbration_carried_out"
                  onChange={handleCapacity}
                  disabled={
                    materialDetails?.status == 'QA LC Pending' && !isEdit
                      ? true
                      : false
                  }
                  value={parseInt(
                    lineClearanceChecklist?.balance_calbration_carried_out
                  )}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Checked the packing condition of the to be dispensed sampling materials and found satisfactory"
              name="check_the_packing_condition"
              rules={[
                {
                  required: false,
                  message: 'Select Check the packing condition'
                }
              ]}
            >
              <Space size={16}>
                {wHLC && materialDetails?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.check_the_packing_condition == 1
                        ? 'Yes'
                        : wHLC?.[0]?.check_the_packing_condition == 2
                        ? 'No'
                        : wHLC?.[0]?.check_the_packing_condition == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC && materialDetails?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.check_the_packing_condition == 1
                        ? 'Yes'
                        : qALC?.[0]?.check_the_packing_condition == 2
                        ? 'No'
                        : qALC?.[0]?.check_the_packing_condition == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {materialDetails?.status !== 'Approved' ? (
                <Radio.Group
                  name="check_the_packing_condition"
                  onChange={handleCapacity}
                  disabled={
                    materialDetails?.status == 'QA LC Pending' && !isEdit
                      ? true
                      : false
                  }
                  value={parseInt(
                    lineClearanceChecklist?.check_the_packing_condition
                  )}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Checked the material name, material code, approved label etc. to be dispensed material"
              name="checked_the_material"
              rules={[
                {
                  required: false,
                  message: 'Select Check the material'
                }
              ]}
            >
              <Space size={16}>
                {wHLC && materialDetails?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.checked_the_material == 1
                        ? 'Yes'
                        : wHLC?.[0]?.checked_the_material == 2
                        ? 'No'
                        : wHLC?.[0]?.checked_the_material == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC && materialDetails?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.checked_the_material == 1
                        ? 'Yes'
                        : qALC?.[0]?.checked_the_material == 2
                        ? 'No'
                        : qALC?.[0]?.checked_the_material == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {materialDetails?.status !== 'Approved' ? (
                <Radio.Group
                  name="checked_the_material"
                  onChange={handleCapacity}
                  disabled={
                    materialDetails?.status == 'QA LC Pending' && !isEdit
                      ? true
                      : false
                  }
                  value={parseInt(lineClearanceChecklist?.checked_the_material)}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Checked the retest date, best before use date and expire date of to be dispensed sampling material and found satisfactory"
              name="checked_the_retest_date"
              rules={[
                {
                  required: false,
                  message: 'Select Check the retest date'
                }
              ]}
            >
              <Space size={16}>
                {wHLC && materialDetails?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.checked_the_retest_date == 1
                        ? 'Yes'
                        : wHLC?.[0]?.checked_the_retest_date == 2
                        ? 'No'
                        : wHLC?.[0]?.checked_the_retest_date == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC && materialDetails?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.checked_the_retest_date == 1
                        ? 'Yes'
                        : qALC?.[0]?.checked_the_retest_date == 2
                        ? 'No'
                        : qALC?.[0]?.checked_the_retest_date == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {materialDetails?.status !== 'Approved' ? (
                <Radio.Group
                  name="checked_the_retest_date"
                  onChange={handleCapacity}
                  disabled={
                    materialDetails?.status == 'QA LC Pending' && !isEdit
                      ? true
                      : false
                  }
                  value={parseInt(
                    lineClearanceChecklist?.checked_the_retest_date
                  )}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Swab results of previous product(If any)"
              name="swab_results_of_previous_product"
              rules={[
                {
                  required: false,
                  message: 'Select swab results of previous product'
                }
              ]}
            >
              <Space size={16}>
                {wHLC && materialDetails?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.swab_results_of_previous_product == 1
                        ? 'Yes'
                        : wHLC?.[0]?.swab_results_of_previous_product == 2
                        ? 'No'
                        : wHLC?.[0]?.swab_results_of_previous_product == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC && materialDetails?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.swab_results_of_previous_product == 1
                        ? 'Yes'
                        : qALC?.[0]?.swab_results_of_previous_product == 2
                        ? 'No'
                        : qALC?.[0]?.swab_results_of_previous_product == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {materialDetails?.status !== 'Approved' ? (
                <Radio.Group
                  name="swab_results_of_previous_product"
                  onChange={handleCapacity}
                  disabled={
                    materialDetails?.status == 'QA LC Pending' && !isEdit
                      ? true
                      : false
                  }
                  value={parseInt(
                    lineClearanceChecklist?.swab_results_of_previous_product
                  )}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Availability & Cleanness of nitrogen purging"
              name="availability_cleanness_nitrogen"
              rules={[
                {
                  required: false,
                  message: 'Select availability & cleanness of Nitrogen'
                }
              ]}
            >
              <Space size={16}>
                {wHLC && materialDetails?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.availability_cleanness_nitrogen == '1'
                        ? 'Yes'
                        : wHLC?.[0]?.availability_cleanness_nitrogen == '2'
                        ? 'No'
                        : wHLC?.[0]?.availability_cleanness_nitrogen == '3'
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC && materialDetails?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.availability_cleanness_nitrogen == 1
                        ? 'Yes'
                        : qALC?.[0]?.availability_cleanness_nitrogen == 2
                        ? 'No'
                        : qALC?.[0]?.availability_cleanness_nitrogen == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {materialDetails?.status !== 'Approved' ? (
                <Radio.Group
                  name="availability_cleanness_nitrogen"
                  onChange={handleCapacity}
                  disabled={
                    materialDetails?.status == 'QA LC Pending' && !isEdit
                      ? true
                      : false
                  }
                  value={parseInt(
                    lineClearanceChecklist?.availability_cleanness_nitrogen
                  )}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Sodium vapor Lamps required for proposed product"
              name="sodium_vapour_lamps_required_proposed_product"
              rules={[
                {
                  required: false,
                  message: 'Select Sodium vapour'
                }
              ]}
            >
              <Space size={16}>
                {wHLC && materialDetails?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.sodium_vapour_lamps == 1
                        ? 'Yes'
                        : wHLC?.[0]?.sodium_vapour_lamps == 2
                        ? 'No'
                        : wHLC?.[0]?.sodium_vapour_lamps == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC && materialDetails?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.sodium_vapour_lamps == 1
                        ? 'Yes'
                        : qALC?.[0]?.sodium_vapour_lamps == 2
                        ? 'No'
                        : qALC?.[0]?.sodium_vapour_lamps == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {materialDetails?.status !== 'Approved' ? (
                <Radio.Group
                  name="sodium_vapour_lamps_required_proposed_product"
                  onChange={handleCapacity}
                  disabled={
                    materialDetails?.status == 'QA LC Pending' && !isEdit
                      ? true
                      : false
                  }
                  value={parseInt(
                    lineClearanceChecklist?.sodium_vapour_lamps_required_proposed_product
                  )}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={12}>
            <FormItem
              label="check the cleanliness of pass box"
              name="cleaniness_of_pass_box"
              rules={[
                {
                  required: false,
                  message: 'Select Check the cleanliness of pass box'
                }
              ]}
            >
              <Space size={16}>
                {wHLC && materialDetails?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.cleaniness_of_pass_box == 1
                        ? 'Yes'
                        : wHLC?.[0]?.cleaniness_of_pass_box == 2
                        ? 'No'
                        : wHLC?.[0]?.cleaniness_of_pass_box == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC && materialDetails?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.cleaniness_of_pass_box == 1
                        ? 'Yes'
                        : qALC?.[0]?.cleaniness_of_pass_box == 2
                        ? 'No'
                        : qALC?.[0]?.cleaniness_of_pass_box == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {materialDetails?.status !== 'Approved' ? (
                <Radio.Group
                  name="cleaniness_of_pass_box"
                  onChange={handleCapacity}
                  disabled={
                    materialDetails?.status == 'QA LC Pending' && !isEdit
                      ? true
                      : false
                  }
                  value={parseInt(
                    lineClearanceChecklist?.cleaniness_of_pass_box
                  )}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
      </Card>
      <br />
      {materialDetails?.status !== 'Approved' ? (
        <div className={styles.submitbutton}>
          {' '}
          <FormItem className="text-center">
            <CustomButton type="primary" htmlType="submit">
              Submit
            </CustomButton>
          </FormItem>
        </div>
      ) : (
        ''
      )}
    </Form>
  );
};
export default CreateLineClearanceChecklistPage;
