import React, { useState } from 'react';
import {
  Row,
  Col,
  Form,
  Card,
  Input,
  Space,
  Select,
  message,
  Image
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import truck from '@/features/qa/components/images/truck-delivery-done.gif';
import Layout from '@/common/Layout';
import {
  useGetDispensingAidsEquipmentMastersQuery,
  useGetDispensingAidsIdMastersQuery,
  useCreateDispensingAidsMutation
} from '../../redux/apiSlice';
import moment from 'moment';
import LiveTimeInput from '@/common/Clock';

import LocalStorage from 'utilities/localStorage';
const user = LocalStorage.getItem('Gravity_user');
console.log('user', user?.user?.employee);

const createProductNewPage = () => {
  const [form] = Form.useForm();

  // #region Initial states - Start
  const [truckShow] = useState(false);
  const [search, setSearch] = useState('');

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  // #region Showing success or error message - Start
  const handleShowMessage = (messageType, messageText) => {
    messageApi.open({
      type: messageType,
      content: messageText
    });
  };
  // #endregion

  // #region Fetching Dispensing Aids Equipment Masters - Start
  let {
    data: dispensingAidsEquipmentMasters,
    refetch: dispensingAidsEquipmentMastersRefetch
  } = useGetDispensingAidsEquipmentMastersQuery({ search });
  // #endregion

  // #region Fetching Dispensing Aids Id Masters - Start
  let {
    data: dispensingAidsIdMasters,
    refetch: dispensingAidsIdMastersRefetch
  } = useGetDispensingAidsIdMastersQuery({ search });
  // #endregion

  // #region Creating Vacuum Cleaning Record - Start
  let [createDispensingAids] = useCreateDispensingAidsMutation();

  const onFinish = async (values) => {
    setSubmitButtonDisabled(true);

    const values2 = { ...values };
    values2.cleaned_by_user_date = moment(new Date()).format();
    values2.date = moment(new Date()).format();

    const response = await createDispensingAids({
      ...values2
    });
    // console.log(response);
    if (response?.data?.success === true) {
      handleShowMessage('success', response?.data?.message);
      setTimeout(() => history.push('/log-books-dispensing-aids-list'), 1500);
    } else {
      setSubmitButtonDisabled(false);
      handleShowMessage('error', response?.error?.data?.message);
    }
  };
  // #endregion

  // #region All onSearch events handler - Start
  const onSearch = async (e, name) => {
    if (e.length === 0 || e.length >= 3) {
      if (name === 'equipment') {
        setSearch(e);
        dispensingAidsEquipmentMastersRefetch();
      } else if (name === 'id') {
        setSearch(e);
        dispensingAidsIdMastersRefetch();
      }
    }
  };
  // #endregion

  return (
    <Space>
      {contextHolder}
      <Layout sider={false}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            cleaned_by_user_id: user?.user?.employee,
            display_cleaned_by: `${user?.user?.employee} (${moment(
              new Date()
            ).format('DD-MM-YY')})`
          }}
        >
          <Card
            title={'CLEANING RECORD OF DISPENSING AIDS'}
            className={styles.CustomPanel}
          >
            <Row gutter={24}>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Date"
                  name="display_date"
                  className={styles.customLable}
                >
                  <LiveTimeInput valueType="date" />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label="Equipment / Accessory Name"
                  name="dispensingAidsEquipmentAccessoriesNameMasterId"
                  rules={[
                    {
                      required: true,
                      message: 'Select Equipment / Accessory Name'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Select
                    placeholder="Select Equipment / Accessory Name"
                    showSearch
                    optionFilterProp="children"
                    onSearch={(e) => onSearch(e, 'equipment')}
                  >
                    {dispensingAidsEquipmentMasters?.data?.map(
                      (item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.name}
                          </option>
                        );
                      }
                    )}
                  </Select>
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label="ID No"
                  name="dispensingAidsIdMasterId"
                  rules={[
                    {
                      required: true,
                      message: 'Select ID'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Select
                    placeholder="Select ID"
                    showSearch
                    optionFilterProp="children"
                    onSearch={(e) => onSearch(e, 'id')}
                  >
                    {dispensingAidsIdMasters?.data?.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.equip_id}
                        </option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label="In-House / Product Batch #"
                  name="in_house_product_batch"
                  rules={[
                    {
                      required: true,
                      message: 'Enter In-House / Product Batch #'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter In-House / Product Batch #"
                    style={{ width: '100%' }}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Cleaned by (User Id & Date)"
                  name="display_cleaned_by"
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Remarks"
                  name="remarks"
                  className={styles.customLable}
                >
                  <Input.TextArea placeholder="" style={{ width: '100%' }} />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24} hidden={true}>
                <FormItem
                  label="Cleaned by"
                  name="cleaned_by_user_id"
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24} hidden={true}>
                <FormItem
                  label="Cleaned by"
                  name="cleaned_by_user_date"
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
            </Row>
          </Card>

          <br />
          <FormItem className="text-center">
            <Space>
              {truckShow ? (
                <Image
                  src={truck}
                  preview={false}
                  style={{ width: '100px !important', height: '100px' }}
                />
              ) : (
                <CustomButton
                  className={styles.inwardButton}
                  htmlType="submit"
                  type="primary"
                  onSubmit={(e) => onFinish(e)}
                  disabled={submitButtonDisabled}
                >
                  Create
                </CustomButton>
              )}
              <CustomButton
                className={styles.inwardButton}
                type="primary"
                ghost
                onClick={() => history.back()}
              >
                Back
              </CustomButton>
            </Space>
          </FormItem>
        </Form>
      </Layout>
    </Space>
  );
};
export default createProductNewPage;
