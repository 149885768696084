import React, { useState, useEffect } from 'react';
import Layout from '@/common/Layout';
import {
  Row,
  Col,
  Form,
  Card,
  // Select,
  Input,
  // DatePicker,
  Space,
  message,
  Image,
  Spin
} from 'antd';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import {
  createlog,
  getAllLogBooksdetails,
  printLogDetails
} from '../../redux/slice';
import { useDispatch } from 'react-redux';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import truck from '@/features/generateMrn/components/images/truck-delivery-done.gif';
import { EditOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { FilePdfOutlined } from '@ant-design/icons';

// const { TextArea } = Input;

const ViewEditLog = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const params = useParams();

  const [truckShow, setTruckShow] = useState(false);
  const [alllogs, setAlllogs] = useState([]);
  const [enableCard, setEnableCard] = useState();
  const [editedLogs, setEditedLogs] = useState([]);
  const [hidePrint, setHidePrint] = useState(false);

  useEffect(() => {
    handleGetAllLogDetails();
  }, []);

  const handleGetAllLogDetails = () => {
    const payload = { batch_log_id: params?.id };
    dispatch(getAllLogBooksdetails(payload)).then((response) => {
      if (response?.payload?.success) {
        setAlllogs(response?.payload?.data);
      }
    });
  };

  const handleOnChange = (e, id, index, j, type, main_type, wip_id, i) => {
    let list = [...alllogs];
    if (main_type == 'main') {
      list[index][type] = e.target.value;
    }
    if (main_type == 'wip') {
      list[index].wip_batch_nums[j][type] = e.target.value;
    }
    if (main_type == 'fg') {
      list[index].wip_batch_nums[j].fg_batch_nums[i][type] = e.target.value;
    }
    let editableRows = [...editedLogs];
    if (type == 'wip_size') {
      editableRows[index]['blend_alloc_wip'] = list[index].wip_batch_nums[
        j
      ].blend_alloc_wip = parseInt(
        (parseFloat(e.target.value) / parseFloat(list[index].blend_size)) * 100
      );
    }
    setAlllogs(list);

    console.log(editableRows);
    editableRows[index][type] = e.target.value;

    setEditedLogs(editableRows);
  };

  const onFinish = (values) => {
    setTruckShow(true);
    const payload = {
      blend_batch: values?.blend_batch,
      blend_size: values?.blend_size,
      blend_bmr: values?.bmr,
      alloted_by: values?.alloted_by
      // batch_wip: wipDetails
    };
    dispatch(createlog(payload)).then((response) => {
      if (response?.payload?.success) {
        setTruckShow(false);
        message.success(response?.payload?.message);
        history.push('/log-book-list');
      }
    });
  };

  const handleUpdate = () => {
    Object.keys(editedLogs[0]).forEach((key) => {
      if (editedLogs[0][key] === null) {
        delete editedLogs[0][key];
      }
    });
    const result = editedLogs.reduce((obj) => ({
      ...obj
      // [cur.sid]: cur
    }));
    console.log(result);
    let payload = result;

    dispatch(createlog(payload)).then((response) => {
      if (response?.payload?.success) {
        setTruckShow(false);
        message.success(response?.payload?.message);
        history.push(`/view-log/${params.id}`);
      }
    });
    console.log(payload);
  };

  const handleEnable = (id, index, type, j, wip_id, i, fg_id) => {
    setEditedLogs([]);
    if (type == 'main') {
      let editableRows = [...editedLogs];
      editableRows.push({
        blend_batch: null,
        blend_size: null,
        blend_bmr: null,
        blend_id: id
      });
      setEditedLogs(editableRows);
    }
    if (type == 'wip') {
      let editableRows = [...editedLogs];
      editableRows.push({
        wip_batch: null,
        wip_strength: null,
        wip_bmr: null,
        wip_size: null,
        blend_alloc_wip: null,
        wip_size_caps: null,
        wip_id: wip_id,
        blend_id: id
      });
      setEditedLogs(editableRows);
    }
    if (type == 'fg_batch') {
      let editableRows = [...editedLogs];
      editableRows.push({
        wip_batch: null,
        wip_strength: null,
        wip_bmr: null,
        wip_size: null,
        blend_alloc_wip: null,
        wip_size_caps: null,
        wip_id: wip_id,
        fg_id: fg_id,
        blend_id: id
      });
      setEditedLogs(editableRows);
    }
    setEnableCard({ id, index, type, sub_id: j, fg_id: i });
  };

  const handlePrint = () => {
    setHidePrint(true);
    const payload = { id: params.id };
    dispatch(printLogDetails(payload)).then((response) => {
      if (response?.payload?.success) {
        setHidePrint(false);
        window.open(response?.payload?.url, '_blank');
      }
    });
  };
  return (
    <Layout sider={false}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
      >
        {alllogs?.map((log, index) => {
          return (
            <Card
              title="VIEW BATCH NUMBERING LOG BOOK"
              className={styles.CustomPanel}
              key={index}
              extra={
                hidePrint ? (
                  <Spin />
                ) : (
                  <CustomButton type="primary" onClick={handlePrint}>
                    <FilePdfOutlined /> Print
                  </CustomButton>
                )
              }
            >
              <Card
                // className={styles.subpanel}
                title="BATCH NUMBERS"
                className={styles.CustomPanel}
                extra={
                  <EditOutlined
                    style={{
                      fontSize: '24px',
                      color: '#c91761'
                    }}
                    onClick={() => handleEnable(log?.id, index, 'main')}
                  />
                }
              >
                <Row gutter={24}>
                  <Col xs={24} xl={6} span={6} md={24} sm={24}>
                    <FormItem
                      label="Blend Batch"
                      rules={[
                        {
                          required: true,
                          message: 'Select Blend Batch'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Enter Blend Batch"
                        value={log?.blend_batch}
                        disabled={enableCard?.type == 'main' ? false : true}
                        onChange={(e) =>
                          handleOnChange(
                            e,
                            log?.id,
                            index,
                            '_',
                            'blend_batch',
                            'main'
                          )
                        }
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={6} span={6} md={24} sm={24}>
                    <FormItem
                      label="Blend Size"
                      rules={[
                        {
                          required: true,
                          message: 'Enter blend size'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Enter Blend Size"
                        onChange={(e) =>
                          handleOnChange(
                            e,
                            log?.id,
                            index,
                            '_',
                            'blend_size',
                            'main'
                          )
                        }
                        value={log?.blend_size}
                        disabled={enableCard?.type == 'main' ? false : true}
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={6} span={6} md={24} sm={24}>
                    <FormItem
                      label="BMR"
                      rules={[
                        {
                          required: true,
                          message: 'Enter BMR'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Enter BMR"
                        value={log?.blend_bmr}
                        onChange={(e) =>
                          handleOnChange(
                            e,
                            log?.id,
                            index,
                            '_',
                            'blend_bmr',
                            'main'
                          )
                        }
                        disabled={enableCard?.type == 'main' ? false : true}
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={6} span={6} md={24} sm={24}>
                    <FormItem
                      label="Alloted By"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Alloted By'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Enter Alloted By"
                        value={log?.alloted_by}
                        disabled
                      />
                    </FormItem>
                  </Col>
                </Row>
                {enableCard?.type == 'main' ? (
                  <FormItem className="text-center">
                    <Space>
                      {truckShow ? (
                        <Image
                          src={truck}
                          preview={false}
                          style={{ width: '100px !important', height: '100px' }}
                        />
                      ) : (
                        <CustomButton
                          type="primary"
                          onClick={() => handleUpdate(log?.id, index)}
                        >
                          Update
                        </CustomButton>
                      )}
                    </Space>
                  </FormItem>
                ) : (
                  ''
                )}
              </Card>
              {log?.wip_batch_nums?.map((item, j) => {
                return (
                  <Space size={6} key={j}>
                    <Card
                      // className={styles.subpanel}
                      className={styles.CustomPanel}
                      title="Compression/Coating/Capsulation Batch Details"
                      key={j}
                      extra={
                        <EditOutlined
                          style={{
                            fontSize: '24px',
                            color: '#c91761'
                          }}
                          onClick={() =>
                            handleEnable(log?.id, index, 'wip', j, item.id)
                          }
                        />
                      }
                    >
                      <Row gutter={24}>
                        <Col xs={24} xl={8} span={8} md={24} sm={24}>
                          <FormItem
                            label="WIP/Strength Batch #"
                            rules={[
                              {
                                required: true,
                                message: 'Enter WIP'
                              }
                            ]}
                            className={styles.customLable}
                          >
                            <Input
                              placeholder="Enter WIP/Strength Batch"
                              value={item?.wip_batch}
                              disabled={
                                enableCard?.type == 'wip' &&
                                enableCard?.sub_id == j
                                  ? false
                                  : true
                              }
                              onChange={(e) =>
                                handleOnChange(
                                  e,
                                  log?.id,
                                  index,
                                  j,
                                  'wip_batch',
                                  'wip',
                                  item?.id
                                )
                              }
                            />
                          </FormItem>
                        </Col>
                        <Col xs={24} xl={8} span={8} md={24} sm={24}>
                          <FormItem
                            label="Strength"
                            rules={[
                              {
                                required: true,
                                message: 'Enter Strength'
                              }
                            ]}
                            className={styles.customLable}
                          >
                            <Input
                              placeholder="Enter Strength"
                              value={item?.wip_strength}
                              disabled={
                                enableCard?.type == 'wip' &&
                                enableCard?.sub_id == j
                                  ? false
                                  : true
                              }
                              onChange={(e) =>
                                handleOnChange(
                                  e,
                                  log?.id,
                                  index,
                                  j,
                                  'wip_strength',
                                  'wip',
                                  item?.id
                                )
                              }
                            />
                          </FormItem>
                        </Col>
                        <Col xs={24} xl={8} span={8} md={24} sm={24}>
                          <FormItem
                            label="BMR #"
                            rules={[
                              {
                                required: true,
                                message: 'Enter BMR #'
                              }
                            ]}
                            className={styles.customLable}
                          >
                            <Input
                              placeholder="Enter BMR #"
                              value={item?.wip_bmr}
                              disabled={
                                enableCard?.type == 'wip' &&
                                enableCard?.sub_id == j
                                  ? false
                                  : true
                              }
                              onChange={(e) =>
                                handleOnChange(
                                  e,
                                  log?.id,
                                  index,
                                  j,
                                  'wip_bmr',
                                  'wip',
                                  item?.id
                                )
                              }
                            />
                          </FormItem>
                        </Col>
                        <Col xs={24} xl={8} span={8} md={24} sm={24}>
                          <FormItem
                            label="WIP Size(KG)"
                            rules={[
                              {
                                required: true,
                                message: 'Enter WIP Size'
                              }
                            ]}
                            className={styles.customLable}
                          >
                            <Input
                              placeholder="Enter WIP Size"
                              value={item?.wip_size}
                              disabled={
                                enableCard?.type == 'wip' &&
                                enableCard?.sub_id == j
                                  ? false
                                  : true
                              }
                              onChange={(e) =>
                                handleOnChange(
                                  e,
                                  log?.id,
                                  index,
                                  j,
                                  'wip_size',
                                  'wip',
                                  item?.id
                                )
                              }
                            />
                          </FormItem>
                        </Col>
                        <Col xs={24} xl={8} span={8} md={24} sm={24}>
                          <FormItem
                            label="Blend Allocation to WIP %"
                            rules={[
                              {
                                required: true,
                                message: 'Enter Blend Allocation'
                              }
                            ]}
                            className={styles.customLable}
                          >
                            <Input
                              placeholder="Enter Blend Allocation to WIP"
                              value={item?.blend_alloc_wip}
                              disabled
                              onChange={(e) =>
                                handleOnChange(
                                  e,
                                  log?.id,
                                  index,
                                  j,
                                  'blend_alloc_wip',
                                  'wip',
                                  item?.id
                                )
                              }
                            />
                          </FormItem>
                        </Col>
                        <Col xs={24} xl={8} span={8} md={24} sm={24}>
                          <FormItem
                            label="WIP Size Capsuals"
                            rules={[
                              {
                                required: true,
                                message: 'Enter WIP Size Capsuals'
                              }
                            ]}
                            className={styles.customLable}
                          >
                            <Input
                              placeholder="Enter WIP Size Capsuals"
                              value={item?.wip_size_caps}
                              disabled={
                                enableCard?.type == 'wip' &&
                                enableCard?.sub_id == j
                                  ? false
                                  : true
                              }
                              onChange={(e) =>
                                handleOnChange(
                                  e,
                                  log?.id,
                                  index,
                                  j,
                                  'wip_size_caps',
                                  'wip',
                                  item?.id
                                )
                              }
                            />
                          </FormItem>
                        </Col>
                      </Row>
                      {item?.fg_batch_nums?.map((packdata, i) => {
                        return (
                          <Space key={i} size={6}>
                            <Card
                              // className={styles.subpanel}
                              title="Packing/FG Details"
                              className={styles.CustomPanelFG}
                              key={i}
                              extra={
                                <EditOutlined
                                  style={{
                                    fontSize: '24px',
                                    color: '#c91761'
                                  }}
                                  onClick={() =>
                                    handleEnable(
                                      log?.id,
                                      index,
                                      'fg_batch',
                                      j,
                                      item.id,
                                      i,
                                      packdata.id
                                    )
                                  }
                                />
                              }
                            >
                              <Row gutter={24}>
                                <Col xs={24} xl={8} span={8} md={24} sm={24}>
                                  <FormItem
                                    label="Packing/FG Batch #"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Enter Packing/FG Batch #'
                                      }
                                    ]}
                                    className={styles.customLable}
                                  >
                                    <Input
                                      placeholder="Enter Packing/FG Batch #"
                                      value={packdata?.packing_fg_batch}
                                      disabled={
                                        enableCard?.type == 'fg_batch' &&
                                        enableCard?.sub_id == j &&
                                        enableCard?.fg_id == i
                                          ? false
                                          : true
                                      }
                                      onChange={(e) =>
                                        handleOnChange(
                                          e,
                                          log?.id,
                                          index,
                                          j,
                                          'packing_fg_batch',
                                          'fg',
                                          item?.id,
                                          i,
                                          packdata.id
                                        )
                                      }
                                    />
                                  </FormItem>
                                </Col>
                                <Col xs={24} xl={8} span={8} md={24} sm={24}>
                                  <FormItem
                                    label="Count"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Enter Count'
                                      }
                                    ]}
                                    className={styles.customLable}
                                  >
                                    <Input
                                      placeholder="Enter Count"
                                      value={packdata?.fg_count}
                                      disabled={
                                        enableCard?.type == 'fg_batch' &&
                                        enableCard?.sub_id == j &&
                                        enableCard?.fg_id == i
                                          ? false
                                          : true
                                      }
                                      onChange={(e) =>
                                        handleOnChange(
                                          e,
                                          log?.id,
                                          index,
                                          j,
                                          'fg_count',
                                          'fg',
                                          item?.id,
                                          i,
                                          packdata.id
                                        )
                                      }
                                    />
                                  </FormItem>
                                </Col>
                                <Col xs={24} xl={8} span={8} md={24} sm={24}>
                                  <FormItem
                                    label="BPR #"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Enter BPR #'
                                      }
                                    ]}
                                    className={styles.customLable}
                                  >
                                    <Input
                                      placeholder="Enter BPR #"
                                      value={packdata?.fg_bpr}
                                      disabled={
                                        enableCard?.type == 'fg_batch' &&
                                        enableCard?.sub_id == j &&
                                        enableCard?.fg_id == i
                                          ? false
                                          : true
                                      }
                                      onChange={(e) =>
                                        handleOnChange(
                                          e,
                                          log?.id,
                                          index,
                                          j,
                                          'fg_bpr',
                                          'fg',
                                          item?.id,
                                          i,
                                          packdata.id
                                        )
                                      }
                                    />
                                  </FormItem>
                                </Col>
                                <Col xs={24} xl={8} span={8} md={24} sm={24}>
                                  <FormItem
                                    label="FG Allocated Capsuals"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Enter FG Allocated Capsuals'
                                      }
                                    ]}
                                    className={styles.customLable}
                                  >
                                    <Input
                                      placeholder="Enter FG Allocated Capsuals"
                                      value={packdata?.fg_alloc_capsules}
                                      disabled={
                                        enableCard?.type == 'fg_batch' &&
                                        enableCard?.sub_id == j &&
                                        enableCard?.fg_id == i
                                          ? false
                                          : true
                                      }
                                      onChange={(e) =>
                                        handleOnChange(
                                          e,
                                          log?.id,
                                          index,
                                          j,
                                          'fg_alloc_capsules',
                                          'fg',
                                          item?.id,
                                          i,
                                          packdata.id
                                        )
                                      }
                                    />
                                  </FormItem>
                                </Col>
                                <Col xs={24} xl={8} span={8} md={24} sm={24}>
                                  <FormItem
                                    label="Blend Allocation to FG %"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          'Enter Blend Allocation to FG %'
                                      }
                                    ]}
                                    className={styles.customLable}
                                  >
                                    <Input
                                      placeholder="Enter Blend Allocation to FG %"
                                      value={packdata?.blend_alloc_fg}
                                      disabled
                                      onChange={(e) =>
                                        handleOnChange(
                                          e,
                                          log?.id,
                                          index,
                                          j,
                                          'blend_alloc_fg',
                                          'fg',
                                          item?.id,
                                          i,
                                          packdata.id
                                        )
                                      }
                                    />
                                  </FormItem>
                                </Col>
                                <Col xs={24} xl={8} span={8} md={24} sm={24}>
                                  <FormItem
                                    label="WIP Allocation to FG %"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Enter WIP Allocation to FG %'
                                      }
                                    ]}
                                    className={styles.customLable}
                                  >
                                    <Input
                                      placeholder="Enter WIP Allocation to FG %"
                                      value={packdata?.wip_alloc_fg}
                                      disabled
                                      onChange={(e) =>
                                        handleOnChange(
                                          e,
                                          log?.id,
                                          index,
                                          j,
                                          'wip_alloc_fg',
                                          'fg',
                                          item?.id,
                                          i,
                                          packdata.id
                                        )
                                      }
                                    />
                                  </FormItem>
                                </Col>
                              </Row>
                              {enableCard?.type == 'fg_batch' &&
                              enableCard?.sub_id == j &&
                              enableCard?.fg_id == i ? (
                                <FormItem className="text-center">
                                  <Space>
                                    {truckShow ? (
                                      <Image
                                        src={truck}
                                        preview={false}
                                        style={{
                                          width: '100px !important',
                                          height: '100px'
                                        }}
                                      />
                                    ) : (
                                      <CustomButton
                                        type="primary"
                                        onClick={() =>
                                          handleUpdate(log?.id, index)
                                        }
                                      >
                                        Update
                                      </CustomButton>
                                    )}
                                  </Space>
                                </FormItem>
                              ) : (
                                ''
                              )}
                            </Card>
                          </Space>
                        );
                      })}
                      <br />
                      <br />
                      {enableCard?.type == 'wip' && enableCard?.sub_id == j ? (
                        <FormItem className="text-center">
                          <Space>
                            {truckShow ? (
                              <Image
                                src={truck}
                                preview={false}
                                style={{
                                  width: '100px !important',
                                  height: '100px'
                                }}
                              />
                            ) : (
                              <CustomButton
                                type="primary"
                                onClick={() => handleUpdate(log?.id, index)}
                              >
                                Update
                              </CustomButton>
                            )}
                          </Space>
                        </FormItem>
                      ) : (
                        ''
                      )}
                    </Card>
                  </Space>
                );
              })}
            </Card>
          );
        })}

        <br />
        <FormItem className="text-center">
          <Space>
            <CustomButton htmlType="button" onClick={() => history.back()}>
              Cancel
            </CustomButton>
            {truckShow ? (
              <Image
                src={truck}
                preview={false}
                style={{ width: '100px !important', height: '100px' }}
              />
            ) : (
              ''
              // <CustomButton type="primary" htmlType="submit" loading={loading}>
              //   Submit
              // </CustomButton>
            )}
          </Space>
        </FormItem>
      </Form>
    </Layout>
  );
};
export default ViewEditLog;
