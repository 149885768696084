import LocalStorage from './localStorage';

export const getLoggedInUser = () => {
  const loginUser = LocalStorage.getItem('Gravity_user') || '';
  const user = loginUser || { user: null };
  return {
    ...user,
    loggedIn: loginUser !== ''
  };
};

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const blobToFile = (blob, fileName) => {
  blob.lastModified = new Date().getTime();
  blob.name = fileName;

  return blob;
};

export const removeDuplicatesFromArray = (arr, key) => {
  const uniqueArr = [];

  const unique = arr.filter((item) => {
    const isDuplicate = uniqueArr.includes(item[`${key}`]);

    if (!isDuplicate) {
      uniqueArr.push(item[`${key}`]);

      return true;
    }

    return false;
  });

  return unique;
};

export function numFormating(value) {
  var val = Math.abs(value);
  if (val >= 10000000) {
    val = (val / 10000000).toFixed(0) + 'Cr';
  } else if (val >= 100000) {
    val = (val / 100000).toFixed(0) + 'Lac';
  } else if (val >= 1000) {
    val = Math.abs(val)?.toLocaleString(undefined, {
      maximumFractionDigits: 2
    });
  }
  return val;
}

export const weighingRules = (ruleID, no_of_cont, weighing_cont) => {
  if (ruleID == 1) {
    if (weighing_cont == no_of_cont) {
      return true;
    }
  }
  if (ruleID == 2) {
    if (no_of_cont <= 5) {
      if (weighing_cont == no_of_cont) {
        return true;
      }
    }
    if (no_of_cont > 5 && no_of_cont <= 25) {
      if (weighing_cont == 6) {
        return true;
      }
    }
    if (no_of_cont > 25) {
      if (weighing_cont == Math.round(Math.sqrt(no_of_cont)) + 1) {
        console.log(Math.round(Math.sqrt(no_of_cont)));
        return true;
      }
    }
  }
  if (ruleID == 3) {
    if (weighing_cont >= 0) {
      return true;
    }
  }
};

export const weighingRulesCount = (ruleID, no_of_cont) => {
  if (ruleID == 1) {
    return no_of_cont;
  }
  if (ruleID == 2) {
    if (no_of_cont <= 5) {
      return no_of_cont;
    }
    if (no_of_cont > 5 && no_of_cont <= 25) {
      return 6;
    }
    if (no_of_cont > 25) {
      return Math.round(Math.sqrt(no_of_cont) + 1);
    }
  }
  if (ruleID == 3) {
    return 0;
  }
};

export const roles = (role, level) => {
  if (role == 'user' && level !== 3) {
    return 'Warehouse User';
  } else if (role == 'user' && level == 3) {
    return 'Warehouse Lead';
  }
  if (role == 'qc_initiator' && level !== 3) {
    return 'QA User';
  } else if (role == 'qc_initiator' && level == 3) {
    return 'QA Lead';
  }
  if ((role == 'qa-initiator' || role == 'qa_initiator') && level !== 3) {
    return 'QC User';
  } else if (role == 'qa_initiator' && level == 3) {
    return 'QC Lead';
  }
  if (role == 'prod_user' && level !== 3) {
    return 'Production User';
  } else if (role == 'prod_user' && level == 3) {
    return 'Production Lead';
  }
  if (role == 'admin') {
    return 'Admin';
  }
  if (role == 'ppic_user') {
    return 'PPIC User';
  }
};

export const getRoles = (role) => {
  const loginUser = LocalStorage.getItem('Gravity_user') || '';
  const user = loginUser || { user: null };
  const Superadmindata = user?.user?.qa_super_level.filter(
    (item) => item?.department == role
  );
  return {
    Superadmindata
  };
};
