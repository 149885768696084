import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  Card,
  Input,
  Space,
  Image,
  message,
  Select,
  Popconfirm
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import { useDispatch } from 'react-redux';
import { getAllMaterialslist } from '@/features/stockCard/redux/slice';
import {
  CreateMastereBMR,
  getAllProductNames,
  getAllUoms,
  getAllStageslist,
  getAllStageProcesses,
  getEquipaments,
  getAllProcesses,
  getAllExecutionDetails
} from '../../redux/slice';
import truck from '@/features/qa/components/images/truck-delivery-done.gif';
import Layout from '@/common/Layout';
import {
  CopyOutlined,
  DeleteOutlined,
  EditFilled,
  PlusCircleOutlined
} from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import CustomModal from '@/common/CustomModal';
import { getLoggedInUser } from 'utilities/helpers';
// const { Option } = Select;
const { TextArea } = Input;

const createEBMRMaster = () => {
  const [form] = Form.useForm();
  const params = useParams();
  const { user } = getLoggedInUser();

  const dispatch = useDispatch();
  const [truckShow, setTruckShow] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [execDetailsList, setExecDetailsList] = useState([]);
  const [exeName, setExeName] = useState('');
  const [productDetailsdata, setProductDetailsdata] = useState([
    { process_name: '' }
  ]);
  const [execDetailsdata, setExecDetailsdata] = useState([
    {
      exe_name: '',
      type: ''
    }
  ]);
  const [equipments, setEquipments] = useState([
    {
      equipment_name: '',
      eqp_model: [
        {
          equipment_id: '',
          equipment_capacity: '',
          add_parameters: [],
          add_accessories: []
        }
      ]
    }
  ]);
  const onFinish = (values) => {
    params?.id ? (values['id'] = params?.id) : '';
    values['process_masters'] = productDetailsdata;
    values['exe_details'] = execDetailsdata;
    values['eqp_details'] = equipments;
    dispatch(CreateMastereBMR(values)).then((response) => {
      if (response?.payload?.success) {
        setTruckShow(false);
        params?.id ? handleGetEbmrByID() : '';
        message.success(response?.payload?.message);
        history.push(`/ebmr-master-list`);
      } else {
        setTruckShow(false);
      }
    });
    console.log(values);
  };

  useEffect(() => {
    handleGetAllMaterialsList();
    handleGetEquipmets();
  }, []);

  useEffect(() => {
    handleGetProductNames();
    handleGetuoms();
    handleGetStages();
    handleGetStageProcess();
    handleGetAllexecutionDetails();
  }, [exeName]);

  const handleGetProductNames = () => {
    dispatch(getAllProductNames());
  };
  const handleGetStages = () => {
    dispatch(getAllStageslist()).then((response) => {
      if (response?.payload?.success) {
        // setStagesList(response?.payload?.data);
      }
    });
  };

  const handleGetEquipmets = () => {
    dispatch(getEquipaments());
  };

  const handleClose = () => {
    setIsModal(false);
  };

  const handleGetStageProcess = () => {
    dispatch(getAllStageProcesses()).then((response) => {
      if (response?.payload?.success) {
        // setStagesProcesses(response?.payload?.data);
      }
    });
  };
  const handleGetAllMaterialsList = () => {
    const payload = {};
    dispatch(getAllMaterialslist(payload));
  };

  const handleGetuoms = () => {
    const payload = {};
    dispatch(getAllUoms(payload)).then((response) => {
      if (response?.payload?.success) {
        // setUoms(response?.payload?.data);
      }
    });
  };

  const handleGetAllexecutionDetails = () => {
    const payload = { search: exeName };
    dispatch(getAllExecutionDetails(payload)).then((response) => {
      if (response?.payload?.success) {
        setExecDetailsList(response?.payload?.data);
      }
    });
  };

  useEffect(() => {
    params.id ? handleGetEbmrByID() : '';
  }, [truckShow]);

  const handleGetEbmrByID = () => {
    let payload = { id: params.id };
    dispatch(getAllProcesses(payload)).then((response) => {
      if (response?.payload?.success) {
        form.setFieldsValue({
          process_group_name: response?.payload?.data?.[0]?.process_group_name
        });
        setProductDetailsdata(
          response?.payload?.data?.[0]?.bom_process_masters?.map((item) => ({
            process_id: item?.id,
            process_name: item.process_name
          }))
        );
        setExecDetailsdata(
          response?.payload?.data?.[0]?.execution_details?.map((item) => ({
            exe_id: item?.id,
            exe_name: item.exe_name,
            exe_type: item.exe_type
          }))
        );
        setEquipments(
          response?.payload?.data?.[0]?.equipment_masters?.map((item) => ({
            eqp_details_id: item?.id,
            equipment_name: item.equipment_name,
            eqp_model: item.equipment_models?.map((item) => ({
              eqp_model_id: item?.id,
              equipment_id: item.equipment_id,
              equipment_capacity: item.equipment_capacity,
              add_parameters: item.paramater_type_masters?.map((item) => ({
                parameter_id: item?.id,
                parameter_type: item?.parameter_type
              })),
              add_accessories: item.accessory_masters?.map((item) => ({
                accessory_id: item?.id,
                accessory_name: item?.accessory_name,
                accesory_sub_masters: item?.accessory_sub_masters?.map(
                  (item) => ({
                    access_sub_master_id: item?.id,
                    accessory_type: item?.accessory_type
                  })
                )
              }))
            }))
          }))
        );
      }
    });
  };
  const handleAdd = () => {
    let list = [...productDetailsdata];
    list.push({
      process_name: ''
    });
    setProductDetailsdata(list);
  };
  const handleRemove = (index) => {
    let list = [...productDetailsdata];
    list.splice(index, 1);
    setProductDetailsdata(list);
  };

  const handleAddExec = () => {
    let list = [...execDetailsdata];
    list.push({
      exe_name: '',
      exe_type: ''
    });
    setExecDetailsdata(list);
  };

  const handleRemoveExec = (index) => {
    let list = [...execDetailsdata];
    list.splice(index, 1);
    setExecDetailsdata(list);
  };

  const handleChangeDetails = (e, index) => {
    let list = [...productDetailsdata];
    list[index].process_name = e.target.value;
    setProductDetailsdata(list);
  };

  const handleChangeExeDetails = (e, index, type) => {
    let list = [...execDetailsdata];
    list[index][type] = e;
    setExecDetailsdata(list);
  };

  const handleAddEquip = () => {
    let list = [...equipments];
    list.push({
      equipment_name: '',
      eqp_model: [
        {
          equipment_id: '',
          equipment_capacity: '',
          add_parameters: [],
          add_accessories: []
        }
      ]
    });
    setEquipments(list);
  };

  const handleRemoveEquip = (index) => {
    let list = [...equipments];
    list.splice(index, 1);
    setEquipments(list);
  };

  const handleChangeEquip = (e, index) => {
    let list = [...equipments];
    list[index].equipment_name = e.target.value;
    setEquipments(list);
  };

  const handleAddEqpModel = (index) => {
    let list = [...equipments];
    list[index].eqp_model.push({
      equipment_id: '',
      equipment_capacity: '',
      add_parameters: [],
      add_accessories: []
    });
    setEquipments(list);
  };

  const onSearchExecution = (e) => {
    setExeName(e);
  };
  const handleRemoveEqpModel = (index, index1) => {
    let list = [...equipments];
    list[index].eqp_model.splice(index1, 1);
    setEquipments(list);
  };

  const handleAddParams = (index, index1) => {
    let list = [...equipments];
    list[index].eqp_model[index1].add_parameters.push({
      parameter_type: ''
    });
    setEquipments(list);
  };

  const handleCopyParams = (index) => {
    let list = [...equipments];
    console.log(list);
    const duplicatedUsers = list[index].eqp_model[0].add_parameters;
    list[index].eqp_model.map((item, index) => {
      index > 0 ? (item.add_parameters = []) : '';
    });
    setEquipments(list);
    list[index].eqp_model.map((item, index) => {
      index > 0
        ? (item.add_parameters = [...item.add_parameters, ...duplicatedUsers])
        : '';
    });
    setEquipments(list);
  };

  const handleCopyAccess = (index) => {
    let list = [...equipments];
    const duplicatedUsers = list[index].eqp_model[0].add_accessories;
    list[index].eqp_model.map((item, index) => {
      index > 0 ? (item.add_accessories = []) : '';
    });
    setEquipments(list);
    list[index].eqp_model.map((item, index) => {
      index > 0
        ? (item.add_accessories = [...item.add_accessories, ...duplicatedUsers])
        : '';
    });
    setEquipments(list);
  };

  const handleRemoveParam = (index, index1, index2) => {
    let list = [...equipments];
    list[index].eqp_model[index1].add_parameters.splice(index2, 1);
    setEquipments(list);
  };

  const handleAddAccessories = (index, index1) => {
    let list = [...equipments];
    list[index].eqp_model[index1].add_accessories.push({
      accessory_name: '',
      accesory_sub_masters: []
    });
    setEquipments(list);
  };

  const handleRemoveAccessory = (index, index1, index2) => {
    let list = [...equipments];
    list[index].eqp_model[index1].add_accessories.splice(index2, 1);
    setEquipments(list);
  };

  const handleRemoveSubAccessory = (index, index1, index2, index3) => {
    let list = [...equipments];
    list[index].eqp_model[index1].add_accessories[
      index2
    ].accesory_sub_masters.splice(index3, 1);
    setEquipments(list);
  };

  const handleChangeEquipModel = (e, index, index1, type) => {
    let list = [...equipments];
    list[index].eqp_model[index1][type] = e.target.value;
    setEquipments(list);
  };

  const handleChangeParams = (e, index, index1, index2, index3, type) => {
    let list = [...equipments];
    if (type === 'param') {
      list[index].eqp_model[index1].add_parameters[index2].parameter_type =
        e.target.value;
    } else if (type === 'accessory') {
      list[index].eqp_model[index1].add_accessories[index2].accessory_name =
        e.target.value;
    } else if (type === 'accessory_type') {
      list[index].eqp_model[index1].add_accessories[
        index2
      ].accesory_sub_masters[index3].accessory_type = e.target.value;
    }

    setEquipments(list);
  };

  const handleAddSubaccessories = (index, index1, index2) => {
    console.log(equipments, index, index1, index2);
    let list = [...equipments];
    list[index].eqp_model[index1].add_accessories[
      index2
    ].accesory_sub_masters.push({
      accessory_type: ''
    });
    setEquipments(list);
  };

  const handleDelete = (e, type) => {
    console.log(e);
    const payload = {
      id: e,
      type: 'delete',
      delete_type: type
    };
    dispatch(CreateMastereBMR(payload)).then((response) => {
      if (response?.payload?.success) {
        setTruckShow(false);
        message.success(response?.payload?.message);
        handleGetEbmrByID();
        type !== 'bom_group_master'
          ? history.push(`/ebmr-masters-create/${params.id}`)
          : history.push(`/ebmr-master-list`);
      } else {
        setTruckShow(false);
      }
    });
  };

  console.log(equipments, setTruckShow);
  return (
    <Layout sider={false}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Card
          title={'ADD NEW EBMR'}
          className={styles.CustomPanel}
          extra={
            params?.id && user?.role == 'prod_user' ? (
              <Space>
                <Popconfirm
                  title="Are you sure you want to delete this Process Group?"
                  description="Are you sure you want to delete this Process Group?"
                  onConfirm={() => handleDelete(params?.id, 'bom_group_master')}
                  // onCancel={cancel}
                  okText="Delete"
                  cancelText="Cancel"
                >
                  <DeleteOutlined />
                </Popconfirm>
                <EditFilled onClick={() => setIsEdit(!isEdit)} />
              </Space>
            ) : (
              ''
            )
          }
        >
          <Row gutter={24}>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Process Group Name"
                name="process_group_name"
                rules={[
                  {
                    required: false,
                    message: 'Enter Theoretical Yield/Batch Size'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter Product Name"
                  disabled={params?.id && !isEdit}
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <Card className={styles.CustomPanel} title="Process Name">
                {productDetailsdata?.map((item, index) => {
                  return (
                    <Row gutter={24} key={index}>
                      <Col xs={24} xl={22} span={12} md={24} sm={24}>
                        <FormItem
                          label="Process Name"
                          // name="shelf_life"
                          rules={[
                            {
                              required: true,
                              message: 'Enter Shelf life'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Input
                            placeholder="Enter Product Details"
                            onChange={(e) => handleChangeDetails(e, index)}
                            value={item.process_name}
                            disabled={params?.id && !isEdit}
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={2} span={12} md={24} sm={24}>
                        {index === 0 ? (
                          <PlusCircleOutlined
                            onClick={() => handleAdd()}
                            className={styles.plusCircle1}
                          />
                        ) : !item?.process_id ? (
                          <DeleteOutlined
                            onClick={() => handleRemove(index)}
                            className={styles.plusCircle1}
                          />
                        ) : user?.role == 'prod_user' && user?.level == 3 ? (
                          <Popconfirm
                            title="Are you sure you want to delete this Process ?"
                            description="Are you sure you want to delete this Process?"
                            onConfirm={() =>
                              handleDelete(item?.process_id, 'process_master')
                            }
                            className={styles.plusCircle1}
                            // onCancel={cancel}
                            okText="Delete"
                            cancelText="Cancel"
                          >
                            <DeleteOutlined />
                          </Popconfirm>
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  );
                })}
              </Card>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <Card className={styles.CustomPanel} title="Execution Details">
                {execDetailsdata?.map((item, index) => {
                  return (
                    <Row gutter={24} key={index}>
                      <Col xs={24} xl={12} span={12} md={24} sm={24}>
                        <FormItem
                          label="Execution Details"
                          // name="shelf_life"
                          rules={[
                            {
                              required: true,
                              message: 'Enter Shelf life'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Select
                            onChange={(e) =>
                              handleChangeExeDetails(e, index, 'exe_name')
                            }
                            placeholder="Enter execution Details"
                            showSearch
                            optionFilterProp="children"
                            // onChange={onChangeBatch}
                            onSearch={onSearchExecution}
                          >
                            {execDetailsList?.map((exe, i) => (
                              <option value={exe?.exe_details_name} key={i}>
                                {exe.exe_details_name}
                              </option>
                            ))}
                          </Select>
                          {/* <Input
                            placeholder="Enter Product Details"
                            
                            value={item.exe_name}
                            disabled={params?.id && !isEdit}
                          /> */}
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={10} span={12} md={24} sm={24}>
                        <FormItem
                          label="Type"
                          // name="shelf_life"
                          rules={[
                            {
                              required: true,
                              message: 'Enter Shelf life'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Select
                            placeholder="Select Type"
                            onChange={(e) =>
                              handleChangeExeDetails(e, index, 'exe_type')
                            }
                            value={item?.exe_type}
                            disabled={params?.id && !isEdit}
                          >
                            <option value="Input">Alpha Numeric</option>
                            <option value="Date&Time">Date & Time</option>
                            <option value="Date">Date</option>
                            <option value="Sign&Date">User Sign & Date</option>
                          </Select>
                          {/* </FormItem> */}
                          {/* <Input
                            placeholder="Enter Product Details"
                            onChange={(e) =>
                              handleChangeExeDetails(e, index, 'type')
                            }
                            value={item.type}
                          /> */}
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={2} span={12} md={24} sm={24}>
                        {index === 0 ? (
                          <PlusCircleOutlined
                            onClick={() => handleAddExec()}
                            className={styles.plusCircle1}
                          />
                        ) : item?.exe_id &&
                          user?.role == 'prod_user' &&
                          user?.level == 3 ? (
                          <Popconfirm
                            title="Are you sure you want to delete this Execution details ?"
                            description="Are you sure you want to delete this Execution details?"
                            onConfirm={() =>
                              handleDelete(item?.exe_id, 'execution_details')
                            }
                            className={styles.plusCircle1}
                            // onCancel={cancel}
                            okText="Delete"
                            cancelText="Cancel"
                          >
                            <DeleteOutlined />
                          </Popconfirm>
                        ) : (
                          <DeleteOutlined
                            onClick={() => handleRemoveExec(index)}
                            className={styles.plusCircle1}
                          />
                        )}
                      </Col>
                    </Row>
                  );
                })}
              </Card>
            </Col>
          </Row>
          <br />
          <Card title={'Equipments'} className={styles.CustomPanel}>
            {equipments?.map((item, index) => {
              return (
                <>
                  <Card className={styles.customLable} key={index}>
                    <Row gutter={24}>
                      <Col xs={24} xl={12} span={12} md={24} sm={24}>
                        <FormItem
                          label="Equipment Name"
                          rules={[
                            {
                              required: false,
                              message: 'Enter Theoretical Yield/Batch Size'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Input
                            placeholder="Enter Equipment Name"
                            value={item?.equipment_name}
                            onChange={(e) => handleChangeEquip(e, index)}
                            disabled={params?.id && !isEdit}
                          />
                        </FormItem>
                      </Col>

                      <Col xs={24} xl={12} span={12} md={24} sm={24}>
                        {index === 0 ? (
                          <PlusCircleOutlined
                            className={styles.plusCircle1}
                            style={{ float: 'right' }}
                            onClick={() => handleAddEquip()}
                          />
                        ) : item?.eqp_details_id &&
                          user?.role == 'prod_user' &&
                          user?.level == 3 ? (
                          <Popconfirm
                            title="Are you sure you want to delete this Equipment details ?"
                            description="Are you sure you want to delete this Equipment Details?"
                            onConfirm={() =>
                              handleDelete(item?.eqp_details_id, 'eqp_master')
                            }
                            className={styles.plusCircle1}
                            // onCancel={cancel}
                            okText="Delete"
                            cancelText="Cancel"
                          >
                            <DeleteOutlined />
                          </Popconfirm>
                        ) : (
                          <DeleteOutlined
                            className={styles.plusCircle1}
                            style={{ float: 'right' }}
                            onClick={() => handleRemoveEquip(index)}
                          />
                        )}
                      </Col>
                    </Row>
                    {item?.eqp_model?.map((item1, index1) => {
                      return (
                        <>
                          <Card className={styles.customLable} key={index1}>
                            <Row gutter={24}>
                              <Col xs={24} xl={8} span={12} md={24} sm={24}>
                                <FormItem
                                  label="Equipment Model Name"
                                  rules={[
                                    {
                                      required: false,
                                      message:
                                        'Enter Theoretical Yield/Batch Size'
                                    }
                                  ]}
                                  className={styles.customLable}
                                >
                                  <Input
                                    placeholder="Enter Equipment Model Name"
                                    value={item1?.equipment_capacity}
                                    disabled={params?.id && !isEdit}
                                    onChange={(e) =>
                                      handleChangeEquipModel(
                                        e,
                                        index,
                                        index1,
                                        'equipment_capacity'
                                      )
                                    }
                                  />
                                </FormItem>
                              </Col>
                              <Col xs={24} xl={8} span={12} md={24} sm={24}>
                                <FormItem
                                  label="Equipment Model ID"
                                  rules={[
                                    {
                                      required: false,
                                      message:
                                        'Enter Theoretical Yield/Batch Size'
                                    }
                                  ]}
                                  className={styles.customLable}
                                >
                                  <Input
                                    placeholder="Enter Equipment Model ID"
                                    value={item1?.equipment_id}
                                    disabled={params?.id && !isEdit}
                                    onChange={(e) =>
                                      handleChangeEquipModel(
                                        e,
                                        index,
                                        index1,
                                        'equipment_id'
                                      )
                                    }
                                  />
                                </FormItem>
                              </Col>
                              <Col xs={24} xl={1} span={12} md={24} sm={24}>
                                {index1 === 0 ? (
                                  <PlusCircleOutlined
                                    onClick={() => handleAddEqpModel(index)}
                                    className={styles.plusCircle1}
                                  />
                                ) : item1?.eqp_model_id &&
                                  user?.role == 'prod_user' &&
                                  user?.level == 3 ? (
                                  <Popconfirm
                                    title="Are you sure you want to delete this Equipment Model ?"
                                    description="Are you sure you want to delete this Equipment Model?"
                                    onConfirm={() =>
                                      handleDelete(
                                        item1?.eqp_model_id,
                                        'eqp_model'
                                      )
                                    }
                                    className={styles.plusCircle1}
                                    // onCancel={cancel}
                                    okText="Delete"
                                    cancelText="Cancel"
                                  >
                                    <DeleteOutlined />
                                  </Popconfirm>
                                ) : (
                                  <DeleteOutlined
                                    onClick={() =>
                                      handleRemoveEqpModel(index, index1)
                                    }
                                    className={styles.plusCircle1}
                                  />
                                )}
                              </Col>
                              {index1 === 0 ? (
                                <Col xs={24} xl={6} span={12} md={24} sm={24}>
                                  <Space>
                                    <CustomButton
                                      size="small"
                                      className={styles.plusCircle2}
                                      onClick={() =>
                                        handleAddParams(index, index1)
                                      }
                                    >
                                      Add Parameters
                                    </CustomButton>
                                    <CustomButton
                                      size="small"
                                      className={styles.plusCircle2}
                                      onClick={() =>
                                        handleAddAccessories(index, index1)
                                      }
                                    >
                                      Add Accessory
                                    </CustomButton>
                                  </Space>
                                </Col>
                              ) : (
                                ''
                              )}
                            </Row>
                            <Row gutter={24}>
                              {item1?.add_parameters?.length ? (
                                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                                  <Card
                                    className={styles.CustomPanel}
                                    key={index1}
                                    title="Parameters"
                                    extra={
                                      index1 === 0 ? (
                                        <Space>
                                          <CopyOutlined
                                            onClick={() =>
                                              handleCopyParams(index, index1)
                                            }
                                          />
                                          <PlusCircleOutlined
                                            onClick={() =>
                                              handleAddParams(index, index1)
                                            }
                                          />
                                        </Space>
                                      ) : (
                                        ''
                                      )
                                    }
                                  >
                                    <Row gutter={24}>
                                      {item1?.add_parameters?.map(
                                        (item2, index2) => {
                                          return (
                                            <>
                                              <Col
                                                xs={24}
                                                xl={10}
                                                span={12}
                                                md={24}
                                                sm={24}
                                                key={index2}
                                              >
                                                <FormItem
                                                  label="Parameter type"
                                                  rules={[
                                                    {
                                                      required: false,
                                                      message:
                                                        'Enter Theoretical Yield/Batch Size'
                                                    }
                                                  ]}
                                                  className={styles.customLable}
                                                >
                                                  <Input
                                                    placeholder="Enter Parameter Type"
                                                    value={
                                                      item2?.parameter_type
                                                    }
                                                    disabled={
                                                      (params?.id && !isEdit) ||
                                                      index1 > 0
                                                    }
                                                    onChange={(e) =>
                                                      handleChangeParams(
                                                        e,
                                                        index,
                                                        index1,
                                                        index2,
                                                        '',
                                                        'param'
                                                      )
                                                    }
                                                  />
                                                </FormItem>
                                              </Col>
                                              {index1 === 0 ? (
                                                <Col
                                                  xs={24}
                                                  xl={2}
                                                  span={12}
                                                  md={24}
                                                  sm={24}
                                                  key={index2}
                                                >
                                                  {item2?.param_id &&
                                                  user?.role == 'prod_user' &&
                                                  user?.level == 3 ? (
                                                    <Popconfirm
                                                      title="Are you sure you want to delete this Parameter ?"
                                                      description="Are you sure you want to delete this Parameter?"
                                                      onConfirm={() =>
                                                        handleDelete(
                                                          item1?.param_id,
                                                          'parameter_master'
                                                        )
                                                      }
                                                      className={
                                                        styles.plusCircle1
                                                      }
                                                      // onCancel={cancel}
                                                      okText="Delete"
                                                      cancelText="Cancel"
                                                    >
                                                      <DeleteOutlined />
                                                    </Popconfirm>
                                                  ) : (
                                                    <DeleteOutlined
                                                      className={
                                                        styles.plusCircle2
                                                      }
                                                      onClick={() =>
                                                        handleRemoveParam(
                                                          index,
                                                          index1,
                                                          index2
                                                        )
                                                      }
                                                    />
                                                  )}
                                                </Col>
                                              ) : (
                                                ''
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    </Row>
                                  </Card>
                                </Col>
                              ) : (
                                ''
                              )}
                              {item1?.add_accessories?.length ? (
                                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                                  <Card
                                    className={styles.CustomPanel}
                                    key={index1}
                                    title="Accessories"
                                    extra={
                                      index1 === 0 ? (
                                        <Space>
                                          <CopyOutlined
                                            onClick={() =>
                                              handleCopyAccess(index, index1)
                                            }
                                          />
                                          <PlusCircleOutlined
                                            onClick={() =>
                                              handleAddAccessories(
                                                index,
                                                index1
                                              )
                                            }
                                          />
                                        </Space>
                                      ) : (
                                        ''
                                      )
                                    }
                                  >
                                    <Row gutter={24}>
                                      {item1?.add_accessories?.map(
                                        (item2, index2) => {
                                          return (
                                            <>
                                              <Col
                                                xs={24}
                                                xl={index2 > 0 ? 17 : 20}
                                                span={12}
                                                md={24}
                                                sm={24}
                                                key={index2}
                                              >
                                                <FormItem
                                                  label="Accessory Name"
                                                  rules={[
                                                    {
                                                      required: false,
                                                      message:
                                                        'Enter Theoretical Yield/Batch Size'
                                                    }
                                                  ]}
                                                  className={styles.customLable}
                                                >
                                                  <Input
                                                    placeholder="Enter Accessory Name"
                                                    value={
                                                      item2?.accessory_name
                                                    }
                                                    disabled={
                                                      (params?.id && !isEdit) ||
                                                      index1 > 0
                                                    }
                                                    onChange={(e) =>
                                                      handleChangeParams(
                                                        e,
                                                        index,
                                                        index1,
                                                        index2,
                                                        '',
                                                        'accessory'
                                                      )
                                                    }
                                                  />
                                                </FormItem>
                                              </Col>

                                              {index1 === 0 ? (
                                                <Col
                                                  xs={24}
                                                  xl={index2 > 0 ? 7 : 4}
                                                  span={12}
                                                  md={24}
                                                  sm={24}
                                                  key={index2}
                                                  style={{
                                                    paddingLeft:
                                                      '0px !important'
                                                  }}
                                                >
                                                  <Space>
                                                    <CustomButton
                                                      onClick={() =>
                                                        handleAddSubaccessories(
                                                          index,
                                                          index1,
                                                          index2
                                                        )
                                                      }
                                                      size="small"
                                                      className={
                                                        styles.plusCircle2
                                                      }
                                                    >
                                                      Add Type
                                                    </CustomButton>
                                                    {index2 > 0 ? (
                                                      item2?.accessory_id &&
                                                      user?.role ==
                                                        'prod_user' &&
                                                      user?.level == 3 ? (
                                                        <Popconfirm
                                                          title="Are you sure you want to delete this Accessory ?"
                                                          description="Are you sure you want to delete this Accessory?"
                                                          onConfirm={() =>
                                                            handleDelete(
                                                              item2?.accessory_id,
                                                              'accessory_master'
                                                            )
                                                          }
                                                          className={
                                                            styles.plusCircle1
                                                          }
                                                          // onCancel={cancel}
                                                          okText="Delete"
                                                          cancelText="Cancel"
                                                        >
                                                          <DeleteOutlined />
                                                        </Popconfirm>
                                                      ) : (
                                                        <DeleteOutlined
                                                          className={
                                                            styles.plusCircle2
                                                          }
                                                          onClick={() =>
                                                            handleRemoveAccessory(
                                                              index,
                                                              index1,
                                                              index2
                                                            )
                                                          }
                                                        />
                                                      )
                                                    ) : (
                                                      ''
                                                    )}
                                                  </Space>
                                                </Col>
                                              ) : (
                                                ''
                                              )}
                                              {item2?.accesory_sub_masters
                                                ?.length
                                                ? item2?.accesory_sub_masters?.map(
                                                    (item3, index3) => {
                                                      return (
                                                        <>
                                                          <Col
                                                            xs={24}
                                                            xl={10}
                                                            span={12}
                                                            md={24}
                                                            sm={24}
                                                            key={index3}
                                                          >
                                                            <FormItem
                                                              label="Accessory Type"
                                                              rules={[
                                                                {
                                                                  required: false,
                                                                  message:
                                                                    'Enter Theoretical Yield/Batch Size'
                                                                }
                                                              ]}
                                                              className={
                                                                styles.customLable
                                                              }
                                                            >
                                                              <Input
                                                                placeholder="Enter Accessory Type"
                                                                value={
                                                                  item3?.accessory_type
                                                                }
                                                                disabled={
                                                                  (params?.id &&
                                                                    !isEdit) ||
                                                                  index1 > 0
                                                                }
                                                                onChange={(e) =>
                                                                  handleChangeParams(
                                                                    e,
                                                                    index,
                                                                    index1,
                                                                    index2,
                                                                    index3,
                                                                    'accessory_type'
                                                                  )
                                                                }
                                                              />
                                                            </FormItem>
                                                          </Col>
                                                          {index1 === 0 ? (
                                                            <Col
                                                              xs={24}
                                                              xl={2}
                                                              span={12}
                                                              md={24}
                                                              sm={24}
                                                            >
                                                              {item3?.access_sub_master_id &&
                                                              user?.role ==
                                                                'prod_user' &&
                                                              user?.level ==
                                                                3 ? (
                                                                <Popconfirm
                                                                  title="Are you sure you want to delete this Accessory type?"
                                                                  description="Are you sure you want to delete this Accessory type?"
                                                                  onConfirm={() =>
                                                                    handleDelete(
                                                                      item3?.access_sub_master_id,
                                                                      'accessory_sub_master'
                                                                    )
                                                                  }
                                                                  className={
                                                                    styles.plusCircle1
                                                                  }
                                                                  // onCancel={cancel}
                                                                  okText="Delete"
                                                                  cancelText="Cancel"
                                                                >
                                                                  <DeleteOutlined />
                                                                </Popconfirm>
                                                              ) : (
                                                                <DeleteOutlined
                                                                  className={
                                                                    styles.plusCircle2
                                                                  }
                                                                  onClick={() =>
                                                                    handleRemoveSubAccessory(
                                                                      index,
                                                                      index1,
                                                                      index2,
                                                                      index3
                                                                    )
                                                                  }
                                                                />
                                                              )}
                                                            </Col>
                                                          ) : (
                                                            ''
                                                          )}{' '}
                                                        </>
                                                      );
                                                    }
                                                  )
                                                : ''}
                                            </>
                                          );
                                        }
                                      )}
                                    </Row>
                                  </Card>
                                </Col>
                              ) : (
                                ''
                              )}
                            </Row>
                          </Card>
                          <br />
                        </>
                      );
                    })}
                  </Card>
                  <br />
                </>
              );
            })}
          </Card>
        </Card>
        <br />

        <FormItem className="text-center">
          <Space>
            {truckShow ? (
              <Image
                src={truck}
                preview={false}
                style={{ width: '100px !important', height: '100px' }}
              />
            ) : params?.id && user?.role == 'prod_user' && user?.level == 3 ? (
              <CustomButton
                className={styles.inwardButton}
                // htmlType="submit"
                type="primary"
                onClick={() => setIsModal(true)}
              >
                Update
              </CustomButton>
            ) : !params?.id ? (
              <CustomButton
                className={styles.inwardButton}
                htmlType="submit"
                type="primary"
              >
                Create
              </CustomButton>
            ) : (
              ''
            )}
            <CustomButton
              className={styles.inwardButton}
              type="primary"
              ghost
              onClick={() => history.back()}
            >
              Back
            </CustomButton>
          </Space>
        </FormItem>
      </Form>
      {isModal ? (
        <CustomModal
          title="Verification"
          visible={isModal}
          width="50%"
          footer={null}
          className={styles.customModal}
          closable={false}
        >
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem
                  label="User ID"
                  name="employee_id"
                  rules={[
                    {
                      required: true,
                      message: 'Enter User ID'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input placeholder="Enter User ID" />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Password'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input.Password placeholder="Enter Password" />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem
                  label="Comments"
                  name="comments"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Comments'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <TextArea placeholder="Enter Comments" />
                </FormItem>
              </Col>
            </Row>
            <FormItem className="text-center">
              <Space>
                <CustomButton
                  className={styles.inwardButton}
                  onClick={handleClose}
                  type="primary"
                  size="small"
                  ghost
                >
                  Close
                </CustomButton>
                <CustomButton
                  className={styles.inwardButton}
                  htmlType="submit"
                  type="primary"
                  size="small"
                >
                  Verify
                </CustomButton>
              </Space>
            </FormItem>
          </Form>
        </CustomModal>
      ) : (
        ''
      )}
    </Layout>
  );
};
export default createEBMRMaster;
