import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ForgotPasswordPage from './components/ForgotPasswordPage';
import { forgotPassword } from './redux/slice';
import { message } from 'antd';
import { history } from 'app/history';

const ForgotPassword = () => {
  const { loading } = useSelector((state) => state.login);
  const dispatch = useDispatch();

  const onSubmit = (payload) => {
    dispatch(forgotPassword(payload)).then((response) => {
      if (response?.payload?.success) {
        message.success(response?.payload?.message);
        history.push('/login');
      }
    });
  };

  return (
    <>
      <ForgotPasswordPage loading={loading} onSubmit={onSubmit} />
    </>
  );
};

export default ForgotPassword;
