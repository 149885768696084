import React, { useEffect } from 'react';
import Layout from '@/common/Layout';
import { Row, Col, Form, Card, Input, message } from 'antd';
import styles from '@/common/Layout/index.module.less';
import { getDispenseMaterialDetails } from '@/features/dispensing/redux/slice';
import FormItem from '@/common/FormItem';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import CustomButton from '@/common/CustomButton';
import { useState } from 'react';
import { createYieldReconcilation } from '../../redux/slice';
import { history } from 'app/history';

const yieldReconcilation = () => {
  const { materialDetails } = useSelector((state) => state.dispensing);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const params = useParams();

  const [rejectTotal, setRejectTotal] = useState(0);
  const [otherKg, setOtherKg] = useState(0);
  const [unaccountable, setUnaccountable] = useState(0);
  const [reconcilation, setReconcilation] = useState(0);
  const [theoretical, setTheoretical] = useState(0);
  const [actualYield, setActualYield] = useState(0);
  const [sampleQty, setSampleQty] = useState(0);
  const [percent, setPercent] = useState(0);
  useEffect(() => {
    handleGetAllMaterials();
  }, []);

  const handleGetAllMaterials = () => {
    let payload = {
      id: params.id
    };
    dispatch(getDispenseMaterialDetails(payload)).then((response) => {
      if (response?.payload?.success) {
        let theoretical_yield =
          response?.payload?.data?.product_detail
            ?.yield_reconsilation_theoritical;
        let actual_yield =
          response?.payload?.data?.blendings?.[0]?.total_net_weight;
        let sample_qty =
          response?.payload?.data?.samplings?.[0]?.total_sample_qty;
        let sift_left_over =
          response?.payload?.data?.yield_sifted_milleds?.[0]
            ?.total_net_leftover;
        let extra_left_over = 0;
        response?.payload?.data?.extra_granular_containers.map((item) => {
          extra_left_over +=
            parseFloat(item?.sift_left_over_net_weight) +
            parseFloat(item?.blending_left_over_net_weight);
        });
        let blending_left =
          response?.payload?.data?.blendings?.[0]?.total_net_leftover;
        console.log(blending_left);
        setRejectTotal(
          parseFloat(sift_left_over) +
            parseFloat(extra_left_over) +
            parseFloat(blending_left)
        );
        let unaccountable =
          parseFloat(theoretical_yield) -
          (parseFloat(actual_yield) +
            parseFloat(sample_qty) +
            parseFloat(sift_left_over) +
            parseFloat(extra_left_over) +
            parseFloat(blending_left));
        setTheoretical(theoretical_yield);
        setActualYield(actual_yield);
        setSampleQty(sample_qty);
        setPercent(
          (parseFloat(actual_yield) / parseFloat(theoretical_yield)) * 100
        );
        setUnaccountable(unaccountable);
        setReconcilation(
          ((parseFloat(actual_yield) +
            parseFloat(sample_qty) +
            parseFloat(sift_left_over) +
            parseFloat(extra_left_over) +
            parseFloat(blending_left) +
            parseFloat(otherKg) +
            parseFloat(unaccountable)) /
            parseFloat(theoretical_yield)) *
            100
        );
      }
    });
  };

  const onFinish = (values) => {
    const payload = {
      theoretical_yield: theoretical,
      actual_yield: actualYield,
      yield_percent: percent,
      reject: rejectTotal,
      others_remarks: values?.others_remarks,
      others_kgs: otherKg,
      unaccountable_loss: unaccountable,
      reconsiliation_kgs: reconcilation,
      sample_qty: sampleQty,
      dispensing_request_id: params.id
    };
    dispatch(createYieldReconcilation(payload)).then((response) => {
      if (response?.payload?.success) {
        message.success(response?.payload.message);
        history.push(`/blend-distrubution/${params.id}`);
      }
    });
    console.log(payload);
  };

  const handleChangeOther = (e) => {
    setOtherKg(e);
    setUnaccountable(
      parseFloat(
        materialDetails?.product_detail?.yield_reconsilation_theoritical
      ) -
        (parseFloat(materialDetails?.blendings?.[0]?.total_net_weight) +
          parseFloat(materialDetails?.samplings?.[0]?.total_sample_qty) +
          parseFloat(rejectTotal) +
          parseFloat(e))
    );
    console.log(otherKg);
  };
  return (
    <Layout sider={false}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
      >
        <Card
          title={
            <span style={{ fontWeight: 'bold' }}>Yield & Reconcilation</span>
          }
          className={styles.CustomPanel}
        >
          <Card>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem label="Product Name" className={styles.customLable}>
                  <Input
                    disabled
                    value={materialDetails?.product_detail?.name || 'NA'}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="Product Batch Number"
                  className={styles.customLable}
                >
                  <Input
                    disabled
                    value={materialDetails?.product_batch_number || 'NA'}
                  />
                </FormItem>
              </Col>

              <Col span={12}>
                <FormItem label="Effective Date" className={styles.customLable}>
                  <Input
                    disabled
                    value={
                      materialDetails?.effective_date
                        ? moment(materialDetails?.effective_date).format(
                            'DD MMM YYYY'
                          )
                        : 'NA'
                    }
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="Batch Size" className={styles.customLable}>
                  <Input
                    disabled
                    value={materialDetails?.product_detail?.batch_size || 'NA'}
                  />
                </FormItem>
              </Col>
            </Row>
          </Card>
          <Card>
            <Row gutter={24}>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Theoretical Yield"
                  rules={[
                    {
                      required: false,
                      message: 'Enter Theoretical Yield'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter Theoretical Yield"
                    disabled
                    value={theoretical}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Actual Yield"
                  rules={[
                    {
                      required: false,
                      message: 'Enter Actual Yield'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter Actual Yield"
                    disabled
                    value={actualYield}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Sampling Qty"
                  rules={[
                    {
                      required: false,
                      message: 'Enter Sampling Qty'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter Sampling Qty"
                    disabled
                    value={sampleQty}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="% of Yield"
                  rules={[
                    {
                      required: false,
                      message: 'Enter % of Yield'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter % of Yield"
                    disabled
                    value={percent.toFixed(3)}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Rejects(Kgs)"
                  rules={[
                    {
                      required: false,
                      message: 'Enter reject'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter reject"
                    disabled
                    value={rejectTotal.toFixed(3)}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Others(remarks)"
                  name="others_remarks"
                  rules={[
                    {
                      required: false,
                      message: 'Enter other remarks'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input placeholder="Enter other remarks" />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Others(Kgs)"
                  rules={[
                    {
                      required: false,
                      message: 'Enter other remarks'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter other remarks"
                    onChange={(e) => handleChangeOther(e.target.value)}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Unaccountable loss"
                  rules={[
                    {
                      required: false,
                      message: 'Enter unaccountableloss'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter unaccountable loss"
                    value={unaccountable.toFixed(3)}
                    disabled
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Reconcilation"
                  rules={[
                    {
                      required: false,
                      message: 'Enter reconsiliation'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter reconsiliation"
                    value={reconcilation.toFixed(3)}
                    disabled
                  />
                </FormItem>
              </Col>
            </Row>
          </Card>
        </Card>
        <br />
        <FormItem className="text-center">
          <CustomButton
            className={styles.inwardButton}
            htmlType="submit"
            type="primary"
          >
            Submit
          </CustomButton>
        </FormItem>
      </Form>
    </Layout>
  );
};
export default yieldReconcilation;
