import React from 'react';
import Layout from '@/common/Layout';
import PendingListPage from './components/PendingListPage';

const PendingReceipts = () => {
  return (
    <>
      <Layout sider={false}>
        <PendingListPage />
      </Layout>
    </>
  );
};

export default PendingReceipts;
