import apiClient from 'utilities/apiClient';

export const materials = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.materialList}/${url}`, payload, true);
};

export const createRecon = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.stocks}/${url}`, payload, true);
};

export const recons = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.stocks}/${url}`, payload, true);
};

export const updateStock = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.stocks}/${url}`, payload, true);
};
