import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  materials,
  create_materials,
  create_weighing,
  create_damaged,
  suppliers,
  qc,
  print,
  create_vehicles,
  create_invoice,
  materialsMRNS,
  documents,
  netweights,
  updatenetweights,
  dispensingList,
  batch,
  dispense,
  batchDetails,
  createCodetransfer,
  getCodetoCodeTransferData,
  approvedData,
  update,
  typedMaterials,
  dispensing,
  purchase
} from './api';

const initialState = {
  allMaterials: [],
  material: {},
  pagination: [],
  getAllMaterilLoading: null,
  MaterialsList: [],
  suppliers: [],
  dispenses: [],
  purchases: []
};

const actions = {
  GET_ALL_MATERIALS: 'materials/GET_ALL_MATERIALS',
  GET_MATERIAL_BY_ID: 'materials/GET_MATERIAL_BY_ID',
  GET_MATERIALS_LIST: 'materials/GET_MATERIALS_LIST',
  CREATE_MATERIAL: 'materials/CREATE_MATERIAL',
  CREATE_WEIGHING: 'materials/CREATE_WEIGHING',
  CREATE_DAMAGED: 'materials/CREATE_DAMAGED',
  GET_SUPPLIERS: 'materials/GET_SUPPLIERS',
  INITIATE_QC: 'materials/INITIATE_QC',
  PRINT_LABEL: 'material/PRINT_LABEL',
  CREATE_VEHICLE_STORAGE: 'material/VEHICLE_STORAGE',
  CREATE_INVOICE: 'material/CREATE_INVOICE',
  GET_ALL_MRN_MATERIALS: 'material/GET_ALL_MRN_MATERIALS',
  GET_DOCS_BY_ID: 'material/GET_DOCS_BY_ID',
  GET_ALL_NET_WEIGHTS: 'material/GET_ALL_NET_WEIGHTS',
  UPDATE_NET_WEIGHTS: 'material/UPDATE_NET_WEIGHTS',
  GET_ALL_DISPENSES: 'material/GET_ALL_DISPENSES',
  UPDATE_BATCH: 'material/UPDATE_BATCH',
  UPDATE_INVOICE: 'material/UPDATE_INVOICE',
  DELETE_DISPENSE: 'material/DELETE_DISPENSE',
  GET_ALL_DETAILS: 'material/GET_ALL_DETAILS',
  GET_FEFO_BATCHES: 'materials/GET_FEFO_BATCHES',
  GET_ASSAY_DETAILS: 'materials/GET_ASSAY_DETAILS',
  GET_BATCH_DETAILS: 'materials/GET_BATCH_DETAILS',
  CREATE_CODE_TO_CODE: 'materials/CREATE_CODE_TO_CODE',
  GET_TRANSFER_DATA: 'materials/GET_TRANSFER_DATA',
  APPROVED_TRANSFERED_DATA: 'materials/APPROVED_TRANSFERED_DATA',
  UPDATE_WEIGHING: 'materials/UPDATE_WEIGHING',
  GET_TYPED_MATERIALS: 'materials/GET_TYPED_MATERIALS',
  CREATE_DISPENSING: 'dispense/CREATE_DISPENSING',
  GET_FOCUSED_POS: 'dispense/GET_FOCUSED_POS'
};

export const getAllMaterials = createAsyncThunk(
  actions.GET_ALL_MATERIALS,
  async (payload) => {
    const response = await materials('all', payload);

    return response;
  }
);

export const getAllMRNMaterials = createAsyncThunk(
  actions.GET_ALL_MRN_MATERIALS,
  async (payload) => {
    const response = await materialsMRNS('all', payload);

    return response;
  }
);

export const getMaterialbyId = createAsyncThunk(
  actions.GET_MATERIAL_BY_ID,
  async (payload) => {
    const response = await materials('all-materials', payload);

    return response;
  }
);

export const getAllMaterialslist = createAsyncThunk(
  actions.GET_MATERIALS_LIST,
  async (payload) => {
    const response = await materials('all', payload);

    return response;
  }
);

export const createMaterialInward = createAsyncThunk(
  actions.CREATE_MATERIAL,
  async (payload) => {
    const response = await create_materials('create', payload);
    return response;
  }
);
export const createInvoice = createAsyncThunk(
  actions.CREATE_INVOICE,
  async (payload) => {
    const response = await create_invoice('update', payload);
    return response;
  }
);

export const createVehicleStorage = createAsyncThunk(
  actions.CREATE_MATERIAL,
  async (payload) => {
    const response = await create_vehicles('create', payload);
    return response;
  }
);

export const createWeighingReport = createAsyncThunk(
  actions.CREATE_WEIGHING,
  async (payload) => {
    const response = await create_weighing('add', payload);
    return response;
  }
);

export const createDamagedReport = createAsyncThunk(
  actions.CREATE_DAMAGED,
  async (payload) => {
    const response = await create_damaged('add', payload);
    return response;
  }
);

export const getAllSuppliers = createAsyncThunk(
  actions.GET_SUPPLIERS,
  async (payload) => {
    const response = await suppliers('all', payload);

    return response;
  }
);

export const initiateQC = createAsyncThunk(
  actions.INITIATE_QC,
  async (payload) => {
    const response = await qc('initiate', payload);

    return response;
  }
);

export const printLabel = createAsyncThunk(
  actions.PRINT_LABEL,
  async (payload) => {
    const response = await print(`get-pdf/${payload}`, payload);

    return response;
  }
);

export const getDocsbyId = createAsyncThunk(
  actions.GET_DOCS_BY_ID,
  async (payload) => {
    const response = await documents('all', payload);

    return response;
  }
);

export const getAllnetWeights = createAsyncThunk(
  actions.GET_ALL_NET_WEIGHTS,
  async (payload) => {
    const response = await netweights('all-containers', payload);

    return response;
  }
);

export const updatenetWeights = createAsyncThunk(
  actions.UPDATE_NET_WEIGHTS,
  async (payload) => {
    const response = await updatenetweights('update', payload);

    return response;
  }
);

export const getAllDispenses = createAsyncThunk(
  actions.GET_ALL_DISPENSES,
  async (payload) => {
    const response = await dispensingList('get-all', payload);

    return response;
  }
);

export const updateBatch = createAsyncThunk(
  actions.UPDATE_BATCH,
  async (payload) => {
    const response = await batch(`create?id=${payload?.batch_id}`, payload);

    return response;
  }
);

export const updateInvoice = createAsyncThunk(
  actions.UPDATE_INVOICE,
  async (payload) => {
    const response = await batch(
      `create?document_id=${payload?.doc_id}`,
      payload
    );

    return response;
  }
);

export const deleteDispense = createAsyncThunk(
  actions.DELETE_DISPENSE,
  async (payload) => {
    const response = await dispense(`delete`, payload);

    return response;
  }
);

export const getdetails = createAsyncThunk(
  actions.GET_ALL_DETAILS,
  async (payload) => {
    const response = await dispensingList(`get_materials`, payload);

    return response;
  }
);

export const getFifo = createAsyncThunk(
  actions.GET_FEFO_BATCHES,
  async (payload) => {
    const response = await dispensingList(`fifo_batch`, payload);

    return response;
  }
);

export const getassaydetails = createAsyncThunk(
  actions.GET_ASSAY_DETAILS,
  async (payload) => {
    const response = await dispensingList(`dispensing_list`, payload);

    return response;
  }
);

export const getBatchDetails = createAsyncThunk(
  actions.GET_BATCH_DETAILS,
  async (payload) => {
    const response = await batchDetails(`container_list`, payload);

    return response;
  }
);

export const createCodetoCodetransfer = createAsyncThunk(
  actions.CREATE_CODE_TO_CODE,
  async (payload) => {
    const response = await createCodetransfer(`create_transfer`, payload);

    return response;
  }
);

export const getTransferDataById = createAsyncThunk(
  actions.GET_TRANSFER_DATA,
  async (payload) => {
    const response = await getCodetoCodeTransferData(`get_list`, payload);

    return response;
  }
);

export const approveTransferedData = createAsyncThunk(
  actions.APPROVED_TRANSFERED_DATA,
  async (payload) => {
    const response = await approvedData(`qa_approve`, payload);

    return response;
  }
);

export const updateWeighment = createAsyncThunk(
  actions.UPDATE_WEIGHING,
  async (payload) => {
    const response = await update(`update_weighment`, payload);

    return response;
  }
);

export const getTypedMaterials = createAsyncThunk(
  actions.GET_TYPED_MATERIALS,
  async (payload) => {
    const response = await typedMaterials(`material_list`, payload);

    return response;
  }
);

export const createDispensing = createAsyncThunk(
  actions.CREATE_DISPENSING,
  async (payload) => {
    const response = await dispensing('create', payload);

    return response;
  }
);

export const getFocusedPOs = createAsyncThunk(
  actions.GET_FOCUSED_POS,
  async (payload) => {
    const response = await purchase('list', payload);

    return response;
  }
);

export const materialSlice = createSlice({
  name: 'materials',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMaterials.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllMaterials.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, pagination } = action.payload;
        if (success) {
          state.allMaterials = data;
          state.pagination = pagination || null;
        } else {
          state.allMaterials = [];
        }
      })
      .addCase(getAllMaterials.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getMaterialbyId.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getMaterialbyId.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data } = action.payload;
        if (success) {
          state.material = data;
        } else {
          state.material = '';
        }
      })
      .addCase(getMaterialbyId.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllMaterialslist.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllMaterialslist.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data } = action.payload;
        if (success) {
          state.MaterialsList = data ? data : [];
        } else {
          state.MaterialsList = '';
        }
      })
      .addCase(getAllMaterialslist.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createMaterialInward.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createMaterialInward.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createMaterialInward.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(createWeighingReport.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createWeighingReport.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createWeighingReport.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(createDamagedReport.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createDamagedReport.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { sucess, message: msg } = action.payload;
        if (sucess) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createDamagedReport.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllSuppliers.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllSuppliers.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;
        const { data, success } = action.payload;
        if (!success) {
          console.log(data);
          state.suppliers = data.data;
          state.pagination = data.pagination || null;
        } else {
          state.suppliers = [];
        }
      })
      .addCase(getAllSuppliers.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(initiateQC.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(initiateQC.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;
        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(initiateQC.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(printLabel.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(printLabel.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;
        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(printLabel.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(createInvoice.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createInvoice.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;
        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createInvoice.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllMRNMaterials.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllMRNMaterials.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, pagination } = action.payload;
        if (success) {
          state.allMaterials = data;
          state.pagination = pagination || null;
        } else {
          state.allMaterials = [];
        }
      })
      .addCase(getAllMRNMaterials.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getDocsbyId.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getDocsbyId.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data } = action.payload;
        if (success) {
          state.material = data;
        } else {
          state.material = '';
        }
      })
      .addCase(getDocsbyId.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllnetWeights.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllnetWeights.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, pagination } = action.payload;
        if (success) {
          state.allMaterials = data;
          state.pagination = pagination || null;
        } else {
          state.allMaterials = [];
        }
      })
      .addCase(getAllnetWeights.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(updatenetWeights.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(updatenetWeights.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(updatenetWeights.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllDispenses.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllDispenses.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, pagination } = action.payload;
        if (success) {
          state.dispenses = data;
          state.pagination = pagination || null;
        } else {
          state.dispenses = [];
        }
      })
      .addCase(getAllDispenses.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(updateBatch.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(updateBatch.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(updateBatch.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(updateInvoice.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(updateInvoice.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(updateInvoice.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(deleteDispense.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(deleteDispense.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(deleteDispense.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getdetails.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getdetails.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getdetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getFifo.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getFifo.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getFifo.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getassaydetails.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getassaydetails.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getassaydetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getBatchDetails.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getBatchDetails.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getBatchDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getTransferDataById.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getTransferDataById.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getTransferDataById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(approveTransferedData.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(approveTransferedData.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(approveTransferedData.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(updateWeighment.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(updateWeighment.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(updateWeighment.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getTypedMaterials.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getTypedMaterials.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getTypedMaterials.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createDispensing.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createDispensing.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createDispensing.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getFocusedPOs.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getFocusedPOs.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, pagination } = action.payload;
        if (success) {
          state.purchases = data;
          state.pagination = pagination || null;
        } else {
          state.dispenses = [];
        }
      })
      .addCase(getFocusedPOs.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
  }
});
export default materialSlice.reducer;
