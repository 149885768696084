import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  Card,
  Input,
  Space,
  message,
  Image,
  Select,
  Popconfirm
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import { useDispatch } from 'react-redux';
import { CreateMasterProduct, getAllMasterProducts } from '../../redux/slice';
import truck from '@/features/qa/components/images/truck-delivery-done.gif';
import Layout from '@/common/Layout';
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import CustomModal from '@/common/CustomModal';
import { getLoggedInUser } from 'utilities/helpers';
// const { Option } = Select;
const { TextArea } = Input;

const CreateProductMaster = () => {
  const [form] = Form.useForm();
  const params = useParams();
  const { user } = getLoggedInUser();

  const dispatch = useDispatch();
  const [truckShow, setTruckShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [productDetailsdata, setProductDetailsdata] = useState([
    { product_details: '' }
  ]);

  useEffect(() => {
    params.id ? handleGetAllProductDetails() : '';
  }, []);

  const handleGetAllProductDetails = () => {
    let payload = { id: params.id };
    dispatch(getAllMasterProducts(payload)).then((response) => {
      if (response?.payload?.success) {
        form.setFieldsValue({
          product_name: response?.payload?.data?.[0]?.product_name
        });
        form.setFieldsValue({
          product_name_type: response?.payload?.data?.[0]?.product_name_type
        });
        form.setFieldsValue({
          shelf_life: response?.payload?.data?.[0]?.shelf_life
        });
        form.setFieldsValue({
          sub_product_type: response?.payload?.data?.[0]?.sub_product_type
        });
        setProductDetailsdata(response?.payload?.data?.[0]?.product_masters);
      } else {
        setTruckShow(false);
      }
    });
  };

  const onFinish = (values) => {
    params?.id ? (values['id'] = params?.id) : '';
    var prod_data = [];
    productDetailsdata?.map((item) => {
      prod_data.push({
        product_master_id: item?.id,
        product_details: item.product_details
      });
    });
    values['productType'] = prod_data;
    dispatch(CreateMasterProduct(values)).then((response) => {
      if (response?.payload?.success) {
        setTruckShow(false);
        params?.id ? handleGetAllProductDetails() : '';
        message.success(response?.payload?.message);
        history.push(`/product-masters-list`);
      } else {
        setTruckShow(false);
      }
    });
  };

  const handleAdd = () => {
    let list = [...productDetailsdata];
    list.push({
      product_details: ''
    });
    setProductDetailsdata(list);
  };
  const handleRemove = (index) => {
    let list = [...productDetailsdata];
    list.splice(index, 1);
    setProductDetailsdata(list);
  };

  const handleChangeDetails = (e, index) => {
    let list = [...productDetailsdata];
    list[index].product_details = e.target.value;
    setProductDetailsdata(list);
  };

  const handleClose = () => {
    setIsModal(false);
  };

  const handleDelete = (e, type) => {
    console.log(e);
    const payload = {
      id: e,
      type: 'delete',
      delete_type: type
    };
    dispatch(CreateMasterProduct(payload)).then((response) => {
      if (response?.payload?.success) {
        setTruckShow(false);
        handleGetAllProductDetails();
        message.success(response?.payload?.message);
        type == 'product_type'
          ? history.push(`/master-product-view/${params.id}`)
          : history.push(`/product-masters-list`);
      } else {
        setTruckShow(false);
      }
    });
  };

  return (
    <Layout sider={false}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Card
          title={params?.id ? 'VIEW PRODUCT' : 'ADD NEW PRODUCT'}
          className={styles.CustomPanel}
          extra={
            params?.id && user?.role == 'prod_user' && user?.level == 3 ? (
              <Space size={12}>
                <Popconfirm
                  title="Are you sure you want to delete this Product?"
                  description="Are you sure you want to delete this Product?"
                  onConfirm={() => handleDelete(params?.id, 'product')}
                  // onCancel={cancel}
                  okText="Delete"
                  cancelText="Cancel"
                >
                  <DeleteOutlined />
                </Popconfirm>
                <EditOutlined onClick={() => setIsEdit(true)} />
              </Space>
            ) : (
              ''
            )
          }
        >
          <Row gutter={24}>
            <Col xs={24} xl={6} span={12} md={24} sm={24}>
              <FormItem
                label="Product Name"
                name="product_name"
                rules={[
                  {
                    required: false,
                    message: 'Enter Theoretical Yield/Batch Size'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter Product Name"
                  disabled={params?.id && !isEdit ? true : false}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={6} span={24} md={24} sm={24}>
              <FormItem
                label="Product Short Name"
                name="product_name_type"
                rules={[
                  {
                    required: true,
                    message: 'Enter detail name of the Product'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter Product Short Name"
                  disabled={params?.id && !isEdit ? true : false}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={6} span={12}>
              <FormItem
                label="Shelf life"
                name="shelf_life"
                rules={[
                  {
                    required: true,
                    message: 'Enter Shelf life'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter Shelf life"
                  disabled={params?.id && !isEdit ? true : false}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={6} span={12}>
              <FormItem
                label="Sub Product Type"
                name="sub_product_type"
                rules={[
                  {
                    required: true,
                    message: 'Enter Shelf life'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  showSearch
                  placeholder="Select a sub type"
                  disabled={params?.id && !isEdit ? true : false}
                >
                  <option value="Auto">Auto</option>
                  <option value="Manual">Manual</option>
                </Select>
              </FormItem>
            </Col>
          </Row>
          {productDetailsdata?.map((item, index) => {
            return (
              <Row gutter={24} key={index}>
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="Product Details"
                    // name="shelf_life"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Shelf life'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Enter Product Details"
                      onChange={(e) => handleChangeDetails(e, index)}
                      value={item.product_details}
                      disabled={params?.id && !isEdit ? true : false}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <Space>
                    {item?.id &&
                    user?.role == 'prod_user' &&
                    user?.level == 3 ? (
                      <Popconfirm
                        title="Are you sure you want to delete this Product?"
                        description="Are you sure you want to delete this Product?"
                        onConfirm={() => handleDelete(item?.id, 'product_type')}
                        // onCancel={cancel}
                        okText="Delete"
                        cancelText="Cancel"
                      >
                        <DeleteOutlined className={styles.plusCircle} />
                      </Popconfirm>
                    ) : (
                      ''
                    )}
                    {index === 0 ? (
                      <PlusCircleOutlined
                        onClick={() => handleAdd()}
                        className={styles.plusCircle}
                      />
                    ) : !item?.id ? (
                      <DeleteOutlined
                        onClick={() => handleRemove(index)}
                        className={styles.plusCircle}
                      />
                    ) : (
                      ''
                    )}
                  </Space>
                </Col>
              </Row>
            );
          })}
        </Card>
        <br />

        <FormItem className="text-center">
          <Space>
            {truckShow ? (
              <Image
                src={truck}
                preview={false}
                style={{ width: '100px !important', height: '100px' }}
              />
            ) : params?.id && user?.role == 'prod_user' && user?.level == 3 ? (
              <CustomButton
                className={styles.inwardButton}
                // htmlType="submit"
                onClick={() => setIsModal(true)}
                type="primary"
              >
                Update
              </CustomButton>
            ) : !params?.id ? (
              <CustomButton
                className={styles.inwardButton}
                htmlType="submit"
                type="primary"
              >
                Create
              </CustomButton>
            ) : (
              ''
            )}
            <CustomButton
              className={styles.inwardButton}
              type="primary"
              ghost
              onClick={() => history.back()}
            >
              Back
            </CustomButton>
          </Space>
        </FormItem>
      </Form>
      {isModal ? (
        <CustomModal
          title="Verification"
          visible={isModal}
          width="50%"
          footer={null}
          className={styles.customModal}
          closable={false}
        >
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem
                  label="User ID"
                  name="employee_id"
                  rules={[
                    {
                      required: true,
                      message: 'Enter User ID'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input placeholder="Enter User ID" />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Password'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input.Password placeholder="Enter Password" />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem
                  label="Comments"
                  name="comments"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Comments'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <TextArea placeholder="Enter Comments" />
                </FormItem>
              </Col>
            </Row>
            <FormItem className="text-center">
              <Space>
                <CustomButton
                  className={styles.inwardButton}
                  onClick={handleClose}
                  type="primary"
                  size="small"
                  ghost
                >
                  Close
                </CustomButton>
                <CustomButton
                  className={styles.inwardButton}
                  htmlType="submit"
                  type="primary"
                  size="small"
                >
                  Verify
                </CustomButton>
              </Space>
            </FormItem>
          </Form>
        </CustomModal>
      ) : (
        ''
      )}
    </Layout>
  );
};
export default CreateProductMaster;
