import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { label } from './api';

const initialState = {
  lables: {}
};

const actions = {
  GET_ALL_LABELS: 'label/getLablebyId'
};

export const getLablebyId = createAsyncThunk(
  actions.GET_ALL_LABELS,
  async (payload) => {
    const response = await label('material-details', payload);

    return response;
  }
);

export const labelSlice = createSlice({
  name: 'lables',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLablebyId.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getLablebyId.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data } = action.payload;
        if (success) {
          state.lables = data;
        } else {
          state.lables = [];
        }
      })
      .addCase(getLablebyId.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
  }
});
export default labelSlice.reducer;
