import apiClient from 'utilities/apiClient';

export const users = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.admin}/${url}`, payload, true);
};

export const createuser = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.admin}/${url}`, payload, true);
};

export const updateuser = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.admin}/${url}`, payload, true);
};

export const deleteuser = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.admin}/${url}`, payload, true);
};

export const designations = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.department}/${url}`, payload, true);
};

export const createdesignations = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.department}/${url}`, payload, true);
};

export const createdepartments = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.department}/${url}`, payload, true);
};

export const departments = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.department}/${url}`, payload, true);
};
