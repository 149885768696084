import { Form, Card, Space, Row, Col, Select, Input } from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import Layout from '@/common/Layout';
import { history } from 'app/history';
import { useGetTrhmrListQuery } from '../../redux/apiSlice';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import Spinner from '../../../../common/Spinner';

const AdminPage = () => {
  const [form] = Form.useForm();
  const params = useParams();

  const { data, error, isLoading } = useGetTrhmrListQuery({
    id: params.id
  });

  let content;
  if (isLoading) {
    console.log('Loading.....');
    content = <Spinner />;
  } else if (data) {
    console.log('data =', data);
    content = (
      <Space>
        <Layout sider={false}>
          <Form form={form} layout="vertical">
            <Card
              title={'TEMPERATURE AND RELATIVE HUMIDITY MONITORING RECORD'}
              className={styles.CustomPanel}
            >
              <Row gutter={24}>
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="Area Name"
                    name="areaNameMasterId"
                    className={styles.customLable}
                  >
                    <Select
                      disabled={true}
                      defaultValue={data?.data?.area_name_master?.areaName}
                    ></Select>
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={24} md={24} sm={24}>
                  <FormItem
                    label="Thermo-Hygrometer ID No."
                    name="thermoHygrometerIdMasterId"
                    className={styles.customLable}
                  >
                    <Select
                      disabled={true}
                      defaultValue={
                        data?.data?.thermo_hygrometer_id_master
                          ?.thermo_hygrometer_id
                      }
                    ></Select>
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={24} md={24} sm={24}>
                  <FormItem
                    label="Temperature Limit (&deg;C)"
                    name="temperature_limit"
                    className={styles.customLable}
                  >
                    <Input
                      placeholder=""
                      style={{ width: '100%' }}
                      disabled={true}
                      defaultValue={
                        data?.data?.area_name_master?.temperature_limit
                      }
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="Hygrometer Calibration Due Date"
                    name="hygrometer_calibration"
                    className={styles.customLable}
                  >
                    <Input
                      style={{ width: '100%' }}
                      disabled={true}
                      defaultValue={moment(
                        data?.data?.thermo_hygrometer_id_master
                          ?.hygrometer_calibration_due_date
                      ).format('DD-MMM-YYY')}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={24} md={24} sm={24}>
                  <FormItem
                    label="Relative Humidity (&deg;C)"
                    name="relative_humidity_limit"
                    className={styles.customLable}
                  >
                    <Input
                      style={{ width: '100%' }}
                      disabled={true}
                      defaultValue={
                        data?.data?.area_name_master?.relative_humidity
                      }
                    />
                  </FormItem>
                </Col>
              </Row>
            </Card>
            <br />

            {data?.data?.trhmr_log_book_details.length > 0 ? (
              <Form form={form} layout="vertical">
                {data.data?.trhmr_log_book_details?.map((obj, index) => (
                  <>
                    <Card
                      title={`Reading between ${obj.period}`}
                      className={styles.CustomPanel}
                    >
                      <Row gutter={24}>
                        <Col xs={24} xl={12} span={12} md={24} sm={24}>
                          <FormItem
                            label="Date"
                            name={`date${index}`}
                            className={styles.customLable}
                          >
                            <Input
                              defaultValue={moment(obj.verified_by_date).format(
                                'DD-MMM-YYYY'
                              )}
                              disabled={true}
                            />
                          </FormItem>
                        </Col>
                        <Col xs={24} xl={12} span={12} md={24} sm={24}>
                          <FormItem
                            label="Time"
                            name={`time${index}`}
                            className={styles.customLable}
                          >
                            <Input
                              defaultValue={moment(obj.verified_by_date).format(
                                'HH:mm'
                              )}
                              disabled={true}
                            />
                          </FormItem>
                        </Col>
                        <Col xs={24} xl={12} span={24} md={24} sm={24}>
                          <FormItem
                            label="Temperature Minimum (&deg;C)"
                            name={`temperature_minimum${index}`}
                            className={styles.customLable}
                          >
                            <Input
                              defaultValue={obj.temperature_minimum}
                              disabled={true}
                            />
                          </FormItem>
                        </Col>
                        <Col xs={24} xl={12} span={24} md={24} sm={24}>
                          <FormItem
                            label="Temperature Maximum (&deg;C)"
                            name={`temperature_maximum${index}`}
                            className={styles.customLable}
                          >
                            <Input
                              defaultValue={obj.temperature_maximum}
                              disabled={true}
                            />
                          </FormItem>
                        </Col>
                        <Col xs={24} xl={12} span={24} md={24} sm={24}>
                          <FormItem
                            label="Temperature (&deg;C)"
                            name={`temperature${index}`}
                            className={styles.customLable}
                          >
                            <Input
                              defaultValue={obj.temperature}
                              disabled={true}
                            />
                          </FormItem>
                        </Col>
                        <Col xs={24} xl={12} span={24} md={24} sm={24}>
                          <FormItem
                            label="Relative Humidity Minimum (&deg;C)"
                            name={`relative_humidity_minimum${index}`}
                            className={styles.customLable}
                          >
                            <Input
                              defaultValue={obj.relative_humidity_minimum}
                              disabled={true}
                            />
                          </FormItem>
                        </Col>
                        <Col xs={24} xl={12} span={24} md={24} sm={24}>
                          <FormItem
                            label="Relative Humidity Maximum (&deg;C)"
                            name={`relative_humidity_maximum${index}`}
                            className={styles.customLable}
                          >
                            <Input
                              defaultValue={obj.relative_humidity_maximum}
                              disabled={true}
                            />
                          </FormItem>
                        </Col>
                        <Col xs={24} xl={12} span={24} md={24} sm={24}>
                          <FormItem
                            label="Relative Humidity (&deg;C)"
                            name={`relative_humidity${index}`}
                            className={styles.customLable}
                          >
                            <Input
                              defaultValue={obj.relative_humidity}
                              disabled={true}
                            />
                          </FormItem>
                        </Col>
                        <Col xs={24} xl={12} span={24} md={24} sm={24}>
                          <FormItem
                            label="Verified By (User id & Date)"
                            name={`verified_by_user_id${index}`}
                            className={styles.customLable}
                          >
                            <Input
                              defaultValue={`${
                                obj.verified_by_user_id
                              } & ${moment(obj.verified_by_date).format(
                                'DD-MMM-YYYY'
                              )}`}
                              disabled={true}
                            />
                          </FormItem>
                        </Col>
                        <Col xs={24} xl={12} span={24} md={24} sm={24}>
                          <FormItem
                            label="Remarks"
                            name={`remarks${index}`}
                            className={styles.customLable}
                          >
                            <Input.TextArea
                              defaultValue={obj.remarks}
                              disabled={true}
                            />
                          </FormItem>
                        </Col>
                      </Row>
                    </Card>
                    <br />
                  </>
                ))}
              </Form>
            ) : (
              ''
            )}
            <FormItem className="text-center">
              <Space>
                <CustomButton
                  className={styles.inwardButton}
                  type="primary"
                  ghost
                  onClick={() => history.back()}
                >
                  Back
                </CustomButton>
              </Space>
            </FormItem>
          </Form>
        </Layout>
      </Space>
    );
  } else if (error) {
    console.log(error);
    content = 'Something went wrong!';
  }

  return <>{content}</>;
};
export default AdminPage;
