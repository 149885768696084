import React from 'react';
import Layout from '@/common/Layout';
import CreateMaterialPage from './components/CreateMaterialPage';

const Material = () => {
  return (
    <>
      <Layout sider={false}>
        <CreateMaterialPage />
      </Layout>
    </>
  );
};

export default Material;
