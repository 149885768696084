import React, { useState } from 'react';
import { Row, Col, Select, Card, Form } from 'antd';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import CustomButton from '@/common/CustomButton';
import { QrcodeOutlined, DeleteOutlined } from '@ant-design/icons';
import { QrReader } from 'react-qr-reader';
import Layout from '@/common/Layout';
// import CustomTable from '@/common/Content/CustomTable';

const ContainerHistory = () => {
  const [form] = Form.useForm();
  const [openCam, setOpenCam] = useState(false);
  const [bin, setBin] = useState('');
  const [search, setSearch] = useState('');

  const onFinish = (values) => {
    console.log(values);
  };
  const handleError = (err) => {
    console.error(err);
  };

  const onChangebin = (values) => {
    setBin(values);
    form.resetFields();
  };

  const onSearch = (value) => {
    setSearch(value);
  };

  const handleQrScan = async (scanData) => {
    console.log(scanData);
  };
  console.log(search);

  const bin_containers = [
    { name: 'BIN-0001', id: 1 },
    { id: 2, name: 'BIN-0002' }
  ];

  return (
    <Layout sider={false}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Card
          title="TRACK BIN/CONTAINER POSITION"
          className={styles.CustomPanel}
        >
          <Row gutter={24}>
            <Col xs={12} xl={16} span={16} style={{ paddingTop: '11px' }}>
              <h6 className={styles.lable}>Scan Bin</h6>
            </Col>
            {bin == '' ? (
              <>
                <Col xs={10} xl={6} span={6}>
                  <FormItem
                    name="bin_containers"
                    rules={[
                      {
                        required: false,
                        message: 'Enter damaged containers'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Select
                      showSearch
                      placeholder="Scan or select container"
                      optionFilterProp="children"
                      onChange={onChangebin}
                      onSearch={onSearch}
                    >
                      {bin_containers.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Select>
                  </FormItem>
                </Col>
                <Col xs={2} xl={2} span={2} md={2} sm={2}>
                  <CustomButton
                    onClick={() => setOpenCam(true)}
                    // className={styles.qr}
                  >
                    <QrcodeOutlined onClick={() => setOpenCam(true)} />
                  </CustomButton>
                </Col>
              </>
            ) : (
              <>
                <Col xs={10} xl={6} span={6}>
                  <h6 className={styles.lableValue}>{bin}</h6>
                </Col>
                <Col xs={2} xl={2} span={2}>
                  <DeleteOutlined
                    onClick={() => setBin('')}
                    className={styles.removal}
                  />
                </Col>
              </>
            )}
          </Row>
        </Card>

        {openCam ? (
          <QrReader
            constraints={{ facingMode: 'environment' }}
            delay={1000}
            onError={handleError}
            onResult={(result) => handleQrScan(result)}
            // chooseDeviceId={()=>selected}
            style={{ width: '3px', height: '3px' }}
          />
        ) : (
          ''
        )}
      </Form>
    </Layout>
  );
};
export default ContainerHistory;
