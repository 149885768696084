import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  batches,
  qcApprove,
  dispensingList,
  updateDispensing,
  crete_testForm
} from './api';

const initialState = {
  batchdetails: [],
  dispenses: []
};

const actions = {
  GET_ALL_BATCHES: 'dispensing/GET_ALL_BATCHES',
  UPDATE_QC_APPROVE: 'dispensing/UPDATE_QC_APPROVE',
  GET_ALL_DISPENSES: 'dispensing/GET_ALL_DISPENSES',
  UPDATE_DISPENSES: 'dispensing/UPDATE_DISPENSES',
  CREATE_TEST_FORM: 'dispensing/CREATE_TEST_FORM'
};

export const getAllBatches = createAsyncThunk(
  actions.GET_ALL_BATCHES,
  async (payload) => {
    const response = await batches('get-all', payload);

    return response;
  }
);

export const updateQCapprove = createAsyncThunk(
  actions.UPDATE_QC_APPROVE,
  async (payload) => {
    const response = await qcApprove('bacth-qc', payload);

    return response;
  }
);

export const getAllDispenses = createAsyncThunk(
  actions.GET_ALL_DISPENSES,
  async (payload) => {
    const response = await dispensingList('get-all', payload);

    return response;
  }
);

export const updateDispense = createAsyncThunk(
  actions.UPDATE_DISPENSES,
  async (payload) => {
    const response = await updateDispensing('update', payload);

    return response;
  }
);

export const createTestForm = createAsyncThunk(
  actions.CREATE_TEST_FORM,
  async (payload) => {
    const response = await crete_testForm(
      `${
        payload?.type == 'regenerate'
          ? 'regenerate_trf'
          : payload?.trf_id
          ? 'update-trf'
          : 'create-trf'
      }`,
      payload
    );

    return response;
  }
);

export const qaapprovalSlice = createSlice({
  name: 'qaapproval',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllBatches.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllBatches.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, pagination, message: msg } = action.payload;
        if (success) {
          state.batchdetails = data;
          state.pagination = pagination || null;
        } else {
          state.batchdetails = [];
          message.error(msg);
        }
      })
      .addCase(getAllBatches.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(updateQCapprove.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(updateQCapprove.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(updateQCapprove.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllDispenses.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllDispenses.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, pagination } = action.payload;
        if (success) {
          state.dispenses = data;
          state.pagination = pagination || null;
        } else {
          state.dispenses = [];
        }
      })
      .addCase(getAllDispenses.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(updateDispense.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(updateDispense.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(updateDispense.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createTestForm.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createTestForm.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createTestForm.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
  }
});
export default qaapprovalSlice.reducer;
