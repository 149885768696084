import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import moment from 'moment';

function LiveTimeInput(props) {
  const [time, setTime] = useState(moment());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(moment());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);
  if (props?.valueType === 'time') {
    return (
      <Input
        value={moment(time).format('HH:mm:ss')}
        disabled={true}
        onChange={setTime}
      />
    );
  } else if (props?.valueType === 'date') {
    return (
      <Input
        value={moment(time).format('DD-MMM-YYYY')}
        disabled={true}
        onChange={setTime}
      />
    );
  } else {
    return <Input value={time} onChange={setTime} />;
  }
}

export default LiveTimeInput;
