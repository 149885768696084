import React, { useState, useEffect } from 'react';
import { Form, Card, Space, Input, Row, Col } from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomTable from 'common/Content/CustomTable';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDepartments, createDepartment } from '../../redux/slice';
import { EditOutlined } from '@ant-design/icons';
import CustomModal from '@/common/CustomModal';
import Layout from '@/common/Layout';

const Departments = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { allDepartments } = useSelector((state) => state.admin);

  const { Search } = Input;

  // const [page, setPage] = useState(1);
  // const [showDisablePopup, setShowDisablePopup] = useState(false);
  const [search, setSearch] = useState('');
  // const [role, setRole] = useState('');
  // const [level, setLevel] = useState('');
  // const [isEdit, setIsEdit] = useState(true);
  const [isModal, setIsModal] = useState(false);
  const [isDsgntn, setIsDsgntn] = useState('');
  const [deptId, setDeptId] = useState('');

  // const handleModel = (role, level) => {
  //   setRole(role);
  //   setLevel(level);
  //   setIsModal(true);
  // };

  // console.log(isModal);
  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    handleGetAllDepartments();
  }, [search]);

  const handleGetAllDepartments = () => {
    const payload = { search };
    dispatch(getAllDepartments(payload));
  };

  const handleView = (designation, id) => {
    setIsModal(true);
    setIsDsgntn(designation);
    setDeptId(id);
  };

  // const handleDeleteUser = (payload) => {
  //   dispatch(deleteUser(payload)).then(() => {
  //     handleGetAllUsers();
  //     // setShowDisablePopup(false);
  //   });
  // };

  // const handleClose = () => {
  //   setIsModal(false);
  // };

  const columns = [
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
      ellipsis: true,
      width: '100px'
    },
    // {
    //   title: 'Created By',
    //   dataIndex: 'employee_id',
    //   key: 'employee_id',
    //   ellipsis: true,
    //   width: '150px'
    // },

    {
      title: 'Action',
      dataIndex: 'designation',
      key: 'designation',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return (
          <Space>
            <EditOutlined
              onClick={() => handleView(record?.department, record?.id)}
            />
          </Space>
        );
      }
    }
  ];

  const handleAddDept = (e) => {
    setIsDsgntn(e);
  };
  const handleSubmit = () => {
    const payload = deptId
      ? { department: isDsgntn, id: deptId }
      : { department: isDsgntn };
    dispatch(createDepartment(payload)).then((response) => {
      if (response?.payload?.success) {
        setIsModal(false);
        setIsDsgntn('');
        handleGetAllDepartments();
      }
    });
  };

  const handleClose = () => {
    setIsDsgntn('');
    setIsModal(false);
  };
  return (
    <>
      <Layout sider={false}>
        <Form form={form} layout="vertical">
          <Card
            title={<span>Department Details</span>}
            className={`${styles.cardStyles_approver}`}
            extra={
              <Space>
                <Search
                  onChange={(e) => handleChange(e)}
                  className={`w-100 ${styles.searchbar}`}
                  justify="start"
                  align="start"
                  placeholder="Search by Department"
                />
                <CustomButton
                  className={styles.inwardButton}
                  onClick={() => setIsModal(true)}
                  type="primary"
                >
                  {`Create Department`}
                </CustomButton>
              </Space>
            }
          >
            <div className={`${styles.table_container}`}>
              <CustomTable
                columns={columns}
                dataSource={allDepartments}
                pagination={
                  // size: 'small',
                  // total: pagination?.total_records || 0,
                  // showSizeChanger: false,
                  // onChange: (page) => {
                  //   setPage(page);
                  false
                }
              />
            </div>
          </Card>
          {isModal ? (
            <CustomModal
              // title="Create Department"
              visible={isModal}
              width="50%"
              footer={null}
              className={styles.customModal}
              closable={false}
            >
              <Card
                title={deptId ? 'Edit Department' : 'Create Department'}
                // extra={<EditOutlined onClick={() => setIsEdit(!isEdit)} />}
                className={styles.CustomPanel}
              >
                <Row gutter={24}>
                  <Col xs={24} xl={12} span={8} md={24} sm={24}>
                    <FormItem
                      label="Department"
                      // name="designation"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Department name'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Enter Department name"
                        // disabled={isDsgntn && isEdit}
                        onChange={(e) => handleAddDept(e.target.value)}
                        value={isDsgntn}
                      />
                    </FormItem>
                  </Col>
                </Row>
                <FormItem className="text-center">
                  <Space>
                    <CustomButton
                      className={styles.inwardButton}
                      onClick={() => handleClose()}
                      type="primary"
                      ghost
                    >
                      Close
                    </CustomButton>
                    <CustomButton
                      className={styles.inwardButton}
                      htmlType="submit"
                      type="primary"
                      onClick={() => handleSubmit()}
                    >
                      {deptId ? `Update` : `Create`}
                    </CustomButton>
                  </Space>
                </FormItem>
              </Card>
            </CustomModal>
          ) : (
            ''
          )}
          <br />
          <FormItem className="text-center">
            <CustomButton
              className={styles.inwardButton}
              onClick={() => history.back()}
              type="primary"
            >
              Back to Home
            </CustomButton>
          </FormItem>
        </Form>
      </Layout>
    </>
  );
};

export default Departments;
