import React, { useState } from 'react';
import { Row, Col, Form, Card, Space, DatePicker, message } from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import moment from 'moment';
import LocalStorage from 'utilities/localStorage';
import Layout from '@/common/Layout';

const { RangePicker } = DatePicker;

const COGSReport = () => {
  const [form] = Form.useForm();
  const [dateRange, setDateRange] = useState([moment(), moment()]);

  const onFinish = (values) => {
    console.log(values);
    // message.error('Something went wrong. We are looking on it!');
    // form.resetFields();
    // setDownloadReports(true);
    const fromDate = values.fromDate
      ? `${moment(values.fromDate[0]._d).format('YYYY-MM-DD')}`
      : '';
    const toDate = values.fromDate
      ? `${moment(values.fromDate[1]._d).format('YYYY-MM-DD')}`
      : '';
    const user = LocalStorage.getItem('Gravity_user');

    const authToken = user?.token;

    fetch(
      `${process.env.REACT_APP_API_URL}/batch_log_book/download_fgdetails?from_date=${fromDate}&to_date=${toDate}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/csv',
          Authorization: `Bearer ${authToken}`
        }
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        console.log(blob);
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Report.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        message.success('Report Downloaded successfully');
        form.resetFields();
        // setDownloadReports(false);
      });
  };

  return (
    <Layout sider={false}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Card title={'DOWNLOAD COGS REPORT'} className={styles.CustomPanel}>
          <Row gutter={24}>
            {/* <Col xs={24} xl={12} span={12} md={24} sm={24}>
            <FormItem
              label="Report Name"
              name="report"
              rules={[
                {
                  required: true,
                  message: 'Select Report'
                }
              ]}
              className={styles.customLable}
            >
              <Select placeholder="Please Select Report">
                <option value="dispense">Dispense Report</option>
                <option value="material">MRN report</option>
                <option value="sampling">Sampling report</option>
                <option value="wastage">Wastage Report</option>
              </Select>
            </FormItem>
          </Col> */}
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Select FROM and TO date"
                name="fromDate"
                rules={[
                  {
                    required: true,
                    message: 'Enter Select date'
                  }
                ]}
                className={styles.customLable}
              >
                <RangePicker
                  format={'YYYY-MM-DD'}
                  onChange={(x) => {
                    setDateRange(x);
                  }}
                  disabledDate={(current) => {
                    return current && current.valueOf() > Date.now();
                  }}
                  value={dateRange}
                  // separator={'-'}
                  style={{ width: '100%' }}
                />
              </FormItem>
            </Col>
          </Row>
        </Card>
        <br />
        <FormItem className="text-center">
          <Space>
            <CustomButton
              className={styles.inwardButton}
              htmlType="submit"
              type="primary"
            >
              Download
            </CustomButton>
            <CustomButton
              className={styles.inwardButton}
              type="primary"
              ghost
              onClick={() => history.back()}
            >
              Back
            </CustomButton>
          </Space>
        </FormItem>
      </Form>
    </Layout>
  );
};
export default COGSReport;
