import apiClient from 'utilities/apiClient';

export const getReports = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.audit}/${url}`, payload, true);
};

export const getFailedLogs = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.print}/${url}`, payload, true);
};

export const downloadReport = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.audit}/${url}`, payload, true);
};
