import React from 'react';
import Layout from '@/common/Layout';
import SamplingMaterialsPage from './components/SamplingMaterialsPage';

const SamplingMaterials = () => {
  return (
    <>
      <Layout sider={false}>
        <SamplingMaterialsPage />
      </Layout>
    </>
  );
};

export default SamplingMaterials;
