import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Card,
  Space,
  DatePicker,
  Select,
  message,
  Image,
  Spin
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
// import moment from 'moment';
import LocalStorage from 'utilities/localStorage';
import Layout from '@/common/Layout';
import truck from '../../images/truck-delivery-done.gif';
// import { getAllMaterialslist } from '@/features/stockCard/redux/slice';
const { RangePicker } = DatePicker;
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMaterialslist } from '@/features/stockCard/redux/slice';

const StockReportPage = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { allMaterials } = useSelector((state) => state.stocks);
  const [truckShow, setTruckShow] = useState(false);
  const [dateRange, setDateRange] = useState([moment(), moment()]);
  const [futerdate, setFuterdate] = useState(true);
  const [search, setSearch] = useState('');
  const [materialId, setMaterialId] = useState('');
  const [type, setType] = useState('');
  // const [code, setCode] = useState('');

  useEffect(() => {
    handleGetAllMaterialsList();
  }, [search]);

  const handleGetAllMaterialsList = () => {
    let payload = { search };
    dispatch(getAllMaterialslist(payload));
  };

  const onFinish = (values) => {
    const fromDate = values.fromDate
      ? `${moment(values.fromDate[0]._d).format('YYYY-MM-DD')}`
      : '';
    const toDate = values.fromDate
      ? `${moment(values.fromDate[1]._d).format('YYYY-MM-DD')}`
      : '';

    const user = LocalStorage.getItem('Gravity_user');
    setTruckShow(true);
    const authToken = user?.token;
    fetch(
      `${process.env.REACT_APP_API_URL}/other-reports?report_type=${values.report}&from_date=${fromDate}&to_date=${toDate}&material_id=${materialId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/csv',
          Authorization: `Bearer ${authToken}`
        }
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        console.log(blob);
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${values.report}.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        message.success('Report Downloaded successfully');
        form.resetFields();
        setTruckShow(false);
        // setDownloadReports(false);
      });
  };

  const handleChange = (e) => {
    setType(e);
    if (e === 'retest_material_report' || e === 'expiry_material_report') {
      setFuterdate(false);
    } else {
      setFuterdate(true);
    }
  };

  const onSearch = (value) => {
    setSearch(value);
  };

  const onChange = (value) => {
    setMaterialId(value);
  };
  return (
    <Layout sider={false}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Card title={'DOWNLOAD OTHER REPORTS'} className={styles.CustomPanel}>
          <Row gutter={24}>
            <Col xs={24} xl={6} span={12} md={24} sm={24}>
              <FormItem
                label="Stock Statement"
                name="report"
                rules={[
                  {
                    required: true,
                    message: 'Select Statement'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  placeholder="Please Select Statement"
                  onChange={(e) => handleChange(e)}
                >
                  <option value="stock_reconsiliation">
                    Stock Reconsiliation
                  </option>
                  <option value="wastage_material_transactions_stock">
                    Wastage Material
                  </option>
                  <option value="retest_material_report">
                    Retest Material
                  </option>
                  <option value="expiry_material_report">
                    Expiry Material
                  </option>
                  <option value="damage_report">Damage Report</option>
                  <option value="non_moving_type">Non-Moving Stock</option>
                  <option value="material_stock_report">
                    Material Stock Report
                  </option>
                </Select>
              </FormItem>
            </Col>
            {
              <>
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="Select start and end date"
                    name="fromDate"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Select date'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <RangePicker
                      format={'YYYY-MM-DD'}
                      onChange={(x) => {
                        setDateRange(x);
                      }}
                      disabledDate={
                        futerdate
                          ? (current) => {
                              return current && current.valueOf() > Date.now();
                            }
                          : false
                      }
                      value={dateRange}
                      // separator={'-'}
                      style={{ width: '100%' }}
                    />
                  </FormItem>
                </Col>
                {type === 'material_stock_report' && (
                  <Col xs={24} xl={6} span={6} md={24} sm={24}>
                    <FormItem
                      label="Material Code"
                      name="material_id"
                      rules={[
                        {
                          required: true,
                          message: 'Select Material Code'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Select
                        showSearch
                        placeholder="Select a material code"
                        optionFilterProp="children"
                        onChange={(e) => onChange(e, 'id')}
                        onSearch={onSearch}
                      >
                        {allMaterials ? (
                          allMaterials?.map((item, index) => {
                            return (
                              <option value={item.id} key={index}>
                                {item.code}
                              </option>
                            );
                          })
                        ) : (
                          <Spin />
                        )}
                      </Select>
                    </FormItem>
                  </Col>
                )}
              </>
            }
          </Row>
        </Card>
        <br />
        <FormItem className="text-center">
          {truckShow ? (
            <Image
              src={truck}
              preview={false}
              style={{ width: '100px !important', height: '100px' }}
            />
          ) : (
            <Space>
              <CustomButton
                className={styles.inwardButton}
                htmlType="submit"
                type="primary"
              >
                Download
              </CustomButton>
              <CustomButton
                className={styles.inwardButton}
                type="primary"
                ghost
                onClick={() => history.back()}
              >
                Back
              </CustomButton>
            </Space>
          )}
        </FormItem>
      </Form>
    </Layout>
  );
};
export default StockReportPage;
