import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { document, create_batch, create_damage, edit_damage } from './api';

const initialState = {
  allMaterials: [],
  material: {},
  pagination: [],
  getAllMaterilLoading: null,
  MaterialsList: [],
  suppliers: []
};

const actions = {
  GET_ALL_MATERIALS: 'damage/GET_ALL_MATERIALS',
  GET_DOCS_BY_ID: 'damage/GET_DOCS_BY_ID',
  CREATE_DAMAGE_REPORT: 'damage/CREATE_DAMAGE_REPORT',
  EDIT_DAMAGE_REPORT: 'damage/EDIT_DAMAGE_REPORT'
};

export const getDocsbyId = createAsyncThunk(
  actions.GET_DOCS_BY_ID,
  async (payload) => {
    const response = await document('all', payload);

    return response;
  }
);

export const createBatch = createAsyncThunk(
  actions.CREATE_MATERIAL,
  async (payload) => {
    const response = await create_batch('create', payload);
    return response;
  }
);

export const createDamageReport = createAsyncThunk(
  actions.CREATE_DAMAGE_REPORT,
  async (payload) => {
    const response = await create_damage('add', payload);
    return response;
  }
);

export const editDamageReport = createAsyncThunk(
  actions.EDIT_DAMAGE_REPORT,
  async (payload) => {
    const response = await edit_damage('edit', payload);
    return response;
  }
);

export const damageSlice = createSlice({
  name: 'damage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDocsbyId.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getDocsbyId.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data } = action.payload;
        if (success) {
          state.material = data;
        } else {
          state.material = '';
        }
      })
      .addCase(getDocsbyId.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createBatch.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createBatch.fulfilled, (state) => {
        state.getAllMaterilLoading = false;

        // const { success, message: msg } = action.payload;
        // if (!success) {
        //   message.error(msg);
        // }
      })
      .addCase(createBatch.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(createDamageReport.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createDamageReport.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createDamageReport.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(editDamageReport.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(editDamageReport.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(editDamageReport.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
  }
});
export default damageSlice.reducer;
