import React from 'react';
import { Card, Image, Space, Row, Col, Form } from 'antd';
import ApprovalMissingDoc from '../../images/approval-for-missing-documents.svg';
import ApprovalStorageFailure from '../../images/approval-for-storage-inspection-failure.svg';
import ApprovalDamagedReport from '../../images/approval-for-damages-reported.svg';
import ApprovalDiscrepency from '../../images/approval-for-discrepancy-raised.svg';
import ApprovalLCDispense from '../../images/approval-for-LC-to-dispense-material.svg';
import truck from '../../images/1.svg';
import Gatepasstruck from '../../images/2.svg';
import QCtruck from '../../images/3.svg';
import FileImage from '../../images/file-icon.svg';
// import temp from '../../images/Untitled.png';
// import CustomButton  from '@/common/CustomButton';
import styles from './index.module.less';
import { ArrowRightOutlined } from '@ant-design/icons';
import { getLoggedInUser } from 'utilities/helpers';
import { useLocation } from 'react-router-dom';

const DashBoardPage = () => {
  const [form] = Form.useForm();
  const { user } = getLoggedInUser();
  const searchParam = useLocation().search;
  const searchParams = new URLSearchParams(searchParam);

  const cards = [
    {
      title: 'Receive Material',
      icon: truck,
      url: '/pending-receipts'
    },
    {
      title: 'Move Material',
      icon: Gatepasstruck,
      url: '/landingpage'
    },
    {
      title: 'Stock Reconciliation',
      icon: QCtruck,
      url: '/stock-reconsilation'
    },
    {
      title: `Dispense Material`,
      icon: truck,
      url: '/dispense-material-list'
    },
    // {
    //   title: 'Sampling Material',
    //   icon: truck,
    //   url: user?.lead === true ? '/samples-list' : '/sampling'
    // },
    {
      title: 'Wastage Material',
      icon: truck,
      url: '/wastage-reconsilation'
    },
    {
      title: 'Material Return Request',
      icon: truck,
      url: 'material-return-request'
    },
    {
      title: 'Log Books',
      icon: truck,
      url: 'log-books'
    },
    {
      title: 'Code to Code Transfer',
      icon: truck,
      url: 'code-to-code-list'
    }
  ];
  const QAcards = [
    {
      title: 'Create Dispense Request',
      icon: ApprovalMissingDoc,
      url: 'dispensing'
    },
    {
      title: 'Approval for Missing Document',
      icon: ApprovalMissingDoc,
      url: 'qc-list?qc=doc_ver_pending'
    },
    {
      title: 'Approval for Storage Inspection Failure',
      icon: ApprovalStorageFailure,
      url: 'qc-list?qc=veh_ver_pending'
    },
    {
      title: 'Approval for Damages Reported',
      icon: ApprovalDamagedReport,
      url: 'qc-list?qc=dmg_ver_pending'
    },
    {
      title: 'Approval for Discrepency Raised',
      icon: ApprovalDiscrepency,
      url: 'qc-list?qc=wh_ver_pending'
    },
    {
      title: 'Approval for LC to Dispense Material',
      icon: ApprovalLCDispense,
      url: 'dispense-material-list'
    },
    {
      title: 'Approval for Stock Reconciliation',
      icon: ApprovalLCDispense,
      url: 'stock-reconsilation'
    },
    {
      title: 'Approval for Wastage Material',
      icon: ApprovalLCDispense,
      url: 'wastage-reconsilation'
    },
    {
      title:
        user?.level == 3
          ? 'Approval for Vendor/Material'
          : 'Add New Vendor/Material',
      icon: ApprovalLCDispense,
      url: 'vendor-material'
    },
    {
      title: 'Approval for Material based Dispensing Request',
      icon: ApprovalLCDispense,
      url: 'dispense-qa-materials-list'
    },
    {
      title: 'Material Return Request',
      icon: ApprovalLCDispense,
      url: 'material-return-request'
    },
    {
      title: 'Batch Numbering Log Book',
      icon: ApprovalLCDispense,
      url: 'log-book-list'
    },
    {
      title: 'Approval for Equipment LC - eBMR',
      icon: ApprovalLCDispense,
      url: 'dispensing-list?type=granulation'
    }

    // {
    //   title: 'Log Books',
    //   icon: truck,
    //   url: 'log-books'
    // }
  ];

  const QALeadcards = [
    {
      title: 'Create Dispense Request',
      icon: ApprovalMissingDoc,
      url: 'dispensing'
    },
    {
      title: 'Approval for Missing Document',
      icon: ApprovalMissingDoc,
      url: 'qc-list?qc=doc_ver_pending'
    },
    {
      title: 'Approval for Storage Inspection Failure',
      icon: ApprovalStorageFailure,
      url: 'qc-list?qc=veh_ver_pending'
    },
    {
      title: 'Approval for Damages Reported',
      icon: ApprovalDamagedReport,
      url: 'qc-list?qc=dmg_ver_pending'
    },
    {
      title: 'Approval for Discrepency Raised',
      icon: ApprovalDiscrepency,
      url: 'qc-list?qc=wh_ver_pending'
    },
    {
      title: 'Approval for LC to Dispense Material',
      icon: ApprovalLCDispense,
      url: 'dispense-material-list'
    },
    {
      title: 'Approval for Stock Reconciliation',
      icon: ApprovalLCDispense,
      url: 'stock-reconsilation'
    },
    {
      title: 'Approval for Wastage Material',
      icon: ApprovalLCDispense,
      url: 'wastage-reconsilation'
    },
    {
      title:
        user?.level == 3
          ? 'Approval for Vendor/Material'
          : 'Add New Vendor/Material',
      icon: ApprovalLCDispense,
      url: 'vendor-material'
    },
    {
      title: 'Approval for Material based Dispensing Request',
      icon: ApprovalLCDispense,
      url: 'dispense-qa-materials-list'
    },
    // {
    //   title: 'Approval for Product',
    //   icon: ApprovalLCDispense,
    //   url: 'product-list'
    // },
    // {
    //   title: 'Log Books',
    //   icon: ApprovalLCDispense,
    //   url: 'log-books'
    // },
    {
      title: 'Material Return Request',
      icon: truck,
      url: 'material-return-request'
    },
    {
      title: 'Code to Code Transfer',
      icon: ApprovalLCDispense,
      url: 'code-to-code-list'
    },
    {
      title: 'Approval for Equipment LC - eBMR',
      icon: ApprovalLCDispense,
      url: 'dispensing-list?type=granulation'
    },
    {
      title: 'Batch Numbering Log Book',
      icon: ApprovalLCDispense,
      url: 'log-book-list'
    },
    {
      title: 'Approval for eBMR',
      icon: ApprovalLCDispense,
      url: 'product-list'
    }
  ];
  const PodUsercards = [
    {
      title: 'Create Dispense Request',
      icon: truck,
      url: 'dispensing'
    },
    {
      title: 'List of Dispensing Materials',
      icon: Gatepasstruck,
      url: 'dispensing-list'
    },
    {
      title: 'Add New Product',
      icon: truck,
      url: 'product-list'
    },
    {
      title: 'Execute BMR',
      icon: truck,
      url: 'dispensing-list?type=granulation'
    },
    {
      title: 'Material Return Request',
      icon: truck,
      url: 'material-return-request'
    },
    {
      title: 'Product Master',
      icon: truck,
      url: 'product-masters-list'
    },
    {
      title: 'EBMR Master',
      icon: truck,
      url: 'ebmr-master-list'
    }
    // {
    //   title: 'Log Books',
    //   icon: truck,
    //   url: 'log-books'
    // }
  ];

  const PPICCards = [
    {
      title: 'Dispensing List',
      icon: Gatepasstruck,
      url: 'dispensing-list'
    }
    // {
    //   title: 'Log Books',
    //   icon: truck,
    //   url: 'log-books'
    // }
  ];

  const FinanceUser = [
    {
      title: 'Material Costing',
      icon: Gatepasstruck,
      url: 'material-costing-list'
    },
    {
      title: 'Invoice Details',
      icon: Gatepasstruck,
      url: 'invoice-details-list'
    }
    // {
    //   title: 'Log Books',
    //   icon: truck,
    //   url: 'log-books'
    // }
  ];

  const BDUser = [
    {
      title: 'Detail FG Batch Report',
      icon: Gatepasstruck,
      url: 'fg-batch-report'
    },
    {
      title: 'COGS Report',
      icon: Gatepasstruck,
      url: 'cogs-report'
    }
    // {
    //   title: 'Log Books',
    //   icon: truck,
    //   url: 'log-books'
    // }
  ];

  const QcCards =
    user?.level == 3
      ? [
          {
            title: 'Batch Approval',
            icon: truck,
            url: 'batches-list?type=batches'
          },
          {
            title: 'Sampling Material',
            icon: truck,
            url: '/samples-list'
          },
          {
            title: 'Test Request Forms',
            icon: truck,
            url: '/batches-list?type=test_requests'
          },
          {
            title: 'Create Dispense Request',
            icon: truck,
            url: 'dispense-material-list'
          },
          {
            title: 'Batch Numbering Log Book',
            icon: truck,
            url: 'log-book-list'
          }
          // {
          //   title: 'Log Books',
          //   icon: truck,
          //   url: 'log-books'
          // }
          // {
          //   title: 'Code to Code Transfer',
          //   icon: truck,
          //   url: 'code-to-code-list'
          // }
        ]
      : [
          {
            title: 'Batch Approval',
            icon: truck,
            url: 'batches-list'
          },
          {
            title: 'Sampling Material',
            icon: truck,
            url: '/samples-list'
          },
          {
            title: 'Test Request Forms',
            icon: truck,
            url: '/batches-list?type=test_requests'
          },
          {
            title: 'Create Dispense Request',
            icon: truck,
            url: 'dispense-material-list'
          },
          {
            title: 'Batch Numbering Log Book',
            icon: truck,
            url: 'log-book-list'
          }
          // {
          //   title: 'Log Books',
          //   icon: truck,
          //   url: 'log-books'
          // }
        ];

  const adminCards = [
    {
      title: 'User Management',
      icon: truck,
      url: 'users-list'
    },
    {
      title: 'Departments',
      icon: truck,
      url: 'departments-list'
    },
    {
      title: 'Designations',
      icon: truck,
      url: 'designations-list'
    }
  ];
  const cardView =
    user?.role === 'user' ||
    (user?.role === 'qa_super_user' && searchParams.get('type') === 'warehouse')
      ? cards
      : user?.role === 'prod_user' ||
        (user?.role === 'qa_super_user' &&
          searchParams.get('type') === 'production')
      ? PodUsercards
      : (user?.role === 'qc_initiator' && user?.level !== 3) ||
        (user?.role === 'qa_super_user' &&
          searchParams.get('type') === 'quality-assurance')
      ? QAcards
      : (user?.role === 'qc_initiator' && user?.level === 3) ||
        (user?.role === 'qa_super_user' &&
          searchParams.get('type') === 'quality-assurance')
      ? QALeadcards
      : user?.role === 'qa_initiator' ||
        (user?.role === 'qa_super_user' &&
          searchParams.get('type') === 'quality-control')
      ? QcCards
      : user?.role === 'admin' ||
        (user?.role === 'qa_super_user' && searchParams.get('type') === 'admin')
      ? adminCards
      : user?.role === 'ppic_user' ||
        (user?.role === 'qa_super_user' &&
          searchParams.get('type') === 'ppic_user')
      ? PPICCards
      : user?.role === 'finance_user' ||
        (user?.role === 'qa_super_user' &&
          searchParams.get('type') === 'finance_user')
      ? FinanceUser
      : user?.role === 'bd_user' ||
        (user?.role === 'qa_super_user' &&
          searchParams.get('type') === 'bd_user')
      ? BDUser
      : [];
  return (
    <>
      <Form form={form} layout="vertical">
        <Row gutter={24}>
          {cardView?.map((items, index) => {
            return (
              <>
                <Col
                  xs={12}
                  xl={4}
                  md={6}
                  sm={6}
                  span={6}
                  className={`${styles.cardbody1} mb-2`}
                  key={index}
                >
                  {/* <div
                    className={styles.container}
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = `${items.url}`;
                    }}
                  > */}
                  {/* <div className={styles.cardleft}>
                      <div className={styles.cardimage}>
                        <Image
                          // src="https://images.pexels.com/photos/220072/pexels-photo-220072.jpeg?auto=compress&amp;cs=tinysrgb&amp;h=100%&amp;w=1260"
                          src={items.icon}
                          preview={false}
                          // style={{ height: '100% !important' }}
                          // className={
                          //   user?.role === 'qc_initiator'
                          //     ? styles.qc_stickers
                          //     : styles.stickers
                          // }
                        />
                         <Image src="https://images.pexels.com/photos/220072/pexels-photo-220072.jpeg?auto=compress&amp;cs=tinysrgb&amp;h=100%&amp;w=1260" /> 
                      </div>
                      <div className={styles.cardtext}>
                        <p>{items.title}</p>
                      </div>
                    </div> */}
                  {/* </div> */}
                  <Card
                    hoverable
                    className={styles.cardbody}
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = items.url;
                    }}
                  >
                    <Space direction="vertical">
                      <Image
                        src={items.icon}
                        preview={false}
                        style={{ width: '80% !important' }}
                        className={
                          user?.role === 'qc_initiator'
                            ? styles.qc_stickers
                            : styles.stickers
                        }
                      />
                      <span>{items.title}</span>
                    </Space>
                  </Card>
                  <br />
                </Col>
              </>
            );
          })}
          {/* {cardView?.map((item, index) => {
            return (
              <Col
                xs={12}
                xl={12}
                md={12}
                sm={12}
                span={12}
                className={`${styles.cardbody1} mb-2`}
                key={index}
              >
                <Card hoverable className={styles.cardbody}>
                  <Space direction="vertical">
                    <Image
                      src={item.icon}
                      preview={false}
                      style={{ height: '200px' }}
                    />
                    <CustomButton 
                      className={styles.inwardbutton}
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.href = `${item.url}`;
                      }}
                    >
                      {item.name}
                    </CustomButton >
                  </Space>
                </Card>
              </Col>
            );
          })} */}
        </Row>
        {user?.role !== 'prod_user' ? (
          <Card hoverable className={styles.cardbody1}>
            <Row>
              <Col xs={24} xl={8} span={8}>
                <Space direction="vertical" size={19}>
                  <Image src={FileImage} preview={false} />

                  <span>
                    <h2 style={{ fontWeight: 'bold' }}>Our Policies</h2>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </span>
                </Space>
              </Col>

              <Col className="mt-3" xs={24} xl={16} span={16}>
                <Space direction="vertical" size={19}>
                  <span style={{ fontSize: '14px' }}>
                    <h4 style={{ fontWeight: 'bold' }}>
                      Material Reciept Guidelines
                    </h4>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using Content here, content here, making it look like
                    readable English. Many desktop publishing packages and web
                    page editors now use Lorem Ipsum as their default model
                    text, and a search for lorem ipsum will uncover many web
                    sites still in their infancy. <br />
                    <p style={{ float: 'right', color: 'red' }}>
                      Learn More <ArrowRightOutlined />
                    </p>
                  </span>

                  <span style={{ fontSize: '13px' }}>
                    <h4 style={{ fontWeight: 'bold' }}>Quality policy</h4>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using Content here, content here, making it look like
                    readable English. Many desktop publishing packages and web
                    page editors now use Lorem Ipsum as their default model
                    text, and a search for lorem ipsum will uncover many web
                    sites still in their infancy. <br />
                    <p style={{ float: 'right', color: 'red' }}>
                      Learn More <ArrowRightOutlined />
                    </p>
                  </span>
                </Space>
              </Col>
            </Row>
          </Card>
        ) : (
          ''
        )}
      </Form>
    </>
  );
};

export default DashBoardPage;
