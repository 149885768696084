import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  create_checklist,
  dispenseMaterialDetails,
  Dispensematerials,
  lc,
  get_compaign_batches
} from './api';
import { message } from 'antd';

const initialState = {
  materialDetails: {},
  getMaterialDetailslLoading: null
};

const actions = {
  CREATE_CHECKLIST: 'lc/CREATE_CHECKLIST',
  GET_DISPENSE_MATERIAL_DETAILS: 'dispense/GET_DISPENSE_MATERIAL_DETAILS',
  GET_PREVIOUS_PRODUCT: 'lc/GET_PREVIOUS_PRODUCT',
  GET_CAMPAIGN_BATCHES: 'lc/GET_CAMPAIGN_BATCHES'
};

export const createChecklist = createAsyncThunk(
  actions.CREATE_CHECKLIST,
  async (payload) => {
    const response = await create_checklist('create', payload);
    return response;
  }
);

export const getDispenseMaterialDetails = createAsyncThunk(
  actions.GET_DISPENSE_MATERIAL_DETAILS,
  async (payload) => {
    const response = await dispenseMaterialDetails('get-all', payload);
    // console.log('getDispenseMaterialDetails', response);
    return response;
  }
);

export const getAllDispenseMaterials = createAsyncThunk(
  actions.GET_ALL_DISPENSE_MATERIALS,
  async (payload) => {
    const response = await Dispensematerials('get-all', payload);

    return response;
  }
);

export const getPreviousProduct = createAsyncThunk(
  actions.GET_PREVIOUS_PRODUCT,
  async (payload) => {
    const response = await lc('previous_product', payload);

    return response;
  }
);

export const getCampaignBatches = createAsyncThunk(
  actions.GET_CAMPAIGN_BATCHES,
  async (payload) => {
    const response = await get_compaign_batches('compaign_batches', payload);

    return response;
  }
);

export const materialDetailSlice = createSlice({
  name: 'dispenseMaterialDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // console.log('builder before');
    builder
      .addCase(getDispenseMaterialDetails.pending, (state) => {
        state.getMaterialDetailslLoading = true;
      })
      .addCase(getDispenseMaterialDetails.fulfilled, (state, action) => {
        // console.log('check', action.payload);
        state.getMaterialDetailslLoading = false;

        const { success, data } = action.payload;

        if (success) {
          state.materialDetails = data;
        } else {
          state.materialDetails = '';
        }
      })
      .addCase(getDispenseMaterialDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getMaterialDetailslLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getPreviousProduct.pending, (state) => {
        state.getMaterialDetailslLoading = true;
      })
      .addCase(getPreviousProduct.fulfilled, (state, action) => {
        // console.log('check', action.payload);
        state.getMaterialDetailslLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getPreviousProduct.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getMaterialDetailslLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getCampaignBatches.pending, (state) => {
        state.getMaterialDetailslLoading = true;
      })
      .addCase(getCampaignBatches.fulfilled, (state) => {
        // console.log('check', action.payload);
        state.getMaterialDetailslLoading = false;

        // const { success, message: msg } = action.payload;

        // if (!success) {
        //   message.error(msg);
        // }
      })
      .addCase(getCampaignBatches.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getMaterialDetailslLoading = false;

        message.error(msg);
      });
  }
});

export default materialDetailSlice.reducer;
