import React, { useState, useEffect } from 'react';
import {
  Row,
  Form,
  Card,
  Space,
  Col,
  Tag,
  Input,
  Collapse,
  // message,
  // DatePicker,
  Divider
  // Badge,
  // Checkbox
  // Slider
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
// import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import { useDispatch } from 'react-redux';
// import { DeleteOutlined, PlusCircleFilled } from '@ant-design/icons';
// import truck from '@/features/qa/components/images/truck-delivery-done.gif';
import Layout from '@/common/Layout';
import {
  getAllParamsList,
  getconfigboms,
  createInstruction,
  printBMR,
  downloadUsers
  // createactualMaterials
} from '../../redux/slice';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import CustomButton from '@/common/CustomButton';
import CustomTable from '@/common/Content/CustomTable';
// import { getLoggedInUser } from 'utilities/helpers';

const { Panel } = Collapse;

const ViewExecuteBMR = () => {
  const [form] = Form.useForm();
  const params = useParams();

  const [processList, setProcessList] = useState([]);
  // const [process, setProcess] = useState([]);

  const dispatch = useDispatch();
  // const user = getLoggedInUser();

  useEffect(() => {
    // handleGetAllProcessList();
    handleGetAllBoms();
    handleGetAllParameters();
  }, []);

  const handleGetAllParameters = () => {
    dispatch(getAllParamsList()).then((res) => {
      if (res?.payload?.success) {
        // setParamsList(res?.payload?.data);
      }
    });
  };

  const handleGetAllBoms = () => {
    const payload = { id: params.id };
    dispatch(getconfigboms(payload)).then((res) => {
      if (res?.payload?.success) {
        res?.payload?.data?.map((item) => {
          item?.process_configs?.map((data) => {
            data.lots = [];
            [...Array(data?.lot_no)].map(() =>
              data.lots.push(data?.instruction_configs)
            );
            data.instructions = [];

            data.instructions.push({
              eqp_id: '',
              instruction: '',
              execute_bmr: []
            });

            data?.process_equipment_config?.map((params) => {
              params.parameters = [];
              params.accessories = [];
              // (params.lot = i + 1),
              params.parameters.push({
                parameter_id: '',
                sub_param_id: '',
                type_of_value: '',
                low: '',
                high: '',
                absolute_value: '',
                uom: '',
                actual_value: '',
                instructions: [{ instrction: '' }]
              });

              params.accessories.push({
                parameter_id: '',
                sub_param_id: '',
                type_of_value: '',
                low: '',
                high: '',
                absolute_value: '',
                uom: '',
                actual_value: '',
                instructions: [{ instrction: '' }]
              });
            });
          });
        });
        setProcessList(res?.payload?.data);
      }
    });
  };

  // const handleSubmit = (index, i, g, q) => {
  //   console.log(q);
  //   console.log(processList[index].process_configs[i].lots[g]);
  //   const payload = {
  //     product_id: processList[index].product_id,
  //     process_group_id: processList[index].id,
  //     process_id: processList[index].process_configs[i].id,
  //     lot_stage: `lot${g + 1}`,
  //     step: `step${q + 1}`,
  //     lot: processList[index].process_configs[i].lots[g][q]
  //   };

  //   dispatch(createactualMaterials(payload)).then((res) => {
  //     if (res?.payload?.success) {
  //       message.success(res?.payload?.message);
  //       handleGetAllBoms();
  //     }
  //   });
  //   console.log(payload);
  // };

  //   const onChange = (e) => {
  //     console.log(`checked = ${e.target.checked}`);
  //   };
  // console.log(setParameters, subcatgries);
  console.log(processList);
  const onFinish = () => {
    let config_materials = [];
    processList?.map((item) => {
      item?.process_configs?.map((materials) => {
        config_materials.push({
          process_master: [
            {
              process_config_id: materials?.id,
              instructions: materials?.instructions,
              // association_param: materials.params,
              // instruction_param: materials.instructions?.[0]?.[0],
              equipment_masters: materials?.process_equipment_config
            }
          ]
        });
      });
    });
    const payload = { product_id: params.id, process_group: config_materials };
    // console.log(payload);
    dispatch(createInstruction(payload)).then((res) => {
      if (res?.payload?.success) {
        history.push(`/bmr-params-view/${params.id}`);
      }
    });
  };

  // const handleChangeOther = (e, index, i, g, q, l) => {
  //   let list = [...processList];
  //   list[index].process_configs[i].lots[g][q].execute_bmr_details[
  //     l
  //   ].actual_value = e;
  //   setProcessList(list);
  // };

  // const handleChangeparamact = (e, index, i, g, q, l) => {
  //   console.log(e, index, i, g, q, l);
  //   console.log(processList);
  //   let list = [...processList];
  //   list[index].process_configs[i].lots[g][q].params[0].parameter_configs[
  //     l
  //   ].actual_value = e.target.value;
  //   setProcessList(list);
  // };

  // const handleChangeacces = (e, index, i, g, q, l) => {
  //   let list = [...processList];
  //   list[index].process_configs[i].lots[g][q].params[l].accessory_configs[
  //     l
  //   ].actual_value = e.target.value;
  //   setProcessList(list);
  // };

  const handlePrintBMRsheet = (index, id) => {
    const payload = { dispensing_request_id: id };
    dispatch(printBMR(payload)).then(function (response) {
      if (response?.payload?.success) {
        window.open(response?.payload?.url, '_blank');
      }
    });
  };

  const handleDownloadUsers = (index, id) => {
    const payload = { dispensing_request_id: id };
    dispatch(downloadUsers(payload)).then(function (response) {
      if (response?.payload?.success) {
        window.open(response?.payload?.data, '_blank');
      }
    });
  };
  // const handleChangevalue = (index, i, q, l, e) => {
  //   let list = [...actualParams];
  // list[index].process_configs[i].final_instr_param = true;
  // setProcessList(list);
  // };

  const column = [
    {
      title: 'Date & Time',
      dataIndex: 'fbb_date',
      key: 'fbb_date',
      ellipsis: false,
      width: '100px',
      visible: true,
      render: (_, record) =>
        moment(record.fbb_date).format('DD MMM YYYY HH:mm') || 'NA'
    },
    {
      title: `Granulating Solution`,
      children: [
        {
          title: `Initial Qty.in KG(W1)`,
          dataIndex: 'Granulating Solution',
          key: 'permission',
          ellipsis: false,
          width: '100px',
          visible: true,
          render: (_, record) => record.initial_qty || 'NA'
        },
        {
          title: `Final Qty.in KG(W2)`,
          dataIndex: 'Granulating Solution',
          key: 'permission',
          ellipsis: false,
          width: '100px',
          visible: true,
          render: (_, record) => record.final_qty || 'NA'
        },
        {
          title: `Qty. Sprayed in KG(Y= W1-W2)`,
          dataIndex: 'Granulating Solution',
          key: 'permission',
          ellipsis: false,
          width: '100px',
          visible: true,
          render: (_, record) => record.sprayed_qty || 'NA'
        }
      ]
    },
    {
      title: `Inlet Temp.`,
      children: [
        {
          title: `Set`,
          dataIndex: 'Inlet temperature',
          key: 'permission',
          ellipsis: false,
          width: '100px',
          visible: true,
          render: (_, record) => record.inlet_set || 'NA'
        },
        {
          title: `Actual`,
          dataIndex: 'Inlet temperature',
          key: 'permission',
          ellipsis: false,
          width: '100px',
          visible: true,
          render: (_, record) => record.inlet_observed || 'NA'
        }
      ]
    },
    {
      title: `Product Temp.`,
      dataIndex: 'Product temperature',
      key: 'permission',
      ellipsis: false,
      width: '100px',
      visible: true,
      render: (_, record) => record.product_temp || 'NA'
    },
    {
      title: `Exhaust Temp.`,
      dataIndex: 'Exhaust temperature',
      key: 'permission',
      ellipsis: false,
      width: '100px',
      visible: true,
      render: (_, record) => record.exhaust_temp || 'NA'
    },
    {
      title: `Airflow (CFM)`,
      dataIndex: 'Air flow (CFM)',
      key: 'permission',
      ellipsis: false,
      visible: true,
      width: '100px',
      render: (_, record) => record.air_flow || 'NA'
    },
    {
      title: `Pump (RPM)`,
      dataIndex: 'Pump',
      key: 'permission',
      ellipsis: false,
      width: '100px',
      visible: true,
      render: (_, record) => record.pump_rpm || 'NA'
    },
    {
      title: `Spray rate (gm/min/gun)`,
      dataIndex: 'Spray rate (g/min)',
      key: 'permission',
      ellipsis: false,
      width: '100px',
      visible: true,
      render: (_, record) => record.spray_rate || 'NA'
    },
    {
      title: `Atomaization(Bar)`,
      children: [
        {
          title: `Set`,
          dataIndex: 'Inlet temperature',
          key: 'permission',
          ellipsis: false,
          width: '100px',
          visible: true,
          render: (_, record) => record.automisation_set || 'NA'
        },
        {
          title: `Actual`,
          dataIndex: 'Inlet temperature',
          key: 'permission',
          ellipsis: false,
          width: '100px',
          visible: true,
          render: (_, record) => record.automisation || 'NA'
        }
      ]
    },
    {
      title: `Filter Shaking Pause Time (sec)`,
      dataIndex: 'Filter shaking pause time (Sec)',
      key: 'permission',
      ellipsis: false,
      width: '100px',
      visible: true,
      render: (_, record) => record.filter_shaking || 'NA'
    },
    {
      title: `Inlet RH (%)`,
      dataIndex: 'Inlet RH',
      key: 'permission',
      ellipsis: false,
      width: '100px',
      visible: true,
      render: (_, record) => record.inlet_RH | 'NA'
    },
    {
      title: `Filter Bag Differential Pressure`,
      dataIndex: 'permission',
      key: 'permission',
      ellipsis: false,
      width: '100px',
      visible: true,
      render: (_, record) => record.filter_bag || 'NA'
    },
    {
      title: `Filter shaking mode`,
      dataIndex: 'permission',
      key: 'permission',
      ellipsis: false,
      width: '100px',
      visible: true,
      render: (_, record) => record.filter_shake_mode || 'NA'
    },
    {
      title: `Remarks`,
      dataIndex: 'Remarks',
      key: 'Remarks',
      ellipsis: false,
      width: '100px',
      visible: true,
      render: (_, record) => record.Remarks || 'NA'
    },
    {
      title: `Done By Sign & Date`,
      dataIndex: 'permission',
      key: 'permission',
      ellipsis: false,
      width: '100px',
      visible: true,
      render: (_, record) => record.done_by || 'NA'
    }
  ];

  console.log(processList);
  return (
    <Layout sider={false}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        {processList?.map((item, index) => {
          return (
            <>
              <Collapse
                collapsible="header"
                defaultActiveKey={['0']}
                className={styles.CustomPanel1}
                // key={index}
                // className={styles.cardStyles_approver2}
              >
                <Panel
                  header={
                    item?.group_mod_name ? (
                      <Tag color="rgb(201 23 97)" className={styles.tagfont}>
                        {item?.group_mod_name}
                      </Tag>
                    ) : (
                      item?.bom_process_group_master?.process_group_name
                    )
                  }
                  className={styles.CustomPanel2}
                  key={index}
                  extra={
                    <Space>
                      {item?.group_mod_name ? (
                        // <Tag
                        //   color="rgb(201 23 97)"
                        //   style={{ fontWeight: '100' }}
                        // >
                        <span style={{ fontSize: '18px' }}>
                          {item?.bom_process_group_master?.process_group_name}
                        </span>
                      ) : (
                        // </Tag>
                        ''
                      )}
                      {index == 0 ? (
                        <Space>
                          <CustomButton
                            type="primary"
                            onClick={() =>
                              handleDownloadUsers(index, params?.id)
                            }
                          >
                            EBMR Users
                          </CustomButton>
                          <CustomButton
                            type="primary"
                            onClick={() =>
                              handlePrintBMRsheet(index, params?.id)
                            }
                          >
                            Print
                          </CustomButton>
                        </Space>
                      ) : (
                        ''
                      )}
                    </Space>
                  }
                >
                  {item?.process_configs?.map((param, i) => {
                    return (
                      <>
                        {/* <Badge.Ribbon
                          text={
                            item?.bom_process_group_master?.material_level
                              ? 'Materials'
                              : 'Lot'
                          }
                          className={styles.tagStyle1}
                        > */}
                        <Card
                          key={i}
                          title={
                            param?.bom_process_master?.process_mod_name ? (
                              <Tag
                                color="rgb(201 23 97)"
                                className={styles.tagfont}
                              >
                                {param?.bom_process_master?.process_mod_name}
                              </Tag>
                            ) : (
                              param?.bom_process_master?.process_name
                            )
                          }
                          className={styles.SubPanel}
                          extra={
                            <Space>
                              <span style={{ fontSize: '18px !important' }}>
                                {param?.bom_process_master?.process_mod_name
                                  ? param?.bom_process_master?.process_name
                                  : ''}
                              </span>
                            </Space>
                          }
                        >
                          <>
                            <Row gutter={24}>
                              <Col span={24}>
                                <p
                                  style={{
                                    fontSize: '14px',
                                    color: '#263238'
                                    // marginTop: '7px'
                                  }}
                                >
                                  Materials used during this process
                                </p>
                              </Col>
                            </Row>

                            <Row gutter={24}>
                              <Col span={24}>
                                {param?.bom_materials?.map((item, j) => {
                                  return (
                                    <Space size={1} key={j}>
                                      <p className={styles.paragraph}>
                                        <Tag
                                          key={j}
                                          // style={{
                                          //   padding: '3px',
                                          //   color: 'rgb(201 23 97) !important'
                                          // }}
                                          className={styles.tagStyle}
                                        >
                                          {item?.material?.name}
                                        </Tag>
                                      </p>
                                    </Space>
                                  );
                                })}
                              </Col>
                            </Row>

                            {param?.lot_no || param?.material_level
                              ? param?.lots.map((x, g) => (
                                  <>
                                    <Collapse
                                      collapsible="header"
                                      defaultActiveKey={['0']}
                                      className={styles.CustomPanel1}
                                      // key={i}
                                      // className={styles.cardStyles_approver2}
                                    >
                                      <Panel
                                        header={
                                          param?.material_level
                                            ? `Batch`
                                            : `LOT ${g + 1}`
                                        }
                                        className={styles.CustomPanel2}
                                        key={g}
                                      >
                                        {param?.instruction_configs?.map(
                                          (instr, q) => {
                                            const act_values =
                                              param?.bmr_lot_values.filter(
                                                (data) =>
                                                  data?.step_no ==
                                                    instr?.step_no &&
                                                  data?.lot_no == `lot${g + 1}`
                                              );
                                            console.log(act_values);
                                            const parameters =
                                              param?.process_equipment_config?.filter(
                                                (params) =>
                                                  params?.id ==
                                                  instr?.process_eqp_config_id
                                              );
                                            let list = [...processList];
                                            console.log(
                                              list[index].process_configs[i]
                                                .lots[g][q]
                                            );
                                            list[index].process_configs[i].lots[
                                              g
                                            ][q].params = [];
                                            list[index].process_configs[i].lots[
                                              g
                                            ][q].params.push(parameters[0]);
                                            // // setProcessList(list);

                                            // let parametrs = act_values[0]
                                            //   ?.equipment_model?.id
                                            //   ? instr?.params[0]?.process_eqp_ids.filter(
                                            //       (id) =>
                                            //         id.equipment_model_id ==
                                            //         act_values[0]
                                            //           ?.equipment_model?.id
                                            //     )
                                            //   : [];

                                            return (
                                              <>
                                                <Card
                                                  className={styles.SubPanel3}
                                                >
                                                  <Card
                                                    className={styles.SubPanel2}
                                                    key={q}
                                                  >
                                                    <h3
                                                      style={{
                                                        fontWeight: 'bold',
                                                        color: '#c91761'
                                                      }}
                                                    >
                                                      Step - {q + 1}
                                                    </h3>
                                                    <Row gutter={24}>
                                                      <Col span={24}>
                                                        <span
                                                          style={{
                                                            fontSize: '14px',
                                                            fontWeight: '100'
                                                          }}
                                                          key={index}
                                                        >
                                                          <span
                                                            dangerouslySetInnerHTML={{
                                                              __html:
                                                                instr?.instruction_step
                                                            }}
                                                            // className={styles.tdbody}
                                                          />
                                                        </span>
                                                      </Col>
                                                    </Row>
                                                  </Card>
                                                  <br />
                                                  {/* {instr?.process_eqp_config_id ? ( */}
                                                  <>
                                                    {act_values?.[0]
                                                      ?.bmr_eqp_actuals
                                                      .length ? (
                                                      <span
                                                        style={{
                                                          fontSize: '18px'
                                                        }}
                                                      >
                                                        Equipment & Parametrs
                                                      </span>
                                                    ) : (
                                                      ''
                                                    )}
                                                    {act_values?.[0]
                                                      ?.fbb_processes.length ? (
                                                      <Card
                                                        title="Fluid Bed Parameters"
                                                        className={
                                                          styles.SubPanel
                                                        }
                                                      >
                                                        <div
                                                          className={`${styles.table_row}`}
                                                        >
                                                          <CustomTable
                                                            columns={column}
                                                            dataSource={
                                                              act_values?.[0]
                                                                ?.fbb_processes
                                                            }
                                                            bordered
                                                            pagination={false}
                                                            size="middle"
                                                            scroll={{
                                                              x: 'calc(700px + 50%)',
                                                              y: 240
                                                            }}
                                                          />
                                                        </div>
                                                      </Card>
                                                    ) : (
                                                      ''
                                                    )}

                                                    {/* <Row gutter={24}>
                                                        <Col span={8}>
                                                          <FormItem
                                                            label="Name"
                                                            rules={[
                                                              {
                                                                required: false,
                                                                message:
                                                                  'Select Material'
                                                              }
                                                            ]}
                                                            className={
                                                              styles.customLable
                                                            }
                                                          >
                                                            <Input
                                                              value={
                                                                act_values[0]
                                                                  ?.equipment_master
                                                                  ?.equipment_name
                                                              }
                                                              disabled
                                                            />
                                                          </FormItem>
                                                        </Col>
                                                        <Col span={8}>
                                                          <FormItem
                                                            label="Code"
                                                            rules={[
                                                              {
                                                                required: false,
                                                                message:
                                                                  'Select Code'
                                                              }
                                                            ]}
                                                            className={
                                                              styles.customLable
                                                            }
                                                            placeholder="Select Equipment Code"
                                                          >
                                                            <Input
                                                              value={
                                                                act_values[0]
                                                                  ?.equipment_model
                                                                  ?.equipment_id
                                                              }
                                                              disabled
                                                            />
                                                          </FormItem>
                                                        </Col>
                                                        <Col span={8}>
                                                          <FormItem
                                                            label="Model"
                                                            className={
                                                              styles.customLable
                                                            }
                                                          >
                                                            <Input
                                                              value={
                                                                act_values[0]
                                                                  ?.equipment_model
                                                                  ?.equipment_capacity
                                                              }
                                                              disabled
                                                            />
                                                          </FormItem>
                                                        </Col>
                                                      </Row> */}
                                                    {/* <Row gutter={24}>
                                                        {parametrs?.[0]?.parameter_configs?.map(
                                                          (param, l) => {
                                                            return (
                                                              <>
                                                                <Col
                                                                  span={8}
                                                                  key={l}
                                                                >
                                                                  <FormItem
                                                                    label="Parameter"
                                                                    rules={[
                                                                      {
                                                                        required: false,
                                                                        message:
                                                                          'Select Material'
                                                                      }
                                                                    ]}
                                                                    className={
                                                                      styles.customLable
                                                                    }
                                                                  >
                                                                    <Input
                                                                      value={
                                                                        param?.name_param
                                                                      }
                                                                      disabled
                                                                    />
                                                                  </FormItem>
                                                                </Col>
                                                                <Col
                                                                  span={8}
                                                                  key={l}
                                                                >
                                                                  <FormItem
                                                                    label="Range/Absolute"
                                                                    rules={[
                                                                      {
                                                                        required: false,
                                                                        message:
                                                                          'Select Material'
                                                                      }
                                                                    ]}
                                                                    className={
                                                                      styles.customLable
                                                                    }
                                                                  >
                                                                    <Input
                                                                      value={
                                                                        param?.low_param
                                                                          ? `${param?.low_param}-${param?.high_param}`
                                                                          : param?.absolute_value ||
                                                                            'NA'
                                                                      }
                                                                      disabled
                                                                    />
                                                                  </FormItem>
                                                                </Col>
                                                                <Col
                                                                  span={8}
                                                                  key={l}
                                                                >
                                                                  <FormItem
                                                                    label="Actual Value"
                                                                    rules={[
                                                                      {
                                                                        required: false,
                                                                        message:
                                                                          'Select Material'
                                                                      }
                                                                    ]}
                                                                    className={
                                                                      styles.customLable
                                                                    }
                                                                  >
                                                                    <Input
                                                                      value={
                                                                        act_values?.[0]
                                                                          ?.instruction_config
                                                                          ?.parameter_actual_value?.[
                                                                          l
                                                                        ]
                                                                          ?.actual_value
                                                                      }
                                                                      placeholder="Enetr Value"
                                                                      disabled={
                                                                        act_values?.[0]
                                                                          ?.instruction_config
                                                                          ?.parameter_actual_value?.[
                                                                          l
                                                                        ]
                                                                          ?.actual_value
                                                                          ? true
                                                                          : false
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeparamact(
                                                                          e,
                                                                          index,
                                                                          i,
                                                                          g,
                                                                          q,
                                                                          l,
                                                                          param.pro_eqp_id
                                                                        )
                                                                      }
                                                                    />
                                                                  </FormItem>
                                                                </Col>
                                                              </>
                                                            );
                                                          }
                                                        )}
                                                      </Row> */}
                                                    {/* <Row gutter={24}>
                                                        {parametrs?.[0]?.accessory_configs?.map(
                                                          (param, l) => {
                                                            return (
                                                              <>
                                                                <Col
                                                                  span={8}
                                                                  key={l}
                                                                >
                                                                  <FormItem
                                                                    label="Accessory"
                                                                    rules={[
                                                                      {
                                                                        required: false,
                                                                        message:
                                                                          'Select Material'
                                                                      }
                                                                    ]}
                                                                    className={
                                                                      styles.customLable
                                                                    }
                                                                  >
                                                                    <Input
                                                                      value={
                                                                        param?.name_accessory
                                                                      }
                                                                      disabled
                                                                    />
                                                                  </FormItem>
                                                                </Col>
                                                                <Col
                                                                  span={8}
                                                                  key={l}
                                                                >
                                                                  <FormItem
                                                                    label="Range/Absolute"
                                                                    rules={[
                                                                      {
                                                                        required: false,
                                                                        message:
                                                                          'Select Material'
                                                                      }
                                                                    ]}
                                                                    className={
                                                                      styles.customLable
                                                                    }
                                                                  >
                                                                    <Input
                                                                      value={
                                                                        param?.low_param
                                                                          ? `${param?.low_param}-${param?.high_param}`
                                                                          : param?.absolute_value ||
                                                                            'NA'
                                                                      }
                                                                      disabled
                                                                    />
                                                                  </FormItem>
                                                                </Col>
                                                                <Col
                                                                  span={8}
                                                                  key={l}
                                                                >
                                                                  <FormItem
                                                                    label="Actual Value"
                                                                    rules={[
                                                                      {
                                                                        required: false,
                                                                        message:
                                                                          'Select Material'
                                                                      }
                                                                    ]}
                                                                    className={
                                                                      styles.customLable
                                                                    }
                                                                  >
                                                                    <Input
                                                                      value={
                                                                        act_values?.[0]
                                                                          ?.instruction_config
                                                                          ?.acccessory_actual_values?.[
                                                                          l
                                                                        ]
                                                                          ?.actual_value
                                                                      }
                                                                      disabled={
                                                                        act_values?.[0]
                                                                          ?.instruction_config
                                                                          ?.acccessory_actual_values?.[
                                                                          l
                                                                        ]
                                                                          ?.actual_value
                                                                          ? true
                                                                          : false
                                                                      }
                                                                      placeholder="Enetr Value"
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeacces(
                                                                          e,
                                                                          index,
                                                                          i,
                                                                          g,
                                                                          q,
                                                                          l,
                                                                          param.pro_eqp_id
                                                                        )
                                                                      }
                                                                    />
                                                                  </FormItem>
                                                                </Col>
                                                              </>
                                                            );
                                                          }
                                                        )}
                                                      </Row> */}
                                                    {/* <Row gutter={24}>
                                                        {parameters?.[0]?.parameter_configs?.map(
                                                          (param, l) => {
                                                            return (
                                                              <>
                                                                <Col
                                                                  span={8}
                                                                  key={l}
                                                                >
                                                                  <FormItem
                                                                    label="Parameter"
                                                                    rules={[
                                                                      {
                                                                        required: false,
                                                                        message:
                                                                          'Select Material'
                                                                      }
                                                                    ]}
                                                                    className={
                                                                      styles.customLable
                                                                    }
                                                                  >
                                                                    <Input
                                                                      value={
                                                                        param?.name_param
                                                                      }
                                                                      disabled
                                                                    />
                                                                  </FormItem>
                                                                </Col>
                                                                <Col
                                                                  span={8}
                                                                  key={l}
                                                                >
                                                                  <FormItem
                                                                    label="Range/Absolute"
                                                                    rules={[
                                                                      {
                                                                        required: false,
                                                                        message:
                                                                          'Select Material'
                                                                      }
                                                                    ]}
                                                                    className={
                                                                      styles.customLable
                                                                    }
                                                                  >
                                                                    <Input
                                                                      value={
                                                                        param?.low_param
                                                                          ? `${param?.low_param}-${param?.high_param}`
                                                                          : param?.absolute_value ||
                                                                            'NA'
                                                                      }
                                                                      disabled
                                                                    />
                                                                  </FormItem>
                                                                </Col>
                                                                <Col
                                                                  span={8}
                                                                  key={l}
                                                                >
                                                                  <FormItem
                                                                    label="Actual Value"
                                                                    rules={[
                                                                      {
                                                                        required: false,
                                                                        message:
                                                                          'Select Material'
                                                                      }
                                                                    ]}
                                                                    className={
                                                                      styles.customLable
                                                                    }
                                                                  >
                                                                    <Input
                                                                      value={
                                                                        act_values?.[0]
                                                                          ?.instruction_config
                                                                          ?.parameter_actual_value?.[
                                                                          l
                                                                        ]
                                                                          ?.actual_value
                                                                      }
                                                                      placeholder="Enetr Value"
                                                                      disabled={
                                                                        act_values?.[0]
                                                                          ?.instruction_config
                                                                          ?.parameter_actual_value?.[
                                                                          l
                                                                        ]
                                                                          ?.actual_value
                                                                          ? true
                                                                          : false
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeparamact(
                                                                          e,
                                                                          index,
                                                                          i,
                                                                          g,
                                                                          q,
                                                                          l
                                                                        )
                                                                      }
                                                                    />
                                                                  </FormItem>
                                                                </Col>
                                                              </>
                                                            );
                                                          }
                                                        )}
                                                      </Row> */}
                                                    {/* <Row gutter={24}>
                                                        {parameters?.[0]?.accessory_configs?.map(
                                                          (param, l) => {
                                                            return (
                                                              <>
                                                                <Col
                                                                  span={8}
                                                                  key={l}
                                                                >
                                                                  <FormItem
                                                                    label="Accessory"
                                                                    rules={[
                                                                      {
                                                                        required: false,
                                                                        message:
                                                                          'Select Material'
                                                                      }
                                                                    ]}
                                                                    className={
                                                                      styles.customLable
                                                                    }
                                                                  >
                                                                    <Input
                                                                      value={
                                                                        param?.name_accessory
                                                                      }
                                                                      disabled
                                                                    />
                                                                  </FormItem>
                                                                </Col>
                                                                <Col
                                                                  span={8}
                                                                  key={l}
                                                                >
                                                                  <FormItem
                                                                    label="Range/Absolute"
                                                                    rules={[
                                                                      {
                                                                        required: false,
                                                                        message:
                                                                          'Select Material'
                                                                      }
                                                                    ]}
                                                                    className={
                                                                      styles.customLable
                                                                    }
                                                                  >
                                                                    <Input
                                                                      value={
                                                                        param?.low_param
                                                                          ? `${param?.low_param}-${param?.high_param}`
                                                                          : param?.absolute_value ||
                                                                            'NA'
                                                                      }
                                                                      disabled
                                                                    />
                                                                  </FormItem>
                                                                </Col>
                                                                <Col
                                                                  span={8}
                                                                  key={l}
                                                                >
                                                                  <FormItem
                                                                    label="Actual Value"
                                                                    rules={[
                                                                      {
                                                                        required: false,
                                                                        message:
                                                                          'Select Material'
                                                                      }
                                                                    ]}
                                                                    className={
                                                                      styles.customLable
                                                                    }
                                                                  >
                                                                    <Input
                                                                      value={
                                                                        act_values?.[0]
                                                                          ?.instruction_config
                                                                          ?.acccessory_actual_values?.[
                                                                          l
                                                                        ]
                                                                          ?.actual_value
                                                                      }
                                                                      disabled={
                                                                        act_values?.[0]
                                                                          ?.instruction_config
                                                                          ?.acccessory_actual_values?.[
                                                                          l
                                                                        ]
                                                                          ?.actual_value
                                                                          ? true
                                                                          : false
                                                                      }
                                                                      placeholder="Enetr Value"
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeacces(
                                                                          e,
                                                                          index,
                                                                          i,
                                                                          g,
                                                                          q,
                                                                          l
                                                                        )
                                                                      }
                                                                    />
                                                                  </FormItem>
                                                                </Col>
                                                              </>
                                                            );
                                                          }
                                                        )}
                                                      </Row> */}
                                                    {act_values?.[0]
                                                      ?.instruction_config
                                                      ?.exe_bmr_actuals
                                                      .length ? (
                                                      <Card
                                                        title="Execute BMR's"
                                                        className={
                                                          styles.SubPanel
                                                        }
                                                      >
                                                        {act_values?.[0]
                                                          ?.bmr_eqp_actuals
                                                          .length
                                                          ? act_values?.[0]?.bmr_eqp_actuals.map(
                                                              (data, kk) => {
                                                                return (
                                                                  <>
                                                                    <Card
                                                                      className={
                                                                        styles.SubPanel3
                                                                      }
                                                                    >
                                                                      <Row
                                                                        gutter={
                                                                          24
                                                                        }
                                                                        key={kk}
                                                                      >
                                                                        <Col
                                                                          span={
                                                                            8
                                                                          }
                                                                        >
                                                                          <FormItem
                                                                            label="Name"
                                                                            rules={[
                                                                              {
                                                                                required: false,
                                                                                message:
                                                                                  'Select Material'
                                                                              }
                                                                            ]}
                                                                            className={
                                                                              styles.customLable
                                                                            }
                                                                          >
                                                                            <Input
                                                                              value={
                                                                                data
                                                                                  ?.equipment_master
                                                                                  ?.equipment_name
                                                                              }
                                                                              disabled
                                                                            />
                                                                          </FormItem>
                                                                        </Col>
                                                                        <Col
                                                                          span={
                                                                            8
                                                                          }
                                                                        >
                                                                          <FormItem
                                                                            label="Code"
                                                                            rules={[
                                                                              {
                                                                                required: false,
                                                                                message:
                                                                                  'Select Material'
                                                                              }
                                                                            ]}
                                                                            className={
                                                                              styles.customLable
                                                                            }
                                                                          >
                                                                            <Input
                                                                              value={
                                                                                data
                                                                                  ?.equipment_model
                                                                                  ?.equipment_id
                                                                              }
                                                                              disabled
                                                                            />
                                                                          </FormItem>
                                                                        </Col>
                                                                        <Col
                                                                          span={
                                                                            8
                                                                          }
                                                                        >
                                                                          <FormItem
                                                                            label="Model"
                                                                            rules={[
                                                                              {
                                                                                required: false,
                                                                                message:
                                                                                  'Select Material'
                                                                              }
                                                                            ]}
                                                                            className={
                                                                              styles.customLable
                                                                            }
                                                                          >
                                                                            <Input
                                                                              value={
                                                                                data
                                                                                  ?.equipment_model
                                                                                  ?.equipment_capacity
                                                                              }
                                                                              disabled
                                                                            />
                                                                          </FormItem>
                                                                        </Col>
                                                                      </Row>
                                                                      {data
                                                                        ?.parameter_actual_values
                                                                        .length
                                                                        ? data?.parameter_actual_values?.map(
                                                                            (
                                                                              parameters,
                                                                              ll
                                                                            ) => {
                                                                              return (
                                                                                <Row
                                                                                  gutter={
                                                                                    24
                                                                                  }
                                                                                  key={
                                                                                    ll
                                                                                  }
                                                                                >
                                                                                  <Col
                                                                                    span={
                                                                                      8
                                                                                    }
                                                                                    key={
                                                                                      ll
                                                                                    }
                                                                                  >
                                                                                    <FormItem
                                                                                      label="Parameter"
                                                                                      rules={[
                                                                                        {
                                                                                          required: false,
                                                                                          message:
                                                                                            'Select Material'
                                                                                        }
                                                                                      ]}
                                                                                      className={
                                                                                        styles.customLable
                                                                                      }
                                                                                    >
                                                                                      <Input
                                                                                        value={
                                                                                          parameters
                                                                                            ?.parameter_config
                                                                                            ?.name_param
                                                                                        }
                                                                                        disabled
                                                                                      />
                                                                                    </FormItem>
                                                                                  </Col>
                                                                                  <Col
                                                                                    span={
                                                                                      8
                                                                                    }
                                                                                  >
                                                                                    <FormItem
                                                                                      label="Range/Absolute"
                                                                                      rules={[
                                                                                        {
                                                                                          required: false,
                                                                                          message:
                                                                                            'Select Material'
                                                                                        }
                                                                                      ]}
                                                                                      className={
                                                                                        styles.customLable
                                                                                      }
                                                                                    >
                                                                                      <Input
                                                                                        value={
                                                                                          parameters
                                                                                            ?.parameter_config
                                                                                            ?.low_param
                                                                                            ? `${parameters?.parameter_config?.low_param}-${parameters?.parameter_config?.high_param}`
                                                                                            : parameters
                                                                                                ?.parameter_config
                                                                                                ?.absolute_value ||
                                                                                              'NA'
                                                                                        }
                                                                                        disabled
                                                                                      />
                                                                                    </FormItem>
                                                                                  </Col>
                                                                                  <Col
                                                                                    span={
                                                                                      8
                                                                                    }
                                                                                  >
                                                                                    <FormItem
                                                                                      label="Actual Value"
                                                                                      rules={[
                                                                                        {
                                                                                          required: false,
                                                                                          message:
                                                                                            'Select Material'
                                                                                        }
                                                                                      ]}
                                                                                      className={
                                                                                        styles.customLable
                                                                                      }
                                                                                    >
                                                                                      <Input
                                                                                        value={
                                                                                          parameters?.actual_value
                                                                                        }
                                                                                        disabled
                                                                                      />
                                                                                    </FormItem>
                                                                                  </Col>
                                                                                </Row>
                                                                              );
                                                                            }
                                                                          )
                                                                        : ''}
                                                                      <Row
                                                                        gutter={
                                                                          24
                                                                        }
                                                                      >
                                                                        {act_values?.[0]
                                                                          ?.instruction_config
                                                                          ?.exe_bmr_actuals
                                                                          .length
                                                                          ? act_values?.[0]?.instruction_config?.exe_bmr_actuals?.map(
                                                                              (
                                                                                bmrs,
                                                                                f
                                                                              ) => {
                                                                                return (
                                                                                  <>
                                                                                    {f >
                                                                                      0 &&
                                                                                    bmrs?.type_sequence !==
                                                                                      act_values?.[0]
                                                                                        ?.instruction_config
                                                                                        ?.exe_bmr_actuals[
                                                                                        f -
                                                                                          1
                                                                                      ]
                                                                                        ?.type_sequence ? (
                                                                                      <Divider />
                                                                                    ) : (
                                                                                      ''
                                                                                    )}
                                                                                    <Col
                                                                                      span={
                                                                                        12
                                                                                      }
                                                                                      key={
                                                                                        f
                                                                                      }
                                                                                    >
                                                                                      <FormItem
                                                                                        label={
                                                                                          bmrs?.exe_nama
                                                                                        }
                                                                                        rules={[
                                                                                          {
                                                                                            required: false,
                                                                                            message:
                                                                                              'Select Material'
                                                                                          }
                                                                                        ]}
                                                                                        className={
                                                                                          styles.customLable
                                                                                        }
                                                                                      >
                                                                                        <Input
                                                                                          value={
                                                                                            bmrs
                                                                                              ?.execute_bmr_detail
                                                                                              ?.exe_type ==
                                                                                            'Date'
                                                                                              ? moment(
                                                                                                  bmrs?.actual_value
                                                                                                ).format(
                                                                                                  'DD MMM YYYY HH:mm'
                                                                                                )
                                                                                              : bmrs?.actual_value
                                                                                          }
                                                                                          disabled={
                                                                                            true
                                                                                          }
                                                                                          placeholder="Enter value"
                                                                                        />
                                                                                      </FormItem>
                                                                                    </Col>
                                                                                  </>
                                                                                );
                                                                              }
                                                                            )
                                                                          : ''}
                                                                        {instr?.bmr_show ? (
                                                                          <Divider />
                                                                        ) : (
                                                                          ''
                                                                        )}
                                                                      </Row>
                                                                      <Row
                                                                        gutter={
                                                                          24
                                                                        }
                                                                      >
                                                                        {act_values?.[0]?.container_processes?.map(
                                                                          (
                                                                            param,
                                                                            n
                                                                          ) => {
                                                                            return (
                                                                              <>
                                                                                <Col
                                                                                  span={
                                                                                    6
                                                                                  }
                                                                                  key={
                                                                                    n
                                                                                  }
                                                                                >
                                                                                  <FormItem
                                                                                    label="Container"
                                                                                    rules={[
                                                                                      {
                                                                                        required: false,
                                                                                        message:
                                                                                          'Select Container'
                                                                                      }
                                                                                    ]}
                                                                                    className={
                                                                                      styles.customLable
                                                                                    }
                                                                                  >
                                                                                    <Input
                                                                                      placeholder="Enter Container"
                                                                                      value={
                                                                                        param?.container
                                                                                      }
                                                                                      disabled
                                                                                    />
                                                                                  </FormItem>
                                                                                </Col>
                                                                                <Col
                                                                                  span={
                                                                                    6
                                                                                  }
                                                                                >
                                                                                  <FormItem
                                                                                    label="Tare Weight"
                                                                                    rules={[
                                                                                      {
                                                                                        required: false,
                                                                                        message:
                                                                                          'Select Container'
                                                                                      }
                                                                                    ]}
                                                                                    className={
                                                                                      styles.customLable
                                                                                    }
                                                                                  >
                                                                                    <Input
                                                                                      placeholder="Enter Tare Weight"
                                                                                      value={
                                                                                        param?.tare_weight
                                                                                      }
                                                                                      disabled
                                                                                    />
                                                                                  </FormItem>
                                                                                </Col>
                                                                                <Col
                                                                                  span={
                                                                                    6
                                                                                  }
                                                                                >
                                                                                  <FormItem
                                                                                    label="Net Weight"
                                                                                    rules={[
                                                                                      {
                                                                                        required: false,
                                                                                        message:
                                                                                          'Select Container'
                                                                                      }
                                                                                    ]}
                                                                                    className={
                                                                                      styles.customLable
                                                                                    }
                                                                                  >
                                                                                    <Input
                                                                                      placeholder="Enter Net Weight"
                                                                                      value={
                                                                                        param?.net_weight
                                                                                      }
                                                                                      disabled
                                                                                    />
                                                                                  </FormItem>
                                                                                </Col>
                                                                                <Col
                                                                                  span={
                                                                                    6
                                                                                  }
                                                                                >
                                                                                  <FormItem
                                                                                    label="Gross Weight"
                                                                                    rules={[
                                                                                      {
                                                                                        required: false,
                                                                                        message:
                                                                                          'Select Container'
                                                                                      }
                                                                                    ]}
                                                                                    className={
                                                                                      styles.customLable
                                                                                    }
                                                                                  >
                                                                                    <Input
                                                                                      placeholder="Enter Gross Weight"
                                                                                      value={
                                                                                        param?.gross_weight
                                                                                      }
                                                                                      disabled
                                                                                    />
                                                                                  </FormItem>
                                                                                </Col>
                                                                              </>
                                                                            );
                                                                          }
                                                                        )}
                                                                      </Row>
                                                                    </Card>
                                                                    <br />
                                                                  </>
                                                                );
                                                              }
                                                            )
                                                          : ''}
                                                      </Card>
                                                    ) : (
                                                      ''
                                                    )}
                                                    {/*  */}
                                                  </>

                                                  {/* <FormItem className="text-center">
                                                    <Space>
                                                      <CustomButton
                                                        className={
                                                          styles.inwardButton
                                                        }
                                                        onClick={() =>
                                                          handleSubmit(
                                                            index,
                                                            i,
                                                            g,
                                                            q
                                                          )
                                                        }
                                                        type="primary"
                                                        // size="small"
                                                        disabled={
                                                          act_values?.length
                                                            ? true
                                                            : false
                                                        }
                                                      >
                                                        Save
                                                      </CustomButton>
                                                    </Space>
                                                  </FormItem> */}
                                                </Card>
                                                <br />
                                              </>
                                            );
                                          }
                                        )}
                                      </Panel>
                                    </Collapse>

                                    <br />
                                  </>
                                ))
                              : ''}
                            {/* <Row>
                                <Col span={24}>
                                  <p
                                    style={{
                                      fontSize: '14px',
                                      color: '#263238'
                                      // marginTop: '7px'
                                    }}
                                  >
                                    Equipments:
                                  </p>
                                </Col>
                              </Row> */}

                            {/* <Row gutter={24}>
                                <Col span={10}>
                                  <p
                                    style={{
                                      fontSize: '14px',
                                      color: '#263238'
                                      // marginTop: '7px'
                                    }}
                                  >
                                    Is this process step carried out lot-wise?
                                  </p>
                                </Col>
                                <Col span={14}>
                                  <Radio.Group
                                    onChange={(e) => handleSetLots(e, i, index)}
                                  >
                                    <Radio value={1}>Yes</Radio>
                                    <Radio value={2}>No</Radio>
                                  </Radio.Group>
                                </Col>
                              </Row> */}
                            {param?.lot ? (
                              <Row gutter={24}>
                                <Col span={6}>
                                  <FormItem
                                    //   label="Materials"
                                    rules={[
                                      {
                                        required: false,
                                        message: 'Enter Lots'
                                      }
                                    ]}
                                    className={styles.customLable}
                                  >
                                    <Input placeholder="Enter number of Lots" />
                                  </FormItem>
                                </Col>
                              </Row>
                            ) : (
                              ''
                            )}
                          </>
                        </Card>
                        {/* </Badge.Ribbon> */}
                        <br />
                      </>
                    );
                  })}
                </Panel>
              </Collapse>
              <br />
            </>
          );
        })}

        <br />
        <FormItem className="text-center">
          <Space>
            {/* {truckShow ? (
              <Image
                src={truck}
                preview={false}
                style={{ width: '100px !important', height: '100px' }}
              />
            ) : ( */}
            {/* <CustomButton
              className={styles.inwardButton}
              htmlType="submit"
              type="primary"
            >
              Proceed
            </CustomButton> */}
            {/* )} */}
            {/* <CustomButton
              className={styles.inwardButton}
              type="primary"
              ghost
              onClick={() => history.back()}
            >
              Back
            </CustomButton> */}
          </Space>
        </FormItem>
      </Form>
    </Layout>
  );
};
export default ViewExecuteBMR;
