import React from 'react';
import { Card, Image, Space, Row, Col, Form } from 'antd';
import truck from '../../images/1.svg';
import styles from './index.module.less';
import Layout from '@/common/Layout';
import { getLoggedInUser } from 'utilities/helpers';

const ReportsDashboard = () => {
  const [form] = Form.useForm();
  const { user } = getLoggedInUser();

  const Cards =
    user?.role == 'admin'
      ? [
          {
            title: 'Audit Trails',
            icon: truck,
            url: 'view-audit-trails'
          }
        ]
      : user?.role == 'ppic_user'
      ? [
          {
            title: 'Stock Report',
            icon: truck,
            url: 'stock-report'
          }
        ]
      : [
          // {
          //   title: 'Audit Trails',
          //   icon: truck,
          //   url: 'audit-trails'
          // },
          {
            title: 'Stock Report',
            icon: truck,
            url: 'stock-report'
          },
          {
            title: 'Audit Trails',
            icon: truck,
            url: 'view-audit-trails'
          },
          {
            title: 'Other Reports',
            icon: truck,
            url: 'other-reports'
          }
        ];
  const cardView = Cards;
  return (
    <>
      <Layout sider={false}>
        <Form form={form} layout="vertical">
          <Row gutter={24}>
            {cardView?.map((items, index) => {
              return (
                <Col
                  xs={4}
                  xl={4}
                  md={6}
                  sm={6}
                  span={6}
                  className={`${styles.cardbody1} mb-2`}
                  key={index}
                >
                  <Card
                    hoverable
                    className={styles.cardbody}
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = items.url;
                    }}
                  >
                    <Space direction="vertical">
                      <Image
                        src={items.icon}
                        preview={false}
                        // style={{ width: '80% !important' }}
                        className={styles.stickers}
                      />
                      <span>{items.title}</span>
                    </Space>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Form>
      </Layout>
    </>
  );
};

export default ReportsDashboard;
