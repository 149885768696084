export const columns = [
  {
    title: 'Material',
    dataIndex: 'material',
    key: 'material',
    ellipsis: true,
    width: '200px',
    render: (record) => {
      {
        return record?.name;
      }
    },
    responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
  },
  {
    title: 'Material Code',
    dataIndex: 'material',
    key: 'material_code',
    ellipsis: true,
    width: '150px',
    render: (record) => {
      {
        return record?.code;
      }
    },
    responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
  },
  {
    title: 'In House Batch No',
    dataIndex: 'material',
    key: 'in_house_batch_no',
    ellipsis: true,
    width: '150px',
    render: (record) => {
      {
        return record?.in_house_batch_no;
      }
    },
    responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
  },
  {
    title: 'Required Weight',
    dataIndex: 'required_weight',
    key: 'required_weight',
    ellipsis: true,
    width: '150px',
    render: (record) => {
      {
        return record;
      }
    },
    responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
  }
];
