import React from 'react';
import Layout from '@/common/Layout';
import LandingPage from './components/LandingPage';

const MoveMaterial = () => {
  return (
    <>
      <Layout sider={false}>
        <LandingPage />
      </Layout>
    </>
  );
};

export default MoveMaterial;
