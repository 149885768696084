import React, { useState } from 'react';
import {
  Row,
  Col,
  Collapse,
  Form,
  Input,
  // DatePicker,
  // Select,
  Radio,
  Space,
  Image,
  message
  // Card
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import truck from '../images/truck-delivery-done.gif';
import { useDispatch } from 'react-redux';
import {
  // getAllMaterialslist,
  createMaterialInward
  // getAllSuppliers
} from '../../redux/slice';
// import dayjs from 'dayjs';
import { history } from 'app/history';
// import moment from 'moment';

const { Panel } = Collapse;
// const { TextArea } = Input;

const CreateMaterialPage = ({ loading }) => {
  // const { MaterialsList } = useSelector((state) => state.material);
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [truckShow, setTruckShow] = useState(false);
  const [material, setMaterial] = useState({});
  // const [search, setSearch] = useState('');
  // const [code, setCode] = useState('');
  // const [searchvendor, setSearchvendor] = useState('');
  // const [suppliersdata, setSuppliersdata] = useState([]);
  // const [manufacturer, setManufacturer] = useState();
  // const [selectVendor, setSelectVendor] = useState();
  // const current = new Date();
  // const month =
  //   `${current.getMonth() + 1}`.length > 2
  //     ? `${current.getMonth() + 1}`
  //     : `0${current.getMonth() + 1}`;
  // const date = `${current.getFullYear()}-${month}-${current.getDate()}`;
  // useEffect(() => {
  //   handleGetAllMaterialsList();
  // }, []);
  // useEffect(() => {
  //   handleGetAllSuppliers();
  // }, [searchvendor]);
  // useEffect(() => {
  //   form.setFieldsValue({
  //     material_name: code?.name,
  //     supplier_id: '',
  //     uom: code?.uom,
  //     mfg_name: '',
  //     vendor_status: ''
  //   });
  // }, [code]);
  // useEffect(() => {
  //   form.setFieldsValue({
  //     mfg_name: manufacturer ? manufacturer[0]?.manufacturer_name : '',
  //     vendor_status: manufacturer ? manufacturer[0]?.vendor_status : ''
  //   });
  // }, [manufacturer]);
  // const onChange = (value) => {
  //   const material = MaterialsList.filter(
  //     (MaterialsList) => MaterialsList.id === value
  //   );
  //   console.log(material);
  //   setCode(material[0]);
  //   setSuppliersdata(material[0].suppliers);
  //   setSelectVendor('');
  // };

  // const onChangeSupplier = (value) => {
  //   const manufacturerData = suppliersdata.filter(
  //     (suppliersdata) => suppliersdata.id === value
  //   );
  //   setManufacturer(manufacturerData);
  // };
  // console.log(manufacturer);
  // const onSearch = (value) => {
  //   setSearch(value);
  // };

  // const onSearchvendor = (value) => {
  //   setSearchvendor(value);
  // };
  const handleCapacity = (e) => {
    const { name, value } = e.target;
    setMaterial({ ...material, [name]: value });
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo.errorFields.length);
    if (errorInfo.errorFields.length > 0) {
      message.error(
        'Please select a response to all the questions in the checklist'
      );
    }
  };
  const onFinish = (values) => {
    values['for_graviti'] = material.gravity;
    values['po_available'] = material.po_available;
    values['boe_exists'] = material.boe;
    values['coa_exists'] = material.coa;
    values['coc_exists'] = material.coc;
    values['dc_exists'] = material.dc;
    values['invoice_exists'] = material.invoice_exist;
    values['coa_coc_msda_available'] = material.coa_coc_msda_available;
    values['approved_vendor'] = material.approved_vendor;
    values['vechile_clean'] = material.clineleness_vehicle;
    values['material_enclosed'] = material.material_condition;
    values['stroage_condition'] = material.storage_condition;
    values['packages_labelled'] = material.packages_labelled;
    values['seal_integrity'] = material.seal_integrity;
    values['data_logger'] = material.data_logger;
    values['labels_defaced'] = material.labels_defaced;
    values['dedusting'] = material.de_dusting_status;
    values['weight_verification'] = material.weight_verification;
    // values['receipt_date'] = date;
    setTruckShow(true);

    dispatch(createMaterialInward(values)).then(function (response) {
      if (response?.payload?.success) {
        setTimeout(() => {
          localStorage.setItem(
            'invoice_num',
            response?.payload?.data?.invoice_number
          );
          message.success(response?.payload?.message);
          {
            response?.payload?.data?.material_status === 'doc_ver_approved'
              ? history.push(`/vehicle-storage/${response?.payload?.data?.id}`)
              : history.push(`/pending-receipts`);
          }
        }, 2000);
      } else {
        setTruckShow(false);
      }
    });
  };

  // const handleGetAllMaterialsList = () => {
  //   let payload = { search };
  //   dispatch(getAllMaterialslist(payload));
  // };

  // const handleGetAllSuppliers = () => {
  //   let payload = { search };
  //   dispatch(getAllSuppliers(payload));
  // };

  // const disabledDate = (current) => {
  //   return current && current > dayjs().endOf('day');
  // };

  // const handleSeldctVendor = (value) => {
  //   setSelectVendor(value);
  // };

  const handleStandardCancel = () => {
    history.push('/dashboard');
  };
  // const Statuses = [
  //   {
  //     label: 'Provisional ',
  //     valu: 'provisional '
  //   },
  //   {
  //     label: 'Approved',
  //     valu: 'approved'
  //   }
  // ];

  // const dateFormat = 'YYYY-MM-DD';

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className={styles.panelborder}
    >
      <Collapse defaultActiveKey={['1']} accordion>
        <Panel
          header="DOCUMENT VERIFICATION"
          key="1"
          className={styles.CustomPanel}
        >
          <Row gutter={24}>
            <Col xs={24} xl={8} span={8}>
              <FormItem
                label="Is the material meant for graviti?"
                name="gravity"
                rules={[
                  {
                    required: true,
                    message: 'Choose Yes/No'
                  }
                ]}
                className={styles.customLable}
              >
                <Radio.Group
                  onChange={handleCapacity}
                  className={styles.customRadio}
                  name="gravity"
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </FormItem>
            </Col>

            <Col xs={24} xl={8} span={8}>
              <FormItem
                label="Invoice/DC/BOE Number available"
                name="invoice_dc_boe_available"
                rules={[
                  {
                    required: true,
                    message: 'Choose Yes/No'
                  }
                ]}
                className={styles.customLable}
              >
                <Radio.Group
                  onChange={handleCapacity}
                  className={styles.customRadio}
                  name="invoice_exist"
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </FormItem>
            </Col>
            {/* <Col xs={24} xl={8} span={8}>
              <FormItem
                label="DC available?"
                name="dc"
                rules={[
                  {
                    required: true,
                    message: 'Choose Yes/No'
                  }
                ]}
                className={styles.customLable}
              >
                <Radio.Group
                  onChange={handleCapacity}
                  className={styles.customRadio}
                  name="dc"
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <FormItem
                label="BOE available?"
                name="boe"
                rules={[
                  {
                    required: true,
                    message: 'Choose Yes/No'
                  }
                ]}
                className={styles.customLable}
              >
                <Radio.Group
                  onChange={handleCapacity}
                  className={styles.customRadio}
                  name="boe"
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </FormItem>
            </Col> */}
            <Col xs={24} xl={8} span={8}>
              <FormItem
                label="Invoice/DC/BOE Number"
                name="invoice_number"
                rules={[
                  {
                    required: true,
                    message: 'Enter Invoice number'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Invoice number" />
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <FormItem
                label="Purchase Order available"
                name="po_available"
                rules={[
                  {
                    required: true,
                    message: 'Choose Yes/No'
                  }
                ]}
                className={styles.customLable}
              >
                <Radio.Group
                  onChange={handleCapacity}
                  className={styles.customRadio}
                  name="po_available"
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <FormItem
                label="COA/COC, MSDS available"
                name="coa_coc_msda_available"
                rules={[
                  {
                    required: true,
                    message: 'Choose Yes/No'
                  }
                ]}
                className={styles.customLable}
              >
                <Radio.Group
                  onChange={handleCapacity}
                  className={styles.customRadio}
                  name="coa_coc_msda_available"
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <FormItem
                label="Material Vendor Status"
                name="material_vendor_status"
                rules={[
                  {
                    required: true,
                    message: 'Choose Yes/No'
                  }
                ]}
                className={styles.customLable}
              >
                <Radio.Group
                  onChange={handleCapacity}
                  className={styles.customRadio}
                  name="material_vendor_status"
                >
                  <Radio value="approved">Approved</Radio>
                  <Radio value="prov">Prov Approved</Radio>
                  <Radio value="New">New</Radio>
                </Radio.Group>
              </FormItem>
            </Col>
            {/* <Col xs={24} xl={8} span={8}>
              <FormItem
                label="COA available?"
                name="coa"
                rules={[
                  {
                    required: true,
                    message: 'Choose Yes/No'
                  }
                ]}
                className={styles.customLable}
              >
                <Radio.Group
                  onChange={handleCapacity}
                  className={styles.customRadio}
                  name="coa"
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <FormItem
                label="COC available?"
                name="coc"
                rules={[
                  {
                    required: true,
                    message: 'Choose Yes/No'
                  }
                ]}
                className={styles.customLable}
              >
                <Radio.Group
                  onChange={handleCapacity}
                  className={styles.customRadio}
                  name="coc"
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </FormItem>
            </Col> */}
            <Col span={18}>
              <FormItem
                label="Manufacturing site address matching with Provisionally
                Approved/Approved Vendor list"
                name="manufacture_site_address_matching"
                rules={[
                  {
                    required: true,
                    message: 'Choose Yes/No'
                  }
                ]}
                className={styles.customLable}
              >
                <Radio.Group
                  onChange={handleCapacity}
                  className={styles.customRadio}
                  name="approved_vendor"
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </FormItem>
            </Col>
          </Row>
        </Panel>
      </Collapse>
      <br />
      <FormItem className="text-right">
        <Space>
          <CustomButton
            htmlType="button"
            onClick={handleStandardCancel}
            className={styles.border_button}
          >
            Cancel
          </CustomButton>
          {truckShow ? (
            <Image
              src={truck}
              preview={false}
              style={{ width: '100px !important', height: '100px' }}
            />
          ) : (
            <CustomButton
              type="primary"
              htmlType="submit"
              loading={loading}
              className={styles.border_button}
            >
              Proceed
            </CustomButton>
          )}
        </Space>
      </FormItem>
      {/* </Space> */}
    </Form>
  );
};
export default CreateMaterialPage;
