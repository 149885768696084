import React, { useEffect } from 'react';
import Layout from '@/common/Layout';
import { Card, Col, DatePicker, Form, Input, Row, Space, message } from 'antd';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import CustomButton from '@/common/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  // createReturnablGP,
  // getAllReturnGP,
  getAllReturnGPRegister,
  createReturnablGPRegister
} from '../../redux/slice';
import { history } from 'app/history';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

const createReturnableGatepassRegister = () => {
  const { returnableData } = useSelector((state) => state.return);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    handleGetAllReturnGatepass();
    id
      ? form.setFieldsValue({
          date: moment(returnableData?.[0]?.date, 'YYYY-MM-DD'),
          rgp: returnableData?.[0]?.rgp,
          des: returnableData?.[0]?.des,
          uom: returnableData?.[0].uom,
          qty: returnableData?.[0]?.qty,
          exp: moment(returnableData?.[0]?.exp, 'YYYY-MM-DD'),
          qty_ret: returnableData?.[0].qty_ret,
          sec: returnableData?.[0]?.sec,
          rec: returnableData?.[0]?.rec,
          remarks: returnableData?.[0].remarks
          // vehicle_no: returnableData?.[0]?.vehicle_no,
          // purpose: returnableData?.[0]?.purpose,
          // prepared_by: returnableData?.[0]?.user,
          // authorized_by: returnableData?.[0]?.auth,
          // received: returnableData?.[0]?.received_by,
          // material: moment(returnableData?.[0]?.material, 'YYYY-MM-DD'),
        })
      : '';
  }, [id]);

  const handleGetAllReturnGatepass = () => {
    let payload = {
      id: id ? id : ''
    };
    dispatch(getAllReturnGPRegister(payload));
  };

  // console.log(returnableData);
  const onFinish = (values) => {
    console.log('Success:', values);
    values['id'] = id;
    //dispatch(createReturnableGatepassRegister(values)).then((response) => {
    dispatch(createReturnablGPRegister(values)).then((response) => {
      if (response?.payload?.success) {
        message.success(response?.payload?.message);
        history.push('returnable-gate-pass-register-list');
      }
    });
  };

  return (
    <Layout sider={false}>
      <Card
        title="Returnable Gate Pass Register"
        className={`${styles.cardStyles_approver}`}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={styles.panelborder}
        >
          <Row gutter={24}>
            <Col span={12}>
              <FormItem
                label="Date"
                name="date"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <DatePicker
                  placeholder="Select Date"
                  style={{ width: '100%' }}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="RGP #"
                name="rgp"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input placeholder="Enter RGP #" />
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem
                label="Description of Goods"
                name="des"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Description" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="UOM"
                name="uom"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input placeholder="Enter UOM" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Qty"
                name="qty"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Qty" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Expected date of Return"
                name="exp"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <DatePicker
                  placeholder="Select Date"
                  style={{ width: '100%' }}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Qty Returned"
                name="qty_ret"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Qty Returned" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Security in Ward#"
                name="sec"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Security in ward" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Received By(User ID & Date)"
                name="rec"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input placeholder="Enter received by" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Remarks"
                name="remarks"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Remarks" />
              </FormItem>
            </Col>
          </Row>
          <FormItem className="text-center">
            <Space>
              {/* <CustomButton
                className={styles.inwardButton}
                // onClick={handleClose}
                type="primary"
                // size="small"
                ghost
              >
                Close
              </CustomButton> */}
              <CustomButton
                className={styles.inwardButton}
                htmlType="submit"
                type="primary"
                // size="small"
              >
                Submit
              </CustomButton>
            </Space>
          </FormItem>
        </Form>
      </Card>
    </Layout>
  );
};
export default createReturnableGatepassRegister;
