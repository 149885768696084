import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { message } from 'antd';
import {
  materials,
  updateDate,
  dispensing,
  dispensingList,
  updateDispensing,
  getbatches,
  allContainers,
  dispenseMaterialDetails,
  products,
  dispenseBMRDetails,
  update_products,
  validate_material,
  update_material,
  recal_yield,
  dispensing_booths,
  get_balance,
  get_containers,
  approvedDispense,
  getName,
  update_lots,
  update_return_qty
} from './api';

const initialState = {
  allMaterials: [],
  dispenses: [],
  allBatches: [],
  allContainers: [],
  materialDetails: [],
  allProducts: [],
  getAllMaterilLoading: null
};

const actions = {
  GET_ALL_MATERIALS: 'dispensing/GET_ALL_MATERIALS',
  CREATE_DISPENSING: 'dispensing/CREATE_DISPENSING',
  MODIFIED_DATE: 'dispensing/MODIFIED_DATE',
  GET_ALL_DISPENSES: 'dispensing/GET_ALL_DISPENSES',
  UPDATE_DISPENSES: 'dispensing/UPDATE_DISPENSES',
  GET_ALL_BATCHES: 'dispensing/GET_ALL_BATCHES',
  GET_ALL_FILTERED_MATERIALS: 'dispensing/GET_ALL_FILTERED_MATERIALS',
  GET_ALL_CONTAINERS: 'dispensing/GET_ALL_CONTAINERS',
  GET_DISPENSE_MATERIAL_DETAILS: 'dispense/GET_DISPENSE_MATERIAL_DETAILS',
  GET_All_MATERIAL_BATCHES: 'dispense/GET_All_MATERIAL_BATCHES',
  CREATE_PRINT_ISSUE_TAG: 'dispense/CREATE_PRINT_ISSUE_TAG',
  GET_ALL_PRODUCTS: 'dispense/GET_ALL_PRODUCTS',
  CREATE_DISPENSING_SHEET: 'dispense/CREATE_DISPENSING_SHEET',
  UPDATE_PRINT_ISSUE: 'dispense/UPDATE_PRINT_ISSUE',
  GET_DISPENSE_BMR_DETAILS: 'dispense/GET_DISPENSE_BMR_DETAILS',
  GET_DISPENSE_PRODUCT_MATERIALS: 'dispense/GET_DISPENSE_PRODUCT_MATERIALS',
  UPDATE_PRODUCT: 'dispense/UPDATE_PRODUCT',
  VALIDATE_MATERIAL: 'dispense/VALIDATE_MATERIAL',
  UPDATE_MATERIAL: 'dispense/UPDATE_MATERIAL',
  RECALCULATE_YIELD: 'dispense/RECALCULATE_YIELD',
  DISPENSE_BOOTHS: 'dispense/DISPENSE_BOOTHS',
  GET_BALANCE: 'dispense/GET_BALANCE',
  GET_CONTAINERS: 'dispense/GET_CONTAINERS',
  APPROVE_DISPENSE: 'dispense/APPROVE_DISPENSE',
  GET_STAGE_NAME: 'dispense/GET_STAGE_NAME',
  UPDATE_LOTS: 'dispense/UPDATE_LOTS',
  RETURN_QTY: 'dispense/RETURN_QTY'
};

export const getAllMaterials = createAsyncThunk(
  actions.GET_ALL_MATERIALS,
  async (payload) => {
    const response = await materials('all', payload);

    return response;
  }
);

export const getAllMaterialsBatches = createAsyncThunk(
  actions.GET_All_MATERIAL_BATCHES,
  async (payload) => {
    const response = await materials('all-materialcodes', payload);

    return response;
  }
);

export const getAllFilteredMaterials = createAsyncThunk(
  actions.GET_ALL_FILTERED_MATERIALS,
  async (payload) => {
    const response = await materials('all-material-batches', payload);

    return response;
  }
);

export const modifiedDate = createAsyncThunk(
  actions.MODIFIED_DATE,
  async (payload) => {
    const response = await updateDate('approve_product', payload);

    return response;
  }
);

export const createDispensing = createAsyncThunk(
  actions.CREATE_DISPENSING,
  async (payload) => {
    const response = await dispensing('create', payload);

    return response;
  }
);

export const createDispensingSheet = createAsyncThunk(
  actions.CREATE_DISPENSING_SHEET,
  async (payload) => {
    const response = await dispensing('dc-sheet', payload);

    return response;
  }
);

export const getAllDispenses = createAsyncThunk(
  actions.GET_ALL_DISPENSES,
  async (payload) => {
    const response = await dispensingList('get-all', payload);

    return response;
  }
);

export const updateDispense = createAsyncThunk(
  actions.UPDATE_DISPENSES,
  async (payload) => {
    const response = await updateDispensing('update', payload);

    return response;
  }
);

export const getBatchdetails = createAsyncThunk(
  actions.GET_ALL_BATCHES,
  async (payload) => {
    const response = await getbatches('all-batches', payload);

    return response;
  }
);

export const getContainers = createAsyncThunk(
  actions.GET_ALL_CONTAINERS,
  async (payload) => {
    const response = await allContainers('all', payload);

    return response;
  }
);

export const getDispenseMaterialDetails = createAsyncThunk(
  actions.GET_DISPENSE_MATERIAL_DETAILS,
  async (payload) => {
    const response = await dispenseMaterialDetails('get-all', payload);
    return response;
  }
);

export const getDispenseBMRDetails = createAsyncThunk(
  actions.GET_DISPENSE_BMR_DETAILS,
  async (payload) => {
    const response = await dispenseBMRDetails('dc-id', payload);
    // console.log('getDispenseMaterialDetails', response);
    return response;
  }
);

export const createprintIssue = createAsyncThunk(
  actions.CREATE_PRINT_ISSUE_TAG,
  async (payload) => {
    const response = await dispensing('material', payload);

    return response;
  }
);

export const updateprintIssue = createAsyncThunk(
  actions.UPDATE_PRINT_ISSUE,
  async (payload) => {
    const response = await dispensing('dc-update', payload);

    return response;
  }
);
export const getAllproducts = createAsyncThunk(
  actions.GET_ALL_PRODUCTS,
  async (payload) => {
    const response = await products('products', payload);

    return response;
  }
);

export const ApproveDispensing = createAsyncThunk(
  actions.APPROVE_DISPENSE,
  async (payload) => {
    const response = await approvedDispense('material_indent', payload);

    return response;
  }
);

export const getstageName = createAsyncThunk(
  actions.GET_STAGE_NAME,
  async (payload) => {
    const response = await getName('stage_group_name', payload);

    return response;
  }
);

export const getallprodMaterials = createAsyncThunk(
  actions.GET_DISPENSE_PRODUCT_MATERIALS,
  async (payload) => {
    const response = await dispenseBMRDetails(
      `${
        payload?.type == 'calculation'
          ? 'all'
          : payload?.type == 'assay_calculation'
          ? 'dispense_history'
          : payload?.type == 'product_view'
          ? 'product_view'
          : 'product'
      }`,
      payload
    );
    // console.log('getDispenseMaterialDetails', response);
    return response;
  }
);

export const updateProduct = createAsyncThunk(
  actions.UPDATE_PRODUCT,
  async (payload) => {
    const response = await update_products(
      payload?.type == 'update' ? 'update_product' : 'approve_product',
      payload
    );
    // console.log('getDispenseMaterialDetails', response);
    return response;
  }
);

export const ValidateMaterial = createAsyncThunk(
  actions.VALIDATE_MATERIAL,
  async (payload) => {
    const response = await validate_material('validate-batch', payload);
    // console.log('getDispenseMaterialDetails', response);
    return response;
  }
);

export const updateRequestStatus = createAsyncThunk(
  actions.UPDATE_MATERIAL,
  async (payload) => {
    const response = await update_material('double_lc', payload);
    // console.log('getDispenseMaterialDetails', response);
    return response;
  }
);

export const recalculateYield = createAsyncThunk(
  actions.RECALCULATE_YIELD,
  async (payload) => {
    const response = await recal_yield('recalculate_yield', payload);
    // console.log('getDispenseMaterialDetails', response);
    return response;
  }
);

export const getAllDispenseBooths = createAsyncThunk(
  actions.DISPENSE_BOOTHS,
  async (payload) => {
    const response = await dispensing_booths('dispense_booth', payload);
    // console.log('getDispenseMaterialDetails', response);
    return response;
  }
);

export const getBalance = createAsyncThunk(
  actions.GET_BALANCE,
  async (payload) => {
    const response = await get_balance('fetch-weight-prod', payload);
    // console.log('getDispenseMaterialDetails', response);
    return response;
  }
);

export const getAllContainers = createAsyncThunk(
  actions.GET_CONTAINERS,
  async (payload) => {
    const response = await get_containers('get_containers', payload);
    // console.log('getDispenseMaterialDetails', response);
    return response;
  }
);

export const updateLots = createAsyncThunk(
  actions.UPDATE_LOTS,
  async (payload) => {
    const response = await update_lots('edit_dispe_materials', payload);
    // console.log('getDispenseMaterialDetails', response);
    return response;
  }
);

export const returnStockQty = createAsyncThunk(
  actions.RETURN_QTY,
  async (payload) => {
    const response = await update_return_qty('dispenseDelete', payload);
    // console.log('getDispenseMaterialDetails', response);
    return response;
  }
);
export const dispensingSlice = createSlice({
  name: 'dispensing',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMaterials.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllMaterials.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, pagination } = action.payload;
        if (success) {
          state.allMaterials = data;
          state.pagination = pagination || null;
        } else {
          state.allMaterials = [];
        }
      })
      .addCase(getAllMaterials.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(createDispensing.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createDispensing.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createDispensing.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllDispenses.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllDispenses.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, pagination } = action.payload;
        if (success) {
          state.dispenses = data;
          state.pagination = pagination || null;
        } else {
          state.dispenses = [];
        }
      })
      .addCase(getAllDispenses.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(updateDispense.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(updateDispense.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(updateDispense.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(modifiedDate.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(modifiedDate.fulfilled, (state, action) => {
        // state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(modifiedDate.rejected, (state, action) => {
        const { message: msg } = action.error;

        // state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getBatchdetails.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getBatchdetails.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, pagination } = action.payload;
        if (success) {
          state.allBatches = data;
          state.pagination = pagination || null;
        } else {
          state.allBatches = [];
        }
      })
      .addCase(getBatchdetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllFilteredMaterials.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllFilteredMaterials.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, pagination } = action.payload;
        if (success) {
          state.allMaterials = data;
          state.pagination = pagination || null;
        } else {
          state.allMaterials = [];
        }
      })
      .addCase(getAllFilteredMaterials.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getContainers.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getContainers.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, pagination } = action.payload;
        if (success) {
          state.allContainers = data;
          state.pagination = pagination || null;
        } else {
          state.allContainers = [];
        }
      })
      .addCase(getContainers.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getDispenseMaterialDetails.pending, (state) => {
        state.getMaterialDetailslLoading = true;
      })
      .addCase(getDispenseMaterialDetails.fulfilled, (state, action) => {
        console.log('check', action);
        state.getMaterialDetailslLoading = false;

        const { success, data } = action.payload;

        if (success) {
          state.materialDetails = data;
        } else {
          state.materialDetails = '';
        }
      })
      .addCase(getDispenseMaterialDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getMaterialDetailslLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllMaterialsBatches.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllMaterialsBatches.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, pagination } = action.payload;
        if (success) {
          state.allMaterials = data;
          state.pagination = pagination || null;
        } else {
          state.allMaterials = [];
        }
      })
      .addCase(getAllMaterialsBatches.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createprintIssue.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createprintIssue.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createprintIssue.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllproducts.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllproducts.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, pagination } = action.payload;
        if (success) {
          state.allProducts = data;
          state.pagination = pagination || null;
        } else {
          state.allProducts = [];
        }
      })
      .addCase(getAllproducts.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createDispensingSheet.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createDispensingSheet.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createDispensingSheet.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(updateprintIssue.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(updateprintIssue.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(updateprintIssue.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getDispenseBMRDetails.pending, (state) => {
        state.getMaterialDetailslLoading = true;
      })
      .addCase(getDispenseBMRDetails.fulfilled, (state, action) => {
        // console.log('check', action.payload);
        state.getMaterialDetailslLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getDispenseBMRDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getMaterialDetailslLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getallprodMaterials.pending, (state) => {
        state.getMaterialDetailslLoading = true;
      })
      .addCase(getallprodMaterials.fulfilled, (state, action) => {
        // console.log('check', action.payload);
        state.getMaterialDetailslLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getallprodMaterials.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getMaterialDetailslLoading = false;

        message.error(msg);
      });

    builder
      .addCase(updateProduct.pending, (state) => {
        state.getMaterialDetailslLoading = true;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        // console.log('check', action.payload);
        state.getMaterialDetailslLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(updateProduct.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getMaterialDetailslLoading = false;

        message.error(msg);
      });
    builder
      .addCase(ValidateMaterial.pending, (state) => {
        state.getMaterialDetailslLoading = true;
      })
      .addCase(ValidateMaterial.fulfilled, (state, action) => {
        // console.log('check', action.payload);
        state.getMaterialDetailslLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(ValidateMaterial.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getMaterialDetailslLoading = false;

        message.error(msg);
      });
    builder
      .addCase(updateRequestStatus.pending, (state) => {
        state.getMaterialDetailslLoading = true;
      })
      .addCase(updateRequestStatus.fulfilled, (state, action) => {
        // console.log('check', action.payload);
        state.getMaterialDetailslLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(updateRequestStatus.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getMaterialDetailslLoading = false;

        message.error(msg);
      });

    builder
      .addCase(recalculateYield.pending, (state) => {
        state.getMaterialDetailslLoading = true;
      })
      .addCase(recalculateYield.fulfilled, (state, action) => {
        // console.log('check', action.payload);
        state.getMaterialDetailslLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(recalculateYield.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getMaterialDetailslLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllDispenseBooths.pending, (state) => {
        state.getMaterialDetailslLoading = true;
      })
      .addCase(getAllDispenseBooths.fulfilled, (state, action) => {
        // console.log('check', action.payload);
        state.getMaterialDetailslLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllDispenseBooths.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getMaterialDetailslLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getBalance.pending, (state) => {
        state.getMaterialDetailslLoading = true;
      })
      .addCase(getBalance.fulfilled, (state, action) => {
        // console.log('check', action.payload);
        state.getMaterialDetailslLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getBalance.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getMaterialDetailslLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllContainers.pending, (state) => {
        state.getMaterialDetailslLoading = true;
      })
      .addCase(getAllContainers.fulfilled, (state, action) => {
        // console.log('check', action.payload);
        state.getMaterialDetailslLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllContainers.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getMaterialDetailslLoading = false;

        message.error(msg);
      });

    builder
      .addCase(ApproveDispensing.pending, (state) => {
        state.getMaterialDetailslLoading = true;
      })
      .addCase(ApproveDispensing.fulfilled, (state, action) => {
        // console.log('check', action.payload);
        state.getMaterialDetailslLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(ApproveDispensing.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getMaterialDetailslLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getstageName.pending, (state) => {
        state.getMaterialDetailslLoading = true;
      })
      .addCase(getstageName.fulfilled, (state, action) => {
        // console.log('check', action.payload);
        state.getMaterialDetailslLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getstageName.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getMaterialDetailslLoading = false;

        message.error(msg);
      });

    builder
      .addCase(updateLots.pending, (state) => {
        state.getMaterialDetailslLoading = true;
      })
      .addCase(updateLots.fulfilled, (state, action) => {
        // console.log('check', action.payload);
        state.getMaterialDetailslLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(updateLots.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getMaterialDetailslLoading = false;

        message.error(msg);
      });
    builder
      .addCase(returnStockQty.pending, (state) => {
        state.getMaterialDetailslLoading = true;
      })
      .addCase(returnStockQty.fulfilled, (state, action) => {
        // console.log('check', action.payload);
        state.getMaterialDetailslLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(returnStockQty.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getMaterialDetailslLoading = false;

        message.error(msg);
      });
  }
});
export default dispensingSlice.reducer;
