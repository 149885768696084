import React from 'react';
import Layout from '@/common/Layout';
import DashBoardPage from './components/DashBoardPage';

const superAdminDashboard = () => {
  return (
    <>
      <Layout sider={false}>
        <DashBoardPage />
      </Layout>
    </>
  );
};

export default superAdminDashboard;
