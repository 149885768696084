import React, { useEffect } from 'react';
import { Form, Card, Col, Row, Space, Input, message } from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
// import CustomTable from 'common/Content/CustomTable';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getallprodMaterials,
  updateProduct
  // getAllMaterialsBatches
} from '../../redux/slice';
import Layout from '@/common/Layout';
import { useState } from 'react';
import { getLoggedInUser, getRoles } from 'utilities/helpers';

const { Meta } = Card;
const { TextArea } = Input;

const PrintAssay = () => {
  const [form] = Form.useForm();
  const params = useParams();
  const dispatch = useDispatch();
  const { user } = getLoggedInUser();
  const { Superadmindata } = getRoles('Quality Assurance');
  // const { allMaterials } = useSelector((state) => state.dispensing);

  const [data, setData] = useState({});
  const [extraGranulardata, setExtraGranulardata] = useState([]);
  const [materialData, setMaterialData] = useState([]);
  const [dispenses, setDispenses] = useState([]);
  // const [actualData, setActualData] = useState([]);

  console.log(extraGranulardata);
  useEffect(() => {
    handleGetAllProductMaterials();
    // populatedFields();
  }, []);

  const handleGetAllProductMaterials = () => {
    let payload = { id: params?.id, type: 'assay_calculation' };
    dispatch(getallprodMaterials(payload)).then((response) => {
      console.log(response?.payload?.data?.product_detail);
      if (response?.payload?.success) {
        setData(response?.payload?.data);
        setDispenses(response?.payload?.batches);
        const material_data =
          response?.payload?.data?.product_detail?.bmr_product_materials.filter(
            (item) => item?.is_extra == false
          );
        const extra_granular_data =
          response?.payload?.data?.product_detail?.bmr_product_materials.filter(
            (item) => item?.is_extra == true
          );

        setMaterialData(material_data);
        setExtraGranulardata(extra_granular_data);
      }
    });
  };
  const bdata = materialData?.map((item) => {
    // console.log(item?.material?.code);
    var batches = dispenses?.filter(
      (batch) => batch.material_id == item?.material_id
    );

    var dis_batches = [];
    batches?.map((b_item) => {
      dis_batches.push({
        inhouse_batch_number: b_item?.inhouse_batch_number,
        qty: b_item?.avl_qty,
        lot_1: b_item?.lot_1 || 0,
        lot_2: b_item?.lot_2 || 0,
        lot_3: b_item?.lot_3 || 0,
        lot_4: b_item?.lot_4 || 0
      });
    });
    console.log(batches);
    return {
      material_code: item?.material?.code,
      available_qty: item?.quantity_for_batch,
      lot_1: item?.lot_1 || 0,
      lot_2: item?.lot_2 || 0,
      lot_3: item?.lot_3 || 0,
      lot_4: item?.lot_4 || 0,

      batches: dis_batches
    };
  });

  const extradata = extraGranulardata?.map((item) => {
    // console.log(item?.material?.code);
    var batches = dispenses?.filter(
      (batch) => batch.material_id == item?.material_id
    );

    var dis_batches = [];
    batches?.map((b_item) => {
      dis_batches.push({
        inhouse_batch_number: b_item?.inhouse_batch_number,
        qty: b_item?.avl_qty,
        dispense_qty: b_item?.dispense_qty
      });
    });
    console.log(batches);
    return {
      material_code: item?.material?.code,
      available_qty: item?.quantity_for_batch,
      lot_1: item?.lot_1 || 0,
      lot_2: item?.lot_2 || 0,
      lot_3: item?.lot_3 || 0,
      lot_4: item?.lot_4 || 0,

      batches: dis_batches
    };
  });

  // console.log(actualData);
  // console.log(bdata);

  const ExtrarowData = extradata?.length
    ? extradata.reduce((a, { material_code, available_qty, batches }) => {
        const rowSpan = batches.length || 0;

        let [firstCell] = [material_code].map((txt) => ({
          txt,
          rowSpan
        }));
        let [secondCell] = [available_qty].map((txt) => ({
          txt,
          rowSpan
        }));

        // info.forEach(({ batches }) => {
        // const rowSpan = batches.length;

        let hobCell = { rowSpan };

        batches.forEach((f, j) => {
          const fieldCells = Object.values(f).map((txt) => ({ txt }));
          // console.log(fieldCells);
          if (j > 0) {
            hobCell = firstCell = secondCell = null;
          }
          console.log(hobCell);
          const row = [firstCell, secondCell, ...fieldCells];
          a.push(row);
        });
        // });

        return a;
      }, [])
    : '';

  const rowData = bdata?.length
    ? bdata.reduce(
        (
          a,
          { material_code, available_qty, lot_1, lot_2, lot_3, lot_4, batches }
        ) => {
          const rowSpan = batches.length || 0;

          let [firstCell] = [material_code].map((txt) => ({
            txt,
            rowSpan
          }));
          let [secondCell] = [available_qty].map((txt) => ({
            txt,
            rowSpan
          }));
          let [lot1] = [lot_1].map((txt) => ({
            txt,
            rowSpan
          }));
          let [lot2] = [lot_2].map((txt) => ({
            txt,
            rowSpan
          }));
          let [lot3] = [lot_3].map((txt) => ({
            txt,
            rowSpan
          }));
          let [lot4] = [lot_4].map((txt) => ({
            txt,
            rowSpan
          }));

          // info.forEach(({ batches }) => {
          // const rowSpan = batches.length;

          let hobCell = { rowSpan };

          batches.forEach((f, j) => {
            const fieldCells = Object.values(f).map((txt) => ({ txt }));
            // console.log(fieldCells);
            if (j > 0) {
              hobCell =
                firstCell =
                secondCell =
                lot1 =
                lot2 =
                lot3 =
                lot4 =
                  null;
            }
            console.log(hobCell);
            const row = [
              firstCell,
              secondCell,
              lot1,
              lot2,
              lot3,
              lot4,
              ...fieldCells
            ];
            a.push(row);
          });
          // });

          return a;
        },
        []
      )
    : '';

  // console.log(rowData);
  // const [rows] = useState(rowData || []);
  // console.log(rows);
  // var adata = [
  //   {
  //     material_code: '123455',
  //     available_qty: 50,
  //     lot_1: 21.9,
  //     lot_2: 21.9,
  //     lot_3: 21.9,
  //     lot_4: 21.9,
  //     batches: [
  //       {
  //         inhouse_batch_number: '123123',
  //         qty: 50,
  //         lot_1: 21.9,
  //         lot_2: 21.9,
  //         lot_3: 21.9,
  //         lot_4: 21.9
  //       },
  //       {
  //         inhouse_batch_number: '123124',
  //         qty: 150,
  //         lot_1: 21.9,
  //         lot_2: 21.9,
  //         lot_3: 21.9,
  //         lot_4: 21.9
  //       }
  //     ]
  //   }
  // ];
  const onFinish = (values) => {
    const payload = {
      status: status,
      id: params.id,
      comments: values.comments
    };
    dispatch(updateProduct(payload)).then((response) => {
      if (response?.payload?.success) {
        message.success(response?.payload?.message);
        history.push('/product-list');
      }
    });
  };

  // const columns = [
  //   {
  //     title: 'Material Code',
  //     dataIndex: 'code',
  //     key: 'code',
  //     width: '100px',
  //     render: (_, record) => {
  //       return record?.material_code;
  //     },
  //     rowSpan: 2
  //   },
  //   {
  //     title: 'Available Quantity',
  //     dataIndex: 'available_qty',
  //     key: 'available_qty',
  //     width: '100px',
  //     render: (_, record) => {
  //       return record?.available_qty;
  //     },
  //     rowSpan: 2
  //   },
  //   {
  //     title: 'In house Batch Number',
  //     dataIndex: 'inhouse_batch_number',
  //     key: 'inhouse_batch_number',
  //     ellipsis: true,
  //     width: '150px',
  //     rowSpan: 2,
  //     render: (_, record) => {
  //       return record?.batches?.map((batch) => batch.inhouse_batch_number);
  //     }
  //   },
  //   {
  //     title: 'Qty for Batch (KG)',
  //     dataIndex: 'quantity_for_batch',
  //     width: '100px',
  //     key: 'quantity_for_batch'
  //   },
  //   {
  //     title: 'Required Quantity',
  //     children: [
  //       {
  //         title: 'LOT-1',
  //         dataIndex: 'lot_1',
  //         key: 'lot_1',
  //         width: '100px'
  //       },
  //       {
  //         title: 'LOT-2',
  //         dataIndex: 'lot_2',
  //         key: 'lot_2',
  //         width: '100px'
  //       },
  //       {
  //         title: 'LOT-3',
  //         dataIndex: 'lot_3',
  //         key: 'lot_3',
  //         width: '100px'
  //       },
  //       {
  //         title: 'LOT-4',
  //         dataIndex: 'lot_4',
  //         key: 'lot_4',
  //         width: '100px'
  //       }
  //     ]
  //   },
  //   {
  //     title: 'Actual Quantity to be Dispensed',
  //     children: [
  //       {
  //         title: 'LOT-1',
  //         dataIndex: 'lot_1',
  //         key: 'lot_1',
  //         width: '100px',
  //         render: (_, record) => {
  //           return record?.dispensing_histories?.map(
  //             (lot) => lot.lot_n == 'lot_1'
  //           );
  //         }
  //       },
  //       {
  //         title: 'LOT-2',
  //         dataIndex: 'lot_2',
  //         key: 'lot_2',
  //         width: '100px',
  //         render: (_, record) => {
  //           return record?.dispensing_histories?.map(
  //             (lot) => lot.lot_n == 'lot_2'
  //           );
  //         }
  //       },
  //       {
  //         title: 'LOT-3',
  //         dataIndex: 'lot_3',
  //         key: 'lot_3',
  //         width: '100px',
  //         render: (_, record) => {
  //           return record?.dispensing_histories?.map((lot) => lot.lot_n);
  //         }
  //       },
  //       {
  //         title: 'LOT-4',
  //         dataIndex: 'lot_4',
  //         key: 'lot_4',
  //         width: '100px',
  //         render: (_, record) => {
  //           return record?.dispensing_histories?.map(
  //             (lot) => lot.lot_n == 'lot_4'
  //           );
  //         }
  //       }
  //     ]
  //   }
  // ];

  // const columns1 = [
  //   {
  //     title: 'Material Name',
  //     dataIndex: 'material',
  //     key: 'material',
  //     ellipsis: true,
  //     width: '250px',
  //     render: (_, record) => {
  //       return record?.material?.name;
  //     }
  //   },
  //   {
  //     title: 'Source',
  //     dataIndex: 'source',
  //     key: 'source',
  //     ellipsis: true,
  //     width: '150px',
  //     render: (_, record) => {
  //       return record?.material?.suppliers?.[0]?.name;
  //     }
  //   },
  //   {
  //     title: 'Material Code',
  //     dataIndex: 'code',
  //     key: 'code',
  //     render: (_, record) => {
  //       return record?.material?.code;
  //     }
  //   },
  //   {
  //     title: 'Qty for Batch (KG)',
  //     dataIndex: 'quantity_for_batch',
  //     key: 'quantity_for_batch'
  //   }
  // ];
  return (
    <>
      <Layout sider={false}>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Card
            title={
              <span style={{ fontWeight: 'bold', color: '#c91761' }}>
                Create Dispensing Request - BMR Based
              </span>
            }
          >
            <Row gutter={24}>
              <Col xs={24} xl={24} span={12}>
                <p>
                  <span style={{ fontWeight: 'bold' }}>Product Name :-</span>
                  {'  '}
                  <span>{data?.product_detail?.name}</span>
                </p>
              </Col>
              <Col xs={24} xl={12} span={12}>
                <p>
                  <span style={{ fontWeight: 'bold' }}>Product Batch :-</span>
                  {'  '}
                  <span>{data?.product_batch_number}</span>
                </p>
              </Col>
              <Col xs={24} xl={12} span={12}>
                <p>
                  <span style={{ fontWeight: 'bold' }}>BMR BPR Number :-</span>
                  {'  '}
                  <span>{data?.bmr_number}</span>
                </p>
              </Col>
              <Col xs={24} xl={12} span={12}>
                <p>
                  <span style={{ fontWeight: 'bold' }}>Effective Date :-</span>
                  {'  '}
                  <span>{data?.effective_date}</span>
                </p>
              </Col>
              <Col xs={24} xl={12} span={12}>
                <p>
                  <span style={{ fontWeight: 'bold' }}>Batch Size :-</span>
                  {'  '}
                  <span>{data?.product_detail?.batch_size}</span>
                </p>
              </Col>
            </Row>
          </Card>
          <br />
          <Card>
            <Meta
              title={
                <span style={{ color: '#c91761', fontWeight: 'bold' }}>
                  Dispensing Details
                </span>
              }
              description={
                <span style={{ fontWeight: 'bold' }}>
                  Note:- When two or more materials code options are available
                  for a material, that entire material quantity shall be sourced
                  from any one of the material
                </span>
              }
              className={`${styles.cardStyles_approver}`}
            />
            <div className={`${styles.table_container1}`}>
              <table
                className="ant-table"
                // border="1"
                style={{
                  width: 'auto',
                  minWidth: '100%',
                  tableLayout: 'fixed',
                  fontSize: '14px !important',
                  border: 'none !important',
                  /* border-color: #fff3f3 !important; */
                  /* background-color: #ccc6c6; */
                  lineHeight: '2.5715 !important',
                  listStyle: 'none',
                  position: 'relative',
                  textAlign: 'center'
                }}
              >
                <th>Code</th>
                <th>Batch Qty</th>

                <th>LOT 1</th>
                <th>LOT 2</th>
                <th>LOT 3</th>
                <th>LOT 4</th>
                <th>Inhouse Batch#</th>
                <th>Available Qty</th>
                <th>LOT 1</th>
                <th>LOT 2</th>
                <th>LOT 3</th>
                <th>LOT 4</th>
                {rowData?.length
                  ? rowData?.map((cells, i) => (
                      <tr
                        key={i}
                        style={{
                          fontSize: '13px !important',
                          lineHeight: '54px !important'
                        }}
                      >
                        {cells.map(
                          (cell, j) =>
                            cell && (
                              <td
                                key={`${i}-${j}`}
                                rowSpan={cell.rowSpan}
                                style={{ fontSize: '13px !important' }}
                              >
                                {cell.txt}
                              </td>
                            )
                        )}
                      </tr>
                    ))
                  : ''}
              </table>
            </div>
            {/* <div className={`${styles.table_container}`}>
              <CustomTable
                columns={columns}
                dataSource={adata}
                scroll={{ x: 'auto' }}
              />
            </div> */}
          </Card>
          <br />
          <Card>
            <Meta
              title={
                <span style={{ color: '#c91761', fontWeight: 'bold' }}>
                  Extra Granular Ingredients
                </span>
              }
              className={`${styles.cardStyles_approver}`}
            />
            <div className={`${styles.table_container1}`}>
              <table
                className="ant-table"
                // border="1"
                style={{
                  width: 'auto',
                  minWidth: '100%',
                  tableLayout: 'fixed',
                  fontSize: '14px !important',
                  border: 'none !important',
                  /* border-color: #fff3f3 !important; */
                  /* background-color: #ccc6c6; */
                  lineHeight: '2.5715 !important',
                  listStyle: 'none',
                  position: 'relative',
                  textAlign: 'center'
                }}
              >
                <th>Code</th>
                <th>Batch Qty</th>

                {/* <th>LOT-1</th>
                <th>LOT-2</th>
                <th>LOT-3</th>
                <th>LOT-4</th> */}
                <th>Inhouse Batch#</th>
                <th>Available Qty</th>
                <th>Dispensed Qty</th>
                {/* <th>LOT-1</th>
                <th>LOT-2</th>
                <th>LOT-3</th>
                <th>LOT-4</th> */}
                {ExtrarowData?.length
                  ? ExtrarowData?.map((cells, i) => (
                      <tr
                        key={i}
                        style={{
                          fontSize: '13px !important',
                          lineHeight: '54px !important'
                        }}
                      >
                        {cells.map(
                          (cell, j) =>
                            cell && (
                              <td
                                key={`${i}-${j}`}
                                rowSpan={cell.rowSpan}
                                style={{ fontSize: '13px !important' }}
                              >
                                {cell.txt}
                              </td>
                            )
                        )}
                      </tr>
                    ))
                  : ''}
              </table>
            </div>
            {/* <div className={`${styles.table_container}`}>
              <CustomTable columns={columns1} dataSource={extraGranulardata} />
            </div> */}
            {(user?.level == 3 && user?.role == 'qc_initiator') ||
            Superadmindata?.[0]?.level == 3 ? (
              <div>
                <Row gutter={24}>
                  <Col xs={24} xl={24} span={12} md={24} sm={24}>
                    <FormItem
                      label="Comments"
                      name="comments"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Comments'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <TextArea rows={4} placeholder="Please Enter Comments" />
                    </FormItem>
                  </Col>
                </Row>
              </div>
            ) : (
              ''
            )}
          </Card>
          <br />

          <FormItem className="text-center">
            <Space>
              <>
                {/* <CustomButton 
                  className={styles.inwardButton}
                  onClick={() => history.back()}
                  type="primary"
                  ghost
                >
                  Print
                </CustomButton > */}
                <CustomButton
                  className={styles.inwardButton}
                  onClick={() => history.push(`/create-checklist/${params.id}`)}
                  type="primary"
                >
                  Proceed to LC Checklist
                </CustomButton>
              </>
            </Space>
          </FormItem>
        </Form>
      </Layout>
    </>
  );
};
export default PrintAssay;
