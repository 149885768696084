import React, { useState, useEffect } from 'react';
import { Card, Empty, Space, Tag, Input } from 'antd';
import CustomTable from 'common/Content/CustomTable';
import Layout from '@/common/Layout';
import styles from '@/common/Layout/index.module.less';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDispenses, updateDispense } from '../../redux/slice';
import { getdetails } from '@/features/material/redux/slice';
import { DndContext } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { HolderOutlined, EyeOutlined } from '@ant-design/icons';
import FormItem from '@/common/FormItem';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import moment from 'moment';
import { getLoggedInUser, getRoles } from 'utilities/helpers';
import { useLocation } from 'react-router-dom';
import CustomModal from '@/common/CustomModal';

const { Search } = Input;

const ListPage = () => {
  const { dispenses, getAllMaterilLoading, pagination } = useSelector(
    (state) => state.dispensing
  );

  const { user } = getLoggedInUser();
  const { Superadmindata } = getRoles('Production');

  const searchParam = useLocation().search;
  const searchParams = new URLSearchParams(searchParam);

  const [dataSource, setDataSource] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [expandData, setExpandData] = useState();
  const [showModal, setShowModal] = useState(false);
  console.log(dispenses);
  const dispatch = useDispatch();

  useEffect(() => {
    handleGetAllDispenses();
  }, [page, search]);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const handleGetAllDispenses = () => {
    let payload =
      searchParams.get('type') == 'granulation'
        ? { page, is_dispensed: true, search }
        : { page, search };

    dispatch(getAllDispenses(payload)).then(function (response) {
      if (response?.payload?.success) {
        console.log(response?.payload?.data);
        setDataSource(response?.payload?.data);
      }
    });
  };

  const handleSubmit = () => {
    const payload = { update_sequence: dataSource };
    dispatch(updateDispense(payload)).then(function (response) {
      if (response?.payload?.success) {
        history.push('/dispensing-list');
      }
    });
  };

  const ShowdetailedView = (id, type) => {
    console.log(id, type);
    setShowModal(true);
    const payload = { id: id, type: type };
    dispatch(getdetails(payload)).then((response) => {
      if (response?.payload?.success) {
        response?.payload?.data?.type == 'bmr'
          ? setExpandData(response?.payload?.data)
          : setExpandData(response?.payload?.data);
      }
    });
  };

  // const columns = [
  //   {
  //     title: 'BMR/Material #',
  //     dataIndex: 'bmr_number',
  //     // ellipsis: true,
  //     width: '100px',
  //     fixed: 'left',
  //     render: (text, record) => {
  //       return searchParams.get('type') == 'granulation' ? (
  //         <a href={`granulation/${record?.id}`}>{text}</a>
  //       ) : (
  //         `${text || 'NA'}`
  //       );
  //     }
  //   },
  //   {
  //     title: 'Product',
  //     dataIndex: 'product_name',
  //     ellipsis: true,
  //     width: '100px',
  //     render: (text, record) => {
  //       return `${record?.product_detail?.name || 'NA'}`;
  //     }
  //   },

  //   {
  //     title: 'Created On',
  //     dataIndex: 'createdAt',
  //     ellipsis: true,
  //     width: '100px',
  //     render: (text, record) => {
  //       return `${moment(record?.createdAt).format('DD MMM YYYY') || 'NA'}`;
  //     }
  //   },
  //   {
  //     title: 'Status',
  //     dataIndex: 'status',
  //     ellipsis: true,
  //     width: '100px',
  //     render: (text, record) => {
  //       return (
  //         <Tag
  //           color={
  //             record?.status === 'WH LC Pending'
  //               ? '#dc3545'
  //               : record?.status === 'QA LC Pending'
  //               ? '#ff9933'
  //               : record?.status === 'Approved'
  //               ? '#28a745'
  //               : '#007bff'
  //           }
  //         >
  //           {record?.status}
  //         </Tag>
  //       );
  //     }
  //   },
  //   {
  //     title: 'Sequence',
  //     dataIndex: 'id',
  //     key: 'id',
  //     ellipsis: true,
  //     width: '100px',
  //     render: () => {
  //       return <HolderOutlined />;
  //     }
  //   },
  //   {
  //     title: 'Action',
  //     dataIndex: 'batch_number',
  //     ellipsis: true,
  //     width: '100px',
  //     render: (_, record) => {
  //       return (
  //         <Space>
  //           <EditOutlined
  //             onClick={() => history.push(`dispensing?id=${record.id}`)}
  //           />
  //         </Space>
  //       );
  //     }
  //   }
  // ];
  const columns1 = [
    {
      title: 'BMR/Material #',
      dataIndex: 'bmr_number',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return searchParams.get('type') == 'granulation' ? (
          <a href={`granulation/${record?.id}`}>{text}</a>
        ) : (
          `${text || 'NA'}`
        );
      }
    },

    {
      title: 'Product',
      dataIndex: 'product_name',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return `${record?.product_detail?.name || 'NA'}`;
      }
    },
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return `${moment(record?.createdAt).format('DD MMM YYYY') || 'NA'}`;
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return (
          <Tag
            color={
              record?.status === 'WH LC Pending'
                ? '#dc3545'
                : record?.status === 'QA LC Pending'
                ? '#ff9933'
                : record?.status === 'Approved'
                ? '#28a745'
                : '#007bff'
            }
          >
            {record?.status}
          </Tag>
        );
      }
    },
    {
      title: 'Sequence',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: '100px',
      render: () => {
        return <HolderOutlined />;
      }
    }
  ];
  const columns3 = [
    {
      title: 'Product Batch #',
      dataIndex: 'product_batch_number',
      // ellipsis: true,
      width: '200px',
      render: (text, record) => {
        return record?.is_print ? (
          <a href={`view-execute-bmr/${record.id}`}>{text || 'NA'}</a>
        ) : user?.role == 'prod_user' &&
          user?.level == 2 &&
          searchParams.get('type') !== 'granulation' ? (
          record?.status == 'Dispensed' && record?.sub_type == 'auto' ? (
            <a href={`dispensing-material/${record.id}`}>{text || 'NA'}</a>
          ) : (record?.status == 'Dispensed' && record?.sub_type == 'full') ||
            record?.sub_type == 'partial' ? (
            <a href={`manual_dispense/${record.id}?type=${record?.type}`}>
              {text || 'NA'}
            </a>
          ) : (
            text || 'NA'
          )
        ) : searchParams.get('type') == 'granulation' &&
          record?.status !== 'WH LC Pending' ? (
          // <a href={`granulation/${record?.id}`}>{text}</a>
          <a href={`weighing/${record?.id}`}>{text}</a>
        ) : (record?.sub_type == 'full' ||
            record?.sub_type == 'partial' ||
            record?.type == 'material') &&
          record?.status !== 'WH LC Pending' ? (
          <a href={`manual_dispense/${record.id}?type=${record?.type}`}>
            {text || 'NA'}
          </a>
        ) : record?.sub_type == 'auto' && record?.status !== 'WH LC Pending' ? (
          <a href={`dispensing-material/${record.id}`}>{text || 'NA'}</a>
        ) : (
          `${text || 'NA'}`
        );
      }
    },
    {
      title: 'BMR/Material #',
      dataIndex: 'bmr_number',
      fixed: 'left',
      // ellipsis: true,
      // width: '100px',
      render: (text, record) => {
        return `${record?.bmr_number || 'NA'}`;
      }
    },
    {
      title: 'Product',
      dataIndex: 'product_name',
      ellipsis: true,
      // width: '100px',
      render: (text, record) => {
        return `${record?.product_detail?.name || 'NA'}`;
      }
    },
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      ellipsis: true,
      // width: '100px',
      render: (text, record) => {
        return `${moment(record?.createdAt).format('DD MMM YYYY') || 'NA'}`;
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      ellipsis: true,
      // width: '100px',
      render: (text, record) => {
        return (
          <Tag
            color={
              record?.status === 'WH LC Pending'
                ? '#dc3545'
                : record?.status === 'QA LC Pending'
                ? '#ff9933'
                : record?.status === 'Approved'
                ? '#28a745'
                : '#007bff'
            }
          >
            {record?.status}
          </Tag>
        );
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return (
          <Space>
            {record.type == 'bmr' && record.sub_type == 'auto' ? (
              <EyeOutlined
                size="small"
                onClick={() => ShowdetailedView(record.id, record.type)}
                // onClick={() => history.push(`/assay-calculation/${record.id}`)}
              />
            ) : record.type == 'material' ? (
              <EyeOutlined
                size="small"
                onClick={() => ShowdetailedView(record.id, record.type)}
              />
            ) : (
              ''
            )}
          </Space>
        );
      }
    }
  ];
  const columns5 = [
    {
      title: 'Product Batch #',
      dataIndex: 'product_batch_number',
      // ellipsis: true,
      width: '200px',
      render: (text, record) => {
        return user?.role == 'prod_user' &&
          user?.level == 2 &&
          searchParams.get('type') !== 'granulation' ? (
          record?.status === 'WH LC Pending' ||
          record?.status === 'QA LC Pending' ? (
            <a href={`dispensing?id=${record.id}`}>{text || 'NA'}</a>
          ) : (record?.status == 'Dispensed' || record?.status == 'Approved') &&
            record?.sub_type == 'auto' ? (
            <a href={`dispensing-material/${record.id}`}>{text || 'NA'}</a>
          ) : record?.status == 'Dispensed' || record?.status == 'Approved' ? (
            //   &&
            //   record?.sub_type == 'full') ||
            // record?.sub_type == 'partial' ||
            <a href={`manual_dispense/${record.id}?type=${record?.type}`}>
              {text || 'NA'}
            </a>
          ) : (
            text || 'NA'
          )
        ) : searchParams.get('type') == 'granulation' &&
          record?.status !== 'WH LC Pending' ? (
          // <a href={`granulation/${record?.id}`}>{text}</a>
          <a href={`weighing/${record?.id}`}>{text}</a>
        ) : (record?.sub_type == 'full' ||
            record?.sub_type == 'partial' ||
            record?.type == 'material') &&
          record?.status !== 'WH LC Pending' ? (
          <a href={`manual_dispense/${record.id}?type=${record?.type}`}>
            {text || 'NA'}
          </a>
        ) : record?.sub_type == 'auto' && record?.status !== 'WH LC Pending' ? (
          <a href={`dispensing-material/${record.id}`}>{text || 'NA'}</a>
        ) : (
          `${text || 'NA'}`
        );
      }
    },
    {
      title: 'BMR/Material #',
      dataIndex: 'bmr_number',
      fixed: 'left',
      // ellipsis: true,
      // width: '100px',
      render: (text, record) => {
        return `${record?.bmr_number || 'NA'}`;
      }
    },
    {
      title: 'Product',
      dataIndex: 'product_name',
      ellipsis: true,
      // width: '100px',
      render: (text, record) => {
        return `${record?.product_detail?.name || 'NA'}`;
      }
    },
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      ellipsis: true,
      // width: '100px',
      render: (text, record) => {
        return `${moment(record?.createdAt).format('DD MMM YYYY') || 'NA'}`;
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      ellipsis: true,
      // width: '100px',
      render: (text, record) => {
        return (
          <Tag
            color={
              record?.status === 'WH LC Pending'
                ? '#dc3545'
                : record?.status === 'QA LC Pending'
                ? '#ff9933'
                : record?.status === 'Approved'
                ? '#28a745'
                : '#007bff'
            }
          >
            {record?.status}
          </Tag>
        );
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return (
          <Space>
            {record.type == 'bmr' && record.sub_type == 'auto' ? (
              <EyeOutlined
                size="small"
                onClick={() => ShowdetailedView(record.id, record.type)}
                // onClick={() => history.push(`/assay-calculation/${record.id}`)}
              />
            ) : record.type == 'material' ? (
              <EyeOutlined
                size="small"
                onClick={() => ShowdetailedView(record.id, record.type)}
              />
            ) : (
              ''
            )}
          </Space>
        );
      }
    }
  ];

  const Row = ({ children, ...props }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging
    } = useSortable({
      id: props['data-row-key']
    });
    const style = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && {
          ...transform,
          scaleY: 1
        }
      )?.replace(/translate3d\(([^,]+),/, 'translate3d(0,'),
      transition,
      ...(isDragging
        ? {
            position: 'relative',
            zIndex: 9999
          }
        : {})
    };
    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if (child.key === 'id') {
            return React.cloneElement(child, {
              children: (
                <HolderOutlined
                  ref={setActivatorNodeRef}
                  style={{
                    touchAction: 'none',
                    cursor: 'move'
                  }}
                  {...listeners}
                />
              )
            });
          }
          return child;
        })}
      </tr>
    );
  };

  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        <div className={styles.empty_state_text}>
          There are no material requests to display
        </div>
      </span>
    )
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.id === active.id);
        const overIndex = previous.findIndex((i) => i.id === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };
  console.log(dataSource);
  // const expandedRowRender = (data) => {
  // console.log(id);

  const columns4 = [
    // {
    //   title: 'Batch Number',
    //   dataIndex: 'date',
    //   key: 'date',
    //   render: (text, record) => {
    //     return record?.batch_detail?.inhouse_batch_number;
    //   }
    // },
    {
      title: 'Material Code',
      dataIndex: 'name',
      key: 'name',
      width: '200px',
      render: (text, record) => {
        return record?.material?.code;
      }
    },
    {
      title: 'Material Name',
      dataIndex: 'upgradeNum',
      key: 'upgradeNum',
      width: '300px',
      render: (text, record) => {
        return record?.material?.name;
      }
    },
    {
      title: 'Required Quantity',
      dataIndex: 'upgradeNum',
      key: 'upgradeNum',
      render: (text, record) => {
        return `${record?.required_weight} ${record?.material?.uom}`;
      }
    }
  ];

  //   return data?.dispensing_materials?.length && data?.type !== 'bmr' ? (
  //     <CustomTable
  //       columns={columns}
  //       dataSource={data?.dispensing_materials}
  //       pagination={false}
  //     />
  //   ) : data?.type == 'bmr' ? (
  //     <div
  //       dangerouslySetInnerHTML={{
  //         __html: data?.bmr_partial_details
  //       }}
  //       className={styles.tdbody}
  //     />
  //   ) : (
  //     ''
  //   );
  // };

  console.log(dataSource);
  return (
    <Layout sider={false}>
      <Card
        className={`${styles.cardStyles_approver}`}
        title={<span style={{ fontWeight: 'bold' }}>Dispensing List</span>}
        extra={
          <Space>
            <Search
              onChange={(e) => handleChange(e)}
              className={`w-100 ${styles.searchbar}`}
              justify="start"
              align="start"
              placeholder="Search by Product Batch number"
            />
          </Space>
        }
      >
        <DndContext onDragEnd={onDragEnd}>
          <SortableContext
            items={dataSource.map((i) => i.id)}
            strategy={verticalListSortingStrategy}
          >
            <div className={`${styles.table_container}`}>
              {user?.role == 'ppic_user' ? (
                <CustomTable
                  locale={locale}
                  loading={getAllMaterilLoading}
                  columns={
                    (user?.role == 'prod_user' && user?.level == 3) ||
                    Superadmindata?.[0]?.level == 3
                      ? columns3
                      : user?.role == 'ppic_user' && user?.level == 2
                      ? columns3
                      : columns3
                  }
                  dataSource={dataSource}
                  pagination={{
                    size: 'small',
                    total: pagination?.total_records || 0,
                    showSizeChanger: false,
                    pageSize: 10,
                    onChange: (page) => {
                      setPage(page);
                    }
                  }}
                  components={{
                    body: {
                      row: Row
                    }
                  }}
                  rowKey={user?.role == 'ppic_user' ? 'id' : ''}
                  // className={styles.scrolling}
                  // scroll={{ x: 'auto' }}
                  // expandable={{
                  //   expandedRowRender,
                  //   defaultExpandedRowKeys: ['0']
                  // }}
                />
              ) : (
                <CustomTable
                  locale={locale}
                  loading={getAllMaterilLoading}
                  columns={
                    user?.role == 'prod_user' && user?.level == 2
                      ? columns5
                      : user?.role == 'prod_user' && user?.level == 3
                      ? columns3
                      : user?.role == 'ppic_user' && user?.level == 2
                      ? columns1
                      : columns3
                  }
                  dataSource={dataSource}
                  pagination={{
                    size: 'small',
                    total: pagination?.total_records || 0,
                    showSizeChanger: false,
                    pageSize: 10,
                    onChange: (page) => {
                      setPage(page);
                    }
                  }}
                  // className={styles.scrolling}
                  // scroll={{ x: 'auto' }}
                  // expandable={{
                  //   expandedRowRender,
                  //   defaultExpandedRowKeys: ['0']
                  // }}
                />
              )}
            </div>
          </SortableContext>
        </DndContext>
      </Card>
      {showModal ? (
        <CustomModal
          title="Dispense Material Details"
          visible={showModal}
          width={expandData?.type == 'material' ? '70%' : '30%'}
          footer={null}
          className={styles.customModal}
          closable={false}
          // className={styles.cardStyles_approver}
        >
          {expandData?.type == 'material' ? (
            <CustomTable
              columns={columns4}
              dataSource={expandData?.dispensing_materials}
              pagination={false}
              loading={getAllMaterilLoading}
            />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: expandData?.bmr_partial_details
              }}
              // className={styles.tdbody}
            />
          )}
          <br />
          <FormItem className="text-center">
            <Space size={12}>
              <CustomButton
                htmlType="button"
                onClick={() => setShowModal(false)}
              >
                Close
              </CustomButton>
            </Space>
          </FormItem>
        </CustomModal>
      ) : (
        ''
      )}
      <br />
      {user?.role !== 'prod_user' ? (
        <FormItem className="text-center">
          <Space>
            <CustomButton type="primary" onClick={handleSubmit}>
              Update
            </CustomButton>
          </Space>
        </FormItem>
      ) : (
        ''
      )}
    </Layout>
  );
};
export default ListPage;
