import React, { useState, useEffect } from 'react';
// import Layout from '@/common/Layout';
import { Form, Card, Space, Input, Tag } from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import { getAllApprovedMaterials } from '../../redux/slice';
import CustomTable from 'common/Content/CustomTable';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import Layout from '@/common/Layout';
import { useDispatch } from 'react-redux';
import { getLoggedInUser } from 'utilities/helpers';
// import moment from 'moment';

const { Search } = Input;

const MaterialList = ({ loading }) => {
  const [form] = Form.useForm();
  const [allsuppliers, setAllsuppliers] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [search, setSearch] = useState('');

  const { user } = getLoggedInUser();
  //   console.log(allsamples);
  const dispatch = useDispatch();
  useEffect(() => {
    handleGetAllMaterialDetails();
  }, [page, search]);

  const handleGetAllMaterialDetails = () => {
    const payload = { page: page, search: search };
    dispatch(getAllApprovedMaterials(payload)).then((response) => {
      if (response?.payload?.success) {
        setAllsuppliers(response?.payload?.data);
        setPagination(response?.payload?.pagination);
      }
    });
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  console.log(user);
  const columns = [
    {
      title: 'Material Code',
      dataIndex: 'code',
      key: 'code',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.qa_status == 'Pending' ? (
          <a href={`/view-vendor?id=${record?.id}&type=material`}>
            {record?.code}
          </a>
        ) : (
          record?.code
        );
      }
    },
    {
      title: 'Material Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.name;
      }
    },
    {
      title: 'Type',
      dataIndex: 'item',
      key: 'item',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'UOM',
      dataIndex: 'uom',
      key: 'uom',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'Status',
      dataIndex: 'qa_status',
      key: 'qa_status',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return (
          <Tag
            color={
              record?.qa_status === 'Pending'
                ? '#007bff'
                : record?.qa_status === 'Rejected'
                ? '#dc3545'
                : record?.qa_status === 'Approved'
                ? '#28a745'
                : '#007bff'
            }
          >
            {record?.qa_status?.toUpperCase()}
          </Tag>
        );
      }
    }
  ];

  return (
    <>
      <Layout sider={false}>
        <Form form={form} layout="vertical">
          <Card
            title={<span>Material Details</span>}
            className={`${styles.cardStyles_approver}`}
            extra={
              <Space>
                <Search
                  onChange={(e) => handleChange(e)}
                  className={`w-100 ${styles.searchbar}`}
                  justify="start"
                  align="start"
                  placeholder="Search by Material Code"
                />
                <CustomButton
                  className={styles.inwardButton}
                  onClick={() => history.push('/create-material')}
                  type="primary"
                >
                  Create
                </CustomButton>
              </Space>
            }
          >
            <div className={`${styles.table_container}`}>
              <CustomTable
                columns={columns}
                pagination={{
                  size: 'small',
                  total: pagination?.total_records || 0,
                  showSizeChanger: false,
                  onChange: (page) => {
                    setPage(page);
                  }
                }}
                dataSource={allsuppliers}
                loading={loading}
              />
            </div>
          </Card>
          <br />
          <FormItem className="text-center">
            <CustomButton
              className={styles.inwardButton}
              onClick={() => history.back()}
              type="primary"
            >
              Back to Home
            </CustomButton>
          </FormItem>
        </Form>
      </Layout>
    </>
  );
};
export default MaterialList;
