import React, { useState } from 'react';
import { Form, Card, Space, DatePicker } from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import localStyles from './index.module.less';
import CustomTable from 'common/Content/CustomTable';
import CustomButton from '@/common/CustomButton';
import Layout from '@/common/Layout';
import { history } from 'app/history';
import { useGetUacrOfSamplingOrDbarsQuery } from '../../redux/apiSlice';
import moment from 'moment';
import Spinner from '../../../../common/Spinner';
const { RangePicker } = DatePicker;

const AdminPage = () => {
  const [form] = Form.useForm();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  let totalRecords;

  const { data, error, isLoading, refetch } = useGetUacrOfSamplingOrDbarsQuery({
    page,
    per_page: perPage,
    from_date: fromDate,
    to_date: toDate
  });

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      ellipsis: true,
      // width: '200px',
      render: (_, record) => {
        return record?.createdAt ? (
          <a href={`uacr-of-sampling-or-dbar-list/${record?.id}`}>
            {moment(record.createdAt).format('DD-MMM-YYYY')}
          </a>
        ) : (
          <a href={`uacr-of-sampling-or-dbar-list/${record?.id}`}>{'N/A'}</a>
        );
      }
    },
    {
      title: 'Name',
      dataIndex: 'areaName',
      key: 'areaName',
      ellipsis: true,
      // width: '200px',
      render: (_, record) => {
        return record?.sampling_dispensing_booth_area_master?.area
          ? record.sampling_dispensing_booth_area_master.area
          : '';
      }
    },
    {
      title: 'Equipment Id',
      dataIndex: 'areaName',
      key: 'areaName',
      ellipsis: true,
      // width: '200px',
      render: (_, record) => {
        return (
          <>
            {record?.cleaning_log_book_accessories.length > 0
              ? record?.cleaning_log_book_accessories.map((obj, index) => {
                  if (index === 0) {
                    return (
                      <span>{`${obj?.cleaning_accessories_master?.cleaning_accessories} (${obj?.cleaning_accessories_master?.cleaning_equip_id})`}</span>
                    );
                  } else {
                    return (
                      <>
                        <span>,</span>
                        <br />
                        <span>{`${obj?.cleaning_accessories_master?.cleaning_accessories} (${obj?.cleaning_accessories_master?.cleaning_equip_id})`}</span>
                      </>
                    );
                  }
                })
              : ''}
          </>
        );
      }
    }
  ];

  let content;
  if (isLoading) {
    console.log('Loading.....');
    content = <Spinner />;
  } else if (data) {
    console.log(data?.pagination?.total_records);
    totalRecords = data?.pagination?.total_records;
    content = (
      <Layout sider={false}>
        <div className={localStyles.heading1}>
          <h1>Overview</h1>
          <h4>Your current requests summary and other activities</h4>
        </div>
        <Form form={form} layout="vertical">
          <Card
            title={
              <span>
                Usage and Cleaning Record of Sampling / Dispensing Booth And
                Room
              </span>
            }
            className={`${styles.cardStyles_approver}`}
            extra={
              <Space>
                <RangePicker
                  onChange={(e) => {
                    console.log(e[0]._d);
                    setFromDate(moment(e[0]._d).startOf('day').format());
                    setToDate(moment(e[1]._d).endOf('day').format());
                  }}
                />
                <CustomButton
                  className={styles.inwardButton}
                  onClick={() =>
                    history.push('/uacr-of-sampling-or-dbar-create')
                  }
                  type="primary"
                >
                  Create
                </CustomButton>
              </Space>
            }
          >
            <div className={`${styles.table_container}`}>
              <CustomTable
                columns={columns}
                dataSource={data.data}
                pagination={{
                  size: 'small',
                  pageSize: perPage,
                  defaultCurrentPage: page,
                  total: totalRecords,
                  onChange: (page, pageSize) => {
                    setPage(page);
                    setPerPage(pageSize);
                    refetch();
                  },
                  showSizeChanger: true
                }}
              />
            </div>
          </Card>
          <br />
          <FormItem className="text-center">
            <CustomButton
              className={styles.inwardButton}
              onClick={() => history.back()}
              type="primary"
            >
              Back to Home
            </CustomButton>
          </FormItem>
        </Form>
      </Layout>
    );
  } else if (error) {
    console.log(error);
    content = 'Something went wrong!';
  }

  return <>{content}</>;
};
export default AdminPage;
