import { configureStore } from '@reduxjs/toolkit';
import loginReducer from 'features/login/redux/slice';
import dashboardReducer from '../features/dashboard/redux/slice';
import materialReducer from '../features/material/redux/slice';
import materialDetailsReducer from '../features/lineclearancechecklist/redux/slice';
import labelReducer from '../features/label/redux/slice';
import batchReducer from '../features/batchdetails/redux/slice';
import damageReducer from '../features/damagereport/redux/slice';
import qcReducer from '../features/qc/redux/slice';
import lcMaterialDetailsReducer from '../features/qc/redux/slice';
import binReducer from '../features/MoveMaterials/redux/slice';
import dispensingReducer from '../features/dispensing/redux/slice';
import qaapprovalReducer from '../features/qa/redux/slice';
import stocksReducer from '../features/stockCard/redux/slice';
import issuanceReducer from '../features/stockCardIssuance/redux/slice';
import reconsiliationsReducer from '../features/stockReconsilation/redux/slice';
import reconciliationReducer from '../features/ReconsiliationRequest/redux/slice';
import wastageReducer from '../features/wastageReconsilation/redux/slice';
import adminReducer from '../features/admin/redux/slice';
import productReducer from '../features/prodUser/redux/slice';
import returnReducer from '../features/returnRequest/redux/slice';
import FinanceUserReducer from '../features/financeUser/redux/slice';
import AuditTrailReducer from '../features/auditTrails/redux/slice';
import LcReducer from '../features/lineclearancechecklist/redux/slice';
import ReturnReducer from '../features/logBooks/redux/slice';
import { logBooksApi } from '@/features/logBooks/redux/apiSlice';
import { emsApi } from '../features/prodUser/redux/apiSlice';

export const store = configureStore({
  reducer: {
    login: loginReducer,
    dashboard: dashboardReducer,
    material: materialReducer,
    materialDetails: materialDetailsReducer,
    lcMaterialDetails: lcMaterialDetailsReducer,
    label: labelReducer,
    batch: batchReducer,
    damage: damageReducer,
    qc: qcReducer,
    bins: binReducer,
    dispensing: dispensingReducer,
    qaapproval: qaapprovalReducer,
    stocks: stocksReducer,
    issuance: issuanceReducer,
    reconsiliations: reconsiliationsReducer,
    reconciliation: reconciliationReducer,
    wastage: wastageReducer,
    admin: adminReducer,
    product: productReducer,
    returns: returnReducer,
    FinanceUser: FinanceUserReducer,
    AuditTrail: AuditTrailReducer,
    lc: LcReducer,
    return: ReturnReducer,
    [logBooksApi.reducerPath]: logBooksApi.reducer,
    [emsApi.reducerPath]: emsApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(logBooksApi.middleware, emsApi.middleware)
});
