import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ResetPasswordPage from './components/ResetPasswordPage';
import { resetPassword } from './redux/slice';
import { message } from 'antd';
import { history } from 'app/history';

const ResetPassword = () => {
  const { loading } = useSelector((state) => state.login);
  const dispatch = useDispatch();

  const onSubmit = (payload) => {
    dispatch(resetPassword(payload)).then((response) => {
      if (response?.payload?.success) {
        message.success(response?.payload?.message);
        history.push('/login');
      } else {
        message.error(response?.payload?.message);
      }
    });
  };

  return (
    <>
      <ResetPasswordPage loading={loading} onSubmit={onSubmit} />
    </>
  );
};

export default ResetPassword;
