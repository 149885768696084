import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  // DatePicker,
  // Select,
  Radio,
  Space,
  // Image,
  message,
  Card
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
// import truck from '../images/truck-delivery-done.gif';
import { useDispatch } from 'react-redux';
import { history } from 'app/history';
import Layout from '@/common/Layout';
import { storage_qc } from '../../redux/slice';
import { useParams } from 'react-router-dom';

const { TextArea } = Input;

const StorageMissingPage = ({ loading }) => {
  const params = useParams();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  // const [truckShow, setTruckShow] = useState(false);
  const [material, setMaterial] = useState({});
  const handleCapacity = (e) => {
    const { name, value } = e.target;
    setMaterial({ ...material, [name]: value });
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo.errorFields.length);
    if (errorInfo.errorFields.length > 0) {
      message.error(
        'Please select a response to all the questions in the checklist'
      );
    }
  };
  console.log(localStorage.getItem('inv_num'));
  const onFinish = (values) => {
    // setTruckShow(true);
    values['document_id'] = params.id;
    dispatch(storage_qc(values)).then(function (response) {
      if (response?.payload?.success) {
        message.success(response?.payload?.message);
        history.push('/qc-list?qc=veh_ver_pending');
      }
    });
  };

  // const handleStandardCancel = () => {
  //   history.push('/dashboard');
  // };

  useEffect(() => {
    form.setFieldsValue({
      invoice_no: localStorage.getItem('invoice_num')
    });
  }, []);

  return (
    <Layout sider={false}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Card
          title="APPROVAL FOR MISSING DOCUMENT"
          className={styles.CustomPanel}
        >
          <Row>
            <Col xs={24} xl={6} span={6}>
              <FormItem
                label="Invoice Number"
                name="invoice_no"
                rules={[
                  {
                    required: true,
                    message: 'Enter Invoice number'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter Invoice number"
                  defaultValue={localStorage.getItem('inv_num')}
                  disabled
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} xl={8} span={8}>
              <FormItem
                label="Proceed further"
                name="storage_proceed_further"
                rules={[
                  {
                    required: true,
                    message: 'Choose Yes/No'
                  }
                ]}
                className={styles.customLable}
              >
                <Radio.Group
                  onChange={handleCapacity}
                  className={styles.customRadio}
                  name="storage_proceed_further"
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </FormItem>
            </Col>
            <Col xs={24} xl={24} span={24}>
              <FormItem
                label="Comments"
                name="comments_on_storage"
                rules={[
                  {
                    required: false,
                    message: 'Enter comments'
                  }
                ]}
                className={styles.customLable}
              >
                <TextArea
                  placeholder="Please enter comments"
                  name="comments"
                  // onChange={(e) => handleComments(index, e)}
                />
              </FormItem>
            </Col>
          </Row>
        </Card>
        <br />
        <FormItem className="text-center">
          <Space>
            <CustomButton type="primary" htmlType="submit" loading={loading}>
              Approve
            </CustomButton>
          </Space>
        </FormItem>
        {/* </Space> */}
      </Form>
    </Layout>
  );
};
export default StorageMissingPage;
