import React, { useEffect, useState } from 'react';
import Layout from '@/common/Layout';
import {
  Row,
  Col,
  Form,
  Card,
  Select,
  Input,
  DatePicker,
  Space,
  message,
  Image
} from 'antd';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import { getAllMaterials, createVendor } from '../../redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import moment from 'moment';
import truck from '@/features/generateMrn/components/images/truck-delivery-done.gif';
import CustomTable from 'common/Content/CustomTable';

const { TextArea } = Input;

const CreateVendor = ({ loading }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { allqcMaterials } = useSelector((state) => state.material);
  console.log(allqcMaterials);

  const [code, setCode] = useState('');
  const [search, setSearch] = useState('');
  const [materials, setMaterials] = useState([]);
  const [suppliersdata, setSuppliersdata] = useState([]);
  const [truckShow, setTruckShow] = useState(false);

  console.log(setCode, setSearch, materials);
  useEffect(() => {
    handleGetAllMaterialsList();
  }, [search, code]);

  const onSearch = (value) => {
    setSearch(value);
  };

  const onChange = (value) => {
    console.log(value);
    const material = materials.filter(
      (MaterialsList) => MaterialsList.id === value
    );
    form.setFieldsValue({ material_name: material[0]?.name });
    form.setFieldsValue({ uom: material[0]?.uom });
    form.setFieldsValue({ type: material[0]?.item });
    form.setFieldsValue({ temparature: material[0]?.temp });
    setCode(material[0]);
    setSuppliersdata(material[0]);
  };

  const handleGetAllMaterialsList = () => {
    let payload = { search };
    dispatch(getAllMaterials(payload)).then((response) => {
      if (response?.payload?.success) {
        setMaterials(response?.payload?.data);
      }
    });
  };

  const onFinish = (values) => {
    console.log(values);
    setTruckShow(true);
    const payload = {
      name: values?.suppliers_name,
      vendor_status: values?.status,
      address1: values?.supplier_address,
      manufacturer_name: values?.manufacturer_name,
      manufacturer_address: values?.manufacturer_address,
      requalification_due: values?.requalification_due,
      tolerance_months: values?.tollerence,
      material_id: values?.material_code
    };
    dispatch(createVendor(payload)).then((response) => {
      if (response?.payload?.success) {
        setTruckShow(false);
        message.success(response?.payload?.message);
        history.push('/vendors');
      }
    });
  };

  console.log(suppliersdata);
  const columns = [
    {
      title: 'Supplier Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'Manufacturer Name',
      dataIndex: 'manufacturer_name',
      key: 'manufacturer_name',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'Material Code',
      dataIndex: 'code',
      key: 'code',
      ellipsis: true,
      width: '100px',
      render: () => {
        return suppliersdata?.code;
      }
    },
    {
      title: 'Material Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: '100px',
      render: () => {
        return suppliersdata?.name;
      }
    },
    {
      title: 'Status',
      dataIndex: 'qa_status',
      key: 'qa_status',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'Re-qualification Due',
      dataIndex: 'requalification_due',
      key: 'requalification_due',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.requalification_due
          ? moment(record?.requalification_due).format('DD MMM YYYY')
          : 'NA';
      }
    }
  ];

  return (
    <Layout sider={false}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
      >
        <Card title="CREATE NEW VENDOR" className={styles.CustomPanel}>
          <Row gutter={24}>
            <Col xs={24} xl={8} span={8} md={24} sm={24}>
              <FormItem
                label="Material Code"
                name="material_code"
                rules={[
                  {
                    required: true,
                    message: 'Select material code'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  showSearch
                  placeholder="Select a material code"
                  optionFilterProp="children"
                  onChange={onChange}
                  onSearch={onSearch}
                >
                  {materials?.map((item, index) => {
                    return (
                      <option value={item?.id} key={index}>
                        {item?.code}
                      </option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={8} md={24} sm={24}>
              <FormItem
                label="Name of the Material"
                name="material_name"
                rules={[
                  {
                    required: true,
                    message: 'Enter name of the material'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter name of the material" disabled />
              </FormItem>
            </Col>
            <Col xs={24} xl={6} span={6} md={24} sm={24}>
              <FormItem
                label="Type of Material"
                name="type"
                rules={[
                  {
                    required: true,
                    message: 'Enter name of the material type'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter name of the type" disabled />
              </FormItem>
            </Col>
            <Col xs={24} xl={2} span={2} md={24} sm={24}>
              <FormItem
                label="UOM"
                name="uom"
                rules={[
                  {
                    required: true,
                    message: 'Enter UOM'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter UOM" disabled />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Manufacturer Name"
                name="manufacturer_name"
                rules={[
                  {
                    required: true,
                    message: 'Enter Manufacturer Name'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Manufacturer Name" />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Supplier Name"
                name="suppliers_name"
                rules={[
                  {
                    required: true,
                    message: 'Enter Suppliers Name'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Suppliers Name" />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Manufacturer Address"
                name="manufacturer_address"
                rules={[
                  {
                    required: true,
                    message: 'Enter Manufacturer Address'
                  }
                ]}
                className={styles.customLable}
              >
                <TextArea rows={4} placeholder="Enter Manufacturer Address" />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Supplier Address"
                name="supplier_address"
                rules={[
                  {
                    required: true,
                    message: 'Enter Suppliers Address'
                  }
                ]}
                className={styles.customLable}
              >
                <TextArea rows={4} placeholder="Enter Suppliers Address" />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Requalification Due"
                name="requalification_due"
                rules={[
                  {
                    required: true,
                    message: 'Enter Requalification Due'
                  }
                ]}
                className={styles.customLable}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  placeholder="Select Requalification Due Date"
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Vendor Status"
                name="status"
                rules={[
                  {
                    required: true,
                    message: 'Enter Vendor Status'
                  }
                ]}
                className={styles.customLable}
              >
                <Select placeholder="Select Status">
                  <option value="pending">Pending</option>
                  <option value="approved">Approved</option>
                  <option value="partially_approved">Partially Approved</option>
                </Select>
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Tolerance Months"
                name="tollerence"
                rules={[
                  {
                    required: true,
                    message: 'Enter Tolerance Months'
                  }
                ]}
                className={styles.customLable}
              >
                <Select placeholder="Select Tolerance Months">
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </Select>
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Storage Temperature Condition"
                name="temparature"
                rules={[
                  {
                    required: true,
                    message: 'Enter Storage Temareture Condition'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter MStorage Temareture Condition"
                  disabled
                />
              </FormItem>
            </Col>
          </Row>
        </Card>
        <br />
        <FormItem className="text-center">
          <Space>
            <CustomButton htmlType="button" onClick={() => history.back()}>
              Cancel
            </CustomButton>
            {truckShow ? (
              <Image
                src={truck}
                preview={false}
                style={{ width: '100px !important', height: '100px' }}
              />
            ) : (
              <CustomButton type="primary" htmlType="submit" loading={loading}>
                Add New Vendor
              </CustomButton>
            )}
          </Space>
        </FormItem>
      </Form>
      <br />
      <Card
        title={<span>Existing Vendors</span>}
        className={`${styles.cardStyles_approver}`}
      >
        <div className={`${styles.table_container}`}>
          <CustomTable
            columns={columns}
            dataSource={suppliersdata?.suppliers}
            loading={loading}
          />
        </div>
      </Card>
    </Layout>
  );
};
export default CreateVendor;
