import apiClient from 'utilities/apiClient';

export const getInvoices = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.batchLog}/${url}`, payload, true);
};

export const createInvoices = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.batchLog}/${url}`, payload, true);
};

export const getFG = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.batchLog}/${url}`, payload, true);
};

export const getPrdDsc = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.batchLog}/${url}`, payload, true);
};
