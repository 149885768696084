import React from 'react';
import styles from './index.module.less';
import { Card, Image, Row, Space, Col, Form } from 'antd';
import Layout from '@/common/Layout';
import truck from './images/1.svg';

const VendorCards = () => {
  const [form] = Form.useForm();
  const VendorCards = [
    {
      title: 'Vendors',
      icon: truck,
      url: '/vendors'
    },
    {
      title: 'Materials',
      icon: truck,
      url: '/material-list'
    }
  ];
  return (
    <Layout sider={false}>
      <Form
        form={form}
        layout="vertical"
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
      >
        <Row gutter={24}>
          {VendorCards?.map((items, index) => {
            return (
              <Col
                xs={6}
                xl={6}
                md={6}
                sm={6}
                span={6}
                className={`${styles.cardbody1} mb-2`}
                key={index}
              >
                <Card
                  hoverable
                  className={styles.cardbody}
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = items.url;
                  }}
                >
                  <Space direction="vertical">
                    <Image
                      src={items.icon}
                      preview={false}
                      // style={{ height: '200px' }}
                      className={styles.stickers}
                    />
                    <span>{items.title}</span>
                  </Space>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Form>
    </Layout>
  );
};
export default VendorCards;
