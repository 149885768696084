import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Card,
  Space,
  Input,
  DatePicker,
  Select,
  message,
  Image
} from 'antd';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import 'react-quill/dist/quill.snow.css';
import CustomButton from '@/common/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBatches, createTestForm } from '../../redux/slice';
// import { PlusOutlined } from '@ant-design/icons';
import { getLoggedInUser } from 'utilities/helpers';
import { history } from 'app/history';
import { useLocation } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import Layout from '@/common/Layout';
import moment from 'moment';
import truck from '../images/truck-delivery-done.gif';

const TestRequestForm = ({ loading }) => {
  const { batchdetails } = useSelector((state) => state.qaapproval);
  console.log(batchdetails);
  const searchParam = useLocation().search;
  const searchParams = new URLSearchParams(searchParam);
  const { user } = getLoggedInUser();

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isEdit, setIsEdit] = useState(false);

  const [samplings, setSamplings] = useState();
  const [truckShow, setTruckShow] = useState(false);

  const dateFormat = 'DD-MM-YYYY';

  useEffect(() => {
    handleGetAllBatchList();
  }, []);
  const handleGetAllBatchList = () => {
    let payload = {
      id: searchParams.get('id'),
      type: searchParams.get('type')
    };
    dispatch(getAllBatches(payload)).then((response) => {
      if (response?.payload?.success) {
        setSamplings(response?.payload?.data);
        form.setFieldsValue({
          ar_no: response?.payload?.data?.test_request_form?.ar_no
        });
        form.setFieldsValue({
          sampled_date: response?.payload?.data?.test_request_form?.sampled_date
            ? moment(
                response?.payload?.data?.test_request_form?.sampled_date,
                'YYYY-MM-DD'
              )
            : ''
        });
        form.setFieldsValue({
          sample_quantity:
            response?.payload?.data?.test_request_form?.sample_quantity
        });
        form.setFieldsValue({
          test_performed:
            response?.payload?.data?.test_request_form?.test_performed
        });
        form.setFieldsValue({
          analytical_status:
            response?.payload?.data?.test_request_form?.analytical_status
        });
        form.setFieldsValue({
          remarks: response?.payload?.data?.test_request_form?.remarks
        });

        form.setFieldsValue({
          trf_serial_no: response?.payload?.data?.trf_serial_no
        });
        form.setFieldsValue({
          test_to_be_performed: response?.payload?.data?.test_to_be_performed
        });
        response?.payload?.data?.test_request_form?.id
          ? setIsEdit(false)
          : setIsEdit(true);
      }
    });
  };
  console.log(isEdit);
  const onFinish = (Values) => {
    setTruckShow(true);
    Values['batch_details_id'] = searchParams.get('id');
    samplings?.test_request_form?.id
      ? (Values['trf_id'] = samplings?.test_request_form?.id)
      : '';
    samplings?.test_request_form?.id &&
    (user?.role == 'user' || user?.role == 'qa_super_user')
      ? (Values['type'] = 'regenerate')
      : '';
    console.log(Values);
    dispatch(createTestForm(Values)).then((response) => {
      if (response?.payload?.success) {
        setTruckShow(false);
        samplings?.test_request_form?.id && user?.role !== 'user'
          ? window.open(response?.payload?.data, '_blank')
          : '';
        message.success(response?.payload?.message);
        user?.role == 'user' || user?.role == 'qa_super_user'
          ? history.push(`list`)
          : history.push(`/batches-list?type=test_requests`);
      } else {
        setTruckShow(false);
      }
    });
    console.log(Values);
  };

  return (
    <Layout sider={false}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
      >
        <Card title="TEST REQUEST FORM" className={styles.CustomPanel}>
          <>
            <Card className={styles.SubPanel3}>
              <Row gutter={24}>
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="Date"
                    rules={[
                      {
                        required: false,
                        message: 'Select Date'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      value={moment(samplings?.createdAt).format('DD MMM YYYY')}
                      disabled
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="Serial Number"
                    name="trf_serial_no"
                    rules={[
                      {
                        required: false,
                        message: 'Enter Serial Number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input placeholder="Please Enter Serial Number" disabled />
                    {/* {user?.role == 'user' ? (
                      <Input placeholder="Please Enter Serial Number" />
                    ) : (
                      <Input value={samplings?.id} disabled />
                    )} */}
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="Material Code"
                    rules={[
                      {
                        required: false,
                        message: 'Enter Material Code'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input value={samplings?.material?.code} disabled />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="Name of the Material"
                    rules={[
                      {
                        required: false,
                        message: 'Enter Material Name'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input value={samplings?.material?.name} disabled />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="Vendor Batch/ LOT number"
                    rules={[
                      {
                        required: false,
                        message: 'Enter Vendor Batch/ LOT number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input value={samplings?.mfg_batch_lot_no} disabled />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="Name of the Manufacturer"
                    rules={[
                      {
                        required: false,
                        message: 'Enter Name of the Manufacturer'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      value={samplings?.supplier?.manufacturer_name}
                      disabled
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="Name of the Supplier name"
                    rules={[
                      {
                        required: false,
                        message: 'Enter Name of the Supplier'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input value={samplings?.supplier?.name} disabled />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="InHouse Batch Number"
                    rules={[
                      {
                        required: false,
                        message: 'Enter InHouse Batch Number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input value={samplings?.inhouse_batch_number} disabled />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="UOM"
                    rules={[
                      {
                        required: false,
                        message: 'Enter UOM'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input value={samplings?.material?.uom} disabled />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="MRN Number"
                    rules={[
                      {
                        required: false,
                        message: 'Enter MRN Number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input value={samplings?.mrn_number || 'NA'} disabled />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="MRN Date"
                    rules={[
                      {
                        required: false,
                        message: 'Enter MRN Date'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      value={
                        moment(samplings?.createdAt).format('DD MMM YYYY') ||
                        'NA'
                      }
                      disabled
                    />
                  </FormItem>
                </Col>

                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="Manufacturing Date"
                    rules={[
                      {
                        required: false,
                        message: 'Enter Manufacturing Date'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      value={
                        moment(samplings?.mfg_date).format('DD MMM YYYY') ||
                        'NA'
                      }
                      disabled
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="MFR Re-test Date"
                    rules={[
                      {
                        required: false,
                        message: 'Enter MFR Re-test Date'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      value={
                        moment(samplings?.mfg_retest_date).format(
                          'DD MMM YYYY'
                        ) || 'NA'
                      }
                      disabled
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="MFG Expiry Date"
                    rules={[
                      {
                        required: false,
                        message: 'Enter MFG Expiry Date'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      value={
                        moment(samplings?.mfg_expiry_date).format(
                          'DD MMM YYYY'
                        ) || 'NA'
                      }
                      disabled
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="Assigned Inhouse Expiry Date"
                    rules={[
                      {
                        required: false,
                        message: 'Enter Assigned Inhouse Expiry Date'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      value={
                        moment(samplings?.in_house_expiry_date).format(
                          'DD MMM YYYY'
                        ) || 'NA'
                      }
                      disabled
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="Total Received Quantity as per MRN"
                    rules={[
                      {
                        required: false,
                        message: 'Enter Total Received Quantity'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      value={samplings?.received_quantity || 'NA'}
                      disabled
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="Closing Quantity as per MRN"
                    rules={[
                      {
                        required: false,
                        message: 'Enter Closing Quantity'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      value={
                        samplings?.stock_card_issuances[0]?.closing_balance ||
                        'NA'
                      }
                      disabled
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="No.of Containers/Packs Received as per MRN"
                    rules={[
                      {
                        required: false,
                        message: 'Enter No.of Containers'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      value={samplings?.containers_packs_received || 'NA'}
                      disabled
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="Test to be Performed"
                    name="test_to_be_performed"
                    rules={[
                      {
                        required: false,
                        message: 'Enter No.of Containers'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    {user?.role == 'user' || user?.role == 'qa_super_user' ? (
                      <Select
                        placeholder="Select test perfomed"
                        // disabled={!isEdit}
                      >
                        <option value="Identification Test">
                          Identification Test
                        </option>
                        <option value="Complete Analysis">
                          Complete Analysis
                        </option>
                        <option value="R&D Identification Test">
                          R&D Identification Test
                        </option>
                        <option value="Retest">Retest</option>
                      </Select>
                    ) : (
                      <Input
                        value={samplings?.test_to_be_performed || 'NA'}
                        disabled
                      />
                    )}

                    {/* > */}
                  </FormItem>
                </Col>
                <Col xs={24} xl={24} span={24} md={24} sm={24}>
                  <FormItem
                    label="Remarks"
                    name="comments"
                    rules={[
                      {
                        required: false,
                        message: 'Enter Test to be Perfomed'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    {user?.role == 'user' || user?.role == 'qa_super_user' ? (
                      <Input placeholder="Enter Remarks" />
                    ) : (
                      <Input
                        placeholder="Enter Remarks"
                        value={samplings?.remarks || ''}
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
            </Card>
            {
              // samplings?.test_request_form?.id &&
              user?.role !== 'user' ? (
                <Card
                  title="To be Filled by Quality Control"
                  extra={
                    samplings?.test_request_form?.id ? (
                      <CustomButton
                        onClick={() => setIsEdit(true)}
                        type="primary"
                        ghost
                      >
                        <EditOutlined />
                      </CustomButton>
                    ) : (
                      ''
                    )
                  }
                >
                  <Row gutter={24}>
                    <Col xs={24} xl={12} span={12} md={24} sm={24}>
                      <FormItem
                        label="AR Number"
                        name="ar_no"
                        rules={[
                          {
                            required: false,
                            message: 'Enter AR number'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter AR number"
                          disabled={!isEdit}
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={12} span={12} md={24} sm={24}>
                      <FormItem
                        label="Sampled Date"
                        name="sampled_date"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Sampled Date'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <DatePicker
                          format={dateFormat}
                          style={{ width: '100%' }}
                          disabled
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={12} span={12} md={24} sm={24}>
                      <FormItem
                        label="Sampling Quantity"
                        name="sample_quantity"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Sampling Quantity'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input placeholder="Enter Sampling Quantity" disabled />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={12} span={12} md={24} sm={24}>
                      <FormItem
                        label="Test Perfomed"
                        name="test_performed"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Sampling Quantity'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Select
                          placeholder="Select test perfomed"
                          disabled={!isEdit}
                        >
                          <option value="Identification Test">
                            Identification Test
                          </option>
                          <option value="Complete Analysis">
                            Complete Analysis
                          </option>
                          <option value="R&D Identification Test">
                            R&D Identification Test
                          </option>
                        </Select>
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={12} span={12} md={24} sm={24}>
                      <FormItem
                        label="Analytical Status of Material"
                        name="analytical_status"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Analytical Status of Material'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Select placeholder="Select status" disabled={!isEdit}>
                          <option value="Approved">Approved</option>
                          <option value="Rejected">Rejected</option>
                          <option value="Discontinued">Discontinued</option>
                          <option value="Others">Others</option>
                        </Select>
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={12} span={12} md={24} sm={24}>
                      <FormItem
                        label="Remarks"
                        name="remarks"
                        rules={[
                          {
                            required: false,
                            message: 'Enter remarks'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input placeholder="Enter remarks" disabled={!isEdit} />
                      </FormItem>
                    </Col>
                  </Row>
                </Card>
              ) : (
                ''
              )
            }
            <br />
          </>
        </Card>
        <br />
        <FormItem className="text-center">
          <Space>
            {samplings?.test_request_form?.id ? (
              <>
                <CustomButton
                  type="primary"
                  ghost
                  loading={loading}
                  onClick={() => history.back()}
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Update
                </CustomButton>
              </>
            ) : (
              <>
                <CustomButton
                  type="primary"
                  ghost
                  loading={loading}
                  onClick={() => history.back()}
                >
                  Cancel
                </CustomButton>
                {truckShow ? (
                  <Image
                    src={truck}
                    preview={false}
                    style={{ width: '100px !important', height: '100px' }}
                  />
                ) : (
                  <CustomButton
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                  >
                    Create
                  </CustomButton>
                )}
              </>
            )}
          </Space>
        </FormItem>
      </Form>
    </Layout>
  );
};
export default TestRequestForm;
