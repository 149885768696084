import React from 'react';
import Layout from '@/common/Layout';
import DamageReportPage from './components/DamageReportPage';

const DamageReport = () => {
  return (
    <>
      <Layout sider={false}>
        <DamageReportPage />
      </Layout>
    </>
  );
};

export default DamageReport;
