import React from 'react';
import Layout from '@/common/Layout';
import BackendMRNPage from './components/backendMRNPage';

const backendMRN = () => {
  return (
    <>
      <Layout sider={false}>
        <BackendMRNPage />
      </Layout>
    </>
  );
};

export default backendMRN;
