import React, { useState } from 'react';
import { Row, Form, Input, Col, Card } from 'antd';
import styles from '@/common/Layout/index.module.less';
import { Message, Icon } from 'semantic-ui-react';
import tataPro from '../../images/logo.svg';
import truck_img from '../../images/login-icon.svg';
import FormItem from 'common/FormItem';
import CustomButton from 'common/CustomButton';
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
// import { useLocation } from 'react-router-dom';

// const { Link } = Typography;

const ChangePasswordPage = ({ loading, onSubmit }) => {
  const [errorMsg] = useState('');
  const [form] = Form.useForm();
  const [data, setData] = useState();
  const [uCletter, setUCletter] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);

  const handleOnChange = (e) => {
    setData(e.target.value);
    if (
      /[A-Z][a-z]/.test(e.target.value) ||
      /[a-z][A-Z]/.test(e.target.value)
    ) {
      setUCletter(true);
    } else {
      setUCletter(false);
    }

    if (
      /[\d][!@#$%^&*]/.test(e.target.value) ||
      /[!@#$%^&*][\d]/.test(e.target.value)
    ) {
      setSpecialChar(true);
    } else {
      setSpecialChar(false);
    }
  };
  // const searchParam = useLocation().search;
  // const searchParams = new URLSearchParams(searchParam);

  const onFinish = (values) => {
    onSubmit(values);
  };
  return (
    <>
      <Row className={styles.mainContainerStyle}>
        <Col xs={2} xl={12}>
          <img className={`${styles.left_logo} mt-5 ml-5 mb-5`} src={tataPro} />

          <img className={`${styles.main_img}mt-5 mb-5`} src={truck_img} />
        </Col>
        <Col xs={22} xl={12}>
          <div>
            <h1
              style={{
                marginTop: '20%',
                marginLeft: '20%',
                fontWeight: 'Bold',
                fontFamily: 'Source Sans Pro'
              }}
            >
              Change Password
            </h1>
            {/* <h4
              style={{
                marginLeft: '20%',
                fontFamily: 'Source Sans Pro',
                textJustify: 'inter-word',
                marginRight: '20%'
              }}
            >
              Manage all receipts, stock movements, QC and dispensing processes
            </h4> */}
          </div>
          {/* <AuthWrapper> */}
          <div className={` ${styles.right_outer_container}`}>
            <Form
              form={form}
              layout="vertical"
              className={`ant-form ant-form-vertical mt-4 ${styles.form_width}`}
              onFinish={onFinish}
            >
              <FormItem
                label="Employee Code"
                name="user_id"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
              >
                <Input placeholder="Enter Employee/User ID" />
              </FormItem>
              <FormItem
                label="Old Password"
                name="current_password"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
              >
                <Input placeholder="Enter old password" />
              </FormItem>
              <FormItem
                label="New Password"
                name="new_password"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  },
                  {
                    pattern: new RegExp(
                      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/
                    )
                    // message:
                    //   'Min 8 characters,with at least a symbol, upper and lower case letters and a number'
                  }
                ]}
              >
                <Input.Password
                  placeholder="Enter new password"
                  onChange={(e) => handleOnChange(e)}
                />
              </FormItem>

              {/* <br /> */}
              <Card
                style={{
                  borderColor:
                    data?.length >= 6 && uCletter && specialChar
                      ? 'green'
                      : 'red'
                }}
              >
                <p style={{ fontSize: '14px', fontWeight: 'bold' }}>
                  Your Password Must have:
                </p>
                <span style={{ fontSize: '13px' }}>
                  {data?.length >= 6 ? (
                    <CheckCircleOutlined
                      style={{ color: 'green', marginRight: '6px' }}
                    />
                  ) : (
                    <CloseCircleOutlined
                      style={{ color: 'red', marginRight: '6px' }}
                    />
                  )}{' '}
                  Passwords must consist of at least 6 characters & users are
                  prohibited from using blank passwords..
                </span>
                <br />
                <span style={{ fontSize: '13px' }}>
                  {uCletter ? (
                    <CheckCircleOutlined
                      style={{ color: 'green', marginRight: '6px' }}
                    />
                  ) : (
                    <CloseCircleOutlined
                      style={{ color: 'red', marginRight: '6px' }}
                    />
                  )}
                  Passwords must include a combination of letters (Uppercase &
                  Lowercase),
                </span>
                <br />
                <span style={{ fontSize: '13px' }}>
                  {specialChar ? (
                    <CheckCircleOutlined
                      style={{ color: 'green', marginRight: '6px' }}
                    />
                  ) : (
                    <CloseCircleOutlined
                      style={{ color: 'red', marginRight: '6px' }}
                    />
                  )}
                  Passwords must include a combination of Numbers, and Special
                  Characters (!, @, #, $, etc.)
                </span>
                <br />
                <br />
                Terms & Conditions: <br />
                <span style={{ fontSize: '13px' }}>
                  1.Passwords will expire within 90 days from the date of
                  activation.
                  <br />
                  2. Users must avoid reusing their last three passwords under
                  any circumstances as applicable.
                  <br />
                  3. After three unsuccessful login attempts, the account will
                  be locked until reactivated by the system administrator.
                  <br />
                </span>
              </Card>
              <br />
              <FormItem
                label="Confirm Password"
                name="confirm_password"
                dependencies={['new_password']}
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('new_password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          'The two passwords that you entered do not match!'
                        )
                      );
                    }
                  })
                ]}
              >
                <Input.Password placeholder="Enter password" />
              </FormItem>
              {/* <Link
                href="/login"
                className="d-block text-right mb-4 sfprotext-medium font-12"
              >
                Login
              </Link> */}
              <FormItem>
                <CustomButton
                  block
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Change Password
                </CustomButton>
              </FormItem>
            </Form>
          </div>
        </Col>
      </Row>
      {/*  <div className={styles.bakground_img}>
        <div className={styles.left_screen}>
          <Row className={`mt-5 ml-5 mb-5`}></Row>
          <Row className={`mt-5 ml-5 mb-5`}></Row>
        </div>
        <div className={styles.right_screen}></div>
      </div> */}
      <div>
        {errorMsg && (
          <Message negative>
            <Icon name="warning" />
            {errorMsg}
          </Message>
        )}
      </div>
    </>
  );
};

export default ChangePasswordPage;
