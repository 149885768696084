import React from 'react';
import Layout from '@/common/Layout';
import ReturnRequestPage from './components/returnRequestPage';

const ReturnRequest = () => {
  return (
    <>
      <Layout sider={false}>
        <ReturnRequestPage />
      </Layout>
    </>
  );
};

export default ReturnRequest;
