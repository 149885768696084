import React, { useState, useEffect } from 'react';
import Layout from '@/common/Layout';
import {
  Row,
  Col,
  Form,
  Card,
  // Select,
  Input,
  // DatePicker,
  Space,
  message,
  Image
} from 'antd';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import { createlog, getAllLogBooksdetails } from '../../redux/slice';
import { useDispatch } from 'react-redux';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import truck from '@/features/generateMrn/components/images/truck-delivery-done.gif';
import {
  MinusCircleFilled,
  MinusCircleOutlined,
  PlusCircleFilled,
  PlusCircleOutlined
} from '@ant-design/icons';
import { getLoggedInUser } from 'utilities/helpers';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
// const { TextArea } = Input;

const CreateLog = ({ loading }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  // const { batchlogs } = useSelector((state) => state.logs);

  const user = getLoggedInUser();
  const searchParam = useLocation().search;
  const searchParams = new URLSearchParams(searchParam);

  const [truckShow, setTruckShow] = useState(false);
  // const [blendStrength, setBlendStrength] = useState();
  const [alllogs, setAlllogs] = useState([]);
  const [blendDetails, setBlendDetails] = useState({});
  const [logid, setLogid] = useState('');
  const [wipDetails, setWipDetails] = useState([
    {
      id: '',
      wip_batch: '',
      wip_strength: '',
      wip_bmr: '',
      wip_size: '',
      blend_alloc_wip: '',
      wip_size_caps: ''
    }
  ]);
  const [packageDetails, setPackageDetails] = useState([
    {
      id: '',
      wip_id: '',
      packing_fg_batch: '',
      fg_count: '',
      fg_bpr: '',
      fg_alloc_capsules: '',
      blend_alloc_fg: '',
      wip_alloc_fg: ''
    }
  ]);

  const handleOnClick = () => {
    let list = [...alllogs];
    list?.[0]?.wip_batch_nums.push({
      wip_batch: '',
      wip_strength: '',
      wip_bmr: '',
      wip_size: '',
      blend_alloc_wip: '',
      wip_size_caps: ''
    });
    setAlllogs(list);
  };

  const handleMinus = (index) => {
    let list = [...alllogs];
    list[0]?.wip_batch_nums.splice(index, 1);

    setAlllogs(list);
  };
  const handleAddSub = (index) => {
    let list = [...alllogs];

    list?.[0]?.wip_batch_nums[index].fg_batch_nums.push({
      packing_fg_batch: '',
      fg_count: '',
      fg_bpr: '',
      fg_alloc_capsules: '',
      blend_alloc_fg: '',
      wip_alloc_fg: ''
    });
    setAlllogs(list);
  };

  const handleMinusSub = (index, i) => {
    let list = [...alllogs];
    list[0]?.wip_batch_nums[index].fg_batch_nums.splice(i, 1);

    setAlllogs(list);
  };
  const handleChange = (e, type) => {
    let list = blendDetails;
    blendDetails[type] = e.target.value;
    setBlendDetails(list);
  };

  const handleOnChange = (e, type) => {
    console.log(alllogs?.[0]?.blend_size);
    let list = [...wipDetails];
    list[0][type] = e.target.value;
    list[0].id = searchParams.get('id');
    if (type === 'wip_size') {
      list[0].blend_alloc_wip = parseInt(
        (parseFloat(e.target.value) / parseFloat(alllogs?.[0]?.blend_size)) *
          100
      );
    }
    setWipDetails(list);
    console.log(wipDetails);
  };

  const handleOnChangeSub = (e, index, wipid, type) => {
    console.log(wipid);
    let list = [...packageDetails];
    list[0][type] = e.target.value;
    list[0].wip_id = wipid;
    list[0].id = searchParams.get('id');
    if (type == 'fg_alloc_capsules') {
      list[0].blend_alloc_fg = parseInt(
        (parseFloat(e.target.value) /
          parseFloat(alllogs[0]?.wip_batch_nums[index].wip_size_caps)) *
          parseInt(alllogs[0]?.wip_batch_nums[index].blend_alloc_wip)
      );
      list[0].wip_alloc_fg = parseInt(
        (parseFloat(e.target.value) /
          parseFloat(alllogs[0]?.wip_batch_nums[index].wip_size_caps)) *
          100
      );
    }
    setPackageDetails(list);
  };

  useEffect(() => {
    handleGetAllLogDetails();
  }, [logid]);

  const handleGetAllLogDetails = () => {
    const payload = { batch_log_id: searchParams.get('id') };
    searchParams.get('id')
      ? dispatch(getAllLogBooksdetails(payload)).then((response) => {
          if (response?.payload?.success) {
            setAlllogs(response?.payload?.data);
          }
        })
      : '';
  };
  console.log(packageDetails);
  const onFinish = () => {
    history.push('/log-book-list');
  };

  const handleCreate = (type) => {
    setTruckShow(true);
    console.log(wipDetails);

    const payload =
      type == 'main'
        ? blendDetails
        : type == 'WIP'
        ? wipDetails[0]
        : type == 'PACK'
        ? packageDetails[0]
        : '';
    dispatch(createlog(payload)).then((response) => {
      if (response?.payload?.success) {
        setTruckShow(false);
        message.success(response?.payload?.message);
        // handleGetAllLogDetails();
        searchParams.get('id')
          ? history.push(`/create-log?id=${searchParams.get('id')}`)
          : history.push(`/create-log?id=${response?.payload?.data?.id}`);
        setLogid(
          searchParams.get('id')
            ? searchParams.get('id')
            : response?.payload?.data?.id
        );
        handleGetAllLogDetails();
      }
    });
  };
  console.log(alllogs);
  let logs = alllogs?.[0]?.wip_batch_nums?.length
    ? alllogs?.[0]?.wip_batch_nums
    : alllogs?.[0]?.wip_batch_nums.concat(wipDetails);
  return (
    <Layout sider={false}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
      >
        <Card title="BATCH NUMBERING LOG BOOK" className={styles.CustomPanel}>
          <Row gutter={24}>
            <Col xs={24} xl={6} span={6} md={24} sm={24}>
              <FormItem
                label="Blend Batch #"
                // name="blend_batch"
                rules={[
                  {
                    required: true,
                    message: 'Select Blend Batch'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter Blend Batch"
                  disabled={alllogs?.[0]?.blend_batch ? true : false}
                  onChange={(e) => handleChange(e, 'blend_batch')}
                  value={alllogs?.[0]?.blend_batch}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={6} span={6} md={24} sm={24}>
              <FormItem
                label="Blend Size"
                // name="blend_size"
                rules={[
                  {
                    required: true,
                    message: 'Enter blend size'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter Blend Size"
                  // onChange={(e) => handleChangeBlend(e)}
                  onChange={(e) => handleChange(e, 'blend_size')}
                  disabled={alllogs?.[0]?.blend_size ? true : false}
                  value={alllogs?.[0]?.blend_size}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={6} span={6} md={24} sm={24}>
              <FormItem
                label="BMR #"
                // name="bmr"
                rules={[
                  {
                    required: true,
                    message: 'Enter BMR'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter BMR"
                  disabled={alllogs?.[0]?.blend_bmr ? true : false}
                  onChange={(e) => handleChange(e, 'blend_bmr')}
                  value={alllogs?.[0]?.blend_bmr}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={6} span={6} md={24} sm={24}>
              <FormItem
                label="Alloted By"
                // name="alloted_by"
                rules={[
                  {
                    required: true,
                    message: 'Enter Alloted By'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter Alloted By"
                  disabled
                  value={
                    alllogs?.[0]?.alloted_by
                      ? alllogs?.[0]?.alloted_by
                      : `${user?.user?.employee} ${' '}(${moment().format(
                          'DD MMM YYYY'
                        )})`
                  }
                />
              </FormItem>
            </Col>
          </Row>
          {!alllogs?.[0]?.blend_batch ? (
            <FormItem className="text-center">
              <Space>
                {truckShow ? (
                  <Image
                    src={truck}
                    preview={false}
                    style={{ width: '100px !important', height: '100px' }}
                  />
                ) : (
                  <CustomButton
                    type="primary"
                    onClick={() => handleCreate('main')}
                  >
                    Save
                  </CustomButton>
                )}
              </Space>
            </FormItem>
          ) : (
            ''
          )}

          {logs?.map((item, index) => {
            let sublogs = item?.fg_batch_nums?.length
              ? item?.fg_batch_nums
              : item?.fg_batch_nums?.concat({
                  packing_fg_batch: '',
                  fg_count: '',
                  fg_bpr: '',
                  fg_alloc_capsules: '',
                  blend_alloc_fg: '',
                  wip_alloc_fg: ''
                });
            return (
              <Space size={6} key={index}>
                <Card
                  title="Compression/Coating/Capsulation Batch Details"
                  className={styles.CustomPanel}
                  key={index}
                  extra={
                    <Space>
                      {item?.wip_batch !== '' && logs?.length - 1 == index ? (
                        <PlusCircleOutlined
                          style={{
                            fontSize: '24px',
                            color: '#c91761'
                          }}
                          onClick={handleOnClick}
                        />
                      ) : (
                        ''
                      )}
                      {item?.wip_batch == '' ? (
                        <MinusCircleOutlined
                          style={{
                            fontSize: '24px',
                            color: '#c91761'
                          }}
                          onClick={() => handleMinus(index)}
                        />
                      ) : (
                        ''
                      )}
                    </Space>
                  }
                >
                  <Row gutter={24}>
                    <Col xs={24} xl={8} span={8} md={24} sm={24}>
                      <FormItem
                        label="WIP/Strength Batch #"
                        rules={[
                          {
                            required: true,
                            message: 'Enter WIP'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter WIP/Strength Batch"
                          onChange={(e) => handleOnChange(e, 'wip_batch')}
                          value={
                            alllogs?.[0]?.wip_batch_nums?.[index]?.wip_batch ||
                            wipDetails?.wip_batch
                          }
                          disabled={
                            alllogs?.[0]?.wip_batch_nums?.[index]?.wip_batch
                              ? true
                              : false
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={8} span={8} md={24} sm={24}>
                      <FormItem
                        label="Strength"
                        rules={[
                          {
                            required: true,
                            message: 'Enter Strength'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter Strength"
                          onChange={(e) => handleOnChange(e, 'wip_strength')}
                          value={
                            alllogs?.[0]?.wip_batch_nums?.[index]
                              ?.wip_strength || wipDetails?.wip_strength
                          }
                          disabled={
                            alllogs?.[0]?.wip_batch_nums?.[index]?.wip_strength
                              ? true
                              : false
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={8} span={8} md={24} sm={24}>
                      <FormItem
                        label="BMR #"
                        rules={[
                          {
                            required: true,
                            message: 'Enter BMR #'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter BMR #"
                          onChange={(e) => handleOnChange(e, 'wip_bmr')}
                          value={
                            alllogs?.[0]?.wip_batch_nums?.[index]?.wip_bmr ||
                            wipDetails?.wip_bmr
                          }
                          disabled={
                            alllogs?.[0]?.wip_batch_nums?.[index]?.wip_bmr
                              ? true
                              : false
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={8} span={8} md={24} sm={24}>
                      <FormItem
                        label="WIP Size(KG)"
                        rules={[
                          {
                            required: true,
                            message: 'Enter WIP Size'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter WIP Size"
                          onChange={(e) => handleOnChange(e, 'wip_size')}
                          value={
                            alllogs?.[0]?.wip_batch_nums?.[index]?.wip_size ||
                            wipDetails?.wip_size
                          }
                          disabled={
                            alllogs?.[0]?.wip_batch_nums?.[index]?.wip_size
                              ? true
                              : false
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={8} span={8} md={24} sm={24}>
                      <FormItem
                        label="Blend Allocation to WIP %"
                        rules={[
                          {
                            required: true,
                            message: 'Enter Blend Allocation'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter Blend Allocation to WIP"
                          value={
                            alllogs?.[0]?.wip_batch_nums?.[index]
                              ?.blend_alloc_wip
                              ? alllogs?.[0]?.wip_batch_nums?.[index]
                                  ?.blend_alloc_wip
                              : wipDetails[0]?.blend_alloc_wip
                          }
                          disabled
                          onChange={(e) => handleOnChange(e, 'blend_alloc_wip')}
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={8} span={8} md={24} sm={24}>
                      <FormItem
                        label="WIP Size Capsuals"
                        rules={[
                          {
                            required: true,
                            message: 'Enter WIP Size Capsuals'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter WIP Size Capsuals"
                          onChange={(e) => handleOnChange(e, 'wip_size_caps')}
                          value={
                            alllogs?.[0]?.wip_batch_nums?.[index]
                              ?.wip_size_caps || wipDetails?.wip_size_caps
                          }
                          disabled={
                            alllogs?.[0]?.wip_batch_nums?.[index]?.wip_size_caps
                              ? true
                              : false
                          }
                        />
                      </FormItem>
                    </Col>
                  </Row>
                  {!alllogs?.[0]?.wip_batch_nums?.[index]?.wip_batch ? (
                    <FormItem className="text-center">
                      <Space>
                        {truckShow ? (
                          <Image
                            src={truck}
                            preview={false}
                            style={{
                              width: '100px !important',
                              height: '100px'
                            }}
                          />
                        ) : (
                          <CustomButton
                            type="primary"
                            onClick={() => handleCreate('WIP')}
                          >
                            Save
                          </CustomButton>
                        )}
                      </Space>
                    </FormItem>
                  ) : (
                    ''
                  )}
                  {sublogs?.map((packdata, i) => {
                    return (
                      <Space key={i} size={6}>
                        <Card
                          title="Packing/FG Details"
                          className={styles.CustomPanelFG}
                          key={i}
                          extra={
                            <Space>
                              {packdata?.packing_fg_batch !== '' &&
                              sublogs?.length - 1 == i ? (
                                <PlusCircleFilled
                                  onClick={() => handleAddSub(index)}
                                  style={{
                                    fontSize: '20px',
                                    color: '#c91761'
                                  }}
                                />
                              ) : (
                                ''
                              )}
                              {packdata?.packing_fg_batch == '' ? (
                                <MinusCircleFilled
                                  onClick={() => handleMinusSub(index, i)}
                                  style={{
                                    fontSize: '20px',
                                    color: '#c91761'
                                  }}
                                />
                              ) : (
                                ''
                              )}
                            </Space>
                          }
                        >
                          <Row gutter={24}>
                            <Col xs={24} xl={8} span={8} md={24} sm={24}>
                              <FormItem
                                label="Packing/FG Batch #"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Enter Packing/FG Batch #'
                                  }
                                ]}
                                className={styles.customLable}
                              >
                                <Input
                                  placeholder="Enter Packing/FG Batch #"
                                  onChange={(e) =>
                                    handleOnChangeSub(
                                      e,
                                      index,
                                      logs?.[index].id,
                                      'packing_fg_batch'
                                    )
                                  }
                                  value={
                                    packdata?.packing_fg_batch ||
                                    packageDetails?.packing_fg_batch
                                  }
                                  disabled={
                                    packdata?.packing_fg_batch ? true : false
                                  }
                                />
                              </FormItem>
                            </Col>
                            <Col xs={24} xl={8} span={8} md={24} sm={24}>
                              <FormItem
                                label="Count"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Enter Count'
                                  }
                                ]}
                                className={styles.customLable}
                              >
                                <Input
                                  placeholder="Enter Count"
                                  onChange={(e) =>
                                    handleOnChangeSub(
                                      e,
                                      index,
                                      logs?.[index].id,
                                      'fg_count'
                                    )
                                  }
                                  value={
                                    packdata?.fg_count ||
                                    packageDetails?.fg_count
                                  }
                                  disabled={packdata?.fg_count ? true : false}
                                />
                              </FormItem>
                            </Col>
                            <Col xs={24} xl={8} span={8} md={24} sm={24}>
                              <FormItem
                                label="BPR #"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Enter BPR #'
                                  }
                                ]}
                                className={styles.customLable}
                              >
                                <Input
                                  placeholder="Enter BPR #"
                                  onChange={(e) =>
                                    handleOnChangeSub(
                                      e,
                                      index,
                                      logs?.[index].id,
                                      'fg_bpr'
                                    )
                                  }
                                  value={
                                    packdata?.fg_bpr || packageDetails?.fg_bpr
                                  }
                                  disabled={packdata?.fg_bpr ? true : false}
                                />
                              </FormItem>
                            </Col>
                            <Col xs={24} xl={8} span={8} md={24} sm={24}>
                              <FormItem
                                label="FG Allocated Capsuals"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Enter FG Allocated Capsuals'
                                  }
                                ]}
                                className={styles.customLable}
                              >
                                <Input
                                  placeholder="Enter FG Allocated Capsuals"
                                  onChange={(e) =>
                                    handleOnChangeSub(
                                      e,
                                      index,
                                      logs?.[index].id,
                                      'fg_alloc_capsules'
                                    )
                                  }
                                  value={
                                    packdata?.fg_alloc_capsules ||
                                    packageDetails?.fg_alloc_capsules
                                  }
                                  disabled={
                                    packdata?.fg_alloc_capsules ? true : false
                                  }
                                />
                              </FormItem>
                            </Col>
                            <Col xs={24} xl={8} span={8} md={24} sm={24}>
                              <FormItem
                                label="Blend Allocation to FG %"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Enter Blend Allocation to FG %'
                                  }
                                ]}
                                className={styles.customLable}
                              >
                                <Input
                                  placeholder="Enter Blend Allocation to FG %"
                                  value={
                                    packdata?.blend_alloc_fg ||
                                    packageDetails?.blend_alloc_fg
                                  }
                                  disabled
                                  onChange={(e) =>
                                    handleOnChangeSub(
                                      e,
                                      index,
                                      logs?.[index].id,
                                      'blend_alloc_fg'
                                    )
                                  }
                                />
                              </FormItem>
                            </Col>
                            <Col xs={24} xl={8} span={8} md={24} sm={24}>
                              <FormItem
                                label="WIP Allocation to FG %"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Enter WIP Allocation to FG %'
                                  }
                                ]}
                                className={styles.customLable}
                              >
                                <Input
                                  placeholder="Enter WIP Allocation to FG %"
                                  // value={packdata?.wip_alloc_fg}
                                  value={
                                    packdata?.wip_alloc_fg ||
                                    packageDetails?.wip_alloc_fg
                                  }
                                  disabled
                                  onChange={(e) =>
                                    handleOnChangeSub(
                                      e,
                                      index,
                                      logs?.[index].id,
                                      'wip_alloc_fg'
                                    )
                                  }
                                />
                              </FormItem>
                            </Col>
                          </Row>
                          {!packdata?.packing_fg_batch ? (
                            <FormItem className="text-center">
                              <Space>
                                {truckShow ? (
                                  <Image
                                    src={truck}
                                    preview={false}
                                    style={{
                                      width: '100px !important',
                                      height: '100px'
                                    }}
                                  />
                                ) : (
                                  <CustomButton
                                    type="primary"
                                    onClick={() => handleCreate('PACK')}
                                  >
                                    Save
                                  </CustomButton>
                                )}
                              </Space>
                            </FormItem>
                          ) : (
                            ''
                          )}
                        </Card>
                      </Space>
                    );
                  })}
                </Card>
              </Space>
            );
          })}
        </Card>
        <br />
        <FormItem className="text-center">
          <Space>
            <CustomButton htmlType="button" onClick={() => history.back()}>
              Cancel
            </CustomButton>
            {truckShow ? (
              <Image
                src={truck}
                preview={false}
                style={{ width: '100px !important', height: '100px' }}
              />
            ) : (
              <CustomButton type="primary" htmlType="Save" loading={loading}>
                Submit
              </CustomButton>
            )}
          </Space>
        </FormItem>
      </Form>
    </Layout>
  );
};
export default CreateLog;
