import React, { useState } from 'react';
import {
  Row,
  Col,
  Form,
  Card,
  Input,
  Space,
  Select,
  message,
  Image,
  DatePicker
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import truck from '@/features/qa/components/images/truck-delivery-done.gif';
import Layout from '@/common/Layout';
import { v4 as uuidv4 } from 'uuid';
import {
  useGetUacrOfSamplingOrDbarAreaMastersQuery,
  useGetUacrOfSamplingOrDbarCleaningAccessoriesMastersQuery,
  useInitiateVerificationMutation,
  useCreateUacrOfSamplingOrDbarMutation,
  useLazyGetLafProductOrMaterialNameAndOtherInformationQuery
} from '../../redux/apiSlice';
import moment from 'moment';
import CustomModal from '../../../../common/CustomModal';

import LocalStorage from 'utilities/localStorage';
const user = LocalStorage.getItem('Gravity_user');
console.log('user', user?.user?.employee);

const createProductNewPage = () => {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  // #region Initial states - Start
  const [truckShow] = useState(false);
  const [search, setSearch] = useState('');
  const [typeOfCleaning] = useState([
    {
      id: 1,
      label: 'TYPE A',
      value: 'TYPE A'
    },
    {
      id: 2,
      label: 'TYPE C',
      value: 'TYPE C'
    }
  ]);
  const [verificationButtonDisabled, setVerificationButtonDisabled] =
    useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [isModalSubmitDisabled, setIsModalSubmitDisabled] = useState(false);
  const [uniqueId] = useState(uuidv4());
  const [messageApi, contextHolder] = message.useMessage();
  const [isModal, setIsModal] = useState(false);

  // #endregion Initial states - End

  // #region Showing success or error message - Start
  const handleShowMessage = (messageType, messageText) => {
    messageApi.open({
      type: messageType,
      content: messageText
    });
  };
  // #endregion Showing success or error message - End

  // #region Initiate Verification Modal functionality - Start
  const [initiateVerification] = useInitiateVerificationMutation();
  const handleModel = async () => {
    await form.validateFields();
    setIsModal(true);
  };
  const handleClose = () => {
    setIsModal(false);
  };

  const onFinishModal = async () => {
    try {
      setIsModalSubmitDisabled(true);
      const values = await form2.validateFields();
      form.setFieldsValue({
        verified_by: values.email,
        verified_by_and_date_display: `${values.email} (${moment(
          new Date()
        ).format('DD-MM-YY')})`,
        remarks: values.remarks2,
        common_unique_id: uniqueId
      });
      const response = await initiateVerification({
        ...values,
        common_unique_id: uniqueId,
        form: 'usage_cleaning'
      });
      if (response?.data?.success === true) {
        form2.resetFields();
        setIsModal(false);
        setVerificationButtonDisabled(true);
        setSubmitButtonDisabled(false);
      } else {
        // console.log(response);
        handleShowMessage('error', response?.error?.data?.message);
        setIsModalSubmitDisabled(false);
      }
    } catch (info) {
      console.log('Validate Failed:', info);
    }
  };
  // #endregion Initiate Verification Modal functionality - Ends

  // #region Fetching Usage and Cleaning Record of Sampling or Dispensing booth and room Area Masters - Start
  let {
    data: uacrOfSamplingOrDbarAreaMasters,
    refetch: uacrOfSamplingOrDbarAreaMastersRefetch
  } = useGetUacrOfSamplingOrDbarAreaMastersQuery({
    search
  });
  // #endregion Fetching Usage and Cleaning Record of Sampling or Dispensing booth and room Area Masters - End

  // #region Fetching Usage and Cleaning Record of Sampling or Dispensing booth and room Cleaning Accessories Masters - Start
  let { data: cleaningAccessoryMasters } =
    useGetUacrOfSamplingOrDbarCleaningAccessoriesMastersQuery({
      limit: 1000
    });
  // #endregion Fetching Usage and Cleaning Record of Sampling or Dispensing booth and room Cleaning Accessories Masters - End

  // #region Fetching Laf, Product Or Material Name And Other Information - Start
  let [triggerGetLafProductOrMaterialNameAndOtherInformationQuery] =
    useLazyGetLafProductOrMaterialNameAndOtherInformationQuery();
  // #endregion Fetching Laf, Product Or Material Name And Other Information - End

  // #region Creating Usage and Cleaning Record of Sampling or Dispensing Booth and Room logbook - Start
  let [createUacrOfSamplingOrDbar] = useCreateUacrOfSamplingOrDbarMutation();

  const onFinish = async (values) => {
    setSubmitButtonDisabled(true);

    const values2 = { ...values, common_unique_id: uniqueId };
    values2.laf_date = moment(values.laf_date).format();
    values2.laf_time = moment(values.laf_time).format('YYYY-MM-DD HH:mm:ss');
    values2.laf_off_time = values.laf_off_time
      ? moment(values.laf_off_time).format('YYYY-MM-DD HH:mm:ss')
      : '';
    values2.laf_time = moment(values.laf_time).format('YYYY-MM-DD HH:mm:ss');
    values2.laf_off_time = values.laf_off_time
      ? moment(values.laf_off_time).format('YYYY-MM-DD HH:mm:ss')
      : '';
    console.log('Form 1 values', { ...values, common_unique_id: uniqueId });
    const response = await createUacrOfSamplingOrDbar({
      ...values2
    });
    // console.log(response);
    if (response?.data?.success === true) {
      handleShowMessage('success', response?.data?.message);
      setTimeout(() => history.push('/uacr-of-sampling-or-dbar-list'), 1500);
    } else {
      handleShowMessage('error', response?.error?.data?.message);
      setSubmitButtonDisabled(false);
    }
  };
  // #endregion Creating Usage and Cleaning Record of Sampling or Dispensing Booth and Room logbook - End

  // #region All onChange events handler - Start
  const onChange = async (e, name) => {
    if (name === 'area_name') {
      form.resetFields([
        'laf_date',
        'laf_time',
        'laf_product_name',
        'laf_product_batch_no',
        'activity_start_time_user_id_date',
        'activity_start_time_date',
        'activity_start_time_user_id',
        'activity_end_time_user_id_date',
        'activity_end_time_date',
        'activity_end_time_user_id',
        'laf_off_time'
      ]);
      const areaNameArray = uacrOfSamplingOrDbarAreaMasters?.data?.filter(
        (a) => a.id == e
      );
      try {
        const data =
          await triggerGetLafProductOrMaterialNameAndOtherInformationQuery({
            area_name: areaNameArray[0]?.area
          }).unwrap();

        if (data.success === true) {
          form.setFieldsValue({
            laf_date: moment(data?.data?.date).format('DD-MM-YYYY'),
            laf_time:
              data?.data?.laf_switched_on === 'on'
                ? moment(data.data.laf_time_date).format('DD-MM-YYYY HH:mm:ss')
                : '',
            laf_product_name: data?.data?.product_name,
            laf_product_batch_no: data?.data?.batch_number,
            activity_start_time_user_id_date: data?.newDispensary?.[0]
              ?.createdAt
              ? `${moment(data?.newDispensary?.[0]?.createdAt).format(
                  'HH:mm'
                )} (${data?.newDispensary?.[0]?.user_id}/${moment(
                  data?.newDispensary?.[0]?.createdAt
                ).format('DD-MM-YYYY')})`
              : '',
            activity_start_time_user_id: data?.newDispensary?.[0]?.user_id,
            activity_end_time_user_id_date: data?.newDispensary?.[
              data?.newDispensary.length - 1
            ]?.createdAt
              ? `${moment(
                  data?.newDispensary?.[data?.newDispensary.length - 1]
                    ?.createdAt
                ).format('HH:mm')} (${
                  data?.newDispensary?.[data?.newDispensary.length - 1]?.user_id
                }/${moment(
                  data?.newDispensary?.[data?.newDispensary.length - 1]
                    ?.createdAt
                ).format('DD-MM-YYYY')})`
              : '',
            activity_end_time_user_id: data?.newDispensary?.[
              data?.newDispensary.length - 1
            ]?.user_id
              ? data?.newDispensary?.[data?.newDispensary.length - 1]?.user_id
              : '',
            laf_off_time:
              data?.newDispensary.length > 0 &&
              data?.newDispensary?.[data.newDispensary.length - 1]?.createdAt
                ? `${moment(
                    data.newDispensary[data.newDispensary.length - 1]?.createdAt
                  ).format('DD-MM-YYYY HH:mm:ss')}`
                : '',
            activity_start_time_date: data?.newDispensary?.[0]?.createdAt
              ? data?.newDispensary?.[0]?.createdAt
              : '',
            activity_end_time_date: data?.newDispensary?.[
              data?.newDispensary.length - 1
            ]?.createdAt
              ? data?.newDispensary?.[data?.newDispensary.length - 1]?.createdAt
              : ''
          });
        } else if (data.success === false) {
          handleShowMessage('warning', data?.message);
        }
      } catch (err) {
        console.log(err);
        handleShowMessage('error', err?.message);
      }
    }
  };
  // #endregion All onChange events handler - End
  // #region All onSearch events handler - Start
  const onSearch = async (e, name) => {
    if (e.length === 0 || e.length >= 3) {
      if (name === 'area_name') {
        setSearch(e);
        uacrOfSamplingOrDbarAreaMastersRefetch();
      }
    }
  };
  // #endregion All onSearch events handler - End

  return (
    <Space>
      {contextHolder}
      <Layout sider={false}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            done_by_user_id: user?.user?.employee,
            done_by_user_id_and_date_display: `${
              user?.user?.employee
            } (${moment(new Date()).format('DD-MM-YY')})`
          }}
        >
          <Card
            title={
              'USAGE AND CLEANING RECORD OF SAMPLING / DISPENSING BOOTH AND ROOM'
            }
            className={styles.CustomPanel}
          >
            <Row gutter={24}>
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label="Area"
                  name="samplingDispensingBoothAreaMasterId"
                  rules={[
                    {
                      required: true,
                      message: 'Select Area'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Select
                    placeholder="Select Area"
                    showSearch
                    optionFilterProp="children"
                    onSearch={(e) => onSearch(e, 'area_name')}
                    onChange={(e) => onChange(e, 'area_name')}
                    disabled={verificationButtonDisabled}
                  >
                    {uacrOfSamplingOrDbarAreaMasters?.data?.map(
                      (item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.area}
                          </option>
                        );
                      }
                    )}
                  </Select>
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Date"
                  name="laf_date"
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label="LAF On Time"
                  name="laf_time"
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Product / Material Name"
                  name="laf_product_name"
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Product Batch No. / In-House Batch No."
                  name="laf_product_batch_no"
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Activity Start Time User Id & Date"
                  name="activity_start_time_user_id_date"
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24} hidden>
                <FormItem
                  label="Activity Start Time User Id"
                  name="activity_start_time_user_id"
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24} hidden>
                <FormItem
                  label="Activity Start Time Date"
                  name="activity_start_time_date"
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Activity End Time User Id & Date"
                  name="activity_end_time_user_id_date"
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24} hidden>
                <FormItem
                  label="Activity Start Time User Id"
                  name="activity_end_time_user_id"
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24} hidden>
                <FormItem
                  label="Activity Start Time Date"
                  name="activity_end_time_date"
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="LAF Off Time"
                  name="laf_off_time"
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
            </Row>
          </Card>
          <br />{' '}
          <Card title={'Cleaning Activity'} className={styles.CustomPanel}>
            <Row gutter={24}>
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label="Type of Cleaning"
                  name="type_of_cleaning"
                  rules={[
                    {
                      required: true,
                      message: 'Select Type of Cleaning'
                    }
                  ]}
                  disabled={verificationButtonDisabled}
                  className={styles.customLable}
                >
                  <Select
                    placeholder="Type of Cleaning"
                    showSearch
                    optionFilterProp="children"
                    onSearch={(e) => onSearch(e, 'type_of_cleaning')}
                    onChange={(e) => onChange(e, 'type_of_cleaning')}
                    disabled={verificationButtonDisabled}
                  >
                    {typeOfCleaning?.map((item, index) => {
                      return (
                        <option value={item.value} key={index}>
                          {item.label}
                        </option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Start Date & Time"
                  name="start_date"
                  rules={[
                    {
                      required: true,
                      message: 'Select Start Date'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <DatePicker
                    showTime
                    disabled={verificationButtonDisabled}
                    style={{ width: '100%' }}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="End Date & Time"
                  name="end_date"
                  rules={[
                    {
                      required: true,
                      message: 'Select End Date'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <DatePicker
                    showTime
                    disabled={verificationButtonDisabled}
                    style={{ width: '100%' }}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Cleaning Accessories"
                  name="cleaningAccessoriesMasterId"
                  rules={[
                    {
                      required: true,
                      message: 'Select Cleaning Accessories'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Select
                    mode="multiple"
                    placeholder="Select Cleaning Accessories"
                    optionFilterProp="children"
                    style={{ width: '100%' }}
                    disabled={verificationButtonDisabled}
                  >
                    {cleaningAccessoryMasters?.data?.length > 0
                      ? cleaningAccessoryMasters.data.map((obj, index) => {
                          return (
                            <Select.Option key={index} value={obj.id}>
                              {`${obj.cleaning_accessories} (${obj.cleaning_equip_id})`}
                            </Select.Option>
                          );
                        })
                      : ''}
                  </Select>
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24} hidden>
                <FormItem
                  label="Done By User Id & Date"
                  name="done_by_user_id"
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Done By User Id & Date"
                  name="done_by_user_id_and_date_display"
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24} hidden>
                <FormItem
                  label="Verified By User Id & Date"
                  name="verified_by"
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Verified By User Id & Date"
                  name="verified_by_and_date_display"
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Remarks"
                  name="remarks"
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
            </Row>
          </Card>
          <>
            <br />
            <FormItem className="text-center">
              <Space>
                <CustomButton
                  className={styles.inwardButton}
                  htmlType="button"
                  type="primary"
                  onClick={() => handleModel()}
                  disabled={verificationButtonDisabled}
                >
                  Initiate Verification
                </CustomButton>
                {truckShow ? (
                  <Image
                    src={truck}
                    preview={false}
                    style={{ width: '100px !important', height: '100px' }}
                  />
                ) : (
                  <CustomButton
                    className={styles.inwardButton}
                    htmlType="submit"
                    type="primary"
                    onSubmit={(e) => onFinish(e)}
                    disabled={submitButtonDisabled}
                  >
                    Create
                  </CustomButton>
                )}
                <CustomButton
                  className={styles.inwardButton}
                  type="primary"
                  ghost
                  onClick={() => history.back()}
                >
                  Back
                </CustomButton>
              </Space>
            </FormItem>
          </>
          {isModal ? (
            <CustomModal
              title=""
              visible={isModal}
              width="50%"
              footer={null}
              className={styles.customModal}
              closable={false}
            >
              <div className={`${styles.table_row}`}>
                <Form form={form2} layout="vertical" onFinish={onFinishModal}>
                  <Card
                    title={'Initiate Verification'}
                    className={styles.CustomPanel}
                  >
                    <Row gutter={24}>
                      <Col xs={24} xl={12} span={24} md={24} sm={24}>
                        <FormItem
                          label="Verified By (User id)"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: 'Enter Verifier User Id'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Input placeholder="Enter Verifier User Id" />
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={12} span={24} md={24} sm={24}>
                        <FormItem
                          label="Password"
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: 'Enter Password'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Input.Password placeholder="Enter Password" />
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={12} span={24} md={24} sm={24}>
                        <FormItem
                          label="Remarks"
                          name="remarks2"
                          className={styles.customLable}
                        >
                          <Input.TextArea placeholder="Enter remarks" />
                        </FormItem>
                      </Col>
                    </Row>
                  </Card>

                  <br />
                  <FormItem className="text-center">
                    <Space>
                      <CustomButton
                        className={styles.inwardButton}
                        htmlType="submit"
                        type="primary"
                        size="medium"
                        disabled={isModalSubmitDisabled}
                      >
                        Submit
                      </CustomButton>
                      <CustomButton
                        className={styles.inwardButton}
                        onClick={handleClose}
                        type="primary"
                        ghost
                        size="medium"
                      >
                        Close
                      </CustomButton>
                    </Space>
                  </FormItem>
                </Form>
              </div>
            </CustomModal>
          ) : (
            ''
          )}
        </Form>
      </Layout>
    </Space>
  );
};
export default createProductNewPage;
