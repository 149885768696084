import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  // Collapse,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Select,
  // Radio,
  Space,
  Image,
  message,
  Card
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import truck from '../images/truck-delivery-done.gif';
import { useDispatch, useSelector } from 'react-redux';
import {
  batchRecreate,
  getAllSuppliers,
  getAllMaterialslist
} from '../../redux/slice';
import { history } from 'app/history';
import { useParams } from 'react-router-dom';

const BackendMRNPage = ({ loading }) => {
  const params = useParams();
  const { MaterialsList } = useSelector((state) => state.material);
  console.log(MaterialsList);
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [truckShow, setTruckShow] = useState(false);
  const [code, setCode] = useState('');
  const [searchvendor, setSearchvendor] = useState('');
  const [suppliersdata, setSuppliersdata] = useState([]);
  const [manufacturer, setManufacturer] = useState();
  const [selectVendor, setSelectVendor] = useState('');
  const [search, setSearch] = useState('');
  // const [material, setMaterial] = useState({});

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo.errorFields.length);
    if (errorInfo.errorFields.length > 0) {
      message.error(
        'Please select a response to all the questions in the checklist'
      );
    }
  };
  const onChange = (value) => {
    const material = MaterialsList.filter(
      (MaterialsList) => MaterialsList.id === value
    );
    form.setFieldsValue({ supplier_id: null });
    setCode(material[0]);
    setSuppliersdata(material[0].suppliers);
    setSelectVendor('');
  };

  const onChangeSupplier = (value) => {
    const manufacturerData = suppliersdata.filter(
      (suppliersdata) => suppliersdata.id === value
    );
    setManufacturer(manufacturerData);
  };
  console.log(manufacturer);
  const onSearch = (value) => {
    setSearch(value);
  };

  const onSearchvendor = (value) => {
    setSearchvendor(value);
  };

  useEffect(() => {
    form.setFieldsValue({
      material_name: code?.name,
      // supplier_id: '',
      uom: code?.uom,
      mfg_name: '',
      vendor_status: null
    });
  }, [code]);

  useEffect(() => {
    form.setFieldsValue({
      mfg_name: manufacturer ? manufacturer[0]?.manufacturer_name : '',
      vendor_status: manufacturer ? manufacturer[0]?.vendor_status : ''
    });
  }, [manufacturer]);
  useEffect(() => {
    handleGetAllSuppliers();
  }, [searchvendor]);
  useEffect(() => {
    handleGetAllMaterialsList();
  }, [search, code]);
  console.log(selectVendor);
  const onFinish = (values) => {
    console.log(values);
    values['document_id'] = params.id;
    values['material_status'] = 'inv_ver_approved';

    const payload = {
      invoice_number: values.invoice_no,
      material_id: values.material_id,
      supplier_id: values.supplier_id,
      po_number: values.po_no,
      material_vendor_status: values.vendor_status,
      bacth_details_list: [
        {
          mfg_batch_lot_no: values.mfg_batch_lot_no,
          mfg_date: values.mfg_date,
          mfg_expiry_date: values.mfg_expiry_date,
          no_of_containers: values.no_of_containers,
          in_house_expiry_date: values.in_house_expiry_date,
          total_net_weight: values.available_quantity,
          inhouse_batch_number: values.in_house_batch_number,
          mrn_number: values.mrn_number
        }
      ]
    };
    setTruckShow(true);

    dispatch(batchRecreate(payload)).then(function (response) {
      if (response?.payload?.success) {
        setTimeout(() => {
          message.success(response?.payload?.message);
          history.push(`/backend_mrn`);
        }, 2000);
        form.resetFields();
        setTruckShow(false);
      } else {
        message.error(response?.payload?.message);
        setTruckShow(false);
      }
    });
  };

  const handleStandardCancel = () => {
    history.push('/dashboard');
  };
  const handleGetAllMaterialsList = () => {
    let payload = { search };
    dispatch(getAllMaterialslist(payload));
  };

  const handleGetAllSuppliers = () => {
    let payload = { search };
    dispatch(getAllSuppliers(payload));
  };

  const handleSeldctVendor = (value) => {
    setSelectVendor(value);
  };
  const Statuses = [
    {
      label: 'Provisional ',
      valu: 'provisional '
    },
    {
      label: 'Approved',
      valu: 'approved'
    }
  ];

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Card title="MATERIAL DETAILS" className={styles.CustomPanel}>
        <Row gutter={24}>
          <Col xs={24} xl={12} span={12} md={24} sm={24}>
            <FormItem
              label="Material Code"
              name="material_id"
              rules={[
                {
                  required: true,
                  message: 'Select Material Code'
                }
              ]}
              className={styles.customLable}
            >
              <Select
                showSearch
                placeholder="Select a material code"
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
              >
                {MaterialsList?.map((item, index) => {
                  return (
                    <option value={item.id} key={index}>
                      {item.code}
                    </option>
                  );
                })}
              </Select>
            </FormItem>
          </Col>
          <Col xs={24} xl={12} span={12} md={24} sm={24}>
            <FormItem
              label="Supplier Name"
              name="supplier_id"
              rules={[
                {
                  required: true,
                  message: 'Select Supplier name'
                }
              ]}
              className={styles.customLable}
            >
              <Select
                showSearch
                placeholder="Select a supplier name"
                optionFilterProp="children"
                onSearch={onSearchvendor}
                onChange={onChangeSupplier}
                onSelect={handleSeldctVendor}
              >
                {suppliersdata?.map((item, index) => {
                  return (
                    <option value={item.id} key={index}>
                      {item.name}
                    </option>
                  );
                })}
              </Select>
            </FormItem>
          </Col>
          <Col xs={24} xl={12} span={12} md={24} sm={24}>
            <FormItem
              label="Name of the Material"
              name="material_name"
              rules={[
                {
                  required: false,
                  message: 'Enter name of the material'
                }
              ]}
              className={styles.customLable}
            >
              <Input placeholder="Enter name of the material" disabled />
            </FormItem>
          </Col>

          <Col xs={24} xl={12} span={12} md={24} sm={24}>
            <FormItem
              label="Manufacturer Name"
              name="mfg_name"
              rules={[
                {
                  required: false,
                  message: 'Enter manufacturer name'
                }
              ]}
              className={styles.customLable}
            >
              <Input placeholder="Enter manufacturer Name" disabled />
            </FormItem>
          </Col>
          <Col xs={24} xl={12} span={12} md={24} sm={24}>
            <FormItem
              label="Material Vendor Status"
              name="vendor_status"
              rules={[
                {
                  required: false,
                  message: 'Select vendor status'
                }
              ]}
              className={styles.customLable}
            >
              <Select
                placeholder="Select a status"
                optionFilterProp="children"
                // onChange={onChange}
                disabled
              >
                {Statuses.map((item, index) => {
                  return (
                    <option value={item.value} key={index}>
                      {item.label}
                    </option>
                  );
                })}
              </Select>
            </FormItem>
          </Col>
          <Col xs={24} xl={12} span={12} md={24} sm={24}>
            <FormItem
              label="UOM"
              name="uom"
              rules={[
                {
                  required: false,
                  message: 'Enter UOM'
                }
              ]}
              className={styles.customLable}
            >
              <Input placeholder="Enter UOM" disabled />
            </FormItem>
          </Col>
          <Col xs={12} xl={12} span={12}>
            <FormItem
              label="Invoice Number"
              name="invoice_no"
              rules={[
                {
                  required: true,
                  message: 'Enter Invoice number'
                }
              ]}
              className={styles.customLable}
            >
              <Input placeholder="Enter Invoice number" />
            </FormItem>
          </Col>
          <Col xs={12} xl={12} span={12}>
            <FormItem
              label="PO Number"
              name="po_no"
              rules={[
                {
                  required: false,
                  message: 'Enter PO number'
                }
              ]}
              className={styles.customLable}
            >
              <Input placeholder="Enter PO number" />
            </FormItem>
          </Col>
        </Row>
      </Card>
      <br />
      <Card title="BATCH DETAILS" className={styles.CustomPanel}>
        <Row gutter={24}>
          <Col xs={12} xl={12} span={12}>
            <FormItem
              label="Mfg. Batch/Lot Number"
              name="mfg_batch_lot_no"
              rules={[
                {
                  required: false,
                  message: 'Enter Mfg Batch number'
                }
              ]}
              className={styles.customLable}
            >
              <Input placeholder="Enter Mfg Batch/LOT number" />
            </FormItem>
          </Col>
          <Col xs={12} xl={12} span={12}>
            <FormItem
              label="Inhouse BatchNumber"
              name="in_house_batch_number"
              rules={[
                {
                  required: true,
                  message: 'Enter Inhouse Batch number'
                }
              ]}
              className={styles.customLable}
            >
              <Input placeholder="Enter Inhouse Batch number" />
            </FormItem>
          </Col>
          <Col xs={12} xl={12} span={12}>
            <FormItem
              label="Mfg Date"
              name="mfg_date"
              rules={[
                {
                  required: false,
                  message: 'Enter Number of Invoices'
                }
              ]}
              className={styles.customLable}
            >
              <DatePicker
                placeholder="Select Date"
                style={{ width: '100%' }}
                name="mfg_Date"
              />
            </FormItem>
          </Col>
          <Col xs={12} xl={12} span={12}>
            <FormItem
              label="MRN Number"
              name="mrn_number"
              rules={[
                {
                  required: true,
                  message: 'Enter MRN number'
                }
              ]}
              className={styles.customLable}
            >
              <Input placeholder="Enter MRN number" />
            </FormItem>
          </Col>
          <Col xs={12} xl={12} span={12}>
            <FormItem
              label="Mfg Retest Date"
              name="mfg_retest_date"
              rules={[
                {
                  required: false,
                  message: 'Enter Mfg Retest Date'
                }
              ]}
              className={styles.customLable}
            >
              <DatePicker
                placeholder="Select Mfg Retest Date"
                style={{ width: '100%' }}
                name="mfg_retest_Date"
              />
            </FormItem>
          </Col>
          <Col xs={12} xl={12} span={12}>
            <FormItem
              label="Inhouse Retest Date"
              name="in_house_retest_date"
              rules={[
                {
                  required: false,
                  message: 'Enter Inhouse Retest Date'
                }
              ]}
              className={styles.customLable}
            >
              <DatePicker
                placeholder="Select Mfg Retest Date"
                style={{ width: '100%' }}
                name="in_house_retest_Date"
              />
            </FormItem>
          </Col>
          <Col xs={12} xl={12} span={12}>
            <FormItem
              label="Mfg Expiry Date"
              name="mfg_expiry_date"
              rules={[
                {
                  required: false,
                  message: 'Enter Mfg Expiry Date'
                }
              ]}
              className={styles.customLable}
            >
              <DatePicker
                placeholder="Select Mfg Expiry Date"
                style={{ width: '100%' }}
                name="mfg_expiry_Date"
              />
            </FormItem>
          </Col>
          <Col xs={12} xl={12} span={12}>
            <FormItem
              label="Inhouse Expiry Date"
              name="in_house_expiry_date"
              rules={[
                {
                  required: false,
                  message: 'Enter Inhouse Expiry Date'
                }
              ]}
              className={styles.customLable}
            >
              <DatePicker
                placeholder="Select Inhouse Expiry Date"
                style={{ width: '100%' }}
                name="in_house_expiry_Date"
              />
            </FormItem>
          </Col>
          <Col xs={12} xl={12} span={12}>
            <FormItem
              label="No. of Containers"
              name="no_of_containers"
              rules={[
                {
                  required: true,
                  message: 'Enter No.of Containers'
                }
              ]}
              className={styles.customLable}
            >
              <InputNumber
                min={1}
                style={{ width: '100%' }}
                placeholder="Enter No.of Containers"
              />
            </FormItem>
          </Col>
          <Col xs={12} xl={12} span={12}>
            <FormItem
              label="Available Quantity(Net)"
              name="available_quantity"
              rules={[
                {
                  required: true,
                  message: 'Enter Available Quantity'
                }
              ]}
              className={styles.customLable}
            >
              <InputNumber
                min={0}
                style={{ width: '100%' }}
                placeholder="Enter Available Quantity"
              />
            </FormItem>
          </Col>
        </Row>
      </Card>
      <br />
      <FormItem className="text-right">
        <Space>
          <CustomButton htmlType="button" onClick={handleStandardCancel}>
            Cancel
          </CustomButton>
          {truckShow ? (
            <Image
              src={truck}
              preview={false}
              style={{ width: '100px !important', height: '100px' }}
            />
          ) : (
            <CustomButton type="primary" htmlType="submit" loading={loading}>
              Submit
            </CustomButton>
          )}
        </Space>
      </FormItem>
      {/* </Space> */}
    </Form>
  );
};
export default BackendMRNPage;
