import React, { useEffect } from 'react';
import Layout from '@/common/Layout';
import { Card, Col, DatePicker, Form, Input, Row, Space, message } from 'antd';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import CustomButton from '@/common/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { createnonReturnablGP, getAllNonReturnGP } from '../../redux/slice';
import { history } from 'app/history';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

const createNonReturnableGatepass = () => {
  const { nonreturnableData } = useSelector((state) => state.return);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    handleGetAllReturnGatepass();
    id
      ? form.setFieldsValue({
          nrgp: nonreturnableData?.[0]?.nrgp,
          prep_date: moment(nonreturnableData?.[0]?.prep_date, 'YYYY-MM-DD'),
          vehi_no: nonreturnableData?.[0]?.vehi_no,
          mat_des: nonreturnableData?.[0]?.mat_des,
          qtyy: nonreturnableData?.[0]?.qtyy,
          remarks: nonreturnableData?.[0]?.remarks,
          purpose_of: nonreturnableData?.[0]?.purpose_of,
          prep_by: nonreturnableData?.[0]?.prep_by,
          auth_by: nonreturnableData?.[0]?.auth_by,
          received_by: nonreturnableData?.[0]?.received_by
        })
      : '';
  }, [id]);

  const handleGetAllReturnGatepass = () => {
    let payload = {
      id: id ? id : ''
    };
    dispatch(getAllNonReturnGP(payload));
  };

  const onFinish = (values) => {
    console.log('Success:', values);
    dispatch(createnonReturnablGP(values)).then((response) => {
      if (response?.payload?.success) {
        message.success(response?.payload?.message);
        history.push('non-returnable-gate-pass-list');
      }
    });
  };

  return (
    <Layout sider={false}>
      <Card
        title="Non Returnable Gate Pass"
        className={`${styles.cardStyles_approver}`}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={styles.panelborder}
        >
          <Row gutter={24}>
            <Col span={12}>
              <FormItem
                label="NRGP #"
                name="nrgp"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input placeholder="Enter NRGP #" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Preparation Date"
                name="prep_date"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <DatePicker
                  placeholder="Select Date"
                  style={{ width: '100%' }}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Vehicle Number"
                name="vehi_no"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Vehicle Number" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Material Description"
                name="mat_des"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Description" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Qty"
                name="qtyy"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Qty" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="remarks"
                name="remarks"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Remarks" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Purpose for sending"
                name="purpose_of"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Purpose" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Prepared By(User & Date)"
                name="prep_by"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input placeholder="Prepared By" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Authorized By(User & Date)"
                name="auth_by"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input placeholder="Authorized By" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Received By(User & Date)"
                name="received_by"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input placeholder="received by" />
              </FormItem>
            </Col>
          </Row>
          <FormItem className="text-center">
            <Space>
              <CustomButton
                className={styles.inwardButton}
                // onClick={handleClose}
                type="primary"
                size="small"
                ghost
              >
                Close
              </CustomButton>
              <CustomButton
                className={styles.inwardButton}
                htmlType="submit"
                type="primary"
                size="small"
              >
                Submit
              </CustomButton>
            </Space>
          </FormItem>
        </Form>
      </Card>
    </Layout>
  );
};
export default createNonReturnableGatepass;
