import React from 'react';
import Layout from '@/common/Layout';
import CreateBatchDetailsPage from './components/CreateBatchDetailsPage';

const BatchDetails = () => {
  return (
    <>
      <Layout sider={false}>
        <CreateBatchDetailsPage />
      </Layout>
    </>
  );
};

export default BatchDetails;
