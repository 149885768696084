import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Col,
  Row,
  Card,
  Radio,
  message,
  Empty,
  Space,
  Divider,
  Tag
} from 'antd';
import FormItem from '@/common/FormItem';
import CustomButton from '@/common/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styles from '@/common/Layout/index.module.less';
import { createChecklist } from './../redux/slice';
import { getQcMaterialDetails } from '../../../redux/slice';
import { getCampaignBatches } from '@/features/lineclearancechecklist/redux/slice';
import { printLC } from '../redux/slice';
import CustomTable from 'common/Content/CustomTable';
import { columns } from './constants.js';
import { history } from 'app/history';
import moment from 'moment';

const CreateLineClearanceChecklistPage = () => {
  const [form] = Form.useForm();
  const [lineClearanceChecklist, setLineClearanceChecklist] = useState({});
  const [wHLC, setWHLC] = useState({});
  const [qALC, setQALC] = useState({});
  const [isPrintDisabled, setIsPrintDisabled] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [dispenseId, setDispenseId] = useState('');
  const [campaignBatches, setCampaignBatches] = useState([]);

  const dispatch = useDispatch();
  const params = useParams();

  const { lcMaterialDetails } = useSelector((state) => state.lcMaterialDetails);
  const material = lcMaterialDetails?.dispensing_request?.type;
  // const dispense_id = lcMaterialDetails?.dispensing_request?.id;

  console.log(lcMaterialDetails?.dispensing_request?.bmr_number);

  const handleCapacity = (e) => {
    const { name, value } = e.target;
    setLineClearanceChecklist({ ...lineClearanceChecklist, [name]: value });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo.errorFields.length);
    if (errorInfo.errorFields.length > 0) {
      message.error(
        'Please select a response to all the questions in the checklist'
      );
    }
  };

  const printLcPdf = () => {
    setIsPrintDisabled(true);
    const payload = { id: params.id };
    dispatch(printLC(payload)).then(function (response) {
      if (response?.payload?.success) {
        window.open(response?.payload?.url, '_blank');
      }
    });
  };

  console.log(wHLC, 'wHLC');
  useEffect(() => {
    handleGetAllMaterials();
    handleGetAllCampaignBatches();
  }, [dispenseId]);

  const handleGetAllMaterials = () => {
    let payload = {
      id: params.id
    };
    dispatch(getQcMaterialDetails(payload)).then(function (result) {
      if (result?.payload?.success) {
        console.log(result?.payload?.data);
        // setDispenseId(result?.payload?.data?.dispensing_request?.id);
        setDispenseId(params.id);
      }
    });
  };

  // console.log(params.id);
  console.log(
    moment(lcMaterialDetails?.laf_time_date)
      .utcOffset(-300)
      .format('YYYY-MM-DD')
  );

  const handleGetAllCampaignBatches = () => {
    let payload = {
      dispensing_request_id: localStorage.getItem('batches')
        ? String(localStorage.getItem('batches')).split(',')
        : dispenseId
    };
    dispatch(getCampaignBatches(payload)).then(function (result) {
      if (result?.payload?.success) {
        console.log(result?.payload?.data);
        setCampaigns(result?.payload?.data);
        // const list = [...campaignBatches];
        result?.payload?.data?.map((res) => {
          campaignBatches.push(res?.id);
        });
        // setCampaignBatches(list);
      }
    });
    console.log(payload);
  };

  console.log(setCampaignBatches, [...new Set(campaignBatches)]);
  useEffect(() => {
    const lc_filter = lcMaterialDetails?.list_clerance_checklists?.filter(
      (item) => item?.lc_type == 'lc_initiated'
    );
    const lc_qa_filter = lcMaterialDetails?.list_clerance_checklists?.filter(
      (item) => item?.lc_type == 'lc_submitted'
    );
    setWHLC(lc_filter);
    setQALC(lc_qa_filter);
    form.setFieldsValue({
      bmr_number: lcMaterialDetails?.dispensing_request?.bmr_number,
      product_name: lcMaterialDetails?.dispensing_request?.product_detail?.name,
      product_code: lcMaterialDetails?.dispensing_request?.product_batch_number,
      material_code: lcMaterialDetails?.material_code,
      material_name: lcMaterialDetails?.material_name,
      required_weight: lcMaterialDetails?.required_weight,
      dispense_sampling_booth_id: lcMaterialDetails?.dispense_sampling_booth_id,
      date: lcMaterialDetails?.date,
      previous_product_name: lcMaterialDetails?.previous_product_name,
      previous_product_batch_no: lcMaterialDetails?.previous_product_batch_no,
      proposed_product_name: lcMaterialDetails?.proposed_product_name,
      proposed_product_batch_no: lcMaterialDetails?.proposed_product_batch_no,
      area_ahu_switched_on: lcMaterialDetails?.area_ahu_switched_on,
      area_ahu_time_date: lcMaterialDetails?.area_ahu_time_date
        ? moment(lcMaterialDetails?.area_ahu_time_date)
            // .utcOffset(0)
            .format('YYYY-MM-DD HH:mm')
        : '',
      laf_switched_on: lcMaterialDetails?.laf_switched_on || 'NA',
      laf_time_date: lcMaterialDetails?.laf_time_date
        ? moment(lcMaterialDetails?.laf_time_date)
            // .utcOffset(0)
            .format('YYYY-MM-DD HH:mm')
        : '',
      magnehelle_guage_reading_dispensing_samping_area:
        lcMaterialDetails?.magnehelle_guage_reading_dispensing_samping_area ||
        'NA',
      temperature: lcMaterialDetails?.temperature || 'NA',
      relative_humidity: lcMaterialDetails?.relative_humidity || 'NA',
      pre_filter: lcMaterialDetails?.pre_filter || 'NA',
      fine_filter: lcMaterialDetails?.fine_filter || 'NA',
      hepa_filter: lcMaterialDetails?.hepa_filter || 'NA'
    });
  }, [lcMaterialDetails]);

  const onFinish = (values) => {
    values['pre_filter'] = lineClearanceChecklist.pre_filter;
    values['fine_filter'] = lineClearanceChecklist.fine_filter;
    values['hepa_filter'] = lineClearanceChecklist.hepa_filter;
    values['no_containers_of_previous_material'] =
      lineClearanceChecklist.no_containers_of_previous_material;
    values['checked_unavailablity_used_dispensing_sample_aids_area'] =
      lineClearanceChecklist.checked_unavailablity_used_dispensing_sample_aids_area;
    values['checked_unavailablity_vivible_powder_observed_around_the_area'] =
      lineClearanceChecklist.checked_unavailablity_vivible_powder_observed_around_the_area;
    values['dustbins_are_cleared'] =
      lineClearanceChecklist.dustbins_are_cleared;
    values['cleaning_of_floor'] = lineClearanceChecklist.cleaning_of_floor;
    values['cleaning_of_covings'] = lineClearanceChecklist.cleaning_of_covings;
    values['cleaning_of_electrical'] =
      lineClearanceChecklist.cleaning_of_electrical;
    values['previous_record_removed'] =
      lineClearanceChecklist.previous_record_removed;
    values['cleaniness_working_area'] =
      lineClearanceChecklist.cleaniness_working_area;
    values['balance_calbration_carried_out'] =
      lineClearanceChecklist.balance_calbration_carried_out;
    values['check_the_packing_condition'] =
      lineClearanceChecklist.check_the_packing_condition;
    values['checked_the_material'] =
      lineClearanceChecklist.checked_the_material;
    values['checked_the_retest_date'] =
      lineClearanceChecklist.checked_the_retest_date;
    values['swab_results_of_previous_product'] =
      lineClearanceChecklist.swab_results_of_previous_product;
    values['availability_cleanness_nitrogen'] =
      lineClearanceChecklist.availability_cleanness_nitrogen;
    values['sodium_vapour_lamps_required_proposed_product'] =
      lineClearanceChecklist.sodium_vapour_lamps_required_proposed_product;
    values['cleaniness_of_pass_box'] =
      lineClearanceChecklist.cleaniness_of_pass_box;
    // values['lc_input_id'] = lcMaterialDetails?.id;
    values['compaign_batches'] = localStorage.getItem('batches')
      ? String(localStorage.getItem('batches')).split(',')
      : [params.id];
    console.log('form', values);

    // dispatch(createChecklist(values));
    dispatch(createChecklist(values)).then(function (response) {
      console.log(lineClearanceChecklist);
      if (response?.payload?.success) {
        console.log('success', response);
        message.success(response.payload.message);
        history.push('/dispense-material-list');
        form.resetFields();
      }
    });
  };

  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        <div className={styles.empty_state_text}>
          There are no material requests to display
        </div>
      </span>
    )
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      {/* <h2 className={styles.CustomPanel}>
        Line Clearance Checklist - {''}
        {material == 'bmr' ? 'BMR Based' : 'Material Code Based'}
      </h2> */}
      <Card
        className={styles.cardStyles_approver}
        title={`Line Clearance Checklist - ${
          material == 'bmr' ? 'BMR Based' : 'Material Code Based'
        }`}
        extra={
          <Space>
            {
              <CustomButton
                type="primary"
                ghost
                onClick={printLcPdf}
                disabled={isPrintDisabled}
              >
                Print
              </CustomButton>
            }
          </Space>
        }
        // <button> Print </button>
      >
        <Row gutter={24}>
          {material == 'bmr' ? (
            <>
              <Col xs={24} xl={6} span={6}>
                <FormItem
                  label="BMR Number"
                  name="bmr_number"
                  rules={[
                    {
                      required: false,
                      message: 'Enter BMR Number'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    name="bmr_number"
                    onChange={handleCapacity}
                    placeholder="Enter BMR Number"
                    value={lcMaterialDetails?.dispensing_request?.bmr_number}
                    disabled
                  />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="Product Name" className={styles.customLable}>
                  <Input
                    disabled
                    value={
                      lcMaterialDetails?.dispensing_request?.product_detail
                        ?.name || 'NA'
                    }
                  />
                </FormItem>
              </Col>
              {/* <Col span={6}>
                <FormItem
                  label="Product Batch Number"
                  className={styles.customLable}
                >
                  <Input
                    disabled
                    value={
                      lcMaterialDetails?.dispensing_request?.product_detail
                        ?.product_batch_number || 'NA'
                    }
                  />
                </FormItem>
              </Col> */}

              <Col span={6}>
                <FormItem label="Effective Date" className={styles.customLable}>
                  <Input
                    disabled
                    value={
                      lcMaterialDetails?.dispensing_request?.product_detail
                        ?.effective_date
                        ? moment(
                            lcMaterialDetails?.dispensing_request
                              ?.product_detail?.effective_date
                          ).format('DD MMM YYYY')
                        : 'NA'
                    }
                  />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="Batch Size" className={styles.customLable}>
                  <Input
                    disabled
                    value={
                      lcMaterialDetails?.dispensing_request?.product_detail
                        ?.theoretical_batch_size || 'NA'
                    }
                  />
                </FormItem>
              </Col>

              <Col span={6}>
                <FormItem label="MFC Number" className={styles.customLable}>
                  <Input
                    disabled
                    value={
                      lcMaterialDetails?.dispensing_request?.ref_mfc || 'NA'
                    }
                  />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="Product Code" className={styles.customLable}>
                  <Input
                    disabled
                    value={
                      lcMaterialDetails?.dispensing_request?.product_code ||
                      'NA'
                    }
                  />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="Lable Claim" className={styles.customLable}>
                  <Input
                    disabled
                    value={
                      lcMaterialDetails?.dispensing_request?.label_claim || 'NA'
                    }
                  />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem
                  label="Manufacturing Date"
                  className={styles.customLable}
                >
                  <Input
                    disabled
                    value={
                      lcMaterialDetails?.dispensing_request?.manufacturing_date
                        ? moment(
                            lcMaterialDetails?.dispensing_request
                              ?.manufacturing_date
                          ).format('YYYY-MM-DD')
                        : 'NA'
                    }
                  />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="Expiry Date" className={styles.customLable}>
                  <Input
                    disabled
                    value={
                      lcMaterialDetails?.dispensing_request?.expiry_date || 'NA'
                    }
                  />
                </FormItem>
              </Col>

              <Col span={6}>
                <FormItem
                  label="Manufacturing Site"
                  className={styles.customLable}
                >
                  <Input
                    disabled
                    value={
                      lcMaterialDetails?.dispensing_request
                        ?.manufacturing_site || 'NA'
                    }
                  />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem
                  label="Market / Customer Details"
                  className={styles.customLable}
                >
                  <Input
                    disabled
                    value={
                      lcMaterialDetails?.dispensing_request?.customer_details ||
                      'NA'
                    }
                  />
                </FormItem>
              </Col>

              {/* <Col xs={24} xl={8} span={8}>
                <FormItem
                  label="BMR Number"
                  name="bmr_number"
                  rules={[
                    {
                      required: false,
                      message: 'Enter BMR Number'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input name="bmr_number" disabled />
                </FormItem>
              </Col>

              <Col xs={24} xl={8} span={8}>
                <FormItem
                  label="Product Name"
                  name="product_name"
                  rules={[
                    {
                      required: false,
                      message: 'Enter Product Name'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    disabled
                    name="product_name"
                    placeholder="Enter Product Name"
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <FormItem
                  label="Effective Date"
                  rules={[
                    {
                      required: false,
                      message: 'Enter Product Name'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    disabled
                    value={
                      lcMaterialDetails?.dispensing_request?.effective_date
                        ? moment(
                            lcMaterialDetails?.dispensing_request
                              ?.effective_date
                          ).format('DD-MM-YYYY')
                        : 'NA'
                    }
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <FormItem
                  label="Batch Size"
                  rules={[
                    {
                      required: false,
                      message: 'Enter Product Name'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    disabled
                    value={
                      lcMaterialDetails?.dispensing_request?.product_detail
                        ?.theoretical_batch_size || 'NA'
                    }
                  />
                </FormItem>
              </Col> */}
              <Col xs={24} xl={16} span={8}>
                <FormItem
                  label="Product Batch Number"
                  name="product_code"
                  rules={[
                    {
                      required: false,
                      message: 'Enter Product Code'
                    }
                  ]}
                  className={styles.customLable}
                >
                  {campaigns.map((batch) => (
                    <Tag
                      key={batch?.id}
                      style={{ fontSize: '14px !important' }}
                    >
                      {batch?.product_batch_number}
                    </Tag>
                  ))}
                  {/* <Input
                    disabled
                    name="product_code"
                    placeholder="Enter Product Code"
                  /> */}
                </FormItem>
              </Col>
            </>
          ) : null}
        </Row>
        {material == 'material' ? (
          <>
            <CustomTable
              pagination={false}
              locale={locale}
              columns={columns}
              dataSource={
                lcMaterialDetails?.dispensing_request?.dispensing_materials
              }
              className={styles.scrolling}
              scroll={{ x: 'auto' }}
            />
          </>
        ) : null}
        {/* </Card> */}
        <Divider />
        {/* <Card className={styles.cardStyles_approver}> */}
        <Row gutter={24}>
          <Col xs={24} xl={12} span={12}>
            <FormItem
              label="Dispensing Sample Booth ID No "
              name="dispense_sampling_booth_id"
              rules={[
                {
                  required: false,
                  message: 'Enter Sample Booth ID Number'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                name="dispense_sampling_booth_id"
                disabled
                placeholder="Enter Sample Booth ID Number"
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={12} span={12}>
            <FormItem
              label="Date"
              name="date"
              rules={[
                {
                  required: false,
                  message: 'Enter Date'
                }
              ]}
              className={styles.customLable}
            >
              <Input name="date" disabled />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={12} span={12}>
            <FormItem
              label="Previous Product Name"
              name="previous_product_name"
              rules={[
                {
                  required: false,
                  message: 'Enter Previous Product Name'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                name="previous_product_name"
                disabled
                placeholder="Enter Previous Product Name"
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={12} span={12}>
            <FormItem
              label="Previous Batch No"
              name="previous_product_batch_no"
              rules={[
                {
                  required: false,
                  message: 'Enter Batch Number'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                name="previous_product_batch_no"
                disabled
                placeholder="Enter Batch Number"
              />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={12} span={12}>
            <FormItem
              label="Proposed Product Name"
              name="proposed_product_name"
              rules={[
                {
                  required: false,
                  message: 'Enter Proposed Product Name'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                name="proposed_product_name"
                disabled
                placeholder="Enter Proposed Product Name"
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={12} span={12}>
            <FormItem
              label="Proposed Batch No"
              name="proposed_product_batch_no"
              rules={[
                {
                  required: false,
                  message: 'Enter Batch Number'
                }
              ]}
              className={styles.customLable}
            >
              {campaigns.map((batch) => (
                <Tag key={batch?.id}>{batch?.product_batch_number}</Tag>
              ))}
              {/* <Input
                name="proposed_product_batch_no"
                disabled
                placeholder="Enter Batch Number"
              /> */}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={12} span={12}>
            <FormItem
              label="Area AHU Switched Status"
              name="area_ahu_switched_on"
              rules={[
                {
                  required: false,
                  message: 'Enter Area AHU Switched ON'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                name="area_ahu_switched_on"
                disabled
                placeholder="Enter Area AHU Switched ON"
              />
            </FormItem>
          </Col>
          {lcMaterialDetails?.area_ahu_switched_on == 'on' ? (
            <Col xs={24} xl={12} span={12}>
              <FormItem
                label="Time & Date"
                name="area_ahu_time_date"
                rules={[
                  {
                    required: false,
                    message: 'Enter Time & Date'
                  }
                ]}
                className={styles.customLable}
              >
                <Input name="area_ahu_time_date" disabled />
              </FormItem>
            </Col>
          ) : (
            ''
          )}
          <Col xs={24} xl={12} span={12}>
            <FormItem
              label="LAF Switched Status"
              name="laf_switched_on"
              rules={[
                {
                  required: false,
                  message: 'Enter LAF Switched ON'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                name="laf_switched_on"
                disabled
                placeholder="Enter LAF Switched ON"
              />
            </FormItem>
          </Col>
          {lcMaterialDetails?.laf_switched_on == 'on' ? (
            <Col xs={24} xl={12} span={12}>
              <FormItem
                label="Time & Date"
                name="laf_time_date"
                rules={[
                  {
                    required: false,
                    message: 'Enter Time & Date'
                  }
                ]}
                className={styles.customLable}
              >
                <Input name="laf_time_date" disabled />
              </FormItem>
            </Col>
          ) : (
            ''
          )}
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Magnehelic gauge reading Dispensing Sample Area"
              name="magnehelle_guage_reading_dispensing_samping_area"
              rules={[
                {
                  required: false,
                  message: 'Enter Gauge Reading Dispensing Sample Area'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                name="magnehelle_guage_reading_dispensing_samping_area"
                disabled
                placeholder="Enter Gauge Reading Dispensing Sample Area"
              />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={12} span={12}>
            <FormItem
              label="Temperature(&deg;C)"
              name="temperature"
              rules={[
                {
                  required: false,
                  message: 'Enter Temperature'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                name="temperature"
                disabled
                placeholder="Enter Temperature"
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={12} span={12}>
            <FormItem
              label="(%)Relative Humidity"
              name="relative_humidity"
              rules={[
                {
                  required: false,
                  message: 'Enter Relative Humidity'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                name="relative_humidity"
                disabled
                placeholder="Enter Relative Humidity"
              />
            </FormItem>
          </Col>
        </Row>
      </Card>
      <br />
      {/* <h2 className={styles.CustomPanel}>LAF Magnehelle Gauge Reading</h2> */}
      <Card
        className={styles.cardStyles_approver}
        title="LAF Magnehelle Gauge Reading"
      >
        <Row gutter={24}>
          <Col xs={24} xl={8} span={8}>
            <FormItem
              label="Pre Filter"
              name="pre_filter"
              rules={[
                {
                  required: false,
                  message: 'Enter Pre Filter'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                name="pre_filter"
                onChange={handleCapacity}
                placeholder="Enter Pre Filter"
                disabled
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={8} span={8}>
            <FormItem
              label="Fine Filter"
              name="fine_filter"
              rules={[
                {
                  required: false,
                  message: 'Enter Fine Filter'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                name="fine_filter"
                onChange={handleCapacity}
                placeholder="Enter Fine Filter"
                disabled
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={8} span={8}>
            <FormItem
              label="HEPA Filter"
              name="hepa_filter"
              rules={[
                {
                  required: false,
                  message: 'Enter HEPA Filter'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                name="hepa_filter"
                onChange={handleCapacity}
                placeholder="Enter HEPA Filter"
                disabled
              />
            </FormItem>
          </Col>
        </Row>
        <Divider />
        {/* </Card>
      <Card className={styles.cardStyles_approver}> */}
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="No containers of previous material observed in the area"
              name="no_containers_of_previous_material"
              rules={[
                {
                  required: false,
                  message: 'Select no containers of previous materials'
                }
              ]}
              className={styles.customLable}
            >
              <Space size={16}>
                {wHLC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.no_containers_of_previous_material == 1
                        ? 'Yes'
                        : wHLC?.[0]?.no_containers_of_previous_material == 2
                        ? 'No'
                        : wHLC?.[0]?.no_containers_of_previous_material == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.no_containers_of_previous_material == 1
                        ? 'Yes'
                        : qALC?.[0]?.no_containers_of_previous_material == 2
                        ? 'No'
                        : qALC?.[0]?.no_containers_of_previous_material == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {lcMaterialDetails?.dispensing_request?.status !== 'Approved' ? (
                <Radio.Group
                  name="no_containers_of_previous_material"
                  onChange={handleCapacity}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Checked for the unavailability of used dispensing sampling aids in the area"
              name="checked_unavailablity_used_dispensing_sample_aids_area"
              rules={[
                {
                  required: false,
                  message: 'Select checked unavailabilty'
                }
              ]}
              className={styles.customLable}
            >
              <Space size={16}>
                {wHLC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.sample_aids_area == 1
                        ? 'Yes'
                        : wHLC?.[0]?.sample_aids_area == 2
                        ? 'No'
                        : wHLC?.[0]?.sample_aids_area == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.sample_aids_area == 1
                        ? 'Yes'
                        : qALC?.[0]?.sample_aids_area == 2
                        ? 'No'
                        : qALC?.[0]?.sample_aids_area == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {lcMaterialDetails?.dispensing_request?.status !== 'Approved' ? (
                <Radio.Group
                  name="checked_unavailablity_used_dispensing_sample_aids_area"
                  onChange={handleCapacity}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Checked for the unavailability of visible powder observed around the balance"
              name="checked_unavailablity_vivible_powder_observed_around_the_area"
              rules={[
                {
                  required: false,
                  message: 'Select checked poweder observed around area'
                }
              ]}
              className={styles.customLable}
            >
              <Space size={16}>
                {wHLC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.vivible_powder == 1
                        ? 'Yes'
                        : wHLC?.[0]?.vivible_powder == 2
                        ? 'No'
                        : wHLC?.[0]?.vivible_powder == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.vivible_powder == 1
                        ? 'Yes'
                        : qALC?.[0]?.vivible_powder == 2
                        ? 'No'
                        : qALC?.[0]?.vivible_powder == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {lcMaterialDetails?.dispensing_request?.status !== 'Approved' ? (
                <Radio.Group
                  name="checked_unavailablity_vivible_powder_observed_around_the_area"
                  onChange={handleCapacity}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Dustbins are cleared and scraps are removed"
              name="dustbins_are_cleared"
              rules={[
                {
                  required: false,
                  message: 'Select Dustbins are cleared and scraps are removed'
                }
              ]}
              className={styles.customLable}
            >
              <Space size={16}>
                {wHLC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.dustbins_are_cleared == 1
                        ? 'Yes'
                        : wHLC?.[0]?.dustbins_are_cleared == 2
                        ? 'No'
                        : wHLC?.[0]?.dustbins_are_cleared == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.dustbins_are_cleared == 1
                        ? 'Yes'
                        : qALC?.[0]?.dustbins_are_cleared == 2
                        ? 'No'
                        : qALC?.[0]?.dustbins_are_cleared == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {lcMaterialDetails?.dispensing_request?.status !== 'Approved' ? (
                <Radio.Group
                  name="dustbins_are_cleared"
                  onChange={handleCapacity}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Cleaning of floor, stainless steel panels of dispensing sampling booth"
              name="cleaning_of_floor"
              rules={[
                {
                  required: false,
                  message: 'Select Cleaning of floor'
                }
              ]}
              className={styles.customLable}
            >
              <Space size={16}>
                {wHLC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.cleaning_of_floor == 1
                        ? 'Yes'
                        : wHLC?.[0]?.cleaning_of_floor == 2
                        ? 'No'
                        : wHLC?.[0]?.cleaning_of_floor == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.cleaning_of_floor == 1
                        ? 'Yes'
                        : qALC?.[0]?.cleaning_of_floor == 2
                        ? 'No'
                        : qALC?.[0]?.cleaning_of_floor == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {lcMaterialDetails?.dispensing_request?.status !== 'Approved' ? (
                <Radio.Group name="cleaning_of_floor" onChange={handleCapacity}>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Cleaning of covings"
              name="cleaning_of_covings"
              rules={[
                {
                  required: false,
                  message: 'Select Cleaning of covings'
                }
              ]}
              className={styles.customLable}
            >
              <Space size={16}>
                {wHLC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.cleaning_of_covings == 1
                        ? 'Yes'
                        : wHLC?.[0]?.cleaning_of_covings == 2
                        ? 'No'
                        : wHLC?.[0]?.cleaning_of_covings == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.cleaning_of_covings == 1
                        ? 'Yes'
                        : qALC?.[0]?.cleaning_of_covings == 2
                        ? 'No'
                        : qALC?.[0]?.cleaning_of_covings == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {lcMaterialDetails?.dispensing_request?.status !== 'Approved' ? (
                <Radio.Group
                  name="cleaning_of_covings"
                  onChange={handleCapacity}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Cleaning of Electrical fixtures of dispensing sampling booth"
              name="cleaning_of_electrical"
              rules={[
                {
                  required: false,
                  message: 'Select Cleaning of Electrical'
                }
              ]}
              className={styles.customLable}
            >
              <Space size={16}>
                {wHLC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.cleaning_of_electrical == 1
                        ? 'Yes'
                        : wHLC?.[0]?.cleaning_of_electrical == 2
                        ? 'No'
                        : wHLC?.[0]?.cleaning_of_electrical == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.cleaning_of_electrical == 1
                        ? 'Yes'
                        : qALC?.[0]?.cleaning_of_electrical == 2
                        ? 'No'
                        : qALC?.[0]?.cleaning_of_electrical == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {lcMaterialDetails?.dispensing_request?.status !== 'Approved' ? (
                <Radio.Group
                  name="cleaning_of_electrical"
                  onChange={handleCapacity}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Previous records removed from the area"
              name="previous_record_removed"
              rules={[
                {
                  required: false,
                  message: 'Select Previous records removed'
                }
              ]}
            >
              <Space size={16}>
                {wHLC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.previous_record_removed == 1
                        ? 'Yes'
                        : wHLC?.[0]?.previous_record_removed == 2
                        ? 'No'
                        : wHLC?.[0]?.previous_record_removed == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.previous_record_removed == 1
                        ? 'Yes'
                        : qALC?.[0]?.previous_record_removed == 2
                        ? 'No'
                        : qALC?.[0]?.previous_record_removed == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {lcMaterialDetails?.dispensing_request?.status !== 'Approved' ? (
                <Radio.Group
                  name="previous_record_removed"
                  onChange={handleCapacity}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Cleanliness of working area"
              name="cleaniness_working_area"
              rules={[
                {
                  required: false,
                  message: 'Select Cleanliness of working area'
                }
              ]}
            >
              <Space size={16}>
                {wHLC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.cleaniness_working_area == 1
                        ? 'Yes'
                        : wHLC?.[0]?.cleaniness_working_area == 2
                        ? 'No'
                        : wHLC?.[0]?.cleaniness_working_area == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.cleaniness_working_area == 1
                        ? 'Yes'
                        : qALC?.[0]?.cleaniness_working_area == 2
                        ? 'No'
                        : qALC?.[0]?.cleaniness_working_area == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {lcMaterialDetails?.dispensing_request?.status !== 'Approved' ? (
                <Radio.Group
                  name="cleaniness_working_area"
                  onChange={handleCapacity}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Balance calibration carried out"
              name="balance_calbration_carried_out"
              rules={[
                {
                  required: false,
                  message: 'Select Balance calibration carried out'
                }
              ]}
            >
              <Space size={16}>
                {wHLC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.balance_calbration_carried_out == 1
                        ? 'Yes'
                        : wHLC?.[0]?.balance_calbration_carried_out == 2
                        ? 'No'
                        : wHLC?.[0]?.balance_calbration_carried_out == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.balance_calbration_carried_out == 1
                        ? 'Yes'
                        : qALC?.[0]?.balance_calbration_carried_out == 2
                        ? 'No'
                        : qALC?.[0]?.balance_calbration_carried_out == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {lcMaterialDetails?.dispensing_request?.status !== 'Approved' ? (
                <Radio.Group
                  name="balance_calbration_carried_out"
                  onChange={handleCapacity}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Checked the packing condition of the to be dispensed sampling materials and found satisfactory"
              name="check_the_packing_condition"
              rules={[
                {
                  required: false,
                  message: 'Select Check the packing condition'
                }
              ]}
            >
              <Space size={16}>
                {wHLC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.check_the_packing_condition == 1
                        ? 'Yes'
                        : wHLC?.[0]?.check_the_packing_condition == 2
                        ? 'No'
                        : wHLC?.[0]?.check_the_packing_condition == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.check_the_packing_condition == 1
                        ? 'Yes'
                        : qALC?.[0]?.check_the_packing_condition == 2
                        ? 'No'
                        : qALC?.[0]?.check_the_packing_condition == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {lcMaterialDetails?.dispensing_request?.status !== 'Approved' ? (
                <Radio.Group
                  name="check_the_packing_condition"
                  onChange={handleCapacity}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Checked the material name, material code, approved label etc. to be dispensed material"
              name="checked_the_material"
              rules={[
                {
                  required: false,
                  message: 'Select Check the material'
                }
              ]}
            >
              <Space size={16}>
                {wHLC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.checked_the_material == 1
                        ? 'Yes'
                        : wHLC?.[0]?.checked_the_material == 2
                        ? 'No'
                        : wHLC?.[0]?.checked_the_material == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.checked_the_material == 1
                        ? 'Yes'
                        : qALC?.[0]?.checked_the_material == 2
                        ? 'No'
                        : qALC?.[0]?.checked_the_material == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {lcMaterialDetails?.dispensing_request?.status !== 'Approved' ? (
                <Radio.Group
                  name="checked_the_material"
                  onChange={handleCapacity}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Checked the retest date, best before use date and expire date of to be dispensed sampling material and found satisfactory"
              name="checked_the_retest_date"
              rules={[
                {
                  required: false,
                  message: 'Select Check the retest date'
                }
              ]}
            >
              <Space size={16}>
                {wHLC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.checked_the_retest_date == 1
                        ? 'Yes'
                        : wHLC?.[0]?.checked_the_retest_date == 2
                        ? 'No'
                        : wHLC?.[0]?.checked_the_retest_date == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.checked_the_retest_date == 1
                        ? 'Yes'
                        : qALC?.[0]?.checked_the_retest_date == 2
                        ? 'No'
                        : qALC?.[0]?.checked_the_retest_date == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {lcMaterialDetails?.dispensing_request?.status !== 'Approved' ? (
                <Radio.Group
                  name="checked_the_retest_date"
                  onChange={handleCapacity}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Swab results of previous product(If any)"
              name="swab_results_of_previous_product"
              rules={[
                {
                  required: false,
                  message: 'Select swab results of previous product'
                }
              ]}
            >
              <Space size={16}>
                {wHLC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.swab_results == 1
                        ? 'Yes'
                        : wHLC?.[0]?.swab_results == 2
                        ? 'No'
                        : wHLC?.[0]?.swab_results == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.swab_results == 1
                        ? 'Yes'
                        : qALC?.[0]?.swab_results == 2
                        ? 'No'
                        : qALC?.[0]?.swab_results == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {lcMaterialDetails?.dispensing_request?.status !== 'Approved' ? (
                <Radio.Group
                  name="swab_results_of_previous_product"
                  onChange={handleCapacity}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Availability & Cleanness of nitrogen purging"
              name="availability_cleanness_nitrogen"
              rules={[
                {
                  required: false,
                  message: 'Select availability & cleanness of Nitrogen'
                }
              ]}
            >
              <Space size={16}>
                {wHLC?.length &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.availability_cleanness_nitrogen === '1'
                        ? 'Yes'
                        : wHLC?.[0]?.availability_cleanness_nitrogen == '2'
                        ? 'No'
                        : wHLC?.[0]?.availability_cleanness_nitrogen == '3'
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.availability_cleanness_nitrogen == 1
                        ? 'Yes'
                        : qALC?.[0]?.availability_cleanness_nitrogen == 2
                        ? 'No'
                        : qALC?.[0]?.availability_cleanness_nitrogen == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {lcMaterialDetails?.dispensing_request?.status !== 'Approved' ? (
                <Radio.Group
                  name="availability_cleanness_nitrogen"
                  onChange={handleCapacity}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={24}>
            <FormItem
              label="Sodium vapor Lamps required for proposed product"
              name="sodium_vapour_lamps_required_proposed_product"
              rules={[
                {
                  required: false,
                  message: 'Select Sodium vapour'
                }
              ]}
            >
              <Space size={16}>
                {wHLC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.sodium_vapour_lamps == 1
                        ? 'Yes'
                        : wHLC?.[0]?.sodium_vapour_lamps == 2
                        ? 'No'
                        : wHLC?.[0]?.sodium_vapour_lamps == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.sodium_vapour_lamps == 1
                        ? 'Yes'
                        : qALC?.[0]?.sodium_vapour_lamps == 2
                        ? 'No'
                        : qALC?.[0]?.sodium_vapour_lamps == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {lcMaterialDetails?.dispensing_request?.status !== 'Approved' ? (
                <Radio.Group
                  name="sodium_vapour_lamps_required_proposed_product"
                  onChange={handleCapacity}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={24} span={12}>
            <FormItem
              label="check the cleanliness of pass box"
              name="cleaniness_of_pass_box"
              rules={[
                {
                  required: false,
                  message: 'Select Check the cleanliness of pass box'
                }
              ]}
            >
              <Space size={16}>
                {wHLC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `WH: ${
                      wHLC?.[0]?.cleaniness_of_pass_box == 1
                        ? 'Yes'
                        : wHLC?.[0]?.cleaniness_of_pass_box == 2
                        ? 'No'
                        : wHLC?.[0]?.cleaniness_of_pass_box == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
                {qALC &&
                lcMaterialDetails?.dispensing_request?.status == 'Approved'
                  ? `QA: ${
                      qALC?.[0]?.cleaniness_of_pass_box == 1
                        ? 'Yes'
                        : qALC?.[0]?.cleaniness_of_pass_box == 2
                        ? 'No'
                        : qALC?.[0]?.cleaniness_of_pass_box == 3
                        ? 'NA'
                        : 'Not Filled'
                    }`
                  : ''}
              </Space>
              {lcMaterialDetails?.dispensing_request?.status !== 'Approved' ? (
                <Radio.Group
                  name="cleaniness_of_pass_box"
                  onChange={handleCapacity}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>NA</Radio>
                </Radio.Group>
              ) : (
                ''
              )}
            </FormItem>
          </Col>
        </Row>
      </Card>
      <br />
      {lcMaterialDetails?.dispensing_request?.status !== 'Approved' ? (
        <div className={styles.submitbutton}>
          <FormItem className="text-center">
            <CustomButton
              type="primary"
              htmlType="submit"
              // className={styles.inwardButton}
            >
              Submit
            </CustomButton>
          </FormItem>
        </div>
      ) : (
        ''
      )}
    </Form>
  );
};

export default CreateLineClearanceChecklistPage;
