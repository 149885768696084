import React from 'react';
import styles from './index.module.less';
import { Card, Image, Space, Row, Col } from 'antd';
import truck from '../../images/1.svg';
import Gatepasstruck from '../../images/2.svg';
// import QCtruck from '../../images/3.svg';
import CustomButton from '@/common/CustomButton';

const LandingPage = () => {
  const cards = [
    {
      title: 'Update Bin Position',
      url: '/updatebin',
      icon: truck
    },
    {
      title: 'Track Bin Position',
      url: '/trackbin',
      icon: Gatepasstruck
    },
    {
      title: 'View Bin Free Containers',
      url: '/free-containers',
      icon: Gatepasstruck
    }
    // {
    //   title: 'View Container History',
    //   url: '',
    //   icon: QCtruck
    // }
  ];
  return (
    <>
      <Row gutter={24}>
        {cards.map((item, index) => {
          return (
            <Col
              xs={6}
              xl={6}
              md={6}
              sm={6}
              span={6}
              className={`${styles.cardbody1} mb-2`}
              key={index}
            >
              <Card hoverable className={styles.cardbody}>
                <Space direction="vertical">
                  <Image
                    src={item.icon}
                    preview={false}
                    style={{ height: '200px', width: '80% !important' }}
                  />
                  <CustomButton
                    // className={styles.inwardbutton}
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = item.url;
                    }}
                  >
                    {item.title}
                  </CustomButton>
                </Space>
              </Card>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default LandingPage;
