const Home = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 23 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5039 19.4999C19.5039 19.7651 19.3986 20.0195 19.211 20.207C19.0235 20.3946 18.7691 20.4999 18.5039 20.4999H13.5603V15.8185H11.0882H8.78067V20.4999H4.50391C4.23869 20.4999 3.98434 20.3946 3.7968 20.207C3.60926 20.0195 3.50391 19.7651 3.50391 19.4999V10.4999H0.503906L10.8309 1.11192C11.015 0.944398 11.255 0.851562 11.5039 0.851562C11.7528 0.851563 11.9928 0.944398 12.1769 1.11192L22.5039 10.4999H19.5039V19.4999Z"
        fill="rgba(65, 54, 54, 0.85)"
      />
    </svg>
  );
};
export default Home;
