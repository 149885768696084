import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  Card,
  Input,
  Space,
  Select,
  message,
  Image
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import moment from 'moment';
import truck from '@/features/qa/components/images/truck-delivery-done.gif';
import Layout from '@/common/Layout';
import { v4 as uuidv4 } from 'uuid';
import {
  useGetTemperatureAreaMastersQuery,
  useGetHygrometerIdMastersQuery,
  useLazyGetAlreadyExistingTrhmrLogBooksQuery,
  useInitiateVerificationMutation,
  useCreateTrhmrLogBookMutation
} from '../../redux/apiSlice';

import LiveTimeInput from '../../../../common/Clock';
import CustomModal from '../../../../common/CustomModal';

const createProductNewPage = () => {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();

  // #region Initial states - Start
  const [truckShow] = useState(false);
  const [search, setSearch] = useState('');
  const [timePeriods, setTimePeriods] = useState([
    {
      id: 1,
      label: '09:00 to 11:00',
      value: '09:00 to 11:00',
      disabled: false,
      sequence: 1
    },
    {
      id: 2,
      label: '15:00 to 17:00',
      value: '15:00 to 17:00',
      disabled: false,
      sequence: 2
    }
  ]);
  const [thermoHygrometerIdMasterId, setThermoHygrometerIdMasterId] =
    useState('');
  const [areaNameMasterId, setAreaNameMasterId] = useState('');
  const [verificationButtonDisabled, setVerificationButtonDisabled] =
    useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [formText, setFormText] = useState('');
  const [isHidden, setIsHidden] = useState(true);
  const [isModalSubmitDisabled, setIsModalSubmitDisabled] = useState(false);
  const [uniqueId] = useState(uuidv4());
  const [messageApi, contextHolder] = message.useMessage();
  const [isModal, setIsModal] = useState(false);
  const [logBooks, setLogBooks] = useState(false);
  // #endregion Initial states - End

  // #region Showing success or error message - Start
  const handleShowMessage = (messageType, messageText) => {
    messageApi.open({
      type: messageType,
      content: messageText
    });
  };
  // #endregion Showing success or error message - End

  // #region Initiate Verification Modal functionality - Start
  const [initiateVerification] = useInitiateVerificationMutation();

  const handleModel = async () => {
    await form.validateFields();
    setIsModal(true);
  };
  const handleClose = () => {
    setIsModal(false);
  };
  const onFinishModal = async () => {
    try {
      setIsModalSubmitDisabled(true);
      const values = await form2.validateFields();
      form.setFieldsValue({
        verified_by_user_id: values.email,
        remarks: values.remarks2,
        common_unique_id: uniqueId
      });
      const response = await initiateVerification({
        ...values,
        common_unique_id: uniqueId,
        form: 'trhmr'
      });
      if (response?.data?.success === true) {
        form2.resetFields();
        setIsModal(false);
        setVerificationButtonDisabled(true);
        setIsHidden(false);
        setSubmitButtonDisabled(false);
      } else {
        console.log(response);
        handleShowMessage('error', response?.error?.data?.message);
        setIsModalSubmitDisabled(false);
      }
    } catch (info) {
      console.log('Validate Failed:', info);
    }
  };
  // #endregion Initiate Verification Modal functionality - Ends

  // #region Fetching Temperature Area Masters -Start
  let { data: temperatureAreaMasters, refetch: temperatureAreaMastersRefetch } =
    useGetTemperatureAreaMastersQuery({
      search
    });
  // #endregion Fetching Temperature Area Masters -End

  // #region Fetching Thermo Hygrometer Id Masters - Start
  let {
    data: thermorHygrometerIdMasters,
    refetch: thermorHygrometerIdMastersRefetch
  } = useGetHygrometerIdMastersQuery({
    search
  });
  // #endregion Fetching Thermo Hygrometer Id Masters - End

  // #region Displaying already existing Trhmr logbooks functionality - Start
  let [
    triggerGetAlreadyExistingTrhmrLogBooksQuery,
    { data: existingTrhmrLogbooks }
  ] = useLazyGetAlreadyExistingTrhmrLogBooksQuery();

  useEffect(async () => {
    if (areaNameMasterId && thermoHygrometerIdMasterId) {
      const modifiedTimePeriods = [...timePeriods];
      modifiedTimePeriods[0].disabled = false;
      modifiedTimePeriods[1].disabled = false;
      setTimePeriods(modifiedTimePeriods);
      const response = await triggerGetAlreadyExistingTrhmrLogBooksQuery({
        areaNameMasterId,
        thermoHygrometerIdMasterId
      });
      if (response?.data?.data === null) {
        setLogBooks(false);
      } else {
        setLogBooks(true);
        response?.data?.data?.trhmr_log_book_details?.map((obj) => {
          if (
            obj.period === '09:00 to 11:00' ||
            obj.period === '9:00 to 11:00'
          ) {
            const modifiedTimePeriods = [...timePeriods];
            modifiedTimePeriods[0].disabled = true;
            setTimePeriods(modifiedTimePeriods);
          } else if (obj.period === '15:00 to 17:00') {
            const modifiedTimePeriods = [...timePeriods];
            modifiedTimePeriods[1].disabled = true;
            setTimePeriods(modifiedTimePeriods);
          }
        });
      }
    }
  }, [areaNameMasterId, thermoHygrometerIdMasterId]);
  // #endregion Displaying already existing Trhmr logbooks functionality - End

  // #region Create Trhmr logbook - Start
  const [createTrhmrLogBook] = useCreateTrhmrLogBookMutation();

  const onFinish = async (values) => {
    setSubmitButtonDisabled(true);
    const response = await createTrhmrLogBook({
      ...values,
      common_unique_id: uniqueId,
      records: [
        {
          ...values
        }
      ]
    });
    if (response?.data?.success === true) {
      handleShowMessage('success', response?.data?.message);
      setTimeout(() => history.push('/log-books-trhmr-list'), 1500);
    } else {
      setSubmitButtonDisabled(false);
      handleShowMessage('error', response?.error?.data?.message);
    }
  };
  // #endregion Create Trhmr logbook - End

  // #region On Change - Start
  const onChange = async (e, name) => {
    if (name === 'area_name') {
      setShowForm(false);
      setAreaNameMasterId(e);
      const areaNameArray = temperatureAreaMasters?.data?.filter(
        (a) => a.id == e
      );
      form.setFieldsValue({
        temperature_limit: areaNameArray?.[0]?.temperature_limit,
        relative_humidity_limit: areaNameArray?.[0]?.relative_humidity
      });
    } else if (name === 'thermo_hygrometer') {
      setShowForm(false);
      setThermoHygrometerIdMasterId(e);
      const hygrometersArray = thermorHygrometerIdMasters?.data?.filter(
        (a) => a.id == e
      );

      form.setFieldsValue({
        hygrometer_calibration: hygrometersArray?.[0]
          ?.hygrometer_calibration_due_date
          ? moment(hygrometersArray[0].hygrometer_calibration_due_date).format(
              'DD-MMM-YYYY'
            )
          : ''
      });
    } else if (name === 'period') {
      const timePeriodsArray = timePeriods?.filter((a) => a.value == e);
      form.setFieldsValue({
        sequence: timePeriodsArray?.[0]?.sequence
      });
      if (timePeriodsArray?.[0]?.value === '09:00 to 11:00') {
        setFormText('Reading between 09:00 to 11:00');
      } else {
        setFormText('Reading between 15:00 to 17:00');
      }
      setShowForm(true);
    }
  };
  // #endregion On Change - End

  // #region On Search - Start
  const onSearch = (e, name) => {
    if (e.length === 0 || e.length >= 3) {
      if (name === 'area_name') {
        setSearch(e);
        temperatureAreaMastersRefetch();
      } else if (name === 'thermo_hygrometer') {
        setSearch(e);
        thermorHygrometerIdMastersRefetch();
      }
    }
  };
  // #endregion

  return (
    <Space>
      {contextHolder}
      <Layout sider={false}>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Card
            title={'TEMPERATURE AND RELATIVE HUMIDITY MONITORING RECORD'}
            className={styles.CustomPanel}
          >
            <Row gutter={24}>
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label="Area Name"
                  name="areaNameMasterId"
                  rules={[
                    {
                      required: true,
                      message: 'Select Area Name'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Select
                    placeholder="Select Area Name"
                    showSearch
                    optionFilterProp="children"
                    onSearch={(e) => onSearch(e, 'area_name')}
                    onChange={(e) => onChange(e, 'area_name')}
                    disabled={verificationButtonDisabled}
                  >
                    {temperatureAreaMasters?.data?.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.areaName}
                        </option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Thermo-Hygrometer ID No."
                  name="thermoHygrometerIdMasterId"
                  rules={[
                    {
                      required: true,
                      message: 'Select Thermo-Hygrometer ID No.'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Select
                    placeholder="Select Thermo-Hygrometer ID No."
                    showSearch
                    optionFilterProp="children"
                    onSearch={(e) => onSearch(e, 'thermo_hygrometer')}
                    onChange={(e) => onChange(e, 'thermo_hygrometer')}
                    disabled={verificationButtonDisabled}
                  >
                    {thermorHygrometerIdMasters?.data?.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.thermo_hygrometer_id}
                        </option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Temperature Limit (&deg;C)"
                  name="temperature_limit"
                  rules={[
                    {
                      required: true,
                      message: ''
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label="Hygrometer Calibration Due Date"
                  name="hygrometer_calibration"
                  rules={[
                    {
                      required: false,
                      message: 'Select Effective Date'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Select Effective Date"
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Relative Humidity (&deg;C)"
                  name="relative_humidity_limit"
                  rules={[
                    {
                      required: true,
                      message: 'Select Relative Humidity'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label="Time"
                  name="period"
                  className={styles.customLable}
                >
                  <Select
                    placeholder="Select A Time"
                    optionFilterProp="children"
                    onChange={(e) => onChange(e, 'period')}
                    disabled={verificationButtonDisabled}
                  >
                    {timePeriods?.map((item, index) => {
                      return (
                        <option
                          value={item.value}
                          key={index}
                          disabled={item.disabled}
                        >
                          {item.label}
                        </option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={12} md={24} sm={24} hidden={true}>
                <FormItem
                  label="Sequence"
                  name="sequence"
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={12} md={24} sm={24} hidden={true}>
                <FormItem
                  label="Common unique id"
                  name="common_unique_id"
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
            </Row>
          </Card>
          <br />

          {logBooks ? (
            <Form form={form3} layout="vertical">
              {existingTrhmrLogbooks.data?.trhmr_log_book_details?.map(
                (obj, index) => (
                  <>
                    <Card
                      title={`Reading between ${obj.period}`}
                      className={styles.CustomPanel}
                    >
                      <Row gutter={24}>
                        <Col xs={24} xl={12} span={12} md={24} sm={24}>
                          <FormItem
                            label="Date"
                            name={`date${index}`}
                            className={styles.customLable}
                          >
                            <Input
                              defaultValue={moment(obj.verified_by_date).format(
                                'DD-MMM-YYYY'
                              )}
                              disabled={true}
                            />
                          </FormItem>
                        </Col>
                        <Col xs={24} xl={12} span={12} md={24} sm={24}>
                          <FormItem
                            label="Time"
                            name={`time${index}`}
                            className={styles.customLable}
                          >
                            <Input
                              defaultValue={moment(obj.verified_by_date).format(
                                'HH:mm'
                              )}
                              disabled={true}
                            />
                          </FormItem>
                        </Col>
                        <Col xs={24} xl={12} span={24} md={24} sm={24}>
                          <FormItem
                            label="Temperature Minimum (&deg;C)"
                            name={`temperature_minimum${index}`}
                            className={styles.customLable}
                          >
                            <Input
                              defaultValue={obj.temperature_minimum}
                              disabled={true}
                            />
                          </FormItem>
                        </Col>
                        <Col xs={24} xl={12} span={24} md={24} sm={24}>
                          <FormItem
                            label="Temperature Maximum (&deg;C)"
                            name={`temperature_maximum${index}`}
                            className={styles.customLable}
                          >
                            <Input
                              defaultValue={obj.temperature_maximum}
                              disabled={true}
                            />
                          </FormItem>
                        </Col>
                        <Col xs={24} xl={12} span={24} md={24} sm={24}>
                          <FormItem
                            label="Temperature (&deg;C)"
                            name={`temperature${index}`}
                            className={styles.customLable}
                          >
                            <Input
                              defaultValue={obj.temperature}
                              disabled={true}
                            />
                          </FormItem>
                        </Col>
                        <Col xs={24} xl={12} span={24} md={24} sm={24}>
                          <FormItem
                            label="Relative Humidity Minimum (&deg;C)"
                            name={`relative_humidity_minimum${index}`}
                            className={styles.customLable}
                          >
                            <Input
                              defaultValue={obj.relative_humidity_minimum}
                              disabled={true}
                            />
                          </FormItem>
                        </Col>
                        <Col xs={24} xl={12} span={24} md={24} sm={24}>
                          <FormItem
                            label="Relative Humidity Maximum (&deg;C)"
                            name={`relative_humidity_maximum${index}`}
                            className={styles.customLable}
                          >
                            <Input
                              defaultValue={obj.relative_humidity_maximum}
                              disabled={true}
                            />
                          </FormItem>
                        </Col>
                        <Col xs={24} xl={12} span={24} md={24} sm={24}>
                          <FormItem
                            label="Relative Humidity (&deg;C)"
                            name={`relative_humidity${index}`}
                            className={styles.customLable}
                          >
                            <Input
                              defaultValue={obj.relative_humidity}
                              disabled={true}
                            />
                          </FormItem>
                        </Col>
                        <Col xs={24} xl={12} span={24} md={24} sm={24}>
                          <FormItem
                            label="Verified By (User id & Date)"
                            name={`verified_by_user_id${index}`}
                            className={styles.customLable}
                          >
                            <Input
                              defaultValue={`${
                                obj.verified_by_user_id
                              } & ${moment(obj.verified_by_date).format(
                                'DD-MMM-YYYY'
                              )}`}
                              disabled={true}
                            />
                          </FormItem>
                        </Col>
                        <Col xs={24} xl={12} span={24} md={24} sm={24}>
                          <FormItem
                            label="Remarks"
                            name={`remarks${index}`}
                            className={styles.customLable}
                          >
                            <Input.TextArea
                              defaultValue={obj.remarks}
                              disabled={true}
                            />
                          </FormItem>
                        </Col>
                      </Row>
                    </Card>
                    <br />
                  </>
                )
              )}
            </Form>
          ) : (
            ''
          )}
          {showForm ? (
            <>
              <Card title={formText} className={styles.CustomPanel}>
                <Row gutter={24}>
                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="Date"
                      name="date"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: 'Date'
                      //   }
                      // ]}
                      className={styles.customLable}
                    >
                      <LiveTimeInput valueType={'date'} />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="Time"
                      name="time"
                      className={styles.customLable}
                    >
                      <LiveTimeInput valueType={'time'} />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={24} md={24} sm={24}>
                    <FormItem
                      label="Temperature Minimum (&deg;C)"
                      name="temperature_minimum"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Temperature Minimum'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Enter Temperature Minimum"
                        disabled={verificationButtonDisabled}
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={24} md={24} sm={24}>
                    <FormItem
                      label="Temperature Maximum (&deg;C)"
                      name="temperature_maximum"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Temperature Maximum'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Enter Temperature Maximum"
                        disabled={verificationButtonDisabled}
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={24} md={24} sm={24}>
                    <FormItem
                      label="Temperature (&deg;C)"
                      name="temperature"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Temperature'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Enter Temperature"
                        disabled={verificationButtonDisabled}
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={24} md={24} sm={24}>
                    <FormItem
                      label="Relative Humidity Minimum (&deg;C)"
                      name="relative_humidity_minimum"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Relative Humidity Minimum'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Enter Relative Humidity Minimum"
                        disabled={verificationButtonDisabled}
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={24} md={24} sm={24}>
                    <FormItem
                      label="Relative Humidity Maximum (&deg;C)"
                      name="relative_humidity_maximum"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Relative Humidity Maximum'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Enter Relative Humidity Maximum"
                        disabled={verificationButtonDisabled}
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={24} md={24} sm={24}>
                    <FormItem
                      label="Relative Humidity (&deg;C)"
                      name="relative_humidity"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Relative Humidity'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Enter Relative Humidity"
                        disabled={verificationButtonDisabled}
                      />
                    </FormItem>
                  </Col>
                  <Col
                    xs={24}
                    xl={12}
                    span={24}
                    md={24}
                    sm={24}
                    hidden={isHidden}
                  >
                    <FormItem
                      label="Verified By (User id & Date)"
                      name="verified_by_user_id"
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Enter Verifier User Id"
                        disabled={verificationButtonDisabled}
                      />
                    </FormItem>
                  </Col>
                  <Col
                    xs={24}
                    xl={12}
                    span={24}
                    md={24}
                    sm={24}
                    hidden={isHidden}
                  >
                    <FormItem
                      label="Remarks"
                      name="remarks"
                      className={styles.customLable}
                    >
                      <Input.TextArea
                        placeholder="Enter remarks"
                        disabled={verificationButtonDisabled}
                      />
                    </FormItem>
                  </Col>
                </Row>
              </Card>
              <br />
              <FormItem className="text-center">
                <Space>
                  <CustomButton
                    className={styles.inwardButton}
                    htmlType="button"
                    type="primary"
                    onClick={() => handleModel()}
                    disabled={verificationButtonDisabled}
                  >
                    Initiate Verification
                  </CustomButton>
                  {truckShow ? (
                    <Image
                      src={truck}
                      preview={false}
                      style={{ width: '100px !important', height: '100px' }}
                    />
                  ) : (
                    <CustomButton
                      className={styles.inwardButton}
                      htmlType="submit"
                      type="primary"
                      onSubmit={(e) => onFinish(e)}
                      disabled={submitButtonDisabled}
                    >
                      Create
                    </CustomButton>
                  )}
                  <CustomButton
                    className={styles.inwardButton}
                    type="primary"
                    ghost
                    onClick={() => history.back()}
                  >
                    Back
                  </CustomButton>
                </Space>
              </FormItem>
            </>
          ) : (
            ''
          )}
          {isModal ? (
            <CustomModal
              title=""
              visible={isModal}
              width="50%"
              footer={null}
              className={styles.customModal}
              closable={false}
            >
              <div className={`${styles.table_row}`}>
                <Form form={form2} layout="vertical" onFinish={onFinishModal}>
                  <Card
                    title={'Initiate Verification'}
                    className={styles.CustomPanel}
                  >
                    <Row gutter={24}>
                      <Col xs={24} xl={12} span={24} md={24} sm={24}>
                        <FormItem
                          label="Verified By (User id)"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: 'Enter Verifier User Id'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Input placeholder="Enter Verifier User Id" />
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={12} span={24} md={24} sm={24}>
                        <FormItem
                          label="Password"
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: 'Enter Password'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Input.Password placeholder="Enter Password" />
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={12} span={24} md={24} sm={24}>
                        <FormItem
                          label="Remarks"
                          name="remarks2"
                          className={styles.customLable}
                        >
                          <Input.TextArea placeholder="Enter remarks" />
                        </FormItem>
                      </Col>
                    </Row>
                  </Card>

                  <br />
                  <FormItem className="text-center">
                    <Space>
                      <CustomButton
                        className={styles.inwardButton}
                        htmlType="submit"
                        type="primary"
                        size="medium"
                        disabled={isModalSubmitDisabled}
                      >
                        Submit
                      </CustomButton>
                      <CustomButton
                        className={styles.inwardButton}
                        onClick={handleClose}
                        type="primary"
                        ghost
                        size="medium"
                      >
                        Close
                      </CustomButton>
                    </Space>
                  </FormItem>
                </Form>
              </div>
            </CustomModal>
          ) : (
            ''
          )}
        </Form>
      </Layout>
    </Space>
  );
};
export default createProductNewPage;
