import React, { useState, useEffect } from 'react';
// import Layout from '@/common/Layout';
import { Form, Card, Space, Input } from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import { getAllInvoicedetails } from '../../redux/slice';
import CustomTable from 'common/Content/CustomTable';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import { useDispatch } from 'react-redux';
import moment from 'moment';

const { Search } = Input;

const FinanceUsersList = ({ loading }) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [alllInvoices, setAlllInvoices] = useState([]);
  const [pagination, setPagination] = useState({});

  useEffect(() => {
    handleGetAllInvoiceDetails();
  }, [page, search]);

  const handleGetAllInvoiceDetails = () => {
    const payload = { page: page, search: search };
    dispatch(getAllInvoicedetails(payload)).then((response) => {
      if (response?.payload?.success) {
        setAlllInvoices(response?.payload?.data);
        setPagination(response?.payload?.pagination);
      }
    });
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const columns = [
    {
      title: 'Invoice #',
      dataIndex: 'inv_num',
      key: 'inv_num',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return <a href={`/view-invoice/${record?.id}`}>{record?.inv_num}</a>;
      }
    },
    {
      title: 'Invoice Date',
      dataIndex: 'inv_date',
      key: 'inv_date',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return moment(record?.inv_date).format('DD MMM YYYY');
      }
    },
    {
      title: 'PO #',
      dataIndex: 'po_num',
      key: 'po_num',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'PO Date',
      dataIndex: 'po_date',
      key: 'po_date',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return moment(record?.po_date).format('DD MMM YYYY');
      }
    },
    {
      title: 'Product Batch #',
      dataIndex: 'product_batch',
      key: 'product_batch',
      ellipsis: true,
      width: '100px'
    }
  ];

  return (
    <>
      {/* <Layout sider={false}> */}
      <Form form={form} layout="vertical">
        <Card
          title={<span>Invoice Details</span>}
          className={`${styles.cardStyles_approver}`}
          extra={
            <Space>
              <Search
                onChange={(e) => handleChange(e)}
                className={`w-100 ${styles.searchbar}`}
                justify="start"
                align="start"
                placeholder="Search by Invoice #"
              />
              <CustomButton
                className={styles.inwardButton}
                onClick={() => history.push('/create-invoice')}
                type="primary"
              >
                Create
              </CustomButton>
            </Space>
          }
        >
          <div className={`${styles.table_container}`}>
            <CustomTable
              columns={columns}
              pagination={{
                size: 'small',
                total: pagination?.total_records || 0,
                showSizeChanger: false,
                onChange: (page) => {
                  setPage(page);
                }
              }}
              dataSource={alllInvoices}
              loading={loading}
            />
          </div>
        </Card>
        <br />
        <FormItem className="text-center">
          <CustomButton
            className={styles.inwardButton}
            onClick={() => history.back()}
            type="primary"
          >
            Back to Home
          </CustomButton>
        </FormItem>
      </Form>
      {/* </Layout> */}
    </>
  );
};
export default FinanceUsersList;
