import React, { useEffect } from 'react';
import Layout from '@/common/Layout';
import { Row, Col, Form, Card, Input, InputNumber, message } from 'antd';
import styles from '@/common/Layout/index.module.less';
import { getDispenseMaterialDetails } from '@/features/dispensing/redux/slice';
import FormItem from '@/common/FormItem';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useState } from 'react';
import CustomButton from '@/common/CustomButton';
import { DeleteOutlined, PlusCircleFilled } from '@ant-design/icons';
import { createSampling } from '../../redux/slice';
import { history } from 'app/history';

const prodSampling = () => {
  const { materialDetails } = useSelector((state) => state.dispensing);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const params = useParams();
  const [blends, setBlends] = useState([
    {
      stage: '',
      sample_type: '',
      sample_qty: ''
    }
  ]);

  const [totalNet, setTotalNet] = useState(0);

  useEffect(() => {
    handleGetAllMaterials();
  }, []);

  const handleGetAllMaterials = () => {
    let payload = {
      id: params.id
    };
    dispatch(getDispenseMaterialDetails(payload));
  };

  const handleAddWeight = () => {
    let temp_lots = [...blends];
    temp_lots.push({
      stage: '',
      sample_type: '',
      sample_qty: ''
    });
    setBlends(temp_lots);
  };

  const handleremoveWeight = (i) => {
    let temp_lots = [...blends];
    temp_lots.splice(i, 1);
    setBlends(temp_lots);
  };

  const handleChange = (type, i, e) => {
    let temp_lots = [...blends];
    temp_lots[i][type] = e;
    setBlends(temp_lots);
    var add = 0;
    blends.map((item) => {
      add += item?.sample_qty !== '' ? item?.sample_qty : 0;
    });
    setTotalNet(add.toFixed(3));
  };
  console.log(blends);
  const onFinish = () => {
    const payload = {
      dispensing_request_id: params.id,
      total_sample_qty: totalNet,
      sampling_material: blends
    };
    dispatch(createSampling(payload)).then((response) => {
      if (response?.payload?.success) {
        message.success(response?.payload.message);
        history.push(`/yield-reconcilation/${params.id}`);
      }
    });
  };
  return (
    <Layout sider={false}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
      >
        <Card
          title={<span style={{ fontWeight: 'bold' }}>Sampling</span>}
          className={styles.CustomPanel}
        >
          <Card>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem label="Product Name" className={styles.customLable}>
                  <Input
                    disabled
                    value={materialDetails?.product_detail?.name || 'NA'}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="Product Batch Number"
                  className={styles.customLable}
                >
                  <Input
                    disabled
                    value={materialDetails?.product_batch_number || 'NA'}
                  />
                </FormItem>
              </Col>

              <Col span={12}>
                <FormItem label="Effective Date" className={styles.customLable}>
                  <Input
                    disabled
                    value={
                      materialDetails?.effective_date
                        ? moment(materialDetails?.effective_date).format(
                            'DD MMM YYYY'
                          )
                        : 'NA'
                    }
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="Batch Size" className={styles.customLable}>
                  <Input
                    disabled
                    value={materialDetails?.product_detail?.batch_size || 'NA'}
                  />
                </FormItem>
              </Col>
            </Row>
          </Card>
          <Card title={`Sampling `}>
            <Row gutter={24}>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Total Sample Qty"
                  rules={[
                    {
                      required: false,
                      message: 'Enter Total Sample Qty'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter Total  Sample Qty"
                    disabled
                    value={totalNet}
                  />
                </FormItem>
              </Col>
            </Row>
            {blends?.map((data, i) => {
              return (
                <>
                  <Row gutter={24} key={i}>
                    <Col xs={24} xl={8} span={24} md={24} sm={24}>
                      <FormItem
                        label="Stage"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Stage'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter Stage"
                          value={data?.stage || ''}
                          onChange={(e) =>
                            handleChange('stage', i, e.target.value)
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={8} span={24} md={24} sm={24}>
                      <FormItem
                        label="Sample Type"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Sample Type'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          style={{ width: '100%' }}
                          placeholder="Enter Sample Type"
                          value={data?.sample_type || ''}
                          onChange={(e) =>
                            handleChange('sample_type', i, e.target.value)
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={6} span={24} md={24} sm={24}>
                      <FormItem
                        label="Sample Qty"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Sample Qty'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <InputNumber
                          style={{ width: '100%' }}
                          min={0}
                          placeholder="Enter Sample Qty"
                          value={data?.sample_qty || ''}
                          onChange={(e) => handleChange('sample_qty', i, e)}
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={2} span={24} md={24} sm={24}>
                      <FormItem label="" className={styles.customLableButton}>
                        {i == 0 ? (
                          <CustomButton onClick={() => handleAddWeight(i)}>
                            <PlusCircleFilled />
                          </CustomButton>
                        ) : (
                          ''
                        )}
                        {i > 0 ? (
                          <CustomButton onClick={() => handleremoveWeight(i)}>
                            <DeleteOutlined />
                          </CustomButton>
                        ) : (
                          ''
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </>
              );
            })}
          </Card>
        </Card>
        <br />
        <FormItem className="text-center">
          <CustomButton
            className={styles.inwardButton}
            htmlType="submit"
            type="primary"
          >
            Save & Proceed
          </CustomButton>
        </FormItem>
      </Form>
    </Layout>
  );
};
export default prodSampling;
