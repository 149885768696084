import React, { useEffect } from 'react';
import styles from './index.module.less';
import { Card, Row, Radio, Space, Empty, Col, Input, Tag } from 'antd';
import CustomTable from 'common/Content/CustomTable';
import moment from 'moment';
import CustomButton from 'common/CustomButton';
import { CloudDownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMaterials } from '../../redux/slice';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
// import CustomTable from 'common/Content/CustomTable';

const { Search } = Input;
const filters = [
  {
    name: 'Vehicle Storage Area',
    value: 'doc_ver_approved'
  },
  {
    name: 'Material Details',
    value: 'veh_ver_approved'
  },
  {
    name: 'Batch Details',
    value: 'inv_ver_approved'
  }
  // {
  //   name: 'Damage Report',
  //   value: 'inv_ver_approved1'
  // },
  // {
  //   name: 'Weighing Report',
  //   value: 'inv_ver_approved2'
  // }
];
const UpnextReceiptsPage = ({ setTriggerFilter }) => {
  const searchParam = useLocation().search;
  const searchParams = new URLSearchParams(searchParam);
  const { allMaterials, pagination, getAllMaterilLoading } = useSelector(
    (state) => state.material
  );

  const [radiofilter, setRadiofilter] = useState(
    `${searchParams.get('material_status')}`
  );
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    handleGetAllMaterials();
  }, [page, search, radiofilter]);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const handleGetAllMaterials = () => {
    let payload = {
      search: search,
      page,
      material_status: `${searchParams.get('material_status')}`
    };
    dispatch(getAllMaterials(payload));
  };

  const columns = [
    {
      title: 'Invoice no',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      ellipsis: true,
      width: '100px',
      sorter: (a, b) => a?.invoice_number?.localeCompare(b?.invoice_number),
      render: (text, record) => {
        return (
          <a
            href={
              record?.material_status === 'doc_ver_approved'
                ? `vehicle-storage/${record.id}`
                : record?.material_status === 'veh_ver_approved'
                ? `invoice-details/${record.id}`
                : record?.material_status === 'inv_ver_approved'
                ? `batch-details/${record.id}`
                : record?.material_status === 'dmg_ver_approved'
                ? `batch-details/${record.id}`
                : record?.material_status === 'wh_ver_approved'
                ? `generate-mrn/${record.id}`
                : '#'
            }
            onClick={() => {
              localStorage.setItem('invoice_num', record.invoice_number),
                localStorage.setItem('material_name', record?.material?.name),
                localStorage.setItem('material_code', record?.material?.code);
            }}
          >
            {text}
          </a>
        );
      },
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Date of Receipts',
      dataIndex: 'receipt_date',
      key: 'receipt_date',
      align: 'center',
      width: '100px',
      sorter: (a, b) =>
        moment(a[`receipt_date`]).unix() - moment(b[`receipt_date`]).unix(),
      render: (receipt_date) => (
        <>{moment(receipt_date).format('DD MMM YYYY')}</>
      ),
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Up next',
      dataIndex: 'up_next',
      key: 'up_next',
      width: 150,
      ellipsis: true,
      sorter: (a, b) => a?.up_next?.localeCompare(b?.up_next),
      render: (text, record) => {
        return record?.material_status == 'doc_ver_approved' ||
          record?.material_status == 'doc_ver_pending'
          ? 'Vehicle Storage Area'
          : record?.material_status == 'veh_ver_approved' ||
            record?.material_status == 'veh_ver_pending'
          ? 'Invoice Details'
          : record?.material_status == 'inv_ver_approved' ||
            record?.material_status == 'inv_ver_pending'
          ? 'Batch Details'
          : record?.material_status == 'dmg_ver_approved' ||
            record?.material_status == 'dmg_ver_pending'
          ? 'Weighing Report'
          : record?.material_status == 'wh_ver_approved' ||
            record?.material_status == 'wh_ver_pending'
          ? 'Mrn generation'
          : 'Document Verification';
      },
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 120,
      ellipsis: true,
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
      render: (text, record) => {
        return (
          <Tag color={record?.qa_status === null ? '#28a745' : '#2db7f5'}>
            {record?.qa_status === null ? 'QA APPROVED' : 'QA PENDING'}
          </Tag>
        );
      },
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    }
  ];
  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        <div className={styles.empty_state_text}>
          There are no material requests to display
        </div>
      </span>
    )
  };
  return (
    <>
      <h2 className={`${styles.page_title}`}>Material Receipts</h2>
      <h4 className={`${styles.page_sub_title}`}>
        View all material receipt notes along with weighing and damage details
      </h4>
      {/* <br /> */}
      <Card
        className={styles.CustomPanel}
        title="Receipts"
        extra={
          <Space size={8}>
            {/* <CustomButton
                    size="small"
                    icon={<CloudUploadOutlined />}
                    className={`${styles.customButton_style_secondary}`}
                    type="primary"
                  >
                    <span>Import</span>
                  </CustomButton> */}
            <CustomButton
              size="small"
              icon={<CloudDownloadOutlined />}
              className={`${styles.customButton_style_secondary}`}
              type="primary"
            >
              <span>Export report</span>
            </CustomButton>
            <Link to="/create">
              <CustomButton
                size="small"
                icon={<PlusOutlined />}
                className={`${styles.customButton_style_secondary}`}
                type="primary"
                style={{ backgroundColor: '#c91761', color: '#fff' }}
              >
                <span>I want to Inward material</span>
              </CustomButton>
            </Link>
          </Space>
        }
      >
        <Space size={9} direction="vertical" className={styles.searchwidth}>
          {/* <Row
            gutter={16}
            style={{
              marginTop: '-7px',
              marginRight: '0px',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            {/* <Col span={12}>
              <div className={`${styles.Gate_pass_req_title}`}>Receipts </div>
              <div className={`${styles.Gate_pass_req_sub_title}`}>
                Keep track of your recent material requests
              </div>
            </Col> 
          </Row> */}
          {/* <Divider /> */}
          <Row gutter={24}>
            <Col span={18}>
              <Radio.Group
                value={radiofilter}
                size="small"
                className={styles.button_import_style}
              >
                {filters.map((item, index) => {
                  return (
                    <Radio.Button
                      value={item.value}
                      key={index}
                      className={`${styles.RadioButton_import_style}`}
                      onChange={() => {
                        window.localStorage.setItem(
                          'set_Radiofilter',
                          JSON?.stringify(item.value)
                        );
                        setRadiofilter(item.value);
                        setTriggerFilter(true);
                      }}
                    >
                      {item.name}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            </Col>
            <Col span={6}>
              <Search
                onChange={(e) => handleChange(e)}
                className={`w-100 ${styles.searchbar}`}
                justify="start"
                align="start"
                inputProps={{
                  placeholder: 'Search by gate pass request#'
                }}
              />
            </Col>
          </Row>
        </Space>
        <div className={`${styles.table_container}`}>
          <CustomTable
            locale={locale}
            loading={getAllMaterilLoading}
            columns={columns}
            dataSource={allMaterials}
            pagination={{
              size: 'small',
              showSizeChanger: false,
              total: pagination?.total_records || 0,
              onChange: (page) => {
                setPage(page);
              }
            }}
            // className={styles.scrolling}
            scroll={{ x: 'auto' }}
          />
        </div>
      </Card>
    </>
  );
};

export default UpnextReceiptsPage;
