import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Card,
  Input,
  Space,
  Select,
  Radio,
  Image,
  message,
  Checkbox,
  InputNumber
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import { useSelector, useDispatch } from 'react-redux';
// import { DeleteOutlined, PlusCircleFilled } from '@ant-design/icons';
import truck from '@/features/qa/components/images/truck-delivery-done.gif';
import Layout from '@/common/Layout';
import { getAllMaterialslist } from '@/features/stockCard/redux/slice';
import { getAllStags, CreateNewBMR } from '../../redux/slice';
import { useParams } from 'react-router-dom';

const CreateBMR = () => {
  const { allMaterials } = useSelector((state) => state.stocks);
  const [form] = Form.useForm();
  const params = useParams();

  const dispatch = useDispatch();

  const [parentBMR, setParentBMR] = useState(false);
  const [search, setSearch] = useState('');
  const [code, setCode] = useState('');
  const [truckShow, setTruckShow] = useState(false);
  const [lots, setLots] = useState(0);
  const [parts, setParts] = useState(0);
  const [stage, setStage] = useState(0);
  const [data, setData] = useState('');
  console.log(setTruckShow);
  const [stages, setStages] = useState([]);
  const [parentYield, setParentYield] = useState('');

  const handleBMR = (e) => {
    console.log(e);
    e.target.value == 'Yes' ? setParentBMR(true) : setParentBMR(false);
  };

  const handleYield = (e) => {
    console.log(e);
    e.target.value == 'Yes' ? setParentYield(true) : setParentYield(false);
  };

  console.log(lots, parts);

  const onSearch = (value) => {
    setSearch(value);
  };

  useEffect(() => {
    handleGetAllMaterialsList();
  }, [search, code, stage]);

  useEffect(() => {
    handleGetAllStages();
  }, []);
  const handleGetAllMaterialsList = () => {
    const payload = { search };
    dispatch(getAllMaterialslist(payload));
  };
  console.log(data, 'dataaaa');
  const handleGetAllStages = () => {
    const payload = { id: params.id };
    dispatch(getAllStags(payload)).then((response) => {
      if (response?.payload?.success) {
        setData(response?.payload?.data);
        const stage_groups = [];
        response?.payload?.data?.stage_groups.map((item) => {
          stage_groups.push({
            materials: [
              {
                stage_name: item?.stage_name,
                stage_group_id: item?.id,
                material_id: '',
                supplier_id: '',
                quantity_for_batch: '',
                lots: [],
                stage: '',
                dispensing_required: false,
                // is_extra: false,
                compensation_material: false,
                qty_dispensed: '',
                part_qty: '',
                lot_qty: '',
                is_excess: false
              }
            ]
          });
        });
        setStages(stage_groups);
      }
    });
  };

  const onChange = (value, index, i) => {
    const material = allMaterials.filter(
      (MaterialsList) => MaterialsList.id === value
    );
    const list = [...stages];
    list[index].materials[i]['material_id'] = material[0]?.id;
    list[index].materials[i]['supplier_id'] = material[0]?.suppliers[0]?.id;
    list[index].materials[i]['supplier_name'] =
      material[0]?.suppliers[0]?.manufacturer_name;
    list[index].materials[i]['material_name'] = material[0]?.name;
    list[index].materials[i]['uom'] = material[0]?.uom;
    list[index].materials[i]['compensation_material'] =
      material[0]?.compensation_material == 'Yes' ? true : false;
    setStages(list);
    // form.setFieldsValue({ material_name: material[0]?.name });
    setCode(material[0]);
  };

  console.log(stages);
  const handleQuantityChange = (e, index, i) => {
    const list = [...stages];
    list[index].materials[i]['quantity_for_batch'] = e.target.value;
    setStages(list);
  };

  const onChangeCheck = (e, index, i) => {
    console.log(e);
    const list = [...stages];

    list[index].materials[i]['dispensing_required'] = e.includes('Dispensing');
    list[index].materials[i]['compensation_material'] =
      e.includes('Compensatory');
    list[index].materials[i]['is_api'] = e.includes('API');
    list[index].materials[i]['is_assay'] = e.includes('Assay');
    list[index].materials[i]['is_extra'] = e.includes('is_extra');
    list[index].materials[i]['is_excess'] = e.includes('Excess');

    setStages(list);
    console.log(e, index, i);
  };

  const handleLotChange = (e, index, i, j) => {
    const list = [...stages];
    console.log(e.target.value);
    list[index].materials[i].lots[j]['lot'] = e.target.value;

    setStages(list);
  };
  const handlePartChange = (e, index, i, j, k) => {
    console.log(e, index, i, j);
    const list = [...stages];
    parts == 1
      ? (list[index].materials[i].lots[j]['parts'] = [
          {
            part:
              k == 0
                ? e.target.value
                : list[index].materials[i].lots[j]['parts'][0]['part']
          }
        ])
      : parts == 2
      ? (list[index].materials[i].lots[j]['parts'] = [
          {
            part:
              k == 0
                ? e.target.value
                : list[index].materials[i].lots[j]['parts'][0]['part']
          },
          {
            part:
              k == 1
                ? e.target.value
                : list[index].materials[i].lots[j]['parts'][1]['part']
          }
        ])
      : parts == 3
      ? (list[index].materials[i].lots[j]['parts'] = [
          {
            part:
              k == 0
                ? e.target.value
                : list[index].materials[i].lots[j]['parts'][0]['part']
          },
          {
            part:
              k == 1
                ? e.target.value
                : list[index].materials[i].lots[j]['parts'][1]['part']
          },
          {
            part:
              k == 2
                ? e.target.value
                : list[index].materials[i].lots[j]['parts'][2]['part']
          }
        ])
      : (list[index].materials[i].lots[j]['parts'] = [
          {
            part:
              k == 0
                ? e.target.value
                : list[index].materials[i].lots[j]['parts'][0]['part']
          },
          {
            part:
              k == 1
                ? e.target.value
                : list[index].materials[i].lots[j]['parts'][1]['part']
          },
          {
            part:
              k == 2
                ? e.target.value
                : list[index].materials[i].lots[j]['parts'][2]['part']
          },
          {
            part:
              k == 3
                ? e.target.value
                : list[index].materials[i].lots[j]['parts'][3]['part']
          }
        ]);
    // setNewmaterials(list);

    setStages(list);
    console.log(stages);
  };
  console.log(stages, 'stagess');
  const handlelotsChange = (e, index, i) => {
    console.log(e.target.value, index, i);
    // console.log(total_qty);
    const list = [...stages];
    setLots(e.target.value);
    let lots = [];
    for (var j = 0; j < e.target.value; j++) {
      lots.push({ lot: '', parts: [] });
    }
    console.log(lots);
    list[index].materials[i].lot_qty = e.target.value;
    list[index].materials[i].lots = lots;
    console.log(stages);
    setStages(list);
  };

  const handlepartsChange = (e, index, i) => {
    console.log(e, index, i);
    setParts(e.target.value);
    const list = [...stages];
    let parts = [];
    for (var j = 0; j < e.target.value; j++) {
      parts.push({ part: '' });
    }
    list[index].materials[i].lots.map((item) => {
      item.parts = parts;
    });
    list[index].materials[i].part_qty = e.target.value;
    setStages(list);
  };

  const handleAdd = (id, index) => {
    console.log(id);

    stages[index].materials.push({
      material_id: '',
      stage_group_id: id,
      supplier_id: '',
      quantity_for_batch: '',
      lots: [],
      stage: '',
      dispensing_required: false,
      // is_extra: false,
      compensation_material: false,
      qty_dispensed: '',
      part_qty: '',
      lot_qty: '',
      excess_qty: 0,
      is_excess: false
    });
    setStage(stages[index].materials.length + 1);
    console.log(stages, 'AAAAA');
  };
  console.log(stages, 'AAAAA');
  // const handleYieldChange = (e, index, i) => {
  //   const list = [...stages];
  //   list[index].materials[i]['standard_yield_qty'] = e.target.value;
  // };

  const handleExcessChange = (e, index, i) => {
    console.log(e);
    const list = [...stages];
    list[index].materials[i]['excess_qty'] = e || 0;
    setStages(list);
  };

  const calculateQty = (index, i) => {
    console.log(stages[index].materials[i].excess_qty);
    let batch_qty = stages[index].materials[i].quantity_for_batch;
    console.log(batch_qty);
    let excess_qty = stages[index].materials[i].excess_qty || 0;
    let cal = (parseFloat(batch_qty) * parseFloat(excess_qty)).toFixed(3) / 100;
    let total_qty = parseFloat(batch_qty) + parseFloat(cal);
    let lot_qty = total_qty / lots;
    let part_qty = lot_qty / parts;
    const list = [...stages];
    list[index].materials[i].qty_dispensed = total_qty;
    list[index].materials[i].lots.map((item) => {
      item['lot'] = parseFloat(lot_qty).toFixed(3);
      item.parts.map((part) => {
        part['part'] = parseFloat(part_qty).toFixed(3);
      });
    });

    setStages(list);
    // list[index].lots.map((item, index, i) => {
    //   // var name = `lot${index}${i}`;
    //   form.setFieldsValue( {`lot${index}${i}`: 30} );
    // });

    console.log(stages, 'stages');
  };

  const handleDispensingSeq = (e, index, i) => {
    const list = [...stages];
    list[index].materials[i]['dispensing_sequence'] = e;
  };

  const list = [];

  for (let i = 1; i <= 30; i++) {
    list.push(i);
  }

  const onFinish = (values) => {
    values['is_parent_bmr'] = parentBMR;
    values['product_id'] = params.id;
    values['is_yield'] = parentYield;
    let materials = [];

    stages.map((item) => {
      item.materials.map((data) => {
        materials.push({
          material_id: data.material_id,
          supplier_id: data.supplier_id,
          quantity_for_batch: data.quantity_for_batch,
          stage_group: data.stage_group_id,
          standard_yield_qty: '',
          lots: data.lots,
          dispensing_required: data.dispensing_required,
          is_extra: data.is_extra,
          compensation_material: data.compensation_material,
          qty_dispensed: data.qty_dispensed,
          part_qty: data.part_qty,
          lot_qty: data.lot_qty,
          supplier_name: data.supplier_name,
          material_name: data.material_name,
          uom: data.uom,
          dispensing_sequence: data.dispensing_sequence,
          excess_qty: data.excess_qty,
          is_assay: data.is_assay,
          is_yield: data.is_yield,
          is_excess: data.is_excess,
          is_api: data.is_api
        });
      });
    });
    values['material_items'] = materials;
    console.log(materials);
    // materials.push({
    //   material_id: 78,
    //     supplier_id: 82,
    //     quantity_for_batch: '450.00',
    //     stage_group: '1',
    //     standard_yield_qty: '336.17'
    //   });
    // });
    // console.log(stages, 'hhhhhhh');
    // setTruckShow(false);

    dispatch(CreateNewBMR(values)).then((response) => {
      if (response?.payload?.success) {
        setTruckShow(false);
        message.success(response?.payload?.message);
        history.push(`/bmr-config/${params.id}`);
      } else {
        setTruckShow(false);
      }
    });
  };
  return (
    <Layout sider={false}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Card title={'ADD NEW BMR'} className={styles.CustomPanel}>
          <Row gutter={24}>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Product Name"
                rules={[
                  {
                    required: false,
                    message: 'Enter Theoretical Yield/Batch Size'
                  }
                ]}
                className={styles.customLable}
              >
                <Input value={data?.name} disabled />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={24} md={24} sm={24}>
              <FormItem
                label="BMR/BPR Number"
                rules={[
                  {
                    required: true,
                    message: 'Enter BMR/BPR number'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter detail name of the Product"
                  value={data?.bmr_bpr_code}
                  disabled
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Equipment Name"
                rules={[
                  {
                    required: false,
                    message: 'Equipment Name'
                  }
                ]}
                className={styles.customLable}
              >
                <Input value={data?.equipment_name} disabled />
              </FormItem>
            </Col>
            <Col xs={24} xl={6} span={12} md={24} sm={24}>
              <FormItem
                label="Is this BMR linked to a parent record?"
                name="parent_bmr"
                rules={[
                  {
                    required: true,
                    message: 'Select BMR'
                  }
                ]}
                className={styles.customLable}
              >
                <Radio.Group onChange={(e) => handleBMR(e)}>
                  <Radio value={'Yes'}>Yes</Radio>
                  <Radio value={'No'}>No</Radio>
                </Radio.Group>
              </FormItem>
            </Col>
            <Col xs={24} xl={7} span={12} md={24} sm={24}>
              <FormItem
                label="Does this product have stage wise yield?"
                name="stae_wise_yield"
                rules={[
                  {
                    required: true,
                    message: 'Select Yield'
                  }
                ]}
                className={styles.customLable}
              >
                <Radio.Group onChange={(e) => handleYield(e)}>
                  <Radio value={'Yes'}>Yes</Radio>
                  <Radio value={'No'}>No</Radio>
                </Radio.Group>
              </FormItem>
            </Col>
            {parentBMR ? (
              <Col xs={24} xl={11} span={12} md={24} sm={24}>
                <FormItem
                  label="Parent BMR"
                  name="parent_bmr"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Parent BMR'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input placeholder="Enter Parent BMR" />
                </FormItem>
              </Col>
            ) : (
              ''
            )}
          </Row>
        </Card>
        <br />
        {stages?.map((item, index) => {
          {
            console.log(item, 'itemmm');
          }
          return (
            <Card
              title={`Stage ${index + 1} : ${item?.materials[0]?.stage_name}`}
              className={styles.CustomPanel}
              key={index}
            >
              {item.materials?.map((material, i) => {
                console.log(material.is_excess, 'mmmmmmmmm');
                return (
                  <>
                    <Card key={i}>
                      <Row gutter={24}>
                        <Col xs={24} xl={3} span={12} md={24} sm={24}>
                          <FormItem
                            label="Material"
                            rules={[
                              {
                                required: true,
                                message: 'Material Code'
                              }
                            ]}
                            className={styles.customLable}
                          >
                            <Select
                              showSearch
                              placeholder="Select a material code"
                              optionFilterProp="children"
                              onChange={(e) => onChange(e, index, i)}
                              onSearch={onSearch}
                            >
                              {allMaterials?.map((item, index) => {
                                return (
                                  <option value={item.id} key={index}>
                                    {item.code}
                                  </option>
                                );
                              })}
                            </Select>
                          </FormItem>
                        </Col>
                        <Col xs={24} xl={2} span={12} md={24} sm={24}>
                          <FormItem
                            label="Std Qty"
                            rules={[
                              {
                                required: false,
                                message: 'Quantity for Batch'
                              }
                            ]}
                            className={styles.customLable}
                          >
                            <Input
                              placeholder="Enter Quantity for Batch"
                              onChange={(e) =>
                                handleQuantityChange(e, index, i)
                              }
                            />
                          </FormItem>
                        </Col>
                        <Col xs={24} xl={12} span={12} md={24} sm={24}>
                          <FormItem
                            rules={[
                              {
                                required: false,
                                message: 'Enter Quantity for Batch'
                              }
                            ]}
                            className={styles.customCheckBox}
                          >
                            <Checkbox.Group
                              style={{
                                width: '100%'
                              }}
                              onChange={(e) => onChangeCheck(e, index, i)}
                            >
                              <Row>
                                <Col span={5}>
                                  <Checkbox value="Dispensing">
                                    Dispensing
                                  </Checkbox>
                                </Col>
                                <Col span={4}>
                                  <Checkbox value="is_extra">Is Extra</Checkbox>
                                </Col>
                                <Col span={6}>
                                  <Checkbox value="Compensatory">
                                    Compensatory
                                  </Checkbox>
                                </Col>

                                <Col span={3}>
                                  <Checkbox value="API">API</Checkbox>
                                </Col>
                                <Col span={3}>
                                  <Checkbox value="Assay">Assay</Checkbox>
                                </Col>

                                <Col span={2}>
                                  <Checkbox value="Excess">Excess</Checkbox>
                                </Col>
                              </Row>
                            </Checkbox.Group>
                          </FormItem>
                        </Col>
                        <Col xs={24} xl={3} span={12} md={24} sm={24}>
                          <FormItem
                            label={`Excess Qty (%) `}
                            rules={[
                              {
                                required: false,
                                message: 'Enter Excess Quantity'
                              }
                            ]}
                            className={styles.customLable}
                          >
                            <InputNumber
                              min={0}
                              style={{
                                width: '100%'
                              }}
                              placeholder="Enter Excess Quantity"
                              onChange={(e) => handleExcessChange(e, index, i)}
                              disabled={!material?.is_excess}
                            />
                          </FormItem>
                        </Col>
                        {/* <Col xs={24} xl={12} span={12} md={24} sm={24}>
                          <FormItem
                            label="Standard Yield of this Stage"
                            rules={[
                              {
                                required: true,
                                message: 'Enter Standard Yield of this Stage'
                              }
                            ]}
                            className={styles.customLable}
                          >
                            <Input
                              placeholder="Enter Standard Yield of this Stage"
                              onChange={(e) => handleYieldChange(e, index, i)}
                            />
                          </FormItem>
                        </Col> */}
                        <Col xs={24} xl={2} span={12} md={24} sm={24}>
                          <FormItem
                            label="Sequence"
                            rules={[
                              {
                                required: false,
                                message: 'Select Dispensing Sequence'
                              }
                            ]}
                            className={styles.customLable}
                          >
                            <Select
                              showSearch
                              placeholder="Sequence"
                              optionFilterProp="children"
                              onChange={(e) => handleDispensingSeq(e, index, i)}
                            >
                              {list?.map((item, index) => {
                                return (
                                  <option value={item} key={index}>
                                    {item}
                                  </option>
                                );
                              })}
                            </Select>
                          </FormItem>
                        </Col>
                        {!material?.is_extra ? (
                          <>
                            <Col xs={24} xl={12} span={12} md={24} sm={24}>
                              <FormItem
                                label="How many lots?"
                                rules={[
                                  {
                                    required: false,
                                    message: 'Enter number of lots'
                                  }
                                ]}
                                className={styles.customLable}
                              >
                                <Input
                                  placeholder="Enter number of lots"
                                  onChange={(e) =>
                                    handlelotsChange(e, index, i)
                                  }
                                />
                              </FormItem>
                            </Col>
                            <Col xs={24} xl={12} span={12} md={24} sm={24}>
                              <FormItem
                                label="How many parts for each lot?"
                                rules={[
                                  {
                                    required: false,
                                    message: 'Enter number of parts'
                                  }
                                ]}
                                className={styles.customLable}
                              >
                                <Input
                                  placeholder="Enter number of parts"
                                  onChange={(e) =>
                                    handlepartsChange(e, index, i)
                                  }
                                />
                              </FormItem>
                            </Col>
                          </>
                        ) : (
                          <Col xs={24} xl={6} span={12} md={24} sm={24} key={i}>
                            <FormItem
                              label="Qty to Dispense"
                              rules={[
                                {
                                  required: false,
                                  message: 'Quantity to Dispense'
                                }
                              ]}
                              className={styles.customLable}
                            >
                              <Input
                                placeholder="Quantity to Dispense"
                                value={material?.qty_dispensed}
                              />
                            </FormItem>
                          </Col>
                        )}
                        {material?.lots.map((sub_item, j) => {
                          return (
                            <Col
                              xs={24}
                              xl={12}
                              span={12}
                              md={24}
                              sm={24}
                              key={i}
                            >
                              <FormItem
                                label={`Lot -${j + 1}`}
                                rules={[
                                  {
                                    required: false,
                                    message: 'Enter Lot Quantity'
                                  }
                                ]}
                                className={styles.customLable}
                              >
                                <Input
                                  placeholder="Enter Lot Quantity"
                                  onChange={(e) =>
                                    handleLotChange(e, index, i, j)
                                  }
                                  value={sub_item.lot}
                                />
                              </FormItem>
                              <Row gutter={24}>
                                {sub_item?.parts?.map((part, k) => {
                                  return (
                                    <Col
                                      xs={12}
                                      xl={12}
                                      span={12}
                                      md={24}
                                      sm={24}
                                      key={j}
                                    >
                                      <FormItem
                                        label={`Part -${k + 1}`}
                                        rules={[
                                          {
                                            required: false,
                                            message: 'Enter part Quantity'
                                          }
                                        ]}
                                        className={styles.customLable}
                                      >
                                        <Input
                                          placeholder="Enter part Quantity"
                                          onChange={(e) =>
                                            handlePartChange(e, index, i, j, k)
                                          }
                                          value={part.part}
                                        />
                                      </FormItem>
                                    </Col>
                                  );
                                })}
                              </Row>
                            </Col>
                          );
                        })}
                      </Row>
                      <FormItem className="text-center">
                        <Space size={5}>
                          <CustomButton
                            className={styles.inwardButton}
                            type="primary"
                            ghost
                            onClick={() => calculateQty(index, i)}
                          >
                            Calculate Lot/Part Quantities
                          </CustomButton>

                          {/* {index > 0 ? (
                      <CustomButton  onClick={() => handleRemove(index)}>
                        <DeleteOutlined />
                      </CustomButton >
                    ) : (
                      ''
                    )} */}
                        </Space>
                      </FormItem>
                    </Card>
                    <br />
                    {item?.materials?.length == i + 1 ? (
                      <FormItem className="text-right">
                        <p
                          onClick={() =>
                            handleAdd(material.stage_group_id, index)
                          }
                        >
                          Add Material
                        </p>
                      </FormItem>
                    ) : (
                      ''
                    )}
                  </>
                );
              })}
            </Card>
          );
        })}
        <br />
        <FormItem className="text-center">
          <Space>
            {truckShow ? (
              <Image
                src={truck}
                preview={false}
                style={{ width: '100px !important', height: '100px' }}
              />
            ) : (
              <CustomButton
                className={styles.inwardButton}
                htmlType="submit"
                type="primary"
              >
                Next
              </CustomButton>
            )}
            <CustomButton
              className={styles.inwardButton}
              type="primary"
              ghost
              onClick={() => history.back()}
            >
              Back
            </CustomButton>
          </Space>
        </FormItem>
      </Form>
    </Layout>
  );
};
export default CreateBMR;
