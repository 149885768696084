import React, { useState } from 'react';
import {
  Row,
  Col,
  Form,
  Card,
  Input,
  Space,
  Select,
  message,
  Image,
  Radio
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import truck from '@/features/qa/components/images/truck-delivery-done.gif';
import Layout from '@/common/Layout';
import {
  useGetSTPIdMastersQuery,
  useGetSTPProductBatchNoMastersQuery,
  useCreateSolventTransferPumpMutation
} from '../../redux/apiSlice';
import moment from 'moment';
import LiveTimeInput from '@/common/Clock';

import LocalStorage from 'utilities/localStorage';
const user = LocalStorage.getItem('Gravity_user');
// console.log('user', user?.user?.employee);

const createProductNewPage = () => {
  const [form] = Form.useForm();

  // #region Initial states - Start
  const [truckShow] = useState(false);
  const [search, setSearch] = useState('');

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [productBatchNoPlaceHolder, setproductBatchNoPlaceHolder] = useState(
    'Select Dispensed Product Batch No'
  );
  const [dpbOrIhpb, setDpbOrIhpb] = useState('dispensed_product_batch_no');
  const [dpbOrIhpbDefaultValue, setdpbOrIhpbDefaultValue] = useState('dpb');
  // #endregion

  // #region Showing success or error message - Start
  const handleShowMessage = (messageType, messageText) => {
    messageApi.open({
      type: messageType,
      content: messageText,
      duration: 3
    });
  };
  // #endregion

  // #region Fetching Dispensing Aids Equipment Masters - Start
  let { data: STPEquipmentIdMasters, refetch: STPEquipmentIdMastersRefetch } =
    useGetSTPIdMastersQuery({ search });
  // #endregion
  // console.log(STPEquipmentIdMasters);

  // #region Fetching Dispensing Aids Id Masters - Start
  let {
    data: stpProductOrBatchNoMasters,
    refetch: stpProductOrBatchNoMastersRefetch
  } = useGetSTPProductBatchNoMastersQuery({ search, type: dpbOrIhpb });
  // #endregion

  // #region Creating Vacuum Cleaning Record - Start
  let [createSolventTransferPump] = useCreateSolventTransferPumpMutation();

  const onFinish = async (values) => {
    setSubmitButtonDisabled(true);

    const values2 = { ...values };
    values2.cleaned_by_user_date = moment(new Date()).format();
    values2.date = moment(new Date()).format();
    values2.dpb_or_ihpb = dpbOrIhpbDefaultValue;

    const response = await createSolventTransferPump({
      ...values2
    });
    if (response?.data?.success === true) {
      handleShowMessage('success', response?.data?.message);
      setTimeout(
        () => history.push('/log-books-solvent-transfer-pump-list'),
        1500
      );
    } else {
      setSubmitButtonDisabled(false);
      handleShowMessage('error', response?.error?.data?.message);
    }
  };
  // #endregion

  // #region All onChange events handler - Start
  const onChange = async (e, name) => {
    if (name === 'dpb_or_ihpb') {
      if (e.target.value === 'dpb') {
        setproductBatchNoPlaceHolder('Select Dispensed Product Batch No');
        setDpbOrIhpb('dispensed_product_batch_no');
        setdpbOrIhpbDefaultValue('dpb');
        stpProductOrBatchNoMastersRefetch();
      } else {
        setproductBatchNoPlaceHolder('Select In House Batch No');
        setDpbOrIhpb('in_house_batch_no');
        setdpbOrIhpbDefaultValue('ihpb');
        stpProductOrBatchNoMastersRefetch();
      }
      form.resetFields(['dpb_or_ihpb_number']);
    }
    if (name === 'dpb_or_ihpb_dropdown') {
      console.log('dpbOrIhpb =', form.getFieldValue('name_of_product'));
      if (dpbOrIhpb === 'dispensed_product_batch_no') {
        const filteredDispensedProduct =
          stpProductOrBatchNoMasters?.data?.filter((obj) => obj.id === e);
        form.setFieldsValue({
          name_of_product: filteredDispensedProduct[0]?.product_detail?.name,
          dpb_or_ihpb_number: filteredDispensedProduct[0]?.product_batch_number
        });
      }
      if (dpbOrIhpb === 'in_house_batch_no') {
        const filteredDispensedProduct =
          stpProductOrBatchNoMasters?.data?.filter((obj) => obj.id === e);
        form.setFieldsValue({
          name_of_product: filteredDispensedProduct[0]?.material?.name,
          dpb_or_ihpb_number: filteredDispensedProduct[0]?.inhouse_batch_number
        });
      }
    }
  };

  // #endregion

  // #region All onSearch events handler - Start
  const onSearch = async (e, name) => {
    if (e.length === 0 || e.length >= 3) {
      if (name === 'equipment') {
        setSearch(e);
        STPEquipmentIdMastersRefetch();
      } else if (name === 'dpb_or_ihpb_dropdown') {
        setSearch(e);
        stpProductOrBatchNoMastersRefetch();
      }
    }
  };
  // #endregion

  return (
    <Space>
      {contextHolder}
      <Layout sider={false}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            cleaned_by_user_id: user?.user?.employee,
            display_cleaned_by: `${user?.user?.employee} (${moment(
              new Date()
            ).format('DD-MM-YY')})`
          }}
        >
          <Card
            title={'CLEANING RECORD OF SOLVENT TRANSFER PUMP'}
            className={styles.CustomPanel}
          >
            <Row gutter={24}>
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label="Equipment Id"
                  name="solventTransferPumpEquipmentIdMasterId"
                  rules={[
                    {
                      required: true,
                      message: 'Select Equipment Id'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Select
                    placeholder="Select Equipment Id"
                    showSearch
                    optionFilterProp="children"
                    onSearch={(e) => onSearch(e, 'equipment')}
                  >
                    {STPEquipmentIdMasters?.data?.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.equipment_id}
                        </option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Date"
                  name="display_date"
                  className={styles.customLable}
                >
                  <LiveTimeInput valueType="date" />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label={
                    <Radio.Group
                      onChange={(e) => onChange(e, 'dpb_or_ihpb')}
                      defaultValue={dpbOrIhpbDefaultValue}
                      size="small"
                      style={{
                        zIndex: 0
                      }}
                    >
                      <Radio.Button value="dpb" checked>
                        Dispensed Product Batch No
                      </Radio.Button>
                      <Radio.Button value="ihpb">
                        In House Batch No
                      </Radio.Button>
                    </Radio.Group>
                  }
                  name="display_dpb_or_ihpb_number"
                  rules={[
                    {
                      required: true,
                      message: `${
                        dpbOrIhpb == 'dispensed_product_batch_no'
                          ? 'Select Dispensed Product Batch #'
                          : dpbOrIhpb == 'in_house_batch_no'
                          ? 'Select In House Batch #'
                          : ''
                      }`
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Select
                    placeholder={productBatchNoPlaceHolder}
                    showSearch
                    optionFilterProp="children"
                    onSearch={(e) => onSearch(e, 'dpb_or_ihpb_dropdown')}
                    onChange={(e) => onChange(e, 'dpb_or_ihpb_dropdown')}
                  >
                    {stpProductOrBatchNoMasters?.data?.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {dpbOrIhpb == 'dispensed_product_batch_no'
                            ? item.product_batch_number
                            : item.inhouse_batch_number}
                        </option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label="Name of the Product / Material"
                  name="name_of_product"
                  rules={[
                    {
                      required: true,
                      message: 'Product / Material Name is required'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input style={{ width: '100%' }} disabled={true} />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Cleaned by (User Id & Date)"
                  name="display_cleaned_by"
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Remarks"
                  name="remarks"
                  className={styles.customLable}
                >
                  <Input.TextArea placeholder="" style={{ width: '100%' }} />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24} hidden={true}>
                <FormItem
                  label="Cleaned by"
                  name="cleaned_by_user_id"
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24} hidden={true}>
                <FormItem
                  label="Cleaned by"
                  name="cleaned_by_user_date"
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24} hidden={true}>
                <FormItem
                  label="Cleaned by"
                  name="dpb_or_ihpb_number"
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
            </Row>
          </Card>

          <br />
          <FormItem className="text-center">
            <Space>
              {truckShow ? (
                <Image
                  src={truck}
                  preview={false}
                  style={{ width: '100px !important', height: '100px' }}
                />
              ) : (
                <CustomButton
                  className={styles.inwardButton}
                  htmlType="submit"
                  type="primary"
                  onSubmit={(e) => onFinish(e)}
                  disabled={submitButtonDisabled}
                >
                  Create
                </CustomButton>
              )}
              <CustomButton
                className={styles.inwardButton}
                type="primary"
                ghost
                onClick={() => history.back()}
              >
                Back
              </CustomButton>
            </Space>
          </FormItem>
        </Form>
      </Layout>
    </Space>
  );
};
export default createProductNewPage;
