import React, { useState, useEffect } from 'react';
// import Layout from '@/common/Layout';
import { Form, Card, Space, Input } from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import { getAllLogBooksdetails } from '../../redux/slice';
import CustomTable from 'common/Content/CustomTable';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import Layout from '@/common/Layout';
import { useDispatch } from 'react-redux';
// import { getLoggedInUser } from 'utilities/helpers';
import moment from 'moment';

const { Search } = Input;

const LogbookList = ({ loading }) => {
  const [form] = Form.useForm();
  const [allLogs, setAlllogs] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [search, setSearch] = useState('');

  // const { user } = getLoggedInUser();
  console.log(allLogs);
  const dispatch = useDispatch();
  useEffect(() => {
    handleGetAllLogDetails();
  }, [page, search]);

  const handleGetAllLogDetails = () => {
    const payload = { page: page, search: search };
    dispatch(getAllLogBooksdetails(payload)).then((response) => {
      if (response?.payload?.success) {
        setAlllogs(response?.payload?.data);
        setPagination(response?.payload?.pagination);
      }
    });
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  //   console.log(allSamples);
  const columns = [
    {
      title: 'Blend Batch',
      dataIndex: 'blend_batch',
      key: 'blend_batch',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return (
          <a href={`/create-log?id=${record?.id}`}>{record?.blend_batch}</a>
        );
      }
    },
    {
      title: 'Blend Size',
      dataIndex: 'blend_size',
      key: 'blend_size',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'Blend BMR',
      dataIndex: 'blend_bmr',
      key: 'blend_bmr',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'Created on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      ellipsis: true,
      width: '100px',
      render: (text) => {
        return moment(text).format('DD MMM YYYY');
      }
    },
    {
      title: 'Alloted By',
      dataIndex: 'alloted_by',
      key: 'alloted_by',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'Actions',
      dataIndex: 'alloted_by',
      key: 'alloted_by',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return <a href={`/view-log/${record?.id}`}>View</a>;
      }
    }
  ];

  return (
    <>
      <Layout sider={false}>
        <Form form={form} layout="vertical">
          <Card
            title={<span>Log Book Details</span>}
            className={`${styles.cardStyles_approver}`}
            extra={
              <Space>
                <Search
                  onChange={(e) => handleChange(e)}
                  className={`w-100 ${styles.searchbar}`}
                  justify="start"
                  align="start"
                  placeholder="Search by Blend batch"
                />
                <CustomButton
                  className={styles.inwardButton}
                  onClick={() => history.push('/create-log')}
                  type="primary"
                >
                  Create
                </CustomButton>
              </Space>
            }
          >
            <div className={`${styles.table_container}`}>
              <CustomTable
                columns={columns}
                pagination={{
                  size: 'small',
                  total: pagination?.total_records || 0,
                  showSizeChanger: false,
                  onChange: (page) => {
                    setPage(page);
                  }
                }}
                dataSource={allLogs}
                loading={loading}
              />
            </div>
          </Card>
          <br />
          <FormItem className="text-center">
            <CustomButton
              className={styles.inwardButton}
              onClick={() => history.back()}
              type="primary"
            >
              Back to Home
            </CustomButton>
          </FormItem>
        </Form>
      </Layout>
    </>
  );
};
export default LogbookList;
