import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { materials, createRecon, recons, updateStock } from './api';

const initialState = {
  allMaterials: [],
  reconsData: ''
};

const actions = {
  GET_ALL_MATERIALS: 'reconciliation/GET_ALL_MATERIALS',
  CREATE_RECONSILIATION: 'reconciliation/CREATE_RECONSILIATION',
  GET_RECONSILIATION_BY_ID: 'reconciliation/GET_RECONSILIATION_BY_ID',
  UPDATE_RECONSILIATION: 'reconciliation/UPDATE_RECONSILIATION',
  UPDATE_STOCK: 'reconciliation/UPDATE_STOCK'
};

export const getAllMaterialslist = createAsyncThunk(
  actions.GET_ALL_MATERIALS,
  async (payload) => {
    const response = await materials('all', payload);

    return response;
  }
);

export const getAllReconsiliationbyid = createAsyncThunk(
  actions.GET_RECONSILIATION_BY_ID,
  async (payload) => {
    const response = await recons('get-all', payload);

    return response;
  }
);

export const createReconsiliation = createAsyncThunk(
  actions.CREATE_RECONSILIATION,
  async (payload) => {
    const response = await createRecon('create', payload);

    return response;
  }
);

export const updateReconsiliation = createAsyncThunk(
  actions.UPDATE_RECONSILIATION,
  async (payload) => {
    const response = await createRecon(`create?id=${payload.id}`, payload);

    return response;
  }
);

export const stockUpdation = createAsyncThunk(
  actions.UPDATE_STOCK,
  async (payload) => {
    const response = await updateStock(`stock_edit`, payload);

    return response;
  }
);

export const stocksSlice = createSlice({
  name: 'reconciliation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMaterialslist.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllMaterialslist.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, pagination } = action.payload;
        if (success) {
          state.allMaterials = data;
          state.pagination = pagination || null;
        } else {
          state.allMaterials = [];
        }
      })
      .addCase(getAllMaterialslist.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createReconsiliation.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createReconsiliation.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createReconsiliation.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllReconsiliationbyid.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllReconsiliationbyid.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, pagination } = action.payload;
        if (success) {
          console.log(data);
          state.reconsData = data;
          state.pagination = pagination || null;
        } else {
          state.reconsData = [];
        }
      })
      .addCase(getAllReconsiliationbyid.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(updateReconsiliation.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(updateReconsiliation.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(updateReconsiliation.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(stockUpdation.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(stockUpdation.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(stockUpdation.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
  }
});
export default stocksSlice.reducer;
