import React, { useState } from 'react';
import { Row, Form, Input, Col } from 'antd';
import styles from '@/common/Layout/index.module.less';
import { Message, Icon } from 'semantic-ui-react';
import tataPro from '../../images/logo.svg';
import truck_img from '../../images/login-icon.svg';
import FormItem from 'common/FormItem';
import CustomButton from 'common/CustomButton';
import { useLocation } from 'react-router-dom';

// const { Link } = Typography;

const ForgotPasswordPage = ({ loading, onSubmit }) => {
  const [errorMsg] = useState('');
  const [form] = Form.useForm();

  const searchParam = useLocation().search;
  const searchParams = new URLSearchParams(searchParam);

  const onFinish = (values) => {
    const { password } = values;

    let payload = {
      new_password: password,
      email: searchParams.get('employee_id'),
      token: searchParams.get('token')
    };

    onSubmit(payload);
  };
  return (
    <>
      <Row className={styles.mainContainerStyle}>
        <Col xs={2} xl={12}>
          <img className={`${styles.left_logo} mt-5 ml-5 mb-5`} src={tataPro} />

          <img className={`${styles.main_img}mt-5 ml-5 mb-5`} src={truck_img} />
        </Col>
        <Col xs={22} xl={12}>
          <div>
            <h1
              style={{
                marginTop: '20%',
                marginLeft: '20%',
                fontWeight: 'Bold',
                fontFamily: 'Source Sans Pro'
              }}
            >
              Reset Password
            </h1>
            {/* <h4
              style={{
                marginLeft: '20%',
                fontFamily: 'Source Sans Pro',
                textJustify: 'inter-word',
                marginRight: '20%'
              }}
            >
              Manage all receipts, stock movements, QC and dispensing processes
            </h4> */}
          </div>
          {/* <AuthWrapper> */}
          <div className={` ${styles.right_outer_container}`}>
            <Form
              form={form}
              layout="vertical"
              className={`ant-form ant-form-vertical mt-4 ${styles.form_width}`}
              onFinish={onFinish}
            >
              <FormItem
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  },
                  {
                    pattern: new RegExp(
                      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
                    ),
                    message:
                      'Min 8 characters,with at least a symbol, upper and lower case letters and a number'
                  }
                ]}
              >
                <Input.Password placeholder="Enter password" />
              </FormItem>
              <FormItem
                label="Confirm Password"
                name="confirm_password"
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          'The two passwords that you entered do not match!'
                        )
                      );
                    }
                  })
                ]}
              >
                <Input.Password placeholder="Enter password" />
              </FormItem>
              {/* <Link
                href="/login"
                className="d-block text-right mb-4 sfprotext-medium font-12"
              >
                Login
              </Link> */}
              <FormItem>
                <CustomButton
                  block
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Change Password
                </CustomButton>
              </FormItem>
            </Form>
          </div>
        </Col>
      </Row>
      {/*  <div className={styles.bakground_img}>
        <div className={styles.left_screen}>
          <Row className={`mt-5 ml-5 mb-5`}></Row>
          <Row className={`mt-5 ml-5 mb-5`}></Row>
        </div>
        <div className={styles.right_screen}></div>
      </div> */}
      <div>
        {errorMsg && (
          <Message negative>
            <Icon name="warning" />
            {errorMsg}
          </Message>
        )}
      </div>
    </>
  );
};

export default ForgotPasswordPage;
