import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Card,
  Radio,
  Space,
  Select,
  Input,
  InputNumber,
  Image,
  DatePicker,
  message
} from 'antd';
// import moment from 'moment';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CustomButton from '@/common/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllFilteredMaterials,
  createDispensing,
  getAllproducts,
  getDispenseMaterialDetails
} from '../../redux/slice';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
// import CustomTable from '@/common/Content/CustomTable';
import { history } from 'app/history';
import { useLocation } from 'react-router-dom';
import truck from '@/features/qa/components/images/truck-delivery-done.gif';
import { getLoggedInUser } from 'utilities/helpers';
import VerificationModal from '@/features/material/components/VerificationModal';
import moment from 'moment';
// const { TextArea } = Input;

const DispensingPage = ({ loading }) => {
  const { allMaterials, allProducts } = useSelector(
    (state) => state.dispensing
  );
  console.log(allProducts);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const searchParam = useLocation().search;
  const searchParams = new URLSearchParams(searchParam);

  const { user } = getLoggedInUser();

  const [value, setValue] = useState('');
  const [type, setType] = useState('');
  // const [code, setCode] = useState('');
  const [search, setSearch] = useState('');
  // const [uom, setUom] = useState('');
  const [data, setData] = useState([
    {
      batch_id: '',
      required_weight: '',
      material_id: '',
      material_name: '',
      material_code: '',
      batch_number: ''
    }
  ]);
  const [effectdate, setEffectdate] = useState();
  const [product, setProduct] = useState('');
  const [dispenseData, setDispenseData] = useState({});
  const [truckShow, setTruckShow] = useState(false);
  const [products, setProducts] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [bMRData, setBMRData] = useState();
  const [parentBatch, setParentBatch] = useState('No');
  console.log(dispenseData);
  console.log(type);
  const handleCapacity = (e) => {
    const { name, value } = e.target;
    console.log(allProducts);
    if (value == 'full' || value == 'partial') {
      let products = allProducts.filter(
        (product) => product.product_sub_type == 'Manual'
      );
      setProducts(products);
    } else {
      let products = allProducts.filter(
        (product) => product.product_sub_type == 'Auto'
      );
      setProducts(products);
    }
    console.log(products);
    setType({ ...type, [name]: value });
    // setData([]);
  };
  console.log(products, 'products');
  useEffect(() => {
    handleGetAllMaterialsList();
    handleGetAllProductsList();
  }, [search]);
  useEffect(() => {
    handleGetAllMaterials();
  }, []);
  const handleGetAllMaterials = (type) => {
    console.log(type);
    let payload = {
      id: searchParams.get('id')
    };
    dispatch(getDispenseMaterialDetails(payload)).then(function (result) {
      if (result?.payload?.success) {
        if (result?.payload?.data?.type == 'bmr') {
          setDispenseData(result?.payload?.data);
          setProduct([result?.payload?.data?.product_detail]);
          setValue(result?.payload?.data?.bmr_partial_details);
          setType({
            ...type,
            ['type']: result?.payload?.data?.type,
            ['sub_type']: result?.payload?.data?.sub_type
            // result?.payload?.data?.is_partial == 'false' ? 'full' : 'partial'
          });
          setParentBatch(result?.payload?.data?.is_parent);
          form.setFieldsValue({
            batch_number: result?.payload?.data?.product_batch_number,
            ref_mfc: result?.payload?.data?.ref_mfc,
            product_code: result?.payload?.data?.product_code,
            label_claim: result?.payload?.data?.label_claim,
            shelf_life: result?.payload?.data?.shelf_life,
            manufacturing_site: result?.payload?.data?.manufacturing_site,
            customer_details: result?.payload?.data?.customer_details,
            is_parent: result?.payload?.data?.is_parent,
            parent_batch: result?.payload?.data?.parent_batch,
            effective_date: moment(
              result?.payload?.data?.effective_date,
              'YYYY-MM-DD'
            )
          });
        } else {
          setType({
            ...type,
            ['type']: result?.payload?.data?.type,
            ['sub_type']:
              result?.payload?.data?.is_partial == 'false' ? 'full' : 'partial'
          });
          const dis_materials = [];
          result?.payload?.data?.dispensing_materials?.map((item) => {
            dis_materials.push({
              batch_id: item?.batch_details_id,
              required_weight: item?.required_weight,
              material_id: item?.material_id,
              material_name: item?.material?.name,
              material_code: item?.material?.code,
              batch_number: item?.batch_details?.inhouse_batch_number,
              dispensing_material_id: item?.id
            });
            // dis_materials['batch_id'] = item?.batch_details_id;
            // dis_materials['required_weight'] = item?.required_weight;
            // dis_materials['material_id'] = item?.material_id;
            // dis_materials['material_name'] = item?.material?.name;
            // dis_materials['material_code'] = item?.material?.code;
            // dis_materials['batch_number'] = item?.batch_details?.inhouse_batch_number;
            // dis_materials['dispensing_material_id'] = item?.id
          });
          setData(dis_materials);
        }
      }
    });
  };

  console.log(data);
  const handleGetAllMaterialsList = () => {
    let payload = { search };
    dispatch(getAllFilteredMaterials(payload));
  };

  const handleGetAllProductsList = () => {
    let payload = { search };
    dispatch(getAllproducts(payload));
  };

  const onSearch = (value) => {
    setSearch(value);
  };

  const onChange = (value, index) => {
    console.log(allMaterials);
    const material = allMaterials.filter(
      (allMaterials) => allMaterials.id === value
    );

    const list = [...data];
    list[index].batch_id = material[0]?.batch_details[0]?.id;
    list[index].material_name = material[0]?.name;
    list[index].material_code = material[0]?.code;
    list[index].material_id = material[0]?.id;
    list[index].batch_number = material[0]?.inhouse_batch_number;
    list[index].uom = material[0]?.uom;
    setData(list);
    // required_weight: form.getFieldValue('weight'),
    //     material_id: code?.id,
    //     material_name: form.getFieldValue('material_name'),
    //     material_code: code?.code,
    //     batch_number: code?.batch_details[0]?.inhouse_batch_number
    // setCode(material[0]);
    // form.setFieldsValue({ material_name: material[0]?.name });
    // setUom(material[0]?.uom);
  };

  const handleChangeWeight = (e, index) => {
    const list = [...data];
    list[index].required_weight = e;
    setData(list);
  };

  const onChangeproduct = (value) => {
    const products = allProducts.filter(
      (allProducts) => allProducts.id === value
    );
    setProduct(products);
  };

  const handleClick = () => {
    setData([
      ...data,
      {
        // batch_id: code?.batch_details[0]?.id,
        // required_weight: form.getFieldValue('weight'),
        // material_id: code?.id,
        // material_name: form.getFieldValue('material_name'),
        // material_code: code?.code,
        // batch_number: code?.batch_details[0]?.inhouse_batch_number

        batch_id: '',
        required_weight: '',
        material_id: '',
        material_name: '',
        material_code: '',
        batch_number: ''
      }
    ]);

    // form.resetFields();
    // setCode('');
    // setUom('');
    // form.setFieldsValue({ material_name: '' });
  };

  const handleDeleteMaterial = (index) => {
    const list = [...data];
    list.splice(index, 1);
    setData(list);
  };

  const onFinish = (values) => {
    setTruckShow(true);
    console.log(product);
    const payload = {
      type: user?.role == 'qa_initiator' ? 'material' : type?.type,
      is_partial: type?.sub_type == 'partial' ? true : false,
      sub_type: type?.sub_type,
      product_batch_number: values?.batch_number,
      effective_date: values?.effective_date
        ? values?.effective_date
        : effectdate
        ? effectdate
        : product?.[0]?.effective_date,
      batch_size: product?.[0]?.theoretical_batch_size,
      dispensing_request_id: searchParams.get('id'),
      ref_mfc:
        type?.sub_type == 'auto' ? product?.[0]?.ref_mfc : values.ref_mfc,
      shelf_life:
        type?.sub_type == 'auto' ? product?.[0]?.shelf_life : values.shelf_life,
      manufacturing_site:
        type?.sub_type == 'auto'
          ? product?.[0]?.manufacturing_site
          : values.manufacturing_site,
      customer_details:
        type?.sub_type == 'auto'
          ? product?.[0]?.customer_details
          : values.customer_details,
      manufacturing_date: values.manufacturing_date,
      manufacturing_start_date: values.manufacturing_start_date,
      expiry_date: values.expiry_date,
      manufacturing_end_date: values.manufacturing_end_date,
      product_id: values.product_id,
      bmr_partial_details: value,
      material_list: type?.type == 'bmr' ? [] : data,
      bmr_number: product?.[0]?.bmr_bpr_code,
      label_claim: values.label_claim,
      product_code: values.product_code,
      is_parent: values.is_parent,
      parent_batch: values.is_parent ? values.parent_batch : 'NA'
    };
    console.log(payload);

    const check_empty = data?.filter(
      (data) => data.required_weight === '' || data.material_code === ''
    );
    console.log(check_empty);
    if (check_empty.length > 0 && type?.type === 'material') {
      message.error('Please Fill all the required fields');
      setTruckShow(false);
    } else {
      searchParams.get('id')
        ? handleVerifyBmr(payload)
        : dispatch(createDispensing(payload)).then(function (response) {
            if (response?.payload?.success) {
              message.success(response.payload.message);
              setTruckShow(false);
              type?.type == 'bmr' && type?.sub_type == 'auto'
                ? history.push('/dispensing-list')
                : // history.push(
                //     `/product-details/${response?.payload?.data?.product_id}`
                //   )
                user?.role == 'qa_initiator'
                ? history.push('/dispense-material-list')
                : history.push('/dispensing-list');
            } else {
              setTruckShow(false);
            }
          });
    }
  };

  console.log(product);

  // const onChangeWeight = (value, index) => {
  //   console.log(value);
  //   setData((prevData) => {
  //     const newData = [...prevData];
  //     const newItem = { ...newData[index] };
  //     newItem.required_weight = value;
  //     newData[index] = newItem;

  //     return newData;
  //   });
  // };

  const handleVerifyBmr = (payload) => {
    setTruckShow(false);
    setIsModal(true);
    setBMRData(payload);
  };
  // const columns = [
  //   // {
  //   //   title: 'Batch Number',
  //   //   dataIndex: 'batch_number',
  //   //   key: 'batch_number',
  //   //   width: '150px',
  //   //   ellipsis: true,
  //   //   sorter: (a, b) => a?.batch_number?.localeCompare(b?.batch_number),
  //   //   responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
  //   // },
  //   {
  //     title: 'Material Code',
  //     dataIndex: 'material_code',
  //     key: 'material_code',
  //     width: '150px',
  //     ellipsis: true,
  //     sorter: (a, b) => a?.material_code?.localeCompare(b?.material_code),
  //     responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
  //   },
  //   {
  //     title: 'Material Name',
  //     dataIndex: 'material_name',
  //     key: 'material_name',
  //     width: '150px',
  //     ellipsis: true,
  //     sorter: (a, b) => a?.material_name?.localeCompare(b?.material_name),
  //     responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
  //   },
  //   {
  //     title: 'Required Weight(UOM)',
  //     dataIndex: 'required_weight',
  //     key: 'required_weight',
  //     width: '150px',
  //     ellipsis: true,
  //     sorter: (a, b) => a?.required_weight?.localeCompare(b?.required_weight),
  //     render: (text, _, index) =>
  //       searchParams.get('id') ? (
  //         <InputNumber
  //           value={data[index].required_weight}
  //           min={0}
  //           style={{
  //             width: '100%'
  //           }}
  //           placeholder="Enter Required Weight"
  //           onChange={(v) => onChangeWeight(v, index)}
  //         />
  //       ) : (
  //         text
  //       ),
  //     responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
  //   }
  // ];
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      // onFinishFailed={onFinishFailed}
    >
      {console.log(type)}
      <Card
        title="Dispensing Request -BMR Based"
        className={styles.CustomPanel}
      >
        {user?.role !== 'qa_initiator' ? (
          <Row gutter={24}>
            <Col xs={24} xl={8} span={8}>
              <h6 className={styles.lable}>Request Type</h6>
            </Col>
            <Col xs={24} xl={16} span={16}>
              <Radio.Group
                onChange={handleCapacity}
                className={styles.customRadio}
                name="type"
                value={type?.type}
                disabled={searchParams.get('id')}
              >
                <Radio value="bmr">BMR Based</Radio>
                <Radio value="material">Material Code</Radio>
              </Radio.Group>
            </Col>
          </Row>
        ) : (
          ''
        )}
        {type?.type == 'bmr' ? (
          <>
            <br />
            <Row gutter={24}>
              <Col xs={0} xl={8} span={8}></Col>
              <Col xs={24} xl={8} span={8}>
                <Radio.Group
                  onChange={handleCapacity}
                  className={styles.customRadio}
                  name="sub_type"
                  value={type?.sub_type}
                  disabled={searchParams.get('id')}
                >
                  <Radio value="full">Full</Radio>
                  <Radio value="partial">Partial</Radio>
                  <Radio value="auto">Auto</Radio>
                </Radio.Group>
              </Col>
            </Row>
            <br />
          </>
        ) : (
          ''
        )}
        {type?.type == 'bmr' && type?.sub_type == 'auto' ? (
          <>
            <Card title="Product Details" className={styles.CustomPanel}>
              <Row gutter={24}>
                {searchParams.get('id') ? (
                  <Col xs={24} xl={12} span={12}>
                    <FormItem
                      label="Product Name"
                      className={styles.customLable}
                    >
                      <Input
                        value={dispenseData?.product_detail?.name}
                        disabled
                      />
                    </FormItem>
                  </Col>
                ) : (
                  <Col xs={24} xl={12} span={12}>
                    <FormItem
                      label="Product Name"
                      name="product_id"
                      rules={[
                        {
                          required: true,
                          message: 'Select Product Name'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Select
                        showSearch
                        placeholder="Select a product name"
                        optionFilterProp="children"
                        onChange={onChangeproduct}
                        onSearch={onSearch}
                        name="product_id"
                      >
                        {products?.map((item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {item.name}
                            </option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                )}
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Product Batch #"
                    name="batch_number"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Enter batch number"
                      disabled={searchParams.get('id')}
                    />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="Ref. MFC#" className={styles.customLable}>
                    <Input
                      placeholder="Enter Ref. MFC#"
                      value={product[0]?.ref_mfc}
                      disabled
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Effective date"
                    // name="effective_date"
                    rules={[
                      {
                        required: true,
                        message: 'Select date'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      value={
                        product[0]?.effective_date
                          ? moment(product[0]?.effective_date).format(
                              'DD MMM YYYY'
                            )
                          : 'NA'
                      }
                      disabled
                    />
                    {/* <DatePicker
                      placeholder="Select Date"
                      style={{ width: '100%' }}
                      name="effective_date"
                      // onChange={(date, dateString) =>
                      //   handleChangedate('effective_date', date, dateString)
                      // }
                    /> */}
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="BMR/BPR #"
                    rules={[
                      {
                        required: true,
                        message: 'Enter BMR number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Enter BMR number"
                      value={product[0]?.bmr_bpr_code}
                      disabled
                    />
                  </FormItem>
                </Col>

                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Batch Size"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch Size'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Enter Batch Size"
                      value={product[0]?.theoretical_batch_size}
                      disabled
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Shelf life"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Shelf life'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Enter Shelf life"
                      value={product[0]?.shelf_life}
                      disabled
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Manufacturing Site"
                    rules={[
                      {
                        required: true,
                        message: 'Select Manufacturing Site'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Enter Manufacturing Site"
                      value={
                        product[0]?.manufacturing_site ==
                        'Graviti Pharmaceuticals Pvt. Ltd., Isnapur'
                          ? 'Graviti Pharmaceuticals Pvt. Ltd.'
                          : ''
                      }
                      disabled
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Market / Customer Details"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Market / Customer Details'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Enter Market / Customer Details"
                      value={product[0]?.customer_details}
                      disabled
                    />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="Product Code"
                    className={styles.customLable}
                    name="product_code"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch Size'
                      }
                    ]}
                  >
                    <Input placeholder="Enter Product Code" />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="Label Claim"
                    className={styles.customLable}
                    name="label_claim"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch Size'
                      }
                    ]}
                  >
                    <Input placeholder="Enter Label Claim" />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="Is this Batch linked to a Parent Batch #?"
                    className={styles.customLable}
                    name="is_parent"
                    rules={[
                      {
                        required: true,
                        message: 'Select Yes or No'
                      }
                    ]}
                  >
                    <Radio.Group
                      onChange={(e) => setParentBatch(e.target.value)}
                      disabled={searchParams.get('id')}
                    >
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                    </Radio.Group>
                  </FormItem>
                </Col>
                {parentBatch === 'Yes' ? (
                  <Col span={12}>
                    <FormItem
                      label="Parent Batch #"
                      className={styles.customLable}
                      name="parent_batch"
                      rules={[
                        {
                          required: true,
                          message: 'Select Yes or No'
                        }
                      ]}
                    >
                      <Input
                        placeholder="Enter Parent Batch #"
                        disabled={searchParams.get('id')}
                      />
                    </FormItem>
                  </Col>
                ) : (
                  ''
                )}
              </Row>
            </Card>
            {/* <Card title="Excuation Details">
              <Row gutter={24}>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Manufacturing date"
                    name="manufacturing_date"
                    rules={[
                      {
                        required: true,
                        message: 'Select date'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <DatePicker
                      placeholder="Select Date"
                      style={{ width: '100%' }}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Expiry date"
                    name="expiry_date"
                    rules={[
                      {
                        required: true,
                        message: 'Select date'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <DatePicker
                      placeholder="Select Expiry date"
                      style={{ width: '100%' }}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Manufacturing start date"
                    name="manufacturing_start_date"
                    rules={[
                      {
                        required: true,
                        message: 'Select date'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <DatePicker
                      placeholder="Select Manufacturing start date"
                      style={{ width: '100%' }}
                    />
                  </FormItem>
                </Col>

                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Manufacturing End date"
                    name="manufacturing_end_date"
                    rules={[
                      {
                        required: true,
                        message: 'Select date'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <DatePicker
                      placeholder="Select Manufacturing End date"
                      style={{ width: '100%' }}
                    />
                  </FormItem>
                </Col>
              </Row>
            </Card> */}
            <br />
            {/* <Row gutter={24}>
              <Col xs={24} xl={24} span={24}>
                <FormItem
                  label="Material Details"
                  rules={[
                    {
                      required: true,
                      message: 'Enter material details'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <ReactQuill theme="snow" value={value} onChange={setValue} />
                </FormItem>
              </Col>
            </Row> */}
          </>
        ) : type?.type == 'bmr' &&
          (type?.sub_type == 'partial' || type?.sub_type == 'full') ? (
          <>
            <Card title="Product Details" className={styles.CustomPanel}>
              <Row gutter={24}>
                {searchParams.get('id') ? (
                  <Col xs={24} xl={12} span={12}>
                    <FormItem
                      label="Product Name"
                      className={styles.customLable}
                    >
                      <Input
                        value={dispenseData?.product_detail?.name}
                        disabled
                      />
                    </FormItem>
                  </Col>
                ) : (
                  <Col xs={24} xl={12} span={12}>
                    <FormItem
                      label="Product Name"
                      name="product_id"
                      rules={[
                        {
                          required: true,
                          message: 'Select Product Name'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Select
                        showSearch
                        placeholder="Select a product name"
                        optionFilterProp="children"
                        onChange={onChangeproduct}
                        onSearch={onSearch}
                        name="product_id"
                      >
                        {products?.map((item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {item.name}
                            </option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                )}
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Product Batch #"
                    name="batch_number"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Enter batch number"
                      disabled={searchParams.get('id')}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="BMR/BPR #"
                    rules={[
                      {
                        required: true,
                        message: 'Enter BMR number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Enter BMR number"
                      value={product[0]?.bmr_bpr_code}
                      disabled
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Effective date"
                    // name="effective_date"
                    rules={[
                      {
                        required: true,
                        message: 'Select date'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    {type?.type == 'bmr' &&
                    (type?.sub_type == 'partial' ||
                      type?.sub_type == 'full') ? (
                      <DatePicker
                        placeholder="Select Date"
                        style={{ width: '100%' }}
                        name="effective_date"
                        value={
                          effectdate
                            ? moment(effectdate, 'YYYY-MM-DD')
                            : moment(product[0]?.effective_date, 'YYYY-MM-DD')
                        }
                        onChange={(date) => setEffectdate(date)}
                      />
                    ) : (
                      <Input
                        value={
                          product[0]?.effective_date
                            ? moment(product[0]?.effective_date).format(
                                'DD MMM YYYY'
                              )
                            : 'NA'
                        }
                        disabled
                      />
                    )}
                  </FormItem>
                </Col>

                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Batch Size"
                    rules={[
                      {
                        required: false,
                        message: 'Enter Batch Size'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Enter Batch Size"
                      value={product[0]?.theoretical_batch_size}
                      disabled
                    />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="Ref. MFC#"
                    className={styles.customLable}
                    name="ref_mfc"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch Size'
                      }
                    ]}
                  >
                    <Input placeholder="Enter Ref. MFC#" />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="Product Code"
                    className={styles.customLable}
                    name="product_code"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch Size'
                      }
                    ]}
                  >
                    <Input placeholder="Enter Product Code" />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="Label Claim"
                    className={styles.customLable}
                    name="label_claim"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch Size'
                      }
                    ]}
                  >
                    <Input placeholder="Enter Label Claim" />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="Shelf Life"
                    className={styles.customLable}
                    name="shelf_life"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch Size'
                      }
                    ]}
                  >
                    <Input placeholder="Enter Shelf Life" />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="Manufacturing Site"
                    className={styles.customLable}
                    name="manufacturing_site"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch Size'
                      }
                    ]}
                  >
                    <Input placeholder="Enter Manufacturing Site" />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="Market / Customer Details"
                    className={styles.customLable}
                    name="customer_details"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch Size'
                      }
                    ]}
                  >
                    <Input placeholder="Enter Market / Customer Details" />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="Is this Batch linked to a Parent Batch #?"
                    className={styles.customLable}
                    name="is_parent"
                    rules={[
                      {
                        required: true,
                        message: 'Select Yes or No'
                      }
                    ]}
                  >
                    <Radio.Group
                      onChange={(e) => setParentBatch(e.target.value)}
                      disabled={searchParams.get('id')}
                    >
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                    </Radio.Group>
                  </FormItem>
                </Col>
                {parentBatch === 'Yes' ? (
                  <Col span={12}>
                    <FormItem
                      label="Parent Batch #"
                      className={styles.customLable}
                      name="parent_batch"
                    >
                      <Input
                        placeholder="Enter Parent Batch #"
                        disabled={searchParams.get('id')}
                      />
                    </FormItem>
                  </Col>
                ) : (
                  ''
                )}
              </Row>
            </Card>
            <br />
            <Row gutter={24}>
              <Col xs={24} xl={24} span={24}>
                <FormItem
                  label="Material Details"
                  rules={[
                    {
                      required: true,
                      message: 'Enter material details'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <ReactQuill theme="snow" value={value} onChange={setValue} />
                </FormItem>
              </Col>
            </Row>
          </>
        ) : (
          ''
        )}

        {type?.type == 'material' || user?.role == 'qa_initiator' ? (
          <>
            <br />
            {console.log(data)}
            {data?.map((item, index) => {
              return (
                <Row gutter={24} key={index}>
                  <Col xs={24} xl={5} span={12} md={24} sm={24}>
                    <FormItem
                      label={
                        <>
                          Material Code<span style={{ color: 'red' }}> *</span>
                        </>
                      }
                      // name={`material_id${index}`}
                      rules={[
                        {
                          required: true,
                          message: 'Select Material Code'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Select
                        showSearch
                        placeholder="Select a material code"
                        optionFilterProp="children"
                        onChange={(e) => onChange(e, index)}
                        onSearch={onSearch}
                        value={item?.material_id}
                      >
                        {allMaterials?.map((item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {item.code}
                            </option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={5} span={12} md={24} sm={24}>
                    <FormItem
                      label="Material name"
                      // name="material_name"
                      rules={[
                        {
                          required: true,
                          message: 'Enter name of the material'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Enter name of the material"
                        value={item?.material_name}
                        disabled
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={5} span={12} md={24} sm={24}>
                    <FormItem
                      label={
                        <>
                          Required weight{' '}
                          <span style={{ color: 'red' }}> *</span>
                        </>
                      }
                      // name="weight"
                      rules={[
                        {
                          required: true,
                          message: 'Enter required weight'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <InputNumber
                        min={0}
                        style={{ width: '100%' }}
                        placeholder="Enter required weight"
                        value={item?.required_weight}
                        onChange={(e) => handleChangeWeight(e, index)}
                      />
                    </FormItem>
                  </Col>
                  <Col
                    xs={19}
                    xl={5}
                    span={12}
                    md={24}
                    sm={24}
                    className={styles.uom}
                  >
                    <Input placeholder="Enter UOM" value={item?.uom} disabled />
                  </Col>
                  <Col
                    xs={4}
                    xl={4}
                    span={12}
                    md={24}
                    sm={24}
                    className={styles.uom}
                  >
                    {data.length === 1 ? (
                      <CustomButton
                        loading={loading}
                        type="primary"
                        ghost
                        onClick={handleClick}
                      >
                        <PlusOutlined />
                      </CustomButton>
                    ) : index === data.length - 1 ? (
                      <>
                        <CustomButton
                          loading={loading}
                          type="primary"
                          ghost
                          onClick={handleClick}
                        >
                          <PlusOutlined />
                        </CustomButton>
                        <CustomButton
                          loading={loading}
                          type="primary"
                          ghost
                          onClick={() => handleDeleteMaterial(index)}
                          style={{ marginLeft: '8px' }}
                        >
                          <DeleteOutlined />
                        </CustomButton>
                      </>
                    ) : (
                      <CustomButton
                        loading={loading}
                        type="primary"
                        ghost
                        onClick={() => handleDeleteMaterial(index)}
                      >
                        <DeleteOutlined />
                      </CustomButton>
                    )}
                  </Col>
                </Row>
              );
            })}
          </>
        ) : (
          ''
        )}
      </Card>
      <br />
      {/* {data?.length ? (
        <div className={`${styles.table_container}`}>
          <CustomTable
            columns={columns}
            dataSource={data || []}
            scroll={{ x: 'auto' }}
          />
        </div>
      ) : (
        ''
      )} */}
      <br />
      <FormItem className="text-center">
        <Space>
          <CustomButton
            type="primary"
            loading={loading}
            ghost
            onClick={() => history.back()}
          >
            Back
          </CustomButton>
          {truckShow ? (
            <Image
              src={truck}
              preview={false}
              style={{ width: '100px !important', height: '100px' }}
            />
          ) : searchParams.get('id') ? (
            <CustomButton
              type="primary"
              loading={loading}
              htmlType="submit"
              // disabled={data?.length ? false : true}
            >
              Initiate Verification
            </CustomButton>
          ) : (
            <CustomButton
              type="primary"
              htmlType="submit"
              loading={loading}
              // disabled={data?.length ? false : true}
            >
              {type?.type == 'bmr' && type?.sub_type == 'auto'
                ? 'Next'
                : 'Submit'}
            </CustomButton>
          )}
        </Space>
      </FormItem>
      {isModal ? (
        <VerificationModal
          isModal={isModal}
          materials={bMRData}
          setIsModal={setIsModal}
          // material={material}
          // setIsEdit={setIsEdit}
          page="bmr"
        />
      ) : (
        ''
      )}
    </Form>
  );
};
export default DispensingPage;
