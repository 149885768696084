import React from 'react';
import {
  Form,
  Card,
  Space,
  message,
  Row,
  Col,
  Input,
  Select,
  DatePicker
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import Layout from '@/common/Layout';
import { history } from 'app/history';
import { useGetUacrOfSamplingOrDbarsQuery } from '../../redux/apiSlice';
import moment from 'moment';
import Spinner from '../../../../common/Spinner';
import { useParams } from 'react-router-dom';
// const { RangePicker } = DatePicker;

const AdminPage = () => {
  const [form] = Form.useForm();
  const params = useParams();

  const [messageApi, contextHolder] = message.useMessage();
  // #region Showing success or error message - Start
  const handleShowMessage = (messageType, messageText) => {
    messageApi.open({
      type: messageType,
      content: messageText
    });
  };
  // #endregion Showing success or error message - End

  const { data, isError, error, isLoading } = useGetUacrOfSamplingOrDbarsQuery({
    id: params.id
  });

  let content;
  if (isLoading) {
    console.log('Loading.....');
    content = <Spinner />;
  } else if (data) {
    console.log(data);
    content = (
      <Space>
        {contextHolder}
        <Layout sider={false}>
          <Form
            form={form}
            layout="vertical"
            disabled
            initialValues={{
              samplingDispensingBoothAreaMasterId:
                data?.data?.sampling_dispensing_booth_area_master?.area,
              laf_date: moment(data?.data?.laf_date).format('DD-MM-YYYY'),
              laf_time: moment(data?.data?.laf_time).format('HH:mm'),
              laf_product_name: data?.data?.laf_product_name,
              laf_product_batch_no: data?.data?.laf_product_batch_no,
              activity_start_time_user_id_date: data?.data
                ?.activity_start_time_date
                ? `${moment(data?.data?.activity_start_time_date).format(
                    'HH:mm'
                  )} (${data?.data?.activity_start_time_user_id}/${moment(
                    data?.data?.activity_start_time_date
                  ).format('DD-MM-YYYY')})`
                : '',
              activity_end_time_user_id_date: data?.data?.activity_end_time_date
                ? `${moment(data?.data?.activity_end_time_date).format(
                    'HH:mm'
                  )} (${data?.data?.activity_end_time_user_id}/${moment(
                    data?.data?.activity_end_time_date
                  ).format('DD-MM-YYYY')})`
                : '',
              laf_off_time: data?.data?.laf_off_time
                ? moment(data?.data?.laf_off_time).format('HH:mm')
                : '',
              type_of_cleaning: data?.data?.type_of_cleaning,
              start_date: moment(data?.data?.start_date),
              end_date: moment(data?.data?.end_date),
              cleaningAccessoriesMasterId:
                data?.data?.cleaning_log_book_accessories?.length > 0
                  ? data?.data?.cleaning_log_book_accessories.map(
                      (obj) =>
                        `${obj?.cleaning_accessories_master?.cleaning_accessories} (${obj?.cleaning_accessories_master?.cleaning_equip_id})`
                    )
                  : [],
              done_by_user_id: `${data?.data?.done_by} (${moment(
                data?.data?.done_by_date
              ).format('DD-MM-YY')})`,
              verified_by: `${data?.data?.verified_by} (${moment(
                data?.data?.verified_by_date
              ).format('DD-MM-YY')})`,
              remarks: data?.data?.remarks
            }}
          >
            <Card
              title={
                'USAGE AND CLEANING RECORD OF SAMPLING / DISPENSING BOOTH AND ROOM'
              }
              className={styles.CustomPanel}
            >
              <Row gutter={24}>
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="Area"
                    name="samplingDispensingBoothAreaMasterId"
                    className={styles.customLable}
                  >
                    <Select
                      placeholder="Select Area"
                      showSearch
                      optionFilterProp="children"
                    ></Select>
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={24} md={24} sm={24}>
                  <FormItem
                    label="Date"
                    name="laf_date"
                    className={styles.customLable}
                  >
                    <Input placeholder="" style={{ width: '100%' }} />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="LAF On Time"
                    name="laf_time"
                    className={styles.customLable}
                  >
                    <Input placeholder="" style={{ width: '100%' }} />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={24} md={24} sm={24}>
                  <FormItem
                    label="Product / Material Name"
                    name="laf_product_name"
                    className={styles.customLable}
                  >
                    <Input placeholder="" style={{ width: '100%' }} />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={24} md={24} sm={24}>
                  <FormItem
                    label="Product Batch No. / In-House Batch No."
                    name="laf_product_batch_no"
                    className={styles.customLable}
                  >
                    <Input placeholder="" style={{ width: '100%' }} />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={24} md={24} sm={24}>
                  <FormItem
                    label="Activity Start Time User Id & Date"
                    name="activity_start_time_user_id_date"
                    className={styles.customLable}
                  >
                    <Input placeholder="" style={{ width: '100%' }} />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={24} md={24} sm={24} hidden>
                  <FormItem
                    label="Activity Start Time User Id"
                    name="activity_start_time_user_id"
                    className={styles.customLable}
                  >
                    <Input placeholder="" style={{ width: '100%' }} />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={24} md={24} sm={24} hidden>
                  <FormItem
                    label="Activity Start Time Date"
                    name="activity_start_time_date"
                    className={styles.customLable}
                  >
                    <Input placeholder="" style={{ width: '100%' }} />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={24} md={24} sm={24}>
                  <FormItem
                    label="Activity End Time User Id & Date"
                    name="activity_end_time_user_id_date"
                    className={styles.customLable}
                  >
                    <Input placeholder="" style={{ width: '100%' }} />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={24} md={24} sm={24} hidden>
                  <FormItem
                    label="Activity Start Time User Id"
                    name="activity_end_time_user_id"
                    className={styles.customLable}
                  >
                    <Input placeholder="" style={{ width: '100%' }} />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={24} md={24} sm={24} hidden>
                  <FormItem
                    label="Activity Start Time Date"
                    name="activity_end_time_date"
                    className={styles.customLable}
                  >
                    <Input placeholder="" style={{ width: '100%' }} />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={24} md={24} sm={24}>
                  <FormItem
                    label="LAF Off Time"
                    name="laf_off_time"
                    className={styles.customLable}
                  >
                    <Input placeholder="" style={{ width: '100%' }} />
                  </FormItem>
                </Col>
              </Row>
            </Card>
            <br />{' '}
            <Card title={'Cleaning Activity'} className={styles.CustomPanel}>
              <Row gutter={24}>
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="Type of Cleaning"
                    name="type_of_cleaning"
                    className={styles.customLable}
                  >
                    <Select
                      placeholder="Type of Cleaning"
                      showSearch
                      optionFilterProp="children"
                    ></Select>
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={24} md={24} sm={24}>
                  <FormItem
                    label="Start Date & Time"
                    name="start_date"
                    className={styles.customLable}
                  >
                    <DatePicker showTime style={{ width: '100%' }} />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={24} md={24} sm={24}>
                  <FormItem
                    label="End Date & Time"
                    name="end_date"
                    className={styles.customLable}
                  >
                    <DatePicker showTime style={{ width: '100%' }} />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={24} md={24} sm={24}>
                  <FormItem
                    label="Cleaning Accessories"
                    name="cleaningAccessoriesMasterId"
                    className={styles.customLable}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select Cleaning Accessories"
                      optionFilterProp="children"
                      style={{ width: '100%' }}
                    ></Select>
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={24} md={24} sm={24}>
                  <FormItem
                    label="Done By User Id & Date"
                    name="done_by_user_id"
                    className={styles.customLable}
                  >
                    <Input placeholder="" style={{ width: '100%' }} />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={24} md={24} sm={24}>
                  <FormItem
                    label="Verified By User Id & Date"
                    name="verified_by"
                    className={styles.customLable}
                  >
                    <Input placeholder="" style={{ width: '100%' }} />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={24} md={24} sm={24}>
                  <FormItem
                    label="Remarks"
                    name="remarks"
                    className={styles.customLable}
                  >
                    <Input placeholder="" style={{ width: '100%' }} />
                  </FormItem>
                </Col>
              </Row>
            </Card>
          </Form>
          <br />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <CustomButton
              className={styles.inwardButton}
              type="primary"
              ghost
              onClick={() => history.back()}
            >
              Back
            </CustomButton>
          </div>
        </Layout>
      </Space>
    );
  } else if (isError) {
    handleShowMessage('error', error?.data?.message);

    console.log(error);
    content = 'Something went wrong!';
  }

  return <>{content}</>;
};
export default AdminPage;
