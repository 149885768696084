import React, { useState, useEffect } from 'react';
import {
  Form,
  Space,
  InputNumber,
  Image,
  Input,
  Card
  // DatePicker
} from 'antd';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import 'react-quill/dist/quill.snow.css';
import CustomButton from '@/common/CustomButton';
import { useDispatch } from 'react-redux';
import {
  getAllFilteredMaterials,
  createDispensing,
  getAllproducts,
  getDispenseMaterialDetails,
  ApproveDispensing
} from '../../redux/slice';
import CustomTable from '@/common/Content/CustomTable';
import { history } from 'app/history';
import { useParams } from 'react-router-dom';
import truck from '@/features/qa/components/images/truck-delivery-done.gif';
import { getLoggedInUser, getRoles } from 'utilities/helpers';
import Layout from '@/common/Layout';

const { TextArea } = Input;

const DispensingView = ({ loading }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const params = useParams();
  const { user } = getLoggedInUser();
  const { Superadmindata } = getRoles('Quality Control');

  const [value, setValue] = useState('');
  const [type, setType] = useState('');
  const [data, setData] = useState([]);
  const [product, setProduct] = useState('');
  const [truckShow, setTruckShow] = useState(false);

  useEffect(() => {
    handleGetAllMaterialsList();
    handleGetAllProductsList();
  }, []);
  useEffect(() => {
    handleGetAllMaterials();
  }, []);
  const handleGetAllMaterials = (type) => {
    console.log(params.id);
    let payload = {
      id: params.id
    };
    dispatch(getDispenseMaterialDetails(payload)).then(function (result) {
      if (result?.payload?.success) {
        if (result?.payload?.data?.type == 'bmr') {
          //   setDispenseData(result?.payload?.data);
          setProduct([result?.payload?.data?.product_detail]);
          setValue(result?.payload?.data?.bmr_partial_details);
          setType({
            ...type,
            ['type']: result?.payload?.data?.type,
            ['sub_type']:
              result?.payload?.data?.is_partial == 'false' ? 'full' : 'partial'
          });
          form.setFieldsValue({
            batch_number: result?.payload?.data?.product_batch_number
          });
        } else {
          setType({
            ...type,
            ['type']: result?.payload?.data?.type,
            ['sub_type']:
              result?.payload?.data?.is_partial == 'false' ? 'full' : 'partial'
          });
          const dis_materials = [];
          result?.payload?.data?.dispensing_materials?.map((item) => {
            dis_materials.push({
              batch_id: item?.batch_details_id,
              required_weight: item?.required_weight,
              material_id: item?.material_id,
              material_name: item?.material?.name,
              material_code: item?.material?.code,
              batch_number: item?.batch_details?.inhouse_batch_number,
              dispensing_material_id: item?.id
            });
          });
          setData(dis_materials);
        }
      }
    });
  };

  console.log(data);
  const handleGetAllMaterialsList = () => {
    let payload = {};
    dispatch(getAllFilteredMaterials(payload));
  };

  const handleGetAllProductsList = () => {
    let payload = {};
    dispatch(getAllproducts(payload));
  };

  const handleApprove = () => {
    const payload = {
      dispensing_request_id: params.id,
      status: 'WH LC Pending'
    };
    dispatch(ApproveDispensing(payload)).then((response) => {
      if (response?.payload?.success) {
        history.push('/dispense-qa-materials-list');
      }
    });
  };
  const onFinish = (values) => {
    setTruckShow(true);
    console.log(type);
    const payload = {
      type: user?.role == 'qa_initiator' ? 'material' : type?.type,
      is_partial: type?.sub_type == 'partial' ? true : false,
      sub_type: type?.sub_type,
      product_batch_number: values?.batch_number,
      effective_date: product[0]?.effective_date,
      batch_size: product[0]?.theoretical_batch_size,
      dispensing_request_id: params.id,
      ref_mfc: product[0]?.ref_mfc,
      shelf_life: product[0]?.shelf_life,
      manufacturing_site: product[0]?.manufacturing_site,
      customer_details: product[0]?.customer_details,
      manufacturing_date: values.manufacturing_date,
      manufacturing_start_date: values.manufacturing_start_date,
      expiry_date: values.expiry_date,
      manufacturing_end_date: values.manufacturing_end_date,
      product_id: values.product_id,
      bmr_partial_details: value,
      material_list: data,
      bmr_number: product[0]?.bmr_bpr_code
    };
    dispatch(createDispensing(payload)).then(function (response) {
      if (response?.payload?.success) {
        setTruckShow(false);
        type?.type == 'bmr' && type?.sub_type == 'auto'
          ? history.push(
              `/product-details/${response?.payload?.data?.product_id}`
            )
          : user?.role == 'qa_initiator'
          ? history.push('/dispense-qa-materials-list')
          : history.push('/dispense-qa-materials-list');
      } else {
        setTruckShow(false);
      }
    });
  };
  console.log(product);

  const onChangeWeight = (value, index) => {
    console.log(value);
    setData((prevData) => {
      const newData = [...prevData];
      const newItem = { ...newData[index] };
      newItem.required_weight = value;
      newData[index] = newItem;

      return newData;
    });
  };

  const columns = [
    {
      title: 'Material Code',
      dataIndex: 'material_code',
      key: 'material_code',
      width: '150px',
      ellipsis: true,
      sorter: (a, b) => a?.material_code?.localeCompare(b?.material_code),
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Material Name',
      dataIndex: 'material_name',
      key: 'material_name',
      width: '150px',
      ellipsis: true,
      sorter: (a, b) => a?.material_name?.localeCompare(b?.material_name),
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Required Weight(UOM)',
      dataIndex: 'required_weight',
      key: 'required_weight',
      width: '150px',
      ellipsis: true,
      sorter: (a, b) => a?.required_weight?.localeCompare(b?.required_weight),
      render: (text, _, index) =>
        params.id ? (
          <InputNumber
            value={data[index].required_weight}
            min={0}
            style={{
              width: '100%'
            }}
            placeholder="Enter Required Weight"
            onChange={(v) => onChangeWeight(v, index)}
          />
        ) : (
          text
        ),
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    }
  ];
  return (
    <Layout sider={false}>
      <Card className={styles.SubPanel}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
        >
          <div className={`${styles.table_container}`}>
            <CustomTable
              columns={columns}
              dataSource={data || []}
              scroll={{ x: 'auto' }}
            />
          </div>
          <FormItem label="Comments" className={styles.customLable}>
            <TextArea name="Comments" placeholder="Comments" />
          </FormItem>
          <br />
          <FormItem className="text-center">
            <Space>
              <CustomButton
                type="primary"
                loading={loading}
                ghost
                onClick={() => history.back()}
              >
                Back
              </CustomButton>
              {truckShow ? (
                <Image
                  src={truck}
                  preview={false}
                  style={{ width: '100px !important', height: '100px' }}
                />
              ) : params.id && user?.role == 'qc_initiator' ? (
                <CustomButton
                  type="primary"
                  onClick={() => handleApprove()}
                  loading={loading}
                >
                  {'Approve'}
                </CustomButton>
              ) : (
                <CustomButton
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  {(params.id &&
                    user?.role == 'qa_initiator' &&
                    user?.level == 3) ||
                  Superadmindata?.[0]?.level == 3
                    ? 'Update'
                    : type?.type == 'bmr' && type?.sub_type == 'auto'
                    ? 'Next'
                    : 'Submit'}
                </CustomButton>
              )}
            </Space>
          </FormItem>
        </Form>
      </Card>
    </Layout>
  );
};
export default DispensingView;
