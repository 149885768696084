import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  container,
  wastage,
  Createwastage,
  updatewastage,
  wastageReasons
} from './api';

const initialState = {
  allMaterials: []
};

const actions = {
  GET_ALL_CONTAINERS: 'stocks/GET_ALL_CONTAINERS',
  CREATE_WASTAGE: 'stocks/CREATE_DISPATCH',
  GET_PAST_DETAILS: 'stocks/GET_PAST_DETAILS',
  GET_ALL_WASTAGE: 'stocks/GET_ALL_WASTAGE',
  UPDATE_WASTAGE: 'stocks/UPDATE_WASTAGE',
  GET_WASTAGE_REASONS: 'stocks/GET_WASTAGE_REASONS'
};

export const getAllContainers = createAsyncThunk(
  actions.GET_ALL_CONTAINERS,
  async (payload) => {
    const response = await container('all', payload);

    return response;
  }
);

export const getPastContainerDetails = createAsyncThunk(
  actions.GET_PAST_DETAILS,
  async (payload) => {
    const response = await wastage('all', payload);

    return response;
  }
);

export const createWastage = createAsyncThunk(
  actions.CREATE_WASTAGE,
  async (payload) => {
    const response = await Createwastage('create', payload);

    return response;
  }
);

export const getAllWastage = createAsyncThunk(
  actions.GET_ALL_WASTAGE,
  async (payload) => {
    const response = await wastage('all', payload);

    return response;
  }
);

export const updateWastage = createAsyncThunk(
  actions.UPDATE_WASTAGE,
  async (payload) => {
    const response = await updatewastage(`create?id=${payload?.id}`, payload);

    return response;
  }
);

export const getWastageReasons = createAsyncThunk(
  actions.GET_WASTAGE_REASONS,
  async (payload) => {
    const response = await wastageReasons(`reasons`, payload);

    return response;
  }
);

export const stocksSlice = createSlice({
  name: 'stocks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllContainers.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllContainers.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllContainers.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createWastage.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createWastage.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createWastage.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getPastContainerDetails.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getPastContainerDetails.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getPastContainerDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllWastage.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllWastage.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllWastage.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(updateWastage.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(updateWastage.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(updateWastage.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getWastageReasons.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getWastageReasons.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getWastageReasons.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
  }
});
export default stocksSlice.reducer;
