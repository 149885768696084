import React, { useState } from 'react';
import {
  Row,
  Col,
  Form,
  Card,
  Input,
  Space,
  Select,
  message,
  Image,
  TimePicker
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import truck from '@/features/qa/components/images/truck-delivery-done.gif';
import Layout from '@/common/Layout';
import {
  useCreateVaccumCleaningMutation,
  useGetVaccumCleaningLocationMastersQuery,
  useGetVaccumCleaningIdMastersQuery
} from '../../redux/apiSlice';
import moment from 'moment';
import LiveTimeInput from '@/common/Clock';

import LocalStorage from 'utilities/localStorage';
const user = LocalStorage.getItem('Gravity_user');
console.log('user', user?.user?.employee);

const createProductNewPage = () => {
  const [form] = Form.useForm();

  // #region Initial states - Start
  const [truckShow] = useState(false);
  const [search, setSearch] = useState('');

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [filterStatus] = useState([
    {
      id: 1,
      label: 'Good',
      value: 'Good'
    },
    {
      id: 2,
      label: 'Ok',
      value: 'Ok'
    },
    {
      id: 3,
      label: 'Bad',
      value: 'Bad'
    }
  ]);
  // #endregion

  // #region Showing success or error message - Start
  const handleShowMessage = (messageType, messageText) => {
    messageApi.open({
      type: messageType,
      content: messageText,
      duration: 3
    });
  };
  // #endregion

  // #region Fetching Vacuum Cleaning Location Masters - Start
  let {
    data: vacuumCleaningLocationMasters,
    refetch: vacuumCleaningLocationMastersRefetch
  } = useGetVaccumCleaningLocationMastersQuery({ search });
  // #endregion

  // #region Fetching Vacuum Cleaning Id Masters - Start
  let {
    data: vacuumCleaningIdMasters,
    refetch: vacuumCleaningIdMastersRefetch
  } = useGetVaccumCleaningIdMastersQuery({ search });
  // #endregion

  // #region Creating Vacuum Cleaning Record - Start
  let [createVacuumCleaning] = useCreateVaccumCleaningMutation();

  const onFinish = async (values) => {
    setSubmitButtonDisabled(true);

    const values2 = { ...values };
    values2.cleaned_by_user_date = moment(new Date()).format();
    values2.date = moment(new Date()).format();
    values2.cleaning_start_time = moment(values.cleaning_start_time).format(
      'HH:mm:ss'
    );
    values2.cleaning_end_time = moment(values.cleaning_end_time).format(
      'HH:mm:ss'
    );

    const response = await createVacuumCleaning({
      ...values2
    });
    if (response?.data?.success === true) {
      handleShowMessage('success', response?.data?.message);
      setTimeout(() => history.push('/log-books-vacuum-cleaner-list'), 1500);
    } else {
      setSubmitButtonDisabled(false);
      handleShowMessage('error', response?.error?.data?.message);
    }
  };
  // #endregion

  // #region All onSearch events handler - Start
  const onSearch = async (e, name) => {
    if (e.length === 0 || e.length >= 3) {
      if (name === 'location') {
        setSearch(e);
        vacuumCleaningLocationMastersRefetch();
      } else if (name === 'id') {
        setSearch(e);
        vacuumCleaningIdMastersRefetch();
      }
    }
  };
  // #endregion

  return (
    <Space>
      {contextHolder}
      <Layout sider={false}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            cleaned_by_user_id: user?.user?.employee,
            display_cleaned_by: `${user?.user?.employee} (${moment(
              new Date()
            ).format('DD-MM-YY')})`
          }}
        >
          <Card
            title={'CLEANING RECORD OF VACUUM CLEANER'}
            className={styles.CustomPanel}
          >
            <Row gutter={24}>
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label="Location"
                  name="locationAreaMasterId"
                  rules={[
                    {
                      required: true,
                      message: 'Select Area'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Select
                    placeholder="Select Location"
                    showSearch
                    optionFilterProp="children"
                    onSearch={(e) => onSearch(e, 'location')}
                  >
                    {vacuumCleaningLocationMasters?.data?.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.location}
                        </option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label="ID No"
                  name="cleaningOfVaccumCleanerIdNoMasterId"
                  rules={[
                    {
                      required: true,
                      message: 'Select ID'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Select
                    placeholder="Select ID"
                    showSearch
                    optionFilterProp="children"
                    onSearch={(e) => onSearch(e, 'id')}
                  >
                    {vacuumCleaningIdMasters?.data?.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.id_no}
                        </option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Date"
                  name="display_date"
                  className={styles.customLable}
                >
                  <LiveTimeInput valueType="date" />
                  {/* <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  /> */}
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label="Cleaning start time"
                  name="cleaning_start_time"
                  rules={[
                    {
                      required: true,
                      message: 'Select start time'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <TimePicker
                    placeholder="Select start time"
                    style={{ width: '100%' }}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label="Cleaning end time"
                  name="cleaning_end_time"
                  rules={[
                    {
                      required: true,
                      message: 'Select end time'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <TimePicker
                    placeholder="Select end time"
                    style={{ width: '100%' }}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label="Filter Bag / Filter Status"
                  name="filter_status"
                  rules={[
                    {
                      required: true,
                      message: 'Select Filter Status'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Select
                    placeholder="Select Filter Status"
                    showSearch
                    optionFilterProp="children"
                  >
                    {filterStatus?.map((item, index) => {
                      return (
                        <option value={item.value} key={index}>
                          {item.label}
                        </option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Cleaned by (User Id & Date)"
                  name="display_cleaned_by"
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Remarks"
                  name="remarks"
                  className={styles.customLable}
                >
                  <Input.TextArea placeholder="" style={{ width: '100%' }} />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24} hidden={true}>
                <FormItem
                  label="Cleaned by"
                  name="cleaned_by_user_id"
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24} hidden={true}>
                <FormItem
                  label="Cleaned by"
                  name="cleaned_by_user_date"
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </FormItem>
              </Col>
            </Row>
          </Card>

          <br />
          <FormItem className="text-center">
            <Space>
              {truckShow ? (
                <Image
                  src={truck}
                  preview={false}
                  style={{ width: '100px !important', height: '100px' }}
                />
              ) : (
                <CustomButton
                  className={styles.inwardButton}
                  htmlType="submit"
                  type="primary"
                  onSubmit={(e) => onFinish(e)}
                  disabled={submitButtonDisabled}
                >
                  Create
                </CustomButton>
              )}
              <CustomButton
                className={styles.inwardButton}
                type="primary"
                ghost
                onClick={() => history.back()}
              >
                Back
              </CustomButton>
            </Space>
          </FormItem>
        </Form>
      </Layout>
    </Space>
  );
};
export default createProductNewPage;
