import React from 'react';
import Layout from '@/common/Layout';
import UpnextReceiptsPage from './components/UpnextReceiptsPage';

const UpnextReceipts = () => {
  return (
    <>
      <Layout sider={false}>
        <UpnextReceiptsPage />
      </Layout>
    </>
  );
};

export default UpnextReceipts;
