import React, { useState, useEffect } from 'react';
// import Layout from '@/common/Layout';
import { Form, Card, Space, Input } from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import { getAllProcesses } from '../../redux/slice';
import CustomTable from 'common/Content/CustomTable';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import Layout from '@/common/Layout';
import { useDispatch } from 'react-redux';
import { getLoggedInUser } from 'utilities/helpers';

const { Search } = Input;

const EBMRMasterList = ({ loading }) => {
  const [form] = Form.useForm();
  const { user } = getLoggedInUser();
  // const { SuperadminProddata } = getRoles('Production');
  //   const { allSamples } = useSelector((state) => state.stocks);
  const [allsuppliers, setAllsuppliers] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [search, setSearch] = useState('');
  //   console.log(allsamples);
  const dispatch = useDispatch();
  useEffect(() => {
    handleGetAllProcessess();
  }, [page, search]);

  const handleGetAllProcessess = () => {
    const payload = { page: page, search: search };
    dispatch(getAllProcesses(payload)).then((response) => {
      if (response?.payload?.success) {
        setAllsuppliers(response?.payload?.data);
        setPagination(response?.payload?.pagination);
      }
    });
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const columns = [
    {
      title: 'Process Group Name',
      dataIndex: 'process_group_name',
      key: 'process_group_name',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return <a href={`/ebmr-masters-create/${record.id}`}>{text}</a>;
      }
    }
  ];

  return (
    <>
      <Layout sider={false}>
        <Form form={form} layout="vertical">
          <Card
            title={<span>EBMR Details</span>}
            className={`${styles.cardStyles_approver}`}
            extra={
              <Space>
                {user?.role == 'prod_user' || user?.role == 'qa_super_user' ? (
                  <CustomButton
                    className={styles.inwardButton}
                    // onClick={() => history.push('/create-product')}
                    onClick={() => history.push('/ebmr-masters-create')}
                    type="primary"
                  >
                    Create
                  </CustomButton>
                ) : (
                  ''
                )}
                <Search
                  onChange={(e) => handleChange(e)}
                  className={`w-100 ${styles.searchbar}`}
                  justify="start"
                  align="start"
                  placeholder="Search by Process group Name"
                />
              </Space>
            }
          >
            <div className={`${styles.table_container}`}>
              <CustomTable
                columns={columns}
                pagination={{
                  size: 'small',
                  total: pagination?.total_records || 0,
                  showSizeChanger: false,
                  onChange: (page) => {
                    setPage(page);
                  }
                }}
                dataSource={allsuppliers}
                loading={loading}
              />
            </div>
          </Card>
          <br />
          <FormItem className="text-center">
            <CustomButton
              className={styles.inwardButton}
              onClick={() => history.back()}
              type="primary"
            >
              Back to Home
            </CustomButton>
          </FormItem>
        </Form>
      </Layout>
    </>
  );
};
export default EBMRMasterList;
