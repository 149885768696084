import apiClient from 'utilities/apiClient';

export const create_checklist = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.lc}/${url}`, payload, true);
};

export const lcMaterialDetails = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.lc}/${url}`, payload, true);
};

export const print_lc = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.apiUrl}/${url}`, payload, true);
};
