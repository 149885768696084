import React, { useEffect } from 'react';
import Layout from '@/common/Layout';
import { Row, Col, Form, Card, Input, InputNumber, message } from 'antd';
import styles from '@/common/Layout/index.module.less';
import { getDispenseMaterialDetails } from '@/features/dispensing/redux/slice';
import FormItem from '@/common/FormItem';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useState } from 'react';
import CustomButton from '@/common/CustomButton';
import { DeleteOutlined, PlusCircleFilled } from '@ant-design/icons';
import { createGranuler } from '../../redux/slice';
import { history } from 'app/history';

const granulation = () => {
  const { materialDetails } = useSelector((state) => state.dispensing);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const params = useParams();
  const [lots, setLots] = useState([
    {
      weights: [
        {
          water_ar: '',
          tare_weight: '',
          net_weight: '',
          gross_weight: '',
          lot: 1
        }
      ]
    },
    {
      weights: [
        {
          water_ar: '',
          tare_weight: '',
          net_weight: '',
          gross_weight: '',
          lot: 2
        }
      ]
    },
    {
      weights: [
        {
          water_ar: '',
          tare_weight: '',
          net_weight: '',
          gross_weight: '',
          lot: 3
        }
      ]
    },
    {
      weights: [
        {
          water_ar: '',
          tare_weight: '',
          net_weight: '',
          gross_weight: '',
          lot: 4
        }
      ]
    }
  ]);

  useEffect(() => {
    handleGetAllMaterials();
  }, []);

  const handleGetAllMaterials = () => {
    let payload = {
      id: params.id
    };
    dispatch(getDispenseMaterialDetails(payload));
  };

  const handleAddWeight = (i, index) => {
    let temp_lots = [...lots];
    temp_lots[index].weights.push({
      water_ar: '',
      tare_weight: '',
      net_weight: '',
      gross_weight: '',
      lot: index + 1
    });
    setLots(temp_lots);
  };

  const handleremoveWeight = (i, index) => {
    let temp_lots = [...lots];
    temp_lots[index].weights.splice(i, 1);
    setLots(temp_lots);
  };

  const handleChange = (type, i, index, e) => {
    let temp_lots = [...lots];
    console.log(type, e);
    if (type === 'tare_weight') {
      temp_lots[index].weights[i]['gross_weight'] =
        e + (temp_lots[index].weights[i]['net_weight'] || 0);
    } else if (type === 'net_weight') {
      temp_lots[index].weights[i]['gross_weight'] =
        e + (temp_lots[index].weights[i]['tare_weight'] || 0);
    }
    if (type == 'tare_weight' || type == 'net_weight') {
      temp_lots[index].weights[i][type] = e;
    } else {
      temp_lots[index].weights[i][type] = e.target.value;
    }
    setLots(temp_lots);
  };

  const onFinish = () => {
    var product_granulation = [];
    console.log(lots);
    lots.map((item) => {
      item?.weights.map((data) => {
        product_granulation.push(data);
      });
    });
    console.log(product_granulation);
    const payload = {
      dispensing_request_id: params.id,
      product_granulation: lots
    };
    console.log(payload);
    dispatch(createGranuler(payload)).then((response) => {
      if (response?.payload?.success) {
        message.success(response?.payload.message);
        history.push(`/sifting-and-milling/${params.id}`);
      }
    });
  };
  return (
    <Layout sider={false}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
      >
        <Card
          title={<span style={{ fontWeight: 'bold' }}>Granulation</span>}
          className={styles.CustomPanel}
        >
          <Card>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem label="Product Name" className={styles.customLable}>
                  <Input
                    disabled
                    value={materialDetails?.product_detail?.name || 'NA'}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="Product Batch Number"
                  className={styles.customLable}
                >
                  <Input
                    disabled
                    value={materialDetails?.product_batch_number || 'NA'}
                  />
                </FormItem>
              </Col>

              <Col span={12}>
                <FormItem label="Effective Date" className={styles.customLable}>
                  <Input
                    disabled
                    value={
                      materialDetails?.effective_date
                        ? moment(materialDetails?.effective_date).format(
                            'DD MMM YYYY'
                          )
                        : 'NA'
                    }
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="Batch Size" className={styles.customLable}>
                  <Input
                    disabled
                    value={materialDetails?.product_detail?.batch_size || 'NA'}
                  />
                </FormItem>
              </Col>
            </Row>
          </Card>
          {lots?.map((item, index) => {
            return (
              <Card key={index} title={`Binder Preparation - Lot${index + 1} `}>
                {item?.weights?.map((data, i) => {
                  return (
                    <Row gutter={24} key={i}>
                      <Col xs={24} xl={4} span={24} md={24} sm={24}>
                        <FormItem
                          label="Water AR #"
                          rules={[
                            {
                              required: false,
                              message: 'Enter Water AR #'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Input
                            placeholder="Enter Water AR ID"
                            value={data?.water_ar || ''}
                            onChange={(e) =>
                              handleChange('water_ar', i, index, e)
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={4} span={24} md={24} sm={24}>
                        <FormItem
                          label="Tare Weight"
                          rules={[
                            {
                              required: false,
                              message: 'Enter Tare Weight'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <InputNumber
                            style={{ width: '100%' }}
                            min={0}
                            placeholder="Enter Tare Weight"
                            value={data?.tare_weight || ''}
                            onChange={(e) =>
                              handleChange('tare_weight', i, index, e)
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={2} span={24} md={24} sm={24}>
                        <FormItem
                          label="UOM"
                          rules={[
                            {
                              required: false,
                              message: 'Enter UOM'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Input
                            placeholder="UOM"
                            value={lots[0].weights[0].uom || ''}
                            onChange={(e) => handleChange('uom', i, index, e)}
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={4} span={24} md={24} sm={24}>
                        <FormItem
                          label="Net Weight"
                          rules={[
                            {
                              required: false,
                              message: 'Enter Net Weight'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <InputNumber
                            style={{ width: '100%' }}
                            min={0}
                            placeholder="Enter Net Weight"
                            value={data?.net_weight || ''}
                            onChange={(e) =>
                              handleChange('net_weight', i, index, e)
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={2} span={24} md={24} sm={24}>
                        <FormItem
                          label="UOM"
                          rules={[
                            {
                              required: false,
                              message: 'Enter UOM'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Input
                            placeholder="UOM"
                            value={lots[0].weights[0].uom || ''}
                            disabled
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={4} span={24} md={24} sm={24}>
                        <FormItem
                          label="Gross Weight"
                          rules={[
                            {
                              required: false,
                              message: 'Enter Gross Weight'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Input
                            placeholder="Enter Gross Weight"
                            value={data?.gross_weight || ''}
                            onChange={(e) =>
                              handleChange('gross_weight', i, index, e)
                            }
                            disabled
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={2} span={24} md={24} sm={24}>
                        <FormItem
                          label="UOM"
                          rules={[
                            {
                              required: false,
                              message: 'Enter UOM'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Input
                            placeholder="UOM"
                            value={lots[0].weights[0].uom}
                            disabled
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={2} span={24} md={24} sm={24}>
                        <FormItem
                          label=""
                          rules={[
                            {
                              required: false,
                              message: 'Enter UOM'
                            }
                          ]}
                          className={styles.customLableButton}
                        >
                          {i == 0 ? (
                            <CustomButton
                              onClick={() => handleAddWeight(i, index)}
                            >
                              <PlusCircleFilled />
                            </CustomButton>
                          ) : (
                            ''
                          )}
                          {i > 0 ? (
                            <CustomButton
                              onClick={() => handleremoveWeight(i, index)}
                            >
                              <DeleteOutlined />
                            </CustomButton>
                          ) : (
                            ''
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  );
                })}
              </Card>
            );
          })}
        </Card>
        <br />
        <FormItem className="text-center">
          <CustomButton
            className={styles.inwardButton}
            htmlType="submit"
            type="primary"
          >
            Save & Proceed
          </CustomButton>
        </FormItem>
      </Form>
    </Layout>
  );
};
export default granulation;
