import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  message,
  Card,
  Space,
  Input,
  Select,
  InputNumber
} from 'antd';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDocsbyId,
  // updateInvoice,
  getAllMaterialslist
} from '../../redux/slice';
import { useParams } from 'react-router-dom';
import CustomButton from '@/common/CustomButton';
import { getLoggedInUser, getRoles } from 'utilities/helpers';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import { history } from 'app/history';
import VerificationModal from '../VerificationModal';
import { EditOutlined } from '@ant-design/icons';

dayjs.extend(weekday);
dayjs.extend(localeData);
// const dateFormat = 'YYYY/MM/DD';
// const weekFormat = 'MM/DD';
// const monthFormat = 'YYYY/MM';
// import CustomButton  from '@/common/CustomButton';
const commonviewMaterialPage = ({ loading }) => {
  const params = useParams();
  const { material, MaterialsList } = useSelector((state) => state.material);
  // console.log(material);
  const dispatch = useDispatch();
  const { user } = getLoggedInUser();
  const { Superadmindata } = getRoles('Warehouse');

  const [isinvoice, setIsinvoice] = useState(true);
  const [invoice, setInvoice] = useState(material?.invoice_number);
  const [isEdit, setIsEdit] = useState(false);
  const [containers, setContainers] = useState([]);
  const [isMatEdit, setIsMatEdit] = useState(false);
  const [isModal, setIsModal] = useState(false);
  // const [materials, setMaterials] = useState();
  const [pagetype, setPagetype] = useState('');
  const [search, setSearch] = useState('');
  const [materials, setMaterials] = useState([]);
  const [poqty, setPoqty] = useState(0);
  const [unitRate, setUnitRate] = useState(0);
  const [pONumber, setPONumber] = useState(material?.po_number);
  const [poDetails, setPoDetails] = useState({});
  const [cgstdata, setCgstdata] = useState('');
  const [sgstdata, setSgstdata] = useState('');
  const [igstdata, setIgstdata] = useState('');
  const [totalPrice, setTotalPrice] = useState('');

  const [form] = Form.useForm();
  console.log(
    material?.no_of_bacthes_invoice,
    material?.batch_details?.length,
    setIsEdit
  );
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo.errorFields.length);
    if (errorInfo.errorFields.length > 0) {
      message.error(
        'Please select a response to all the questions in the checklist'
      );
    }
  };

  const onSearch = (value) => {
    setSearch(value);
  };

  const handleCapacity = (e, name) => {
    setMaterials({ ...materials, [name]: e });
  };
  console.log(isinvoice);
  console.log(setContainers, setIsMatEdit, setPagetype);
  const hanldeChange = (e) => {
    setPoqty(e);
  };
  const hanldeChangeUnit = (e) => {
    setUnitRate(e);
  };

  const handleChangePO = (e) => {
    setPONumber(e.target.value);
  };

  useEffect(() => {
    form.setFieldsValue({
      invoice_no: localStorage.getItem('invoice_num'),
      material_code: localStorage.getItem('material_code'),
      material_name: localStorage.getItem('material_name'),
      data_logger: material?.vehicle_storage_area?.vehicle_storage_area
        ? 'Yes'
        : 'No',
      labels_defaced: material?.vehicle_storage_area
        ?.vendor_status_label_defaced
        ? 'Yes'
        : 'No',
      dedusting: material?.vehicle_storage_area?.material_dedusting_status,
      seal_integrity: material?.vehicle_storage_area?.container_seal_integrity,
      po_number: material?.po_number,
      po_qty: material?.po_qty,
      po_unit_rate: material?.po_unit_rate,
      cgst: material?.cgst,
      sgst: material?.sgst,
      igst: material?.igst,
      total_inv_price: material?.total_inv_price
    });
  }, []);
  console.log(invoice, isEdit, setIsinvoice, isMatEdit);
  useEffect(() => {
    handleGetDocsbyId();
    handleGetAllMaterialsList();
  }, [isModal, search, isinvoice]);

  // const handleupdate = (id) => {
  //   containers['batch_id'] = id;
  //   containers['doc_id'] = params.id;
  //   containers['invoice_number'] = invoice;
  //   console.log(containers);
  //   dispatch(updateBatch(containers)).then((response) => {
  //     if (response?.payload?.success) {
  //       message.success(response?.payload?.message);
  //       history.push('/list');
  //     }
  //   });
  // };
  const handleinvoicedetails = (type) => {
    setPagetype(type);
    setIsinvoice(false);
  };
  const handeChangeGst = (e, type) => {
    type == 'cgst'
      ? setCgstdata(e.target.value)
      : type == 'sgst'
      ? setSgstdata(e.target.value)
      : type == 'igst'
      ? setIgstdata(e.target.value)
      : type == 'total_inv_price'
      ? setTotalPrice(e.target.value)
      : '';
  };

  const handleinvoice = (type) => {
    setIsModal(true);
    setPagetype(type);
    type == 'invoice'
      ? setPoDetails({
          cgst: cgstdata ? cgstdata : material?.cgst,
          sgst: sgstdata ? sgstdata : material?.sgst,
          igst: igstdata ? igstdata : material?.igst,
          total_inv_price: totalPrice ? totalPrice : material?.total_inv_price
        })
      : '';
    type == 'po'
      ? setPoDetails({
          po_number: pONumber ? pONumber : material?.po_number,
          po_qty: poqty ? poqty : material?.po_qty,
          po_unit_rate: unitRate ? unitRate : material?.po_unit_rate,
          document_id: params?.id,
          po_total_amount:
            poqty !== 0 || unitRate !== 0
              ? (poqty !== 0
                  ? parseFloat(poqty)
                  : parseFloat(material?.po_qty)) *
                (unitRate !== 0
                  ? parseFloat(unitRate)
                  : parseFloat(material?.po_unit_rate))
              : material?.po_total_amount
        })
      : '';
    // const payload = {
    //   doc_id: params.id,
    //   invoice_number: invoice
    // };
    // dispatch(updateInvoice(payload)).then((response) => {
    //   if (response?.payload?.success) {
    //     message.success(response?.payload?.message);
    //     history.push('/list');
    //   }
    // });
  };

  // const handleCapacity = (e) => {
  //   const { name, value } = e.target;
  //   setMaterials({ ...materials, [name]: value });
  // };

  const handleGetDocsbyId = () => {
    const payload = {
      id: params.id
    };
    dispatch(getDocsbyId(payload));
  };

  const handleGetAllMaterialsList = () => {
    let payload = { search };
    dispatch(getAllMaterialslist(payload));
  };

  // const onFinish = (values) => {
  //   setIsModal(true);
  //   setPagetype('po');
  //   setPoDetails({
  //     cgst: values?.cgst,
  //     sgst: values?.sgst,
  //     igst: values?.igst,
  //     total_inv_price: values?.total_inv_price
  //   });
  // };
  // const handleEdit = (value) => {
  //   setContainers([]);
  //   setIsEdit({ [value]: true });
  // };

  // const handleEditMaterial = () => {
  //   setIsMatEdit(true);
  // };

  // const disabledDate = (current) => {
  //   return current && current > dayjs().endOf('day');
  // };

  // const dateFormat = 'YYYY-MM-DD';

  // const handleChangegross = (index, e) => {
  //   console.log(e);
  //   const { name, value } = e.target;
  //   setContainers({ ...containers, [name]: value });
  // };
  // const handleChangedate = (name, date, dateString, index) => {
  //   console.log(index);
  //   console.log(date);
  //   // const list = [...containers];
  //   // list[index][name] = dateString;
  //   // setContainers(list);
  //   setContainers({ ...containers, [name]: dateString });
  // };

  // const handleStandardCancel = () => {
  //   history.push('/dashboard');
  // };

  // const handleVerification = (type, id) => {
  //   containers['id'] = id;
  //   //   containers['doc_id'] = params.id;
  //   //   containers['invoice_number'] = invoice;
  //   setPagetype(type);
  //   setIsModal(true);
  // };
  return (
    // <Layout sider={false}>
    <>
      <Form
        form={form}
        layout="vertical"
        // onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Card title="DOCUMENT VERIFICATION" className={styles.CustomPanel}>
          <Row gutter={24}>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>
                Is the Material meant for Graviti?
              </p>
              <p className={styles.customValue}>
                {material?.for_graviti == true
                  ? 'Yes'
                  : material?.for_graviti == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>
                Invoice/DC/BOE Number Available?
              </p>
              <p className={styles.customValue}>
                {material?.invoice_dc_boe_available == true
                  ? 'Yes'
                  : material?.invoice_dc_boe_available == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>Purchase Order Available?</p>
              <p className={styles.customValue}>
                {material?.po_available == true
                  ? 'Yes'
                  : material?.po_available == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>COA/COC, MSDS Available?</p>
              <p className={styles.customValue}>
                {material?.coa_coc_msda_available == true
                  ? 'Yes'
                  : material?.coa_coc_msda_available == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>Material Vendor Status</p>
              <p className={styles.customValue}>
                {material?.material_vendor_status?.toUpperCase() || 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>
                Manufacturing site address matching with Provisionally
                Approved/Approved Vendor list
              </p>
              <p className={styles.customValue}>
                {material?.manufacture_site_address_matching == true
                  ? 'Yes'
                  : material?.manufacture_site_address_matching == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
          </Row>
        </Card>
        <br />
        <Card title="VEHICLE STORAGE AREA" className={styles.CustomPanel}>
          <Row gutter={24}>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>Vehicle Cleanliness</p>
              <p className={styles.customValue}>
                {material?.vehicle_storage_area?.vehicle_cleanliness == true
                  ? 'Yes'
                  : material?.vehicle_storage_area?.vehicle_cleanliness == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>
                Storage In Enclosed Condition
              </p>
              <p className={styles.customValue}>
                {material?.vehicle_storage_area?.storage_enclose == true
                  ? 'Yes'
                  : material?.vehicle_storage_area?.storage_enclose == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>Storage Condition Available</p>
              <p className={styles.customValue}>
                {material?.vehicle_storage_area?.storage_conditions == true
                  ? 'Yes'
                  : material?.vehicle_storage_area?.storage_conditions == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
          </Row>
        </Card>
        <br />
        <Card
          title="MATERIAL DETAILS"
          className={styles.CustomPanel}
          extra={
            user?.level == 3 || Superadmindata?.[0]?.level == 3 ? (
              <CustomButton onClick={() => handleinvoicedetails('material')}>
                <EditOutlined />
              </CustomButton>
            ) : (
              ''
            )
          }
        >
          {!isinvoice && pagetype == 'material' ? (
            <Row gutter={24}>
              <Col xs={24} xl={8} span={8} md={24} sm={24}>
                <FormItem
                  label="Material Code"
                  name="material_id"
                  rules={[
                    {
                      required: true,
                      message: 'Select Material Code'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Select
                    showSearch
                    placeholder="Select a material code"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // name="material_id"
                    onSearch={onSearch}
                    onChange={(e) => handleCapacity(e, 'material_id')}
                  >
                    {MaterialsList?.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.code}
                        </option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <FormItem
                  label="Material Name"
                  rules={[
                    {
                      required: false,
                      message: 'Enter Material name'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter Material name"
                    disabled
                    value={material?.material?.name}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <FormItem label="No.of Batches" className={styles.customLable}>
                  <Input
                    placeholder="Enter No.of Batches"
                    onChange={(e) => setInvoice(e.target.value)}
                    disabled
                    defaultValue={material?.no_of_bacthes_invoice}
                  />
                </FormItem>
              </Col>
            </Row>
          ) : (
            <Row gutter={24}>
              <Col xs={24} xl={8} span={8}>
                <p className={styles.customLable}>Material Code</p>
                <p className={styles.customValue}>{material?.material?.code}</p>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <p className={styles.customLable}>Material Name</p>
                <p className={styles.customValue}>{material?.material?.name}</p>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <p className={styles.customLable}>No.of Batches</p>
                <p className={styles.customValue}>
                  {material?.no_of_bacthes_invoice}
                </p>
              </Col>
            </Row>
          )}
          {!isinvoice && pagetype == 'material' ? (
            <FormItem className="text-center">
              <Space>
                <CustomButton
                  onClick={() => history.back()}
                  loading={loading}
                  type="primary"
                  ghost
                >
                  Back
                </CustomButton>
                <CustomButton
                  type="primary"
                  // htmlType="submit"
                  loading={loading}
                  className={styles.border_button}
                  onClick={() => handleinvoice('invoicedetails')}
                  ghost
                >
                  Initiate Verification
                </CustomButton>
                {/* <CustomButton
                  onClick={() => handleinvoice()}
                  loading={loading}
                  type="primary"
                >
                  Update
                </CustomButton> */}
              </Space>
            </FormItem>
          ) : (
            ''
          )}
        </Card>
        <br />
        <Card
          title="PO DETAILS"
          className={styles.CustomPanel}
          extra={
            user?.level == 3 || Superadmindata?.[0]?.level == 3 ? (
              <CustomButton onClick={() => handleinvoicedetails('po')}>
                <EditOutlined />
              </CustomButton>
            ) : (
              ''
            )
          }
        >
          {!isinvoice && pagetype == 'po' ? (
            <Row gutter={24}>
              <Col xs={12} xl={6} span={12}>
                <FormItem
                  label="PO Number"
                  // name="po_number"
                  rules={[
                    {
                      required: true,
                      message: 'Enter PO number'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter PO number"
                    value={pONumber ? pONumber : material?.po_number}
                    onChange={(e) => handleChangePO(e)}
                  />
                </FormItem>
              </Col>
              <Col xs={12} xl={6} span={12}>
                <FormItem
                  label="PO Quantity"
                  // name="po_qty"
                  rules={[
                    {
                      required: true,
                      message: 'Enter PO qty'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <InputNumber
                    min={0}
                    style={{ width: '100%' }}
                    placeholder="Enter PO qty"
                    onChange={(e) => hanldeChange(e)}
                    value={poqty ? poqty : material?.po_qty}
                  />
                </FormItem>
              </Col>
              <Col xs={12} xl={6} span={12}>
                <FormItem
                  label="PO Unit Rate (INR)"
                  // name="po_unit_rate"
                  rules={[
                    {
                      required: true,
                      message: 'Enter PO unit rate'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <InputNumber
                    min={0}
                    style={{ width: '100%' }}
                    placeholder="Enter PO unit rate"
                    onChange={(e) => hanldeChangeUnit(e)}
                    value={unitRate ? unitRate : material?.po_unit_rate}
                  />
                </FormItem>
              </Col>
              <Col xs={12} xl={6} span={12}>
                <FormItem
                  label="PO Total Amount"
                  rules={[
                    {
                      required: false,
                      message: 'Enter PO Total Amount'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter PO Total Amount"
                    value={
                      poqty !== 0 || unitRate !== 0
                        ? (poqty !== 0
                            ? parseFloat(poqty)
                            : parseFloat(material?.po_qty)) *
                          (unitRate !== 0
                            ? parseFloat(unitRate)
                            : parseFloat(material?.po_unit_rate))
                        : material?.po_total_amount
                    }
                    disabled
                  />
                </FormItem>
              </Col>
            </Row>
          ) : (
            <Row gutter={24}>
              <Col xs={24} xl={4} span={6}>
                <p className={styles.customLable}>PO Number</p>
                <p className={styles.customValue}>{material?.po_number}</p>
              </Col>
              <Col xs={24} xl={5} span={6}>
                <p className={styles.customLable}>PO Quantity</p>
                <p className={styles.customValue}>{material?.po_qty}</p>
              </Col>
              <Col xs={24} xl={5} span={6}>
                <p className={styles.customLable}>PO Unit Rate</p>
                <p className={styles.customValue}>{material?.po_unit_rate}</p>
              </Col>
              <Col xs={24} xl={5} span={6}>
                <p className={styles.customLable}>PO Total Amount</p>
                <p className={styles.customValue}>
                  {material?.po_total_amount ||
                    parseFloat(poqty) * parseFloat(unitRate)}
                </p>
              </Col>
              <Col xs={24} xl={5} span={6}>
                <p className={styles.customLable}>Focus PO #</p>
                <p className={styles.customValue}>{material?.focus_po_no}</p>
              </Col>
            </Row>
          )}
          {!isinvoice && pagetype == 'po' ? (
            <FormItem className="text-center">
              <Space>
                <CustomButton
                  onClick={() => history.back()}
                  loading={loading}
                  type="primary"
                  ghost
                >
                  Back
                </CustomButton>
                <CustomButton
                  type="primary"
                  // htmlType="submit"
                  loading={loading}
                  className={styles.border_button}
                  onClick={() => handleinvoice('po')}
                  ghost
                >
                  Initiate Verification
                </CustomButton>
                {/* <CustomButton
                  onClick={() => handleinvoice()}
                  loading={loading}
                  type="primary"
                >
                  Update
                </CustomButton> */}
              </Space>
            </FormItem>
          ) : (
            ''
          )}
        </Card>
        <br />
        <Card
          title="INVOICE DETAILS"
          className={styles.CustomPanel}
          extra={
            user?.level == 3 || Superadmindata?.[0]?.level == 3 ? (
              <CustomButton onClick={() => handleinvoicedetails('invoice')}>
                <EditOutlined />
              </CustomButton>
            ) : (
              ''
            )
          }
        >
          {!isinvoice && pagetype == 'invoice' ? (
            <Row gutter={24}>
              <Col xs={12} xl={6} span={12}>
                <FormItem
                  label="CGST"
                  // name="cgst"
                  rules={[
                    {
                      required: false,
                      message: 'Enter CGST'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter CGST"
                    value={cgstdata ? cgstdata : material?.cgst}
                    onChange={(e) => handeChangeGst(e, 'cgst')}
                  />
                </FormItem>
              </Col>
              <Col xs={12} xl={6} span={12}>
                <FormItem
                  label="SGST"
                  // name="sgst"
                  rules={[
                    {
                      required: false,
                      message: 'Enter SGST'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter SGST"
                    value={sgstdata ? sgstdata : material?.sgst}
                    onChange={(e) => handeChangeGst(e, 'sgst')}
                  />
                </FormItem>
              </Col>
              <Col xs={12} xl={6} span={12}>
                <FormItem
                  label="IGST"
                  // name="igst"
                  rules={[
                    {
                      required: false,
                      message: 'Enter IGST'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter IGST"
                    value={igstdata ? igstdata : material?.igst}
                    onChange={(e) => handeChangeGst(e, 'igst')}
                  />
                </FormItem>
              </Col>
              <Col xs={12} xl={6} span={12}>
                <FormItem
                  label="Total Invoice Price(Incl. GST)"
                  // name="total_inv_price"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Total Invoice'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter Total Invoice"
                    value={totalPrice ? totalPrice : material?.total_inv_price}
                    onChange={(e) => handeChangeGst(e, 'total_inv_price')}
                  />
                </FormItem>
              </Col>
            </Row>
          ) : (
            <Row gutter={24}>
              <Col xs={24} xl={6} span={6}>
                <p className={styles.customLable}>CGST</p>
                <p className={styles.customValue}>{material?.cgst}</p>
              </Col>
              <Col xs={24} xl={6} span={6}>
                <p className={styles.customLable}>SGST</p>
                <p className={styles.customValue}>{material?.sgst}</p>
              </Col>
              <Col xs={24} xl={6} span={6}>
                <p className={styles.customLable}>IGST</p>
                <p className={styles.customValue}>{material?.igst}</p>
              </Col>
              <Col xs={24} xl={6} span={6}>
                <p className={styles.customLable}>
                  Total Invoice Price (Incl.GST)
                </p>
                <p className={styles.customValue}>
                  {material?.total_inv_price}
                </p>
              </Col>
            </Row>
          )}
          {!isinvoice && pagetype == 'invoice' ? (
            <FormItem className="text-center">
              <Space>
                <CustomButton
                  onClick={() => history.back()}
                  loading={loading}
                  type="primary"
                  ghost
                >
                  Back
                </CustomButton>
                <CustomButton
                  type="primary"
                  // htmlType="submit"
                  loading={loading}
                  className={styles.border_button}
                  onClick={() => handleinvoice('invoice')}
                  ghost
                >
                  Initiate Verification
                </CustomButton>
                {/* <CustomButton
                  onClick={() => handleinvoice()}
                  loading={loading}
                  type="primary"
                >
                  Update
                </CustomButton> */}
              </Space>
            </FormItem>
          ) : (
            ''
          )}
        </Card>
        <br />
      </Form>
      {isModal ? (
        <VerificationModal
          isModal={isModal}
          materials={
            pagetype == 'po' || pagetype == 'invoice' ? poDetails : materials
          }
          setIsModal={setIsModal}
          material={material}
          setIsEdit={setIsEdit}
          page={pagetype}
          editableBatch={containers}
          setIsinvoice={setIsinvoice}
        />
      ) : (
        ''
      )}
    </>
  );
};
export default commonviewMaterialPage;
