import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Card,
  Radio,
  Space,
  Select,
  Input,
  Collapse,
  message,
  InputNumber,
  Tooltip,
  Image
} from 'antd';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import 'react-quill/dist/quill.snow.css';
import CustomButton from '@/common/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllMaterialsBatches,
  createDispensingSheet,
  getContainers,
  getDispenseMaterialDetails,
  createprintIssue,
  updateprintIssue,
  getDispenseBMRDetails,
  getAllContainers
} from '../../redux/slice';
import Layout from '@/common/Layout';
const { Panel } = Collapse;
import { QrReader } from 'react-qr-reader';
import {
  QrcodeOutlined,
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  ArrowDownOutlined
} from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { history } from 'app/history';
import moment from 'moment';
import { getLoggedInUser } from 'utilities/helpers';
import VerificationModal from '@/features/material/components/VerificationModal';
import DeleteMaterialsModel from '../DeleteMaterialsModel';
import truck from '@/features/qa/components/images/truck-delivery-done.gif';
// import { groupBy } from 'lodash';

const ManualDispense = ({ loading }) => {
  const { allMaterials, allContainers, materialDetails } = useSelector(
    (state) => state.dispensing
  );
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const params = useParams();
  const { user } = getLoggedInUser();

  const [type, setType] = useState('');
  const [code, setCode] = useState('');
  const [search, setSearch] = useState('');
  const [truckShow, setTruckShow] = useState(false);
  // const [uom, setUom] = useState('');
  // const [data, setData] = useState([]);
  const [openCam, setOpenCam] = useState(false);
  const [matContainers, setMatContainers] = useState([]);
  const [counter, setCounter] = useState(1);
  const [data, setData] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [materialsLength, setMaterialsLength] = useState(0);
  const [printIssue, setPrintIssue] = useState(false);
  const [materialContainers, setMaterialContainers] = useState([]);
  const [matId, setMatId] = useState('');
  const [containersData, setContainersData] = useState([]);
  const [dispenseMaterial, setDispenseMaterial] = useState();
  // const [matarialbtn, setMatarialbtn] = useState(false);
  // const [warehouseData, setWarehouseData] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [stockBatches, setStockBatches] = useState([]);
  const [isVerify, setIsVerify] = useState(false);
  const [stockMatID, setStockMatID] = useState();
  const [newStockBatches, setNewStockBatches] = useState([]);
  const [materials, setMaterials] = useState([
    {
      dispensing_request_id: params.id,
      material_code: '',
      material_name: '',
      uom: '',
      product_name: '',
      product_batch_number: '',
      batches: [
        {
          dispenses: [
            {
              dispensing_container: 'DC-23-0001',
              tare_weight: '',
              net_weight: '',
              gross_weight: 0
            }
          ],
          sub_batches: [
            { batch_number: '', warehouse_container: '', is_left_over: 'No' }
          ]
        }
      ]
    }
  ]);
  console.log(materials);
  console.log(allContainers);
  console.log(allMaterials);

  const all_products = [
    {
      materials: [
        {
          material_code: [{ code: 1234 }, { code: 2345 }],
          material_name: [{ name: 'abcd' }, { name: 'efgh' }],
          quantity: 21,
          batches: [
            {
              dispenses: [
                {
                  dispensing_container: 'DC-23-0001',
                  tare_weight: '',
                  net_weight: '',
                  gross_weight: 0
                }
              ],
              sub_batches: [
                {
                  batch_number: '',
                  warehouse_container: '',
                  is_left_over: 'No'
                }
              ]
            }
          ]
        },
        {
          material_code: [{ code: 1234 }, { code: 2345 }],
          material_name: [{ name: 'abcd' }, { name: 'efgh' }],
          quantity: 21,
          batches: [
            {
              dispenses: [
                {
                  dispensing_container: 'DC-23-0001',
                  tare_weight: '',
                  net_weight: '',
                  gross_weight: 0
                }
              ],
              sub_batches: [
                {
                  batch_number: '',
                  warehouse_container: '',
                  is_left_over: 'No'
                }
              ]
            }
          ]
        },
        {
          material_code: [{ code: 1234 }, { code: 2345 }],
          material_name: [{ name: 'abcd' }, { name: 'efgh' }],
          quantity: 21,
          batches: [
            {
              dispenses: [
                {
                  dispensing_container: 'DC-23-0001',
                  tare_weight: '',
                  net_weight: '',
                  gross_weight: 0
                }
              ],
              sub_batches: [
                {
                  batch_number: '',
                  warehouse_container: '',
                  is_left_over: 'No'
                }
              ]
            }
          ]
        },
        {
          material_code: [{ code: 1234 }, { code: 2345 }],
          material_name: [{ name: 'abcd' }, { name: 'efgh' }],
          quantity: 21,
          batches: [
            {
              dispenses: [
                {
                  dispensing_container: 'DC-23-0001',
                  tare_weight: '',
                  net_weight: '',
                  gross_weight: 0
                }
              ],
              sub_batches: [
                {
                  batch_number: '',
                  warehouse_container: '',
                  is_left_over: 'No'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      materials: [
        {
          material_code: [{ code: 1234 }, { code: 2345 }],
          material_name: [{ name: 'abcd' }, { name: 'efgh' }],
          quantity: 21,
          batches: [
            {
              dispenses: [
                {
                  dispensing_container: 'DC-23-0001',
                  tare_weight: '',
                  net_weight: '',
                  gross_weight: 0
                }
              ],
              sub_batches: [
                {
                  batch_number: '',
                  warehouse_container: '',
                  is_left_over: 'No'
                }
              ]
            }
          ]
        },
        {
          material_code: [{ code: 1234 }, { code: 2345 }],
          material_name: [{ name: 'abcd' }, { name: 'efgh' }],
          quantity: 21,
          batches: [
            {
              dispenses: [
                {
                  dispensing_container: 'DC-23-0001',
                  tare_weight: '',
                  net_weight: '',
                  gross_weight: 0
                }
              ],
              sub_batches: [
                {
                  batch_number: '',
                  warehouse_container: '',
                  is_left_over: 'No'
                }
              ]
            }
          ]
        },
        {
          material_code: [{ code: 1234 }, { code: 2345 }],
          material_name: [{ name: 'abcd' }, { name: 'efgh' }],
          quantity: 21,
          batches: [
            {
              dispenses: [
                {
                  dispensing_container: 'DC-23-0001',
                  tare_weight: '',
                  net_weight: '',
                  gross_weight: 0
                }
              ],
              sub_batches: [
                {
                  batch_number: '',
                  warehouse_container: '',
                  is_left_over: 'No'
                }
              ]
            }
          ]
        },
        {
          material_code: [{ code: 1234 }, { code: 2345 }],
          material_name: [{ name: 'abcd' }, { name: 'efgh' }],
          quantity: 21,
          batches: [
            {
              dispenses: [
                {
                  dispensing_container: 'DC-23-0001',
                  tare_weight: '',
                  net_weight: '',
                  gross_weight: 0
                }
              ],
              sub_batches: [
                {
                  batch_number: '',
                  warehouse_container: '',
                  is_left_over: 'No'
                }
              ]
            }
          ]
        }
      ]
    }
  ];
  console.log(containersData, all_products, 'containersdata');
  // const data1 = [
  //   {
  //     id: 69,
  //     batch_id: 123,
  //     dis_id: 1
  //   },
  //   {
  //     id: 69,
  //     batch_id: 123,
  //     dis_id: 2
  //   },
  //   {
  //     id: 69,
  //     batch_id: 124,
  //     dis_id: 1
  //   },
  //   {
  //     id: 70,
  //     batch_id: 122,
  //     dis_id: 1
  //   }
  // ];

  // const out = [];
  // data1.map((item) => {
  //   const existingEntry = out.find((o) => o.mat_id === item.id);
  //   if (existingEntry) {
  //     const existingBatch = existingEntry.batches['batchesd'].find(
  //       (o) => o.batch_id === item.batch_id
  //     );
  //     if (existingBatch) {
  //       console.log(existingEntry);
  //       existingBatch.dises = existingBatch.dises.concat({
  //         dis_id: item.dis_id
  //       });
  //     } else {
  //       existingEntry.batches['batchesd'] = existingEntry.batches[
  //         'batchesd'
  //       ].concat({
  //         batch_id: item.batch_id,
  //         dises: [{ dis_id: item.dis_id }]
  //       });
  //     }
  //   } else {
  //     out.push({
  //       mat_id: item.id,
  //       batches: {
  //         batchesd: [
  //           { batch_id: item.batch_id, dises: [{ dis_id: item.dis_id }] }
  //         ]
  //       }
  //     });
  //   }
  // });
  // console.log(out);
  // const data2 = [];
  // const mats = groupBy(data1, 'id');
  // console.log(mats);
  // !data1?.map((item) => {
  //   data2.push(mats[item.id]);
  // });
  // console.log(data2);
  // console.log(groupBy(mats[69], 'batch_id'));
  useEffect(() => {
    handleGetAllMaterials();
  }, []);

  const downloadprintIssue = (url) => {
    console.log(materials);
    window.open(url, '_blank');
  };

  useEffect(() => {
    handleGetAllContainers();
  }, [search]);

  const handleGetAllContainers = () => {
    let payload = { material_id: matId, search: search };
    payload.material_id
      ? dispatch(getAllContainers(payload)).then((response) => {
          console.log(response.payload.data, 'llll');
          setContainersData(response.payload.data);
        })
      : '';
  };

  const handleGetAllMaterials = (type) => {
    console.log(type);
    let payload = {
      id: params.id
    };
    dispatch(getDispenseMaterialDetails(payload)).then(function (result) {
      if (result?.payload?.success) {
        setType(result?.payload?.data?.type);
        console.log(result?.payload?.data);
        if (result?.payload?.data?.type == 'bmr') {
          dispatch(getDispenseBMRDetails(payload)).then(function (result) {
            if (result?.payload?.success) {
              result?.payload?.data?.materials?.length
                ? setCounter(result?.payload?.data?.materials?.length)
                : '';
              setMaterialsLength(result?.payload?.data?.materials?.length);
              setMaterials(
                result?.payload?.data?.materials?.map((item, index) => {
                  return {
                    is_drafted: true,
                    dispensing_request_id: params.id,
                    material_code: item?.code,
                    material_id: item?.id,
                    material_name: item?.name,
                    uom: item?.uom,
                    product_name: result?.payload?.data?.product_detail?.name,
                    product_batch_number:
                      result?.payload?.data?.product_batch_number,
                    pdf_url:
                      result?.payload?.data?.type == 'material'
                        ? result?.payload?.data?.dispensing_materials[index]
                            ?.dispensing_request_pdfs?.[0]?.pdf_doc
                        : item?.dispensing_request_pdfs[0]?.pdf_doc,
                    pdf_id:
                      result?.payload?.data?.type == 'material'
                        ? result?.payload?.data?.dispensing_materials[index]
                            ?.dispensing_request_pdfs?.[0]?.id
                        : item?.dispensing_request_pdfs?.[0]?.id,
                    prod_user_id:
                      result?.payload?.data?.type == 'material'
                        ? result?.payload?.data?.dispensing_materials[index]
                            ?.dispensing_request_pdfs?.[0]?.user_id
                        : item?.dispensing_request_pdfs?.[0]?.user_id,
                    batches: item?.batch_details?.map((dispenses) => {
                      return {
                        sub_batches: [
                          {
                            batch_id: dispenses?.id,
                            container_id: dispenses?.id,
                            batch_number:
                              dispenses?.inhouse_batch_number || 'NA',
                            warehouse_container: dispenses?.number || 'NA',
                            is_left_over: dispenses?.is_left_over_conta
                          }
                        ],
                        dispenses: dispenses?.dispensary_containers?.map(
                          (dispenses) => {
                            return {
                              dispensing_container:
                                dispenses?.dc_number || 'DC-23-0001',
                              tare_weight:
                                parseFloat(dispenses?.tare_weight) || '',
                              net_weight:
                                parseFloat(dispenses?.net_weight) || '',
                              gross_weight:
                                parseFloat(dispenses?.gross_weight) || 0,
                              dispense_container_id: dispenses?.id || ''
                            };
                          }
                        )
                      };
                    })
                  };
                })
              );
            }
          });
        } else if (result?.payload?.data?.dispensing_materials?.length) {
          dispatch(getDispenseBMRDetails(payload)).then(function (result) {
            if (result?.payload?.success) {
              result?.payload?.data?.materials?.length
                ? setCounter(result?.payload?.data?.materials?.length)
                : '';
              setMaterialsLength(result?.payload?.data?.materials?.length);
              setMaterials(
                result?.payload?.data?.materials?.map((item) => {
                  return {
                    is_drafted: true,
                    dispensing_request_id: params.id,
                    material_code: item?.code,
                    material_id: item?.id,
                    material_name: item?.name,
                    uom: item?.uom,
                    product_name: result?.payload?.data?.product_detail?.name,
                    product_batch_number:
                      result?.payload?.data?.product_batch_number,
                    pdf_url:
                      result?.payload?.data?.type == 'material'
                        ? item?.dispensing_request_pdfs?.[0]?.pdf_doc
                        : item?.dispensing_request_pdfs?.[0]?.pdf_doc,
                    pdf_id:
                      result?.payload?.data?.type == 'material'
                        ? item?.dispensing_request_pdfs?.[0]?.id
                        : item?.dispensing_request_pdfs?.[0]?.id,
                    prod_user_id:
                      result?.payload?.data?.type == 'material'
                        ? item?.dispensing_request_pdfs?.[0]?.user_id
                        : item?.dispensing_request_pdfs?.[0]?.user_id,
                    batches: item?.batch_details?.map((dispenses) => {
                      return {
                        sub_batches: [
                          {
                            batch_id: dispenses?.id,
                            container_id: '',
                            batch_number:
                              dispenses?.inhouse_batch_number || 'NA',
                            warehouse_container: '',
                            is_left_over: dispenses?.is_left_over_conta
                          }
                        ],
                        dispenses: dispenses?.dispensary_containers?.length
                          ? dispenses?.dispensary_containers?.map(
                              (dispenses) => {
                                return {
                                  dispensing_container:
                                    dispenses?.dc_number || 'DC-23-0001',
                                  tare_weight:
                                    parseFloat(dispenses?.tare_weight) || '',
                                  net_weight:
                                    parseFloat(dispenses?.net_weight) || '',
                                  gross_weight:
                                    parseFloat(dispenses?.gross_weight) || 0,
                                  dispense_container_id: dispenses?.id || ''
                                };
                              }
                            )
                          : [
                              {
                                dispensing_container: 'DC-23-0001',
                                tare_weight: '',
                                net_weight: '',
                                gross_weight: 0
                              }
                            ]
                      };
                    })
                  };
                })
              );
            }
          });
          // setMaterials(
          //   result?.payload?.data?.dispensing_materials?.map((item) => {
          //     return {
          //       is_drafted: true,
          //       dispensing_request_id: params.id,
          //       material_code: item?.material?.code,
          //       material_id: item?.material?.id,
          //       material_name: item?.material?.name,
          //       uom: item?.uom,
          //       product_name: result?.payload?.data?.product_detail?.name,
          //       product_batch_number:
          //         result?.payload?.data?.product_batch_number,
          //       batches: [
          //         {
          //           dispenses: [
          //             {
          //               dispensing_container: 'DC-23-0001',
          //               tare_weight: '',
          //               net_weight: '',
          //               gross_weight: 0
          //             }
          //           ],
          //           sub_batches: [
          //             {
          //               batch_number: '',
          //               warehouse_container: '',
          //               is_left_over: 'No'
          //             }
          //           ]
          //         }
          //       ]
          //     };
          //   })
          // );
          setMatContainers(
            result?.payload?.data?.dispensing_materials?.map((item) => {
              return item?.batch_detail?.containers || [];
            })
          );
          setMaterialContainers(
            result?.payload?.data?.dispensing_materials?.map((item) => {
              return item || [];
            })
          );
          // const payload = { material_id: result?.payload?.data?.id };
          // dispatch(getContainers(payload));
          // result?.payload?.data?.dispensing_materials?.map(() => {
          setCounter(result?.payload?.data?.dispensing_materials?.length);
          // });

          // setMaterials(
          //   result?.payload?.data?.dispensing_materials?.map((item) => {
          //     return {
          //       is_drafted: true,
          //       dispensing_request_id: params.id,
          //       material_code: item?.material?.code,
          //       material_id: item?.material?.id,
          //       material_name: item?.material?.name,
          //       uom: item?.material?.uom,
          //       product_name: result?.payload?.data?.product_detail?.name,
          //       product_batch_number:
          //         result?.payload?.data?.product_batch_number,
          //       batches: [
          //         {
          //           dispenses: item?.batch_detail?.dispensary_containers?.map(
          //             (dispenses) => {
          //               return {
          //                 dispensing_container:
          //                   dispenses?.dc_numb || 'DC-23-0001',
          //                 tare_weight: parseFloat(dispenses?.tare_we) || '',
          //                 net_weight: parseFloat(dispenses?.net_wei) || '',
          //                 gross_weight: parseFloat(dispenses?.gross_w) || 0,
          //                 dispense_container_id: dispenses?.id || ''
          //               };
          //             }
          //           ),

          //           sub_batches: item?.batch_detail?.containers?.map(
          //             (sub_batches) => {
          //               return {
          //                 batch_id: sub_batches?.batch_details_id,
          //                 container_id: sub_batches?.id,
          //                 batch_number:
          //                   item?.batch_detail?.inhouse_batch_number || 'NA',
          //                 warehouse_container: sub_batches?.number || 'NA',
          //                 is_left_over: sub_batches?.is_left_over_conta
          //               };
          //             }
          //           )
          //         }
          //       ]
          //     };
          //   })
          // );
        }
      }
    });
  };
  console.log(materials, counter, 'counter1');
  console.log(materials?.length >= counter ? 'true' : 'false');
  const handleaddMaterial = () => {
    setCounter(counter + 1);
    type == 'bmr'
      ? setMaterials([
          ...materials,
          {
            dispensing_request_id: params.id,
            material_code: '',
            material_id: '',
            material_name: '',
            uom: '',
            product_name: '',
            product_batch_number: '',
            batches: [
              {
                dispenses: [
                  {
                    // dispensing_container:
                    //   'DC-23-' +
                    //   (parseInt(last_dispense) + 1).toString().padStart(4, '0'),
                    dispensing_container: 'DC-23-0001',
                    tare_weight: '',
                    net_weight: '',
                    gross_weight: 0
                  }
                ],
                sub_batches: [
                  {
                    batch_id: '',
                    container_id: '',
                    batch_number: '',
                    warehouse_container: '',
                    is_left_over: 'No'
                  }
                ]
              }
            ]
          }
        ])
      : '';
  };

  useEffect(() => {
    handleGetAllMaterialsList();
  }, [search, code]);

  const handleGetAllMaterialsList = () => {
    let payload = { search };
    dispatch(getAllMaterialsBatches(payload));
  };

  const onSearch = (value, id) => {
    console.log(value, id, 'jjj');
    setSearch(value);
    setMatId(id);
  };

  const onChange = (index, value) => {
    const material = allMaterials.rows.filter(
      (allMaterials) => allMaterials.id === value
    );
    setCode(material[0]);
    form.setFieldsValue({
      material_name: material[0]?.name,
      material_code: material[0]?.code
    });
    // setUom(material[0]?.uom);
    const payload = { material_id: value };
    const list = [...materials];
    list[index]['material_code'] = material[0]?.code;
    list[index]['material_id'] = value;
    list[index]['material_name'] = material[0]?.name;
    list[index]['uom'] = material[0]?.uom;
    list[index]['product_name'] = material[0]?.product_detail?.name;
    list[index]['product_batch_number'] = material[0]?.product_batch_number;
    setMaterials(list);
    type == 'bmr'
      ? dispatch(getContainers(payload))
      : dispatch(getDispenseMaterialDetails(payload)).then(function (response) {
          if (response?.payload?.data?.dispensing_materials?.length) {
            setMatContainers(
              response?.payload?.data?.dispensing_materials?.map((item) => {
                return item?.batch_detail?.containers || [];
              })
            );
          }
        });
  };

  // setMatarialbtn(buttondis);
  const onChangeWarehouse = (index, i, sub_i, value) => {
    console.log(materialContainers, 'llpp');
    // setMatId(123);

    const container_list = [];
    containersData.map((item) => {
      item.containers.map((data) => {
        container_list.push(data);
      });
    });

    const warehouse_containers =
      type == 'bmr'
        ? allContainers.filter((allContainers) => allContainers?.id == value)
        : search !== ''
        ? container_list.filter((allContainers) => allContainers?.id == value)
        : materialContainers[index].batch_detail.containers.filter(
            (allContainers) => allContainers?.id == value
          );

    let batch_number =
      type !== 'bmr'
        ? containersData.length
          ? containersData.filter(
              (allContainers) =>
                allContainers?.id === warehouse_containers[0]?.batch_details_id
            )
          : materialContainers.filter(
              (allContainers) =>
                allContainers?.batch_detail.id ==
                warehouse_containers[0]?.batch_details_id
            )
        : [];
    console.log(batch_number, 'nnn');

    console.log(warehouse_containers[0]?.batch_details_id, 'mmmm');
    const list = [...materials];
    list[index]['batches'][i]['sub_batches'][sub_i]['warehouse_container'] =
      warehouse_containers[0]?.number;
    list[index]['batches'][i]['sub_batches'][sub_i]['container_id'] = value;
    {
      type == 'bmr'
        ? (list[index]['batches'][i]['sub_batches'][sub_i]['batch_id'] =
            warehouse_containers[0]?.batch_detail?.id)
        : (list[index]['batches'][i]['sub_batches'][sub_i]['batch_id'] =
            warehouse_containers[0]?.batch_details_id);
    }
    {
      type == 'bmr'
        ? (list[index]['batches'][i]['sub_batches'][sub_i]['batch_number'] =
            warehouse_containers[0]?.batch_detail?.inhouse_batch_number)
        : (list[index]['batches'][i]['sub_batches'][sub_i]['batch_number'] =
            batch_number[0].inhouse_batch_number ||
            batch_number[0].batch_detail.inhouse_batch_number);
    }
    setMaterials(list);
  };

  const onChangeleftover = (index, i, sub_i) => {
    materials[index]['batches'][i]['sub_batches'].map((_, j) => {
      console.log(j, sub_i);
      const list = [...materials];
      j == sub_i
        ? (list[index]['batches'][i]['sub_batches'][j]['is_left_over'] = 'Yes')
        : (list[index]['batches'][i]['sub_batches'][j]['is_left_over'] = 'No');
      setMaterials(list);
    });
    // list[index]['batches'][i]['sub_batches'][sub_i]['is_left_over'] = e.target
    //   .checked
    //   ? true
    //   : false;
  };

  // const handleClick = () => {
  //   setData([
  //     ...data,
  //     {
  //       batch_id: '',
  //       required_weight: form.getFieldValue('weight'),
  //       material_code: code?.code,
  //       material_name: form.getFieldValue('material_name')
  //     }
  //   ]);
  //   form.resetFields();
  //   setCode('');
  //   setUom('');
  //   form.setFieldsValue({ material_name: '' });
  // };

  const onFinish = () => {
    const payload = {
      dispense_materials: materials
    };
    setTruckShow(true);
    dispatch(createDispensingSheet(payload)).then(function (response) {
      if (response?.payload?.success) {
        window.open(response?.payload?.data, '_blank');
        // history.push(`/dispensing-material/${params.id}`);
        history.push('/dispense-material-list');
      }
    });
  };

  const handleError = (err) => {
    console.error(err);
  };

  const handleQrScan = async (index, i, sub_i, scanData) => {
    console.log(index, i, sub_i);
    if (scanData && scanData !== '') {
      let Containers =
        type == 'bmr'
          ? allContainers.filter(
              (allContainers) =>
                allContainers.number ===
                scanData.text.slice(scanData.text.lastIndexOf('/') + 1)
            )
          : matContainers.filter(
              (allContainers) =>
                allContainers.number ===
                scanData.text.slice(scanData.text.lastIndexOf('/') + 1)
            );
      console.log(Containers);
      form.setFieldsValue({
        warehouse_containers: Containers[0].id
      });
      if (Containers?.length) {
        const list = [...materials];
        list[index]['batches'][i]['sub_batches'][sub_i]['warehouse_container'] =
          Containers[0].number;
        list[index]['batches'][i]['sub_batches'][sub_i]['container_id'] =
          Containers[0].id;
        list[index]['batches'][i]['sub_batches'][sub_i]['batch_id'] =
          Containers[0].id;
        list[index]['batches'][i]['sub_batches'][sub_i]['batch_number'] =
          Containers[0]?.batch_detail?.inhouse_batch_number;
        setMaterials(list);
        setOpenCam(false);
      } else {
        message.error('This container does not belong to this batch');
        setOpenCam(false);
      }
    }

    // setOpenCam(false);
  };
  console.log(materials);
  const handleAddbatches = (index, id) => {
    let temp_materials = [...materials];
    temp_materials[index].batches.push({
      dispenses: [
        {
          dispensing_container: 'DC-23-0001',
          tare_weight: '',
          net_weight: '',
          gross_weight: 0
        }
      ],
      sub_batches: [
        {
          warehouse_container: '',
          is_left_over: 'No',
          batch_id:
            materialDetails?.dispensing_materials[index]?.batch_detail?.id,
          batch_number:
            materialDetails?.dispensing_materials[index]?.batch_detail
              ?.inhouse_batch_number || ''
        }
      ]
    });
    const payload = { material_id: id };
    type == 'bmr'
      ? dispatch(getContainers(payload))
      : dispatch(getDispenseMaterialDetails(payload)).then(function (response) {
          if (response?.payload?.data?.dispensing_materials?.length) {
            setMatContainers(
              response?.payload?.data?.dispensing_materials?.map((item) => {
                return item?.batch_detail?.containers || [];
              })
            );
          }
        });
    setMaterials(temp_materials);
  };

  const handleAddsubbatches = (index, i) => {
    let temp_materials = [...materials];
    temp_materials[index].batches[i].sub_batches.push({
      warehouse_container: '',
      is_left_over: 'No',
      batch_id: materialDetails?.dispensing_materials[index]?.batch_detail?.id,
      batch_number:
        materialDetails?.dispensing_materials[index]?.batch_detail
          ?.inhouse_batch_number || ''
    });

    setMaterials(temp_materials);
  };

  const handleDeletesubbatches = (index, i, sub_bat_i) => {
    console.log(sub_bat_i);
    let temp_materials = [...materials];
    temp_materials[index].batches[i].sub_batches.splice(sub_bat_i, 1);
    setMaterials(temp_materials);
  };
  console.log(materials, 'mats');
  const handleAddDispensing = (index, i) => {
    let temp_materials = [...materials];
    const last_dispense = temp_materials[index].batches[i]?.dispenses[
      temp_materials[index].batches[i]?.dispenses.length - 1
    ].dispensing_container.slice(
      temp_materials[index].batches[i]?.dispenses[
        temp_materials[index].batches[i]?.dispenses.length - 1
      ].dispensing_container.lastIndexOf('-') + 1
    );
    temp_materials[index].batches[i]?.dispenses.push({
      dispensing_container:
        'DC-23-' + (parseInt(last_dispense) + 1).toString().padStart(4, '0'),
      tare_weight: '',
      net_weight: '',
      gross_weight: 0
    });

    setMaterials(temp_materials);
  };

  const handleChangeTare = (index, i, dis_i, e) => {
    const list = [...materials];
    list[index]['batches'][i]['dispenses'][dis_i]['tare_weight'] = e;
    list[index]['batches'][i]['dispenses'][dis_i]['gross_weight'] =
      parseFloat(e) +
      parseFloat(
        list[index]['batches'][i]['dispenses'][dis_i]['net_weight'] !== ''
          ? list[index]['batches'][i]['dispenses'][dis_i]['net_weight']
          : 0
      );
    setMaterials(list);
  };

  const handleChangeNet = (index, i, dis_i, e) => {
    const list = [...materials];
    list[index]['batches'][i]['dispenses'][dis_i]['net_weight'] = e;
    list[index]['batches'][i]['dispenses'][dis_i]['gross_weight'] =
      parseFloat(e) +
      parseFloat(
        list[index]['batches'][i]['dispenses'][dis_i]['tare_weight'] !== ''
          ? list[index]['batches'][i]['dispenses'][dis_i]['tare_weight']
          : 0
      );
    setMaterials(list);
  };

  const handlePrintIssueTag = (index) => {
    setPrintIssue(true);
    setTruckShow(true);
    const payload = {
      dispense_materials: [materials[index]]
    };
    if (materialsLength > 0) {
      dispatch(updateprintIssue(payload)).then(function (response) {
        if (response?.payload?.success) {
          window.open(response?.payload?.data, '_blank');
          handleGetAllMaterials();
          setPrintIssue(false);
          setTruckShow(false);
          // history.push('/dispense-material-list');
          // history.push('/dispense-material-list');
        } else {
          setTruckShow(false);
        }
      });
    } else {
      dispatch(createprintIssue(payload)).then(function (response) {
        if (response?.payload?.success) {
          // history.push('/dispense-material-list');
          window.open(response?.payload?.data, '_blank');
          setPrintIssue(false);
          handleGetAllMaterials();
          setTruckShow(false);
          // history.push('/dispense-material-list');
        }
      });
    }
  };

  // const handlepdfPrintIssueTag = (index, pdf_id) => {
  //   console.log(pdf_id);
  //   const payload = {
  //     dispense_materials: [
  //       {
  //         dispensing_request_id: params.id,
  //         dispense_request_pdf_id: pdf_id
  //       }
  //     ]
  //   };
  //   dispatch(createprintIssue(payload)).then(function (response) {
  //     if (response?.payload?.success) {
  //       // history.push('/dispense-material-list');
  //       window.open(response?.payload?.data, '_blank');
  //       setPrintIssue(false);
  //       handleGetAllMaterials();
  //       // history.push('/dispensing-list');
  //     }
  //   });
  // };
  // const UpdateContainers = (index) => {
  //   const payload = {
  //     dispense_materials: [materials[index]]
  //   };
  //   dispatch(updateprintIssue(payload)).then(function (response) {
  //     if (response?.payload?.success) {
  //       window.open(response?.payload?.data, '_blank');
  //       history.push(`/dispensing-material/${params.id}`);
  //     }
  //   });
  // };

  const UpdateContainers = (index) => {
    // const payload = {
    //   dispense_materials: [materials[index]]
    // };
    setDispenseMaterial([materials[index]]);
    setIsModal(true);
    // dispatch(updateprintIssue(payload)).then(function (response) {
    //   if (response?.payload?.success) {
    //     message.success(response?.payload?.message);
    //     // window.open(response?.payload?.data, '_blank');
    //     // history.push(`/dispensing-material/${params.id}`);
    //   }
    // });
  };

  const handleEdit = (value) => {
    setIsEdit({ [value]: true });
  };

  const handleDelete = (value, batches, id) => {
    setIsDelete({ [value]: true });
    setStockBatches(batches);
    setStockMatID(id);
  };
  // const buttondis = Object.values(materials).every((k) => k.pdf_url);
  // setMatarialbtn(buttondis);
  // console.log(buttondis, 'buttondis');
  const buttondis = Object.values(materials).every((k) => k.pdf_url);
  console.log(isEdit, buttondis);
  console.log(allMaterials, 'allmats');
  console.log(materials, counter, 'counter');

  return (
    <Layout sider={false}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
      >
        <Card
          title={
            <span style={{ fontWeight: 'bold' }}>
              Dispense Material -{' '}
              {type == 'bmr' ? 'BMR/BPR Based' : 'Material Based'}
            </span>
          }
          className={styles.CustomPanel}
          // extra={
          //   <span style={{ fontWeight: 'bold' }}>
          //     {materialDetails?.bmr_number?.toUpperCase()}
          //   </span>
          // }
        >
          <>
            {type == 'bmr' ? (
              <Card className={styles.subpanel}>
                <Row gutter={24}>
                  <Col xs={24} xl={6} span={6}>
                    <FormItem
                      label="BMR Number"
                      // name="bmr_number"
                      rules={[
                        {
                          required: false,
                          message: 'Enter BMR Number'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        // name="bmr_number"
                        // onChange={handleCapacity}
                        placeholder="Enter BMR Number"
                        value={materialDetails?.product_detail?.bmr_bpr_code}
                        disabled
                      />
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Product Name"
                      className={styles.customLable}
                    >
                      <Input
                        disabled
                        value={materialDetails?.product_detail?.name || 'NA'}
                      />
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Product Batch Number"
                      className={styles.customLable}
                    >
                      <Input
                        disabled
                        value={materialDetails?.product_batch_number || 'NA'}
                      />
                    </FormItem>
                  </Col>

                  <Col span={6}>
                    <FormItem
                      label="Effective Date"
                      className={styles.customLable}
                    >
                      <Input
                        disabled
                        value={
                          materialDetails?.effective_date
                            ? moment(materialDetails?.effective_date).format(
                                'DD MMM YYYY'
                              )
                            : 'NA'
                        }
                      />
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem label="Batch Size" className={styles.customLable}>
                      <Input
                        disabled
                        value={
                          materialDetails?.product_detail
                            ?.theoretical_batch_size || 'NA'
                        }
                      />
                    </FormItem>
                  </Col>

                  <Col span={6}>
                    <FormItem label="MFC Number" className={styles.customLable}>
                      <Input
                        disabled
                        value={materialDetails?.ref_mfc || 'NA'}
                      />
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Product Code"
                      className={styles.customLable}
                    >
                      <Input
                        disabled
                        value={materialDetails?.product_code || 'NA'}
                      />
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Lable Claim"
                      className={styles.customLable}
                    >
                      <Input
                        disabled
                        value={materialDetails?.label_claim || 'NA'}
                      />
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Manufacturing Date"
                      className={styles.customLable}
                    >
                      <Input
                        disabled
                        value={
                          moment(materialDetails?.manufacturing_date).format(
                            'DD MMM YYYY'
                          ) || 'NA'
                        }
                      />
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Expiry Date"
                      className={styles.customLable}
                    >
                      <Input
                        disabled
                        value={materialDetails?.expiry_date || 'NA'}
                      />
                    </FormItem>
                  </Col>

                  <Col span={6}>
                    <FormItem
                      label="Manufacturing Site"
                      className={styles.customLable}
                    >
                      <Input
                        disabled
                        value={materialDetails?.manufacturing_site || 'NA'}
                      />
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Market / Customer Details"
                      className={styles.customLable}
                    >
                      <Input
                        disabled
                        value={materialDetails?.customer_details || 'NA'}
                      />
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Equipment Name"
                      className={styles.customLable}
                    >
                      <Input
                        disabled
                        value={
                          materialDetails?.product_detail?.equipment_name ||
                          'NA'
                        }
                      />
                    </FormItem>
                  </Col>
                </Row>
              </Card>
            ) : (
              ''
            )}
            <br />
            <Space direction="vertical" className={styles.cardWidth}>
              {materials?.length
                ? materials?.map((item, index) => {
                    console.log(item, 'llll');
                    const container_list = [];
                    containersData.map((item) => {
                      item.containers.map((data) => {
                        container_list.push(data);
                      });
                    });

                    const warehouseContainers =
                      type == 'bmr'
                        ? allContainers
                        : container_list.length
                        ? container_list
                        : matContainers[index];
                    console.log(matContainers, 'containers_list');
                    return counter >= index ? (
                      <Collapse
                        collapsible="header"
                        defaultActiveKey={['1']}
                        key={index}
                        className={styles.cardStyles_approver2}
                      >
                        <Panel
                          header={
                            <span
                              style={{
                                color: '#c91761',
                                fontWeight: 'bold',
                                fontSize: '14px'
                              }}
                            >
                              Material {index + 1}
                            </span>
                          }
                          className={styles.cardStyles_approver1}
                          extra={
                            <>
                              {user?.level == 3 ? (
                                <>
                                  <CustomButton
                                    className={styles.border_button}
                                    onClick={() =>
                                      handleDelete(
                                        index,
                                        item?.batches,
                                        materials[index]?.material_id
                                      )
                                    }
                                  >
                                    <DeleteOutlined />
                                  </CustomButton>
                                  <CustomButton
                                    className={styles.border_button}
                                    onClick={() => handleEdit(index)}
                                  >
                                    <EditOutlined />
                                  </CustomButton>
                                </>
                              ) : (
                                ''
                              )}
                              {item?.pdf_url &&
                              user?.role === 'user' &&
                              user?.level == 3 ? (
                                <Tooltip
                                  placement="top"
                                  title={'Download Print Issue Tag'}
                                >
                                  <CustomButton
                                    className={styles.border_button}
                                    onClick={() =>
                                      downloadprintIssue(item?.pdf_url)
                                    }
                                  >
                                    <ArrowDownOutlined />
                                  </CustomButton>
                                </Tooltip>
                              ) : (
                                ''
                              )}
                            </>
                          }
                          // className={styles.CustomPanel}
                          key="1"
                        >
                          <Row gutter={24}>
                            <Col span={12}>
                              <FormItem
                                label="Material Code"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Select Material Code'
                                  }
                                ]}
                                className={styles.customLable}
                              >
                                {type !== 'bmr' ? (
                                  <Input
                                    disabled
                                    value={`${materials[index]?.material_code}`}
                                  />
                                ) : materials[index]?.material_code == '' ? (
                                  <Select
                                    showSearch
                                    placeholder="Select a material code"
                                    optionFilterProp="children"
                                    onChange={(e) => onChange(index, e)}
                                    onSearch={onSearch}
                                  >
                                    {allMaterials?.rows?.map((item, index) => {
                                      return (
                                        <option value={item.id} key={index}>
                                          {item.code}
                                        </option>
                                      );
                                    })}
                                  </Select>
                                ) : (
                                  <Input
                                    disabled
                                    value={`${materials[index]?.material_code}`}
                                  />
                                )}
                              </FormItem>
                            </Col>
                            <Col span={12}>
                              <FormItem
                                label="Material Name"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Select Material Code'
                                  }
                                ]}
                                className={styles.customLable}
                              >
                                <Input
                                  disabled
                                  value={`${materials[index]?.material_name}`}
                                />
                              </FormItem>
                            </Col>
                            {/* <Col span={4}>
                              <FormItem
                                label="No.of Containers"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Select Material Code'
                                  }
                                ]}
                                className={styles.customLable}
                              >
                                <Input placeholder="Enter No. of containers" />
                              </FormItem>
                            </Col> */}
                          </Row>

                          {item?.batches?.length
                            ? item?.batches?.map((batch_item, i) => (
                                <>
                                  <Card className={styles.subpanel}>
                                    {batch_item?.sub_batches?.map(
                                      (sub_batch_item, sub_bat_i) => (
                                        <div key={sub_bat_i}>
                                          <Row gutter={24} key={sub_bat_i}>
                                            {user?.level == 3 &&
                                            item?.is_drafted ? (
                                              ''
                                            ) : materials[index]?.batches[i]
                                                ?.sub_batches[sub_bat_i]
                                                ?.container_id &&
                                              materials[index]?.is_drafted &&
                                              materials[index]?.batches[i]
                                                ?.sub_batches[sub_bat_i]
                                                ?.warehouse_container ==
                                                'NA' ? (
                                              ''
                                            ) : (
                                              <Col
                                                xs={4}
                                                xl={2}
                                                span={2}
                                                md={2}
                                                sm={2}
                                              >
                                                <CustomButton
                                                  onClick={() =>
                                                    setData({
                                                      index,
                                                      i,
                                                      sub_bat_i
                                                    })
                                                  }
                                                  className={styles.qrcode}
                                                >
                                                  <QrcodeOutlined
                                                    onClick={() =>
                                                      setOpenCam(true)
                                                    }
                                                  />
                                                </CustomButton>
                                              </Col>
                                            )}

                                            {user?.level == 3 &&
                                            item?.is_drafted ? (
                                              ''
                                            ) : // <FormItem
                                            //   label="Warehouse Containers"
                                            //   rules={[
                                            //     {
                                            //       required: false,
                                            //       message:
                                            //         'Enter warehouse containers'
                                            //     }
                                            //   ]}
                                            //   className={styles.customLable}
                                            // >
                                            //   <Input
                                            //     value={
                                            //       `${materials[index]?.batches[i]?.sub_batches[sub_bat_i]?.warehouse_container}` ||
                                            //       ''
                                            //     }
                                            //     disabled
                                            //   />
                                            // </FormItem>
                                            materials[index]?.batches[i]
                                                ?.sub_batches[sub_bat_i]
                                                ?.container_id &&
                                              materials[index]?.is_drafted &&
                                              materials[index]?.batches[i]
                                                ?.sub_batches[sub_bat_i]
                                                ?.warehouse_container ==
                                                'NA' ? (
                                              ''
                                            ) : (
                                              // <Col
                                              //   xs={18}
                                              //   xl={7}
                                              //   span={6}
                                              //   md={10}
                                              //   sm={10}
                                              // >
                                              //   <FormItem
                                              //     label="Warehouse Containers"
                                              //     rules={[
                                              //       {
                                              //         required: false,
                                              //         message:
                                              //           'Enter warehouse containers'
                                              //       }
                                              //     ]}
                                              //     className={styles.customLable}
                                              //   >
                                              //     <Select
                                              //       showSearch
                                              //       placeholder="Scan or select container"
                                              //       optionFilterProp="children"
                                              //       onChange={(e) =>
                                              //         onChangeWarehouse(
                                              //           index,
                                              //           i,
                                              //           sub_bat_i,
                                              //           e
                                              //         )
                                              //       }
                                              //       onSearch={onSearch}
                                              //     >
                                              //       {warehouseContainers?.map(
                                              //         (item, index) => {
                                              //           return (
                                              //             <option
                                              //               value={item?.id}
                                              //               key={index}
                                              //             >
                                              //               {item?.number}
                                              //             </option>
                                              //           );
                                              //         }
                                              //       )}
                                              //     </Select>
                                              //   </FormItem>
                                              // </Col>
                                              <Col
                                                xs={18}
                                                xl={7}
                                                span={6}
                                                md={10}
                                                sm={10}
                                              >
                                                <FormItem
                                                  label="Warehouse Containers"
                                                  rules={[
                                                    {
                                                      required: false,
                                                      message:
                                                        'Enter warehouse containers'
                                                    }
                                                  ]}
                                                  className={styles.customLable}
                                                >
                                                  {materials[index]?.batches[i]
                                                    ?.sub_batches[sub_bat_i]
                                                    ?.warehouse_container !==
                                                  '' ? (
                                                    <Input
                                                      value={
                                                        materials[index]
                                                          ?.batches[i]
                                                          ?.sub_batches[
                                                          sub_bat_i
                                                        ]?.warehouse_container
                                                      }
                                                      disabled
                                                    />
                                                  ) : (
                                                    <Select
                                                      showSearch
                                                      placeholder="Scan or select container"
                                                      optionFilterProp="children"
                                                      onChange={(e) =>
                                                        onChangeWarehouse(
                                                          index,
                                                          i,
                                                          sub_bat_i,
                                                          e
                                                        )
                                                      }
                                                      onSearch={(e) =>
                                                        onSearch(
                                                          e,
                                                          materials[index]
                                                            ?.material_id
                                                        )
                                                      }
                                                    >
                                                      {warehouseContainers?.map(
                                                        (item, index) => {
                                                          return (
                                                            <option
                                                              value={item?.id}
                                                              key={index}
                                                            >
                                                              {item?.number}
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                    </Select>
                                                  )}
                                                </FormItem>
                                              </Col>
                                            )}

                                            <Col
                                              xs={24}
                                              xl={5}
                                              span={5}
                                              md={10}
                                              sm={10}
                                            >
                                              <FormItem
                                                label="Batch Number"
                                                rules={[
                                                  {
                                                    required: true,
                                                    message:
                                                      'Select batch number'
                                                  }
                                                ]}
                                                className={styles.customLable}
                                              >
                                                <Input
                                                  value={
                                                    `${materials[index]?.batches[i]?.sub_batches[sub_bat_i]?.batch_number}` ||
                                                    ''
                                                  }
                                                  disabled
                                                />
                                                {/* <Select
                                            showSearch
                                            placeholder="Select a batch number"
                                            optionFilterProp="children"
                                            onChange={(e) =>
                                              onChangebatch(index, i, e)
                                            }
                                            onSearch={onSearch}
                                          >
                                            {allBatches?.map((item, index) => {
                                              return (
                                                <option
                                                  value={item.id}
                                                  key={index}
                                                >
                                                  {item.inhouse_batch_number}
                                                </option>
                                              );
                                            })}
                                          </Select> */}
                                              </FormItem>
                                            </Col>
                                            {user?.level == 3 &&
                                            item?.is_drafted ? (
                                              // materials[index]?.batches[i]
                                              //   ?.sub_batches[sub_bat_i]
                                              //   ?.is_left_over ? (
                                              //   'Left Over Container'
                                              // ) : (
                                              //   ''
                                              // )
                                              ''
                                            ) : batch_item?.sub_batches.length -
                                                1 ==
                                              sub_bat_i ? (
                                              <Col
                                                xs={20}
                                                xl={6}
                                                span={6}
                                                md={10}
                                                sm={10}
                                                className={styles.qr}
                                              >
                                                <FormItem
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message:
                                                        'Indicate if it is left over container'
                                                    }
                                                  ]}
                                                  className={styles.customLable}
                                                  onChange={(e) =>
                                                    onChangeleftover(
                                                      index,
                                                      i,
                                                      sub_bat_i,
                                                      e
                                                    )
                                                  }
                                                  name={`left_over${index}${i}`}
                                                >
                                                  <Radio.Group
                                                    value={
                                                      sub_batch_item?.is_left_over ==
                                                      'Yes'
                                                        ? `${i}${sub_bat_i}`
                                                        : ''
                                                    }
                                                  >
                                                    <Radio
                                                      value={`${i}${sub_bat_i}`}
                                                    >
                                                      Left Over Container
                                                    </Radio>
                                                  </Radio.Group>
                                                </FormItem>
                                              </Col>
                                            ) : (
                                              ''
                                            )}
                                            {sub_bat_i > 0 ? (
                                              user?.level == 3 &&
                                              item?.is_drafted ? (
                                                ''
                                              ) : (
                                                <Col
                                                  xs={2}
                                                  xl={2}
                                                  span={2}
                                                  md={2}
                                                  sm={2}
                                                >
                                                  <CustomButton
                                                    onClick={() =>
                                                      handleDeletesubbatches(
                                                        index,
                                                        i,
                                                        sub_bat_i
                                                      )
                                                    }
                                                    className={styles.qr}
                                                  >
                                                    <DeleteOutlined />
                                                  </CustomButton>
                                                </Col>
                                              )
                                            ) : null}
                                            {batch_item?.sub_batches.length -
                                              1 ==
                                            sub_bat_i ? (
                                              user?.level == 3 &&
                                              item?.is_drafted ? (
                                                ''
                                              ) : (
                                                <Col
                                                  xs={2}
                                                  xl={2}
                                                  span={2}
                                                  md={2}
                                                  sm={2}
                                                >
                                                  <CustomButton
                                                    onClick={() =>
                                                      handleAddsubbatches(
                                                        index,
                                                        i
                                                      )
                                                    }
                                                    className={styles.qr}
                                                  >
                                                    <PlusOutlined />
                                                  </CustomButton>
                                                </Col>
                                              )
                                            ) : null}
                                          </Row>
                                        </div>
                                      )
                                    )}
                                    {batch_item?.dispenses?.length
                                      ? batch_item?.dispenses?.map(
                                          (dis, dis_i) => {
                                            return (
                                              <Row gutter={24} key={dis_i}>
                                                <Col
                                                  xs={20}
                                                  xl={5}
                                                  span={6}
                                                  md={10}
                                                  sm={10}
                                                >
                                                  <FormItem
                                                    label="Dispensing Containers"
                                                    rules={[
                                                      {
                                                        required: false,
                                                        message:
                                                          'Enter warehouse containers'
                                                      }
                                                    ]}
                                                    className={
                                                      styles.customLable
                                                    }
                                                  >
                                                    <Input
                                                      value={
                                                        dis?.dispensing_container
                                                      }
                                                      disabled
                                                    />
                                                  </FormItem>
                                                </Col>
                                                <Col
                                                  xs={14}
                                                  xl={4}
                                                  span={6}
                                                  md={10}
                                                  sm={10}
                                                >
                                                  <FormItem
                                                    label="Tare Weight"
                                                    rules={[
                                                      {
                                                        required: false,
                                                        message:
                                                          'Enter warehouse containers'
                                                      }
                                                    ]}
                                                    className={
                                                      styles.customLable
                                                    }
                                                  >
                                                    <InputNumber
                                                      onChange={(e) =>
                                                        handleChangeTare(
                                                          index,
                                                          i,
                                                          dis_i,
                                                          e
                                                        )
                                                      }
                                                      placeholder="Tare Weight"
                                                      min={0}
                                                      max={1000000}
                                                      style={{
                                                        width: '100%'
                                                      }}
                                                      value={
                                                        parseFloat(
                                                          dis?.tare_weight
                                                        ) || ''
                                                      }
                                                      disabled={
                                                        user?.level == 3 &&
                                                        item?.is_drafted
                                                          ? isEdit[index]
                                                            ? false
                                                            : true
                                                          : item?.is_drafted &&
                                                            dis?.dispense_container_id
                                                          ? true
                                                          : false
                                                      }
                                                    />
                                                  </FormItem>
                                                </Col>
                                                {isEdit[index]}
                                                <Col
                                                  xs={8}
                                                  xl={2}
                                                  span={6}
                                                  md={10}
                                                  sm={10}
                                                >
                                                  <FormItem
                                                    label="UOM"
                                                    rules={[
                                                      {
                                                        required: false,
                                                        message:
                                                          'Enter Net Weight'
                                                      }
                                                    ]}
                                                    className={
                                                      styles.customLable
                                                    }
                                                  >
                                                    <Input
                                                      placeholder="UOM"
                                                      value={
                                                        materials[index]?.uom
                                                      }
                                                      disabled
                                                    />
                                                  </FormItem>
                                                </Col>
                                                <Col
                                                  xs={12}
                                                  xl={3}
                                                  span={6}
                                                  md={10}
                                                  sm={10}
                                                >
                                                  <FormItem
                                                    label="Net Weight"
                                                    rules={[
                                                      {
                                                        required: false,
                                                        message:
                                                          'Enter Net Weight'
                                                      }
                                                    ]}
                                                    className={
                                                      styles.customLable
                                                    }
                                                  >
                                                    <InputNumber
                                                      min={0}
                                                      max={1000000}
                                                      placeholder="Net Weight"
                                                      onChange={(e) =>
                                                        handleChangeNet(
                                                          index,
                                                          i,
                                                          dis_i,
                                                          e
                                                        )
                                                      }
                                                      style={{
                                                        width: '100%'
                                                      }}
                                                      value={
                                                        parseFloat(
                                                          dis?.net_weight
                                                        ) || ''
                                                      }
                                                      disabled={
                                                        user?.level == 3 &&
                                                        item?.is_drafted
                                                          ? isEdit[index]
                                                            ? false
                                                            : true
                                                          : item?.is_drafted &&
                                                            dis?.dispense_container_id
                                                          ? true
                                                          : false
                                                      }
                                                    />
                                                  </FormItem>
                                                </Col>
                                                <Col
                                                  xs={8}
                                                  xl={2}
                                                  span={6}
                                                  md={10}
                                                  sm={10}
                                                >
                                                  <FormItem
                                                    label="UOM"
                                                    rules={[
                                                      {
                                                        required: false,
                                                        message:
                                                          'Enter Net Weight'
                                                      }
                                                    ]}
                                                    className={
                                                      styles.customLable
                                                    }
                                                  >
                                                    <Input
                                                      placeholder="UOM"
                                                      value={
                                                        materials[index]?.uom
                                                      }
                                                      disabled
                                                    />
                                                  </FormItem>
                                                </Col>
                                                <Col
                                                  xs={14}
                                                  xl={4}
                                                  span={6}
                                                  md={10}
                                                  sm={10}
                                                >
                                                  <FormItem
                                                    label="Gross Weight"
                                                    rules={[
                                                      {
                                                        required: false,
                                                        message:
                                                          'Enter Gross containers'
                                                      }
                                                    ]}
                                                    className={
                                                      styles.customLable
                                                    }
                                                  >
                                                    <Input
                                                      placeholder="Gross Weight"
                                                      value={
                                                        parseFloat(
                                                          dis?.gross_weight
                                                        ) || ''
                                                      }
                                                      disabled
                                                    />
                                                  </FormItem>
                                                </Col>
                                                <Col
                                                  xs={8}
                                                  xl={2}
                                                  span={6}
                                                  md={10}
                                                  sm={10}
                                                >
                                                  <FormItem
                                                    label="UOM"
                                                    rules={[
                                                      {
                                                        required: false,
                                                        message:
                                                          'Enter Gross Weight'
                                                      }
                                                    ]}
                                                    className={
                                                      styles.customLable
                                                    }
                                                  >
                                                    <Input
                                                      placeholder="UOM"
                                                      value={
                                                        materials[index]?.uom
                                                      }
                                                      disabled
                                                    />
                                                  </FormItem>
                                                </Col>
                                                {batch_item?.dispenses?.length -
                                                  1 ==
                                                dis_i ? (
                                                  user?.level == 3 &&
                                                  item?.is_drafted ? (
                                                    ''
                                                  ) : (
                                                    <Col
                                                      xs={2}
                                                      xl={2}
                                                      span={2}
                                                      md={2}
                                                      sm={2}
                                                    >
                                                      <CustomButton
                                                        onClick={() =>
                                                          handleAddDispensing(
                                                            index,
                                                            i
                                                          )
                                                        }
                                                        className={
                                                          styles.qrcode
                                                        }
                                                      >
                                                        <PlusOutlined />
                                                      </CustomButton>
                                                    </Col>
                                                  )
                                                ) : null}
                                              </Row>
                                            );
                                          }
                                        )
                                      : ''}
                                  </Card>
                                  <br />
                                </>
                              ))
                            : ''}
                          <br />
                          {openCam && index == data?.index ? (
                            <QrReader
                              constraints={{
                                facingMode: 'environment'
                              }}
                              delay={1000}
                              onError={handleError}
                              onResult={(result) =>
                                handleQrScan(
                                  data?.index,
                                  data?.i,
                                  data?.sub_bat_i,
                                  result
                                )
                              }
                              // chooseDeviceId={()=>selected}
                              style={{
                                width: '3px',
                                height: '3px'
                              }}
                            />
                          ) : (
                            ''
                          )}
                          {/* <div className="text-right">
                              <span
                                style={{
                                  fontSize: '12px',
                                  color: '#c91761'
                                }}
                                onClick={() => handleAddDispensing(index)}
                              >
                                Add Dispensing Container
                              </span>
                            </div>
                            <br /> */}
                          {user?.role !== 'prod_user' ? (
                            <FormItem className="text-center">
                              {item?.is_drafted && user?.level == 3 ? (
                                <Space direction="vertical">
                                  <CustomButton
                                    className={styles.border_button}
                                    onClick={() => UpdateContainers(index)}
                                    loading={loading}
                                    style={{
                                      width: '100%',
                                      borderColor: '#28a745',
                                      color: '#28a745'
                                    }}
                                    ghost
                                  >
                                    Initiate Verification
                                  </CustomButton>
                                </Space>
                              ) : (
                                <Space direction="vertical">
                                  <CustomButton
                                    className={styles.border_button}
                                    type="primary"
                                    onClick={() =>
                                      handleAddbatches(index, item?.material_id)
                                    }
                                    loading={loading}
                                    style={{
                                      width: '100%',
                                      borderColor: item?.pdf_id
                                        ? 'rgb(203 205 203)'
                                        : '#007bff',
                                      color: item?.pdf_id
                                        ? 'rgb(184 195 187)'
                                        : '#007bff'
                                    }}
                                    ghost
                                    disabled={item?.pdf_id ? true : false}
                                  >
                                    Continue Dispensing from another Batch
                                  </CustomButton>
                                  {
                                    user?.role == 'user' ? (
                                      truckShow ? (
                                        <Image
                                          src={truck}
                                          preview={false}
                                          style={{
                                            width: '100px !important',
                                            height: '100px'
                                          }}
                                        />
                                      ) : (
                                        <CustomButton
                                          className={styles.border_button}
                                          onClick={() =>
                                            handlePrintIssueTag(index)
                                          }
                                          loading={loading}
                                          style={{
                                            width: '100%',
                                            borderColor: item?.pdf_id
                                              ? 'rgb(203 205 203)'
                                              : '#28a745',
                                            color: item?.pdf_id
                                              ? 'rgb(184 195 187)'
                                              : '#28a745'
                                          }}
                                          disabled={
                                            item?.pdf_id ? true : printIssue
                                          }
                                          ghost
                                        >
                                          Submit & Print Issue Tag
                                        </CustomButton>
                                      )
                                    ) : (
                                      ''
                                    )
                                    // user?.role == 'prod_user' ? (
                                    //   <CustomButton
                                    //     className={styles.border_button}
                                    //     onClick={() =>
                                    //       handlepdfPrintIssueTag(
                                    //         index,
                                    //         item?.pdf_id
                                    //       )
                                    //     }
                                    //     loading={loading}
                                    //     style={{
                                    //       width: '100%',
                                    //       borderColor: item?.prod_user_id
                                    //         ? 'rgb(173 183 175)'
                                    //         : '#28a745',
                                    //       color: item?.prod_user_id
                                    //         ? 'rgb(173 183 175)'
                                    //         : '#28a745'
                                    //     }}
                                    //     disabled={
                                    //       item?.prod_user_id ? true : false
                                    //     }
                                    //     ghost
                                    //   >
                                    //     Print Issue Tag
                                    //   </CustomButton>
                                    // ) : (
                                    //   ''
                                    // )
                                  }
                                  {/* <CustomButton 
                                type="primary"
                                onClick={() => handleLeftOverTag(index)}
                                loading={loading}
                                style={{
                                  width: '100%',
                                  borderColor: '#007bff',
                                  color: '#007bff'
                                }}
                                ghost
                              >
                                Print Leftover Container Label
                              </CustomButton > */}
                                </Space>
                              )}
                            </FormItem>
                          ) : (
                            ''
                          )}
                        </Panel>
                      </Collapse>
                    ) : (
                      ''
                    );
                  })
                : ''}
            </Space>
          </>
          {/* ) : (
            ''
          )} */}
        </Card>
        <br />
        {/* {data?.length ? (
          <div className={`${styles.table_container}`}>
            <CustomTable
              columns={columns}
              dataSource={data || []}
              scroll={{ x: 'auto' }}
            />
          </div>
        ) : (
          ''
        )} */}
        {user?.role !== 'prod_user' ? (
          <>
            <br />
            <FormItem className="text-center">
              <Space direction="vertical" size={15}>
                {user?.level == 3 ? (
                  <CustomButton
                    onClick={() => handleaddMaterial()}
                    loading={loading}
                  >
                    Add material
                  </CustomButton>
                ) : type == 'bmr' ? (
                  <CustomButton
                    onClick={() => handleaddMaterial()}
                    loading={loading}
                    disabled={!buttondis}
                  >
                    Add material
                  </CustomButton>
                ) : materials?.length > counter && type == 'bmr' ? (
                  <CustomButton
                    onClick={() => handleaddMaterial()}
                    loading={loading}
                  >
                    Add material
                  </CustomButton>
                ) : (
                  ''
                )}
                <CustomButton
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  // ghost
                >
                  Print Dispensing Sheet
                </CustomButton>
              </Space>
            </FormItem>
          </>
        ) : (
          ''
        )}
      </Form>
      {isModal ? (
        <VerificationModal
          isModal={isModal}
          materials={materials}
          setIsModal={setIsModal}
          material={dispenseMaterial}
          setIsEdit={setIsEdit}
          page="manual"
        />
      ) : (
        ''
      )}

      {isDelete ? (
        <DeleteMaterialsModel
          isModal={isDelete}
          setIsDelete={setIsDelete}
          setIsConfirm={setIsConfirm}
          isConfirm={isConfirm}
          stockBatches={stockBatches}
          setIsVerify={setIsVerify}
          isVerify={isVerify}
          stockMatID={stockMatID}
          dispenseRequestId={params.id}
          setNewStockBatches={setNewStockBatches}
          newStockBatches={newStockBatches}
          handleGetAllMaterials={handleGetAllMaterials}
        />
      ) : (
        ''
      )}
    </Layout>
  );
};
export default ManualDispense;
