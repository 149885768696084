import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  // Collapse,
  Form,
  Input,
  DatePicker,
  Select,
  Radio,
  Space,
  Image,
  message,
  Card
  // Select
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
// import CustomButton  from '@/common/CustomButton';
import truck from '../images/truck-delivery-done.gif';
import { useDispatch, useSelector } from 'react-redux';
import {
  // createMaterialInward,
  getDocsbyId,
  createMRN
  //   getAllSuppliers,
  //   getAllMaterialslist
} from '../../redux/slice';
import { history } from 'app/history';
import { useParams } from 'react-router-dom';
// import dayjs from 'dayjs';
import CustomButton from '@/common/CustomButton';
import moment from 'moment';
import ViewMaterialPage from '@/features/material/components/commonviewMaterialPage';

const { TextArea } = Input;
const dateFormat = 'YYYY-MM-DD';

const CreateGenerateMRNPage = ({ loading }) => {
  const params = useParams();
  const { material } = useSelector((state) => state.batch);
  console.log(material);
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [truckShow, setTruckShow] = useState(false);
  // const [containers, setContainers] = useState([]);
  const [batches, setBatches] = useState([]);
  const [materials, setMaterials] = useState({});
  console.log(material?.no_of_bacthes_invoice, material?.batch_details?.length);
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo.errorFields.length);
    if (errorInfo.errorFields.length > 0) {
      message.error(
        'Please select a response to all the questions in the checklist'
      );
    }
  };

  // const disabledDate = (current) => {
  //   return current && current > dayjs().endOf('day');
  // };

  const handleCapacity = (e) => {
    const { name, value } = e.target;
    setMaterials({ ...materials, [name]: value });
  };
  console.log(materials.data_logger);
  const onFinish = (values) => {
    console.log(values);
    // history.push('/dashboard');
    // setBatchUpdate(false);
    values['document_id'] = params.id;
    values['seal_integrity'] = materials.seal_integrity;
    values['data_logger'] = materials.data_logger;
    values['dedusting_status'] = values.dedusting;
    values['labels_defaced'] = materials.labels_defaced;
    values['batches'] = batches;
    // console.log(values);
    setTruckShow(true);

    dispatch(createMRN(values)).then(function (response) {
      if (response?.payload?.success) {
        // setTimeout(() => {
        // setBatchUpdate(true);
        form.resetFields();
        setTruckShow(false);
        message.success(response?.payload?.message);
        history.push('/list');
        // }, 2000);
      } else {
        setTruckShow(false);
      }
    });
  };

  const handleStandardCancel = () => {
    history.push(`/batch-details/${params.id}`);
  };
  useEffect(() => {
    form.setFieldsValue({
      invoice_no: localStorage.getItem('invoice_num'),
      material_code:
        material?.material?.code || localStorage.getItem('material_code'),
      material_name:
        material?.material?.name || localStorage.getItem('material_name')
    });
  }, []);

  useEffect(() => {
    handleGetDocsbyId();
  }, []);

  const handleGetDocsbyId = () => {
    const payload = {
      id: params.id
    };
    dispatch(getDocsbyId(payload)).then(function (response) {
      if (response?.payload?.success) {
        setBatches(
          Array.apply(
            0,
            Array(response?.payload?.data?.batch_details?.length)
          )?.map((item, index) => ({
            batch_id: response?.payload?.data?.batch_details[index]?.id,
            comments: ''
          }))
        );
      }
    });
  };
  const handleComments = (index, e) => {
    const list = [...batches];
    list[index]['comments'] = e.target.value;
    setBatches(list);
  };
  console.log(batches);
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <ViewMaterialPage />
      {/* <Card title="GENERATE MRN's" className={styles.CustomPanel}>
        <Row gutter={24}>
          <Col xs={24} xl={8} span={8}>
            <FormItem
              label="Material Code"
              name="material_code"
              rules={[
                {
                  required: false,
                  message: 'Enter Material code'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                placeholder="Enter Material code"
                disabled
                defaultValue={material?.material?.code}
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={8} span={8}>
            <FormItem
              label="Material Name"
              name="material_name"
              rules={[
                {
                  required: false,
                  message: 'Enter Material name'
                }
              ]}
              className={styles.customLable}
            >
              <Input placeholder="Enter Material name" disabled />
            </FormItem>
          </Col>
          <Col xs={24} xl={8} span={8}>
            <FormItem
              label="Invoice Number"
              name="invoice_no"
              rules={[
                {
                  required: false,
                  message: 'Enter Invoice number'
                }
              ]}
              className={styles.customLable}
            >
              <Input placeholder="Enter Invoice number" disabled />
            </FormItem>
          </Col>
        </Row>
      </Card> */}
      <br />
      {material?.batch_details?.map((item, index) => {
        return (
          <>
            <Card
              title={`BATCH ${index + 1}`}
              className={styles.CustomPanel}
              key={index}
            >
              <Row gutter={24}>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Mfg.Batch/Lot no"
                    // name={`mfg_batch_lot_no${index}`}
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Enter Batch Number"
                      disabled
                      value={item?.mfg_batch_lot_no}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Manufacturing date"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <DatePicker
                      placeholder="Select Date"
                      style={{ width: '100%' }}
                      name="mfg_Date"
                      disabled
                      value={moment(item?.mfg_date, dateFormat)}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Manufacturing re-test date"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <DatePicker
                      placeholder="Select Date"
                      style={{ width: '100%' }}
                      name="mfg_retest_date"
                      disabled
                      value={moment(item?.mfg_retest_date, dateFormat)}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Manufacturing expiry date"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <DatePicker
                      placeholder="Select Date"
                      style={{ width: '100%' }}
                      name="mfg_expiry_date"
                      disabled
                      value={moment(item?.mfg_expiry_date, dateFormat)}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="In-house expiry date"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <DatePicker
                      placeholder="Select Date"
                      style={{ width: '100%' }}
                      name="in_house_expiry_date"
                      disabled
                      value={moment(item?.in_house_expiry_date, dateFormat)}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Inhouse batch number"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Enter Batch Number"
                      disabled
                      value={item?.inhouse_batch_number}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="No. of containers/packs received"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Enter Batch Number"
                      disabled
                      value={item?.containers_packs_received}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Invoice Quantity"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Invoice Quantity"
                      disabled
                      value={item?.invoice_quantity}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Received Quantity"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Invoice Quantity"
                      disabled
                      value={item?.received_quantity}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Batch Unit Rate"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch Unit Rate'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Batch Unit Rate"
                      disabled
                      value={item?.batch_unit_rate}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Batch Total Price"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch Total Price'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Batch Total Price"
                      disabled
                      value={item?.batch_total_price}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Test to be Performed"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Select
                      placeholder="Select test perfomed"
                      // disabled={!isEdit}
                      value={item?.test_to_be_performed}
                      disabled
                    >
                      <option value="Identification Test">
                        Identification Test
                      </option>
                      <option value="Complete Analysis">
                        Complete Analysis
                      </option>
                      {/* <option value="R&D Identification Test">
                        R&D Identification Test
                      </option> */}
                    </Select>
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Focus MRN #"
                    rules={[
                      {
                        required: false,
                        message: 'Enter Focus MRN #'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Focus MRN #"
                      disabled
                      value={item?.focus_mrn_no || 'NA'}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={24} span={24}>
                  <FormItem
                    label="Comments"
                    rules={[
                      {
                        required: false,
                        message: 'Enter comments'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <TextArea
                      placeholder="Please enter comments"
                      name="comments"
                      onChange={(e) => handleComments(index, e)}
                    />
                  </FormItem>
                </Col>
              </Row>
              {/* <Row gutter={24}>
                <Col xs={24} xl={12} span={12}>
                  <p className={styles.customLable}>Mfg.Batch/Lot no</p>
                  <p className={styles.customValue}>{item?.mfg_batch_lot_no}</p>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <p className={styles.customLable}>Manufacturing date</p>
                  <p className={styles.customValue}>
                    {' '}
                    {item?.mfg_date
                      ? moment(item?.mfg_date).format('DD MMM YYYY')
                      : ''}
                  </p>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <p className={styles.customLable}>
                    Manufacturing re-test date
                  </p>
                  <p className={styles.customValue}>
                    {item?.mfg_retest_date
                      ? moment(item?.mfg_retest_date).format('DD MMM YYYY')
                      : ''}
                  </p>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <p className={styles.customLable}>
                    Manufacturing expiry date
                  </p>
                  <p className={styles.customValue}>
                    {item?.mfg_expiry_date
                      ? moment(item?.mfg_expiry_date).format('DD MMM YYYY')
                      : ''}
                  </p>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <p className={styles.customLable}>Inhouse batch number</p>
                  <p className={styles.customValue}>
                    {item?.inhouse_batch_number || 'NA'}
                  </p>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <p className={styles.customLable}>
                    No. of containers/packs received
                  </p>
                  <p className={styles.customValue}>
                    {item?.containers_packs_received}
                  </p>
                </Col>

                <Col xs={24} xl={24} span={24}>
                  <FormItem
                    label="Comments"
                    rules={[
                      {
                        required: false,
                        message: 'Enter comments'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <TextArea
                      placeholder="Please enter comments"
                      name="comments"
                      onChange={(e) => handleComments(index, e)}
                    />
                  </FormItem>
                </Col>
              </Row> */}
            </Card>
            <br />
          </>
        );
      })}
      <Card title="MATERIAL VERIFICATION" className={styles.CustomPanel}>
        <Row gutter={24}>
          <Col xs={24} xl={8} span={8}>
            <FormItem
              label="Data logger received along with consignment"
              name="data_logger"
              rules={[
                {
                  required: true,
                  message: 'Choose Yes/No'
                }
              ]}
              className={styles.customLable}
            >
              <Radio.Group
                onChange={handleCapacity}
                className={styles.customRadio}
                name="data_logger"
              >
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Radio.Group>
            </FormItem>
          </Col>
          <Col xs={24} xl={8} span={8}>
            <FormItem
              label="Vendor status label defaced"
              name="labels_defaced"
              rules={[
                {
                  required: true,
                  message: 'Choose Yes/No'
                }
              ]}
              className={styles.customLable}
            >
              <Radio.Group
                onChange={handleCapacity}
                className={styles.customRadio}
                name="labels_defaced"
              >
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Radio.Group>
            </FormItem>
          </Col>
          <Col xs={24} xl={8} span={8}>
            <FormItem
              label="Material dedusting status"
              name="dedusting"
              rules={[
                {
                  required: true,
                  message: 'Choose Yes/No'
                }
              ]}
              className={styles.customLable}
            >
              <Radio.Group
                onChange={handleCapacity}
                className={styles.customRadio}
                name="dedusting"
              >
                <Radio value="Yes">Performed</Radio>
                <Radio value="No">Not performed</Radio>
              </Radio.Group>
            </FormItem>
          </Col>
          <Col xs={24} xl={8} span={8}>
            <FormItem
              label="Seal integrity of the containers"
              name="seal_integrity"
              rules={[
                {
                  required: true,
                  message: 'Choose Yes/No'
                }
              ]}
              className={styles.customLable}
            >
              <Radio.Group
                onChange={handleCapacity}
                className={styles.customRadio}
                name="seal_integrity"
              >
                <Radio value="Yes">Satisfactory</Radio>
                <Radio value="No">Not satisfactory</Radio>
              </Radio.Group>
            </FormItem>
          </Col>
          {/* <Col xs={24} xl={12} span={12} md={24} sm={24}>
            <FormItem
              label="Test to be Performed"
              name="test_to_be_performed"
              rules={[
                {
                  required: true,
                  message: 'Select any test to be performed'
                }
              ]}
              className={styles.customLable}
            >
              <Select placeholder="Select Test to be performed">
                <option value="Identification Test">Identification Test</option>
                <option value="Complete Analysis">Complete Analysis</option>
              </Select>
            </FormItem>
          </Col> */}
        </Row>
      </Card>
      <br />
      <FormItem className="text-center">
        <Space>
          <CustomButton htmlType="button" onClick={handleStandardCancel}>
            Back
          </CustomButton>

          {truckShow ? (
            <Image
              src={truck}
              preview={false}
              style={{ width: '100px !important', height: '100px' }}
            />
          ) : (
            <CustomButton type="primary" htmlType="submit" loading={loading}>
              {`Generate MRN`}
            </CustomButton>
          )}
        </Space>
      </FormItem>
    </Form>
  );
};
export default CreateGenerateMRNPage;
