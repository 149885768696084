import React, { useEffect, useState } from 'react';
import { Form, Card, Tag, Input, Space } from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllBatches
  //   createWastage,
  //   getPastContainerDetails
} from '../../redux/slice';
import CustomTable from 'common/Content/CustomTable';
import moment from 'moment';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import { getLoggedInUser, getRoles } from 'utilities/helpers';
import Layout from '@/common/Layout';
import { useLocation } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';

const { Search } = Input;

const QAListpage = () => {
  const [form] = Form.useForm();
  const { user } = getLoggedInUser();
  const { SuperadminQCdata } = getRoles('Quality Control');
  const { batchdetails, pagination } = useSelector((state) => state.qaapproval);

  const searchParam = useLocation().search;
  const searchParams = new URLSearchParams(searchParam);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [batches, setBatches] = useState('');

  console.log(batchdetails);
  const dispatch = useDispatch();
  useEffect(() => {
    handleGetAllWastageDetails();
  }, [page, search]);

  const handleGetAllWastageDetails = () => {
    const payload = { page, status: 'Quarantine', search };
    dispatch(getAllBatches(payload)).then((response) => {
      if (response?.payload.success) {
        setBatches(response?.payload?.data);
      }
    });
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const columns = [
    {
      title: 'Batch Number',
      dataIndex: 'inhouse_batch_number',
      key: 'inhouse_batch_number',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return searchParams.get('type') == 'test_requests' ? (
          <a href={`test_request_form?id=${record?.id}`}>{text}</a>
        ) : user?.level == 3 || SuperadminQCdata?.[0]?.level == 3 ? (
          <a href={`qc-approval?id=${record?.id}`}>{text}</a>
        ) : (
          text
        );
      }
    },
    {
      title: 'Material Code',
      dataIndex: 'code',
      key: 'code',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.material?.code || 'NA';
      }
    },
    {
      title: 'Material Name',
      dataIndex: 'material_name',
      key: 'material_name',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.material?.name || 'NA';
      }
    },
    {
      title: 'Quantity',
      dataIndex: 'received_quantity',
      key: 'received_quantity',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.received_quantity || 'NA';
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return (
          (
            <Tag
              color={
                record?.status === 'pending'
                  ? '#007bff'
                  : record?.status === 'Approved'
                  ? '#28a745'
                  : record?.status === 'Rejected'
                  ? '#dc3545'
                  : record?.status === 'Under Sampling'
                  ? '#17a2b8'
                  : record?.status === 'Under Test'
                  ? '#343a40'
                  : '#FF9800'
              }
            >
              {record?.status.toUpperCase()}
            </Tag>
          ) || 'NA'
        );
      }
    },
    {
      title: 'Type',
      dataIndex: 'is_code_transfer',
      key: 'is_code_transfer',
      ellipsis: true,
      width: '100px',
      render: (text) => {
        return (
          (
            <Tag ghost color={text ? 'processing' : 'default'}>
              {text ? 'Code to Code' : 'General'}
            </Tag>
          ) || 'NA'
        );
      }
    },
    {
      title: 'Status Updated On',
      dataIndex: 'approved_on',
      key: 'approved_on',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.status === 'Quarantine'
          ? moment(record?.createdAt).format('DD MMM YYYY')
          : record?.qc_update_logs[0]?.updated_date
          ? moment(record?.qc_update_logs[0]?.updated_date).format(
              'DD MMM YYYY'
            )
          : 'NA';
      }
    },
    {
      title: 'View',
      dataIndex: 'inhouse_batch_number',
      key: 'inhouse_batch_number',
      align: 'center',
      width: '100px',
      render: (text, record) => {
        return searchParams.get('type') == 'test_requests' ? (
          <a href={`test_request_form?id=${record?.id}&type=in_active`}>
            <EyeOutlined />
          </a>
        ) : user?.level == 3 ||
          user?.level == 2 ||
          SuperadminQCdata?.[0]?.level == 3 ||
          SuperadminQCdata?.[0]?.level == 3 ? (
          <a href={`qc-approval?id=${record?.id}&type=in_active`}>
            {' '}
            <EyeOutlined />
          </a>
        ) : (
          <EyeOutlined />
        );
      },
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    }
  ];

  return (
    <Layout sider={false}>
      <Form form={form} layout="vertical">
        <Card
          title={
            <span>
              {searchParams.get('type') == 'test_requests'
                ? 'Test Request Forms'
                : 'Batches List'}
            </span>
          }
          className={`${styles.cardStyles_approver}`}
          extra={
            <Space>
              <Search
                onChange={(e) => handleChange(e)}
                className={`w-100 ${styles.searchbar}`}
                justify="start"
                align="start"
                placeholder="Search by Batch number"
              />
            </Space>
          }
        >
          <div className={`${styles.table_container}`}>
            <CustomTable
              columns={columns}
              pagination={{
                size: 'small',
                showSizeChanger: false,
                total: pagination?.total_records || 0,
                onChange: (page) => {
                  setPage(page);
                }
              }}
              dataSource={batches}
            />
          </div>
        </Card>
        <br />
        <FormItem className="text-center">
          <CustomButton
            className={styles.inwardButton}
            onClick={() => history.back()}
            type="primary"
          >
            Back to Home
          </CustomButton>
        </FormItem>
      </Form>
    </Layout>
  );
};
export default QAListpage;
