import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { changePassword } from './api';
// import LocalStorage from 'utilities/localStorage';
// import { history } from 'app/history';

const initialState = {};

const actions = {
  FORGOTPASSWORD: 'login/FORGOTPASSWORD'
};

export const forgotPassword = createAsyncThunk(
  actions.FORGOTPASSWORD,
  async (payload) => {
    const response = await changePassword('update-password', payload);
    return response;
  }
);

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    resetLoginDetails: (state) => {
      state.token = '';
      state.user = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        console.log('asdasd');
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  }
});

export default loginSlice.reducer;
