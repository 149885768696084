import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  container,
  wastage,
  Createwastage,
  sampling,
  Createsampling,
  allSamples,
  allSamplesById,
  update
} from './api';

const initialState = {
  allMaterials: [],
  allSamples: [],
  Samples: {},
  pagination: {}
};

const actions = {
  GET_ALL_CONTAINERS: 'stocks/GET_ALL_CONTAINERS',
  CREATE_WASTAGE: 'stocks/CREATE_DISPATCH',
  GET_PAST_DETAILS: 'stocks/GET_PAST_DETAILS',
  GET_PAST_SAMPLING_DETAILS: 'stocks/GET_PAST_SAMPLING_DETAILS',
  CREATE_SAMPLING: 'stocks/CREATE_SAMPLING',
  GET_ALL_SAMPLES: 'stocks/GET_ALL_SAMPLES',
  GET_SAMPLES_BY_ID: 'stocks/GET_SAMPLES_BY_ID',
  UPDATE_SAMPLES: 'stocks/UPDATE_SAMPLES'
};

export const getAllContainers = createAsyncThunk(
  actions.GET_ALL_CONTAINERS,
  async (payload) => {
    const response = await container('all', payload);

    return response;
  }
);

export const getPastContainerDetails = createAsyncThunk(
  actions.GET_PAST_DETAILS,
  async (payload) => {
    const response = await wastage('all', payload);

    return response;
  }
);

export const getPastSamplingDetails = createAsyncThunk(
  actions.GET_PAST_SAMPLING_DETAILS,
  async (payload) => {
    const response = await sampling('all', payload);

    return response;
  }
);

export const createWastage = createAsyncThunk(
  actions.CREATE_WASTAGE,
  async (payload) => {
    const response = await Createwastage('create', payload);

    return response;
  }
);
export const createSampling = createAsyncThunk(
  actions.CREATE_SAMPLING,
  async (payload) => {
    const response = await Createsampling('create', payload);

    return response;
  }
);

export const getAllSample = createAsyncThunk(
  actions.GET_ALL_SAMPLES,
  async (payload) => {
    const response = await allSamples('all', payload);

    return response;
  }
);

export const getsamples = createAsyncThunk(
  actions.GET_SAMPLES_BY_ID,
  async (payload) => {
    const response = await allSamplesById(`all`, payload);

    return response;
  }
);

export const updateSampling = createAsyncThunk(
  actions.UPDATE_SAMPLES,
  async (payload) => {
    const response = await update(`create?id=${payload.id}`, payload);

    return response;
  }
);

export const stocksSlice = createSlice({
  name: 'stocks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllContainers.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllContainers.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllContainers.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createWastage.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createWastage.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createWastage.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getPastContainerDetails.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getPastContainerDetails.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getPastContainerDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getPastSamplingDetails.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getPastSamplingDetails.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getPastSamplingDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createSampling.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createSampling.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createSampling.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllSample.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllSample.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, pagination, message: msg } = action.payload;
        if (success) {
          console.log(pagination);
          state.allSamples = data;
          state.pagination = pagination || null;
        } else {
          message.error(msg);
          state.allSamples = [];
        }
      })
      .addCase(getAllSample.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getsamples.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getsamples.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, pagination } = action.payload;
        if (success) {
          state.Samples = data;
          state.pagination = pagination || null;
        } else {
          state.Samples = {};
        }
      })
      .addCase(getsamples.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(updateSampling.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(updateSampling.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(updateSampling.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
  }
});
export default stocksSlice.reducer;
