import CustomButton from '@/common/CustomButton';
import { Card, Col, Divider, Row, Badge } from 'antd';
import React, { useEffect } from 'react';
import Styles from '@/common/Layout/index.module.less';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getLablebyId } from '../../redux/slice';
import moment from 'moment';

const MaterialLabelPage = () => {
  const { lables } = useSelector((state) => state.label);
  const dispatch = useDispatch();
  const params = useParams();
  console.log(lables);
  useEffect(() => {
    handleGetLable();
  }, []);

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);
  const handleClose = () => {
    window.open('https://google.com/', '_self');
    window.close();
  };

  const handleGetLable = () => {
    const payload = {
      id: params.id
    };
    dispatch(getLablebyId(payload));
  };

  return (
    <>
      <Badge.Ribbon
        text={lables?.qc_status?.toUpperCase()}
        color={
          lables?.qc_status == 'pending'
            ? '#007FFF'
            : lables?.qc_status == 'Quarantine'
            ? '#FF9800'
            : lables?.qc_status == 'approved'
            ? '#4CAF50'
            : '#007FFF'
        }
      >
        <Card>
          <>
            <br />
            <Row>
              <Col span={12}>
                <h4 style={{ fontWeight: 'bold' }}>Name of the Material</h4>
              </Col>
              <Col span={3}>:</Col>
              <Col span={9}>
                {lables?.document_verification?.material?.name}
              </Col>
            </Row>
            <Divider className={Styles.divider} />
            <Row>
              <Col span={12}>
                <h4 style={{ fontWeight: 'bold' }}>Material Code</h4>
              </Col>
              <Col span={3}>:</Col>
              <Col span={9}>
                {lables?.document_verification?.material?.code}
              </Col>
            </Row>
            <Divider className={Styles.divider} />
            <Row>
              <Col span={12}>
                <h4 style={{ fontWeight: 'bold' }}>In-House Batch Number</h4>
              </Col>
              <Col span={3}>:</Col>
              <Col span={9}>{lables?.batch_detail?.inhouse_batch_number}</Col>
            </Row>
            <Divider className={Styles.divider} />
            <Row>
              <Col span={12}>
                <h4 style={{ fontWeight: 'bold' }}>In-House Retest Date</h4>
              </Col>
              <Col span={3}>:</Col>
              <Col span={9}>
                {lables?.batch_detail?.inhouse_retest_date
                  ? moment(lables?.batch_detail?.inhouse_retest_date).format(
                      'DD MMM YYYY'
                    )
                  : 'NA'}
              </Col>
            </Row>
            {/* <Divider className={Styles.divider} />
            <Row>
              <Col span={12}>
                <h4 style={{ fontWeight: 'bold' }}>Material Status</h4>
              </Col>
              <Col span={3}>:</Col>
              <Col span={9}>
                {lables?.document_verification?.material_status}
              </Col>
            </Row> */}
            <Divider className={Styles.divider} />
            <Row>
              <Col span={12}>
                <h4 style={{ fontWeight: 'bold' }}>Manufacturer Name</h4>
              </Col>
              <Col span={3}>:</Col>
              <Col span={9}>
                {lables?.document_verification?.supplier?.manufacturer_name}
              </Col>
            </Row>
            <Divider className={Styles.divider} />
            <Row>
              <Col span={12}>
                <h4 style={{ fontWeight: 'bold' }}>Supplier Name</h4>
              </Col>
              <Col span={3}>:</Col>
              <Col span={9}>
                {lables?.document_verification?.supplier?.name}
              </Col>
            </Row>
            <Divider className={Styles.divider} />
            <Row>
              <Col span={12}>
                <h4 style={{ fontWeight: 'bold' }}>Material Vendor Status</h4>
              </Col>
              <Col span={3}>:</Col>
              <Col span={9}>
                {lables?.document_verification?.supplier?.vendor_status}
              </Col>
            </Row>
            <Divider className={Styles.divider} />
            <Row>
              <Col span={12}>
                <h4 style={{ fontWeight: 'bold' }}>Mfg. Batch/Lot No</h4>
              </Col>
              <Col span={3}>:</Col>
              <Col span={9}>
                {lables?.batch_detail?.mfg_batch_lot_no || 'NA'}
              </Col>
            </Row>
            <Divider className={Styles.divider} />
            <Row>
              <Col span={12}>
                <h4 style={{ fontWeight: 'bold' }}>Mfg. Date</h4>
              </Col>
              <Col span={3}>:</Col>
              <Col span={9}>
                {lables?.batch_detail?.mfg_date
                  ? moment(lables?.batch_detail?.mfg_date).format('DD MMM YYYY')
                  : 'NA'}
              </Col>
            </Row>
            <Divider className={Styles.divider} />
            <Row>
              <Col span={12}>
                <h4 style={{ fontWeight: 'bold' }}>Mfg. Expiry Date</h4>
              </Col>
              <Col span={3}>:</Col>
              <Col span={9}>
                {lables?.batch_detail?.mfg_expiry_date
                  ? moment(lables?.batch_detail?.mfg_expiry_date).format(
                      'DD MMM YYYY'
                    )
                  : 'NA'}
              </Col>
            </Row>
            <Divider className={Styles.divider} />
            <Row>
              <Col span={12}>
                <h4 style={{ fontWeight: 'bold' }}>Mfg. Re-test Date</h4>
              </Col>
              <Col span={3}>:</Col>
              <Col span={9}>
                {lables?.batch_detail?.mfg_retest_date
                  ? moment(lables?.batch_detail?.mfg_retest_date).format(
                      'DD MMM YYYY'
                    )
                  : 'NA'}
              </Col>
            </Row>
            <Divider className={Styles.divider} />
            <Row>
              <Col span={12}>
                <h4 style={{ fontWeight: 'bold' }}>Inhouse Expiry Date</h4>
              </Col>
              <Col span={3}>:</Col>
              <Col span={9}>
                {lables?.batch_detail?.in_house_expiry_date
                  ? moment(lables?.batch_detail?.in_house_expiry_date).format(
                      'DD MMM YYYY'
                    )
                  : 'NA'}
              </Col>
            </Row>
            <Divider className={Styles.divider} />
            <Row>
              <Col span={12}>
                <h4 style={{ fontWeight: 'bold' }}>Sampling Status</h4>
              </Col>
              <Col span={3}>:</Col>
              <Col span={9}>{lables?.sampling_status || 'NA'}</Col>
            </Row>
            <Divider className={Styles.divider} />
            <Row>
              <Col span={12}>
                <h4 style={{ fontWeight: 'bold' }}>Invoice Quantity</h4>
              </Col>
              <Col span={3}>:</Col>
              <Col span={9}>
                {lables?.batch_detail?.invoice_quantity || 'NA'}
              </Col>
            </Row>
            <Divider className={Styles.divider} />
            <Row>
              <Col span={12}>
                <h4 style={{ fontWeight: 'bold' }}>Received Quantity</h4>
              </Col>
              <Col span={3}>:</Col>
              <Col span={9}>
                {lables?.batch_detail?.received_quantity || 'NA'}
              </Col>
            </Row>
            <Divider className={Styles.divider} />
            <Row>
              <Col span={12}>
                <h4 style={{ fontWeight: 'bold' }}>
                  No. of Containers/Packs received
                </h4>
              </Col>
              <Col span={3}>:</Col>
              <Col span={9}>
                {lables?.batch_detail?.containers_packs_received}
              </Col>
            </Row>
            <Divider className={Styles.divider} />
            <Row>
              <Col span={12}>
                <h4 style={{ fontWeight: 'bold' }}>Container Number</h4>
              </Col>
              <Col span={3}>:</Col>
              <Col
                span={9}
              >{`${lables?.sequence}/${lables?.batch_detail?.packs_accepted}`}</Col>
            </Row>
            <Divider className={Styles.divider} />
            <Row>
              <Col span={12}>
                <h4 style={{ fontWeight: 'bold' }}>Material Location</h4>
              </Col>
              <Col span={3}>:</Col>
              <Col span={9}>{lables?.current_location}</Col>
            </Row>
            <Divider className={Styles.divider} />
            <Row>
              <Col span={12}>
                <h4 style={{ fontWeight: 'bold' }}>
                  Left Over Container Status
                </h4>
              </Col>
              <Col span={3}>:</Col>
              <Col span={9}>{lables?.is_left_over_container || 'No'}</Col>
            </Row>
          </>
        </Card>
      </Badge.Ribbon>
      <br />
      <div style={{ textAlign: 'center' }}>
        <CustomButton type="primary" onClick={handleClose}>
          Close
        </CustomButton>
      </div>
    </>
  );
};
export default MaterialLabelPage;
