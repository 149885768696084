import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { sendMail } from './api';
import { history } from 'app/history';

const initialState = {
  loading: false,
  token: '',
  user: null
};

const actions = {
  RESET: 'login/RESET'
};

export const resetPassword = createAsyncThunk(
  actions.RESET,
  async (payload) => {
    const response = await sendMail('send-email', payload);
    return response;
  }
);

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        console.log('adasd');
        if (success) {
          message.success(msg);
          history.push('login');
        } else {
          message.error(msg);
        }
      })
      .addCase(resetPassword.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  }
});
export default loginSlice.reducer;
