import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { document, create_batch, get_focus_mrn } from './api';

const initialState = {
  allMaterials: [],
  material: {},
  pagination: [],
  getAllMaterilLoading: null,
  MaterialsList: [],
  suppliers: []
};

const actions = {
  GET_ALL_MATERIALS: 'materials/GET_ALL_MATERIALS',
  GET_DOCS_BY_ID: 'materials/GET_DOCS_BY_ID',
  GET_FOCUS_MRNS: 'materials/GET_FOCUS_MRNS'
};

export const getDocsbyId = createAsyncThunk(
  actions.GET_DOCS_BY_ID,
  async (payload) => {
    const response = await document('all', payload);

    return response;
  }
);

export const createBatch = createAsyncThunk(
  actions.CREATE_MATERIAL,
  async (payload) => {
    const response = await create_batch('create', payload);
    return response;
  }
);

export const getAllFocusMRNList = createAsyncThunk(
  actions.GET_FOCUS_MRNS,
  async (payload) => {
    const response = await get_focus_mrn('mrnlist', payload);
    return response;
  }
);

export const batchSlice = createSlice({
  name: 'batch',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDocsbyId.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getDocsbyId.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data } = action.payload;
        if (success) {
          state.material = data;
        } else {
          state.material = '';
        }
      })
      .addCase(getDocsbyId.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createBatch.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createBatch.fulfilled, (state) => {
        state.getAllMaterilLoading = false;

        // const { success, message: msg } = action.payload;
        // if (!success) {
        //   message.error(msg);
        // }
      })
      .addCase(createBatch.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllFocusMRNList.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllFocusMRNList.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllFocusMRNList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
  }
});
export default batchSlice.reducer;
