import React, { useEffect } from 'react';
import { Form, Card, Tag } from 'antd';
import styles from '@/common/Layout/index.module.less';
import { useDispatch, useSelector } from 'react-redux';
import { getAllReconsiliations } from '../../redux/slice';
import CustomTable from 'common/Content/CustomTable';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import { getLoggedInUser, getRoles } from 'utilities/helpers';
import { useState } from 'react';

const StockReconsilationPage = () => {
  const [form] = Form.useForm();

  const { allStocks, pagination } = useSelector(
    (state) => state.reconsiliations
  );
  const { user } = getLoggedInUser();

  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  useEffect(() => {
    handleGetAllStockReconsiliations();
  }, [page]);

  const { Superadmindata } = getRoles('Warehouse');
  const { SuperadminQCdata } = getRoles('Quality Control');

  const handleGetAllStockReconsiliations = () => {
    const payload = { page };
    dispatch(getAllReconsiliations(payload));
  };

  const reiseRequest = () => {
    history.push('/reconsiliation_request');
  };

  const columns = [
    {
      title: 'Batch Number',
      dataIndex: 'inhouse_batch_number',
      key: 'inhouse_batch_number',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return (user?.role == 'qc_initiator' ||
          (user?.role == 'user' && user?.level == 3) ||
          Superadmindata?.[0]?.level == 3) &&
          record?.status !== 'approved' ? (
          <a href={`reconsiliation_request?id=${record?.id}`}>
            {record?.batch_detail?.inhouse_batch_number}
          </a>
        ) : (
          record?.batch_detail?.inhouse_batch_number
        );
      }
    },
    {
      title: 'Material Code',
      dataIndex: 'code',
      key: 'code',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.material?.code;
      }
    },
    {
      title: 'Difference Qty',
      dataIndex: 'difference_qty',
      key: 'difference_qty',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return `${text} ${record?.material?.uom}`;
      }
    },
    {
      title: '% Variance',
      dataIndex: 'variance',
      key: 'variance',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return parseFloat(record?.variance).toFixed(3);
      }
    },
    {
      title: 'Status',
      dataIndex: 'inhouse_batch_number',
      key: 'inhouse_batch_number',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return (
          <Tag
            color={
              record?.status === 'pending'
                ? '#007bff'
                : record?.status === 'approved'
                ? '#28a745'
                : record?.status === 'reject'
                ? '#dc3545'
                : '#007bff'
            }
          >
            {record?.status.toUpperCase()}
          </Tag>
        );
      }
    }
  ];

  return (
    <Form
      form={form}
      layout="vertical"
      //   onFinish={onFinish}
    >
      <Card
        className={`${styles.cardStyles_approver}`}
        title={
          <span>
            {user?.role == 'qc_initiator' || SuperadminQCdata?.[0]?.level == 3
              ? 'APPROVAL FOR STOCK RECONCILIATION'
              : 'STOCK RECONCILIATION'}
          </span>
        }
        extra={
          user?.role == 'user' || Superadmindata?.[0]?.level == 3 ? (
            <CustomButton type="primary" onClick={reiseRequest}>
              Raise Stock Reconciliation Request{' '}
            </CustomButton>
          ) : (
            ''
          )
        }
      >
        <div className={`${styles.table_container}`}>
          <CustomTable
            columns={columns}
            pagination={{
              size: 'small',
              total: pagination?.total_records || 0,
              showSizeChanger: false,
              onChange: (page) => {
                setPage(page);
              }
            }}
            // scroll={{ x: 'auto' }}
            dataSource={allStocks}
          />
        </div>
      </Card>
    </Form>
  );
};
export default StockReconsilationPage;
