import React from 'react';
import Layout from '@/common/Layout';
import QCApprovalPage from './components/QCApprovalPage';

const QA = () => {
  return (
    <>
      <Layout sider={false}>
        <QCApprovalPage />
      </Layout>
    </>
  );
};

export default QA;
