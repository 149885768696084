import React from 'react';
import Layout from '@/common/Layout';
import DispenseLabelPage from './components/DispenseLabelPage';

const DispenseLabel = () => {
  return (
    <>
      <Layout sider={false} menuItems={false}>
        <DispenseLabelPage />
      </Layout>
    </>
  );
};

export default DispenseLabel;
