import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  // DatePicker,
  // Select,
  Radio,
  Space,
  Image,
  message,
  Card
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import truck from '../images/truck-delivery-done.gif';
import { useDispatch, useSelector } from 'react-redux';
import { createVehicleStorage, getDocsbyId } from '../../redux/slice';
import { history } from 'app/history';
import Layout from '@/common/Layout';
import { useParams } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import VerificationModal from '../VerificationModal';
import { getLoggedInUser, getRoles } from 'utilities/helpers';

const InvoiceDetailsPage = ({ loading }) => {
  const { material } = useSelector((state) => state.material);
  const params = useParams();
  const dispatch = useDispatch();

  const { user } = getLoggedInUser();
  const { Superadmindata } = getRoles('Warehouse');

  const [form] = Form.useForm();

  const [truckShow, setTruckShow] = useState(false);
  const [materials, setMaterials] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [isModal, setIsModal] = useState(false);

  const handleCapacity = (e) => {
    const { name, value } = e.target;
    setMaterials({ ...materials, [name]: value });
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo.errorFields.length);
    if (errorInfo.errorFields.length > 0) {
      message.error(
        'Please select a response to all the questions in the checklist'
      );
    }
  };
  console.log(localStorage.getItem('inv_num'));
  const onFinish = (values) => {
    values['for_graviti'] = materials.gravity;
    values['purchase_order'] = materials.purchase_order;
    values['boe_exists'] = materials.boe;
    values['coa_exists'] = materials.coa;
    values['coc_exists'] = materials.coc;
    values['dc_exists'] = materials.dc;
    values['invoice_exists'] = materials.invoice_exist;
    values['msds_exists'] = materials.msds;
    values['approved_vendor'] = materials.approved_vendor;
    values['vechile_clean'] = materials.clineleness_vehicle;
    values['material_enclosed'] = materials.material_condition;
    values['stroage_condition'] = materials.storage_condition;
    values['packages_labelled'] = materials.packages_labelled;
    values['seal_integrity'] = materials.seal_integrity;
    values['data_logger'] = materials.data_logger;
    values['labels_defaced'] = materials.labels_defaced;
    values['dedusting'] = materials.de_dusting_status;
    values['weight_verification'] = materials.weight_verification;
    values['document_id'] = params.id;
    setTruckShow(true);

    dispatch(createVehicleStorage(values)).then(function (response) {
      if (response?.payload?.success) {
        setTimeout(() => {
          message.success(response?.payload?.message);
          response?.payload?.data?.material_status === 'veh_ver_approved'
            ? history.push(`/invoice-details/${response?.payload?.data?.id}`)
            : history.push('/pending-receipts');
        }, 2000);
      } else {
        setTruckShow(false);
      }
    });
  };

  const handleStandardCancel = () => {
    history.push('/dashboard');
  };

  useEffect(() => {
    form.setFieldsValue({
      invoice_num: localStorage.getItem('invoice_num')
    });
  }, []);

  useEffect(() => {
    handleGetDocsbyId();
  }, [isModal]);

  const handleGetDocsbyId = () => {
    const payload = {
      id: params.id
    };
    dispatch(getDocsbyId(payload));
  };

  const handleEdit = () => {
    setIsEdit(true);
    form.setFieldsValue({
      gravity:
        material?.for_graviti == true
          ? 'Yes'
          : material?.for_graviti == false
          ? 'No'
          : 'NA',
      invoice_dc_boe_available:
        material?.invoice_dc_boe_available == true
          ? 'Yes'
          : material?.invoice_dc_boe_available == false
          ? 'No'
          : 'NA',
      invoice_number: material?.invoice_number,
      po_available:
        material?.po_available == true
          ? 'Yes'
          : material?.po_available == false
          ? 'No'
          : 'NA',
      coa_coc_msda_available:
        material?.coa_coc_msda_available == true
          ? 'Yes'
          : material?.coa_coc_msda_available == false
          ? 'No'
          : 'NA',
      material_vendor_status: material?.material_vendor_status,
      manufacture_site_address_matching:
        material?.manufacture_site_address_matching == true
          ? 'Yes'
          : material?.manufacture_site_address_matching == false
          ? 'No'
          : 'NA'
    });
  };
  console.log(material);
  return (
    <Layout sider={false}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Space direction="vertical">
          <Card
            title="DOCUMENT VERIFICATION"
            className={styles.CustomPanel}
            extra={
              user?.level == 3 || Superadmindata?.[0]?.level == 3 ? (
                <EditOutlined onClick={() => handleEdit()} />
              ) : (
                ''
              )
            }
          >
            {isEdit ? (
              <>
                <Row gutter={24}>
                  <Col xs={24} xl={8} span={8}>
                    <FormItem
                      label="Is the material meant for graviti?"
                      name="gravity"
                      rules={[
                        {
                          required: true,
                          message: 'Choose Yes/No'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Radio.Group
                        onChange={handleCapacity}
                        className={styles.customRadio}
                        name="gravity"
                      >
                        <Radio value="Yes">Yes</Radio>
                        <Radio value="No">No</Radio>
                      </Radio.Group>
                    </FormItem>
                  </Col>

                  <Col xs={24} xl={8} span={8}>
                    <FormItem
                      label="Invoice/DC/BOE Number available"
                      name="invoice_dc_boe_available"
                      rules={[
                        {
                          required: true,
                          message: 'Choose Yes/No'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Radio.Group
                        onChange={handleCapacity}
                        className={styles.customRadio}
                        name="invoice_exist"
                      >
                        <Radio value="Yes">Yes</Radio>
                        <Radio value="No">No</Radio>
                      </Radio.Group>
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={8} span={8}>
                    <FormItem
                      label="Invoice/DC/BOE Number"
                      name="invoice_number"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Invoice number'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Enter Invoice number"
                        name="invoice_number"
                        onChange={handleCapacity}
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={8} span={8}>
                    <FormItem
                      label="Purchase Order available"
                      name="po_available"
                      rules={[
                        {
                          required: true,
                          message: 'Choose Yes/No'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Radio.Group
                        onChange={handleCapacity}
                        className={styles.customRadio}
                        name="po_available"
                      >
                        <Radio value="Yes">Yes</Radio>
                        <Radio value="No">No</Radio>
                      </Radio.Group>
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={8} span={8}>
                    <FormItem
                      label="COA/COC, MSDS available"
                      name="coa_coc_msda_available"
                      rules={[
                        {
                          required: true,
                          message: 'Choose Yes/No'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Radio.Group
                        onChange={handleCapacity}
                        className={styles.customRadio}
                        name="coa_coc_msda_available"
                      >
                        <Radio value="Yes">Yes</Radio>
                        <Radio value="No">No</Radio>
                      </Radio.Group>
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={8} span={8}>
                    <FormItem
                      label="Material Vendor Status"
                      name="material_vendor_status"
                      rules={[
                        {
                          required: true,
                          message: 'Choose Yes/No'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Radio.Group
                        onChange={handleCapacity}
                        className={styles.customRadio}
                        name="material_vendor_status"
                      >
                        <Radio value="approved">Approved</Radio>
                        <Radio value="prov">Prov Approved</Radio>
                        <Radio value="New">New</Radio>
                      </Radio.Group>
                    </FormItem>
                  </Col>
                  <Col span={18}>
                    <FormItem
                      label="Manufacturing site address matching with Provisionally
                Approved/Approved Vendor list"
                      name="manufacture_site_address_matching"
                      rules={[
                        {
                          required: true,
                          message: 'Choose Yes/No'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Radio.Group
                        onChange={handleCapacity}
                        className={styles.customRadio}
                        name="approved_vendor"
                      >
                        <Radio value="Yes">Yes</Radio>
                        <Radio value="No">No</Radio>
                      </Radio.Group>
                    </FormItem>
                  </Col>
                </Row>
                <FormItem className="text-center">
                  <Space>
                    <CustomButton
                      htmlType="button"
                      onClick={handleStandardCancel}
                      className={styles.border_button}
                    >
                      Cancel
                    </CustomButton>
                    {truckShow ? (
                      <Image
                        src={truck}
                        preview={false}
                        style={{ width: '100px !important', height: '100px' }}
                      />
                    ) : (
                      <CustomButton
                        type="primary"
                        // htmlType="submit"
                        loading={loading}
                        className={styles.border_button}
                        onClick={() => setIsModal(true)}
                        ghost
                      >
                        Initiate Verification
                      </CustomButton>
                    )}
                  </Space>
                </FormItem>
              </>
            ) : (
              <Row gutter={24}>
                <Col xs={24} xl={8} span={8}>
                  <p className={styles.customLable}>
                    Is the Material meant for Graviti?
                  </p>
                  <p className={styles.customValue}>
                    {material?.for_graviti == true
                      ? 'Yes'
                      : material?.for_graviti == false
                      ? 'No'
                      : 'NA'}
                  </p>
                </Col>
                <Col xs={24} xl={8} span={8}>
                  <p className={styles.customLable}>
                    Invoice/DC/BOE Number Available?
                  </p>
                  <p className={styles.customValue}>
                    {material?.invoice_dc_boe_available == true
                      ? 'Yes'
                      : material?.invoice_dc_boe_available == false
                      ? 'No'
                      : 'NA'}
                  </p>
                </Col>
                <Col xs={24} xl={8} span={8}>
                  <p className={styles.customLable}>Invoice/DC/BOE Number</p>
                  <p className={styles.customValue}>
                    {material?.invoice_number || 'NA'}
                  </p>
                </Col>
                <Col xs={24} xl={8} span={8}>
                  <p className={styles.customLable}>
                    Purchase Order Available?
                  </p>
                  <p className={styles.customValue}>
                    {material?.po_available == true
                      ? 'Yes'
                      : material?.po_available == false
                      ? 'No'
                      : 'NA'}
                  </p>
                </Col>
                <Col xs={24} xl={8} span={8}>
                  <p className={styles.customLable}>COA/COC, MSDS Available?</p>
                  <p className={styles.customValue}>
                    {material?.coa_coc_msda_available == true
                      ? 'Yes'
                      : material?.coa_coc_msda_available == false
                      ? 'No'
                      : 'NA'}
                  </p>
                </Col>
                <Col xs={24} xl={8} span={8}>
                  <p className={styles.customLable}>Material Vendor Status</p>
                  <p className={styles.customValue}>
                    {material?.material_vendor_status?.toUpperCase() || 'NA'}
                  </p>
                </Col>
                <Col xs={24} xl={8} span={8}>
                  <p className={styles.customLable}>
                    Manufacturing site address matching with Provisionally
                    Approved/Approved Vendor list
                  </p>
                  <p className={styles.customValue}>
                    {material?.manufacture_site_address_matching == true
                      ? 'Yes'
                      : material?.manufacture_site_address_matching == false
                      ? 'No'
                      : 'NA'}
                  </p>
                </Col>
              </Row>
            )}
          </Card>

          <Card title="VEHICLE STORAGE AREA" className={styles.CustomPanel}>
            <Row gutter={24}>
              <Col xs={24} xl={8} span={8}>
                <FormItem
                  label="Vehicle cleanliness"
                  name="vehicle_cleanliness"
                  rules={[
                    {
                      required: true,
                      message: 'Choose Yes/No'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Radio.Group
                    onChange={handleCapacity}
                    className={styles.customRadio}
                    name="vehicle_cleanliness"
                  >
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <FormItem
                  label="Storage in  enclosed condition"
                  name="storage_enclose"
                  rules={[
                    {
                      required: true,
                      message: 'Choose Yes/No'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Radio.Group
                    onChange={handleCapacity}
                    className={styles.customRadio}
                    name="storage_enclose"
                  >
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <FormItem
                  label="Storage condition available"
                  name="storage_conditions"
                  rules={[
                    {
                      required: true,
                      message: 'Choose Yes/No'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Radio.Group
                    onChange={handleCapacity}
                    className={styles.customRadio}
                    name="storage_conditions"
                  >
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
            </Row>
          </Card>
          <FormItem className="text-center">
            <Space>
              <CustomButton htmlType="button" onClick={handleStandardCancel}>
                Cancel
              </CustomButton>
              {truckShow ? (
                <Image
                  src={truck}
                  preview={false}
                  style={{ width: '100px !important', height: '100px' }}
                />
              ) : (
                <CustomButton
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Proceed
                </CustomButton>
              )}
            </Space>
          </FormItem>
        </Space>
        {/* </Space> */}
      </Form>
      {isModal ? (
        <VerificationModal
          isModal={isModal}
          materials={materials}
          setIsModal={setIsModal}
          material={material}
          setIsEdit={setIsEdit}
        />
      ) : (
        ''
      )}
    </Layout>
  );
};
export default InvoiceDetailsPage;
