import React from 'react';
import Layout from '@/common/Layout';
import StockReconsilationPage from './components/stockReconsilationPage';

const StockReconsilation = () => {
  return (
    <>
      <Layout sider={false}>
        <StockReconsilationPage />
      </Layout>
    </>
  );
};

export default StockReconsilation;
