import React, { useState, useEffect, useCallback } from 'react';
import {
  Card,
  Empty,
  Tag,
  Space,
  Popconfirm,
  Input,
  InputNumber,
  // Col,
  // Select,
  message
} from 'antd';
import CustomTable from 'common/Content/CustomTable';
import Layout from '@/common/Layout';
import styles from '@/common/Layout/index.module.less';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllDispenses,
  deleteDispense,
  getdetails,
  getFifo,
  getassaydetails
} from '../../redux/slice';
import { ValidateMaterial } from '@/features/dispensing/redux/slice';
import { DndContext } from '@dnd-kit/core';
import FormItem from '@/common/FormItem';
import CustomButton from '@/common/CustomButton';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  HolderOutlined,
  DeleteOutlined,
  EyeOutlined,
  ReadOutlined
} from '@ant-design/icons';
import { history } from 'app/history';
import moment from 'moment';
import { getLoggedInUser } from 'utilities/helpers';
import CustomModal from '@/common/CustomModal';

const { Search } = Input;

const ListofQaDispenseMaterials = () => {
  const { dispenses, getAllMaterilLoading, pagination } = useSelector(
    (state) => state.material
  );
  const { user } = getLoggedInUser();
  const [dataSource, setDataSource] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [showDisablePopup, setShowDisablePopup] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [expandData, setExpandData] = useState();
  // const [batchId, setBatchId] = useState();
  const [fifoBatches, setFifoBatches] = useState([]);
  const [newBatches, setNewBatches] = useState();
  const [dispenseId, setDispenseId] = useState();
  const [disBtn, setDisBtn] = useState(false);
  const [viewAssay, setViewAssay] = useState(false);
  const [assayData, setAssayData] = useState([]);

  console.log(expandData, dispenses);
  const dispatch = useDispatch();
  console.log(dataSource);
  useEffect(() => {
    handleGetAllDispenses();
  }, [page, search]);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const handleDeleteUser = (payload) => {
    dispatch(deleteDispense(payload)).then(() => {
      handleGetAllDispenses();
      setShowDisablePopup(false);
    });
  };

  const handleGetAllDispenses = () => {
    const payload = { page: page, search: search, type: 'material' };
    dispatch(getAllDispenses(payload)).then(function (response) {
      if (response?.payload?.success) {
        console.log(response?.payload?.data);
        setDataSource(response?.payload?.data);
      }
    });
  };

  const handlegetFefo = (id) => {
    setFifoBatches([]);
    setNewBatches([]);
    const payload = { id: id };
    setDispenseId(id);
    dispatch(getFifo(payload)).then((response) => {
      if (response?.payload?.success) {
        console.log(response?.payload?.data, 'dataaaa');
        setFifoBatches(response?.payload?.data);
        setExpandData([]);
        setShowModal(true);
      }
    });
  };

  const handleAssayCal = () => {
    console.log(fifoBatches, 'fifo');
    setDisBtn(true);
    const batches = [...fifoBatches].sort((a, b) => (a.type > b.type ? 1 : -1));
    console.log(batches);
    const payload = {
      dispensing_request_id: dispenseId,
      bmr_material: batches
    };
    dispatch(ValidateMaterial(payload)).then((response) => {
      if (response?.payload?.success) {
        message.success(response?.payload?.message);
        setShowModal(false);
      }
    });

    console.log(fifoBatches);
  };
  const ShowdetailedView = (id, type) => {
    console.log(id, type);
    setShowModal(true);
    const payload = { id: id, type: type };
    dispatch(getdetails(payload)).then((response) => {
      if (response?.payload?.success) {
        console.log(response?.payload?.data);
        setFifoBatches([]);
        response?.payload?.data?.type == 'bmr'
          ? setExpandData(response?.payload?.data)
          : setExpandData(response?.payload?.data);
      }
    });
  };

  const ShowdetailedAssayView = (id, type) => {
    console.log(id, type);
    setShowModal(true);
    setViewAssay(true);
    const payload = { id: id, type: type };
    dispatch(getassaydetails(payload)).then((response) => {
      if (response?.payload?.success) {
        console.log(response?.payload?.data, 'Assay data');
        setFifoBatches([]);
        response?.payload?.data?.type == 'bmr'
          ? setAssayData(response?.payload?.data)
          : setAssayData(response?.payload?.data);
      }
    });
  };
  console.log(expandData);
  // const handleSubmit = () => {
  //   const payload = { update_sequence: dataSource };
  //   dispatch(updateDispense(payload)).then(function (response) {
  //     if (response?.payload?.success) {
  //       history.push('/dispensing-list');
  //     }
  //   });
  // };

  const visibility = useCallback((record) => {
    return showDisablePopup[record.id];
  }, showDisablePopup);

  // const handleDeleteBatch = (index, id, batch_id) => {
  //   console.log(id);
  //   // dataMerge;
  //   let temp_materials = [...newBatches];
  //   temp_materials.splice(index, 1);
  //   setNewBatches(temp_materials);

  //   let temp_batches = [...fifoBatches];
  //   const material = fifoBatches.filter((material) => material.id == id);
  //   let mat_ind = '';
  //   fifoBatches.map((item, index) => {
  //     if (item.material_id == id) {
  //       mat_ind = index;
  //     }
  //   });
  //   console.log(material);
  //   let bat_ind = '';
  //   fifoBatches?.[mat_ind]?.batches.map((item, index) => {
  //     console.log(item.batch_details_id);
  //     console.log(batch_id);
  //     if (item.batch_details_id == batch_id) {
  //       bat_ind = index;
  //     }
  //   });
  //   console.log(bat_ind);
  //   temp_batches[mat_ind].batches.splice(bat_ind, 1);
  //   setFifoBatches(temp_batches);
  //   // console.log(batch);
  // };
  console.log(fifoBatches);
  // const handleChangeBatch = (id) => {
  //   console.log(id);
  //   setBatchId(id);
  // };
  const columns = [
    {
      title: 'Product Batch #',
      dataIndex: 'product_batch_number',
      ellipsis: true,
      width: '150px',
      fixed: 'left',
      render: (text, record) => {
        return (
          // record?.status == 'QA LC Pending' ? (
          <a href={`dispensing/${record?.id}`}>{text || 'NA'}</a>
        );
      }
    },
    {
      title: 'BMR/Material #',
      dataIndex: 'bmr_number',
      ellipsis: true,
      width: '100px',
      // fixed: 'left',

      render: (text) => {
        return `${text || 'NA'}`;
      }
    },

    {
      title: 'Product Name',
      dataIndex: 'product_name',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return `${record?.product_detail?.name || 'NA'}`;
      }
    },
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return `${moment(record?.createdAt).format('DD MMM YYYY') || 'NA'}`;
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return (
          <Tag
            color={
              record?.status === 'WH LC Pending'
                ? '#dc3545'
                : record?.status === 'QA LC Pending'
                ? '#ff9933'
                : record?.status === 'Approved'
                ? '#28a745'
                : '#007bff'
            }
          >
            {record?.status}
          </Tag>
        );
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return (
          <Space>
            {record?.status == 'Dispensed' && user?.role == 'user' ? (
              <>
                <Popconfirm
                  title={`Are you sure do you want to ${
                    record.is_active ? 'Deactivate' : 'Activate'
                  } this Dispense?`}
                  visible={visibility(record)}
                  key={record.id}
                  onConfirm={(e) => {
                    e.stopPropagation();
                    handleDeleteUser({
                      id: record.id,
                      is_active: !record?.is_active
                    });
                  }}
                  onCancel={(e) => {
                    e.stopPropagation();
                    setShowDisablePopup({ [record.id]: false });
                  }}
                  okText={record.is_active ? 'Deactivate' : 'Activate'}
                  cancelText="No"
                  okButtonProps={{
                    loading: getAllMaterilLoading,
                    type: record.is_active ? 'danger' : 'primary'
                  }}
                >
                  {/* <DeleteOutlined
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDisablePopup({ [record.id]: true });
                }}
                style={{ color: '#e80e94' }}
              /> */}
                  <DeleteOutlined size="small" checked={record.is_active} />
                </Popconfirm>
                {record.type == 'bmr' &&
                record?.sub_type === 'auto' &&
                record?.assay_status === false ? (
                  <EyeOutlined
                    size="small"
                    onClick={() => handlegetFefo(record.id, record.type)}
                    // onClick={() => history.push(`/print-assay/${record.id}`)}
                  />
                ) : record.type == 'bmr' &&
                  record?.sub_type !== 'auto' &&
                  record?.assay_status === false ? (
                  <EyeOutlined
                    size="small"
                    onClick={() => ShowdetailedView(record.id, record.type)}
                    // onClick={() => history.push(`/print-assay/${record.id}`)}
                  />
                ) : record.type == 'material' ? (
                  <EyeOutlined
                    size="small"
                    onClick={() => ShowdetailedView(record.id, record.type)}
                  />
                ) : record.type == 'bmr' && record?.assay_status == true ? (
                  <EyeOutlined
                    size="small"
                    onClick={() => ShowdetailedView(record.id, record.type)}
                    // onClick={() => history.push(`/print-assay/${record.id}`)}
                  />
                ) : (
                  ''
                )}
              </>
            ) : record.type == 'bmr' &&
              record.sub_type === 'auto' &&
              record?.assay_status === false ? (
              <EyeOutlined
                size="small"
                onClick={() => handlegetFefo(record.id, record.type)}
              />
            ) : record.type == 'bmr' &&
              record.sub_type !== 'auto' &&
              record?.assay_status === false ? (
              <EyeOutlined
                size="small"
                onClick={() => ShowdetailedView(record.id, record.type)}
              />
            ) : record.type == 'material' ? (
              <EyeOutlined
                size="small"
                onClick={() => ShowdetailedView(record.id, record.type)}
              />
            ) : record.type == 'bmr' && record?.assay_status == true ? (
              <ReadOutlined
                size="small"
                onClick={() => ShowdetailedAssayView(record.id, record.type)}
                // onClick={() => history.push(`/print-assay/${record.id}`)}
              />
            ) : (
              ''
            )}
          </Space>
        );
      }
    }
    // {
    //   title: 'Batch Number',
    //   dataIndex: 'batch_number',
    //   ellipsis: true,
    //   width: '100px',
    //   render: (text, record) => {
    //     return `${
    //       record?.material?.document_verifications[0]?.batch_details[0]
    //         ?.inhouse_batch_num || 'NA'
    //     }`;
    //   }
    // },
    // {
    //   title: 'Material Code',
    //   dataIndex: 'material_code',
    //   ellipsis: true,
    //   width: '100px',
    //   render: (text, record) => {
    //     return `${record?.material?.code || 'NA'}`;
    //   }
    // },
    // {
    //   title: 'Material Name',
    //   dataIndex: 'material_name',
    //   ellipsis: true,
    //   width: '100px',
    //   render: (text, record) => {
    //     return (
    //       (record?.material?.name?.length > 20 ? (
    //         <Popover content={record?.material?.name}>
    //           {record?.material?.name.substring(0, 20) + '...'}
    //         </Popover>
    //       ) : (
    //         record?.material?.name
    //       )) || 'NA'
    //     );
    //   }
    // },
    // {
    //   dataIndex: 'id',
    //   key: 'id',
    //   ellipsis: true,
    //   width: '100px',
    //   render: () => {
    //     return <HolderOutlined />;
    //   }
    // }
  ];

  const Row = ({ children, ...props }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging
    } = useSortable({
      id: props['data-row-key']
    });
    const style = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && {
          ...transform,
          scaleY: 1
        }
      )?.replace(/translate3d\(([^,]+),/, 'translate3d(0,'),
      transition,
      ...(isDragging
        ? {
            position: 'relative',
            zIndex: 9999
          }
        : {})
    };
    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if (child?.key === 'id') {
            return React.cloneElement(child, {
              children: (
                <HolderOutlined
                  ref={setActivatorNodeRef}
                  style={{
                    touchAction: 'none',
                    cursor: 'move'
                  }}
                  {...listeners}
                />
              )
            });
          }
          return child;
        })}
      </tr>
    );
  };

  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        <div className={styles.empty_state_text}>
          There are no material requests to display
        </div>
      </span>
    )
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.id === active.id);
        const overIndex = previous.findIndex((i) => i.id === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  const onChange = (value) => {
    console.log(value);
    // setNetWeights((prevData) => {
    //   const newData = [...prevData];
    //   const newItem = { ...newData[index] };
    //   newItem.net_weight = value;
    //   newData[index] = newItem;

    //   return newData;
    // });
  };
  console.log(dataSource);
  // const expandedRowRender = (data) => {
  // console.log(id);
  console.log(fifoBatches);
  // const onChangeBatch = (id, batch_id, index, value) => {
  //   const material = fifoBatches.filter(
  //     (material) => material.material_id == id
  //   );
  //   console.log(material, batch_id, 'mmmmm');
  //   const batch = material?.[0]?.batches.filter(
  //     (batch) => batch.batch_details_id == batch_id
  //   );
  //   const update_material = material?.[0]?.batch_details.filter(
  //     (batch) => batch.inhouse_batch_number == value
  //   );
  //   console.log(batch);
  //   console.log(update_material);
  //   console.log(newBatches, 'new_bat');
  //   let list = [...newBatches];
  //   list[index].number = update_material?.[0]?.inhouse_batch_number;
  //   list[index].batch_id = update_material?.[0]?.id;
  //   list[index].avl_qty = update_material?.[0]?.received_quantity;

  //   console.log(id, batch_id, index, value);

  //   let newlist = [...fifoBatches];
  //   newlist[index].batches[0].number =
  //     update_material?.[0]?.inhouse_batch_number;
  //   newlist[index].batches[0].avl_qty = update_material?.[0]?.received_quantity;

  //   // batch[0].number = update_material?.[0]?.number;
  //   // batch[0].batch_details_id = update_material?.[0]?.id;
  //   // batch[0].avl_qty = update_material?.[0]?.received_quantity;
  //   setFifoBatches(newlist);
  //   setNewBatches(list);
  //   console.log(fifoBatches, batch, value, 'tttt');
  // };
  useEffect(() => {
    if (newBatches?.length) {
      console.log('h');
    } else {
      const data = fifoBatches.map((item) => {
        const data = item.batches.map((item2) => {
          return {
            material_id: item.material_id,
            batch_id: item2.batch_details_id,
            name: item.name,
            code: item.code,
            standard_quantity: item.standard_quantity,
            number: item2.number,
            avl_qty: item2.avl_qty,
            bin_number: item2.bin_number,
            batch_details: item?.batch_details,
            batch_qty:
              item?.is_product == 'BPR'
                ? item?.dispensed_quantity
                : item?.batch_qty
          };
        });
        return data;
      });
      const dataMerge = [].concat.apply([], data);
      console.log(dataMerge);
      dataMerge?.length ? setNewBatches(dataMerge) : '';
    }
  }, [fifoBatches]);
  console.log(newBatches);
  // const sharedOnCell = (_, index) => {
  //   if (index === 1) {
  //     return {
  //       colSpan: 0
  //     };
  //   }
  //   return {};
  // };
  const columns1 = [
    {
      title: 'Material Code',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return record?.material?.code;
      }
    },
    {
      title: 'Material',
      dataIndex: 'upgradeNum',
      key: 'upgradeNum',
      render: (text, record) => {
        return record?.material?.name;
      }
    },
    {
      title: 'Required Quantity',
      dataIndex: 'upgradeNum',
      key: 'upgradeNum',
      render: (text, record) => {
        return `${record?.required_weight} ${record?.material?.uom}`;
      }
    }
  ];

  const columns2 = [
    {
      title: 'Material Code',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return record?.code;
      }
      // onCell: (_, index) => {
      //   if (index === 0) {
      //     return {
      //       rowSpan: 2
      //     };
      //   }
      //   if (index === 1) {
      //     return {
      //       rowSpan: 0
      //     };
      //   }
      //   return {};
      // }
    },
    {
      title: 'Material',
      dataIndex: 'upgradeNum',
      key: 'upgradeNum',
      render: (text, record) => {
        return record?.name;
      }
      // onCell: sharedOnCell
    },
    {
      title: 'Required Quantity',
      dataIndex: 'standard_quantity',
      key: 'standard_quantity',
      render: (text, record) => {
        return record?.batch_qty;
      }
    },
    {
      title: 'BIN Location',
      dataIndex: 'bin_location',
      key: 'bin_location',
      render: (text, record) => {
        return record?.bin_number;
      }
    },
    {
      title: 'Inhouse Batch #',
      dataIndex: 'bin_location',
      key: 'bin_location',
      render: (text, record) => {
        // return batchId == record.batch_id ? (
        //   <Select
        //     showSearch
        //     placeholder="Inhouse Batch Number"
        //     optionFilterProp="children"
        //     onChange={(e) =>
        //       onChangeBatch(record.material_id, record.batch_id, index, e)
        //     }
        //     // onSearch={onSearch}
        //     // value={selectSecondValue[index]}
        //   >
        //     {record.batch_details?.map((item, index) => {
        //       return (
        //         <option value={item.inhouse_batch_number} key={index}>
        //           {item.inhouse_batch_number}
        //         </option>
        //       );
        //     })}
        //   </Select>
        // ) : (
        return record?.number;
        // );
      }
    },
    {
      title: 'Available Quantity',
      dataIndex: 'upgradeNum',
      key: 'upgradeNum',
      render: (text, record, index) => (
        <InputNumber
          value={record?.avl_qty}
          min={1}
          max={100000}
          style={{
            width: '100%'
          }}
          placeholder="Enter Net Weight"
          onChange={(v) => onChange(v, index)}
          // disabled={batchId == record.batch_id ? false : true}
          disabled={true}
        />
      )
    }
    // {
    //   title: 'Change',
    //   dataIndex: 'batch_id',
    //   key: 'batch_id',
    //   render: (text) => {
    //     return (
    //       <CustomButton  onClick={() => handleChangeBatch(text)}>
    //         {'Change'}
    //       </CustomButton >
    //     );
    //   }
    // },
    // {
    //   title: 'Action',
    //   dataIndex: 'batch_id',
    //   key: 'batch_id',
    //   render: (text, record, index) => {
    //     return (
    //       <DeleteOutlined
    //         onClick={() =>
    //           handleDeleteBatch(index, record.material_id, record.batch_id)
    //         }
    //       />
    //     );
    //   }
    // }
  ];

  return (
    <Layout sider={false}>
      <Card
        className={`${styles.cardStyles_approver}`}
        title={
          <span style={{ fontWeight: 'bold', color: '#c91761' }}>
            {user?.role == 'qc_initiator'
              ? 'Approval for LC Dispense Material'
              : 'Dispense Material'}
          </span>
        }
        extra={
          <Space>
            {user?.role == 'qa_initiator' ? (
              <CustomButton
                type="primary"
                ghost
                onClick={() => history.push('/dispensing')}
              >
                Create
              </CustomButton>
            ) : (
              ''
            )}
            <Search
              onChange={(e) => handleChange(e)}
              className={`w-100 ${styles.searchbar}`}
              justify="start"
              align="start"
              placeholder="Search by Product Batch number"
            />
          </Space>
        }
      >
        <DndContext onDragEnd={onDragEnd}>
          <SortableContext
            items={dataSource.map((i) => i.id)}
            strategy={verticalListSortingStrategy}
          >
            <div className={`${styles.table_container}`}>
              <CustomTable
                locale={locale}
                loading={getAllMaterilLoading}
                columns={columns}
                dataSource={dataSource}
                pagination={{
                  size: 'small',
                  total: pagination?.total_records || 0,
                  showSizeChanger: false,
                  pageSize: 10,
                  onChange: (page) => {
                    setPage(page);
                  }
                }}
                components={{
                  body: {
                    row: Row
                  }
                }}
                rowKey="id"
                // className={styles.scrolling}
                scroll={{ x: 'auto' }}
                // expandable={{
                //   expandedRowRender,
                //   defaultExpandedRowKeys: ['0']
                // }}
              />
            </div>
          </SortableContext>
        </DndContext>
      </Card>
      {showModal ? (
        <CustomModal
          title="Dispense Material Details"
          visible={showModal}
          width={expandData?.type == 'material' ? '70%' : '70%'}
          footer={null}
          className={styles.customModal}
          closable={false}
        >
          {expandData?.type == 'material' ? (
            <CustomTable
              columns={columns1}
              dataSource={expandData?.dispensing_materials}
              pagination={false}
              loading={getAllMaterilLoading}
            />
          ) : expandData?.type == 'bmr' && expandData?.sub_type !== 'auto' ? (
            <div
              dangerouslySetInnerHTML={{
                __html: expandData?.bmr_partial_details
              }}
              // className={styles.tdbody}
            />
          ) : !viewAssay ? (
            <CustomTable
              columns={columns2}
              dataSource={newBatches}
              pagination={false}
              loading={getAllMaterilLoading}
            />
          ) : (
            ''
          )}
          <br />
          {!viewAssay ? (
            <FormItem className="text-center">
              <Space size={12}>
                <CustomButton
                  htmlType="button"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </CustomButton>
                {fifoBatches.length ? (
                  <CustomButton
                    htmlType="button"
                    type="primary"
                    onClick={() => handleAssayCal(false)}
                    disabled={
                      fifoBatches[0]?.assay_calcuate === 'exist'
                        ? true
                        : false || disBtn
                    }
                  >
                    Calculate Assay
                  </CustomButton>
                ) : (
                  ''
                )}
              </Space>
            </FormItem>
          ) : (
            ''
          )}
          {viewAssay ? (
            <Row gutter={24} style={{ top: '-34px' }}>
              {assayData?.map((item, index) => {
                return (
                  <>
                    <Space size={16} key={index}>
                      <Card
                        // bordered={false}
                        key={index}
                        style={{
                          width: 400,
                          marginBottom: '18px'
                        }}
                      >
                        <p>
                          <b>Material Name:</b> {item?.material_name}
                        </p>
                        <p>
                          <Space size={8}>
                            <b>Material Code:</b>
                            {item?.material_code}
                            <b>Required Quantity:</b>
                            {item?.required_qty}
                          </Space>
                        </p>
                        <Space size={8}>
                          <p>
                            <b>BIN Location:</b> {item?.bin_location || 'NA'}
                          </p>
                          <p>
                            <b>Available Quantity:</b> {item?.avl_qty}
                          </p>
                        </Space>
                        {item?.dispenseLots?.map((lot, index) => {
                          return (
                            <>
                              <Space key={index}>
                                <p>
                                  <b>LOT:</b>
                                  {lot?.lot_no}
                                </p>
                                <p>
                                  <b>Dispensed Qty:</b> {lot?.dispensed_qty}
                                </p>
                              </Space>
                              <p>
                                <b>Inhouse Batch Number:</b>{' '}
                                {item?.inhouse_batch_number}
                              </p>
                            </>
                          );
                        })}
                      </Card>
                      {'  '}
                    </Space>
                  </>
                );
              })}
              <FormItem className="text-center">
                <Space size={12}>
                  <CustomButton
                    htmlType="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </CustomButton>
                </Space>
              </FormItem>
            </Row>
          ) : (
            ''
          )}
        </CustomModal>
      ) : (
        ''
      )}
    </Layout>
  );
};
export default ListofQaDispenseMaterials;
