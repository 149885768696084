import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  // Collapse,
  Form,
  Input,
  DatePicker,
  InputNumber,
  Select,
  // Radio,
  Space,
  Image,
  message,
  Card
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from './index.module.less';
import CustomButton from '@/common/CustomButton';
import truck from '../images/truck-delivery-done.gif';
import { useDispatch, useSelector } from 'react-redux';
import {
  // createMaterialInward,
  getDocsbyId,
  createBatch,
  getAllFocusMRNList
  // getAllMaterialslist
  //   getAllSuppliers,
  //   getAllMaterialslist
} from '../../redux/slice';
import { getAllMaterialslist } from '@/features/material/redux/slice';
import { history } from 'app/history';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import moment from 'moment';
import { EditOutlined } from '@ant-design/icons';
import VerificationModal from '@/features/material/components/VerificationModal';
import { setAutoFreeze } from 'immer';
import { getLoggedInUser, getRoles } from 'utilities/helpers';
import ViewMaterialPage from '@/features/material/components/commonviewMaterialPage';

const dateFormat = 'YYYY-MM-DD';
const CreateBatchDetailsPage = ({ loading }) => {
  const params = useParams();
  const { material } = useSelector((state) => state.batch);
  // const { MaterialsList } = useSelector((state) => state.material);
  console.log(material);
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const { user } = getLoggedInUser();
  const { Superadmindata } = getRoles('Warehouse');

  const [truckShow, setTruckShow] = useState(false);
  const [containers, setContainers] = useState([]);
  const [batchUpdate, setBatchUpdate] = useState(false);
  // const [isEdit, setIsEdit] = useState(false);
  const [search, setSearch] = useState('');
  const [isModal, setIsModal] = useState(false);
  // const [materials, setMaterials] = useState([]);
  const [batchEdit, setBatchEdit] = useState('');
  const [batchData, setBatchData] = useState([]);
  const [type, setType] = useState('');
  const [editableBatch, setEditableBatch] = useState({});
  const [focusmrns, setFocusmrns] = useState([]);
  setAutoFreeze(false);
  // const [material, setMaterial] = useState({});
  console.log(material?.no_of_bacthes_invoice, material?.batch_details?.length);
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo.errorFields.length);
    if (errorInfo.errorFields.length > 0) {
      message.error(
        'Please select a response to all the questions in the checklist'
      );
    }
  };
  const batches = material?.batch_details;
  const numberOfTrueValues = (batches) => {
    var counter = 0;
    for (var index = 0; index < batches?.length; index++) {
      if (batches[index].weighing_done === true) {
        counter++;
      }
    }
    return counter;
  };
  console.log(batches?.length);
  console.log(numberOfTrueValues(batches));
  const disabledDate = (current) => {
    return current && current > dayjs().endOf('day');
  };

  const onSearch = (value) => {
    setSearch(value);
  };

  const onFinish = (values) => {
    setBatchUpdate(false);
    values['document_id'] = params.id;
    values['bacth_details_list'] = containers;
    console.log(values);
    setTruckShow(true);

    dispatch(createBatch(values)).then(function (response) {
      console.log(material);
      if (response?.payload?.success) {
        setTimeout(() => {
          setBatchUpdate(true);
          form.resetFields();
          setTruckShow(false);
          message.success(response?.payload?.message);
          window.open(response?.payload?.data, '_blank');
          // history.push('/list');
        }, 2000);
      } else {
        setTruckShow(false);
      }
    });
  };

  //   const handleStandardCancel = () => {
  //     history.push('/dashboard');
  //   };

  useEffect(() => {
    handleGetDocsbyId();
    handleGetAllMaterialsList();
  }, [batchUpdate]);
  useEffect(() => {
    handleGetFocusMRNList();
  }, [search]);

  const handleGetAllMaterialsList = () => {
    let payload = {};
    dispatch(getAllMaterialslist(payload));
  };

  const handleGetFocusMRNList = () => {
    const payload = {
      search: search
    };
    dispatch(getAllFocusMRNList(payload)).then((result) => {
      if (result?.payload?.success) {
        setFocusmrns(result?.payload?.data);
      } else {
        message.error('Failed to fetch Focus MRN List');
      }
    });
  };

  const handleGetDocsbyId = () => {
    const payload = {
      id: params.id
    };
    dispatch(getDocsbyId(payload)).then(function (response) {
      if (response?.payload?.success) {
        setBatchData(response?.payload?.data?.batch_details);
        localStorage.setItem(
          'material_code',
          response?.payload?.data?.material?.code
        );
        localStorage.setItem(
          'material_name',
          response?.payload?.data?.material?.name
        );
        localStorage.setItem(
          'invoice_num',
          response?.payload?.data?.invoice_number
        );
        localStorage.setItem('uom', response?.payload?.data?.material?.uom);
        setContainers(
          Array.apply(0, Array(1))?.map(() => ({
            mfg_batch_lot_no: '',
            mfg_date: '',
            mfg_retest_date: '',
            mfg_expiry_date: '',
            containers_packs_received: '',
            in_house_expiry_date: '',
            document_id: params.id,
            invoice_quantity: '',
            received_quantity: '',
            trf_serial_no: '',
            test_to_be_performed: ''
          }))
        );
      }
    });
  };
  const batch = material?.batch_details;
  const batches_done = material?.batch_details
    ? batch.filter((batch) => batch.weighing_done !== true)
    : [];
  console.log(batches_done?.length);
  const handleChangegross = (index, e) => {
    console.log(e);
    const { name, value } = e.target;
    const list = [...containers];
    // if (name === 'mrn_project_id') {
    //   let unit_rate = focusmrns.filter((r) => r.id === value);
    //   console.log(unit_rate);
    //   list[index]['batch_unit_rate'] = unit_rate[0].rate;
    // }
    list[index][name] = value;
    list[index]['received_quantity'] !== '' &&
    list[index]['batch_unit_rate'] !== ''
      ? (list[index]['batch_total_price'] =
          parseFloat(list[index]['received_quantity']) *
          parseFloat(list[index]['batch_unit_rate']))
      : '';
    setContainers(list);
  };

  console.log(containers);
  const handleChangeBatch = (index, e, name) => {
    console.log(index, e, name, batchData);

    const arr = batchData;
    const arrCopy = [...arr];
    arrCopy[0][name] = e;
    setBatchData(arrCopy);
  };

  const handleChangedate = (name, date, dateString, index) => {
    console.log(name);
    console.log(date);
    const list = [...containers];
    list[index][name] = dateString;
    setContainers(list);
  };

  // const onSearch = (value) => {
  //   setSearch(value);
  // };

  // const handleCapacity = (e, name) => {
  //   console.log(e);
  //   // const { name, value } = e.target;

  //   setMaterials({ ...materials, [name]: e });
  // };

  useEffect(() => {
    form.setFieldsValue({
      invoice_num: localStorage.getItem('invoice_num'),
      material_code:
        material?.material?.code || localStorage.getItem('material_code'),
      material_name:
        material?.material?.name || localStorage.getItem('material_name'),
      uom: material?.material?.uom || localStorage.getItem('uom')
    });
  }, [truckShow]);

  // const handleEdit = () => {
  //   setIsEdit(true);
  //   form.setFieldsValue({
  //     material_id: material?.material?.id,
  //     po_number: material?.po_number
  //   });
  // };

  const handleStandardCancel = () => {
    history.push('/dashboard');
  };

  const handleBatchEdit = (index) => {
    setBatchEdit(index);
  };

  const handleModal = (type, index) => {
    setIsModal(true);
    setType(type);
    if (type === 'batch_details') {
      setEditableBatch(batchData[index]);
    }
  };
  console.log(batchEdit);
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <ViewMaterialPage />
      {/* <Card
        title="BATCH DETAILS"
        className={styles.CustomPanel}
        extra={
          batchData?.length ? '' : <EditOutlined onClick={() => handleEdit()} />
        }
      >
        {isEdit ? (
          <>
            <Row gutter={24}>
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label="Material Code"
                  name="material_id"
                  rules={[
                    {
                      required: true,
                      message: 'Select Material Code'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Select
                    showSearch
                    placeholder="Select a material code"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // name="material_id"
                    onSearch={onSearch}
                    onChange={(e) => handleCapacity(e, 'material_id')}
                  >
                    {MaterialsList?.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.code}
                        </option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
              <Col xs={12} xl={12} span={12}>
                <FormItem
                  label="PO Number"
                  name="po_number"
                  rules={[
                    {
                      required: true,
                      message: 'Enter PO number'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter PO number"
                    name="po_number"
                    onChange={(e) =>
                      handleCapacity(e.target.value, 'po_number')
                    }
                  />
                </FormItem>
              </Col>
            </Row>
            <FormItem className="text-center">
              <Space>
                <CustomButton
                  htmlType="button"
                  onClick={handleStandardCancel}
                  className={styles.border_button}
                >
                  Cancel
                </CustomButton>
                {truckShow ? (
                  <Image
                    src={truck}
                    preview={false}
                    style={{ width: '100px !important', height: '100px' }}
                  />
                ) : (
                  <CustomButton
                    type="primary"
                    // htmlType="submit"
                    loading={loading}
                    className={styles.border_button}
                    onClick={() => handleModal('invoicedetails')}
                    ghost
                  >
                    Initiate Verification
                  </CustomButton>
                )}
              </Space>
            </FormItem>
          </>
        ) : (
          <Row gutter={24}>
            <Col xs={24} xl={8} span={8}>
              <FormItem
                label="Material Code"
                name="material_code"
                rules={[
                  {
                    required: false,
                    message: 'Enter Material code'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter Material code"
                  disabled
                  defaultValue={material?.material?.code}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <FormItem
                label="Material Name"
                name="material_name"
                rules={[
                  {
                    required: false,
                    message: 'Enter Material name'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Material name" disabled />
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <FormItem
                label="Invoice Number"
                name="invoice_num"
                rules={[
                  {
                    required: false,
                    message: 'Enter Invoice number'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Invoice number" disabled />
              </FormItem>
            </Col>
          </Row>
        )}
      </Card>
      <br /> */}

      {batchData?.map((item, index) => {
        return (
          <>
            <Card
              title={`BATCH ${index + 1}`}
              className={styles.CustomPanel}
              key={index}
              extra={
                <Space>
                  {(user?.level == 3 && user?.role == 'user') ||
                  Superadmindata?.[0]?.level == 3 ? (
                    <EditOutlined onClick={() => handleBatchEdit(index)} />
                  ) : (
                    ''
                  )}{' '}
                  {
                    <CustomButton
                      size="small"
                      type="primary"
                      ghost
                      onClick={() =>
                        history.push(
                          `/damage-report?id=${params.id}&batch_id=${item.id}&type=view`
                        )
                      }
                    >
                      View Damage
                    </CustomButton>
                  }
                  {item?.weighing_done ? (
                    <CustomButton
                      size="small"
                      type="primary"
                      ghost
                      onClick={() =>
                        history.push(
                          `/weighing-report?id=${params.id}&batch_id=${item.id}`
                        )
                      }
                    >
                      View Weighing
                    </CustomButton>
                  ) : (
                    ''
                  )}
                </Space>
              }
            >
              <Row gutter={24}>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Mfg.Batch/Lot no"
                    // name={`mfg_batch_lot_no${index}`}
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Enter Batch Number"
                      onChange={(e) =>
                        handleChangeBatch(
                          index,
                          e.target.value,
                          'mfg_batch_lot_no'
                        )
                      }
                      disabled={batchEdit !== index ? true : false}
                      value={item?.mfg_batch_lot_no}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Manufacturing date"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <DatePicker
                      placeholder="Select Date"
                      style={{ width: '100%' }}
                      name="mfg_Date"
                      disabled={batchEdit !== index ? true : false}
                      value={moment(item?.mfg_date, dateFormat)}
                      onChange={(e) => handleChangeBatch(index, e, 'mfg_date')}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Manufacturing re-test date"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <DatePicker
                      placeholder="Select Date"
                      style={{ width: '100%' }}
                      name="mfg_retest_date"
                      disabled={batchEdit !== index ? true : false}
                      value={moment(item?.mfg_retest_date, dateFormat)}
                      onChange={(e) =>
                        handleChangeBatch(index, e, 'mfg_retest_date')
                      }
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Manufacturing expiry date"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <DatePicker
                      placeholder="Select Date"
                      style={{ width: '100%' }}
                      name="mfg_expiry_date"
                      disabled={batchEdit !== index ? true : false}
                      value={moment(item?.mfg_expiry_date, dateFormat)}
                      onChange={(e) =>
                        handleChangeBatch(index, e, 'mfg_expiry_date')
                      }
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="In-house expiry date"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <DatePicker
                      placeholder="Select Date"
                      style={{ width: '100%' }}
                      name="in_house_expiry_date"
                      disabled={batchEdit !== index ? true : false}
                      value={moment(item?.in_house_expiry_date, dateFormat)}
                      onChange={(e) =>
                        handleChangeBatch(index, e, 'in_house_expiry_date')
                      }
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Inhouse batch number"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Enter Batch Number"
                      onChange={(e) =>
                        handleChangeBatch(
                          index,
                          e.target.value,
                          'inhouse_batch_number'
                        )
                      }
                      disabled={batchEdit !== index ? true : false}
                      value={item?.inhouse_batch_number}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="No. of containers/packs received"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Enter Batch Number"
                      disabled
                      value={item?.containers_packs_received}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Invoice Quantity"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Invoice Quantity"
                      onChange={(e) =>
                        handleChangeBatch(
                          index,
                          e.target.value,
                          'invoice_quantity'
                        )
                      }
                      disabled={batchEdit !== index ? true : false}
                      value={item?.invoice_quantity}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Received Quantity"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Invoice Quantity"
                      onChange={(e) =>
                        handleChangeBatch(
                          index,
                          e.target.value,
                          'received_quantity'
                        )
                      }
                      disabled={batchEdit !== index ? true : false}
                      value={item?.received_quantity}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Batch Unit Rate"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch Unit Rate'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Enter Batch Unit Rate"
                      onChange={(e) =>
                        handleChangeBatch(index, e.target.value, 'unit_rate')
                      }
                      disabled={batchEdit !== index ? true : false}
                      value={parseFloat(item?.batch_unit_rate).toFixed(2)}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Batch Total Price"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch Total Price'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Batch Total Price"
                      // onChange={(e) =>
                      //   handleChangeBatch(
                      //     index,
                      //     e.target.value,
                      //     'unit_rate'
                      //   )
                      // }
                      disabled={batchEdit !== index ? true : false}
                      value={
                        parseFloat(item?.batch_total_price).toFixed(2) || 0
                      }
                    />
                  </FormItem>
                </Col>
                {/* <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="TRF Serial Number"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Serial Number"
                      onChange={(e) =>
                        handleChangeBatch(
                          index,
                          e.target.value,
                          'trf_serial_no'
                        )
                      }
                      disabled={batchEdit !== index ? true : false}
                      value={item?.trf_serial_no}
                    />
                  </FormItem>
                </Col> */}
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Test to be Performed"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Select
                      placeholder="Select test perfomed"
                      // disabled={!isEdit}
                      value={item?.test_to_be_performed}
                      onChange={(e) =>
                        handleChangeBatch(index, e, 'test_to_be_performed')
                      }
                      disabled={batchEdit !== index ? true : false}
                    >
                      <option value="Identification Test">
                        Identification Test
                      </option>
                      <option value="Complete Analysis">
                        Complete Analysis
                      </option>
                      {/* <option value="R&D Identification Test">
                        R&D Identification Test
                      </option> */}
                    </Select>
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="Focus MRN #"
                    rules={[
                      {
                        required: false,
                        message: 'Select a Focus PO #'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Select
                      showSearch
                      placeholder="Select a Focus MRN #"
                      optionFilterProp="children"
                      value={item?.mrn_project_id}
                      // onChange={onChange}
                      // onChange={(e) =>
                      //   handleChangegross(i, {
                      //     target: {
                      //       name: 'mrn_project_id',
                      //       value: e
                      //     }
                      //   })
                      // }
                      onSearch={onSearch}
                      disabled={batchEdit !== index ? true : false}
                    >
                      {focusmrns?.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.focus_mrn_no}
                          </option>
                        );
                      })}
                    </Select>
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Focus Batch Unit Rate"
                    rules={[
                      {
                        required: false,
                        message: 'Enter Batch Unit Rate'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Focus Batch Unit Rate"
                      // onChange={(e) =>
                      //   handleChangeBatch(index, e.target.value, 'unit_rate')
                      // }
                      disabled={batchEdit !== index ? true : false}
                      // value={parseFloat(item?.batch_unit_rate).toFixed(2)}
                    />
                  </FormItem>
                </Col>
              </Row>
              {batchEdit === index ? (
                <FormItem className="text-center">
                  <Space>
                    <CustomButton
                      htmlType="button"
                      onClick={handleStandardCancel}
                      className={styles.border_button}
                    >
                      Cancel
                    </CustomButton>
                    {truckShow ? (
                      <Image
                        src={truck}
                        preview={false}
                        style={{ width: '100px !important', height: '100px' }}
                      />
                    ) : (
                      <CustomButton
                        type="primary"
                        // htmlType="submit"
                        loading={loading}
                        className={styles.border_button}
                        onClick={() => handleModal('batch_details', index)}
                        ghost
                      >
                        Initiate Verification
                      </CustomButton>
                    )}
                  </Space>
                </FormItem>
              ) : (
                <FormItem className="text-center">
                  <Space direction="vertical" size={5}>
                    <CustomButton
                      loading={loading}
                      // className={styles.inwardButton}
                      onClick={() =>
                        history.push(
                          `/damage-report?id=${params.id}&batch_id=${item.id}`
                        )
                      }
                    >
                      Proceed to Damage Report
                    </CustomButton>
                    {item?.weighing_done ? (
                      ''
                    ) : (
                      <CustomButton
                        onClick={() =>
                          history.push(
                            `/weighing-report?id=${params.id}&batch_id=${item.id}`
                          )
                        }
                        loading={loading}
                        // className={styles.inwardButton}
                      >
                        Proceed to Weighing
                      </CustomButton>
                    )}
                  </Space>
                </FormItem>
              )}
            </Card>
            <br />
          </>
        );
      })}
      {material?.no_of_bacthes_invoice > material?.batch_details?.length
        ? Array.apply(0, Array(1)).map(function (x, i) {
            return (
              <>
                <Card
                  title={`BATCH ${material?.batch_details?.length + 1}`}
                  className={styles.CustomPanel}
                  key={i}
                >
                  <Row gutter={24}>
                    <Col xs={24} xl={12} span={12}>
                      <FormItem
                        label="Mfg.Batch/Lot no"
                        name={`mfg_batch_lot_no${i}`}
                        rules={[
                          {
                            required: true,
                            message: 'Enter Batch number'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter Batch Number"
                          name="mfg_batch_lot_no"
                          onChange={(e) => handleChangegross(i, e)}
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={12} span={12}>
                      <FormItem
                        label="Manufacturing date"
                        name={`mfg_Date${i}`}
                        rules={[
                          {
                            required: false,
                            message: 'Select date'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <DatePicker
                          placeholder="Select Date"
                          style={{ width: '100%' }}
                          disabledDate={disabledDate}
                          name="mfg_Date"
                          onChange={(date, dateString) =>
                            handleChangedate('mfg_date', date, dateString, i)
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={12} span={12}>
                      <FormItem
                        label="Mfg Re-test Date"
                        name={`mfg_retest_Date${i}`}
                        rules={[
                          {
                            required: false,
                            message: 'Select Mfg re-test date'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <DatePicker
                          placeholder="Select Date"
                          style={{ width: '100%' }}
                          name="mfg_retest_Date"
                          onChange={(date, dateString) =>
                            handleChangedate(
                              'mfg_retest_date',
                              date,
                              dateString,
                              i
                            )
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={12} span={12}>
                      <FormItem
                        label="Mfg Expiry Date"
                        name={`mfg_expiry_Date${i}`}
                        rules={[
                          {
                            required: false,
                            message: 'Select MFR expiry date'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <DatePicker
                          placeholder="Select Date"
                          style={{ width: '100%' }}
                          name="mfg_expiry_Date"
                          onChange={(date, dateString) =>
                            handleChangedate(
                              'mfg_expiry_date',
                              date,
                              dateString,
                              i
                            )
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={12} span={12}>
                      <FormItem
                        label="In house Expiry date"
                        name={`in_house_expiry_date${i}`}
                        rules={[
                          {
                            required: false,
                            message: 'Select In-House expiry date'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <DatePicker
                          placeholder="Select Date"
                          style={{ width: '100%' }}
                          name="mfg_expiry_Date"
                          onChange={(date, dateString) =>
                            handleChangedate(
                              'in_house_expiry_date',
                              date,
                              dateString,
                              i
                            )
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={12} span={12}>
                      <FormItem
                        label="No. of Containers/packs received"
                        name={`containers_packs_received${i}`}
                        rules={[
                          {
                            required: true,
                            message: 'Enter No. of containers'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <InputNumber
                          name="containers_packs_received"
                          min={1}
                          max={100000}
                          style={{
                            width: '100%'
                          }}
                          placeholder="Enter No. of Containers"
                          onChange={(e) =>
                            handleChangegross(i, {
                              target: {
                                name: 'containers_packs_received',
                                value: e
                              }
                            })
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col xs={16} xl={12} span={12} md={16} sm={16}>
                      <FormItem
                        label="Invoice Quantity"
                        name={`invoice_quantity${i}`}
                        rules={[
                          {
                            required: true,
                            message: 'Enter Invoice Quantity'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <InputNumber
                          min={0}
                          placeholder="Enter Invoice Quantity"
                          style={{
                            width: '100%'
                          }}
                          onChange={(e) =>
                            handleChangegross(i, {
                              target: {
                                name: 'invoice_quantity',
                                value: e
                              }
                            })
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col xs={8} xl={12} span={12} md={8} sm={8}>
                      <FormItem
                        label="UOM"
                        rules={[
                          {
                            required: false,
                            message: 'Enter UOM'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter UOM"
                          disabled
                          value={localStorage.getItem('uom')}
                        />
                      </FormItem>
                    </Col>
                    <Col xs={16} xl={12} span={12} md={16} sm={16}>
                      <FormItem
                        label="Received Quantity"
                        name={`received_quantity${i}`}
                        rules={[
                          {
                            required: true,
                            message: 'Enter Received Quantity'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <InputNumber
                          min={0}
                          placeholder="Enter Received Quantity"
                          style={{
                            width: '100%'
                          }}
                          onChange={(e) =>
                            handleChangegross(i, {
                              target: {
                                name: 'received_quantity',
                                value: e
                              }
                            })
                          }
                        />
                      </FormItem>
                    </Col>

                    <Col xs={8} xl={12} span={12} md={8} sm={8}>
                      <FormItem
                        label="UOM"
                        rules={[
                          {
                            required: false,
                            message: 'Enter UOM'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter UOM"
                          disabled
                          value={localStorage.getItem('uom')}
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={12} span={12}>
                      <FormItem
                        label="Batch Unit Rate"
                        name={`batch_unit_rate${i}`}
                        rules={[
                          {
                            required: true,
                            message: 'Enter Batch Unit Rate'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <InputNumber
                          placeholder="Enter Batch Unit Rate"
                          onChange={(e) =>
                            handleChangegross(i, {
                              target: {
                                name: 'batch_unit_rate',
                                value: e
                              }
                            })
                          }
                          style={{ width: '100%' }}
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={12} span={12} md={24} sm={24}>
                      <FormItem
                        label="Batch Total Price"
                        // name={`batch_total_price${i}`}
                        rules={[
                          {
                            required: false,
                            message: 'Enter Batch Unit Rate'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Please Total price"
                          value={
                            containers?.[i]?.batch_total_price
                              ? parseFloat(
                                  containers?.[i]?.batch_total_price
                                ).toFixed(2)
                              : 0
                          }
                          disabled
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={12} span={12} md={24} sm={24}>
                      <FormItem
                        label="Test to be Performed"
                        name={`test_to_be_performed${i}`}
                        rules={[
                          {
                            required: true,
                            message: 'Select test performed'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Select
                          placeholder="Select test perfomed"
                          // disabled={!isEdit}
                          onChange={(e) =>
                            handleChangegross(i, {
                              target: {
                                name: 'test_to_be_performed',
                                value: e
                              }
                            })
                          }
                        >
                          <option value="Identification Test">
                            Identification Test
                          </option>
                          <option value="Complete Analysis">
                            Complete Analysis
                          </option>
                        </Select>
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={12} span={12} md={24} sm={24}>
                      <FormItem
                        label="Focus MRN #"
                        name={`mrn_project_id${i}`}
                        rules={[
                          {
                            required: false,
                            message: 'Select a Focus PO #'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Select
                          showSearch
                          placeholder="Select a Focus MRN #"
                          optionFilterProp="children"
                          // onChange={onChange}
                          onChange={(e) =>
                            handleChangegross(i, {
                              target: {
                                name: 'mrn_project_id',
                                value: e
                              }
                            })
                          }
                          onSearch={onSearch}
                        >
                          {focusmrns?.map((item, index) => {
                            return (
                              <option value={item.id} key={index}>
                                {item.focus_mrn_no}
                              </option>
                            );
                          })}
                        </Select>
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={12} span={12} md={24} sm={24}>
                      <FormItem
                        label="Focus Batch Unit Rate"
                        // name={`batch_unit_rate${i}`}
                        rules={[
                          {
                            required: true,
                            message: 'Enter Batch Unit Rate'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Focus Batch Unit Rate"
                          // onChange={(e) =>
                          //   handleChangegross(i, {
                          //     target: {
                          //       name: 'batch_unit_rate',
                          //       value: e
                          //     }
                          //   })
                          // }
                          disabled
                          style={{
                            width: '100%'
                          }}
                          // value={containers[i]?.batch_unit_rate}
                        />
                      </FormItem>
                    </Col>
                  </Row>
                  <FormItem className="text-center">
                    <Space>
                      {truckShow ? (
                        <Image
                          src={truck}
                          preview={false}
                          style={{ width: '100px !important', height: '100px' }}
                        />
                      ) : (
                        <>
                          <Space direction="vertical" size={5}>
                            <CustomButton
                              type="primary"
                              htmlType="submit"
                              loading={loading}
                              className={styles.inwardButton}
                            >
                              Submit & Print Labels
                            </CustomButton>
                            {/* <CustomButton
                              htmlType="submit"
                              loading={loading}
                              className={styles.inwardButton}
                            >
                              Proceed to Damage Report
                            </CustomButton>
                            <CustomButton
                              htmlType="submit"
                              loading={loading}
                              className={styles.inwardButton}
                            >
                              Proceed to Weighinfg
                            </CustomButton> */}
                          </Space>
                        </>
                      )}
                    </Space>
                  </FormItem>
                </Card>
                <br />
              </>
            );
          })
        : ''}
      {
        // batches_done?.length == 0 &&
        material?.batch_details?.length == material?.no_of_bacthes_invoice &&
        material?.material?.weighingrules == 3 ? (
          <FormItem className="text-center">
            {/* <Row gutter={24}>
            <Col xs={12} xl={6} span={12}> */}
            {truckShow ? (
              <Image
                src={truck}
                preview={false}
                style={{ width: '100px !important', height: '100px' }}
              />
            ) : (
              <CustomButton
                onClick={() => history.push(`/generate-mrn/${params.id}`)}
                loading={loading}
                // className={styles.inwardButton}
                type="primary"
                // style={{ width: '20%' }}
              >
                Accept Batches
              </CustomButton>
            )}

            {/* </Col>
          </Row> */}
          </FormItem>
        ) : material?.material?.weighingrules !== 3 &&
          material?.no_of_bacthes_invoice == numberOfTrueValues(batches) ? (
          <FormItem className="text-center">
            {truckShow ? (
              <Image
                src={truck}
                preview={false}
                style={{ width: '100px !important', height: '100px' }}
              />
            ) : (
              <CustomButton
                onClick={() => history.push(`/generate-mrn/${params.id}`)}
                loading={loading}
                type="primary"
              >
                Accept Batches
              </CustomButton>
            )}
          </FormItem>
        ) : (
          ''
        )
      }
      {/* </Space> */}
      {isModal ? (
        <VerificationModal
          isModal={isModal}
          // materials={materials}
          setIsModal={setIsModal}
          material={material}
          // setIsEdit={setIsEdit}
          page={type}
          editableBatch={editableBatch}
          setBatchEdit={setBatchEdit}
        />
      ) : (
        ''
      )}
    </Form>
  );
};
export default CreateBatchDetailsPage;
