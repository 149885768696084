import React, { Fragment, useEffect } from 'react';
import { useState } from 'react';
import {
  Row,
  Col,
  Select,
  Card,
  Form,
  InputNumber,
  Space,
  message,
  Popconfirm,
  Image,
  Input
} from 'antd';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import { useDispatch, useSelector } from 'react-redux';
import { getDocsbyId, createWeighmentReport } from '../../redux/slice';
import CustomButton from '@/common/CustomButton';
import { QrReader } from 'react-qr-reader';
import CustomTable from '@/common/Content/CustomTable';
import {
  QrcodeOutlined,
  DeleteOutlined,
  EditOutlined
  // PlusOutlined
} from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { history } from 'app/history';
import { weighingRules, weighingRulesCount } from 'utilities/helpers';
import truck from '@/features/generateMrn/components/images/truck-delivery-done.gif';
import { setAutoFreeze } from 'immer';
import VerificationModal from '@/features/material/components/VerificationModal';
// import CustomModal from '@/common/CustomModal';

const WeighingReportPage = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const searchParam = useLocation().search;
  const searchParams = new URLSearchParams(searchParam);
  const { pagination } = useSelector((state) => state.damage);

  const [material, setMaterial] = useState([]);
  const [openCam, setOpenCam] = useState(false);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [grossweight, setGrossweight] = useState('');
  const [observedweight, setObservedweight] = useState('');
  const [page, setPage] = useState(1);
  const [descrepencyStatus, setDescrepencyStatus] = useState('No');
  const [showModal, setShowModal] = useState(false);
  const [weighingCont, setWeighingCont] = useState([]);
  const [truckShow, setTruckShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [weighingData, setWeighingData] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [isModal, setIsModal] = useState(false);

  setAutoFreeze(false);
  console.log(search, page);
  useEffect(() => {
    handleGetDocsbyId();
  }, []);

  const handleGetDocsbyId = () => {
    const payload = {
      id: searchParams.get('id')
    };
    dispatch(getDocsbyId(payload)).then(function (response) {
      if (response?.payload?.success) {
        setMaterial(response?.payload?.data);
        localStorage.setItem(
          'material_code',
          response?.payload?.data?.material?.code
        );
        localStorage.setItem(
          'material_name',
          response?.payload?.data?.material?.name
        );
        localStorage.setItem(
          'invoice_num',
          response?.payload?.data?.invoice_number
        );
        localStorage.setItem('uom', response?.payload?.data?.material?.uom);
      }
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      invoice_no:
        material?.invoice_number || localStorage.getItem('invoice_num'),
      material_code:
        material?.material?.code || localStorage.getItem('material_code'),
      material_name:
        material?.material?.name || localStorage.getItem('material_name'),
      uom: material?.material?.uom || localStorage.getItem('uom')
    });
    setWeighingData(material?.Weighments);
  }, [material]);

  // console.log(weighingData);
  const onFinish = (values) => {
    console.log(values);
    const payload = {
      weighments_data: weighingCont,
      remarks: values.Comments
    };
    setTruckShow(true);
    dispatch(createWeighmentReport(payload)).then(function (response) {
      if (response?.payload?.success) {
        setTruckShow(false);
        message.success(response?.payload?.message);
        form.resetFields();
        setGrossweight('');
        setObservedweight('');
        setData([]);
        setDescrepencyStatus('No');
        history.push(`/batch-details/${searchParams.get('id')}`);
        // console.log()
        // history.push(`/generate-mrn/${searchParams.get('id')}`);
      } else {
        setTruckShow(false);
      }
    });
  };

  const handleClickWight = () => {
    setWeighingCont([
      ...weighingCont,
      {
        damaged_containers: data[0],
        gross_weight: grossweight,
        observed_weight: observedweight,
        discrepancy: descrepencyStatus,
        uom: material?.material?.uom || localStorage.getItem('uom')
      }
    ]);
    form.resetFields();
    setGrossweight('');
    setObservedweight('');
    setData([]);
    setDescrepencyStatus('No');
  };

  console.log(weighingCont);

  const handleQrScan = async (scanData) => {
    if (scanData && scanData !== '') {
      let result = batches?.length
        ? batches.filter((person) =>
            material?.Weighments.every(
              (person2) => !person2?.container_number.includes(person.number)
            )
          )
        : [];
      let invalid = result?.length
        ? result.filter(
            (result) =>
              result == scanData.text.slice(scanData.text.lastIndexOf('/') + 1)
          )
        : [];
      invalid.length
        ? setData([
            ...data,
            scanData.text.slice(scanData.text.lastIndexOf('/') + 1)
          ])
        : message.error(
            'This container does not belong to this batch/already added'
          );
      setOpenCam(false);
    }
  };
  const handleError = (err) => {
    console.error(err);
  };

  const onChange = (values) => {
    setData([...data, values]);
    form.resetFields();
  };

  const onSearch = (value) => {
    setSearch(value);
  };

  const conatainers = material?.batch_details;
  const batchContainers = conatainers?.length
    ? conatainers?.filter(
        (conatainers) => conatainers?.id == searchParams.get('batch_id')
      )
    : [];
  // const Weighments = material?.Weighments;
  // const batch_Weighments = Weighments?.length
  //   ? Weighments?.filter(
  //       (Weighments) =>
  //         Weighments?.batch_details_id == searchParams.get('batch_id')
  //     )
  //   : [];
  const removeConatiner = (id) => {
    console.log(id);
    const list = [...data];
    list.splice(id, 1);
    setData(list);
  };
  const confirm = () => {
    setDescrepencyStatus('Yes');
    setShowModal(false);
  };
  const cancel = () => {
    setDescrepencyStatus('No');
    setShowModal(false);
  };
  let batches = batchContainers[0]?.containers.filter(
    (person) => person.is_damaged == false
  );
  let result = batches?.length
    ? batches.filter((person) =>
        material?.Weighments.every(
          (person2) => !person2?.container_number.includes(person.number)
        )
      )
    : [];
  const damaged_cont =
    batchContainers[0]?.containers.filter(
      (person) => person.is_damaged == true
    ) || 0;

  const handleClick = () => {
    const payload = {
      id: searchParams.get('id'),
      batch_id: searchParams.get('batch_id'),
      containers: weighingData
    };
    setMaterials(payload);
    setIsModal(true);
    console.log(payload);
  };
  console.log(damaged_cont?.length);
  const columns = [
    {
      title: 'Container',
      dataIndex: 'damaged_containers',
      key: 'damaged_containers',
      width: '150px',
      ellipsis: true,
      sorter: (a, b) =>
        a?.damaged_containers?.localeCompare(b?.damaged_containers),
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Gross weight as per supplier label',
      dataIndex: 'gross_weight',
      key: 'gross_weight',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return `${text}${record.uom}`;
      },
      sorter: (a, b) => a?.gross_weight?.localeCompare(b?.gross_weight),
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Observed weight/UOM',
      dataIndex: 'observed_weight',
      key: 'observed_weight',
      align: 'center',
      width: '100px',
      render: (text, record) => {
        return `${text}${record.uom}`;
      },
      sorter: (a, b) => a?.observed_weight?.localeCompare(b?.observed_weight),
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Discrepency',
      dataIndex: 'discrepancy',
      key: 'discrepancy',
      align: 'center',
      width: '100px',
      sorter: (a, b) => a?.discrepancy?.localeCompare(b?.discrepancy),

      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    }
  ];
  const randomizeIndex = (count) => {
    return Math.floor(count * Math.random());
  };

  const randomizeElemnts = (array, count) => {
    console.log(array.length, count);
    if (count > array?.length) {
      throw new Error(
        'Array size cannot be smaller than expected random numbers count.'
      );
    }
    const result = [];
    const guardian = new Set();
    while (result.length < count) {
      const index = randomizeIndex(array?.length);
      if (guardian.has(index)) {
        continue;
      }
      const element = array[index];
      guardian.add(index);
      result.push(element);
    }
    return result;
  };
  const element = randomizeElemnts(
    result,
    result?.length
      ? weighingRulesCount(
          material?.material?.weighingrules,
          batchContainers[0]?.containers?.length - damaged_cont
        )
      : 0
  );
  console.log(element);
  let filterresult = result?.length
    ? result.filter((person) =>
        weighingCont.every(
          (person2) => !person2?.damaged_containers.includes(person.number)
        )
      )
    : [];

  const handleEditWeight = (e, index, type) => {
    const list = [...weighingData];
    console.log(list, index, e.target.value, type);
    list[index][type] = e.target.value;
    setWeighingData(list);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      // onFinishFailed={onFinishFailed}
    >
      <Fragment>
        <Card title="WEIGHING REPORT" className={styles.CustomPanel}>
          <Row gutter={24}>
            <Col xs={12} xl={16} span={16}>
              <h6 className={styles.lable}>Material Code</h6>
            </Col>
            <Col xs={12} xl={8} span={8}>
              <h6 className={styles.lableValue}>{material?.material?.code}</h6>
            </Col>
            <Col xs={12} xl={16} span={16}>
              <h6 className={styles.lable}>Material Name</h6>
            </Col>
            <Col xs={12} xl={8} span={8}>
              <h6 className={styles.lableValue}>{material?.material?.name}</h6>
            </Col>
            <Col xs={12} xl={16} span={16}>
              <h6 className={styles.lable}>Invoice Number</h6>
            </Col>
            <Col xs={12} xl={8} span={8}>
              <h6 className={styles.lableValue}>{material?.invoice_number}</h6>
            </Col>
          </Row>
        </Card>
        <br />
        {material?.is_Weighments && weighingData?.length ? (
          <Card
            title="CONTAINERS"
            className={styles.CustomPanel}
            extra={<EditOutlined onClick={() => setIsEdit(true)} />}
          >
            {weighingData?.map((item, index) => {
              return (
                <>
                  <Row
                    gutter={24}
                    className={styles.selectContainers}
                    key={index}
                  >
                    <Col xs={10} xl={8} span={16}>
                      <FormItem
                        label="Container"
                        rules={[
                          {
                            required: false,
                            message: 'Enter damaged containers'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input value={item?.container_number} disabled />
                      </FormItem>
                    </Col>
                    <Col xs={10} xl={8} span={16}>
                      <FormItem
                        label="Gross Weight"
                        rules={[
                          {
                            required: false,
                            message: 'Enter damaged containers'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          value={item?.gross_weight_on_label}
                          disabled={!isEdit}
                          onChange={(e) =>
                            handleEditWeight(e, index, 'gross_weight_on_label')
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col xs={10} xl={8} span={16}>
                      <FormItem
                        label="Observed Weight"
                        rules={[
                          {
                            required: false,
                            message: 'Enter damaged containers'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          value={item?.observed_weight}
                          disabled={!isEdit}
                          onChange={(e) =>
                            handleEditWeight(e, index, 'observed_weight')
                          }
                        />
                      </FormItem>
                    </Col>
                  </Row>
                  <br />
                </>
              );
            })}
          </Card>
        ) : (
          ''
        )}
        {filterresult?.length ? (
          <Card title="CONTAINERS" className={styles.CustomPanel}>
            <Row gutter={24} className={styles.selectContainers}>
              <Col xs={10} xl={16} span={16}>
                <h6 className={styles.lable}>Containers</h6>
              </Col>
              {data?.length
                ? data?.map((item, index) => {
                    return index === 0 ? (
                      <>
                        <Col xs={12} xl={6} span={6} key={index}>
                          <h6 className={styles.lableValue}>{item}</h6>
                        </Col>
                        <Col xs={2} xl={2} span={2}>
                          <DeleteOutlined
                            onClick={() => removeConatiner(index)}
                            className={styles.removal}
                          />
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col xs={12} xl={16} span={16}></Col>
                        <Col xs={10} xl={6} span={6} key={index}>
                          <h6 className={styles.lableValue}>{item}</h6>
                        </Col>
                        <Col xs={2} xl={2} span={2}>
                          <DeleteOutlined
                            onClick={() => removeConatiner(index)}
                            className={styles.removal}
                          />
                        </Col>
                      </>
                    );
                  })
                : ''}
              {data?.length ? (
                <Col xs={12} xl={16} span={16} md={12} sm={12}></Col>
              ) : (
                ''
              )}
              {data?.length ? (
                ''
              ) : (
                <>
                  <Col xs={12} xl={6} span={6} md={10} sm={10}>
                    <FormItem
                      name="damaged_containers"
                      rules={[
                        {
                          required: false,
                          message: 'Enter damaged containers'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Select
                        showSearch
                        placeholder="Scan or select container"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                      >
                        {filterresult?.map((item, index) => {
                          return (
                            <option value={item.number} key={index}>
                              {item.number}
                            </option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col xs={2} xl={2} span={2} md={2} sm={2}>
                    <CustomButton
                      onClick={() => setOpenCam(true)}
                      className={styles.qr}
                    >
                      <QrcodeOutlined onClick={() => setOpenCam(true)} />
                    </CustomButton>
                  </Col>
                </>
              )}
            </Row>
            <Row gutter={24} className={styles.selectContainers}>
              <Col
                xs={10}
                xl={16}
                span={16}
                className={styles.selectContainersLable1}
              >
                <h6 className={styles.lable}>
                  Gross weight as per supplier label
                </h6>
              </Col>
              <Col xs={8} xl={6} span={6}>
                <FormItem
                  name="gross_weight"
                  rules={[
                    {
                      required: false,
                      message: 'Enter damaged containers'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <InputNumber
                    min={0}
                    style={{
                      width: '100%'
                    }}
                    placeholder="Enter Weight"
                    onChange={(e) => setGrossweight(e)}
                  />
                </FormItem>
              </Col>
              <Col xs={6} xl={2} span={2}>
                <h6 className={styles.lableValue}>{material?.material?.uom}</h6>
              </Col>
            </Row>
            <br />
            <Row gutter={24} className={styles.selectContainers}>
              <Col
                xs={10}
                xl={16}
                span={16}
                className={styles.selectContainersLable}
              >
                <h6 className={styles.lable}>Observed weight UOM</h6>
              </Col>
              <Col xs={8} xl={6} span={6}>
                <FormItem
                  name="observed_weight"
                  rules={[
                    {
                      required: false,
                      message: 'Enter damaged containers'
                    }
                  ]}
                  className={styles.customLable1}
                >
                  <InputNumber
                    min={0}
                    style={{
                      width: '100%'
                    }}
                    placeholder="Enter Weight"
                    onChange={(e) => setObservedweight(e)}
                  />
                </FormItem>
              </Col>
              <Col xs={6} xl={2} span={2}>
                <h6 className={styles.lableValue1}>
                  {material?.material?.uom}
                </h6>
              </Col>
            </Row>
            <br />
            <FormItem className="text-center">
              <Space size={5} className={styles.direction2}>
                <Popconfirm
                  placement="topLeft"
                  title="Are you sure you want to raise a discrepancy?"
                  // description={description}
                  onConfirm={confirm}
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                  open={showModal}
                >
                  <CustomButton
                  // className={styles.inwardButton}
                  // type="primary"
                  // onClick={() => setShowModal('true')}
                  >
                    {/* <PlusOutlined /> */}
                    Add Container with Discrepency
                  </CustomButton>
                </Popconfirm>

                <CustomButton
                  // className={styles.inwardButton}
                  // type="primary"
                  // htmlType="submit"
                  onClick={handleClickWight}
                >
                  {/* <PlusOutlined /> */}
                  Add Container
                </CustomButton>
              </Space>
            </FormItem>
          </Card>
        ) : (
          ''
        )}
        {console.log(
          batchContainers[0]?.containers?.length - damaged_cont?.length,
          'weighingrules1234'
        )}
        {weighingCont?.length ? (
          <>
            <br />
            <Card
              title="CONTAINER DETAILS"
              className={`${styles.cardStyles_approver}`}
              extra={
                material?.material?.weighingrules === 3
                  ? ''
                  : `${weighingCont?.length} of ${weighingRulesCount(
                      material?.material?.weighingrules,
                      batchContainers[0]?.containers?.length -
                        damaged_cont?.length
                    )} Container(s)`
              }
            >
              <div className={`${styles.table_container}`}>
                <CustomTable
                  columns={columns}
                  dataSource={weighingCont || []}
                  pagination={{
                    size: 'small',
                    showSizeChanger: false,
                    total: pagination?.total_records || 0,
                    onChange: (page) => {
                      setPage(page);
                    }
                  }}
                  // className={styles.scrolling}
                  scroll={{ x: 'auto' }}
                />
              </div>
            </Card>
          </>
        ) : (
          ''
        )}
        <br />
        <FormItem className="text-center">
          <Space size={5}>
            {truckShow ? (
              <Image
                src={truck}
                preview={false}
                style={{ width: '100px !important', height: '100px' }}
              />
            ) : isEdit ? (
              <CustomButton type="primary" onClick={handleClick}>
                Initial Verification
              </CustomButton>
            ) : (
              <CustomButton
                // className={styles.inwardButton}
                type="primary"
                // onClick={handleClick}
                htmlType="submit"
                // disabled={material?.Weighments?.length ? false : true}
                disabled={
                  weighingRules(
                    material?.material?.weighingrules,
                    batchContainers[0]?.containers?.length -
                      damaged_cont?.length,
                    weighingCont?.length
                  )
                    ? false
                    : true
                }
              >
                Submit
              </CustomButton>
            )}

            <CustomButton
              onClick={() => history.back()}
              // className={styles.inwardButton}
              disabled={data?.length ? true : false}
            >
              Back to Batch Details
            </CustomButton>
          </Space>
        </FormItem>

        {openCam ? (
          <QrReader
            constraints={{ facingMode: 'environment' }}
            delay={1000}
            onError={handleError}
            onResult={(result) => handleQrScan(result)}
            // chooseDeviceId={()=>selected}
            style={{ width: '3px', height: '3px' }}
          />
        ) : (
          ''
        )}
      </Fragment>
      {isModal ? (
        <VerificationModal
          isModal={isModal}
          materials={materials}
          setIsModal={setIsModal}
          material={material}
          setIsEdit={setIsEdit}
          page="weighing"
        />
      ) : (
        ''
      )}
    </Form>
  );
};
export default WeighingReportPage;
