import React from 'react';
import Layout from '@/common/Layout';
import CreateLineClearanceChecklistPage from './components/CreateLineClearanceChecklist';

const Checklist = () => {
  return (
    <>
      <Layout sider={false}>
        <CreateLineClearanceChecklistPage />
      </Layout>
    </>
  );
};

export default Checklist;
