import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { wastage, updatewastage } from './api';

const initialState = {
  allWastage: [],
  pagination: {}
};

const actions = {
  GET_ALL_WASTAGE: 'wastage/GET_ALL_WASTAGE',
  UPDATE_WASTAGE: 'wastage/UPDATE_WASTAGE'
};

export const getAllWastage = createAsyncThunk(
  actions.GET_ALL_WASTAGE,
  async (payload) => {
    const response = await wastage('all', payload);

    return response;
  }
);

export const updateWastage = createAsyncThunk(
  actions.UPDATE_WASTAGE,
  async (payload) => {
    const response = await updatewastage(`create?id=${payload?.id}`, payload);

    return response;
  }
);

export const wastageSlice = createSlice({
  name: 'wastage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllWastage.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllWastage.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, pagination } = action.payload;
        if (success) {
          state.allWastage = data;
          state.pagination = pagination || null;
        } else {
          state.allWastage = [];
        }
      })
      .addCase(getAllWastage.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(updateWastage.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(updateWastage.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(updateWastage.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
  }
});
export default wastageSlice.reducer;
