import React, { useEffect, useState } from 'react';
import { Form, Card, Tag } from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllWastage
  //   createWastage,
  //   getPastContainerDetails
} from '../../redux/slice';
import CustomTable from 'common/Content/CustomTable';
import moment from 'moment';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import { getLoggedInUser } from 'utilities/helpers';
import { EyeOutlined } from '@ant-design/icons';

const WastageReconsilationPage = () => {
  const [form] = Form.useForm();
  const { user } = getLoggedInUser();
  const { allWastage, pagination } = useSelector((state) => state.wastage);

  const [page, setPage] = useState(1);
  console.log(allWastage);
  const dispatch = useDispatch();
  useEffect(() => {
    handleGetAllWastageDetails();
  }, [page]);

  const handleGetAllWastageDetails = () => {
    const payload = { page };
    dispatch(getAllWastage(payload));
  };

  const columns = [
    {
      title: 'Batch Number',
      dataIndex: 'inhouse_batch_number',
      key: 'inhouse_batch_number',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return (
          (record?.status !== 'approved' && user?.level == 3 ? (
            //   <a href={`/wastage?id=${record?.id}`}>
            //     {record?.container?.batch_detail?.inhouse_batch_number}
            //   </a>
            // ) : record?.status !== 'approved'
            // ? (
            <a href={`/wastageApproval?id=${record?.id}`}>
              {record?.container?.batch_detail?.inhouse_batch_number}
            </a>
          ) : (
            record?.container?.batch_detail?.inhouse_batch_number
          )) || 'NA'
        );
      }
    },
    {
      title: 'Date',
      dataIndex: 'mfg_date',
      key: 'mfg_date',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.container?.batch_detail?.mfg_date
          ? moment(record?.container?.batch_detail?.mfg_date).format(
              'DD MMM YYYY'
            )
          : 'NA';
      }
    },
    {
      title: 'Container Number',
      dataIndex: 'inhouse_batch_number',
      key: 'inhouse_batch_number',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.container?.number ? record?.container?.number : 'NA';
      }
    },
    {
      title: 'Quantity',
      dataIndex: 'inhouse_batch_number',
      key: 'inhouse_batch_number',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return (
          `${record?.quantity}${record?.container?.batch_detail?.material?.uom}` ||
          'NA'
        );
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return (
          (
            <Tag
              color={
                record?.status === 'pending'
                  ? '#007bff'
                  : record?.status === 'approved'
                  ? '#28a745'
                  : record?.status === 'reject'
                  ? '#dc3545'
                  : '#007bff'
              }
            >
              {record?.status.toUpperCase()}
            </Tag>
          ) || 'NA'
        );
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return (
          <a href={`/wastage?id=${record?.id}`}>
            <EyeOutlined />
          </a>
        );
      }
    }
  ];

  return (
    <Form form={form} layout="vertical">
      <Card
        title={<span>Wastage/Leakage Details</span>}
        className={`${styles.cardStyles_approver}`}
        extra={
          user?.level == 3 || user?.level == 2 ? (
            <CustomButton
              className={styles.inwardButton}
              onClick={() => history.push('/wastage')}
              type="primary"
            >
              Create
            </CustomButton>
          ) : (
            ''
          )
        }
      >
        <div className={`${styles.table_container}`}>
          <CustomTable
            columns={columns}
            pagination={{
              size: 'small',
              showSizeChanger: false,
              total: pagination?.total_records || 0,
              onChange: (page) => {
                setPage(page);
              }
            }}
            dataSource={allWastage}
          />
        </div>
      </Card>
      <br />
      <FormItem className="text-center">
        <CustomButton
          className={styles.inwardButton}
          onClick={() => history.back()}
          type="primary"
        >
          Back to Home
        </CustomButton>
      </FormItem>
    </Form>
  );
};
export default WastageReconsilationPage;
