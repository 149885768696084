import React from 'react';
import Layout from '@/common/Layout';
import TestRequestListPage from './components/TestRequestListPage';

const Qc = () => {
  return (
    <>
      <Layout sider={false}>
        <TestRequestListPage />
      </Layout>
    </>
  );
};

export default Qc;
