import React, { useState, useEffect } from 'react';
import Layout from '@/common/Layout';
import { Card, Empty, Input, Space, Tag } from 'antd';
import styles from '@/common/Layout/index.module.less';
import CustomTable from '@/common/Content/CustomTable';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import { useDispatch } from 'react-redux';
import { getTransferDataById } from '../../redux/slice';
import moment from 'moment';
import { getLoggedInUser } from 'utilities/helpers';

const { Search } = Input;

const CodetoCode = () => {
  const dispatch = useDispatch();
  const { user } = getLoggedInUser();

  const [transferData, setTransferData] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    handleGetTransferdata();
  }, [page]);

  const handleGetTransferdata = () => {
    const payload = { page };
    dispatch(getTransferDataById(payload)).then((response) => {
      if (response?.payload?.success) {
        setTransferData(response?.payload?.data);
        setPagination(response?.payload?.pagination);
      }
    });
  };

  const columns = [
    {
      title: 'Request #',
      dataIndex: 'request',
      key: 'request',
      ellipsis: true,
      width: '150px',
      sorter: (a, b) => a?.request?.localeCompare(b?.request),
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md'],
      render: (text, record) => {
        return (
          <a href={`create-code-to-code?id=${record?.id}`}>{text || 'NA'}</a>
        );
      }
    },
    {
      title: 'Request Date',
      dataIndex: 'req_date',
      key: 'req_date',
      ellipsis: true,
      width: '150px',
      sorter: (a, b) => a?.req_date?.localeCompare(b?.req_date),
      render: (text) => {
        return moment(text).format('DD MMM YYYY') || 'NA';
      },
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'From Material Code',
      dataIndex: 'from_material',
      key: 'from_material',
      ellipsis: true,
      width: '150px',
      sorter: (a, b) => a?.from_material?.localeCompare(b?.from_material),
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md'],
      render: (text, record) => {
        return record?.batch_details?.material?.code;
      }
    },
    {
      title: 'Material Name',
      dataIndex: 'material_name',
      key: 'material_name',
      ellipsis: true,
      width: '150px',
      sorter: (a, b) => a?.material_name?.localeCompare(b?.material_name),
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md'],
      render: (text, record) => {
        return record?.batch_details?.material?.name;
      }
    },
    {
      title: 'From Inhouse Batch',
      dataIndex: 'from_in_house',
      key: 'from_in_house',
      ellipsis: true,
      width: '150px',
      sorter: (a, b) => a?.from_in_house?.localeCompare(b?.from_in_house),
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md'],
      render: (text, record) => {
        return record?.batch_details?.inhouse_batch_number;
      }
    },
    {
      title: 'To Material Code',
      dataIndex: 'to_material',
      key: 'to_material',
      ellipsis: true,
      width: '150px',
      sorter: (a, b) => a?.to_material?.localeCompare(b?.to_material),
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md'],
      render: (text, record) => {
        return record?.materials?.code;
      }
    },
    {
      title: 'Qty to Transfer',
      dataIndex: 'qty_transfer',
      key: 'qty_transfer',
      ellipsis: true,
      width: '150px',
      sorter: (a, b) => a?.qty_transfer?.localeCompare(b?.qty_transfer),
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: '150px',
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md'],
      render: (text, record) => {
        return (
          <Tag
            color={
              record?.status === 'QA Pending'
                ? '#dc3545'
                : record?.status === 'QA Approved'
                ? '#ff9933'
                : record?.status === 'Approved'
                ? '#28a745'
                : '#007bff'
            }
          >
            {record?.status}
          </Tag>
        );
      }
    }
  ];
  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        <div className={styles.empty_state_text}>
          There are no material requests to display
        </div>
      </span>
    )
  };
  return (
    <Layout sider={false}>
      <Card
        title="Code to Code Transfer Requests"
        className={`${styles.cardStyles_approver}`}
        extra={
          <Space>
            {user?.role !== 'qa_initiator' ? (
              <CustomButton
                type="primary"
                className={styles.CustomButton}
                size={'small'}
                onClick={() => history.push('/create-code-to-code')}
              >
                Create
              </CustomButton>
            ) : (
              ''
            )}
            <Search
              // onChange={(e) => handleChange(e)}
              className={`w-100 ${styles.searchbar}`}
              justify="start"
              align="start"
              placeholder="Search by material code"
            />
          </Space>
        }
      >
        <div className={`${styles.table_container}`}>
          <CustomTable
            locale={locale}
            // loading={getAllMaterilLoading}
            columns={columns}
            dataSource={transferData}
            pagination={{
              size: 'small',
              total: pagination?.total_records || 0,
              showSizeChanger: false,
              onChange: (page) => {
                setPage(page);
              }
            }}
            className={styles.scrolling}
            scroll={{ x: 'auto' }}
          />
        </div>
      </Card>
    </Layout>
  );
};
export default CodetoCode;
