import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  addProduct,
  addGranuler,
  addSifting,
  addExtraGranulation,
  addBlending,
  addSample,
  addYieldReconcilation,
  addBlendDistribution,
  dispense,
  getProductNames,
  getProductDetails,
  getstageDetails,
  createnewBmr,
  getuoms,
  getStages,
  getStageProcess,
  getAllEquipments,
  getAllWeighingMaterials,
  createGross,
  getAllProcess,
  getSelectedMaterials,
  createbom,
  getallboms,
  getallparams,
  getallaccessories,
  createbomInstructions,
  createbmrExe,
  getallexeboms,
  print_bmr,
  create_fbb,
  get_fbb,
  update_mat,
  delete_params,
  update_seq,
  add_end_date_time,
  download_ebmr_users,
  stopProcess,
  getlcEquips,
  getlcCurrentProd,
  createClearenceforProd,
  getlcList,
  createClearenceforQA,
  getMasterProdiucts,
  createMasterProduct,
  getAllProcessGroups,
  createEbmrMaster,
  getExecutionData
} from './api';

const initialState = {
  allUsers: [],
  pagination: {},
  user: {},
  getAllMaterilLoading: false,
  productNames: [],
  productDetails: [],
  Allequipements: [],
  WeighingMaterials: [],
  prodDetails: []
};

const actions = {
  CREATE_PRODUCT: 'product/CREATE_PRODUCT',
  CREATE_GRANULER: 'product/CREATE_GRANULER',
  CREATE_SIFTING: 'product/CREATE_SIFTING',
  CREATE_EXTRA_GRANULATION: 'product/EXTRA_GRANULATION',
  CREATE_BLENDING: 'product/CREATE_BLENDING',
  CREATE_SAMPLING: 'product/CREATE_SAMPLING',
  CREATE_YIELD_RECONCILATION: 'product/CREATE_YIELD_RECONCILATION',
  CREATE_BLEND_DISTRIBUTION: 'product/CREATE_BLEND_DISTRIBUTION',
  GET_ALL_PRODUCTS: 'product/GET_ALL_PRODUCT',
  GET_ALL_PRODUCT_NAMES: 'product/GET_ALL_PRODUCT_NAMES',
  GET_ALL_PRODUCT_DETAILS: 'product/GET_ALL_PRODUCT_DETAILS',
  CREATE_NEW_PRODUCT: 'product/CREATE_NEW_PRODUCT',
  GET_ALL_STAGES: 'product/GET_ALL_STAGES',
  CREATE_NEW_BMR: 'product/CREATE_NEW_BMR',
  GET_ALL_UOMS: 'product/GET_ALL_UOMS',
  GET_ALL_STAGES_LIST: 'product/GET_ALL_STAGES_LIST',
  GET_ALL_PROCESSES: 'product/GET_ALL_PROCESSES',
  GET_EQUIPMENTS: 'product/GET_EQUIPMENTS',
  Get_ALL_MATERIALS: 'product/GET_ALL_MATERIALS',
  CREATE_PROD_GROSS: 'product/CREATE_PROD_GROSS',
  GET_ALL_PROCESS: 'product/GET_ALL_PROCESS',
  GET_MATERIALS: 'product/GET_MATERIALS',
  CREATE_BOM_MATERIALS: 'product/CREATE_BOM_MATERIALS',
  GET_ALL_BOMS: 'product/GET_ALL_BOMS',
  GET_ALL_PARAMS_LIST: 'product/GET_ALL_PARAMS_LIST',
  GET_ACCESSORIES: 'product/GET_ACCESSORIES',
  CREATE_INSTRUCTION: 'product/CREATE_INSTRUCTION',
  CREATE_ACTUAL_VALUES: 'product/CREATE_ACTUAL_VALUES',
  GET_ALL_CONFIG_BOMS: 'product/GET_ALL_CONFIG_BOMS',
  GET_PRINT_BMR: 'product/GET_PRINT_BMR',
  CREATE_FBB: 'product/CREATE_FBB',
  GET_FBB: 'product/GET_FBB',
  UPDATE_MATERIALS: 'product/UPDATE_MATERIALS',
  DELETE_PARAMS: 'product/DELETE_PARAMS',
  ADD_END_DATE: 'product/ADD_END_DATE',
  DOWNLOAD_USERS: 'product/DOWNLOAD_USERS',
  EMERGENCY_STOP: 'product/EMERGENCY_STOP',
  GET_LC_EQUIPMENTS: 'product/GET_LC_EQUIPMENTS',
  GET_CURRENT_PRODUCT: 'product/GET_CURRENT_PRODUCT',
  CREATE_PROD_CLEARENCE: 'prod/CREATE_PROD_CLEARENCE',
  GET_LC_LIST: 'prod/GET_LC_LIST',
  CREATE_QA_CLEARENCE: 'prod/CREATE_QA_CLEARENCE',
  GET_ALL_MSTER_PRODUCTS: 'prod/GET_ALL_MSTER_PRODUCTS',
  CREATE_MASTER_PRODUCT: 'prod/CREATE_MASTER_PRODUCT',
  GET_ALL_PROCESS_GROUPS: 'prod/GET_ALL_PROCESS_GROUPS',
  CREATE_EBMR: 'prod/CREATE_EBMR',
  GET_ALL_EXECUTION_DETAILS: 'prod/GET_ALL_EXECUTION_DETAILS'
};

export const CreateProduct = createAsyncThunk(
  actions.CREATE_PRODUCT,
  async (payload) => {
    const response = await addProduct('add', payload);
    return response;
  }
);

export const CreateNewProduct = createAsyncThunk(
  actions.CREATE_NEW_PRODUCT,
  async (payload) => {
    const response = await addProduct('create_product', payload);

    return response;
  }
);

export const createGranuler = createAsyncThunk(
  actions.CREATE_GRANULER,
  async (payload) => {
    const response = await addGranuler('granular_create', payload);

    return response;
  }
);

export const createSifting = createAsyncThunk(
  actions.CREATE_SIFTING,
  async (payload) => {
    const response = await addSifting('shifting_create', payload);

    return response;
  }
);

export const createExtraGranulation = createAsyncThunk(
  actions.CREATE_EXTRA_GRANULATION,
  async (payload) => {
    const response = await addExtraGranulation(
      'extra_granular_create',
      payload
    );

    return response;
  }
);

export const createBlending = createAsyncThunk(
  actions.CREATE_BLENDING,
  async (payload) => {
    const response = await addBlending('blending_create', payload);

    return response;
  }
);

export const createSampling = createAsyncThunk(
  actions.CREATE_SAMPLING,
  async (payload) => {
    const response = await addSample('sampling_create', payload);

    return response;
  }
);

export const createYieldReconcilation = createAsyncThunk(
  actions.CREATE_YIELD_RECONCILATION,
  async (payload) => {
    const response = await addYieldReconcilation('yield_create', payload);

    return response;
  }
);

export const getAllMaterials = createAsyncThunk(
  actions.GET_ALL_PRODUCTS,
  async (payload) => {
    const response = await dispense('products', payload);

    return response;
  }
);

export const createBlendDistribution = createAsyncThunk(
  actions.CREATE_BLEND_DISTRIBUTION,
  async (payload) => {
    const response = await addBlendDistribution(
      'blend_distribution_create',
      payload
    );

    return response;
  }
);

export const getAllProductNames = createAsyncThunk(
  actions.GET_ALL_PRODUCT_NAMES,
  async (payload) => {
    const response = await getProductNames('type-list', payload);

    return response;
  }
);

export const getAllProductDetails = createAsyncThunk(
  actions.GET_ALL_PRODUCT_DETAILS,
  async (payload) => {
    const response = await getProductDetails('product-type-details', payload);

    return response;
  }
);

export const getAllStags = createAsyncThunk(
  actions.GET_ALL_STAGES,
  async (payload) => {
    const response = await getstageDetails('product_view', payload);

    return response;
  }
);

export const CreateNewBMR = createAsyncThunk(
  actions.CREATE_NEW_BMR,
  async (payload) => {
    const response = await createnewBmr('add_materials', payload);

    return response;
  }
);

export const getAllUoms = createAsyncThunk(
  actions.GET_ALL_UOMS,
  async (payload) => {
    const response = await getuoms('all-materialuom', payload);

    return response;
  }
);

export const getAllStageslist = createAsyncThunk(
  actions.GET_ALL_STAGES_LIST,
  async (payload) => {
    const response = await getStages('stage_master', payload);

    return response;
  }
);

export const getAllStageProcesses = createAsyncThunk(
  actions.GET_ALL_PROCESSES,
  async (payload) => {
    const response = await getStageProcess('process_master', payload);

    return response;
  }
);

export const getEquipaments = createAsyncThunk(
  actions.GET_EQUIPMENTS,
  async (payload) => {
    const response = await getAllEquipments('equipment_master', payload);

    return response;
  }
);

export const getAllMatrials = createAsyncThunk(
  actions.Get_ALL_MATERIALS,
  async (payload) => {
    const response = await getAllWeighingMaterials(
      'dispense_containers',
      payload
    );

    return response;
  }
);

export const createProdGross = createAsyncThunk(
  actions.CREATE_PROD_GROSS,
  async (payload) => {
    const response = await createGross('create_gross_weight', payload);

    return response;
  }
);

export const getAllProcessList = createAsyncThunk(
  actions.GET_ALL_PROCESS,
  async (payload) => {
    const response = await getAllProcess('bom_process_list', payload);

    return response;
  }
);

export const getMaterials = createAsyncThunk(
  actions.GET_MATERIALS,
  async (payload) => {
    const response = await getSelectedMaterials('product_view', payload);

    return response;
  }
);

export const createbomMateials = createAsyncThunk(
  actions.CREATE_BOM_MATERIALS,
  async (payload) => {
    const response = await createbom('create_bom', payload);

    return response;
  }
);

export const getboms = createAsyncThunk(
  actions.GET_ALL_BOMS,
  async (payload) => {
    const response = await getallboms('get_bom', payload);

    return response;
  }
);

export const getconfigboms = createAsyncThunk(
  actions.GET_ALL_CONFIG_BOMS,
  async (payload) => {
    const response = await getallexeboms('get_config', payload);

    return response;
  }
);

export const getAllParamsList = createAsyncThunk(
  actions.GET_ALL_PARAMS_LIST,
  async (payload) => {
    const response = await getallparams('parameter_type_master', payload);

    return response;
  }
);

export const getAccessories = createAsyncThunk(
  actions.GET_ACCESSORIES,
  async (payload) => {
    const response = await getallaccessories('get_param_accesory', payload);

    return response;
  }
);

export const createInstruction = createAsyncThunk(
  actions.CREATE_INSTRUCTION,
  async (payload) => {
    const response = await createbomInstructions('add_bom_parameters', payload);

    return response;
  }
);

export const createactualMaterials = createAsyncThunk(
  actions.CREATE_ACTUAL_VALUES,
  async (payload) => {
    const response = await createbmrExe('create_actuals', payload);

    return response;
  }
);

export const printBMR = createAsyncThunk(
  actions.GET_PRINT_BMR,
  async (payload) => {
    const response = await print_bmr(
      `generate-pdf-bmr/${payload?.dispensing_request_id}`,
      payload
    );

    return response;
  }
);

export const createFbb = createAsyncThunk(
  actions.CREATE_FBB,
  async (payload) => {
    const response = await create_fbb(`create_fbb`, payload);

    return response;
  }
);

export const getFbb = createAsyncThunk(actions.GET_FBB, async (payload) => {
  const response = await get_fbb(`get_fbb`, payload);

  return response;
});

export const updateMaterials = createAsyncThunk(
  actions.UPDATE_MATERIALS,
  async (payload) => {
    const response =
      payload?.eqp_type == 'update_sequence'
        ? await update_seq(`update_sequence`, payload)
        : await update_mat(
            payload?.eqp_type == 'Instructions'
              ? `edit_instruction`
              : `edit_config`,
            payload
          );

    return response;
  }
);

export const deleteParams = createAsyncThunk(
  actions.DELETE_PARAMS,
  async (payload) => {
    const response = await delete_params(`delete_config`, payload);

    return response;
  }
);

export const AddEndDate = createAsyncThunk(
  actions.ADD_END_DATE,
  async (payload) => {
    const response = await add_end_date_time(`end_date`, payload);

    return response;
  }
);

export const downloadUsers = createAsyncThunk(
  actions.DOWNLOAD_USERS,
  async (payload) => {
    const response = await download_ebmr_users(`ebmr_user_list`, payload);

    return response;
  }
);

export const emergencyStop = createAsyncThunk(
  actions.EMERGENCY_STOP,
  async (payload) => {
    const response = await stopProcess(`emergency_hold`, payload);

    return response;
  }
);

export const getAllEquips = createAsyncThunk(
  actions.GET_LC_EQUIPMENTS,
  async (payload) => {
    const response = await getlcEquips(`lc_eqp_list`, payload);

    return response;
  }
);

export const getCurrentProduct = createAsyncThunk(
  actions.GET_CURRENT_PRODUCT,
  async (payload) => {
    const response = await getlcCurrentProd(`lc_current_product`, payload);

    return response;
  }
);

export const createProdClearence = createAsyncThunk(
  actions.CREATE_PROD_CLEARENCE,
  async (payload) => {
    const response = await createClearenceforProd(`lc_bmr_create`, payload);

    return response;
  }
);

export const getAlllclist = createAsyncThunk(
  actions.GET_LC_LIST,
  async (payload) => {
    const response = await getlcList(`lc_bmr_eqp_list`, payload);

    return response;
  }
);

export const createQAClearence = createAsyncThunk(
  actions.CREATE_QA_CLEARENCE,
  async (payload) => {
    const response = await createClearenceforQA(`qa_lc_create`, payload);

    return response;
  }
);

export const getAllMasterProducts = createAsyncThunk(
  actions.GET_ALL_MSTER_PRODUCTS,
  async (payload) => {
    const response = await getMasterProdiucts(`get_all_productmaster`, payload);

    return response;
  }
);

export const CreateMasterProduct = createAsyncThunk(
  actions.CREATE_MASTER_PRODUCT,
  async (payload) => {
    const response = await createMasterProduct(`add_productmaster`, payload);

    return response;
  }
);

export const getAllProcesses = createAsyncThunk(
  actions.GET_ALL_PROCESS_GROUPS,
  async (payload) => {
    const response = await getAllProcessGroups(`get_ebmr`, payload);

    return response;
  }
);

export const CreateMastereBMR = createAsyncThunk(
  actions.CREATE_EBMR,
  async (payload) => {
    const response = await createEbmrMaster(`create_ebmr_master`, payload);

    return response;
  }
);

export const getAllExecutionDetails = createAsyncThunk(
  actions.GET_ALL_EXECUTION_DETAILS,
  async (payload) => {
    const response = await getExecutionData(`get`, payload);

    return response;
  }
);

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(CreateProduct.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(CreateProduct.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(CreateProduct.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createGranuler.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createGranuler.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createGranuler.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createSifting.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createSifting.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createSifting.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createExtraGranulation.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createExtraGranulation.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createExtraGranulation.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createBlending.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createBlending.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createBlending.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createSampling.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createSampling.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createSampling.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createYieldReconcilation.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createYieldReconcilation.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createYieldReconcilation.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllMaterials.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllMaterials.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllMaterials.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createBlendDistribution.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createBlendDistribution.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createBlendDistribution.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllProductNames.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllProductNames.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, message: msg } = action.payload;
        if (success) {
          state.productNames = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getAllProductNames.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllProductDetails.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllProductDetails.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, message: msg } = action.payload;
        if (success) {
          state.productDetails = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getAllProductDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(CreateNewProduct.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(CreateNewProduct.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(CreateNewProduct.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllStags.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllStags.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllStags.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(CreateNewBMR.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(CreateNewBMR.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(CreateNewBMR.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllUoms.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllUoms.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllUoms.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllStageslist.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllStageslist.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllStageslist.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllStageProcesses.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllStageProcesses.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllStageProcesses.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getEquipaments.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getEquipaments.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, message: msg } = action.payload;
        if (success) {
          state.Allequipements = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getEquipaments.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllMatrials.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllMatrials.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const {
          success,
          data,
          dispense_details,
          message: msg
        } = action.payload;
        console.log(data);
        if (success) {
          state.WeighingMaterials = data;
          state.prodDetails = dispense_details;
        } else {
          message.error(msg);
        }
      })
      .addCase(getAllMatrials.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createProdGross.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createProdGross.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createProdGross.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllProcessList.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllProcessList.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllProcessList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getMaterials.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getMaterials.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getMaterials.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createbomMateials.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createbomMateials.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createbomMateials.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getboms.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getboms.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getboms.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllParamsList.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllParamsList.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllParamsList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAccessories.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAccessories.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAccessories.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createInstruction.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createInstruction.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createInstruction.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createactualMaterials.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createactualMaterials.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createactualMaterials.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getconfigboms.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getconfigboms.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getconfigboms.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(printBMR.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(printBMR.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(printBMR.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createFbb.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createFbb.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createFbb.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getFbb.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getFbb.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getFbb.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(updateMaterials.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(updateMaterials.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(updateMaterials.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(deleteParams.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(deleteParams.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(deleteParams.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(AddEndDate.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(AddEndDate.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(AddEndDate.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(downloadUsers.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(downloadUsers.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(downloadUsers.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(emergencyStop.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(emergencyStop.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(emergencyStop.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllEquips.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllEquips.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllEquips.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getCurrentProduct.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getCurrentProduct.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getCurrentProduct.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(createProdClearence.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createProdClearence.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createProdClearence.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAlllclist.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAlllclist.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAlllclist.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createQAClearence.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createQAClearence.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createQAClearence.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllMasterProducts.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllMasterProducts.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllMasterProducts.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(CreateMasterProduct.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(CreateMasterProduct.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(CreateMasterProduct.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllProcesses.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllProcesses.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllProcesses.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(CreateMastereBMR.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(CreateMastereBMR.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(CreateMastereBMR.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllExecutionDetails.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllExecutionDetails.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllExecutionDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
  }
});
export default productSlice.reducer;
