import apiClient from 'utilities/apiClient';

export const materials = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.returnQty}/${url}`, payload, true);
};

export const create = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.returnQty}/${url}`, payload, true);
};

export const updateReturnStatus = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.returnQty}/${url}`, payload, true);
};
