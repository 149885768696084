import React, { useState, useEffect } from 'react';
import { Card, Form, Space, Tag, Input } from 'antd';
import styles from '@/common/Layout/index.module.less';
import CustomTable from 'common/Content/CustomTable';
import { useDispatch } from 'react-redux';
import { getAllMaterialslist } from '../../redux/slice';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import { getLoggedInUser } from 'utilities/helpers';
import moment from 'moment';

const { Search } = Input;

const ReturnRequestPage = () => {
  const [form] = Form.useForm();
  const { user } = getLoggedInUser();

  const dispatch = useDispatch();

  const [search, setSearch] = useState('');
  const [allMaterials, setAllMaterials] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    handleGetAllMaterialsList();
  }, [page, search]);

  const handleGetAllMaterialsList = () => {
    let payload = { page, search };
    dispatch(getAllMaterialslist(payload)).then((response) => {
      if (response?.payload?.success) {
        setAllMaterials(response?.payload?.data);
        setPagination(response?.payload?.pagination);
      }
    });
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const columns = [
    {
      title: 'Product Batch #',
      dataIndex: 'product_batch_number',
      key: 'product_batch_number',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        // return user?.role !== 'prod_user' ? (
        return (
          <a href={`create-return-qty?id=${record?.id}`}>{text || 'NA'}</a>
        );
        // ) : (
        //   text
        // );
      }
    },
    {
      title: 'MRR Number',
      dataIndex: 'MRR_number',
      key: 'MRR_number',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'Product Name',
      dataIndex: 'product_name',
      key: 'product_name',
      ellipsis: true,
      width: '100px'
    },

    {
      title: 'Created on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      ellipsis: true,
      width: '100px',
      render: (text) => {
        return text ? moment(text).format('DD MMM YYYY') : 'NA';
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: '100px',
      render: (text) => {
        return text == 'QA Approved' ? (
          <Tag color="#007bff">{text?.toUpperCase()}</Tag>
        ) : text == 'QA Pending' ? (
          <Tag color="#FF9800">{text?.toUpperCase()}</Tag>
        ) : text == 'QA Rejected' ? (
          <Tag color="#dc3545">{text?.toUpperCase()}</Tag>
        ) : (
          <Tag color="#4CAF50">{text?.toUpperCase()}</Tag>
        );
      }
    }
  ];

  return (
    <Form form={form} layout="vertical">
      <Card
        title={<span>MATERIAL RETURN REQUESTS</span>}
        className={`${styles.cardStyles_approver}`}
        extra={
          <Space>
            {user?.role === 'prod_user' || user?.role === 'qa_super_user' ? (
              <CustomButton
                type="primary"
                // ghost
                onClick={() => history.push('/create-return-qty')}
              >
                Create
              </CustomButton>
            ) : (
              ''
            )}
            <Search
              onChange={(e) => handleChange(e)}
              className={`w-100 ${styles.searchbar}`}
              justify="start"
              align="start"
              placeholder="Search by Product batch"
            />
          </Space>
        }
      >
        <div className={`${styles.table_container}`}>
          <CustomTable
            columns={columns}
            // pagination={true}
            dataSource={allMaterials}
            pagination={{
              size: 'small',
              total: pagination?.total_records || 0,
              showSizeChanger: false,
              pageSize: 10,
              onChange: (page) => {
                setPage(page);
              }
            }}
            // scroll={{ x: 'auto' }}
          />
        </div>
      </Card>
    </Form>
  );
};
export default ReturnRequestPage;
