import React from 'react';
import { Col, Form, Input, Row, Space, message } from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomModal from '@/common/CustomModal';
import CustomButton from '@/common/CustomButton';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  createMaterialInward,
  createVehicleStorage,
  createInvoice,
  updateBatch,
  updateWeighment,
  createDispensing
} from '../../redux/slice';
import { updateProduct } from '@/features/dispensing/redux/slice';
import { updateprintIssue } from '@/features/dispensing/redux/slice';
import { updateQCapprove } from '@/features/qa/redux/slice';
import { updateSampling } from '@/features/samplingMaterials/redux/slice';
import {
  createProdClearence,
  createQAClearence
} from '@/features/prodUser/redux/slice';
import { history } from 'app/history';

const { TextArea } = Input;

const VerificationModal = ({
  isModal,
  materials,
  setIsModal,
  material,
  setIsEdit,
  page,
  editableBatch,
  setBatchEdit,
  setIsinvoice
  // setEdit
}) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const params = useParams();
  console.log(materials, 'materials');
  const onFinish = (values) => {
    const payload =
      page == 'prod_user'
        ? {
            employee_id: values.employee_id,
            password: values.password,
            comments: values.comments,
            id: material?.id,
            previous_product: material?.previous_product,
            previous_batch: material?.previous_batch,
            infer_prod_dept: material?.infer_prod_dept,
            questions: material?.questions
          }
        : page == 'QA'
        ? {
            employee_id: values.employee_id,
            password: values.password,
            comments: values.comments,
            id: material?.id,
            isType: material?.isType,
            infer_qa_dept: material?.infer_qa_dept,
            questions: material?.questions,
            accessory_verify: material?.accessory_verify,
            dispensing_request_id: materials?.dispensing_request_id
          }
        : page == 'bmrupdate'
        ? {
            employee_id: values.employee_id,
            password: values.password,
            comments: values.comments,
            id: materials?.id,
            product_batch_number: materials?.product_batch_number,
            product_code: materials?.product_code,
            label_claim: materials?.label_claim,
            ref_mfc: materials?.ref_mfc,
            product_name: materials?.product_name,
            bmr_bpr_number: materials?.bmr_bpr_number,
            effective_date: materials?.effective_date,
            theoretical_yield: materials?.theoretical_yield,
            theoretical_batch_size: materials?.theoretical_batch_size,
            shelf_life: materials?.shelf_life,
            manufacturing_site: materials?.manufacturing_site,
            customer_details: materials?.customer_details,
            is_product: materials?.is_product,
            type: 'update',
            is_cloned: materials?.is_cloned
          }
        : page == 'bmr'
        ? {
            employee_id: values.employee_id,
            password: values.password,
            comments: values.comments,
            dispensing_request_id: materials?.dispensing_request_id,
            type: materials?.type,
            product_batch_number: materials?.product_batch_number,
            product_code: materials?.product_code,
            label_claim: materials?.label_claim,
            ref_mfc: materials?.ref_mfc,
            product_name: materials?.product_name,
            bmr_bpr_number: materials?.bmr_bpr_number,
            effective_date: materials?.effective_date,
            theoretical_yield: materials?.theoretical_yield,
            theoretical_batch_size: materials?.theoretical_batch_size,
            shelf_life: materials?.shelf_life,
            manufacturing_site: materials?.manufacturing_site,
            customer_details: materials?.customer_details,
            is_product: materials?.is_product,
            // type: 'update',
            material_list: materials?.material_list,
            is_parent: materials?.is_parent,
            parent_batch: materials.is_parent ? materials.parent_batch : 'NA',
            bmr_partial_details: materials?.bmr_partial_details
          }
        : page == 'sampling'
        ? {
            employee_id: values.employee_id,
            password: values.password,
            comments: values.comments,
            // batch_list: materials
            // container_id: containerDetails[0]?.id,
            batch_details_id: materials?.batch_details_id,
            quantity: materials?.quantity,
            received_date: materials?.createdAt,
            id: materials?.id,
            uom: materials?.uom
          }
        : page == 'BatchApproval'
        ? {
            employee_id: values.employee_id,
            password: values.password,
            comments: values.comments,
            batch_list: materials
          }
        : page == 'dispense' || page == 'manual'
        ? {
            // id: materials?.id,
            // batch_id: materials?.batch_id,
            employee_id: values.employee_id,
            password: values.password,
            comments: values.comments,
            dispense_materials: material
          }
        : page == 'weighing'
        ? {
            id: materials?.id,
            batch_id: materials?.batch_id,
            employee_id: values.employee_id,
            password: values.password,
            comments: values.comments,
            containers: materials?.containers
          }
        : page == 'materialVerification'
        ? {
            doc_id: params?.id,
            material_status: 'inv_ver_approved',
            type: 'batch_update',
            comments: values.comments,
            employee_id: values.employee_id,
            password: values.password,
            data_logger: materials?.data_logger,
            labels_defaced: materials?.labels_defaced,
            dedusting_status: materials?.dedusting,
            seal_integrity: materials?.seal_integrity,
            document_id: params?.id
          }
        : page == 'batch_details' || page == 'viewbatch'
        ? {
            batch_id: editableBatch?.id,
            doc_id: params?.id,
            material_status: 'inv_ver_approved',
            type: 'batch_update',
            comments: values.comments,
            employee_id: values.employee_id,
            password: values.password,
            mfg_batch_lot_no: editableBatch?.mfg_batch_lot_no,
            mfg_date: editableBatch?.mfg_date,
            mfg_retest_date: editableBatch?.mfg_retest_date,
            mfg_expiry_date: editableBatch?.mfg_expiry_date,
            in_house_expiry_date: editableBatch?.in_house_expiry_date,
            invoice_quantity: editableBatch?.invoice_quantity,
            received_quantity: editableBatch?.received_quantity,
            trf_serial_no: editableBatch?.trf_serial_no,
            test_to_be_performed: editableBatch?.test_to_be_performed
          }
        : page == 'invoicedetails' || page == 'po' || page == 'invoice'
        ? {
            // id: params?.id,
            document_id: params?.id,
            material_status: 'inv_ver_approved',
            type: 'invoice_update',
            comments: values.comments,
            employee_id: values.employee_id,
            password: values.password,
            material_id: materials?.material_id || material?.material?.id,
            po_number: materials?.po_number,
            po_qty: materials?.po_qty,
            po_unit_rate: materials?.po_unit_rate,
            po_total_amount: materials?.po_total_amount,
            cgst: materials?.cgst,
            sgst: materials?.sgst,
            igst: materials?.igst,
            total_inv_price: materials?.total_inv_price
            // po_number: materials?.po_number || material?.po_number
          }
        : page == 'vehicleStorage'
        ? {
            id: material?.vehicle_storage_area?.id,
            document_id: params?.id,
            comments: values.comments,
            employee_id: values.employee_id,
            password: values.password,
            vehicle_cleanliness:
              materials?.vehicle_cleanliness ||
              material?.vehicle_storage_area?.for_graviti,
            storage_enclose:
              materials?.storage_enclose ||
              material?.vehicle_storage_area?.storage_enclose,
            storage_conditions:
              materials?.storage_conditions ||
              material?.vehicle_storage_area?.storage_conditions
          }
        : {
            id: params?.id,
            comments: values.comments,
            employee_id: values.employee_id,
            password: values.password,
            for_graviti: materials?.gravity || material?.for_graviti,
            invoice_dc_boe_available:
              materials?.invoice_dc_boe_available ||
              material?.invoice_dc_boe_available,
            invoice_number:
              materials?.invoice_number || material?.invoice_number,
            po_available: materials?.po_available || material?.po_available,
            coa_coc_msda_available:
              materials?.coa_coc_msda_available ||
              material?.coa_coc_msda_available,
            material_vendor_status:
              materials?.material_vendor_status ||
              material?.material_vendor_status,
            manufacture_site_address_matching:
              materials?.manufacture_site_address_matching ||
              material?.manufacture_site_address_matching
          };
    dispatch(
      page == 'prod_user'
        ? createProdClearence(payload)
        : page == 'QA'
        ? createQAClearence(payload)
        : page == 'bmrupdate'
        ? updateProduct(payload)
        : page == 'bmr'
        ? createDispensing(payload)
        : page == 'sampling'
        ? updateSampling(payload)
        : page == 'BatchApproval'
        ? updateQCapprove(payload)
        : page == 'dispense' || page == 'manual'
        ? updateprintIssue(payload)
        : page == 'weighing'
        ? updateWeighment(payload)
        : page == 'vehicleStorage'
        ? createVehicleStorage(payload)
        : page == 'invoicedetails' || page == 'po' || page == 'invoice'
        ? createInvoice(payload)
        : page == 'batch_details' || page == 'viewbatch'
        ? updateBatch(payload)
        : createMaterialInward(payload)
    ).then(function (response) {
      if (response?.payload?.success) {
        // setTimeout(() => {
        message.success(response?.payload?.message);
        setIsModal(false);
        // setEdit(false);
        // setMatModal(!matModal);
        // message.success(response?.payload?.message);
        page == 'prod_user' || page == 'QA'
          ? history.push(`/qa-lc-list/${material?.dispensing_request_id}`)
          : page == 'bmrupdate'
          ? history.push(`/bmr-params-view/${payload?.id}`)
          : page == 'invoicedetails' || page == 'po' || page == 'invoice'
          ? history.push(`/batch-details/${response?.payload?.data?.id}`)
          : page == 'bmr'
          ? history.push(`/dispensing-list`)
          : page == 'sampling'
          ? history.push(`/samples-list`)
          : page == 'BatchApproval'
          ? history.push(`/batches-list?type=batches`)
          : page == 'dispense'
          ? history.push(
              `/dispensing-material/${payload?.[0]?.dispensing_request_id}`
            )
          : page == 'manual'
          ? history.push(
              `/manual_dispense/${payload?.dispense_materials?.[0]?.dispensing_request_id}?type=bmr`
            )
          : page == 'viewbatch'
          ? history.push(`/view/${response?.payload?.data?.id}`)
          : response?.payload?.data?.material_status === 'veh_ver_approved'
          ? history.push(`/invoice-details/${response?.payload?.data?.id}`)
          : response?.payload?.data?.material_status === 'inv_ver_approved' ||
            page == 'weighing'
          ? history.push(`/batch-details/${response?.payload?.data?.id}`)
          : history.push('/pending-receipts');
        setIsEdit(false);
        setIsinvoice(true);
        setBatchEdit('');
        // }, 2000);
      } else {
        setIsModal(false);
        setIsEdit(false);
        setIsinvoice(true);
      }
    });
    console.log(payload);
  };

  const handleClose = () => {
    setIsModal(false);
  };
  return (
    <CustomModal
      title="Verification By QA"
      visible={isModal}
      width="50%"
      footer={null}
      className={styles.customModal}
      closable={false}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={12}>
            <FormItem
              label="User ID"
              name="employee_id"
              rules={[
                {
                  required: true,
                  message: 'Enter User ID'
                }
              ]}
              className={styles.customLable}
            >
              <Input placeholder="Enter User ID" />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Enter Password'
                }
              ]}
              className={styles.customLable}
            >
              <Input.Password placeholder="Enter Password" />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              label="Comments"
              name="comments"
              rules={[
                {
                  required: true,
                  message: 'Enter Comments'
                }
              ]}
              className={styles.customLable}
            >
              <TextArea placeholder="Enter Comments" />
            </FormItem>
          </Col>
        </Row>
        <FormItem className="text-center">
          <Space>
            <CustomButton
              className={styles.inwardButton}
              onClick={handleClose}
              type="primary"
              size="small"
              ghost
            >
              Close
            </CustomButton>
            <CustomButton
              className={styles.inwardButton}
              htmlType="submit"
              type="primary"
              size="small"
            >
              Verify
            </CustomButton>
          </Space>
        </FormItem>
      </Form>
    </CustomModal>
  );
};
export default VerificationModal;
