import React from 'react';
import Layout from '@/common/Layout';
import StockCardPage from './components/stockcardPage';

const StockCard = () => {
  return (
    <>
      <Layout sider={false}>
        <StockCardPage />
      </Layout>
    </>
  );
};

export default StockCard;
