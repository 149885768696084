import React, { useEffect } from 'react';
import Layout from '@/common/Layout';
import {
  Row,
  Col,
  Form,
  Card,
  Input,
  Radio,
  Collapse,
  InputNumber,
  message
} from 'antd';
import styles from '@/common/Layout/index.module.less';
import { getDispenseMaterialDetails } from '@/features/dispensing/redux/slice';
import FormItem from '@/common/FormItem';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import CustomButton from '@/common/CustomButton';
import { useState } from 'react';
import { DeleteOutlined, PlusCircleFilled } from '@ant-design/icons';
import { createBlendDistribution } from '../../redux/slice';
import { history } from 'app/history';

const { Panel } = Collapse;

const blendDistrubution = () => {
  const { materialDetails } = useSelector((state) => state.dispensing);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const params = useParams();
  useEffect(() => {
    handleGetAllMaterials();
  }, []);

  const [batches, setBatches] = useState([]);
  const [cardShow, setCardShow] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [totalNet, setTotalNet] = useState(0);

  const handleGetAllMaterials = () => {
    let payload = {
      id: params.id
    };
    dispatch(getDispenseMaterialDetails(payload)).then((response) => {
      if (response?.payload?.success) {
        setTotalNet(response?.payload?.data?.blendings[0]?.total_net_weight);
      }
    });
  };

  useEffect(() => {
    if (quantity == 10) {
      setCardShow(true);
      const temp_mbatches = batches;
      for (let i = 0; i < 3; i++) {
        temp_mbatches.push({
          compression_batch: '',
          total_net_weight: '',
          blend_division_qty: '',
          actual_blend_qty: '',
          containers: [
            {
              container: '',
              tare_weight: '',
              net_weight: '',
              gross_weight: '',
              uom: ''
            }
          ]
        });
      }
      setBatches(temp_mbatches);
    } else if (quantity == 20) {
      setCardShow(true);
      const temp_mbatches = batches;
      for (let i = 0; i < 2; i++) {
        temp_mbatches.push({
          compression_batch: '',
          total_net_weight: '',
          blend_division_qty: '',
          actual_blend_qty: '',
          containers: [
            {
              container: '',
              tare_weight: '',
              net_weight: '',
              gross_weight: '',
              uom: ''
            }
          ]
        });
      }
      setBatches(temp_mbatches);
    } else if (quantity == 40 || quantity == 80) {
      setCardShow(true);
      console.log(batches);
      const temp_mbatches = batches;
      for (let i = 0; i < 1; i++) {
        temp_mbatches.push({
          compression_batch: '',
          total_net_weight: '',
          blend_division_qty: '',
          actual_blend_qty: '',
          containers: [
            {
              container: '',
              tare_weight: '',
              net_weight: '',
              gross_weight: '',
              uom: ''
            }
          ]
        });
      }
      setBatches(temp_mbatches);
    }
  }, [quantity]);

  const handleradioChange = (qty) => {
    console.log(qty);
    setCardShow(false);
    setQuantity(qty);
    setBatches([]);
  };
  console.log(batches);
  const handleChange = (type, i, index, e) => {
    console.log(type, i, index, e);
    let temp_lots = [...batches];
    if (type === 'tare_weight') {
      temp_lots[index].containers[i]['gross_weight'] =
        e + (temp_lots[index].containers[i]['net_weight'] || 0);
    } else if (type === 'net_weight') {
      temp_lots[index].containers[i]['gross_weight'] =
        e + (temp_lots[index].containers[i]['tare_weight'] || 0);
    }
    if (type == 'tare_weight' || type == 'net_weight') {
      temp_lots[index].containers[i][type] = e;
    } else {
      temp_lots[index].containers[i][type] = e.target.value;
    }

    setBatches(temp_lots);
    if (batches[index]?.containers?.length) {
      var add = 0;
      batches[index]?.containers?.map((item) => {
        console.log(item);
        add += parseFloat(item?.net_weight);
      });
      temp_lots[index].total_net_weight = add;
    }
  };

  const handleAddWeight = (i, index) => {
    let temp_lots = [...batches];
    temp_lots[index].containers.push({
      container: '',
      tare_weight: '',
      net_weight: '',
      gross_weight: ''
    });
    setBatches(temp_lots);
  };

  const handleCompression = (index, e) => {
    let temp_lots = [...batches];
    temp_lots[index]['compression_batch'] = e.target.value;
  };
  const handleremoveWeight = (i, index) => {
    let temp_lots = [...batches];
    temp_lots[index].containers.splice(i, 1);
    setBatches(temp_lots);
  };
  const onFinish = (values) => {
    const payload = {
      dispensing_request_id: params.id,
      distribution_name: quantity,
      balance_id: values.balance_id,
      theoritical_batch:
        quantity == 10
          ? (totalNet / 3).toFixed(3)
          : quantity == 20
          ? (totalNet / 2).toFixed(3)
          : 0,
      blend_distrubition: batches
    };
    dispatch(createBlendDistribution(payload)).then((response) => {
      if (response?.payload?.success) {
        message.success(response?.payload.message);
        history.push(`/dashboard`);
      }
    });
    // console.log(payload);
  };

  return (
    <Layout sider={false}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
      >
        <Card
          title={
            <span style={{ fontWeight: 'bold' }}>
              Blend Distribution Record
            </span>
          }
          className={styles.CustomPanel}
        >
          <Card>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem label="Product Name" className={styles.customLable}>
                  <Input
                    disabled
                    value={materialDetails?.product_detail?.name || 'NA'}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="Product Batch Number"
                  className={styles.customLable}
                >
                  <Input
                    disabled
                    value={materialDetails?.product_batch_number || 'NA'}
                  />
                </FormItem>
              </Col>

              <Col span={12}>
                <FormItem label="Effective Date" className={styles.customLable}>
                  <Input
                    disabled
                    value={
                      materialDetails?.effective_date
                        ? moment(materialDetails?.effective_date).format(
                            'DD MMM YYYY'
                          )
                        : 'NA'
                    }
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="Batch Size" className={styles.customLable}>
                  <Input
                    disabled
                    value={materialDetails?.product_detail?.batch_size || 'NA'}
                  />
                </FormItem>
              </Col>
            </Row>
          </Card>
          <br />
          <Card>
            <Radio.Group>
              <Row gutter={24}>
                <Col
                  xs={24}
                  xl={12}
                  span={24}
                  md={24}
                  sm={24}
                  style={{ paddingTop: '12px' }}
                >
                  <Radio value={1} onChange={() => handleradioChange('10')}>
                    Atorvastatin Calcium Tablets USP 10mg
                  </Radio>
                </Col>
                <Col
                  xs={24}
                  xl={12}
                  span={24}
                  md={24}
                  sm={24}
                  style={{ paddingTop: '12px' }}
                >
                  <Radio value={2} onChange={() => handleradioChange('20')}>
                    Atorvastatin Calcium Tablets USP 20mg
                  </Radio>
                </Col>
                <Col
                  xs={24}
                  xl={12}
                  span={24}
                  md={24}
                  sm={24}
                  style={{ paddingTop: '12px' }}
                >
                  <Radio value={3} onChange={() => handleradioChange('40')}>
                    Atorvastatin Calcium Tablets USP 40mg
                  </Radio>
                </Col>
                <Col
                  xs={24}
                  xl={12}
                  span={24}
                  md={24}
                  sm={24}
                  style={{ paddingTop: '12px' }}
                >
                  <Radio value={4} onChange={() => handleradioChange('80')}>
                    Atorvastatin Calcium Tablets USP 80mg
                  </Radio>
                </Col>
              </Row>
            </Radio.Group>
          </Card>
          <br />
          {cardShow ? (
            <Card>
              <Card>
                <Row gutter={24}>
                  <Col xs={24} xl={12} span={24} md={24} sm={24}>
                    <FormItem
                      label="Balance Id"
                      name="balance_id"
                      rules={[
                        {
                          required: false,
                          message: 'Enter Balance ID'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input placeholder="Enter Balance ID" />
                    </FormItem>
                  </Col>
                  {quantity == 10 || quantity == 20 ? (
                    <Col xs={24} xl={12} span={24} md={24} sm={24}>
                      <FormItem
                        label="Theoretical batch size of Atorvastatin Calcium Tablets USP 10mg"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Balance ID'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter ID"
                          value={
                            quantity == 10
                              ? (totalNet / 3).toFixed(3)
                              : quantity == 20
                              ? (totalNet / 2).toFixed(3)
                              : ''
                          }
                          disabled
                        />
                      </FormItem>
                    </Col>
                  ) : (
                    ''
                  )}
                </Row>
              </Card>
              <br />
              {batches?.length
                ? batches.map((item, index) => {
                    var blend_division =
                      quantity == 10
                        ? (
                            (parseFloat(totalNet) *
                              (parseFloat(totalNet) / 3)) /
                            parseFloat(
                              materialDetails?.product_detail?.batch_size
                            )
                          ).toFixed(3)
                        : quantity == 20
                        ? ((parseFloat(totalNet) * (parseFloat(totalNet) / 3)) /
                            parseFloat(
                              materialDetails?.product_detail?.batch_size
                            )) *
                          2
                        : '';
                    let temp_lots = [...batches];
                    temp_lots[index]['blend_division_qty'] = blend_division;
                    return (
                      <>
                        <Collapse defaultActiveKey={[index]} key={index}>
                          <Panel
                            header={
                              <span
                                style={{ color: '#e80e94' }}
                              >{`Compression Batch ${index + 1}`}</span>
                            }
                            key={index}
                          >
                            <Row gutter={24}>
                              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                                <FormItem
                                  label="Compression Batch #"
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Enter Compression Batch ID'
                                    }
                                  ]}
                                  className={styles.customLable}
                                >
                                  <Input
                                    placeholder="Enter Compression Batch ID"
                                    onChange={(e) =>
                                      handleCompression(index, e)
                                    }
                                  />
                                </FormItem>
                              </Col>
                              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                                <FormItem
                                  label="Total Net Weight"
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Enter Total Net Weight'
                                    }
                                  ]}
                                  className={styles.customLable}
                                >
                                  <Input
                                    placeholder="Enter Total Net Weight"
                                    value={item?.total_net_weight}
                                    disabled
                                  />
                                </FormItem>
                              </Col>
                              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                                <FormItem
                                  label="Blend Divivsion Calculation"
                                  rules={[
                                    {
                                      required: false,
                                      message:
                                        'Enter Blend Division Calculation'
                                    }
                                  ]}
                                  className={styles.customLable}
                                >
                                  <Input
                                    placeholder="Enter Blend Division Calculation"
                                    value={
                                      quantity == 10
                                        ? (
                                            (parseFloat(totalNet) *
                                              (parseFloat(totalNet) / 3)) /
                                            parseFloat(
                                              materialDetails?.product_detail
                                                ?.batch_size
                                            )
                                          ).toFixed(3)
                                        : quantity == 20
                                        ? ((parseFloat(totalNet) *
                                            (parseFloat(totalNet) / 3)) /
                                            parseFloat(
                                              materialDetails?.product_detail
                                                ?.batch_size
                                            )) *
                                          2
                                        : ''
                                    }
                                    disabled
                                  />
                                </FormItem>
                              </Col>
                              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                                <FormItem
                                  label="Actual Blend Qty"
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Enter Actual Blend Qty'
                                    }
                                  ]}
                                  className={styles.customLable}
                                >
                                  <Input placeholder="Enter Actual Blend Qty" />
                                </FormItem>
                              </Col>
                            </Row>
                            <Card>
                              {item?.containers?.map((data, i) => {
                                return (
                                  <Row gutter={24} key={i}>
                                    <Col
                                      xs={24}
                                      xl={4}
                                      span={24}
                                      md={24}
                                      sm={24}
                                    >
                                      <FormItem
                                        label="Container"
                                        rules={[
                                          {
                                            required: false,
                                            message: 'Enter Container #'
                                          }
                                        ]}
                                        className={styles.customLable}
                                      >
                                        <Input
                                          placeholder="Enter Container ID"
                                          value={data?.container || ''}
                                          onChange={(e) =>
                                            handleChange(
                                              'container',
                                              i,
                                              index,
                                              e
                                            )
                                          }
                                        />
                                      </FormItem>
                                    </Col>
                                    <Col
                                      xs={24}
                                      xl={4}
                                      span={24}
                                      md={24}
                                      sm={24}
                                    >
                                      <FormItem
                                        label="Tare Weight"
                                        rules={[
                                          {
                                            required: false,
                                            message: 'Enter Tare Weight'
                                          }
                                        ]}
                                        className={styles.customLable}
                                      >
                                        <InputNumber
                                          style={{ width: '100%' }}
                                          min={0}
                                          placeholder="Enter Tare Weight"
                                          value={data?.tare_weight || ''}
                                          onChange={(e) =>
                                            handleChange(
                                              'tare_weight',
                                              i,
                                              index,
                                              e
                                            )
                                          }
                                        />
                                      </FormItem>
                                    </Col>
                                    <Col
                                      xs={24}
                                      xl={2}
                                      span={24}
                                      md={24}
                                      sm={24}
                                    >
                                      <FormItem
                                        label="UOM"
                                        rules={[
                                          {
                                            required: false,
                                            message: 'Enter UOM'
                                          }
                                        ]}
                                        className={styles.customLable}
                                      >
                                        <Input
                                          placeholder="UOM"
                                          value={
                                            batches[0].containers[0].uom || ''
                                          }
                                          onChange={(e) =>
                                            handleChange('uom', i, index, e)
                                          }
                                        />
                                      </FormItem>
                                    </Col>
                                    <Col
                                      xs={24}
                                      xl={4}
                                      span={24}
                                      md={24}
                                      sm={24}
                                    >
                                      <FormItem
                                        label="Net Weight"
                                        rules={[
                                          {
                                            required: false,
                                            message: 'Enter Net Weight'
                                          }
                                        ]}
                                        className={styles.customLable}
                                      >
                                        <InputNumber
                                          style={{ width: '100%' }}
                                          min={0}
                                          placeholder="Enter Net Weight"
                                          value={data?.net_weight || ''}
                                          onChange={(e) =>
                                            handleChange(
                                              'net_weight',
                                              i,
                                              index,
                                              e
                                            )
                                          }
                                        />
                                      </FormItem>
                                    </Col>
                                    <Col
                                      xs={24}
                                      xl={2}
                                      span={24}
                                      md={24}
                                      sm={24}
                                    >
                                      <FormItem
                                        label="UOM"
                                        rules={[
                                          {
                                            required: false,
                                            message: 'Enter UOM'
                                          }
                                        ]}
                                        className={styles.customLable}
                                      >
                                        <Input
                                          placeholder="UOM"
                                          value={
                                            batches[0].containers[0].uom || ''
                                          }
                                          disabled
                                        />
                                      </FormItem>
                                    </Col>
                                    <Col
                                      xs={24}
                                      xl={4}
                                      span={24}
                                      md={24}
                                      sm={24}
                                    >
                                      <FormItem
                                        label="Gross Weight"
                                        rules={[
                                          {
                                            required: false,
                                            message: 'Enter Gross Weight'
                                          }
                                        ]}
                                        className={styles.customLable}
                                      >
                                        <Input
                                          placeholder="Enter Gross Weight"
                                          value={data?.gross_weight || ''}
                                          onChange={(e) =>
                                            handleChange(
                                              'gross_weight',
                                              i,
                                              index,
                                              e
                                            )
                                          }
                                          disabled
                                        />
                                      </FormItem>
                                    </Col>
                                    <Col
                                      xs={24}
                                      xl={2}
                                      span={24}
                                      md={24}
                                      sm={24}
                                    >
                                      <FormItem
                                        label="UOM"
                                        rules={[
                                          {
                                            required: false,
                                            message: 'Enter UOM'
                                          }
                                        ]}
                                        className={styles.customLable}
                                      >
                                        <Input
                                          placeholder="UOM"
                                          value={batches[0].containers[0].uom}
                                          disabled
                                        />
                                      </FormItem>
                                    </Col>
                                    <Col
                                      xs={24}
                                      xl={2}
                                      span={24}
                                      md={24}
                                      sm={24}
                                    >
                                      <FormItem
                                        label=""
                                        rules={[
                                          {
                                            required: false,
                                            message: 'Enter UOM'
                                          }
                                        ]}
                                        className={styles.customLableButton}
                                      >
                                        {i == 0 ? (
                                          <CustomButton
                                            className={styles.border_button}
                                            onClick={() =>
                                              handleAddWeight(i, index)
                                            }
                                          >
                                            <PlusCircleFilled />
                                          </CustomButton>
                                        ) : (
                                          ''
                                        )}
                                        {i > 0 ? (
                                          <CustomButton
                                            className={styles.border_button}
                                            onClick={() =>
                                              handleremoveWeight(i, index)
                                            }
                                          >
                                            <DeleteOutlined />
                                          </CustomButton>
                                        ) : (
                                          ''
                                        )}
                                      </FormItem>
                                    </Col>
                                  </Row>
                                );
                              })}
                            </Card>
                          </Panel>
                        </Collapse>
                        <br />
                      </>
                    );
                  })
                : ''}
            </Card>
          ) : (
            ''
          )}
        </Card>
        <br />
        <FormItem className="text-center">
          <CustomButton
            className={styles.inwardButton}
            htmlType="submit"
            type="primary"
          >
            Submit
          </CustomButton>
        </FormItem>
      </Form>
    </Layout>
  );
};
export default blendDistrubution;
