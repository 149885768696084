import apiClient from 'utilities/apiClient';

export const materials = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.materialList}/${url}`, payload, true);
};

export const materialsMRNS = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.document}/${url}`, payload, true);
};

export const create_materials = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.document}/${url}`, payload, true);
};

export const create_invoice = (url, payload) => {
  return apiClient.patch(
    `${apiClient.Urls.document}/${url}?id=${payload.document_id}`,
    payload,
    true
  );
};

export const create_vehicles = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.vehicle}/${url}`, payload, true);
};

export const create_weighing = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.weighing}/${url}`, payload, true);
};

export const create_damaged = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.damaged}/${url}`, payload, true);
};

export const suppliers = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.supplierslist}/${url}`, payload, true);
};

export const qc = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.qc}/${url}?id=${payload.id}`,
    payload,
    true
  );
};

export const print = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.printLable}/${url}`, payload, true);
};
export const documents = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.document}/${url}`, payload, true);
};

export const netweights = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.weighing}/${url}`, payload, true);
};

export const updatenetweights = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.weighing}/${url}`, payload, true);
};

export const dispensingList = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.dispense}/${url}`, payload, true);
};

export const batch = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.batch}/${url}`, payload, true);
};

export const dispense = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.dispense}/${url}`, payload, true);
};

export const batchDetails = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.transfer}/${url}`, payload, true);
};

export const createCodetransfer = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.transfer}/${url}`, payload, true);
};

export const getCodetoCodeTransferData = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.transfer}/${url}`, payload, true);
};

export const approvedData = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.transfer}/${url}`, payload, true);
};

export const update = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.weighing}/${url}`, payload, true);
};

export const typedMaterials = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.transfer}/${url}`, payload, true);
};
export const dispensing = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.dispense}/${url}`, payload, true);
};
export const purchase = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.purchase}/${url}`, payload, true);
};
