import React, { useEffect } from 'react';
// import Layout from '@/common/Layout';
import { Form, Card, Space, Input } from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSample } from '../../redux/slice';
import CustomTable from 'common/Content/CustomTable';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import Layout from '@/common/Layout';
import { useState } from 'react';
import { getLoggedInUser } from 'utilities/helpers';
import moment from 'moment';

const { Search } = Input;

const SamplingList = ({ loading }) => {
  const [form] = Form.useForm();
  const { user } = getLoggedInUser();

  const { allSamples } = useSelector((state) => state.stocks);
  const [allsamples, setAllsamples] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [search, setSearch] = useState('');
  console.log(allsamples);
  const dispatch = useDispatch();
  useEffect(() => {
    handleGetAllSampleDetails();
  }, [page, search]);

  const handleGetAllSampleDetails = () => {
    const payload = { all: true, page: page, search: search };
    dispatch(getAllSample(payload)).then((response) => {
      if (response?.payload?.success) {
        setAllsamples(response?.payload?.data);
        setPagination(response?.payload?.pagination);
      }
    });
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  console.log(allSamples);
  const columns = [
    {
      title: 'Warehouse Container',
      dataIndex: 'warehouse_container',
      key: 'warehouse_container',
      ellipsis: true,
      width: '150px',
      render: (_, record) => {
        return (
          (record?.status !== 'approved' && user?.level == 3 ? (
            <a href={`/sampling?id=${record?.id}`}>
              {record?.container?.number}
            </a>
          ) : (
            record?.container?.number
          )) || 'NA'
        );
      }
    },
    // {
    //   title: 'AR Number',
    //   dataIndex: 'ar_number',
    //   key: 'ar_number',
    //   ellipsis: true,
    //   width: '100px'
    // },
    {
      title: 'Material Code',
      dataIndex: 'material_code',
      key: 'material_code',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.container?.batch_detail?.material?.code
          ? record?.container?.batch_detail?.material?.code
          : 'NA';
      }
    },
    {
      title: 'Material Name',
      dataIndex: 'inhouse_batch_number',
      key: 'inhouse_batch_number',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.container?.batch_detail?.material?.name
          ? record?.container?.batch_detail?.material?.name
          : 'NA';
      }
    },
    {
      title: 'Batch Number',
      dataIndex: 'inhouse_batch_number',
      key: 'inhouse_batch_number',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.container?.batch_detail?.inhouse_batch_number
          ? record?.container?.batch_detail?.inhouse_batch_number
          : 'NA';
      }
    },
    {
      title: 'Quantity',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.quantity
          ? `${parseFloat(
              record?.uom == `No.'s`
                ? record?.quantity
                : record?.quantity * 1000
            ).toFixed(3)} ${record?.uom || ''}`
          : 'NA';
      }
    },
    {
      title: 'Sampling Date',
      dataIndex: 'sampling_date',
      key: 'sampling_date',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.sampling_date
          ? moment(record?.sampling_date).format('DD MMM YYYY')
          : 'NA';
      }
    }
  ];

  return (
    <>
      <Layout sider={false}>
        <Form form={form} layout="vertical">
          <Card
            title={<span>Sampling Details</span>}
            className={`${styles.cardStyles_approver}`}
            extra={
              <Space>
                <Search
                  onChange={(e) => handleChange(e)}
                  className={`w-100 ${styles.searchbar}`}
                  justify="start"
                  align="start"
                  placeholder="Search by Batch Number"
                />
                <CustomButton
                  className={styles.inwardButton}
                  onClick={() => history.push('/sampling')}
                  type="primary"
                >
                  Create
                </CustomButton>
              </Space>
            }
          >
            <div className={`${styles.table_container}`}>
              <CustomTable
                columns={columns}
                pagination={{
                  size: 'small',
                  total: pagination?.total_records || 0,
                  showSizeChanger: false,
                  onChange: (page) => {
                    setPage(page);
                  }
                }}
                dataSource={allsamples}
                loading={loading}
              />
            </div>
          </Card>
          <br />
          <FormItem className="text-center">
            <CustomButton
              className={styles.inwardButton}
              onClick={() => history.back()}
              type="primary"
            >
              Back to Home
            </CustomButton>
          </FormItem>
        </Form>
      </Layout>
    </>
  );
};
export default SamplingList;
