import React, { useEffect } from 'react';
import { Form, Card, Row, Col, Input, message, Space } from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import Layout from '@/common/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getAllWastage, updateWastage } from '../../redux/slice';
import { history } from 'app/history';

const { TextArea } = Input;
const WastageApproved = () => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const { allWastage } = useSelector((state) => state.wastage);
  console.log(allWastage);
  const searchParam = useLocation().search;
  const searchParams = new URLSearchParams(searchParam);

  useEffect(() => {
    dispatch(getAllWastage({ id: searchParams.get('id') }));
  }, []);

  const handleUpdate = (value) => {
    const payload = { id: searchParams.get('id'), status: value };
    dispatch(updateWastage(payload)).then((response) => {
      if (response?.payload?.success) {
        message.success(response?.payload?.message);
        history.push('/dashboard');
      }
    });
  };

  return (
    <Layout sider={false}>
      <Form form={form} layout="vertical">
        <Card
          title={<span>UPDATE WASTAGE/LEAKAGE DETAILS</span>}
          className={`${styles.cardStyles_approver}`}
        >
          <Row gutter={24}>
            <Col xs={10} xl={12} span={6} md={10} sm={10}>
              <FormItem
                label="Warehouse Containers"
                rules={[
                  {
                    required: false,
                    message: 'Enter warehouse containers'
                  }
                ]}
                className={styles.customLable}
              >
                <Input value={allWastage?.container?.number} disabled />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Material Code"
                rules={[
                  {
                    required: true,
                    message: 'Select Material Code'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  disabled
                  value={allWastage?.container?.batch_detail?.material?.code}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Material Name"
                rules={[
                  {
                    required: false,
                    message: 'Select Material Code'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  disabled
                  value={allWastage?.container?.batch_detail?.material?.name}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Batch Number"
                rules={[
                  {
                    required: false,
                    message: 'Select Material Code'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  disabled
                  value={
                    allWastage?.container?.batch_detail?.inhouse_batch_number
                  }
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Received Date"
                rules={[
                  {
                    required: false,
                    message: 'Select Material Code'
                  }
                ]}
                className={styles.customLable}
              >
                <Input disabled value={allWastage?.received_date} />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Quantity"
                rules={[
                  {
                    required: false,
                    message: 'Select Material Code'
                  }
                ]}
                className={styles.customLable}
              >
                <Input disabled value={allWastage?.quantity} />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="UOM"
                rules={[
                  {
                    required: false,
                    message: 'Select Material Code'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  disabled
                  value={allWastage?.container?.batch_detail?.material?.uom}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Reason"
                rules={[
                  {
                    required: false,
                    message: 'Select Material Code'
                  }
                ]}
                className={styles.customLable}
              >
                <Input value={allWastage?.wastage_reason?.name} disabled />
              </FormItem>
            </Col>
            <Col xs={24} xl={24} span={12} md={24} sm={24}>
              <FormItem
                label="Comments"
                rules={[
                  {
                    required: false,
                    message: 'Select Material Code'
                  }
                ]}
                className={styles.customLable}
              >
                <TextArea placeholder="Enter Comments" />
              </FormItem>
            </Col>
          </Row>
          <br />
          <FormItem className="text-center">
            <Space>
              <CustomButton
                className={styles.inwardButton}
                onClick={() => handleUpdate('rejected')}
                ghost
                type="primary"
              >
                Reject
              </CustomButton>
              <CustomButton
                className={styles.inwardButton}
                onClick={() => handleUpdate('approved')}
                type="primary"
              >
                Approve
              </CustomButton>
            </Space>
          </FormItem>
        </Card>
      </Form>
    </Layout>
  );
};
export default WastageApproved;
