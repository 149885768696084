import React, { useEffect, useState } from 'react';
import Layout from '@/common/Layout';
import {
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Space,
  message,
  Select
} from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import CustomButton from '@/common/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { createColdChamber, getColdChamber } from '../../redux/slice';
import { history } from 'app/history';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

const cleaningRecordofColdChamberCreate = () => {
  const { returnableData } = useSelector((state) => state.return);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  // const [fields, setFields] = useState([
  //   { id: 1, month: null, date: null, cle: null, remarks: null }
  // ]);
  const [dropdownGroups, setDropdownGroups] = useState([{ id: 1 }]);

  useEffect(() => {
    if (id) {
      handleGetAllReturnGatepass();
    }
  }, [id]);

  useEffect(() => {
    if (id && returnableData) {
      const recordData = returnableData[0];

      if (recordData) {
        form.setFieldsValue({
          month: recordData.month
        });
      }
      if (Array.isArray(recordData.cleaning_record_secs)) {
        const cleaningRecordSecs = recordData.cleaning_record_secs.map(
          (record, index) => ({
            /*id: record.id*/ id: index + 1,
            date: moment(record.date, 'YYYY-MM-DD'),
            cle: record.cle,
            remarks: record.remarks
          })
        );

        setDropdownGroups(cleaningRecordSecs);

        cleaningRecordSecs.forEach((field, index) => {
          form.setFieldsValue({
            [`id_${index + 1}`]: field.id,
            [`date_${index + 1}`]: field.date,
            [`cle_${index + 1}`]: field.cle,
            [`remarks_${index + 1}`]: field.remarks
          });
        });
      }
      setIsEditMode(true);
    }
  }, [id, returnableData]);

  // const handleMonthChange = (value, id) => {
  //   setFields(
  //     fields.map((field) =>
  //       field.id === id ? { ...field, month: value } : field
  //     )
  //   );
  // };

  const handleGetAllReturnGatepass = () => {
    let payload = {
      id: id || ''
    };
    dispatch(getColdChamber(payload));
  };

  const onFinish = (values) => {
    const cleaning_record_secs = dropdownGroups.map((group) => ({
      //id:values[`id_${group.id}`]|| null,
      date: values[`date_${group.id}`]?.format('YYYY-MM-DD'),
      cle: values[`cle_${group.id}`],
      remarks: values[`remarks_${group.id}`]
    }));

    const payload = {
      month: values.month,
      cleaning_record_secs
    };

    console.log('Payload:', payload);
    dispatch(createColdChamber(payload)).then((response) => {
      if (response?.payload?.success) {
        message.success(response?.payload?.message);
        history.push('log-books-cold-chamber-list');
      } else {
        message.error(response.payload?.error || 'Submission failed');
      }
    });
  };
  const handleAddDropdown = () => {
    setDropdownGroups([
      ...dropdownGroups,
      { id: dropdownGroups.length + 1, date: '', cle: '', remarks: '' }
    ]);
  };

  return (
    <Layout sider={false}>
      <Card
        title="Cleaning Record of Cold Chamber"
        className={`${styles.cardStyles_approver}`}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={styles.panelborder}
        >
          <Row gutter={16} style={{ marginBottom: '16px' }}>
            <Col span={7}>
              <FormItem
                label="Month"
                name="month"
                className={styles.customLabel}
                style={{ marginBottom: 0 }}
              >
                <Select placeholder="Select Month" style={{ width: '100%' }}>
                  {[
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                  ].map((month) => (
                    <Select.Option value={month} key={month}>
                      {month}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col
              span={3}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {!isEditMode && (
                <PlusCircleOutlined
                  onClick={handleAddDropdown}
                  style={{
                    fontSize: '24px',
                    color: '#e80e94',
                    cursor: 'pointer'
                  }}
                />
              )}
            </Col>
          </Row>

          {dropdownGroups.map((group) => (
            <Row gutter={16} style={{ marginBottom: '16px' }} key={group.id}>
              <Col span={8} style={{ marginTop: '24px' }}>
                <FormItem
                  label="Date"
                  name={`date_${group.id}`}
                  className={styles.customLable}
                >
                  <DatePicker style={{ width: '100%' }} />
                </FormItem>
              </Col>
              <Col span={8} style={{ marginTop: '24px' }}>
                <FormItem
                  label="Cleaned by"
                  name={`cle_${group.id}`}
                  className={styles.customLable}
                >
                  <Input />
                </FormItem>
              </Col>
              <Col span={8} style={{ marginTop: '24px' }}>
                <FormItem
                  label="Verified by (User Id & Date)"
                  name={`sec_${group.id}`}
                  className={styles.customLable}
                >
                  <Input disabled />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem
                  label="Remarks"
                  name={`remarks_${group.id}`}
                  className={styles.customLable}
                >
                  <Input placeholder="Enter Remarks" />
                </FormItem>
              </Col>
            </Row>
          ))}

          <FormItem className="text-center">
            <Space>
              <CustomButton
                className={styles.inwardButton}
                htmlType="submit"
                type="primary"
              >
                Submit
              </CustomButton>
            </Space>
          </FormItem>
        </Form>
      </Card>
    </Layout>
  );
};

export default cleaningRecordofColdChamberCreate;
