import React, { useEffect } from 'react';
import Layout from '@/common/Layout';
import { Row, Col, Form, Card, Input, InputNumber, message } from 'antd';
import styles from '@/common/Layout/index.module.less';
import { getDispenseMaterialDetails } from '@/features/dispensing/redux/slice';
import FormItem from '@/common/FormItem';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useState } from 'react';
import CustomButton from '@/common/CustomButton';
import { DeleteOutlined, PlusCircleFilled } from '@ant-design/icons';
import { createSifting } from '../../redux/slice';
import { history } from 'app/history';

const SiftingandMilling = () => {
  const { materialDetails } = useSelector((state) => state.dispensing);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const params = useParams();
  const [totalNet, setTotalNet] = useState(0);
  const [totalLeftNet, setTotalLeftNet] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [calculationShow, setCalculationShow] = useState(false);
  const [lots, setLots] = useState([
    {
      weights: [
        {
          container: '',
          tare_weight: '',
          net_weight: '',
          gross_weight: '',
          lot: 1
        }
      ]
    },
    {
      weights: [
        {
          container: '',
          tare_weight: '',
          net_weight: '',
          gross_weight: '',
          lot: 2
        }
      ]
    },
    {
      weights: [
        {
          container: '',
          tare_weight: '',
          net_weight: '',
          gross_weight: '',
          lot: 3
        }
      ]
    },
    {
      weights: [
        {
          container: '',
          tare_weight: '',
          net_weight: '',
          gross_weight: '',
          lot: 4
        }
      ]
    }
  ]);
  const [leftovers, setLeftovers] = useState([
    {
      weights: [
        {
          container: '',
          tare_weight: '',
          net_weight: '',
          gross_weight: '',
          lot: 1,
          is_left_over: true
        }
      ]
    },
    {
      weights: [
        {
          container: '',
          tare_weight: '',
          net_weight: '',
          gross_weight: '',
          lot: 2,
          is_left_over: true
        }
      ]
    },
    {
      weights: [
        {
          container: '',
          tare_weight: '',
          net_weight: '',
          gross_weight: '',
          lot: 3,
          is_left_over: true
        }
      ]
    },
    {
      weights: [
        {
          container: '',
          tare_weight: '',
          net_weight: '',
          gross_weight: '',
          lot: 4,
          is_left_over: true
        }
      ]
    }
  ]);

  useEffect(() => {
    handleGetAllMaterials();
  }, []);

  const handleGetAllMaterials = () => {
    let payload = {
      id: params.id
    };
    dispatch(getDispenseMaterialDetails(payload));
  };

  const handleAddWeight = (i, index) => {
    let temp_lots = [...lots];
    temp_lots[index].weights.push({
      container: '',
      tare_weight: '',
      net_weight: '',
      gross_weight: '',
      lot: index + 1
    });
    setLots(temp_lots);
  };

  const handleremoveWeight = (i, index) => {
    let temp_lots = [...lots];
    temp_lots[index].weights.splice(i, 1);
    setLots(temp_lots);
  };

  const handleAddLeftWeight = (i, index) => {
    let temp_leftovers = [...leftovers];
    temp_leftovers[index].weights.push({
      container: '',
      tare_weight: '',
      net_weight: '',
      gross_weight: '',
      is_left_over: true,
      lot: index + 1
    });
    setLeftovers(temp_leftovers);
  };

  const handleremoveLeftWeight = (i, index) => {
    let temp_leftovers = [...leftovers];
    temp_leftovers[index].weights.splice(i, 1);
    setLots(temp_leftovers);
  };

  const handleChange = (type, i, index, e) => {
    let temp_lots = [...lots];
    console.log(type, e);
    if (type === 'tare_weight') {
      temp_lots[index].weights[i]['gross_weight'] =
        e + (temp_lots[index].weights[i]['net_weight'] || 0);
    } else if (type === 'net_weight') {
      temp_lots[index].weights[i]['gross_weight'] = (
        e + (temp_lots[index].weights[i]['tare_weight'] || 0)
      ).toFixed(3);
    }
    if (type == 'tare_weight' || type == 'net_weight') {
      temp_lots[index].weights[i][type] = e;
    } else {
      temp_lots[index].weights[i][type] = e.target.value;
    }
    setLots(temp_lots);
    var add = 0;
    lots.map((item) => {
      item?.weights.map((data) => {
        add += data?.net_weight !== '' ? data?.net_weight : 0;
      });
    });
    console.log(add);
    setTotalNet(add);
  };

  const handleLeftoverChange = (type, i, index, e) => {
    let temp_lots = [...leftovers];
    console.log(type, e);
    if (type === 'tare_weight') {
      temp_lots[index].weights[i]['gross_weight'] =
        e + (temp_lots[index].weights[i]['net_weight'] || 0);
    } else if (type === 'net_weight') {
      temp_lots[index].weights[i]['gross_weight'] = (
        e + (temp_lots[index].weights[i]['tare_weight'] || 0)
      ).toFixed(3);
    }
    if (type == 'tare_weight' || type == 'net_weight') {
      temp_lots[index].weights[i][type] = e;
    } else {
      temp_lots[index].weights[i][type] = e.target.value;
    }
    setLeftovers(temp_lots);
    var add = 0;
    leftovers.map((item) => {
      item?.weights.map((data) => {
        add += data?.net_weight !== '' ? data?.net_weight : 0;
      });
    });
    console.log(add);
    setTotalLeftNet(add);
  };

  const handleYield = () => {
    setCalculationShow(true);
    const calculation =
      (parseFloat(totalNet) /
        parseFloat(materialDetails?.product_detail?.theoretical_yield)) *
      100;
    setPercentage(calculation.toFixed(3));
  };

  const onFinish = () => {
    var shift = [];
    var shift_leftover = [];
    console.log(lots);
    lots.map((item) => {
      item?.weights.map((data) => {
        shift.push(data);
      });
    });
    leftovers.map((item) => {
      item?.weights.map((data) => {
        shift_leftover.push(data);
      });
    });

    const payload = {
      dispensing_request_id: params.id,
      shift: shift,
      shift_leftover: shift_leftover,
      total_net_weight: totalNet,
      total_net_leftover: totalLeftNet,
      theoretical_yield:
        materialDetails?.yield_sifted_milleds?.[0]?.theoretical_yield,
      actual_yield: totalNet,
      percent_actual_yield: percentage
    };
    console.log(payload);
    dispatch(createSifting(payload)).then((response) => {
      if (response?.payload?.success) {
        message.success(response?.payload.message);
        history.push(`/extra-granulation/${params.id}`);
      }
    });
  };
  return (
    <Layout sider={false}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
      >
        <Card
          title={
            <span style={{ fontWeight: 'bold' }}>
              Sifting and Milling Process
            </span>
          }
          className={styles.CustomPanel}
        >
          <Card>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem label="Product Name" className={styles.customLable}>
                  <Input
                    disabled
                    value={materialDetails?.product_detail?.name || 'NA'}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="Product Batch Number"
                  className={styles.customLable}
                >
                  <Input
                    disabled
                    value={materialDetails?.product_batch_number || 'NA'}
                  />
                </FormItem>
              </Col>

              <Col span={12}>
                <FormItem label="Effective Date" className={styles.customLable}>
                  <Input
                    disabled
                    value={
                      materialDetails?.effective_date
                        ? moment(materialDetails?.effective_date).format(
                            'DD MMM YYYY'
                          )
                        : 'NA'
                    }
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="Batch Size" className={styles.customLable}>
                  <Input
                    disabled
                    value={materialDetails?.product_detail?.batch_size || 'NA'}
                  />
                </FormItem>
              </Col>
            </Row>
          </Card>
          <br />
          <p style={{ fontSize: '12px', color: '#c91761' }}>
            Weigh and record the sifted & milled material details bellow
          </p>
          {lots?.map((item, index) => {
            return (
              <Card key={index} title={`Lot ${index + 1} `}>
                {item?.weights?.map((data, i) => {
                  return (
                    <Row gutter={24} key={i}>
                      <Col xs={24} xl={4} span={24} md={24} sm={24}>
                        <FormItem
                          label="Container #"
                          rules={[
                            {
                              required: false,
                              message: 'Enter Container #'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Input
                            placeholder="Enter Container ID"
                            value={data?.container || ''}
                            onChange={(e) =>
                              handleChange('container', i, index, e)
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={4} span={24} md={24} sm={24}>
                        <FormItem
                          label="Tare Weight"
                          rules={[
                            {
                              required: false,
                              message: 'Enter Tare Weight'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <InputNumber
                            style={{ width: '100%' }}
                            min={0}
                            placeholder="Enter Tare Weight"
                            value={data?.tare_weight || ''}
                            onChange={(e) =>
                              handleChange('tare_weight', i, index, e)
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={2} span={24} md={24} sm={24}>
                        <FormItem
                          label="UOM"
                          rules={[
                            {
                              required: false,
                              message: 'Enter UOM'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Input
                            placeholder="UOM"
                            value={lots[0].weights[0].uom || ''}
                            onChange={(e) => handleChange('uom', i, index, e)}
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={4} span={24} md={24} sm={24}>
                        <FormItem
                          label="Net Weight"
                          rules={[
                            {
                              required: false,
                              message: 'Enter Net Weight'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <InputNumber
                            style={{ width: '100%' }}
                            min={0}
                            placeholder="Enter Net Weight"
                            value={data?.net_weight || ''}
                            onChange={(e) =>
                              handleChange('net_weight', i, index, e)
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={2} span={24} md={24} sm={24}>
                        <FormItem
                          label="UOM"
                          rules={[
                            {
                              required: false,
                              message: 'Enter UOM'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Input
                            placeholder="UOM"
                            value={lots[0].weights[0].uom || ''}
                            disabled
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={4} span={24} md={24} sm={24}>
                        <FormItem
                          label="Gross Weight"
                          rules={[
                            {
                              required: false,
                              message: 'Enter Gross Weight'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Input
                            placeholder="Enter Gross Weight"
                            value={data?.gross_weight || ''}
                            onChange={(e) =>
                              handleChange('gross_weight', i, index, e)
                            }
                            disabled
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={2} span={24} md={24} sm={24}>
                        <FormItem
                          label="UOM"
                          rules={[
                            {
                              required: false,
                              message: 'Enter UOM'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Input
                            placeholder="UOM"
                            value={lots[0].weights[0].uom}
                            disabled
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={2} span={24} md={24} sm={24}>
                        <FormItem
                          label=""
                          rules={[
                            {
                              required: false,
                              message: 'Enter UOM'
                            }
                          ]}
                          className={styles.customLableButton}
                        >
                          {i == 0 ? (
                            <CustomButton
                              onClick={() => handleAddWeight(i, index)}
                            >
                              <PlusCircleFilled />
                            </CustomButton>
                          ) : (
                            ''
                          )}
                          {i > 0 ? (
                            <CustomButton
                              onClick={() => handleremoveWeight(i, index)}
                            >
                              <DeleteOutlined />
                            </CustomButton>
                          ) : (
                            ''
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  );
                })}
              </Card>
            );
          })}
          <br />
          <p style={{ fontSize: '12px', color: '#c91761' }}>
            Left-Over Materials & Rejects
          </p>
          {leftovers?.map((item, index) => {
            return (
              <Card key={index} title={`Lot ${index + 1} `}>
                {item?.weights?.map((data, i) => {
                  return (
                    <Row gutter={24} key={i}>
                      <Col xs={24} xl={4} span={24} md={24} sm={24}>
                        <FormItem
                          label="Container #"
                          rules={[
                            {
                              required: false,
                              message: 'Enter Container #'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Input
                            placeholder="Enter Container ID"
                            value={data?.container || ''}
                            onChange={(e) =>
                              handleLeftoverChange('container', i, index, e)
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={4} span={24} md={24} sm={24}>
                        <FormItem
                          label="Tare Weight"
                          rules={[
                            {
                              required: false,
                              message: 'Enter Tare Weight'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <InputNumber
                            style={{ width: '100%' }}
                            min={0}
                            placeholder="Enter Tare Weight"
                            value={data?.tare_weight || ''}
                            onChange={(e) =>
                              handleLeftoverChange('tare_weight', i, index, e)
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={2} span={24} md={24} sm={24}>
                        <FormItem
                          label="UOM"
                          rules={[
                            {
                              required: false,
                              message: 'Enter UOM'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Input
                            placeholder="UOM"
                            value={lots[0].weights[0].uom || ''}
                            onChange={(e) =>
                              handleLeftoverChange('uom', i, index, e)
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={4} span={24} md={24} sm={24}>
                        <FormItem
                          label="Net Weight"
                          rules={[
                            {
                              required: false,
                              message: 'Enter Net Weight'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <InputNumber
                            style={{ width: '100%' }}
                            min={0}
                            placeholder="Enter Net Weight"
                            value={data?.net_weight || ''}
                            onChange={(e) =>
                              handleLeftoverChange('net_weight', i, index, e)
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={2} span={24} md={24} sm={24}>
                        <FormItem
                          label="UOM"
                          rules={[
                            {
                              required: false,
                              message: 'Enter UOM'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Input
                            placeholder="UOM"
                            value={lots[0].weights[0].uom || ''}
                            disabled
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={4} span={24} md={24} sm={24}>
                        <FormItem
                          label="Gross Weight"
                          rules={[
                            {
                              required: false,
                              message: 'Enter Gross Weight'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Input
                            placeholder="Enter Gross Weight"
                            value={data?.gross_weight || ''}
                            onChange={(e) =>
                              handleLeftoverChange('gross_weight', i, index, e)
                            }
                            disabled
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={2} span={24} md={24} sm={24}>
                        <FormItem
                          label="UOM"
                          rules={[
                            {
                              required: false,
                              message: 'Enter UOM'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Input
                            placeholder="UOM"
                            value={lots[0].weights[0].uom}
                            disabled
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={2} span={24} md={24} sm={24}>
                        <FormItem
                          label=""
                          rules={[
                            {
                              required: false,
                              message: 'Enter UOM'
                            }
                          ]}
                          className={styles.customLableButton}
                        >
                          {i == 0 ? (
                            <CustomButton
                              onClick={() => handleAddLeftWeight(i, index)}
                            >
                              <PlusCircleFilled />
                            </CustomButton>
                          ) : (
                            ''
                          )}
                          {i > 0 ? (
                            <CustomButton
                              onClick={() => handleremoveLeftWeight(i, index)}
                            >
                              <DeleteOutlined />
                            </CustomButton>
                          ) : (
                            ''
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  );
                })}
              </Card>
            );
          })}
          <Card>
            <Row gutter={24}>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Total Net Weight"
                  rules={[
                    {
                      required: false,
                      message: 'Enter Total Net Weight'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Total Net Weight"
                    value={totalNet}
                    disabled
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Total Left-Over material Net Weight"
                  rules={[
                    {
                      required: false,
                      message: 'Enter Total Net Weight'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Total Left-Over material Net Weight"
                    value={totalLeftNet}
                    disabled
                  />
                </FormItem>
              </Col>
            </Row>
          </Card>

          <br />
          <FormItem className="text-center">
            <CustomButton
              className={styles.inwardButton}
              type="primary"
              ghost
              onClick={() => handleYield()}
            >
              Calculate Yield
            </CustomButton>
          </FormItem>
          {calculationShow ? (
            <>
              <p style={{ fontSize: '12px', color: '#c91761' }}>
                Yield of Sifted & Milled Granules
              </p>
              <Card>
                <Row gutter={24}>
                  <Col xs={24} xl={8} span={24} md={24} sm={24}>
                    <FormItem
                      label="Theoretical Yield"
                      rules={[
                        {
                          required: false,
                          message: 'Enter Theoretical'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Total Theoretical Yield"
                        value={
                          materialDetails?.product_detail?.theoretical_yield
                        }
                        disabled
                      />
                    </FormItem>
                  </Col>

                  <Col xs={24} xl={8} span={24} md={24} sm={24}>
                    <FormItem
                      label="Actual Yield"
                      rules={[
                        {
                          required: false,
                          message: 'Enter Actual Yield'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Actual Yield"
                        value={totalNet}
                        disabled
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={8} span={24} md={24} sm={24}>
                    <FormItem
                      label="% of actual yield (Y)=(B/A * 100)"
                      rules={[
                        {
                          required: false,
                          message: '% of actual yield '
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="% of actual yield "
                        value={percentage}
                        disabled
                      />
                    </FormItem>
                  </Col>
                </Row>
              </Card>
            </>
          ) : (
            ''
          )}
        </Card>
        <br />
        {calculationShow ? (
          <FormItem className="text-center">
            <CustomButton
              className={styles.inwardButton}
              type="primary"
              htmlType="submit"
            >
              Proceed
            </CustomButton>
          </FormItem>
        ) : (
          ''
        )}
      </Form>
    </Layout>
  );
};
export default SiftingandMilling;
