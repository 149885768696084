import React, { useState, useEffect, useCallback } from 'react';
import { Form, Card, Space, Popconfirm, Switch, Input, Avatar } from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomTable from 'common/Content/CustomTable';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers, deleteUser, getAllDesignations } from '../../redux/slice';
import { EditOutlined } from '@ant-design/icons';
import CustomModal from '@/common/CustomModal';
import { roles } from 'utilities/helpers';

const AdminPage = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { allUsers, pagination, getAllMaterilLoading, allDisignations } =
    useSelector((state) => state.admin);

  const { Search } = Input;

  const [page, setPage] = useState(1);
  const [showDisablePopup, setShowDisablePopup] = useState(false);
  const [search, setSearch] = useState('');
  const [role, setRole] = useState('');
  const [level, setLevel] = useState('');
  const [isModal, setIsModal] = useState(false);

  const handleModel = (role, level) => {
    setRole(role);
    setLevel(level);
    setIsModal(true);
  };

  console.log(level, role, isModal);
  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    handleGetAllUsers();
    handleGetAllDesignations();
  }, [page, search]);

  const handleGetAllDesignations = () => {
    const payload = {};
    dispatch(getAllDesignations(payload));
  };

  const designation = allDisignations;
  console.log(designation);
  const transactions = [
    {
      name: 'MRN Generation',
      permission:
        role == 'user' && level == 2 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
          </Space>
        ) : role == 'user' && level == 3 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
            <Avatar
              style={{
                backgroundColor: '#FFBF00',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              E
            </Avatar>
          </Space>
        ) : (
          'NA'
        )
    },
    {
      name: 'Approval for Missing Document',
      permission:
        role == 'qc_initiator' && level == 2 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
          </Space>
        ) : role == 'qc_initiator' && level == 3 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
            <Avatar
              style={{
                backgroundColor: '#FFBF00',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              E
            </Avatar>
          </Space>
        ) : (
          'NA'
        )
    },
    {
      name: 'Approval for Storage Inspection Failure',
      permission:
        role == 'qc_initiator' && level == 2 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
          </Space>
        ) : role == 'qc_initiator' && level == 3 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
            <Avatar
              style={{
                backgroundColor: '#FFBF00',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              E
            </Avatar>
          </Space>
        ) : (
          'NA'
        )
    },
    {
      name: 'Material Damages Creation',
      permission:
        role == 'user' && level == 2 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
          </Space>
        ) : role == 'user' && level == 3 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
            <Avatar
              style={{
                backgroundColor: '#FFBF00',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              E
            </Avatar>
          </Space>
        ) : (
          'NA'
        )
    },
    {
      name: 'Approval for Damages Reported',
      permission:
        role == 'qc_initiator' && level == 2 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
          </Space>
        ) : role == 'qc_initiator' && level == 3 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
            <Avatar
              style={{
                backgroundColor: '#FFBF00',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              E
            </Avatar>
          </Space>
        ) : (
          'NA'
        )
    },
    {
      name: 'Weight Descrepency Creation',
      permission:
        role == 'user' && level == 2 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
          </Space>
        ) : role == 'user' && level == 3 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
            <Avatar
              style={{
                backgroundColor: '#FFBF00',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              E
            </Avatar>
          </Space>
        ) : (
          'NA'
        )
    },
    {
      name: 'Approval for Descrepency Raised',
      permission:
        role == 'qc_initiator' && level == 2 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
          </Space>
        ) : role == 'qc_initiator' && level == 3 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
            <Avatar
              style={{
                backgroundColor: '#FFBF00',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              E
            </Avatar>
          </Space>
        ) : (
          'NA'
        )
    },
    {
      name: 'Update Net Weights',
      permission:
        role == 'user' && level == 2 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
          </Space>
        ) : role == 'user' && level == 3 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
            <Avatar
              style={{
                backgroundColor: '#FFBF00',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              E
            </Avatar>
          </Space>
        ) : (
          'NA'
        )
    },
    {
      name: 'Update Gross Weights',
      permission:
        role == 'user' && level == 2 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
          </Space>
        ) : role == 'user' && level == 3 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
            <Avatar
              style={{
                backgroundColor: '#FFBF00',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              E
            </Avatar>
          </Space>
        ) : (
          'NA'
        )
    },
    {
      name: 'Update Bin Positions',
      permission:
        role == 'user' && level == 2 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
          </Space>
        ) : role == 'user' && level == 3 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
            <Avatar
              style={{
                backgroundColor: '#FFBF00',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              E
            </Avatar>
          </Space>
        ) : (
          'NA'
        )
    },
    {
      name: 'Dispensing Request(BMR Creation)',
      permission:
        role == 'prod_user' && level == 2 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
          </Space>
        ) : role == 'prod_user' && level == 3 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
            <Avatar
              style={{
                backgroundColor: '#FFBF00',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              E
            </Avatar>
          </Space>
        ) : (
          'NA'
        )
    },
    {
      name: 'Dispensing Sequensing',
      permission:
        role == 'prod_user' && level == 2 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
          </Space>
        ) : role == 'prod_user' && level == 3 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
            <Avatar
              style={{
                backgroundColor: '#FFBF00',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              E
            </Avatar>
          </Space>
        ) : role == 'ppic_user' && level == 2 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
          </Space>
        ) : (
          'NA'
        )
    },
    {
      name: 'Approval for LC Dispense Material',
      permission:
        role == 'qc_initiator' && level == 2 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
          </Space>
        ) : role == 'qc_initiator' && level == 3 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
          </Space>
        ) : (
          'NA'
        )
    },
    {
      name: 'Dispensing Completion',
      permission:
        role == 'user' && level == 2 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
          </Space>
        ) : role == 'user' && level == 3 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
            <Avatar
              style={{
                backgroundColor: '#FFBF00',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              E
            </Avatar>
          </Space>
        ) : (
          'NA'
        )
    },
    {
      name: 'LC Creation for Sampling',
      permission:
        role == 'qa_initiator' && level == 2 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
          </Space>
        ) : role == 'qa_initiator' && level == 3 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
            <Avatar
              style={{
                backgroundColor: '#FFBF00',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              E
            </Avatar>
          </Space>
        ) : (
          'NA'
        )
    },
    {
      name: 'Approval for LC Sampling Material',
      permission:
        role == 'qc_initiator' && level == 2 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
          </Space>
        ) : role == 'qc_initiator' && level == 3 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
          </Space>
        ) : (
          'NA'
        )
    },
    {
      name: 'Sampling Completion',
      permission:
        role == 'qa_initiator' && level == 2 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
          </Space>
        ) : role == 'qa_initiator' && level == 3 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
          </Space>
        ) : (
          'NA'
        )
    },
    {
      name: 'Update QC Material Status',
      permission:
        role == 'qa_initiator' && level == 2 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
          </Space>
        ) : role == 'qa_initiator' && level == 3 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
          </Space>
        ) : (
          'NA'
        )
    },
    {
      name: 'Wastage/Leakage Creation(After MRN Creation)',
      permission:
        role == 'user' && level == 2 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
          </Space>
        ) : role == 'user' && level == 3 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
            <Avatar
              style={{
                backgroundColor: '#FFBF00',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              E
            </Avatar>
          </Space>
        ) : (
          'NA'
        )
    },
    {
      name: 'Approval for Wastage Material',
      permission:
        role == 'qc_initiator' && level == 2 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
          </Space>
        ) : role == 'qc_initiator' && level == 3 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
          </Space>
        ) : (
          'NA'
        )
    },
    {
      name: 'Stock Reconciliation Update',
      permission:
        role == 'user' && level == 2 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
          </Space>
        ) : role == 'user' && level == 3 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
            <Avatar
              style={{
                backgroundColor: '#FFBF00',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              E
            </Avatar>
          </Space>
        ) : (
          'NA'
        )
    },
    {
      name: 'Approval for Stock Reconciliation',
      permission:
        role == 'qc_initiator' && level == 2 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
          </Space>
        ) : role == 'qc_initiator' && level == 3 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
          </Space>
        ) : (
          'NA'
        )
    },
    {
      name: 'Stok Card Report',
      permission:
        role !== 'admin' ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
          </Space>
        ) : (
          'NA'
        )
    },
    {
      name: 'User Management (IT Dept)',
      permission:
        role == 'admin' ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
            <Avatar
              style={{
                backgroundColor: '#FFBF00',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              E
            </Avatar>
            ,
            <Avatar
              style={{
                backgroundColor: '#dc3545',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              D
            </Avatar>
          </Space>
        ) : (
          'NA'
        )
    },
    {
      name: 'Data Management (IT Dept)',
      permission:
        role == 'admin' ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
            <Avatar
              style={{
                backgroundColor: 'Green',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              C
            </Avatar>
            <Avatar
              style={{
                backgroundColor: '#FFBF00',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              E
            </Avatar>
            ,
            <Avatar
              style={{
                backgroundColor: '#dc3545',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              D
            </Avatar>
          </Space>
        ) : (
          'NA'
        )
    },
    {
      name: 'Audit Trail Report',
      permission:
        role !== 'admin' && level == 3 ? (
          <Space>
            <Avatar
              style={{
                backgroundColor: 'blue',
                verticalAlign: 'middle'
              }}
              size="small"
            >
              V
            </Avatar>
          </Space>
        ) : (
          'NA'
        )
    }
  ];
  const handleGetAllUsers = () => {
    const payload = { page, search };
    dispatch(getAllUsers(payload));
  };

  const handleDeleteUser = (payload) => {
    dispatch(deleteUser(payload)).then(() => {
      handleGetAllUsers();
      setShowDisablePopup(false);
    });
  };
  const visibility = useCallback((record) => {
    return showDisablePopup[record.id];
  }, showDisablePopup);

  const handleClose = () => {
    setIsModal(false);
  };

  const column1 = [
    {
      title: 'Transactions',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: '100px',
      render: (text) => {
        return <b style={{ fontSize: '12px' }}>{text}</b>;
      }
    },
    {
      title: `Permissions`,
      dataIndex: 'permission',
      key: 'permission',
      ellipsis: true,
      width: '100px'
    }
  ];
  const columns = [
    {
      title: 'Name',
      dataIndex: 'first_name',
      key: 'first_name',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'Employee ID',
      dataIndex: 'employee_id',
      key: 'employee_id',
      ellipsis: true,
      width: '150px'
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email'
      // ellipsis: true,
      // width: '100px'
    },
    {
      title: 'Designation',
      dataIndex: 'designation_id',
      key: 'designation_id',
      // ellipsis: true,
      // width: '100px',
      render: (_, record) => {
        // return record?.role?.replace(/[^a-zA-Z0-9 ]/g, ' ').toUpperCase();
        const des = designation.filter(
          (designation) => designation.id == record?.designation_id
        );
        return des[0]?.designation || 'NA';
      }
    },
    {
      title: 'Permissions',
      dataIndex: 'material_code',
      key: 'material_code',
      render: (_, record) => {
        return (
          <p
            onClick={() => handleModel(record?.role, record?.level)}
            style={{ color: '#e80e94', cursor: 'pointer' }}
          >
            View Matrix
          </p>
        );
        // if (record.level == 1) {
        //   return 'View';
        // } else if (record.level == 2) {
        //   return 'View,Create';
        // } else if (record.level == 3) {
        //   return 'View, Create, Edit';
        // }
      }
    },
    {
      title: 'Action',
      dataIndex: 'inhouse_batch_number',
      key: 'inhouse_batch_number',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return (
          <Space>
            <a href={`user-creation?id=${record.id}`}>
              <EditOutlined />
            </a>
            <Popconfirm
              title={`Are you sure do you want to ${
                record.is_active ? 'Deactivate' : 'Activate'
              } this User?`}
              visible={visibility(record)}
              key={record.id}
              onConfirm={(e) => {
                e.stopPropagation();
                handleDeleteUser({
                  id: record.id,
                  is_active: !record?.is_active
                });
              }}
              onCancel={(e) => {
                e.stopPropagation();
                setShowDisablePopup({ [record.id]: false });
              }}
              okText={record.is_active ? 'Deactivate' : 'Activate'}
              cancelText="No"
              okButtonProps={{
                loading: getAllMaterilLoading,
                type: record.is_active ? 'danger' : 'primary'
              }}
            >
              {/* <DeleteOutlined
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDisablePopup({ [record.id]: true });
                }}
                style={{ color: '#e80e94' }}
              /> */}
              <Switch size="small" checked={record.is_active} />
            </Popconfirm>
          </Space>
        );
      }
    }
  ];

  return (
    <>
      <Form form={form} layout="vertical">
        <Card
          title={<span>User Details</span>}
          className={`${styles.cardStyles_approver}`}
          extra={
            <Space>
              <Search
                onChange={(e) => handleChange(e)}
                className={`w-100 ${styles.searchbar}`}
                justify="start"
                align="start"
                placeholder="Search by Emp ID or Name"
              />
              <CustomButton
                className={styles.inwardButton}
                onClick={() => history.push('/user-creation')}
                type="primary"
              >
                Create User
              </CustomButton>
            </Space>
          }
        >
          <div className={`${styles.table_container}`}>
            <CustomTable
              columns={columns}
              dataSource={allUsers}
              pagination={{
                size: 'small',
                total: pagination?.total_records || 0,
                showSizeChanger: false,
                onChange: (page) => {
                  setPage(page);
                }
              }}
            />
          </div>
        </Card>
        {isModal ? (
          <CustomModal
            title="Permission Matrix"
            visible={isModal}
            width="50%"
            footer={null}
            className={styles.customModal}
            closable={false}
          >
            <p style={{ color: '#f536a5' }}>
              Role: <b>{roles(role, level)}</b> , Level: <b>{level}</b>
            </p>
            <div className={`${styles.table_row}`}>
              <CustomTable
                columns={column1}
                dataSource={transactions}
                pagination={false}
              />
            </div>
            <br />
            <FormItem className="text-center">
              <CustomButton
                className={styles.inwardButton}
                onClick={handleClose}
                type="primary"
                size="small"
              >
                Close
              </CustomButton>
            </FormItem>
          </CustomModal>
        ) : (
          ''
        )}
        <br />
        <FormItem className="text-center">
          <CustomButton
            className={styles.inwardButton}
            onClick={() => history.back()}
            type="primary"
          >
            Back to Home
          </CustomButton>
        </FormItem>
      </Form>
    </>
  );
};
export default AdminPage;
