import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  createReturns,
  getAllreturn,
  getAllnonreturn,
  createnonReturns,
  createReturnsregister,
  getAllReturnRegister,
  createnonReturnsregister,
  getAllnonReturnRegister,
  createcoldchamber,
  getcoldchamber,
  createstretchwrapper,
  getstretchwrapper,
  createprep,
  getprep
} from './api';

const initialState = {
  returnableData: [],
  nonreturnableData: [],
  pagination: []
};
const actions = {
  CREATE_RETURNABLE_GATE_PASS: 'materials/CREATE_RETURNABLE_GATE_PASS',
  GET_ALL_RETURN_GATEPASS: 'materials/GET_ALL_RETURN_GATEPASS',
  CREATE_NON_RETURNABLE_GATE_PASS: 'materials/CREATE_NON_RETURNABLE_GATE_PASS',
  GET_ALL_NON_RETURN_GATEPASS: 'materials/GET_ALL_NON_RETURN_GATEPASS',
  CREATERETURNABLEGATEPASSREGISTER:
    'materials/CREATERETURNABLEGATEPASSREGISTER',
  GETALLRETURNABLEGATEPASSREGISTER:
    'materials/GETALLRETURNABLEGATEPASSREGISTER',
  CREATENONRETURNABLEGATEPASSREGISTER:
    'materials/CREATENONRETURNABLEGATEPASSREGISTER',
  GETALLNONRETURNABLEGATEPASSREGISTER:
    'materials/GETALLNONRETURNABLEGATEPASSREGISTER',
  CREATECOLDCHAMBER: 'materials/CREATECOLDCHAMBER',
  GETCOLDCHAMBER: 'materials/GETCOLDCHAMBER',
  CREATESTRETCHWRAPPER: 'materials/CREATESTRETCHWRAPPER',
  GETSTRETCHWRAPPER: 'materials/GETSTRETCHWRAPPER',
  CREATEPREP: 'materials/CREATEPREP',
  GETPREP: 'materials/GETPREP'
};
export const createReturnablGP = createAsyncThunk(
  actions.CREATE_RETURNABLE_GATE_PASS,
  async (payload) => {
    const response = await createReturns('create', payload);

    return response;
  }
);

export const createPrep = createAsyncThunk(
  actions.CREATEPREP,
  async (payload) => {
    const response = await createprep('create', payload);

    return response;
  }
);

export const createColdChamber = createAsyncThunk(
  actions.CREATECOLDCHAMBER,
  async (payload) => {
    const response = await createcoldchamber('create', payload);

    return response;
  }
);

export const createStretchWrapper = createAsyncThunk(
  actions.CREATESTRETCHWRAPPER,
  async (payload) => {
    const response = await createstretchwrapper('create', payload);

    return response;
  }
);

export const createReturnablGPRegister = createAsyncThunk(
  actions.CREATERETURNABLEGATEPASSREGISTER,
  async (payload) => {
    const response = await createReturnsregister('create', payload);

    return response;
  }
);

export const getAllReturnGP = createAsyncThunk(
  actions.GET_ALL_RETURN_GATEPASS,
  async (payload) => {
    const response = await getAllreturn('get', payload);

    return response;
  }
);

export const getColdChamber = createAsyncThunk(
  actions.GETCOLDCHAMBER,
  async (payload) => {
    const response = await getcoldchamber('get', payload);

    return response;
  }
);

export const getPrep = createAsyncThunk(actions.GETPREP, async (payload) => {
  const response = await getprep('get', payload);

  return response;
});

export const getStretchWrapper = createAsyncThunk(
  actions.GETSTRETCHWRAPPER,
  async (payload) => {
    const response = await getstretchwrapper('get', payload);

    return response;
  }
);

export const getAllReturnGPRegister = createAsyncThunk(
  actions.GETALLRETURNABLEGATEPASSREGISTER,
  async (payload) => {
    const response = await getAllReturnRegister('get', payload);

    return response;
  }
);

export const createnonReturnablGPRegister = createAsyncThunk(
  actions.CREATENONRETURNABLEGATEPASSREGISTER,
  async (payload) => {
    const response = await createnonReturnsregister('create', payload);

    return response;
  }
);

export const getAllnonReturnGPRegister = createAsyncThunk(
  actions.GETALLNONRETURNABLEGATEPASSREGISTER,
  async (payload) => {
    const response = await getAllnonReturnRegister('get', payload);

    return response;
  }
);

export const createnonReturnablGP = createAsyncThunk(
  actions.CREATE_NON_RETURNABLE_GATE_PASS,
  async (payload) => {
    const response = await createnonReturns('create', payload);

    return response;
  }
);

export const getAllNonReturnGP = createAsyncThunk(
  actions.GET_ALL_NON_RETURN_GATEPASS,
  async (payload) => {
    const response = await getAllnonreturn('get', payload);

    return response;
  }
);

export const logbooksSlice = createSlice({
  name: 'logbooks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createReturnablGP.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createReturnablGP.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createReturnablGP.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllReturnGP.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllReturnGP.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;
        const { success, message: msg, data, pagination } = action.payload;
        if (success) {
          state.returnableData = data;
          state.pagination = pagination;
        } else {
          message.error(msg);
        }
      })
      .addCase(getAllReturnGP.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllNonReturnGP.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllNonReturnGP.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;
        const { success, message: msg, data, pagination } = action.payload;
        if (success) {
          state.nonreturnableData = data;
          state.pagination = pagination;
        } else {
          message.error(msg);
        }
      })
      .addCase(getAllNonReturnGP.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createnonReturnablGP.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createnonReturnablGP.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;
        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createnonReturnablGP.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createPrep.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createPrep.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;
        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createPrep.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createColdChamber.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createColdChamber.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createColdChamber.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createReturnablGPRegister.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createReturnablGPRegister.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createReturnablGPRegister.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createStretchWrapper.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createStretchWrapper.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createStretchWrapper.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getStretchWrapper.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getStretchWrapper.fulfilled, (state, action) => {
        // state.getAllMaterilLoading = false;
        state.getAllMaterilLoading = false;
        const { success, message: msg, data, pagination } = action.payload;
        if (success) {
          state.returnableData = data;
          state.pagination = pagination;
        } else {
          message.error(msg);
        }
      })

      .addCase(getStretchWrapper.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getPrep.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getPrep.fulfilled, (state, action) => {
        // state.getAllMaterilLoading = false;
        state.getAllMaterilLoading = false;
        const { success, message: msg, data, pagination } = action.payload;
        if (success) {
          state.returnableData = data;
          state.pagination = pagination;
        } else {
          message.error(msg);
        }
      })

      .addCase(getPrep.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllReturnGPRegister.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllReturnGPRegister.fulfilled, (state, action) => {
        // state.getAllMaterilLoading = false;
        state.getAllMaterilLoading = false;
        const { success, message: msg, data, pagination } = action.payload;
        if (success) {
          state.returnableData = data;
          state.pagination = pagination;
        } else {
          message.error(msg);
        }
      })

      .addCase(getAllReturnGPRegister.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getColdChamber.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getColdChamber.fulfilled, (state, action) => {
        // state.getAllMaterilLoading = false;
        state.getAllMaterilLoading = false;
        const { success, message: msg, data, pagination } = action.payload;
        if (success) {
          state.returnableData = data;
          state.pagination = pagination;
        } else {
          message.error(msg);
        }
      })

      .addCase(getColdChamber.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createnonReturnablGPRegister.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createnonReturnablGPRegister.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createnonReturnablGPRegister.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllnonReturnGPRegister.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllnonReturnGPRegister.fulfilled, (state, action) => {
        // state.getAllMaterilLoading = false;
        state.getAllMaterilLoading = false;
        const { success, message: msg, data, pagination } = action.payload;
        if (success) {
          state.nonreturnableData = data;
          state.pagination = pagination;
        } else {
          message.error(msg);
        }
      })

      .addCase(getAllnonReturnGPRegister.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
  }
});
export default logbooksSlice.reducer;
