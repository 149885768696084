import apiClient from 'utilities/apiClient';

export const create_checklist = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.lc}/${url}`, payload, true);
};

export const dispenseMaterialDetails = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.dispense}/${url}`, payload, true);
};
export const Dispensematerials = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.dispenseMaterialList}/${url}`,
    payload,
    true
  );
};

export const lc = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.lc}/${url}`, payload, true);
};

export const get_compaign_batches = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.lc}/${url}`, payload, true);
};
