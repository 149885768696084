import React, { useState, useEffect } from 'react';
import {
  Form,
  Card,
  Select,
  Row,
  Col,
  Input,
  InputNumber,
  message,
  Image
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from './index.module.less';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllContainers,
  createWastage,
  getPastContainerDetails,
  getAllWastage,
  updateWastage,
  getWastageReasons
} from '../../redux/slice';
import CustomTable from 'common/Content/CustomTable';
import moment from 'moment';
import CustomButton from '@/common/CustomButton';
import { QrcodeOutlined, EditOutlined } from '@ant-design/icons';
import { QrReader } from 'react-qr-reader';
import { history } from 'app/history';
import { useLocation } from 'react-router-dom';
import { getLoggedInUser, getRoles } from 'utilities/helpers';
import truck from '@/features/generateMrn/components/images/truck-delivery-done.gif';

const { TextArea } = Input;
const WastageMaterialsPage = () => {
  const [form] = Form.useForm();

  const { allMaterials } = useSelector((state) => state.stocks);
  const { user } = getLoggedInUser();

  const searchParam = useLocation().search;
  const searchParams = new URLSearchParams(searchParam);

  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  // const [code, setCode] = useState('');
  const [containers, setContainers] = useState([]);
  const [openCam, setOpenCam] = useState(false);
  const [containerDetails, setContainerDetails] = useState(false);
  const [pastData, setPastData] = useState([]);
  const [containerChange, setContainerChange] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [truckShow, setTruckShow] = useState(false);
  const [reasons, setReasons] = useState([]);

  console.log(setContainerDetails);
  const { Superadmindata } = getRoles('Warehouse');
  // console.log(setCode);
  console.log(containerDetails);
  useEffect(() => {
    getwastageReasons();
    dispatch(getAllWastage({ id: searchParams.get('id') })).then((response) => {
      if (response?.payload?.success) {
        setContainerDetails([
          {
            batch_detail: response?.payload?.data?.container?.batch_detail,
            createdAt: response?.payload?.data?.createdAt
          }
        ]);
        form.setFieldsValue({
          warehouse_containers: response?.payload?.data?.container?.number,
          reason: response?.payload?.data?.wastage_reason?.id,
          quantity: response?.payload?.data?.quantity
        });
      }
    });
  }, []);

  const getwastageReasons = () => {
    dispatch(getWastageReasons()).then((res) => {
      if (res?.payload?.success) {
        setReasons(res?.payload?.data);
      }
    });
  };
  const onChange = (value) => {
    console.log(value);

    const details = containers.filter(
      (containers) => containers.number === value
    );
    setContainerDetails(details);
    setContainerChange(true);
    const payload = { container_id: value };
    dispatch(getPastContainerDetails(payload)).then((response) => {
      if (response?.payload?.success) {
        setPastData(response?.payload?.data);
      }
    });
  };
  console.log(allMaterials);
  const onSearch = (value) => {
    setSearch(value);
    let payload = { search: search };
    search.length > 5
      ? dispatch(getAllContainers(payload)).then((response) => {
          if (response?.payload?.success) {
            setContainers(response?.payload?.data);
          }
        })
      : '';
  };
  const handleQrScan = async (scanData) => {
    console.log(`loaded data data`, scanData);
    if (scanData && scanData !== '') {
      const details = containers.filter(
        (containers) =>
          containers.number ===
          scanData.text.slice(scanData.text.lastIndexOf('/') + 1)
      );

      setContainerDetails(details);
      form.setFieldsValue({
        warehouse_containers: scanData.text.slice(
          scanData.text.lastIndexOf('/') + 1
        )
      });
      setContainerChange(true);
      // const payload = { container_id: details[0]?.batch_detail?.id };
      const payload = {
        container_id: scanData.text.slice(scanData.text.lastIndexOf('/') + 1)
      };
      dispatch(getPastContainerDetails(payload)).then((response) => {
        if (response?.payload?.success) {
          setPastData(response?.payload?.data);
        }
      });
      setOpenCam(false);
    }
  };
  const handleError = (err) => {
    console.error(err);
  };

  const onFinish = (values) => {
    console.log(containerDetails, containerChange);
    setTruckShow(true);
    if (containerChange && user?.level !== 3) {
      const payload = {
        container_id: containerDetails[0]?.id,
        batch_details_id: containerDetails[0]?.batch_detail?.id,
        quantity: values?.quantity,
        received_date: containerDetails[0]?.createdAt,
        ar_number: values?.ar_number,
        reason_id: values?.reason
      };
      dispatch(createWastage(payload)).then((response) => {
        if (response?.payload?.success) {
          setTruckShow(false);
          message.success(response?.payload?.message);
          history.push('/wastage-reconsilation');
        }
      });
    } else if (
      (user?.level == 3 || Superadmindata?.[0]?.level == 3) &&
      searchParams.get('id')
    ) {
      const payload = {
        container_id: containerDetails[0]?.id,
        batch_details_id: containerDetails[0]?.batch_detail?.id,
        quantity: values?.quantity,
        received_date: containerDetails[0]?.createdAt,
        reason_id: values?.reason,
        id: searchParams.get('id'),
        notes: values.comments
      };
      dispatch(updateWastage(payload)).then((response) => {
        if (response?.payload?.success) {
          setTruckShow(false);
          message.success(response?.payload?.message);
          history.push('/wastage-reconsilation');
        }
      });
    } else if (
      !searchParams.get('id') &&
      (user?.level == 3 || Superadmindata?.[0]?.level == 3)
    ) {
      const payload = {
        container_id: containerDetails[0]?.id,
        batch_details_id: containerDetails[0]?.batch_detail?.id,
        quantity: values?.quantity,
        received_date: containerDetails[0]?.createdAt,
        ar_number: values?.ar_number,
        reason_id: values?.reason
      };
      dispatch(createWastage(payload)).then((response) => {
        if (response?.payload?.success) {
          setTruckShow(false);
          message.success(response?.payload?.message);
          history.push('/wastage-reconsilation');
        }
      });
    }
  };

  const columns = [
    {
      title: 'Batch Number',
      dataIndex: 'inhouse_batch_number',
      key: 'inhouse_batch_number',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.container?.batch_detail?.inhouse_batch_number || 'NA';
      }
    },
    {
      title: 'Date',
      dataIndex: 'mfg_date',
      key: 'mfg_date',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.container?.batch_detail?.mfg_date
          ? moment(record?.container?.batch_detail?.mfg_date).format(
              'DD MMM YYYY'
            )
          : 'NA';
      }
    },
    {
      title: 'Container Number',
      dataIndex: 'inhouse_batch_number',
      key: 'inhouse_batch_number',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.container?.number ? record?.container?.number : 'NA';
      }
    },
    {
      title: 'Quantity',
      dataIndex: 'inhouse_batch_number',
      key: 'inhouse_batch_number',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.quantity || 'NA';
      }
    }
  ];
  console.log(user?.level, 'llll');
  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Card
        title={<span>UPDATE WASTAGE/LEAKAGE DETAILS</span>}
        className={`${styles.cardStyles_approver}`}
        extra={
          (user?.level == 3 || Superadmindata?.[0]?.level == 3) &&
          searchParams.get('id') ? (
            <CustomButton onClick={() => setIsEdit(false)}>
              <EditOutlined />
            </CustomButton>
          ) : (
            ''
          )
        }
      >
        <Row gutter={24}>
          <Col xs={2} xl={2} span={2} md={2} sm={2}>
            <CustomButton
              onClick={() => setOpenCam(true)}
              className={styles.qrcode}
            >
              <QrcodeOutlined onClick={() => setOpenCam(true)} />
            </CustomButton>
          </Col>
          <Col xs={10} xl={10} span={6} md={10} sm={10}>
            <FormItem
              name="warehouse_containers"
              label="Warehouse Containers"
              rules={[
                {
                  required: false,
                  message: 'Enter warehouse containers'
                }
              ]}
              className={styles.customLable}
            >
              <Select
                showSearch
                placeholder="Scan or select container"
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                disabled={
                  ((user?.level == 3 || Superadmindata?.[0]?.level == 3) &&
                    searchParams.get('id')) ||
                  ((user?.level == 2 || Superadmindata?.[0]?.level == 2) &&
                    searchParams.get('id'))
                    ? isEdit
                    : false
                }
              >
                {containers?.map((item, index) => {
                  return (
                    <option value={item.number} key={index}>
                      {item.number}
                    </option>
                  );
                })}
              </Select>
            </FormItem>
          </Col>
          <Col xs={24} xl={12} span={12} md={24} sm={24}>
            <FormItem
              label="Material Code"
              rules={[
                {
                  required: true,
                  message: 'Select Material Code'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                disabled
                value={
                  user?.level == 3 || Superadmindata?.[0]?.level == 3
                    ? containerDetails[0]?.batch_detail?.material?.code
                    : containerDetails[0]?.batch_detail?.material?.code || 'NA'
                }
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={12} span={12} md={24} sm={24}>
            <FormItem
              label="Material Name"
              rules={[
                {
                  required: false,
                  message: 'Select Material Code'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                value={
                  user?.level == 3 || Superadmindata?.[0]?.level == 3
                    ? containerDetails[0]?.batch_detail?.material?.name
                    : `${
                        containerDetails[0]?.batch_detail?.material?.name ||
                        'NA'
                      }`
                }
                disabled
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={12} span={12} md={24} sm={24}>
            <FormItem
              label="Batch Number"
              rules={[
                {
                  required: false,
                  message: 'Select Material Code'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                value={
                  user?.level == 3 || Superadmindata?.[0]?.level == 3
                    ? containerDetails[0]?.batch_detail?.inhouse_batch_number
                    : containerDetails[0]?.batch_detail?.inhouse_batch_number ||
                      'NA'
                }
                disabled
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={12} span={12} md={24} sm={24}>
            <FormItem
              label="Received Date"
              rules={[
                {
                  required: false,
                  message: 'Select Material Code'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                value={
                  containerDetails[0]?.createdAt
                    ? moment(containerDetails[0]?.createdAt).format(
                        'DD MMM YYYY'
                      )
                    : 'NA'
                }
                disabled
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={12} span={12} md={24} sm={24}>
            <FormItem
              label="Quantity"
              name="quantity"
              rules={[
                {
                  required: true,
                  message: 'Select Material Code'
                }
              ]}
              className={styles.customLable}
            >
              <InputNumber
                min={0}
                style={{ width: '100%' }}
                placeholder="Enter Quantity"
                disabled={
                  ((user?.level == 3 || Superadmindata?.[0]?.level == 3) &&
                    searchParams.get('id')) ||
                  ((user?.level == 2 || Superadmindata?.[0]?.level == 2) &&
                    searchParams.get('id'))
                    ? isEdit
                    : false
                }
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={12} span={12} md={24} sm={24}>
            <FormItem
              label="UOM"
              rules={[
                {
                  required: false,
                  message: 'Select Material Code'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                value={containerDetails[0]?.batch_detail?.material?.uom || 'NA'}
                disabled
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={12} span={12} md={24} sm={24}>
            <FormItem
              label="Reason"
              name="reason"
              rules={[
                {
                  required: true,
                  message: 'Select Reason'
                }
              ]}
              className={styles.customLable}
            >
              <Select
                disabled={
                  ((user?.level == 3 || Superadmindata?.[0]?.level == 3) &&
                    searchParams.get('id')) ||
                  ((user?.level == 2 || Superadmindata?.[0]?.level == 2) &&
                    searchParams.get('id'))
                    ? isEdit
                    : false
                }
              >
                {reasons?.map((reason, i) => {
                  return (
                    <option value={reason?.id} key={i}>
                      {reason?.name}
                    </option>
                  );
                })}
              </Select>
            </FormItem>
          </Col>
          {!isEdit ? (
            user?.level == 3 || Superadmindata?.[0]?.level == 3 ? (
              <Col xs={24} xl={24} span={12} md={24} sm={24}>
                <FormItem
                  label="Reason for Change"
                  name="comments"
                  rules={[
                    {
                      required: false,
                      message: 'Select Material Code'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <TextArea placeholder="Enter Reason for Change" />
                </FormItem>
              </Col>
            ) : (
              ''
            )
          ) : (
            ''
          )}
        </Row>
        {!isEdit ? (
          <>
            <br />
            <FormItem className="text-center">
              <CustomButton
                className={styles.inwardButton}
                htmlType="submit"
                type="primary"
              >
                Update
              </CustomButton>
            </FormItem>
          </>
        ) : ((user?.level == 1 || Superadmindata?.[0]?.level == 1) &&
            !searchParams.get('id')) ||
          ((user?.level == 2 || Superadmindata?.[0]?.level == 2) &&
            !searchParams.get('id')) ||
          ((user?.level == 3 || Superadmindata?.[0]?.level == 3) &&
            !searchParams.get('id')) ? (
          <FormItem className="text-center">
            {truckShow ? (
              <Image
                src={truck}
                preview={false}
                style={{ width: '100px !important', height: '100px' }}
              />
            ) : (
              <CustomButton
                className={styles.inwardButton}
                htmlType="submit"
                type="primary"
              >
                Submit
              </CustomButton>
            )}
          </FormItem>
        ) : (
          ''
        )}
      </Card>
      {openCam ? (
        <QrReader
          constraints={{ facingMode: 'environment' }}
          delay={1000}
          onError={handleError}
          onResult={(result) => handleQrScan(result)}
          // chooseDeviceId={()=>selected}
          style={{ width: '3px', height: '3px' }}
        />
      ) : (
        ''
      )}
      <br />
      <Card
        title={<span>Wastage/Leakage Details</span>}
        className={`${styles.cardStyles_approver}`}
      >
        <div className={`${styles.table_container}`}>
          <CustomTable
            columns={columns}
            pagination={false}
            scroll={{ x: 'auto' }}
            dataSource={pastData}
          />
        </div>
      </Card>
      <br />
      <FormItem className="text-center">
        <CustomButton
          className={styles.inwardButton}
          onClick={() => history.back()}
          type="primary"
        >
          Back to Home
        </CustomButton>
      </FormItem>
    </Form>
  );
};
export default WastageMaterialsPage;
