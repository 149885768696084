import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { materials, create, updateReturnStatus } from './api';

const initialState = {
  allMaterials: []
};
const actions = {
  GET_ALL_MATERIALS: 'returns/GET_ALL_MATERIALS',
  GET_ALL_CODES: 'returns/GET_ALL_CODES',
  CREATE_RETURN_MATERIALS: 'returns/CREATE_RETURN_MATERIALS',
  GET_ALL_PRODUCTS_LIST: 'returns/GET_ALL_PRODUCTS_LIST',
  UPDATE_STATUS: 'returns/UPDATE_STATUS'
};

export const getAllMaterialslist = createAsyncThunk(
  actions.GET_ALL_MATERIALS,
  async (payload) => {
    const response = await materials('returnedqty_list', payload);

    return response;
  }
);

export const getAllCodes = createAsyncThunk(
  actions.GET_ALL_CODES,
  async (payload) => {
    const response = await materials('get_materials_codes', payload);

    return response;
  }
);

export const createReturnMaterials = createAsyncThunk(
  actions.CREATE_RETURN_MATERIALS,
  async (payload) => {
    const response = await create('create_returnedqty', payload);

    return response;
  }
);

export const getAllProductsList = createAsyncThunk(
  actions.GET_ALL_PRODUCTS_LIST,
  async (payload) => {
    const response = await materials('get_product_batch', payload);

    return response;
  }
);

export const updateStatus = createAsyncThunk(
  actions.UPDATE_STATUS,
  async (payload) => {
    const response = await updateReturnStatus('returnedqty_status', payload);

    return response;
  }
);

export const stocksSlice = createSlice({
  name: 'returns',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMaterialslist.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllMaterialslist.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success } = action.payload;
        if (!success) {
          state.allMaterials = [];
        }
      })
      .addCase(getAllMaterialslist.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllCodes.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllCodes.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success } = action.payload;
        if (!success) {
          state.allMaterials = [];
        }
      })
      .addCase(getAllCodes.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createReturnMaterials.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createReturnMaterials.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success } = action.payload;
        if (!success) {
          state.allMaterials = [];
        }
      })
      .addCase(createReturnMaterials.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllProductsList.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllProductsList.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, pagination } = action.payload;
        if (success) {
          state.allMaterials = data;
          state.pagination = pagination || null;
        } else {
          state.allMaterials = [];
        }
      })
      .addCase(getAllProductsList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(updateStatus.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(updateStatus.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success } = action.payload;
        if (!success) {
          state.allMaterials = [];
        }
      })
      .addCase(updateStatus.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
  }
});

export default stocksSlice.reducer;
