import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { stocks } from './api';

const initialState = {
  allStocks: [],
  pagination: {}
};

const actions = {
  GET_ALL_RECONSILIATIONS: 'reconsiliations/GET_ALL_RECONSILIATIONS'
};

export const getAllReconsiliations = createAsyncThunk(
  actions.GET_ALL_MATERIALS,
  async (payload) => {
    const response = await stocks('get-all', payload);

    return response;
  }
);

export const reconsiliationsSlice = createSlice({
  name: 'reconsiliations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllReconsiliations.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllReconsiliations.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, pagination } = action.payload;
        if (success) {
          state.allStocks = data;
          state.pagination = pagination || null;
        } else {
          state.allStocks = [];
        }
      })
      .addCase(getAllReconsiliations.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
  }
});
export default reconsiliationsSlice.reducer;
