import React, { useState, useEffect } from 'react';
import {
  Row,
  Form,
  Card,
  Space,
  Col,
  Tag,
  Select,
  Input,
  Radio,
  Collapse,
  Badge,
  Checkbox,
  DatePicker,
  Divider,
  message
  // Slider
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import { useDispatch } from 'react-redux';
import CustomModal from '@/common/CustomModal';
// import { DeleteOutlined, PlusCircleFilled } from '@ant-design/icons';
// import truck from '@/features/qa/components/images/truck-delivery-done.gif';
import Layout from '@/common/Layout';
import {
  getAllParamsList,
  getboms,
  createInstruction,
  getAccessories
} from '../../redux/slice';
import { useParams } from 'react-router-dom';
import { PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ReactQuill from 'react-quill';

const { TextArea } = Input;
const { Panel } = Collapse;

const ConfigProcess = () => {
  const [form] = Form.useForm();
  const params = useParams();

  const [processList, setProcessList] = useState([]);
  const [paramsList, setParamsList] = useState([]);
  // const [process, setProcess] = useState([]);
  const [isModal, setIsModal] = useState({
    isOpen: { ['id']: false }
  });
  const [parameters, setParameters] = useState([]);
  const [accessories, setAccessories] = useState([]);
  const [paramgroup, setParamgroup] = useState();
  const [showModal, setShowModal] = useState(false);
  const [instruction, setInstruction] = useState([{ instructions: '' }]);
  const [instructiongroup, setInstructiongroup] = useState();
  // const [typeofValue, setTypeofValue] = useState('');
  const [modalid, setModalid] = useState('');
  const [subcatgries, setSubcatgries] = useState([]);
  const [accessoriModal, setAccessoriModal] = useState({
    isOpen: { ['id']: false }
  });
  const [isInstructions, setIsInstructions] = useState({
    isOpen: { ['id']: false, ['second_id']: '' }
  });

  const dispatch = useDispatch();

  useEffect(() => {
    // handleGetAllProcessList();
    handleGetAllBoms();
    handleGetAllParameters();
  }, []);

  console.log(parameters, paramgroup, paramsList);

  const handleGetAllParameters = () => {
    dispatch(getAllParamsList()).then((res) => {
      if (res?.payload?.success) {
        setParamsList(res?.payload?.data);
      }
    });
  };

  const handleGetAllBoms = () => {
    const payload = { id: params.id, type: 'configure' };
    dispatch(getboms(payload)).then((res) => {
      if (res?.payload?.success) {
        res?.payload?.data?.map((item) => {
          item?.process_configs?.map((data) => {
            data.instructions = [];

            data.instructions.push({
              eqp_id: '',
              instruction: '',
              execute_bmr: []
            });
            data?.process_equipment_config?.map((params) => {
              params?.process_eqp_ids.map((eqpids) => {
                eqpids.parameters = [];
                eqpids.accessories = [];

                eqpids.parameters.push({
                  parameter_id: '',
                  sub_param_id: '',
                  type_of_value: '',
                  low: '',
                  high: '',
                  absolute_value: '',
                  uom: '',
                  instructions: [{ instrction: '' }]
                });

                eqpids.accessories.push({
                  parameter_id: '',
                  sub_param_id: '',
                  type_of_value: '',
                  low: '',
                  high: '',
                  absolute_value: '',
                  uom: '',
                  instructions: [{ instrction: '' }]
                });
              });
            });
          });
        });
        setProcessList(res?.payload?.data);
      }
    });
  };

  //   const onChange = (e) => {
  //     console.log(`checked = ${e.target.checked}`);
  //   };
  console.log(setParameters, subcatgries);
  const onFinish = () => {
    let config_materials = [];
    processList?.map((item) => {
      item?.process_configs?.map((materials) => {
        config_materials.push({
          process_master: [
            {
              process_config_id: materials?.id,
              instructions: materials?.instructions,
              equipment_masters: materials?.process_equipment_config
            }
          ]
        });
      });
    });
    const payload = { product_id: params.id, process_group: config_materials };
    // console.log(payload);
    dispatch(createInstruction(payload)).then((res) => {
      if (res?.payload?.success) {
        history.push(`/bmr-params-view/${params.id}`);
      }
    });
  };

  const handleClose = () => {
    // setIsModal(false);
    setIsModal({
      isOpen: {
        ['id']: false
      }
    });
  };

  const handleClosemodal = () => {
    // setAccessoriModal(false);
    setAccessoriModal({
      isOpen: {
        ['id']: false
      }
    });
    setIsInstructions({
      isOpen: {
        ['id']: false
      }
    });
  };

  const handleChangeParameters = (index, i, mm, nn, j, e, type, process) => {
    console.log(index, i, mm, j, e, type, process);
    console.log(type);
    let sub_catgries = accessories?.[0]?.accessory_masters?.filter(
      (data) => data.id == e
    );
    setSubcatgries(sub_catgries);
    let list = [...processList];
    if (process == 'accessories') {
      list[index].process_configs[i].process_equipment_config[
        mm
      ].process_eqp_ids[nn].accessories[j][type] = e;
    } else {
      list[index].process_configs[i].process_equipment_config[
        mm
      ].process_eqp_ids[nn].parameters[j][type] = e;
    }

    setProcessList(list);
    // setParameters({ ...parameters, [type]: e });
    // if (type == 'parameter') {
    //   const payload = { id: e };
    //   dispatch(getAccessories(payload)).then((res) => {
    //     if (res?.payload?.success) {
    //       setAccessories(res?.payload?.data);
    //     }
    //   });
    // }

    // console.log(parameters);
  };

  const handleAdd = (index, i, mm, nn, process) => {
    console.log(processList);

    let list = [...processList];
    if (process == 'accessories') {
      list[index].process_configs[i].process_equipment_config[
        mm
      ].process_eqp_ids[nn].finalaccessoriesparams = true;
    } else {
      list[index].process_configs[i].process_equipment_config[
        mm
      ].process_eqp_ids[nn].finalparams = true;
    }
    setProcessList(list);

    setIsModal({
      isOpen: {
        ['id']: false
      }
    });
    setAccessoriModal({
      isOpen: {
        ['id']: false
      }
    });
    // setInstruction([{ instructions: '' }]);
    console.log(processList);
  };

  const handleSubmit = () => {
    console.log(processList);
    let procoesses = [];
    procoesses.push(instruction);
    let list = [...processList];
    if (
      !processList[instructiongroup?.group_id].process_configs[
        instructiongroup?.process_id
      ]['instructions']
    ) {
      processList[instructiongroup?.group_id].process_configs[
        instructiongroup?.process_id
      ]['instructions'] = [];
    }

    list[instructiongroup?.group_id].process_configs[
      instructiongroup?.process_id
    ].instructions.push(procoesses);
    setProcessList(list);
    setShowModal(false);
    setInstruction([{ instructions: '' }]);
    console.log(procoesses);
  };

  const handleModal = (index, i, k, l, id) => {
    console.log(index, i, k, id);
    const payload = { id };
    dispatch(getAccessories(payload)).then((res) => {
      if (res?.payload?.success) {
        setAccessories(res?.payload?.data);
      }
    });

    console.log(processList);
    console.log(k);
    setIsModal({
      isOpen: {
        ['id']: i
      }
    });
    // setIsModal(true);
    console.log(id);
    setModalid({ main: k, sub: l });
    setParamgroup({ group_id: index, process_id: i });
  };

  const handleacceModal = (index, i, k, l, id) => {
    const payload = { id };
    dispatch(getAccessories(payload)).then((res) => {
      if (res?.payload?.success) {
        setAccessories(res?.payload?.data);
      }
    });

    console.log(processList);
    console.log(k);
    setAccessoriModal({
      isOpen: {
        ['id']: i
      }
    });
    // setAccessoriModal(true);
    console.log(id);
    // setModalid(k);
    setModalid({ main: k, sub: l });
    setParamgroup({ group_id: index, process_id: i });
  };

  // const handleInstructionModal = (index, i) => {
  //   setShowModal(true);
  //   setInstructiongroup({ group_id: index, process_id: i });
  // };
  console.log(setInstructiongroup);
  const handleInstructions = (index, i, mm, n, e) => {
    // setInstruction([...instruction, { instructions: '' }]);
    console.log(mm, n, e);
    let list = [...processList];
    list[index].process_configs[i].instructions.push({
      eqp_id: '',
      instruction: '',
      execute_bmr: []
    });

    setProcessList(list);
  };

  const ChangeInstruction = (index, i, n, e, type) => {
    console.log(e);
    // instruction[index].instructions = e.target.value;
    let list = [...processList];
    // if (process == 'accessories') {
    //   list[index].process_configs[i].process_equipment_config[mm].accessories[
    //     j
    //   ].instructions[n].instrction = e;
    // } else {
    type == 'instruction'
      ? (list[index].process_configs[i].instructions[n].instruction = e)
      : type == 'eqp_id'
      ? (list[index].process_configs[i].instructions[n].eqp_id = e)
      : type == 'is_param'
      ? (list[index].process_configs[i].instructions[n].is_param = e)
      : type == 'is_fbb_exist'
      ? (list[index].process_configs[i].instructions[n].is_fbb_exist = e)
      : type == 'is_container_exist'
      ? (list[index].process_configs[i].instructions[n].is_container_exist = e)
      : (list[index].process_configs[i].instructions[n].execute_bmr = e);
    // }

    setProcessList(list);
  };

  const AddmoreParams = (index, i, n, e) => {
    console.log(e);
    let list = [...processList];
    list[index].process_configs[i].instructions[n].execute_bmr.push(e);

    setProcessList(list);
  };

  const handleSetType = (e, index, i, modalid, nn, j, process) => {
    console.log(e.target.value);
    let list = [...processList];
    if (process == 'accessories') {
      list[index].process_configs[i].process_equipment_config[
        modalid
      ].process_eqp_ids[nn].accessories[j].type_of_value = e.target.value;
    } else {
      list[index].process_configs[i].process_equipment_config[
        modalid
      ].process_eqp_ids[nn].parameters[j].type_of_value = e.target.value;
    }
    setProcessList(list);
    // setTypeofValue(e.target.value);
  };

  const handleAddMultiParams = (index, i, modalid, nn, j, process) => {
    console.log(j);
    let list = [...processList];
    if (process == 'accessories') {
      list[index].process_configs[i].process_equipment_config[
        modalid
      ].process_eqp_ids[nn]?.accessories.push({
        parameter_id: '',
        sub_param_id: '',
        type_of_value: '',
        low: '',
        high: '',
        absolute_value: '',
        uom: '',
        instructions: [{ instrction: '' }]
      });
    } else {
      list[index].process_configs[i].process_equipment_config[
        modalid
      ].process_eqp_ids[nn]?.parameters.push({
        parameter_id: '',
        sub_param_id: '',
        type_of_value: '',
        low: '',
        high: '',
        absolute_value: '',
        uom: '',
        instructions: [{ instrction: '' }]
      });
    }

    setProcessList(list);
  };

  const handleAddInstructions = (e, index, i) => {
    console.log(e, index, i);
    e.preventDefault();
    setIsInstructions({
      isOpen: {
        ['id']: index,
        ['second_id']: i
      }
    });
  };

  const handleAddEqpInstructions = (index, i) => {
    let list = [...processList];
    list[index].process_configs[i].final_instr_param = true;
    setProcessList(list);
    const check_fbb = processList[index].process_configs[i].instructions.every(
      (item) => Object.keys(item).some((key) => key === 'is_fbb_exist')
    );
    check_fbb
      ? setIsInstructions({
          isOpen: {
            ['id']: false
          }
        })
      : message.error(
          'Required information is missing. Please fill in all the mandatory fields marked in *.'
        );
  };

  const handleDrop = (droppedItem, index, i, s) => {
    if (!droppedItem.destination) return;
    console.log(droppedItem);
    console.log(processList);
    let updatedList = [...processList];
    console.log(
      updatedList[index]?.process_configs[i]?.instructions[s]?.execute_bmr
    );
    let gg =
      updatedList[index]?.process_configs[i]?.instructions[s]?.execute_bmr;
    const [reorderedItem] = gg.splice(droppedItem.source.index, 1);
    console.log(reorderedItem);
    gg.splice(droppedItem.destination.index, 0, reorderedItem);
    setProcessList(updatedList);
    // console.log(droppedItem);
  };

  console.log(processList);
  return (
    <Layout sider={false}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        {processList?.map((data, index) => {
          console.log(data);
          return (
            <>
              <Collapse
                collapsible="header"
                defaultActiveKey={['0']}
                className={styles.CustomPanel1}
                // key={index}
                // className={styles.cardStyles_approver2}
              >
                <Panel
                  header={
                    data?.group_mod_name ? (
                      <Tag
                        color="rgb(201 23 97)"
                        style={{ fontWeight: '100', fontSize: '18px' }}
                      >
                        {data?.group_mod_name}
                      </Tag>
                    ) : (
                      data?.bom_process_group_master?.process_group_name
                    )
                  }
                  className={styles.CustomPanel2}
                  key={index}
                  extra={
                    <Space>
                      {data?.group_mod_name
                        ? // <Tag
                          //   color="rgb(201 23 97)"
                          //   style={{ fontWeight: '100', fontSize: '18px' }}
                          // >
                          data?.bom_process_group_master?.process_group_name
                        : // </Tag>
                          ''}
                    </Space>
                  }
                >
                  {data?.process_configs?.map((param, i) => {
                    return (
                      <>
                        <Badge.Ribbon
                          text={
                            data?.bom_process_group_master?.material_level
                              ? 'Materials'
                              : 'Lot'
                          }
                          className={styles.tagStyle1}
                        >
                          <Card
                            key={i}
                            title={
                              param?.process_mod_name ? (
                                <Tag
                                  color="rgb(201 23 97)"
                                  style={{
                                    fontWeight: '100',
                                    fontSize: '18px'
                                  }}
                                >
                                  {param?.process_mod_name}
                                </Tag>
                              ) : (
                                param?.bom_process_master?.process_name
                              )
                            }
                            className={styles.SubPanel}
                            extra={
                              <Space size={20}>
                                {param?.process_mod_name ? (
                                  // <Tag
                                  //   color="rgb(201 23 97)"
                                  //   style={{ fontWeight: '100' }}
                                  // >
                                  <span
                                    style={{
                                      fontSize: '18px',
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    {param?.bom_process_master?.process_name}
                                  </span>
                                ) : (
                                  // </Tag>
                                  ''
                                )}
                                <CustomButton
                                  type="primary"
                                  ghost
                                  style={{ marginLeft: '-10%' }}
                                  onClick={(e) => {
                                    handleAddInstructions(e, index, i);
                                  }}
                                >
                                  Add Instructions
                                </CustomButton>
                              </Space>
                            }
                          >
                            <>
                              {/* {param?.final_instr_param ? (
                                <>
                                  <Card className={styles.cardStyles_approver}>
                                    <h2 style={{ fontWeight: 'bold' }}>
                                      Instructions:{' '}
                                    </h2>
                                    <Row gutter={24}>
                                      {param?.instructions?.map((K, index) => {
                                        return (
                                          <>
                                            <Col span={24}>
                                              <span
                                                style={{
                                                  fontSize: '14px',
                                                  color: 'black'
                                                }}
                                                key={index}
                                              >
                                                <ul
                                                  className={styles.unorderlist}
                                                >
                                                  <li>{K?.instruction}</li>
                                                </ul>
                                              </span>
                                            </Col>
                                          </>
                                        );
                                      })}
                                    </Row>
                                  </Card>

                                  <br />
                                </>
                              ) : (
                                ''
                              )} */}

                              <Row gutter={24}>
                                <Col span={24}>
                                  <p
                                    style={{
                                      fontSize: '14px',
                                      color: '#263238'
                                      // marginTop: '7px'
                                    }}
                                  >
                                    Add materials used during this process
                                  </p>
                                </Col>
                              </Row>
                              <Row gutter={24}>
                                <Col span={24}>
                                  {param?.bom_materials?.map((item, j) => {
                                    return (
                                      <Space size={1} key={j}>
                                        <p className={styles.paragraph}>
                                          <Tag
                                            key={j}
                                            // style={{
                                            //   padding: '3px',
                                            //   color: 'rgb(201 23 97) !important'
                                            // }}
                                            className={styles.tagStyle}
                                          >
                                            {item?.material?.name}
                                          </Tag>
                                        </p>
                                      </Space>
                                    );
                                  })}
                                </Col>
                              </Row>
                              {/* <Row>
                                <Col span={24}>
                                  <p
                                    style={{
                                      fontSize: '14px',
                                      color: '#263238'
                                      // marginTop: '7px'
                                    }}
                                  >
                                    Equipments:
                                  </p>
                                </Col>
                              </Row> */}
                              <Row gutter={24}>
                                <Col span={24}>
                                  {/* {param?.equipment_master?.map((item, j) => { */}
                                  {/* return ( */}
                                  <Space size={1} direction="vertical">
                                    <span
                                      style={{
                                        fontSize: '14px',
                                        color: '#263238'
                                        // marginTop: '7px'
                                      }}
                                    >
                                      Equipments:
                                    </span>
                                    {param?.process_equipment_config?.map(
                                      (equips, k) => {
                                        return (
                                          <>
                                            {/* <Card
                                            // className={styles.CustomPanel}
                                            > */}
                                            <Row gutter={24}>
                                              <Col span={20}>
                                                <p
                                                  className={styles.paragraph}
                                                  key={k}
                                                >
                                                  <Tag
                                                    className={styles.tagStyle}
                                                  >
                                                    {
                                                      equips?.equipment_master
                                                        ?.equipment_name
                                                    }
                                                  </Tag>
                                                </p>
                                              </Col>
                                            </Row>

                                            {equips?.process_eqp_ids?.map(
                                              (eqp_id, l) => {
                                                return (
                                                  <>
                                                    <Row gutter={24} key={l}>
                                                      <Col span={12}>
                                                        <p
                                                          className={
                                                            styles.paragraph
                                                          }
                                                          key={l}
                                                        >
                                                          <Tag
                                                            className={
                                                              styles.tagStyle
                                                            }
                                                          >
                                                            {
                                                              eqp_id
                                                                ?.equipment_model
                                                                ?.equipment_id
                                                            }
                                                          </Tag>
                                                          <Tag
                                                            className={
                                                              styles.tagStyle
                                                            }
                                                          >
                                                            {
                                                              eqp_id
                                                                ?.equipment_model
                                                                ?.equipment_capacity
                                                            }
                                                          </Tag>
                                                        </p>
                                                      </Col>
                                                      <Col span={12}>
                                                        <Tag
                                                          className={
                                                            styles.buttonStyle
                                                          }
                                                          onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleModal(
                                                              index,
                                                              i,
                                                              k,
                                                              l,
                                                              eqp_id
                                                                ?.equipment_model
                                                                ?.id,
                                                              'parameter'
                                                            );
                                                          }}
                                                        >
                                                          Add Parameters
                                                        </Tag>
                                                        <Tag
                                                          className={
                                                            styles.buttonStyle
                                                          }
                                                          onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleacceModal(
                                                              index,
                                                              i,
                                                              k,
                                                              l,
                                                              eqp_id
                                                                ?.equipment_model
                                                                ?.id,
                                                              'accessories'
                                                            );
                                                          }}
                                                        >
                                                          Add Accessories
                                                        </Tag>
                                                      </Col>
                                                    </Row>
                                                    {eqp_id?.finalparams ? (
                                                      <>
                                                        <Row
                                                          gutter={24}
                                                          className={styles.gap}
                                                        >
                                                          <Col span={12}>
                                                            <span
                                                              style={{
                                                                fontSize: '14px'
                                                              }}
                                                            >
                                                              Set Parameters:
                                                            </span>
                                                          </Col>
                                                        </Row>

                                                        {eqp_id?.parameters?.map(
                                                          (item, index) => {
                                                            console.log(item);
                                                            let parameter =
                                                              accessories[0]?.paramater_type_masters?.filter(
                                                                (param) =>
                                                                  param?.id ==
                                                                  item?.parameter_id
                                                              );
                                                            // let accessories_data =
                                                            //   accessories?.filter(
                                                            //     (param) =>
                                                            //       param?.id ==
                                                            //       item[0].accessories
                                                            //   );
                                                            console.log(item);
                                                            return (
                                                              <>
                                                                <Row
                                                                  gutter={24}
                                                                >
                                                                  <Col span={8}>
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          '14px',
                                                                        color:
                                                                          'black'
                                                                      }}
                                                                      key={
                                                                        index
                                                                      }
                                                                    >
                                                                      Type of
                                                                      parameter:{' '}
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            '14px',
                                                                          color:
                                                                            'gray'
                                                                        }}
                                                                      >
                                                                        {
                                                                          parameter?.[0]
                                                                            ?.parameter_type
                                                                        }
                                                                      </span>
                                                                    </span>
                                                                  </Col>

                                                                  <Col span={8}>
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          '14px',
                                                                        color:
                                                                          'black'
                                                                      }}
                                                                      key={
                                                                        index
                                                                      }
                                                                    >
                                                                      Value:{' '}
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            '14px',
                                                                          color:
                                                                            'gray',
                                                                          marginTop:
                                                                            '-6px !important'
                                                                        }}
                                                                      >
                                                                        {item?.absolute_value
                                                                          ? item?.absolute_value
                                                                          : item?.low &&
                                                                            item?.high
                                                                          ? `${item?.low} to ${item?.high}`
                                                                          : 'NA'}
                                                                      </span>
                                                                    </span>
                                                                  </Col>
                                                                  <Col span={8}>
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          '14px',
                                                                        color:
                                                                          'black'
                                                                      }}
                                                                      key={
                                                                        index
                                                                      }
                                                                    >
                                                                      UOM:{' '}
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            '14px',
                                                                          color:
                                                                            'gray'
                                                                        }}
                                                                      >
                                                                        {
                                                                          item?.uom
                                                                        }
                                                                      </span>
                                                                    </span>
                                                                  </Col>
                                                                </Row>
                                                              </>
                                                            );
                                                          }
                                                        )}
                                                      </>
                                                    ) : (
                                                      ''
                                                    )}
                                                    {eqp_id?.finalaccessoriesparams ? (
                                                      <>
                                                        <Row
                                                          gutter={24}
                                                          className={styles.gap}
                                                        >
                                                          <Col span={12}>
                                                            <span
                                                              style={{
                                                                fontSize: '14px'
                                                              }}
                                                            >
                                                              Set Parameters:
                                                            </span>
                                                          </Col>
                                                        </Row>

                                                        {eqp_id?.accessories?.map(
                                                          (item, index) => {
                                                            console.log(item);
                                                            let parameter =
                                                              accessories[0]?.accessory_masters?.filter(
                                                                (param) =>
                                                                  param?.id ==
                                                                  item?.parameter_id
                                                              );
                                                            let accessories_data =
                                                              parameter?.[0]?.accessory_sub_masters?.filter(
                                                                (param) =>
                                                                  param?.id ==
                                                                  item?.sub_param_id
                                                              );
                                                            console.log(
                                                              accessories_data
                                                            );
                                                            return (
                                                              <>
                                                                <Row
                                                                  gutter={24}
                                                                >
                                                                  <Col span={6}>
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          '14px',
                                                                        color:
                                                                          'black'
                                                                      }}
                                                                      key={
                                                                        index
                                                                      }
                                                                    >
                                                                      Type of
                                                                      accessory:{' '}
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            '14px',
                                                                          color:
                                                                            'gray'
                                                                        }}
                                                                      >
                                                                        {
                                                                          parameter?.[0]
                                                                            ?.accessory_name
                                                                        }
                                                                      </span>
                                                                    </span>
                                                                  </Col>
                                                                  <Col span={6}>
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          '14px',
                                                                        color:
                                                                          'black'
                                                                      }}
                                                                      key={
                                                                        index
                                                                      }
                                                                    >
                                                                      Accessory
                                                                      parameter:{' '}
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            '14px',
                                                                          color:
                                                                            'gray'
                                                                        }}
                                                                      >
                                                                        {
                                                                          accessories_data?.[0]
                                                                            ?.accessory_type
                                                                        }
                                                                      </span>
                                                                    </span>
                                                                  </Col>
                                                                  <Col span={6}>
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          '14px',
                                                                        color:
                                                                          'black'
                                                                      }}
                                                                      key={
                                                                        index
                                                                      }
                                                                    >
                                                                      Value:{' '}
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            '14px',
                                                                          color:
                                                                            'gray',
                                                                          marginTop:
                                                                            '-6px !important'
                                                                        }}
                                                                      >
                                                                        {item?.absolute_value
                                                                          ? item?.absolute_value
                                                                          : item?.low &&
                                                                            item?.high
                                                                          ? `${item?.low} to ${item?.high}`
                                                                          : 'NA'}
                                                                      </span>
                                                                    </span>
                                                                  </Col>
                                                                  <Col span={6}>
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          '14px',
                                                                        color:
                                                                          'black'
                                                                      }}
                                                                      key={
                                                                        index
                                                                      }
                                                                    >
                                                                      UOM:{' '}
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            '14px',
                                                                          color:
                                                                            'gray'
                                                                        }}
                                                                      >
                                                                        {
                                                                          item?.uom
                                                                        }
                                                                      </span>
                                                                    </span>
                                                                  </Col>
                                                                </Row>
                                                              </>
                                                            );
                                                          }
                                                        )}
                                                      </>
                                                    ) : (
                                                      ''
                                                    )}
                                                  </>
                                                );
                                              }
                                            )}
                                            {/* </Row> */}
                                            {/* </Card> */}
                                            {isModal.isOpen['id'] === i ? (
                                              <CustomModal
                                                title="Equipment Parameters"
                                                visible={
                                                  isModal.isOpen['id'] === i
                                                }
                                                width="30%"
                                                // height="50%"
                                                footer={null}
                                                className={styles.customModal}
                                                closable={false}
                                              >
                                                <Form
                                                  form={form}
                                                  layout="vertical"
                                                >
                                                  {param?.process_equipment_config?.[
                                                    modalid.main
                                                  ]?.process_eqp_ids?.[
                                                    modalid.sub
                                                  ]?.parameters?.map(
                                                    (parmtrs, j) => {
                                                      return (
                                                        <>
                                                          <Card key={j}>
                                                            <Row gutter={24}>
                                                              <Col span={24}>
                                                                <FormItem
                                                                  label="Type of Equipment Parameters"
                                                                  rules={[
                                                                    {
                                                                      required: false,
                                                                      message:
                                                                        'Select Material'
                                                                    }
                                                                  ]}
                                                                  className={
                                                                    styles.customLable
                                                                  }
                                                                >
                                                                  <Select
                                                                    // mode="multiple"
                                                                    value={
                                                                      parmtrs?.parameter_id
                                                                    }
                                                                    allowClear
                                                                    placeholder="Select Materials"
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleChangeParameters(
                                                                        index,
                                                                        i,
                                                                        modalid.main,
                                                                        modalid.sub,
                                                                        j,
                                                                        e,
                                                                        'parameter_id'
                                                                      )
                                                                    }
                                                                  >
                                                                    {accessories[0]?.paramater_type_masters?.map(
                                                                      (
                                                                        param,
                                                                        j
                                                                      ) => {
                                                                        return (
                                                                          <option
                                                                            key={
                                                                              j
                                                                            }
                                                                            value={
                                                                              param.id
                                                                            }
                                                                          >
                                                                            {
                                                                              param?.parameter_type
                                                                            }
                                                                          </option>
                                                                        );
                                                                      }
                                                                    )}
                                                                  </Select>
                                                                </FormItem>
                                                              </Col>

                                                              <Col span={24}>
                                                                <FormItem
                                                                  label="Type of Value"
                                                                  rules={[
                                                                    {
                                                                      required: false,
                                                                      message:
                                                                        'Select Accessories'
                                                                    }
                                                                  ]}
                                                                  className={
                                                                    styles.customLable
                                                                  }
                                                                >
                                                                  <Radio.Group
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleSetType(
                                                                        e,
                                                                        index,
                                                                        i,
                                                                        modalid.main,
                                                                        modalid.sub,
                                                                        j,
                                                                        'parameter'
                                                                      )
                                                                    }
                                                                  >
                                                                    <Radio
                                                                      value={1}
                                                                    >
                                                                      Range
                                                                    </Radio>
                                                                    <Radio
                                                                      value={2}
                                                                    >
                                                                      Absolute
                                                                    </Radio>
                                                                  </Radio.Group>
                                                                </FormItem>
                                                              </Col>

                                                              {parmtrs.type_of_value ===
                                                              1 ? (
                                                                <>
                                                                  <Col
                                                                    span={12}
                                                                  >
                                                                    <FormItem
                                                                      label="Low value"
                                                                      rules={[
                                                                        {
                                                                          required: false,
                                                                          message:
                                                                            'Select Accessories'
                                                                        }
                                                                      ]}
                                                                      className={
                                                                        styles.customLable
                                                                      }
                                                                    >
                                                                      <Input
                                                                        placeholder="Enter Low"
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          handleChangeParameters(
                                                                            index,
                                                                            i,
                                                                            modalid.main,
                                                                            modalid.sub,
                                                                            j,
                                                                            e
                                                                              .target
                                                                              .value,
                                                                            'low'
                                                                          )
                                                                        }
                                                                      />
                                                                    </FormItem>
                                                                  </Col>
                                                                  <Col
                                                                    span={12}
                                                                  >
                                                                    <FormItem
                                                                      label="High Value"
                                                                      rules={[
                                                                        {
                                                                          required: false,
                                                                          message:
                                                                            'Select Accessories'
                                                                        }
                                                                      ]}
                                                                      className={
                                                                        styles.customLable
                                                                      }
                                                                    >
                                                                      <Input
                                                                        placeholder="Enter High"
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          handleChangeParameters(
                                                                            index,
                                                                            i,
                                                                            modalid.main,
                                                                            modalid.sub,
                                                                            j,
                                                                            e
                                                                              .target
                                                                              .value,
                                                                            'high'
                                                                          )
                                                                        }
                                                                      />
                                                                    </FormItem>
                                                                  </Col>
                                                                </>
                                                              ) : parmtrs.type_of_value ===
                                                                2 ? (
                                                                <Col span={24}>
                                                                  <FormItem
                                                                    label="Value"
                                                                    rules={[
                                                                      {
                                                                        required: false,
                                                                        message:
                                                                          'Select Accessories'
                                                                      }
                                                                    ]}
                                                                    className={
                                                                      styles.customLable
                                                                    }
                                                                  >
                                                                    <Input
                                                                      placeholder="Enter value"
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeParameters(
                                                                          index,
                                                                          i,
                                                                          modalid.main,
                                                                          modalid.sub,
                                                                          j,
                                                                          e
                                                                            .target
                                                                            .value,
                                                                          'absolute_value'
                                                                        )
                                                                      }
                                                                    />
                                                                  </FormItem>
                                                                </Col>
                                                              ) : (
                                                                ''
                                                              )}
                                                              <Col span={24}>
                                                                <FormItem
                                                                  label="UOM"
                                                                  rules={[
                                                                    {
                                                                      required: false,
                                                                      message:
                                                                        'Select Accessories'
                                                                    }
                                                                  ]}
                                                                  className={
                                                                    styles.customLable
                                                                  }
                                                                >
                                                                  <Input
                                                                    placeholder="Enter UOM"
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleChangeParameters(
                                                                        index,
                                                                        i,
                                                                        modalid.main,
                                                                        modalid.sub,
                                                                        j,
                                                                        e.target
                                                                          .value,
                                                                        'uom'
                                                                      )
                                                                    }
                                                                  />
                                                                </FormItem>
                                                              </Col>
                                                            </Row>
                                                          </Card>

                                                          {param
                                                            ?.process_equipment_config?.[
                                                            modalid.main
                                                          ]?.process_eqp_ids?.[
                                                            modalid.sub
                                                          ]?.parameters.length -
                                                            1 ==
                                                          j ? (
                                                            <>
                                                              <br />
                                                              <FormItem className="text-center">
                                                                <CustomButton
                                                                  onClick={() =>
                                                                    handleAddMultiParams(
                                                                      index,
                                                                      i,
                                                                      modalid.main,
                                                                      modalid.sub,
                                                                      j
                                                                    )
                                                                  }
                                                                >
                                                                  <PlusOutlined />{' '}
                                                                  Add another
                                                                  Parameter{' '}
                                                                </CustomButton>
                                                              </FormItem>
                                                            </>
                                                          ) : (
                                                            ''
                                                          )}
                                                        </>
                                                      );
                                                    }
                                                  )}

                                                  <FormItem className="text-center">
                                                    <Space>
                                                      <CustomButton
                                                        className={
                                                          styles.inwardButton
                                                        }
                                                        onClick={handleClose}
                                                        type="primary"
                                                        size="small"
                                                        ghost
                                                      >
                                                        Close
                                                      </CustomButton>
                                                      <CustomButton
                                                        className={
                                                          styles.inwardButton
                                                        }
                                                        onClick={() =>
                                                          handleAdd(
                                                            index,
                                                            i,
                                                            modalid.main,
                                                            modalid.sub
                                                          )
                                                        }
                                                        type="primary"
                                                        size="small"
                                                      >
                                                        Add
                                                      </CustomButton>
                                                    </Space>
                                                  </FormItem>
                                                </Form>
                                              </CustomModal>
                                            ) : (
                                              ''
                                            )}
                                          </>
                                        );
                                      }
                                    )}
                                  </Space>
                                  <Divider />
                                  {/* );
                                  })} */}
                                </Col>

                                {accessoriModal.isOpen['id'] === i ? (
                                  <CustomModal
                                    title="Accessory Parameters"
                                    visible={accessoriModal.isOpen['id'] === i}
                                    width="30%"
                                    // height="50%"
                                    footer={null}
                                    className={styles.customModal}
                                    closable={false}
                                  >
                                    <Form form={form} layout="vertical">
                                      {param?.process_equipment_config?.[
                                        modalid.main
                                      ]?.process_eqp_ids?.[
                                        modalid.sub
                                      ]?.accessories?.map((parmtrs, j) => {
                                        return (
                                          <>
                                            <Card
                                              key={j}
                                              className={styles.subpanel12}
                                            >
                                              <Row gutter={24}>
                                                <Col span={24}>
                                                  <FormItem
                                                    label="Type of Accessory"
                                                    rules={[
                                                      {
                                                        required: false,
                                                        message:
                                                          'Select Material'
                                                      }
                                                    ]}
                                                    className={
                                                      styles.customLable
                                                    }
                                                  >
                                                    <Select
                                                      // mode="multiple"
                                                      value={
                                                        parmtrs?.parameter_id
                                                      }
                                                      allowClear
                                                      placeholder="Select Materials"
                                                      onChange={(e) =>
                                                        handleChangeParameters(
                                                          index,
                                                          i,
                                                          modalid.main,
                                                          modalid.sub,
                                                          j,
                                                          e,
                                                          'parameter_id',
                                                          'accessories'
                                                        )
                                                      }
                                                    >
                                                      {accessories[0]?.accessory_masters?.map(
                                                        (param, j) => {
                                                          return (
                                                            <option
                                                              key={j}
                                                              value={param.id}
                                                            >
                                                              {
                                                                param?.accessory_name
                                                              }
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                    </Select>
                                                  </FormItem>
                                                </Col>
                                                {/* <Col span={24}>
                                                  <FormItem
                                                    label="Accessory ID"
                                                    rules={[
                                                      {
                                                        required: false,
                                                        message:
                                                          'Select Accessories'
                                                      }
                                                    ]}
                                                    className={
                                                      styles.customLable
                                                    }
                                                  >
                                                    <Input
                                                      placeholder="Enter Accessory ID"
                                                      onChange={(e) =>
                                                        handleChangeParameters(
                                                          index,
                                                          i,
                                                          modalid.main,
                                                          modalid.sub,
                                                          j,
                                                          e.target.value,
                                                          'uom',
                                                          'accessory_id'
                                                        )
                                                      }
                                                    />
                                                  </FormItem>
                                                </Col> */}
                                                <Col span={24}>
                                                  <FormItem
                                                    label={`Select Accessory Parameter
                                                    `}
                                                    rules={[
                                                      {
                                                        required: false,
                                                        message:
                                                          'Select Accessories'
                                                      }
                                                    ]}
                                                    className={
                                                      styles.customLable
                                                    }
                                                  >
                                                    <Select
                                                      // mode="multiple"
                                                      allowClear
                                                      placeholder="Select Accessories"
                                                      onChange={(e) =>
                                                        handleChangeParameters(
                                                          index,
                                                          i,
                                                          modalid.main,
                                                          modalid.sub,
                                                          j,
                                                          e,
                                                          'sub_param_id',
                                                          'accessories'
                                                        )
                                                      }
                                                    >
                                                      {subcatgries[0]?.accessory_sub_masters?.map(
                                                        (param, k) => {
                                                          return (
                                                            <option
                                                              key={k}
                                                              value={param.id}
                                                            >
                                                              {
                                                                param?.accessory_type
                                                              }
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                    </Select>
                                                  </FormItem>
                                                </Col>
                                                <Col span={24}>
                                                  <FormItem
                                                    label="Type of Value"
                                                    rules={[
                                                      {
                                                        required: false,
                                                        message:
                                                          'Select Accessories'
                                                      }
                                                    ]}
                                                    className={
                                                      styles.customLable
                                                    }
                                                  >
                                                    <Radio.Group
                                                      onChange={(e) =>
                                                        handleSetType(
                                                          e,
                                                          index,
                                                          i,
                                                          modalid.main,
                                                          modalid.sub,
                                                          j,
                                                          'accessories'
                                                        )
                                                      }
                                                    >
                                                      <Radio value={1}>
                                                        Range
                                                      </Radio>
                                                      <Radio value={2}>
                                                        Absolute
                                                      </Radio>
                                                    </Radio.Group>
                                                  </FormItem>
                                                </Col>

                                                {parmtrs.type_of_value === 1 ? (
                                                  <>
                                                    <Col span={12}>
                                                      <FormItem
                                                        label="Low value"
                                                        rules={[
                                                          {
                                                            required: false,
                                                            message:
                                                              'Select Accessories'
                                                          }
                                                        ]}
                                                        className={
                                                          styles.customLable
                                                        }
                                                      >
                                                        <Input
                                                          placeholder="Enter Low"
                                                          onChange={(e) =>
                                                            handleChangeParameters(
                                                              index,
                                                              i,
                                                              modalid.main,
                                                              modalid.sub,
                                                              j,
                                                              e.target.value,
                                                              'low',
                                                              'accessories'
                                                            )
                                                          }
                                                        />
                                                      </FormItem>
                                                    </Col>
                                                    <Col span={12}>
                                                      <FormItem
                                                        label="High Value"
                                                        rules={[
                                                          {
                                                            required: false,
                                                            message:
                                                              'Select Accessories'
                                                          }
                                                        ]}
                                                        className={
                                                          styles.customLable
                                                        }
                                                      >
                                                        <Input
                                                          placeholder="Enter High"
                                                          onChange={(e) =>
                                                            handleChangeParameters(
                                                              index,
                                                              i,
                                                              modalid.main,
                                                              modalid.sub,
                                                              j,
                                                              e.target.value,
                                                              'high',
                                                              'accessories'
                                                            )
                                                          }
                                                        />
                                                      </FormItem>
                                                    </Col>
                                                  </>
                                                ) : parmtrs.type_of_value ===
                                                  2 ? (
                                                  <Col span={24}>
                                                    <FormItem
                                                      label="Value"
                                                      rules={[
                                                        {
                                                          required: false,
                                                          message:
                                                            'Select Accessories'
                                                        }
                                                      ]}
                                                      className={
                                                        styles.customLable
                                                      }
                                                    >
                                                      <Input
                                                        placeholder="Enter value"
                                                        onChange={(e) =>
                                                          handleChangeParameters(
                                                            index,
                                                            i,
                                                            modalid.main,
                                                            modalid.sub,
                                                            j,
                                                            e.target.value,
                                                            'absolute_value',
                                                            'accessories'
                                                          )
                                                        }
                                                      />
                                                    </FormItem>
                                                  </Col>
                                                ) : (
                                                  ''
                                                )}
                                                <Col span={24}>
                                                  <FormItem
                                                    label="UOM"
                                                    rules={[
                                                      {
                                                        required: false,
                                                        message:
                                                          'Select Accessories'
                                                      }
                                                    ]}
                                                    className={
                                                      styles.customLable
                                                    }
                                                  >
                                                    <Input
                                                      placeholder="Enter UOM"
                                                      onChange={(e) =>
                                                        handleChangeParameters(
                                                          index,
                                                          i,
                                                          modalid.main,
                                                          modalid.sub,
                                                          j,
                                                          e.target.value,
                                                          'uom',
                                                          'accessories'
                                                        )
                                                      }
                                                    />
                                                  </FormItem>
                                                </Col>
                                              </Row>
                                            </Card>

                                            {param?.process_equipment_config?.[
                                              modalid.main
                                            ]?.process_eqp_ids?.[modalid.sub]
                                              ?.accessories.length -
                                              1 ==
                                            j ? (
                                              <>
                                                <br />
                                                <FormItem className="text-center">
                                                  <CustomButton
                                                    onClick={() =>
                                                      handleAddMultiParams(
                                                        index,
                                                        i,
                                                        modalid.main,
                                                        modalid.sub,
                                                        j,
                                                        'accessories'
                                                      )
                                                    }
                                                  >
                                                    <PlusOutlined /> Add another
                                                    Accessory{' '}
                                                  </CustomButton>
                                                </FormItem>
                                              </>
                                            ) : (
                                              ''
                                            )}
                                          </>
                                        );
                                      })}

                                      <FormItem className="text-center">
                                        <Space>
                                          <CustomButton
                                            className={styles.inwardButton}
                                            onClick={handleClosemodal}
                                            type="primary"
                                            size="small"
                                            ghost
                                          >
                                            Close
                                          </CustomButton>
                                          <CustomButton
                                            className={styles.inwardButton}
                                            onClick={() =>
                                              handleAdd(
                                                index,
                                                i,
                                                modalid.main,
                                                modalid.sub,
                                                'accessories'
                                              )
                                            }
                                            type="primary"
                                            size="small"
                                          >
                                            Add
                                          </CustomButton>
                                        </Space>
                                      </FormItem>
                                    </Form>
                                  </CustomModal>
                                ) : (
                                  ''
                                )}

                                {isInstructions.isOpen['id'] === index &&
                                isInstructions.isOpen['second_id'] === i ? (
                                  <CustomModal
                                    title="Add Instructions"
                                    visible={
                                      isInstructions.isOpen['id'] === index
                                    }
                                    width="40%"
                                    // height="50%"
                                    footer={null}
                                    className={styles.customModal}
                                    closable={false}
                                  >
                                    <Form
                                      form={form}
                                      layout="vertical"
                                      key={index}
                                    >
                                      {param?.instructions?.map((item, n) => {
                                        return (
                                          <Row gutter={24} key={n}>
                                            <Col span={24}>
                                              <FormItem
                                                label={`Step ${n + 1}`}
                                                rules={[
                                                  {
                                                    required: false,
                                                    message:
                                                      'Enter instructions'
                                                  }
                                                ]}
                                                // className={styles.customLable9}
                                              >
                                                <ReactQuill
                                                  className={
                                                    styles.customLable9
                                                  }
                                                  theme="snow"
                                                  rows={4}
                                                  // value={value}
                                                  placeholder="Enter Instructions"
                                                  onChange={(e) =>
                                                    ChangeInstruction(
                                                      index,
                                                      i,
                                                      n,
                                                      e,
                                                      'instruction'
                                                    )
                                                  }
                                                />
                                                {/* <TextArea
                                                  rows={4}
                                                  placeholder="Enter Instructions"
                                                  onChange={(e) =>
                                                    ChangeInstruction(
                                                      index,
                                                      i,
                                                      n,
                                                      e.target.value,
                                                      'instruction'
                                                    )
                                                  }
                                                /> */}
                                              </FormItem>
                                            </Col>
                                            {/* <Col span={24}>
                                              <FormItem
                                                label="Type of Equipment Parameters"
                                                rules={[
                                                  {
                                                    required: false,
                                                    message: 'Select Material'
                                                  }
                                                ]}
                                                className={styles.customLable}
                                              >
                                                <Select
                                                  // mode="multiple"
                                                  value={item?.eqp_id || null}
                                                  allowClear
                                                  placeholder="Select Materials"
                                                  onChange={(e) =>
                                                    ChangeInstruction(
                                                      index,
                                                      i,
                                                      n,
                                                      e,
                                                      'eqp_id'
                                                    )
                                                  }
                                                >
                                                  {param?.process_equipment_config?.map(
                                                    (param, j) => {
                                                      return (
                                                        <option
                                                          key={j}
                                                          value={param.id}
                                                        >
                                                          {
                                                            param
                                                              ?.equipment_master
                                                              ?.equipment_name
                                                          }
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                                </Select>
                                              </FormItem>
                                            </Col> */}
                                            <Col span={24}>
                                              <FormItem
                                                label="Would you like to show Equipment & Parameters for this step"
                                                rules={[
                                                  {
                                                    required: false,
                                                    message: 'Select Material'
                                                  }
                                                ]}
                                                className={styles.customLable}
                                              >
                                                <Radio.Group
                                                  onChange={(e) =>
                                                    ChangeInstruction(
                                                      index,
                                                      i,
                                                      n,
                                                      e.target.value,
                                                      'is_param'
                                                    )
                                                  }
                                                >
                                                  <Radio value="true">
                                                    Yes
                                                  </Radio>
                                                  <Radio value="false">
                                                    No
                                                  </Radio>
                                                </Radio.Group>
                                              </FormItem>
                                            </Col>
                                            <Col span={24}>
                                              <FormItem
                                                label={
                                                  <span>
                                                    Do you want to add Fluid Bed
                                                    Parameters
                                                    <sup
                                                      style={{
                                                        color: 'red',
                                                        fontSize: '12px'
                                                      }}
                                                    >
                                                      *
                                                    </sup>
                                                  </span>
                                                }
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: 'Select Material'
                                                  }
                                                ]}
                                                className={styles.customLable}
                                              >
                                                <Radio.Group
                                                  onChange={(e) =>
                                                    ChangeInstruction(
                                                      index,
                                                      i,
                                                      n,
                                                      e.target.value,
                                                      'is_fbb_exist'
                                                    )
                                                  }
                                                >
                                                  <Radio value="true">
                                                    Yes
                                                  </Radio>
                                                  <Radio value="false">
                                                    No
                                                  </Radio>
                                                </Radio.Group>
                                              </FormItem>
                                            </Col>
                                            <Col span={24}>
                                              <FormItem
                                                label={
                                                  <span>
                                                    Would you like to add Tare
                                                    Wt., Net Wt., Gross Wt.
                                                    Fields?
                                                    <sup
                                                      style={{
                                                        color: 'red',
                                                        fontSize: '12px'
                                                      }}
                                                    >
                                                      *
                                                    </sup>
                                                  </span>
                                                }
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: 'Select Material'
                                                  }
                                                ]}
                                                className={styles.customLable}
                                              >
                                                <Radio.Group
                                                  onChange={(e) =>
                                                    ChangeInstruction(
                                                      index,
                                                      i,
                                                      n,
                                                      e.target.value,
                                                      'is_container_exist'
                                                    )
                                                  }
                                                >
                                                  <Radio value="true">
                                                    Yes
                                                  </Radio>
                                                  <Radio value="false">
                                                    No
                                                  </Radio>
                                                </Radio.Group>
                                              </FormItem>
                                            </Col>
                                            <Col span={24}>
                                              <FormItem
                                                label="Other Parameters"
                                                rules={[
                                                  {
                                                    required: false,
                                                    message: 'Select Material'
                                                  }
                                                ]}
                                                className={styles.customLable}
                                              >
                                                <Checkbox.Group
                                                  style={{
                                                    width: '100%'
                                                  }}
                                                >
                                                  <Row gutter={24}>
                                                    {data?.bom_process_group_master?.execution_details?.map(
                                                      // {param?.bom_process_master?.execution_details?.map(
                                                      (other, b) => {
                                                        console.log(other);
                                                        let count =
                                                          item?.execute_bmr.filter(
                                                            (id) =>
                                                              id == other?.id
                                                          );
                                                        console.log(count);
                                                        return (
                                                          <Col
                                                            span={12}
                                                            key={b}
                                                          >
                                                            {/* <Space key={b}> */}
                                                            <Checkbox
                                                              key={b}
                                                              value={other?.id}
                                                              onChange={(e) => {
                                                                AddmoreParams(
                                                                  index,
                                                                  i,
                                                                  n,
                                                                  e.target
                                                                    .value,
                                                                  'other_param'
                                                                );
                                                              }}
                                                            >
                                                              {other?.exe_name}{' '}
                                                            </Checkbox>
                                                            <PlusCircleOutlined
                                                              onClick={(e) => {
                                                                e.stopPropagation(),
                                                                  AddmoreParams(
                                                                    index,
                                                                    i,
                                                                    n,
                                                                    other?.id,
                                                                    'other_param'
                                                                  );
                                                              }}
                                                            />{' '}
                                                            {count?.length > 0
                                                              ? `(${count?.length})`
                                                              : ''}
                                                            {/* </Space> */}
                                                          </Col>
                                                        );
                                                      }
                                                    )}
                                                  </Row>
                                                </Checkbox.Group>
                                                {/* </Checkbox.Group> */}
                                                <span
                                                  style={{
                                                    color: '#c91761',
                                                    float: 'right',
                                                    cursor: 'pointer'
                                                  }}
                                                  onClick={(e) =>
                                                    handleInstructions(
                                                      index,
                                                      i,
                                                      modalid,
                                                      n,
                                                      e.target.value
                                                    )
                                                  }
                                                >
                                                  {/* {instruction?.length - 1 ==
                                                  index */}
                                                  Add Next Step
                                                  {/* : ''} */}
                                                </span>
                                              </FormItem>
                                            </Col>
                                          </Row>
                                        );
                                      })}
                                      <FormItem className="text-center">
                                        <Space>
                                          <CustomButton
                                            className={styles.inwardButton}
                                            onClick={handleClosemodal}
                                            type="primary"
                                            size="small"
                                            ghost
                                          >
                                            Close
                                          </CustomButton>
                                          <CustomButton
                                            className={styles.inwardButton}
                                            onClick={() =>
                                              handleAddEqpInstructions(
                                                index,
                                                i,
                                                modalid.main,
                                                modalid.sub,
                                                'accessories'
                                              )
                                            }
                                            type="primary"
                                            size="small"
                                          >
                                            Add
                                          </CustomButton>
                                        </Space>
                                      </FormItem>
                                    </Form>
                                  </CustomModal>
                                ) : (
                                  ''
                                )}
                              </Row>
                              <br />
                              {param?.final_instr_param ? (
                                <Card
                                  title="Instructions"
                                  className={styles.SubPanel22}
                                >
                                  <>
                                    <Row gutter={24}>
                                      {param?.instructions?.map((K, s) => {
                                        // console.log(data);
                                        let bmr_executes = [];
                                        K.execute_bmr?.map((item) => {
                                          let other_param =
                                            data?.bom_process_group_master?.execution_details?.filter(
                                              (data) => data?.id == item
                                            );
                                          console.log(other_param);
                                          bmr_executes.push({
                                            id: other_param?.[0]?.id,
                                            name: other_param?.[0]?.exe_name,
                                            type: other_param?.[0]?.exe_type
                                          });
                                        });
                                        console.log(bmr_executes);
                                        return (
                                          <>
                                            <Col span={24}>
                                              <Card
                                                title={`Step-${s + 1}`}
                                                className={styles.SubPanel22}
                                              >
                                                <Col span={24}>
                                                  <span
                                                    style={{
                                                      fontSize: '14px'
                                                      // color: 'black'
                                                    }}
                                                    key={s}
                                                  >
                                                    <span
                                                      dangerouslySetInnerHTML={{
                                                        __html: K?.instruction
                                                      }}
                                                      // className={styles.tdbody}
                                                    />
                                                    {/* <ul
                                                      className={
                                                        styles.unorderlist
                                                      }
                                                    >
                                                      <li>{K?.instruction}</li>
                                                    </ul> */}
                                                  </span>
                                                </Col>
                                                <Col span={24}>
                                                  <Collapse
                                                    collapsible="icon"
                                                    defaultActiveKey={['0']}
                                                    className={
                                                      styles.SubPanel21
                                                    }
                                                    // key={index}
                                                    // className={styles.cardStyles_approver2}
                                                  >
                                                    <Panel
                                                      header={
                                                        'Execution Details'
                                                      }
                                                    >
                                                      <Card>
                                                        {/* <Row> */}
                                                        <DragDropContext
                                                          onDragEnd={(
                                                            droppedItem
                                                          ) =>
                                                            handleDrop(
                                                              droppedItem,
                                                              index,
                                                              i,
                                                              s
                                                            )
                                                          }
                                                        >
                                                          <Droppable
                                                            droppableId="list-container"
                                                            className={`${styles.approver_line}`}
                                                          >
                                                            {(provided) => (
                                                              <div
                                                                className="list-container"
                                                                {...provided.droppableProps}
                                                                ref={
                                                                  provided.innerRef
                                                                }
                                                              >
                                                                {/* <Row> */}
                                                                {bmr_executes?.map(
                                                                  (exe, d) => {
                                                                    return (
                                                                      <Col
                                                                        span={
                                                                          24
                                                                        }
                                                                        key={
                                                                          exe.id
                                                                        }
                                                                      >
                                                                        <Draggable
                                                                          key={
                                                                            exe.id
                                                                          }
                                                                          draggableId={`bmr${exe.id}`}
                                                                          index={
                                                                            d
                                                                          }
                                                                        >
                                                                          {(
                                                                            provided
                                                                          ) => (
                                                                            <>
                                                                              <div
                                                                                ref={
                                                                                  provided.innerRef
                                                                                }
                                                                                {...provided.dragHandleProps}
                                                                                {...provided.draggableProps}
                                                                              >
                                                                                <Col
                                                                                  span={
                                                                                    12
                                                                                  }
                                                                                  key={
                                                                                    d
                                                                                  }
                                                                                >
                                                                                  <FormItem
                                                                                    label={`${exe?.name}`}
                                                                                    rules={[
                                                                                      {
                                                                                        required: false,
                                                                                        message:
                                                                                          'Enter Lots'
                                                                                      }
                                                                                    ]}
                                                                                    className={
                                                                                      styles.customLable11
                                                                                    }
                                                                                  >
                                                                                    {exe?.type ==
                                                                                    'Input' ? (
                                                                                      <Input
                                                                                        placeholder={
                                                                                          exe?.name
                                                                                        }
                                                                                        disabled
                                                                                      />
                                                                                    ) : exe?.type ==
                                                                                      'Date' ? (
                                                                                      <DatePicker
                                                                                        disabled
                                                                                        style={{
                                                                                          width:
                                                                                            '100%'
                                                                                        }}
                                                                                      />
                                                                                    ) : exe?.type ==
                                                                                      'Date&Time' ? (
                                                                                      <DatePicker
                                                                                        disabled
                                                                                        showTime={{
                                                                                          format:
                                                                                            'HH:mm'
                                                                                        }}
                                                                                        style={{
                                                                                          width:
                                                                                            '100%'
                                                                                        }}
                                                                                      />
                                                                                    ) : (
                                                                                      ''
                                                                                    )}
                                                                                  </FormItem>
                                                                                </Col>
                                                                              </div>
                                                                            </>
                                                                          )}
                                                                        </Draggable>
                                                                      </Col>
                                                                    );
                                                                  }
                                                                )}
                                                                {/* </Row> */}
                                                              </div>
                                                            )}
                                                          </Droppable>
                                                        </DragDropContext>
                                                        {/* </Row> */}
                                                      </Card>
                                                    </Panel>
                                                  </Collapse>
                                                </Col>
                                              </Card>
                                            </Col>
                                          </>
                                        );
                                      })}
                                    </Row>
                                  </>
                                </Card>
                              ) : (
                                ''
                              )}
                              {/* <Collapse
                                collapsible="icon"
                                defaultActiveKey={['0']}
                                className={styles.CustomPanel11}
                                // key={index}
                                // className={styles.cardStyles_approver2}
                              >
                                <Panel header={'Execution Details'}>
                                  {param?.bom_process_master?.process_name ===
                                  'Sifting' ? (
                                    <Row gutter={24}>
                                      <Col span={12}>
                                        <Card>
                                          <Col span={24}>
                                            <FormItem
                                              label="Lifting & Positioning device Equipment no."
                                              rules={[
                                                {
                                                  required: false,
                                                  message: 'Enter Lots'
                                                }
                                              ]}
                                              className={styles.customLable11}
                                            >
                                              <Input
                                                placeholder="Lifting & Positioning device Equipment no."
                                                disabled
                                              />
                                            </FormItem>
                                          </Col>
                                          <Col span={24}>
                                            <FormItem
                                              label="Start Date"
                                              rules={[
                                                {
                                                  required: false,
                                                  message: 'Enter Lots'
                                                }
                                              ]}
                                              className={styles.customLable11}
                                            >
                                              <Input
                                                placeholder="Start Date"
                                                disabled
                                              />
                                            </FormItem>
                                          </Col>
                                          <Col span={24}>
                                            <FormItem
                                              label="End Date"
                                              rules={[
                                                {
                                                  required: false,
                                                  message: 'Enter Lots'
                                                }
                                              ]}
                                              className={styles.customLable11}
                                            >
                                              <Input
                                                placeholder="End Date"
                                                disabled
                                              />
                                            </FormItem>
                                          </Col>
                                          <Col span={24}>
                                            <FormItem
                                              label="Done By"
                                              rules={[
                                                {
                                                  required: false,
                                                  message: 'Enter Lots'
                                                }
                                              ]}
                                              className={styles.customLable11}
                                            >
                                              <Input
                                                placeholder="Done By"
                                                disabled
                                              />
                                            </FormItem>
                                          </Col>
                                        </Card>
                                      </Col>
                                      <Col span={12}>
                                        <Card>
                                          <Col span={24}>
                                            <FormItem
                                              label="Vibratory Sifter Equipment no."
                                              rules={[
                                                {
                                                  required: false,
                                                  message: 'Enter Lots'
                                                }
                                              ]}
                                              className={styles.customLable11}
                                            >
                                              <Input
                                                placeholder="Enter Vibratory Sifter Equipment no"
                                                disabled
                                              />
                                            </FormItem>
                                          </Col>
                                          <Col span={24}>
                                            <FormItem
                                              label="Start Date"
                                              rules={[
                                                {
                                                  required: false,
                                                  message: 'Enter Lots'
                                                }
                                              ]}
                                              className={styles.customLable11}
                                            >
                                              <Input
                                                placeholder="Start Date"
                                                disabled
                                              />
                                            </FormItem>
                                          </Col>
                                          <Col span={24}>
                                            <FormItem
                                              label="End Date"
                                              rules={[
                                                {
                                                  required: false,
                                                  message: 'Enter Lots'
                                                }
                                              ]}
                                              className={styles.customLable11}
                                            >
                                              <Input
                                                placeholder="End Date"
                                                disabled
                                              />
                                            </FormItem>
                                          </Col>
                                          <Col span={24}>
                                            <FormItem
                                              label="Done By"
                                              rules={[
                                                {
                                                  required: false,
                                                  message: 'Enter Lots'
                                                }
                                              ]}
                                              className={styles.customLable11}
                                            >
                                              <Input
                                                placeholder="Done By"
                                                disabled
                                              />
                                            </FormItem>
                                          </Col>
                                        </Card>
                                      </Col>
                                    </Row>
                                  ) : (
                                    ''
                                  )}
                                </Panel>
                              </Collapse> */}
                              {/* <Row gutter={24}>
                                <Col span={10}>
                                  <p
                                    style={{
                                      fontSize: '14px',
                                      color: '#263238'
                                      // marginTop: '7px'
                                    }}
                                  >
                                    Is this process step carried out lot-wise?
                                  </p>
                                </Col>
                                <Col span={14}>
                                  <Radio.Group
                                    onChange={(e) => handleSetLots(e, i, index)}
                                  >
                                    <Radio value={1}>Yes</Radio>
                                    <Radio value={2}>No</Radio>
                                  </Radio.Group>
                                </Col>
                              </Row> */}
                              {param?.lot ? (
                                <Row gutter={24}>
                                  <Col span={6}>
                                    <FormItem
                                      //   label="Materials"
                                      rules={[
                                        {
                                          required: false,
                                          message: 'Enter Lots'
                                        }
                                      ]}
                                      className={styles.customLable}
                                    >
                                      <Input placeholder="Enter number of Lots" />
                                    </FormItem>
                                  </Col>
                                </Row>
                              ) : (
                                ''
                              )}

                              {/* <Row gutter={24} className={styles.gap}>
                                <Col span={24}>
                                  <Space direction="vertical">
                                    <span style={{ fontSize: '14px' }}>
                                      Other Parameters:
                                    </span>
                                    <span
                                      style={{
                                        fontSize: '14px',
                                        color: 'gray'
                                      }}
                                    >
                                      Lifting and Positioning Device Equipment
                                      #, Vibratory Sifter Equipment #
                                    </span>
                                  </Space>
                                </Col>
                              </Row> */}
                              {/* <Row gutter={24} className={styles.gap}>
                                <Col span={3}>
                                  <span style={{ fontSize: '14px' }}>
                                    Other Parameters:
                                  </span>
                                </Col>
                              </Row>
                              <Row gutter={24}>
                                <Col span={24}>
                                  <span
                                    style={{
                                      fontSize: '14px',
                                      color: 'gray'
                                    }}
                                  >
                                    Lifting and Positioning Device Equipment #,
                                    Vibratory Sifter Equipment #
                                  </span>
                                </Col>
                              </Row> */}
                            </>
                          </Card>
                        </Badge.Ribbon>
                        <br />
                        {showModal ? (
                          <CustomModal
                            title="Add Instructions"
                            visible={showModal}
                            width="30%"
                            // height="50%"
                            footer={null}
                            className={styles.customModal}
                            closable={false}
                          >
                            <Form form={form} layout="vertical">
                              <Row gutter={24}>
                                <Col span={24}>
                                  {instruction?.map((item, index) => {
                                    return (
                                      <FormItem
                                        key={index}
                                        label={`Step ${index + 1}`}
                                        rules={[
                                          {
                                            required: false,
                                            message: 'Enter instructions'
                                          }
                                        ]}
                                        className={styles.customLable}
                                      >
                                        <TextArea
                                          rows={4}
                                          placeholder="Enter Instructions"
                                          onChange={(e) =>
                                            ChangeInstruction(e, index)
                                          }
                                        />
                                        <span
                                          style={{
                                            color: '#c91761',
                                            float: 'right',
                                            cursor: 'pointer'
                                          }}
                                          onClick={handleInstructions}
                                        >
                                          {/* {instruction?.length - 1 == index */}
                                          Add Next Step
                                          {/* : ''} */}
                                        </span>
                                      </FormItem>
                                    );
                                  })}
                                </Col>
                              </Row>
                              <FormItem className="text-center">
                                <Space>
                                  <CustomButton
                                    className={styles.inwardButton}
                                    onClick={handleClose}
                                    type="primary"
                                    size="small"
                                    ghost
                                  >
                                    Close
                                  </CustomButton>
                                  <CustomButton
                                    className={styles.inwardButton}
                                    onClick={() => handleSubmit(index, i)}
                                    type="primary"
                                    size="small"
                                  >
                                    Proceed
                                  </CustomButton>
                                </Space>
                              </FormItem>
                            </Form>
                          </CustomModal>
                        ) : (
                          ''
                        )}
                      </>
                    );
                  })}
                </Panel>
              </Collapse>
              <br />
            </>
          );
        })}

        <br />
        <FormItem className="text-center">
          <Space>
            {/* {truckShow ? (
              <Image
                src={truck}
                preview={false}
                style={{ width: '100px !important', height: '100px' }}
              />
            ) : ( */}
            <CustomButton
              className={styles.inwardButton}
              htmlType="submit"
              type="primary"
            >
              Proceed
            </CustomButton>
            {/* )} */}
            {/* <CustomButton
              className={styles.inwardButton}
              type="primary"
              ghost
              onClick={() => history.back()}
            >
              Back
            </CustomButton> */}
          </Space>
        </FormItem>
      </Form>
    </Layout>
  );
};
export default ConfigProcess;
