import React from 'react';
import Layout from '@/common/Layout';
import WeighingReportPage from './components/WeighingReportPage';

const WeighingReport = () => {
  return (
    <>
      <Layout sider={false}>
        <WeighingReportPage />
      </Layout>
    </>
  );
};

export default WeighingReport;
