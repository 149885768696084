import React, { useEffect } from 'react';
import Layout from '@/common/Layout';
import { Row, Col, Form, Card, Input, InputNumber, message } from 'antd';
import styles from '@/common/Layout/index.module.less';
import { getDispenseMaterialDetails } from '@/features/dispensing/redux/slice';
import FormItem from '@/common/FormItem';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useState } from 'react';
import CustomButton from '@/common/CustomButton';
import { DeleteOutlined, PlusCircleFilled } from '@ant-design/icons';
import { createBlending } from '../../redux/slice';
import { history } from 'app/history';

const blending = () => {
  const { materialDetails } = useSelector((state) => state.dispensing);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const params = useParams();
  const [blends, setBlends] = useState([
    {
      container: '',
      tare_weight: '',
      net_weight: '',
      gross_weight: ''
    }
  ]);

  const [blendsleftovers, setBlendsleftovers] = useState([
    {
      leftover_container: '',
      tare_weight: '',
      net_weight: '',
      gross_weight: ''
    }
  ]);
  const [totalNet, setTotalNet] = useState(0);
  const [totalLeftNet, setTotalLeftNet] = useState(0);

  useEffect(() => {
    handleGetAllMaterials();
  }, []);

  const handleGetAllMaterials = () => {
    let payload = {
      id: params.id
    };
    dispatch(getDispenseMaterialDetails(payload));
  };

  const handleAddWeight = () => {
    let temp_lots = [...blends];
    temp_lots.push({
      container: '',
      tare_weight: '',
      net_weight: '',
      gross_weight: ''
    });
    setBlends(temp_lots);
  };

  const handleAddleftBlends = () => {
    let temp_lots = [...blendsleftovers];
    temp_lots.push({
      container: '',
      tare_weight: '',
      net_weight: '',
      gross_weight: ''
    });
    setBlendsleftovers(temp_lots);
  };

  const handleremoveWeight = (i) => {
    let temp_lots = [...blends];
    temp_lots.splice(i, 1);
    setBlends(temp_lots);
  };

  const handleremoveLeftBlends = (i) => {
    let temp_lots = [...blendsleftovers];
    temp_lots.splice(i, 1);
    setBlendsleftovers(temp_lots);
  };

  const handleChange = (type, i, e) => {
    let temp_lots = [...blends];
    if (type === 'tare_weight') {
      temp_lots[i]['gross_weight'] = e + (temp_lots[i]['net_weight'] || 0);
    } else if (type === 'net_weight') {
      temp_lots[i]['gross_weight'] = (
        e + (temp_lots[i]['tare_weight'] || 0)
      ).toFixed(3);
    }
    if (type == 'tare_weight' || type == 'net_weight') {
      temp_lots[i][type] = e;
    } else {
      temp_lots[i][type] = e.target.value;
    }
    setBlends(temp_lots);
    var add = 0;
    blends.map((item) => {
      add += item?.net_weight !== '' ? item?.net_weight : 0;
    });
    setTotalNet(add.toFixed(3));
  };
  const handleleftChange = (type, i, e) => {
    let temp_lots = [...blendsleftovers];
    if (type === 'tare_weight') {
      temp_lots[i]['gross_weight'] = e + (temp_lots[i]['net_weight'] || 0);
    } else if (type === 'net_weight') {
      temp_lots[i]['gross_weight'] = (
        e + (temp_lots[i]['tare_weight'] || 0)
      ).toFixed(3);
    }
    if (type == 'tare_weight' || type == 'net_weight') {
      temp_lots[i][type] = e;
    } else {
      temp_lots[i][type] = e.target.value;
    }
    setBlendsleftovers(temp_lots);
    var add = 0;
    blendsleftovers.map((item) => {
      add += item?.net_weight !== '' ? item?.net_weight : 0;
    });
    setTotalLeftNet(add);
  };

  console.log(blendsleftovers);
  console.log(blends);
  const onFinish = (values) => {
    const payload = {
      dispensing_request_id: params.id,
      product_balance: values.production_balance,
      total_net_weight: totalNet,
      total_net_leftover: totalLeftNet,
      blend_container: blends,
      blend_leftover: blendsleftovers
    };
    console.log(payload);
    dispatch(createBlending(payload)).then((response) => {
      if (response?.payload?.success) {
        message.success(response?.payload.message);
        history.push(`/prod-sampling/${params.id}`);
      }
    });
  };
  return (
    <Layout sider={false}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
      >
        <Card
          title={<span style={{ fontWeight: 'bold' }}>Blending</span>}
          className={styles.CustomPanel}
        >
          <Card>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem label="Product Name" className={styles.customLable}>
                  <Input
                    disabled
                    value={materialDetails?.product_detail?.name || 'NA'}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="Product Batch Number"
                  className={styles.customLable}
                >
                  <Input
                    disabled
                    value={materialDetails?.product_batch_number || 'NA'}
                  />
                </FormItem>
              </Col>

              <Col span={12}>
                <FormItem label="Effective Date" className={styles.customLable}>
                  <Input
                    disabled
                    value={
                      materialDetails?.effective_date
                        ? moment(materialDetails?.effective_date).format(
                            'DD MMM YYYY'
                          )
                        : 'NA'
                    }
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="Batch Size" className={styles.customLable}>
                  <Input
                    disabled
                    value={materialDetails?.product_detail?.batch_size || 'NA'}
                  />
                </FormItem>
              </Col>
            </Row>
          </Card>
          <Card title={`Blending `}>
            <Row gutter={24}>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Production Balance ID"
                  name="production_balance"
                  rules={[
                    {
                      required: false,
                      message: 'Enter Production Balance ID'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input placeholder="Enter Production Balance ID" />
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Total Net Weight"
                  rules={[
                    {
                      required: false,
                      message: 'Enter Total Net Weight'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter Total Net Weight"
                    disabled
                    value={totalNet}
                  />
                </FormItem>
              </Col>
            </Row>
            {blends?.map((data, i) => {
              return (
                <>
                  <Row gutter={24} key={i}>
                    <Col xs={24} xl={4} span={24} md={24} sm={24}>
                      <FormItem
                        label="Container #"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Container #'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter Container ID"
                          value={data?.container || ''}
                          onChange={(e) => handleChange('container', i, e)}
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={4} span={24} md={24} sm={24}>
                      <FormItem
                        label="Tare Weight"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Tare Weight'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <InputNumber
                          style={{ width: '100%' }}
                          min={0}
                          placeholder="Enter Tare Weight"
                          value={data?.tare_weight || ''}
                          onChange={(e) => handleChange('tare_weight', i, e)}
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={2} span={24} md={24} sm={24}>
                      <FormItem
                        label="UOM"
                        rules={[
                          {
                            required: false,
                            message: 'Enter UOM'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="UOM"
                          value={blends[0].uom || ''}
                          onChange={(e) => handleChange('uom', i, e)}
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={4} span={24} md={24} sm={24}>
                      <FormItem
                        label="Net Weight"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Net Weight'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <InputNumber
                          style={{ width: '100%' }}
                          min={0}
                          placeholder="Enter Net Weight"
                          value={data?.net_weight || ''}
                          onChange={(e) => handleChange('net_weight', i, e)}
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={2} span={24} md={24} sm={24}>
                      <FormItem
                        label="UOM"
                        rules={[
                          {
                            required: false,
                            message: 'Enter UOM'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="UOM"
                          value={blends[0].uom || ''}
                          disabled
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={4} span={24} md={24} sm={24}>
                      <FormItem
                        label="Gross Weight"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Gross Weight'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter Gross Weight"
                          value={data?.gross_weight || ''}
                          onChange={(e) => handleChange('gross_weight', i, e)}
                          disabled
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={2} span={24} md={24} sm={24}>
                      <FormItem
                        label="UOM"
                        rules={[
                          {
                            required: false,
                            message: 'Enter UOM'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="UOM"
                          value={blends[0].uom}
                          disabled
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={2} span={24} md={24} sm={24}>
                      <FormItem
                        label=""
                        rules={[
                          {
                            required: false,
                            message: 'Enter UOM'
                          }
                        ]}
                        className={styles.customLableButton}
                      >
                        {i == 0 ? (
                          <CustomButton onClick={() => handleAddWeight(i)}>
                            <PlusCircleFilled />
                          </CustomButton>
                        ) : (
                          ''
                        )}
                        {i > 0 ? (
                          <CustomButton onClick={() => handleremoveWeight(i)}>
                            <DeleteOutlined />
                          </CustomButton>
                        ) : (
                          ''
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </>
              );
            })}
          </Card>
          <Card title={`Left Over Materials & Rejects `}>
            <Row gutter={24}>
              <Col xs={24} xl={12} span={24} md={24} sm={24}>
                <FormItem
                  label="Total Net Weight"
                  rules={[
                    {
                      required: false,
                      message: 'Enter Total Net Weight'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter Total Net Weight"
                    disabled
                    value={totalLeftNet}
                  />
                </FormItem>
              </Col>
            </Row>
            {blendsleftovers?.map((data, i) => {
              return (
                <>
                  <Row gutter={24} key={i}>
                    <Col xs={24} xl={4} span={24} md={24} sm={24}>
                      <FormItem
                        label="Container #"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Container #'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter Container ID"
                          value={data?.leftover_container || ''}
                          onChange={(e) =>
                            handleleftChange('leftover_container', i, e)
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={4} span={24} md={24} sm={24}>
                      <FormItem
                        label="Tare Weight"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Tare Weight'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <InputNumber
                          style={{ width: '100%' }}
                          min={0}
                          placeholder="Enter Tare Weight"
                          value={data?.tare_weight || ''}
                          onChange={(e) =>
                            handleleftChange('tare_weight', i, e)
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={2} span={24} md={24} sm={24}>
                      <FormItem
                        label="UOM"
                        rules={[
                          {
                            required: false,
                            message: 'Enter UOM'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="UOM"
                          value={blends[0].uom || ''}
                          onChange={(e) => handleleftChange('uom', i, e)}
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={4} span={24} md={24} sm={24}>
                      <FormItem
                        label="Net Weight"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Net Weight'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <InputNumber
                          style={{ width: '100%' }}
                          min={0}
                          placeholder="Enter Net Weight"
                          value={data?.net_weight || ''}
                          onChange={(e) => handleleftChange('net_weight', i, e)}
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={2} span={24} md={24} sm={24}>
                      <FormItem
                        label="UOM"
                        rules={[
                          {
                            required: false,
                            message: 'Enter UOM'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="UOM"
                          value={blends[0].uom || ''}
                          disabled
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={4} span={24} md={24} sm={24}>
                      <FormItem
                        label="Gross Weight"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Gross Weight'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter Gross Weight"
                          value={data?.gross_weight || ''}
                          onChange={(e) =>
                            handleleftChange('gross_weight', i, e)
                          }
                          disabled
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={2} span={24} md={24} sm={24}>
                      <FormItem
                        label="UOM"
                        rules={[
                          {
                            required: false,
                            message: 'Enter UOM'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="UOM"
                          value={blends[0].uom}
                          disabled
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={2} span={24} md={24} sm={24}>
                      <FormItem
                        label=""
                        rules={[
                          {
                            required: false,
                            message: 'Enter UOM'
                          }
                        ]}
                        className={styles.customLableButton}
                      >
                        {i == 0 ? (
                          <CustomButton onClick={() => handleAddleftBlends(i)}>
                            <PlusCircleFilled />
                          </CustomButton>
                        ) : (
                          ''
                        )}
                        {i > 0 ? (
                          <CustomButton
                            onClick={() => handleremoveLeftBlends(i)}
                          >
                            <DeleteOutlined />
                          </CustomButton>
                        ) : (
                          ''
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </>
              );
            })}
          </Card>
        </Card>
        <br />
        <FormItem className="text-center">
          <CustomButton
            className={styles.inwardButton}
            htmlType="submit"
            type="primary"
          >
            Save & Proceed
          </CustomButton>
        </FormItem>
      </Form>
    </Layout>
  );
};
export default blending;
