import React, { useEffect } from 'react';
import styles from '@/common/Layout/index.module.less';
import {
  Card,
  Row,
  Space,
  Empty,
  Col,
  Form,
  Typography,
  InputNumber,
  Image
} from 'antd';
import CustomTable from 'common/Content/CustomTable';
import Layout from '@/common/Layout';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllnetWeights, updatenetWeights } from '../../redux/slice';
import { useParams } from 'react-router-dom';
import FormItem from '@/common/FormItem';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import truck from '@/features/generateMrn/components/images/truck-delivery-done.gif';

const { Title } = Typography;
const UpdateNetWeights = () => {
  const params = useParams();
  const { allMaterials, getAllMaterilLoading } = useSelector(
    (state) => state.material
  );
  const [netWeights, setNetWeights] = useState([]);
  const [truckShow, setTruckShow] = useState(false);
  const [form] = Form.useForm();

  console.log(netWeights);
  const dispatch = useDispatch();

  useEffect(() => {
    handleGetAllMaterials();
  }, []);

  const handleGetAllMaterials = () => {
    let payload = {
      mrn_number: params.id,
      status: 'approved'
    };
    dispatch(getAllnetWeights(payload)).then(function (response) {
      const count = response?.payload?.data[0]?.containers?.length;
      setNetWeights(
        Array.apply(0, Array(count))?.map((item, index) => ({
          // id: response?.payload?.data[0]?.containers[index]?.id,
          container_id: response?.payload?.data[0]?.containers[index]?.id,
          weighment_id:
            response?.payload?.data[0]?.containers[index]?.weighment?.id || '',
          net_weight:
            response?.payload?.data[0]?.containers[index]?.weighment?.net_weight
        }))
      );
    });
  };

  const handleClick = (value) => {
    console.log(value);
    const count = allMaterials[0]?.containers?.length;
    setNetWeights(
      Array.apply(0, Array(count))?.map((item, index) => ({
        container_id: allMaterials[0]?.containers[index]?.id,
        net_weight:
          value ||
          allMaterials[0]?.containers[0]?.weighment.net_weight[index]?.weighment
      }))
    );
  };

  const handleClickClear = (value) => {
    console.log(value);
    const count = allMaterials[0]?.containers?.length;
    setNetWeights(
      Array.apply(0, Array(count))?.map((item, index) => ({
        container_id: allMaterials[0]?.containers[index]?.id,
        net_weight: ''
      }))
    );
  };

  const onChange = (value, index) => {
    console.log(value);
    setNetWeights((prevData) => {
      const newData = [...prevData];
      const newItem = { ...newData[index] };
      newItem.net_weight = value;
      newData[index] = newItem;

      return newData;
    });
  };

  const onFinish = () => {
    setTruckShow(true);
    const payload = { all_net_weight: netWeights };
    console.log(payload);
    dispatch(updatenetWeights(payload)).then(function (response) {
      if (response?.payload?.success) {
        setTruckShow(false);
        history.push(`/list`);
      }
    });
  };
  const columns = [
    {
      title: 'Container',
      dataIndex: 'number',
      key: 'number',
      width: '150px',
      ellipsis: true,
      sorter: (a, b) => a?.number?.localeCompare(b?.number),
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Gross weight',
      dataIndex: 'gross_weight',
      key: 'gross_weight',
      ellipsis: true,
      width: '150px',
      sorter: (a, b) => a?.gross_weight?.localeCompare(b?.gross_weight),
      render: (text, record) => {
        return record?.weighment?.gross_weight_on_label || 'NA';
      },
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Observed Weight',
      dataIndex: 'observed_weight',
      key: 'observed_weight',
      ellipsis: true,
      width: '100px',
      sorter: (a, b) => a?.observed_weight?.localeCompare(b?.observed_weight),
      render: (text, record) => {
        return record?.weighment?.observed_weight || 'NA';
      },
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Net Weight',
      align: 'center',
      ellipsis: true,
      width: '200px',
      render: (text, _, index) => (
        <InputNumber
          value={netWeights[index]?.net_weight}
          min={0}
          style={{
            width: '100%'
          }}
          placeholder="Enter Net Weight"
          onChange={(v) => onChange(v, index)}
        />
      ),
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    }
  ];
  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        <div className={styles.empty_state_text}>
          There are no material requests to display
        </div>
      </span>
    )
  };
  return (
    <>
      <Layout sider={false}>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Card title="Material Details" className={styles.CustomPanel}>
            <Row gutter={24}>
              <Col xs={12} xl={8} span={8}>
                <p className={styles.customLable}>Material Code</p>
                <p className={styles.customValue}>
                  {allMaterials[0]?.document_verification?.material?.code}
                </p>
              </Col>
              <Col xs={12} xl={8} span={8}>
                <p className={styles.customLable}>Material Name</p>
                <p className={styles.customValue}>
                  {allMaterials[0]?.document_verification?.material?.name}
                </p>
              </Col>
              <Col xs={12} xl={8} span={8}>
                <p className={styles.customLable}>Invoice Number</p>
                <p className={styles.customValue}>
                  {allMaterials[0]?.document_verification?.invoice_number}
                </p>
              </Col>
              <Col xs={12} xl={8} span={8}>
                <p className={styles.customLable}>MRN Number</p>
                <p className={styles.customValue}>
                  {allMaterials[0]?.mrn_number}
                </p>
              </Col>
              <Col xs={12} xl={8} span={8}>
                <p className={styles.customLable}>In-house Batch Number</p>
                <p className={styles.customValue}>
                  {allMaterials[0]?.inhouse_batch_number}
                </p>
              </Col>
              <Col xs={12} xl={8} span={8}>
                <p className={styles.customLable}>No. of Contaiers</p>
                <p className={styles.customValue}>
                  {allMaterials[0]?.containers?.length}
                </p>
              </Col>
            </Row>
          </Card>
          <br />
          <Card
            title="Update Net Weight"
            className={styles.CustomPanel}
            extra={
              <Space>
                <Title
                  level={5}
                  className={styles.typography}
                  type="text"
                  onClick={() =>
                    handleClick(
                      netWeights?.length ? netWeights[0].net_weight : ''
                    )
                  }
                >
                  Fill all
                </Title>
                <Title type="text" level={5} className={styles.typography}>
                  |
                </Title>
                <Title
                  className={styles.typography}
                  level={5}
                  type="text"
                  onClick={() =>
                    handleClickClear(
                      netWeights?.length ? netWeights[0].net_weight : ''
                    )
                  }
                >
                  Clear all
                </Title>
              </Space>
            }
          >
            <Space size={9} direction="vertical" className={styles.searchwidth}>
              <Row
                gutter={16}
                style={{
                  marginTop: '-7px',
                  marginRight: '0px',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                {/* <Col span={20} xs={12} xl={20}>
                  <div className={`${styles.Gate_pass_req_title}`}>
                    Update Net Weight{' '}
                  </div>
                </Col> */}
                {/* <Col span={4} xs={12} xl={4} className={styles.CustomButton s}>
                  <Space>
                    <Title
                      level={5}
                      className={styles.typography}
                      type="text"
                      onClick={() =>
                        handleClick(
                          netWeights?.length ? netWeights[0].net_weight : ''
                        )
                      }
                    >
                      Fill all
                    </Title>
                    <Title type="text" level={5} className={styles.typography}>
                      |
                    </Title>
                    <Title
                      className={styles.typography}
                      level={5}
                      type="text"
                      onClick={() =>
                        handleClickClear(
                          netWeights?.length ? netWeights[0].net_weight : ''
                        )
                      }
                    >
                      Clear all
                    </Title>
                  </Space>
                </Col> */}
              </Row>
            </Space>
            <div className={`${styles.table_container}`}>
              <CustomTable
                locale={locale}
                loading={getAllMaterilLoading}
                columns={columns}
                dataSource={allMaterials[0]?.containers}
                scroll={{ x: 'auto' }}
                pagination={false}
              />
            </div>
          </Card>
          <br />
          <FormItem className="text-center">
            <Space>
              {truckShow ? (
                <Image
                  src={truck}
                  preview={false}
                  style={{ width: '100px !important', height: '100px' }}
                />
              ) : (
                <CustomButton type="primary" htmlType="submit">
                  Submit
                </CustomButton>
              )}
            </Space>
          </FormItem>
        </Form>
      </Layout>
    </>
  );
};

export default UpdateNetWeights;
