import React, { useState, useEffect, useCallback } from 'react';
import {
  Card,
  Empty,
  Tag,
  Space,
  Popconfirm,
  Input,
  InputNumber,
  // Col,
  // Select,
  message,
  Col
} from 'antd';
import CustomTable from 'common/Content/CustomTable';
import Layout from '@/common/Layout';
import styles from '@/common/Layout/index.module.less';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllDispenses,
  deleteDispense,
  getdetails,
  getFifo,
  getassaydetails
} from '../../redux/slice';
import { ValidateMaterial } from '@/features/dispensing/redux/slice';
import { DndContext } from '@dnd-kit/core';
import FormItem from '@/common/FormItem';
import CustomButton from '@/common/CustomButton';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  HolderOutlined,
  DeleteOutlined,
  EyeOutlined,
  ReadOutlined,
  SnippetsOutlined
} from '@ant-design/icons';
import { history } from 'app/history';
import moment from 'moment';
import { getLoggedInUser, getRoles } from 'utilities/helpers';
import CustomModal from '@/common/CustomModal';

const { Search } = Input;

const ListofDispenseMaterials = () => {
  const { dispenses, getAllMaterilLoading, pagination } = useSelector(
    (state) => state.material
  );
  const { user } = getLoggedInUser();
  const { Superadmindata } = getRoles('Warehouse');
  const { SuperadminQCdata } = getRoles('Quality Control');

  const [dataSource, setDataSource] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [showDisablePopup, setShowDisablePopup] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [expandData, setExpandData] = useState();
  const [fifoBatches, setFifoBatches] = useState([]);
  const [newBatches, setNewBatches] = useState();
  const [dispenseId, setDispenseId] = useState();
  const [viewAssay, setViewAssay] = useState(false);
  const [assayData, setAssayData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  console.log(expandData, dispenses, selectedRowKeys);
  const dispatch = useDispatch();
  useEffect(() => {
    handleGetAllDispenses();
  }, [page, search]);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const handleDeleteUser = (payload) => {
    dispatch(deleteDispense(payload)).then(() => {
      handleGetAllDispenses();
      setShowDisablePopup(false);
    });
  };

  const handleGetAllDispenses = () => {
    const payload =
      user?.role == 'qa_initiator'
        ? { page: page, search: search, type: 'material' }
        : { page: page, search: search };
    dispatch(getAllDispenses(payload)).then(function (response) {
      if (response?.payload?.success) {
        user?.role == 'qc_initiator'
          ? setDataSource(
              response?.payload?.data.filter(
                (data) => data?.status !== 'WH LC Pending'
              )
            )
          : setDataSource(response?.payload?.data);
      }
    });
  };

  const handlegetFefo = (id) => {
    setFifoBatches([]);
    setNewBatches([]);
    const payload = { id: id };
    setDispenseId(id);
    dispatch(getFifo(payload)).then((response) => {
      if (response?.payload?.success) {
        setFifoBatches(response?.payload?.data);
        setExpandData([]);
        setAssayData([]);
        setShowModal(true);
      }
    });
  };
  console.log(fifoBatches, 'lklklkl');
  const handleAssayCal = () => {
    // const selected_batches = [];
    console.log(fifoBatches);
    const myArrayFiltered = fifoBatches.filter((el) => {
      return selectedRowKeys.some((f) => {
        return f === el?.batches?.[0]?.number;
      });
    });
    console.log(myArrayFiltered);
    const batches = [...myArrayFiltered].sort((a, b) =>
      a.type > b.type ? 1 : -1
    );
    // console.log(batches, fifoBatches?.length);
    // if (batches?.length >= fifoBatches?.length) {
    const payload = {
      dispensing_request_id: dispenseId,
      bmr_material: batches
    };
    dispatch(ValidateMaterial(payload)).then((response) => {
      if (response?.payload?.success) {
        message.success(response?.payload?.message);
        setShowModal(false);
      }
    });
    // } else {
    //   message.error('Please select atleast one batch from the list');
    // }
  };
  const ShowdetailedView = (id, type) => {
    console.log(id, type);
    setShowModal(true);
    const payload = { id: id, type: type };
    dispatch(getdetails(payload)).then((response) => {
      if (response?.payload?.success) {
        console.log(response?.payload?.data);
        setFifoBatches([]);
        setAssayData([]);
        response?.payload?.data?.type == 'bmr'
          ? setExpandData(response?.payload?.data)
          : setExpandData(response?.payload?.data);
      }
    });
  };

  const ShowdetailedAssayView = (id, type) => {
    console.log(id, type);
    setShowModal(true);
    setViewAssay(true);
    const payload = { id: id, type: type };
    dispatch(getassaydetails(payload)).then((response) => {
      if (response?.payload?.success) {
        console.log(response?.payload?.data, 'Assay data');
        setFifoBatches([]);
        setExpandData([]);
        response?.payload?.data?.type == 'bmr'
          ? setAssayData(response?.payload?.data)
          : setAssayData(response?.payload?.data);
      }
    });
  };

  const visibility = useCallback((record) => {
    return showDisablePopup[record.id];
  }, showDisablePopup);

  const handleCampaign = (bacthes) => {
    localStorage.removeItem('batches');
    localStorage.setItem('batches', bacthes);
    user?.role == 'qc_initiator' || user?.role == 'qa_super_user'
      ? history.push(`/qc-create-checklist/${bacthes[0]}`)
      : history.push(`/create-checklist/${bacthes[0]}`);
  };

  const columns = [
    {
      title: 'Product Batch #',
      dataIndex: 'product_batch_number',
      ellipsis: true,
      width: '150px',
      fixed: 'left',
      render: (text, record) => {
        return user?.role == 'qc_initiator' || user?.role == 'qa_super_user' ? (
          <a href={`qc-create-checklist/${record?.line_clearance_input?.id}`}>
            {text || 'NA'}
          </a>
        ) : (user?.role == 'qa_initiator' && user?.level == 3) ||
          SuperadminQCdata?.[0]?.level == 3 ? (
          <a href={`dispensing/${record.id}`}>{text || 'NA'}</a>
        ) : (user?.role == 'qa_initiator' && user?.level == 2) ||
          SuperadminQCdata?.[0]?.level == 2 ? (
          text || 'NA'
        ) : (user?.level == 3 || Superadmindata?.[0]?.level == 3) &&
          record?.status == 'Dispensed' &&
          record?.sub_type == 'auto' ? (
          <a href={`dispensing-material/${record.id}`}>{text || 'NA'}</a>
        ) : (user?.level == 3 || Superadmindata?.[0]?.level == 3) &&
          record?.status == 'WH LC Pending' ? (
          // <a href={`create-checklist/${record.id}`}>{text || 'NA'}</a>
          text || 'NA'
        ) : (user?.level == 3 || Superadmindata?.[0]?.level == 3) &&
          (record?.status == 'Approved' || record?.status == 'Dispensed') &&
          record?.sub_type == 'auto' ? (
          <a href={`dispensing-material/${record.id}`}>{text || 'NA'}</a>
        ) : (user?.level == 2 || Superadmindata?.[0]?.level == 2) &&
          (record?.status == 'WH LC Pending' ||
            record?.status == 'QA LC Pending') ? (
          // <a href={`create-checklist/${record.id}`}>{text || 'NA'}</a>
          text || 'NA'
        ) : (user?.level == 2 || Superadmindata?.[0]?.level == 2) &&
          (record?.status == 'Approved' || record?.status == 'Dispensed') &&
          record?.sub_type == 'auto' ? (
          <a href={`dispensing-material/${record.id}`}>{text || 'NA'}</a>
        ) : ((user?.level == 3 || Superadmindata?.[0]?.level == 3) &&
            (record?.status == 'Approved' || record?.status == 'Dispensed') &&
            record?.sub_type == 'full') ||
          record?.sub_type == 'partial' ? (
          <a href={`manual_dispense/${record.id}?type=${record?.type}`}>
            {text || 'NA'}
          </a>
        ) : (user?.level == 2 || Superadmindata?.[0]?.level == 2) &&
          (record?.status == 'Approved' || record?.status == 'Dispensed') &&
          (record?.sub_type == 'full' || record?.sub_type == 'partial') ? (
          <a href={`manual_dispense/${record.id}?type=${record?.type}`}>
            {text || 'NA'}
          </a>
        ) : (user?.level == 2 ||
            user?.level == 3 ||
            Superadmindata?.[0]?.level == 2 ||
            Superadmindata?.[0]?.level == 3) &&
          (record?.status == 'Approved' || record?.status == 'Dispensed') &&
          record?.type == 'material' ? (
          <a href={`manual_dispense/${record.id}?type=${record?.type}`}>
            {text || 'NA'}
          </a>
        ) : (
          text || 'NA'
        );
      }
    },
    {
      title: 'BMR/Material #',
      dataIndex: 'bmr_number',
      ellipsis: true,
      width: '100px',
      // fixed: 'left',

      render: (text) => {
        return `${text || 'NA'}`;
      }
    },

    {
      title: 'Product Name',
      dataIndex: 'product_name',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return `${record?.product_detail?.name || 'NA'}`;
      }
    },
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return `${moment(record?.createdAt).format('DD MMM YYYY') || 'NA'}`;
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return (
          <Tag
            color={
              record?.status === 'WH LC Pending'
                ? '#dc3545'
                : record?.status === 'QA LC Pending'
                ? '#ff9933'
                : record?.status === 'Approved'
                ? '#28a745'
                : '#007bff'
            }
          >
            {record?.status}
          </Tag>
        );
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return (
          <Space>
            {record?.status == 'Dispensed' && user?.role == 'user' ? (
              <>
                <Popconfirm
                  title={`Are you sure do you want to ${
                    record.is_active ? 'Deactivate' : 'Activate'
                  } this Dispense?`}
                  visible={visibility(record)}
                  key={record.id}
                  onConfirm={(e) => {
                    e.stopPropagation();
                    handleDeleteUser({
                      id: record.id,
                      is_active: !record?.is_active
                    });
                  }}
                  onCancel={(e) => {
                    e.stopPropagation();
                    setShowDisablePopup({ [record.id]: false });
                  }}
                  okText={record.is_active ? 'Deactivate' : 'Activate'}
                  cancelText="No"
                  okButtonProps={{
                    loading: getAllMaterilLoading,
                    type: record.is_active ? 'danger' : 'primary'
                  }}
                >
                  {/* <DeleteOutlined
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDisablePopup({ [record.id]: true });
                }}
                style={{ color: '#e80e94' }}
              /> */}
                  <DeleteOutlined size="small" checked={record.is_active} />
                </Popconfirm>
                {record.type == 'bmr' &&
                record?.sub_type === 'auto' &&
                record?.assay_status === false ? (
                  <EyeOutlined
                    size="small"
                    onClick={() => handlegetFefo(record.id, record.type)}
                    // onClick={() => history.push(`/print-assay/${record.id}`)}
                  />
                ) : record.type == 'bmr' &&
                  record?.sub_type !== 'auto' &&
                  record?.assay_status === false ? (
                  <EyeOutlined
                    size="small"
                    onClick={() => ShowdetailedView(record.id, record.type)}
                    // onClick={() => history.push(`/print-assay/${record.id}`)}
                  />
                ) : record.type == 'material' ? (
                  <EyeOutlined
                    size="small"
                    onClick={() => ShowdetailedView(record.id, record.type)}
                  />
                ) : record.type == 'bmr' && record?.assay_status == true ? (
                  <EyeOutlined
                    size="small"
                    onClick={() => ShowdetailedView(record.id, record.type)}
                    // onClick={() => history.push(`/print-assay/${record.id}`)}
                  />
                ) : (
                  ''
                )}
              </>
            ) : record.type == 'bmr' &&
              record.sub_type === 'auto' &&
              record?.assay_status === false ? (
              <EyeOutlined
                size="small"
                onClick={() => handlegetFefo(record.id, record.type)}
              />
            ) : record.type == 'bmr' &&
              record.sub_type !== 'auto' &&
              record?.assay_status === false ? (
              <EyeOutlined
                size="small"
                onClick={() => ShowdetailedView(record.id, record.type)}
              />
            ) : record.type == 'material' ? (
              <EyeOutlined
                size="small"
                onClick={() => ShowdetailedView(record.id, record.type)}
              />
            ) : record.type == 'bmr' && record?.assay_status == true ? (
              <ReadOutlined
                size="small"
                onClick={() => ShowdetailedAssayView(record.id, record.type)}
                // onClick={() => history.push(`/print-assay/${record.id}`)}
              />
            ) : (
              ''
            )}
            {record?.status == 'Approved' && user?.role == 'qc_initiator' ? (
              <SnippetsOutlined
                onClick={() =>
                  history.push(
                    `/qc-create-checklist/${record?.line_clearance_input?.id}`
                  )
                }
              />
            ) : record?.status == 'Approved' && user?.role == 'user' ? (
              <SnippetsOutlined
                onClick={() =>
                  history.push(
                    `/qc-create-checklist/${record?.line_clearance_input?.id}`
                  )
                }
              />
            ) : (
              ''
            )}
          </Space>
        );
      }
    }
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  const Row = ({ children, ...props }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging
    } = useSortable({
      id: props['data-row-key']
    });
    const style = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && {
          ...transform,
          scaleY: 1
        }
      )?.replace(/translate3d\(([^,]+),/, 'translate3d(0,'),
      transition,
      ...(isDragging
        ? {
            position: 'relative',
            zIndex: 9999
          }
        : {})
    };
    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if (child?.key === 'id') {
            return React.cloneElement(child, {
              children: (
                <HolderOutlined
                  ref={setActivatorNodeRef}
                  style={{
                    touchAction: 'none',
                    cursor: 'move'
                  }}
                  {...listeners}
                />
              )
            });
          }
          return child;
        })}
      </tr>
    );
  };

  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        <div className={styles.empty_state_text}>
          There are no material requests to display
        </div>
      </span>
    )
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.id === active.id);
        const overIndex = previous.findIndex((i) => i.id === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };
  useEffect(() => {
    if (newBatches?.length) {
      console.log('h');
    } else {
      const data = fifoBatches.map((item) => {
        const data = item.batches.map((item2) => {
          return {
            material_id: item.material_id,
            batch_id: item2.batch_details_id,
            name: item.name,
            code: item.code,
            standard_quantity: item.standard_quantity,
            number: item2.number,
            avl_qty: item2.avl_qty,
            bin_number: item2.bin_number,
            batch_details: item?.batch_details,
            batch_qty:
              item?.is_product == 'BPR'
                ? item?.dispensed_quantity
                : item?.batch_qty
          };
        });
        return data;
      });
      const dataMerge = [].concat.apply([], data);
      console.log(dataMerge);
      dataMerge?.length ? setNewBatches(dataMerge) : '';
    }
  }, [fifoBatches]);
  const columns1 = [
    {
      title: 'Material Code',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return record?.material?.code;
      }
    },
    {
      title: 'Material',
      dataIndex: 'upgradeNum',
      key: 'upgradeNum',
      render: (text, record) => {
        return record?.material?.name;
      }
    },
    {
      title: 'Required Quantity',
      dataIndex: 'upgradeNum',
      key: 'upgradeNum',
      render: (text, record) => {
        return `${record?.required_weight} ${record?.material?.uom}`;
      }
    }
  ];

  const columns2 = [
    {
      title: 'Material Code',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return record?.code;
      }
    },
    {
      title: 'Material',
      dataIndex: 'upgradeNum',
      key: 'upgradeNum',
      render: (text, record) => {
        return record?.name;
      }
    },
    {
      title: 'Required Quantity',
      dataIndex: 'standard_quantity',
      key: 'standard_quantity',
      render: (text, record) => {
        return record?.batch_qty;
      }
    },
    {
      title: 'BIN Location',
      dataIndex: 'bin_location',
      key: 'bin_location',
      render: (text, record) => {
        return record?.bin_number;
      }
    },
    {
      title: 'Inhouse Batch #',
      dataIndex: 'bin_location',
      key: 'bin_location',
      render: (text, record) => {
        return record?.number;
      }
    },
    {
      title: 'Available Quantity',
      dataIndex: 'upgradeNum',
      key: 'upgradeNum',
      render: (text, record) => (
        <InputNumber
          value={record?.avl_qty}
          min={1}
          max={100000}
          style={{
            width: '100%'
          }}
          placeholder="Enter Net Weight"
          disabled={true}
        />
      )
    }
  ];

  return (
    <Layout sider={false}>
      <Card
        className={`${styles.cardStyles_approver}`}
        title={
          <span style={{ fontWeight: 'bold', color: '#c91761' }}>
            {user?.role == 'qc_initiator'
              ? 'Approval for LC Dispense Material'
              : 'Dispense Material'}
          </span>
        }
        extra={
          <Space>
            {user?.role == 'qa_initiator' ? (
              <CustomButton
                type="primary"
                ghost
                onClick={() => history.push('/dispensing')}
              >
                Create
              </CustomButton>
            ) : (
              ''
            )}
            <Search
              onChange={(e) => handleChange(e)}
              className={`w-100 ${styles.searchbar}`}
              justify="start"
              align="start"
              placeholder="Search by Product Batch number"
            />
          </Space>
        }
      >
        <DndContext onDragEnd={onDragEnd}>
          <SortableContext
            items={dataSource.map((i) => i.id)}
            strategy={verticalListSortingStrategy}
          >
            <div className={`${styles.table_container}`}>
              <CustomTable
                locale={locale}
                loading={getAllMaterilLoading}
                columns={columns}
                dataSource={dataSource}
                rowSelection={rowSelection}
                rowKey={
                  user?.role == 'qc_initiator' || user?.role == 'qa_super_user'
                    ? (record) => record.line_clearance_input?.id
                    : 'id'
                }
                pagination={{
                  size: 'small',
                  total: pagination?.total_records || 0,
                  showSizeChanger: false,
                  pageSize: 10,
                  onChange: (page) => {
                    setPage(page);
                  }
                }}
                components={{
                  body: {
                    row: Row
                  }
                }}
                // rowKey="id"
                // className={styles.scrolling}
                scroll={{ x: 'auto' }}
                // expandable={{
                //   expandedRowRender,
                //   defaultExpandedRowKeys: ['0']
                // }}
              />
            </div>
          </SortableContext>
        </DndContext>
        <FormItem className="text-center">
          <CustomButton
            htmlType="button"
            type="primary"
            onClick={() => handleCampaign(selectedRowKeys)}
            disabled={selectedRowKeys?.length ? false : true}
          >
            Initiate Line Clearnce
          </CustomButton>
        </FormItem>
      </Card>
      {showModal ? (
        <CustomModal
          title="Dispense Material Details"
          visible={showModal}
          width={expandData?.type == 'material' ? '70%' : '70%'}
          footer={null}
          className={styles.customModal}
          closable={false}
        >
          {expandData?.type == 'material' ? (
            <CustomTable
              columns={columns1}
              dataSource={expandData?.dispensing_materials}
              pagination={false}
              loading={getAllMaterilLoading}
            />
          ) : expandData?.type == 'bmr' && expandData?.sub_type !== 'auto' ? (
            <div
              dangerouslySetInnerHTML={{
                __html: expandData?.bmr_partial_details
              }}
              // className={styles.tdbody}
            />
          ) : !viewAssay ? (
            <CustomTable
              rowSelection={rowSelection}
              rowKey={(record) => record.number}
              columns={columns2}
              dataSource={newBatches}
              pagination={false}
              loading={getAllMaterilLoading}
            />
          ) : (
            ''
          )}
          <br />
          {!viewAssay ? (
            <FormItem className="text-center">
              <Space size={12}>
                <CustomButton
                  htmlType="button"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </CustomButton>
                {fifoBatches.length ? (
                  <CustomButton
                    htmlType="button"
                    type="primary"
                    onClick={() => handleAssayCal(false)}
                    // disabled={
                    //   fifoBatches[0]?.assay_calcuate === 'exist'
                    //     ? true
                    //     : false || disBtn
                    // }
                  >
                    Calculate Assay
                  </CustomButton>
                ) : (
                  ''
                )}
              </Space>
            </FormItem>
          ) : (
            ''
          )}
          {viewAssay ? (
            <Row gutter={24} style={{ top: '-34px' }}>
              {assayData?.map((item, index) => {
                const ids = item?.dispenseBatches.map(
                  ({ batch_number }) => batch_number
                );
                const filtered = item?.dispenseBatches.filter(
                  ({ batch_number }, index) =>
                    !ids.includes(batch_number, index + 1)
                );
                console.log(filtered);
                return (
                  <>
                    <Space size={6} key={index}>
                      <Card
                        // bordered={false}
                        title={
                          <b style={{ fontSize: '14px' }}>
                            {item?.material_name}
                          </b>
                        }
                        key={index}
                        style={{
                          width: 800,
                          marginBottom: '4px'
                        }}
                        extra={
                          <b style={{ color: '#e80e94' }}>
                            {item?.material_code}
                          </b>
                        }
                        className={styles.cardpadding}
                      >
                        <Space>
                          <span>Standard Qty:{item?.required_qty}</span>
                        </Space>
                        <br />
                        <Space size={4} direction="vertical">
                          {filtered?.map((lot, index) => {
                            return (
                              <>
                                <Space size={28} key={index}>
                                  <span>
                                    Inhouse Batch #: {lot?.batch_number || 'NA'}
                                  </span>
                                  <Space size={28}>
                                    <span>
                                      BIN #: {lot?.bin_location || 'NA'}
                                    </span>
                                    <span>Available Qty: {lot?.avl_qty}</span>
                                  </Space>
                                </Space>
                              </>
                            );
                          })}
                          <Space size={12}>
                            {item?.dispenseLots?.map((lot, index) => {
                              return (
                                <>
                                  <Col key={index} xs={24} xl={24} span={24}>
                                    <span>
                                      {lot?.lot_no
                                        .replace(/_/g, ' ')
                                        .charAt(0)
                                        .toUpperCase() +
                                        lot?.lot_no.replace(/_/g, ' ').slice(1)}
                                      : {lot?.dispensed_qty}
                                    </span>
                                  </Col>
                                </>
                              );
                            })}
                          </Space>
                        </Space>
                      </Card>
                      {'  '}
                    </Space>
                  </>
                );
              })}
              <FormItem className="text-center">
                <Space size={12}>
                  <CustomButton
                    htmlType="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </CustomButton>
                </Space>
              </FormItem>
            </Row>
          ) : (
            ''
          )}
        </CustomModal>
      ) : (
        ''
      )}
    </Layout>
  );
};
export default ListofDispenseMaterials;
